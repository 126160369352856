import { ComponentStyleConfig } from "@chakra-ui/react";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 10,
    fontWeight: 400,
    // _focusVisible: {
    //   bg: `${props.colorScheme}.600`,
    // },
  },
  sizes: {
    xs: {
      borderRadius: 6,
    },
    sm: {
      fontSize: "sm",
      borderRadius: 6,
    },
    md: {
      fontSize: "sm",
    },
    lg: {
      fontSize: "sm",
    },
  },
  defaultProps: {
    size: "lg",
    colorScheme: "primary",
  },
};
