import React, { useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  ModalBody,
  ModalFooter,
  useMediaQuery,
} from "@chakra-ui/react";
import Divider from "@material-ui/core/Divider";
import { Form, Formik } from "formik";

import { BaseModalProvider } from "src/components/BaseModal/useBaseModal";
import { BaseSwipeableDrawerProvider } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import SelectField from "src/components/Inputs/SelectField";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Modal from "src/components/NewComponents/Modal";
import Tabs from "src/components/Tabs";
import { ItemTabsProps } from "src/components/Tabs/props";
import { LINKS } from "src/constants/links";
import {
  DashboardProvider,
  useDashboard,
} from "src/hooks/dashboard/useDashboard";
import { FinancialProvider } from "src/hooks/financial/useFinancial";
import { usePermission } from "src/hooks/permission/usePermission";
import { RecordProvider } from "src/hooks/record/useRecord";
import { DashboardInvervalEnum } from "src/interfaces/dashboard";
import { withContext } from "src/utils/withContext";

import Summary from "../Financial/Summary";
import SchedulingsPanel from "./components/SchedulingsPanel";
import Sales from "./Sales";

const INTERVAL_ITEMS = Object.entries(DashboardInvervalEnum).map(
  ([key, value]) => ({
    value: key,
    name: value,
  })
);

export const DASHBOARD_TABS: Array<ItemTabsProps> = [
  {
    key: "dashboard",
    label: "Vendas",
    permissions: [
      {
        subject: "orderDashboard",
        action: "view",
      },
    ],
  },
  {
    key: "financial",
    label: "Financeiro",
    permissions: [
      {
        subject: "financialDashboard",
        action: "view",
      },
    ],
  },
];

const Home: React.FC = () => {
  const [interval, setInterval] =
    useState<keyof typeof DashboardInvervalEnum>("actualMonth");
  const { getDashboardSales } = useDashboard();
  const { can } = usePermission();
  const [tab, setTab] = useState<"dashboard" | "financial">(
    can([
      {
        subject: "orderDashboard",
        action: "view",
      },
    ])
      ? "dashboard"
      : "financial"
  );
  const [isModalOpen, setIsModalOpen] = useState(true);

  const [isMobile] = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (
      can([
        {
          subject: "financialDashboard",
          action: "view",
        },
        {
          subject: "orderDashboard",
          action: "view",
        },
      ])
    ) {
      getDashboardSales(interval);
    }
  }, [interval]);

  const renderRightHeader = useMemo(() => {
    if (
      !can([
        {
          subject: "financialDashboard",
          action: "view",
        },
        {
          subject: "orderDashboard",
          action: "view",
        },
      ])
    ) {
      return null;
    }

    return (
      <Formik
        // eslint-disable-next-line no-console
        onSubmit={v => console.log(v)}
        initialValues={{
          interval,
        }}
      >
        {({ values }) => (
          <Form>
            <SelectField
              defaultValue="week"
              label=""
              name="interval"
              items={INTERVAL_ITEMS}
            />
            {setInterval(values.interval)}
          </Form>
        )}
      </Formik>
    );
  }, []);

  const redirectUser = () => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      window.location.href = LINKS.appstore;
    } else {
      window.location.href = LINKS.playstore;
    }
  };

  return (
    <DashboardLayout selected="dashboard">
      <Breadcrumb home />
      <Divider />
      <PageTitle label="Dashboard">{renderRightHeader}</PageTitle>
      <Box mb={6}>
        <SchedulingsPanel />
      </Box>
      {!can([
        {
          subject: "financialDashboard",
          action: "view",
        },
        {
          subject: "orderDashboard",
          action: "view",
        },
      ]) ? (
        <Flex flex={1} h="60vh" justify="center" align="center">
          <Heading as="h3" fontSize="lg" textAlign="center">
            Você não tem permissão para acessar a dashboard de vendas e
            financeiro.
          </Heading>
        </Flex>
      ) : (
        <>
          {isMobile && (
            <Modal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              title=" Acesse nossa plataforma de forma mais prática com o aplicativo
            mobile !"
            >
              <ModalBody>
                Com a plataforma mobile, você acessa todas as funcionalidades em
                qualquer lugar e com mais praticidade. Baixe agora nosso
                aplicativo e experimente essa nova forma de se conectar!
              </ModalBody>
              <ModalFooter display="flex" gap={4}>
                <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
                  Continuar no web
                </Button>
                <Button onClick={() => redirectUser()}>Baixar o APP</Button>
              </ModalFooter>
            </Modal>
          )}
          <Tabs
            tab={tab}
            setTab={setTab}
            ariaLabel="Configurações"
            tabs={DASHBOARD_TABS}
            tabPanels={[
              <Sales interval={interval} key="dashboard" />,
              <Summary interval={interval} key="financial" />,
            ]}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default withContext(
  Home,
  DashboardProvider,
  RecordProvider,
  BaseModalProvider,
  BaseSwipeableDrawerProvider,
  FinancialProvider
);
