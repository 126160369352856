import React, { useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import BaseModal from "src/components/BaseModal";
import { useBaseModal } from "src/components/BaseModal/useBaseModal";
import Typography from "src/components/Typography";
import { useFinancialAccount } from "src/hooks/financialAccount/useFinancialAccount";

import * as Styled from "./styles";

const FinancialAccountArchiveOrUnarchiveModal: React.FC = () => {
  const { financialAccount, archiveOrUnarchiveStatus, archive, unarchive } =
    useFinancialAccount();

  const { handleClose } = useBaseModal();

  useEffect(() => {
    if (archiveOrUnarchiveStatus.success) {
      handleClose("financialAccountArchiveOrUnArchive");
    }
  }, [archiveOrUnarchiveStatus]);

  const description = useMemo(() => {
    if (financialAccount?.status === "enabled") {
      return "Tem certeza que deseja arquivar esta conta?";
    }
    return "Tem certeza que deseja desarquivar esta conta?";
  }, [financialAccount]);

  const onSubmit = async () => {
    if (!financialAccount) return;
    try {
      if (financialAccount?.status === "enabled") {
        await archive(financialAccount?.id as string);
      } else {
        await unarchive(financialAccount?.id as string);
      }
    } catch (error) {
      toast.error("Ocorreu um problema ao excluir esse lançamento");
    }
  };

  const baseActions = useMemo(() => {
    return (
      <Styled.DeleteButton
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        onClick={onSubmit}
        loading={archiveOrUnarchiveStatus.loading}
        disabled={archiveOrUnarchiveStatus.loading}
      >
        {financialAccount?.status === "enabled" ? "Arquivar" : "Desarquivar"}
      </Styled.DeleteButton>
    );
  }, [financialAccount, archiveOrUnarchiveStatus]);

  return (
    <BaseModal
      tag="financialAccountArchiveOrUnArchive"
      zIndex={1301}
      customHeader={<Styled.AlertIcon />}
      customHandleClose={() =>
        handleClose("financialAccountArchiveOrUnArchive")
      }
      customActions={baseActions}
    >
      <Styled.Container>
        <Typography color="textPrimary" variant="h6" align="center">
          {description}
        </Typography>
      </Styled.Container>
    </BaseModal>
  );
};

export default FinancialAccountArchiveOrUnarchiveModal;
