import { useState } from "react";
import { toast } from "react-toastify";

import downloadjs from "downloadjs";

import { STATUS } from "src/constants/requestStatus";
import { DocumentConfigurationType } from "src/interfaces/documentConfiguration";
import {
  GenerateOrderBudget,
  GenerateOrderDocument,
  GeneratePaymentReceipt,
} from "src/services/documents";

const DEFAULT_ERROR_MESSAGE = `Ocorreu um problema ao fazer o download desse documento`;

export const useDocument = () => {
  const [status, setStatus] = useState(STATUS.inital);

  const downloadOrderBudget = async (
    orderId: string,
    fileName: string,
    params: DocumentConfigurationType
  ) => {
    try {
      setStatus(STATUS.loading);
      const response = await GenerateOrderBudget(orderId, params);
      downloadjs(response, `${fileName}.pdf`);
    } catch (error) {
      toast.error(DEFAULT_ERROR_MESSAGE);
    } finally {
      setStatus(STATUS.success);
    }
  };

  const downloadOrderDocument = async (
    orderId: string,
    fileName: string,
    params: DocumentConfigurationType
  ) => {
    try {
      setStatus(STATUS.loading);
      const response = await GenerateOrderDocument(orderId, params);
      downloadjs(response, `${fileName}.pdf`);
    } catch (error) {
      toast.error(DEFAULT_ERROR_MESSAGE);
    } finally {
      setStatus(STATUS.success);
    }
  };

  const downloadReceipt = async (recordId: string) => {
    try {
      setStatus(STATUS.loading);
      const response = await GeneratePaymentReceipt(recordId);
      downloadjs(response, "recibo.pdf");
    } catch (error) {
      toast.error(DEFAULT_ERROR_MESSAGE);
    } finally {
      setStatus(STATUS.success);
    }
  };

  return {
    status,
    downloadOrderBudget,
    downloadOrderDocument,
    downloadReceipt,
  };
};
