import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@material-ui/core/Box";

import BottomBar from "src/components/Layouts/BottomBar";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Panel from "src/components/Layouts/Panel";
import PATHS from "src/constants/paths";
import { ProductProvider, useProduct } from "src/hooks/product/useProduct";
import { getRouteString } from "src/utils/urlHandler";
import { withContext } from "src/utils/withContext";

import ProductForm from "../components/ProductForm";
import * as Styled from "./styles";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Produtos", path: PATHS.PRODUCTS_LIST },
  { label: "Criação do produto" },
];

const ProductAdd: React.FC = () => {
  const { status, product } = useProduct();

  const navigate = useNavigate();

  useEffect(() => {
    if (status.success) {
      navigate(getRouteString(PATHS.PRODUCTS_DETAIL, product.id));
    }
  }, [status]);

  return (
    <DashboardLayout selected="register" subSelected="products">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <PageTitle label="Criação do produto" />

      <Panel padding={4}>
        <ProductForm method="add" />
      </Panel>

      <BottomBar>
        <Styled.Button
          color="primary"
          variant="contained"
          type="submit"
          form="form-product"
          disabled={status.loading}
        >
          Criar
        </Styled.Button>

        <Box width={16} />

        <Styled.Button
          color="primary"
          variant="outlined"
          to={PATHS.PRODUCTS_LIST}
        >
          Cancelar
        </Styled.Button>
      </BottomBar>
    </DashboardLayout>
  );
};

export default withContext(ProductAdd, ProductProvider);
