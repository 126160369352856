import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import Button from "src/components/Button";
import Loader from "src/components/Loader";
import { ModalProvider, useModal } from "src/components/Modal/useModal";
import Typography from "src/components/Typography";
import { useOrder } from "src/hooks/order/useOrder";
import { withContext } from "src/utils/withContext";

import TechnicalReportModal from "../Modals/TechnicalReport";
import * as Styled from "./styles";

const TechnicalReportManager: React.FC = () => {
  const { technicalReport, status } = useOrder();
  const { handleOpen: handleOpenTechnicalReportModal } = useModal();

  const renderContent = useMemo(() => {
    if (status.loading) {
      return <Loader size="medium" />;
    }

    if (technicalReport) {
      return (
        <>
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenTechnicalReportModal}
              >
                Editar laudo
              </Button>
            </Box>
          </Box>
          <Divider />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container>
                {technicalReport.data
                  .filter(item => item.type === "text")
                  .map(field => (
                    <Grid item xs={4} key={`field-${field.label}`}>
                      <Box mt={2} />
                      <Typography color="textPrimary">{field.label}</Typography>
                      <Typography color="textSecondary">
                        {field.content ? field.content : "-"}
                      </Typography>
                    </Grid>
                  ))}

                {technicalReport.data
                  .filter(item => item.type === "textarea")
                  .map(field => (
                    <Grid item xs={12} key={`field-${field.label}`}>
                      <Box mt={2} />
                      <Typography color="textPrimary">{field.label}</Typography>
                      <Typography color="textSecondary">
                        {field.content ? field.content : "-"}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Box mt={2} />
              <Typography color="textPrimary">Imagens</Typography>
              <Grid container spacing={1}>
                {technicalReport?.gallery?.map(item => {
                  const image: any = item.imageUrl;
                  const url =
                    image instanceof File ? URL.createObjectURL(image) : image;
                  return (
                    <Grid item xs={4} key={`field-${url}`}>
                      <Box mt={2} />
                      <Styled.Photo url={url} />
                      <Typography variant="caption" color="textPrimary">
                        {item.name}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Styled.NoteAddIcon />

          <Typography variant="subtitle1">Nenhum laudo adicionado</Typography>
          <Typography variant="body2">
            Adicione um laudo a ordem de serviço!
          </Typography>

          <Box mt={3}>
            <Button
              fullWidth
              color="primary"
              onClick={handleOpenTechnicalReportModal}
            >
              <Styled.AddCircleIcon color="primary" />
              Adicionar laudo
            </Button>
          </Box>
        </Box>
      </>
    );
  }, [status, technicalReport]);

  return (
    <>
      <Box mt={2} mb={2}>
        <Typography variant="body1" color="textSecondary">
          Laudo Técnico
        </Typography>
      </Box>
      <Styled.Container>
        {renderContent}
        <TechnicalReportModal />
      </Styled.Container>
    </>
  );
};

export default withContext(TechnicalReportManager, ModalProvider);
