import styled from "styled-components";

import { Typography, Box } from "@material-ui/core";
import MuiPaper from "@material-ui/core/Paper";
import MuiSwitch from "@material-ui/core/Switch";
import InfoIconMui from "@material-ui/icons/Info";

import Button from "src/components/Button";
import { boxShadow } from "src/styles/mixins/shadows";

export const InfoIcon = styled(InfoIconMui)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
  margin-right: 8px;
`;

export const Container = styled(MuiPaper)`
  ${boxShadow}
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 600px;
  padding: 0.5rem 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DeleteButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-color: #e54848;
  color: #e54848;
  font-size: 15px;
  font-weight: 500;
`;

export const SwitchLabel = styled(Typography)`
  font-size: 12px;
  color: rgba(19, 34, 62, 0.7);
  margin: 0;
`;

export const Switch = styled(MuiSwitch)`
  width: 64px;
  height: 24px;
  padding: 0;
  border-radius: 20px;
  overflow: visible;

  .MuiSwitch-thumb {
    width: 32px;
    height: 32px;
    background-color: #e54848;
  }

  .MuiSwitch-switchBase {
    padding: 0;
    top: 0;
    bottom: 0;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(32px);
  }

  .MuiSwitch-track {
    border-radius: 20px;
    background-color: #e54848;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: #1a5df2;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    .MuiSwitch-thumb {
      background-color: #1a5df2;
    }
  }
`;

export const BalanceRow = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  border-bottom: solid 1px #b3b6bc;
`;

export const BalanceLabel = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
`;
export const BalanceValue = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
