import { useMemo, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { Box, Divider, Text, Wrap } from "@chakra-ui/react";
import { format } from "date-fns";

import { CashFlow, CashFlowResume } from "src/interfaces/cashFlow";
import { PaymentMethodType } from "src/interfaces/payment";
import { formatPrice } from "src/utils/format";

interface PrintResumeCashFlowProps {
  cashFlowResume?: CashFlowResume | CashFlow;
  paymentMethods: PaymentMethodType[];
  operatorName: string;
  cashflowClosedMoney?: number;
  onAfterPrint?: () => void;
}

export const usePrintResumeCashFlow = ({
  cashFlowResume,
  paymentMethods,
  operatorName,
  cashflowClosedMoney,
  onAfterPrint,
}: PrintResumeCashFlowProps): {
  PrintResumeCashFlowComponent: JSX.Element;
  handlePrint: () => void;
} => {
  const componentRef = useRef(null);

  const PrintResumeCashFlowComponent = useMemo(() => {
    return (
      <Box display="none">
        <Box ref={componentRef} px={1}>
          <Box>
            <Text
              fontSize="14px"
              fontWeight={700}
              color="black"
              textAlign="center"
              lineHeight={1.2}
            >
              FECHAMENTO DE CAIXA
            </Text>
            <Text
              fontSize="10px"
              fontWeight={400}
              color="black"
              textAlign="center"
            >
              {(cashFlowResume as CashFlow).finishDate
                ? format(
                    new Date(
                      (cashFlowResume as CashFlow)?.finishDate as string
                    ),
                    "dd/MM/yyyy 'às' HH:mm"
                  )
                : format(new Date(), "dd/MM/yyyy 'às' HH:mm")}
            </Text>
            <Text
              fontSize="10px"
              fontWeight={400}
              color="black"
              textAlign="center"
            >
              {operatorName}
            </Text>
          </Box>
          <Box>
            <Text fontSize="12px" fontWeight={700} color="black">
              Entradas:
            </Text>
            {!cashFlowResume?.incomes || !cashFlowResume?.incomes.length ? (
              <Text flex={1} fontSize="10px" color="black" mt={2}>
                Nenhuma receita vinculada.
              </Text>
            ) : null}
            {!cashFlowResume?.incomes ||
              cashFlowResume?.incomes.map(income => {
                return (
                  <Wrap key={income.paymentMethod} spacing={0}>
                    <Text flex={1} fontSize="10px" color="black">
                      {paymentMethods?.find(
                        paymentMethod =>
                          paymentMethod.slug === income.paymentMethod
                      )?.title ?? ""}
                    </Text>
                    <Text fontSize="10px" color="black">
                      {formatPrice(income.total)}
                    </Text>
                  </Wrap>
                );
              })}
          </Box>
          <Divider my={2} />
          <Box>
            <Text fontSize="12px" fontWeight={700} color="black">
              Saídas:
            </Text>
            {!cashFlowResume?.expenses || !cashFlowResume?.expenses.length ? (
              <Text flex={1} fontSize="10px" color="black" mt={2}>
                Nenhuma despesa vinculada.
              </Text>
            ) : null}
            {!cashFlowResume?.expenses ||
              cashFlowResume?.expenses.map(expense => {
                return (
                  <Wrap
                    key={`${expense.financialAccount}${Math.random()}`}
                    spacing={0}
                  >
                    <Text flex={1} fontSize="10px" color="black">
                      {expense.type === "expense" ? "Despesas" : "Sangria"}
                      {" - "}
                      {expense.financialAccountType === "cash"
                        ? "Dinheiro"
                        : expense.financialAccount}
                    </Text>
                    <Text fontSize="10px" color="black">
                      {formatPrice(expense.total)}
                    </Text>
                  </Wrap>
                );
              })}
          </Box>
          <Divider my={2} />
          <Box>
            <Text fontSize="12px" fontWeight={700} color="black">
              Resumo do caixa:
            </Text>
            <Wrap>
              <Text flex={1} fontSize="10px" color="text">
                Fundo de caixa
              </Text>
              <Text fontSize="10px" color="black">
                {formatPrice(cashFlowResume?.initMoney ?? 0)}
              </Text>
            </Wrap>
            <Wrap>
              <Text flex={1} fontSize="10px" color="text">
                Entrada espécie
              </Text>
              <Text fontSize="10px" color="black">
                {formatPrice(
                  (cashFlowResume as CashFlow)?.finishIncomesMoney ??
                    (cashFlowResume as CashFlowResume)?.incomesMoney ??
                    0
                )}
              </Text>
            </Wrap>
            <Wrap>
              <Text flex={1} fontSize="10px" color="text">
                Sangria/Despesas
              </Text>
              <Text fontSize="10px" color="black">
                {formatPrice(
                  (cashFlowResume as CashFlow)?.finishExpensesMoney ??
                    (cashFlowResume as CashFlowResume)?.expensesMoney ??
                    0
                )}
              </Text>
            </Wrap>
            <Wrap>
              <Text flex={1} fontSize="10px" color="black" fontWeight={700}>
                Saldo de fechamento
              </Text>
              <Text fontSize="10px" color="black" fontWeight={700}>
                {formatPrice(
                  cashflowClosedMoney ??
                    (cashFlowResume as CashFlow).finishMoney ??
                    0
                )}
              </Text>
            </Wrap>
          </Box>
        </Box>
      </Box>
    );
  }, [cashFlowResume, cashflowClosedMoney]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (onAfterPrint) onAfterPrint();
    },
  });

  return { PrintResumeCashFlowComponent, handlePrint: () => handlePrint() };
};
