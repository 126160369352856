import React from "react";

import { useDashboard } from "src/hooks/dashboard/useDashboard";
import { formatPrice } from "src/utils/format";

import DashboardCard from "../DashboardCard";
import * as Styled from "./styles";

const FinancialData = () => {
  const { dashboardSales, status } = useDashboard();

  return (
    <Styled.BoxGrid>
      <DashboardCard
        title="Clientes atendidos"
        value={String(dashboardSales?.customersTotal ?? 0)}
        percentage={dashboardSales.customersTotalPercentChange}
        type="income"
        loading={status.loading}
      />
      <DashboardCard
        title="Novos clientes"
        value={String(dashboardSales?.customersNewTotal ?? 0)}
        percentage={dashboardSales.customersNewTotalPercentChange}
        type="income"
        loading={status.loading}
      />
      <DashboardCard
        title="Ticket Médio"
        value={formatPrice(dashboardSales?.ticketTotalActual ?? 0)}
        percentage={dashboardSales.ticketTotalPercentChange}
        type="income"
        loading={status.loading}
      />
      <DashboardCard
        title="Vendas"
        value={formatPrice(dashboardSales?.salesTotalActual ?? 0)}
        percentage={dashboardSales.salesTotalPercentChange}
        type="income"
        loading={status.loading}
      />
    </Styled.BoxGrid>
  );
};

export default FinancialData;
