import React, { useState, createContext, useContext } from "react";

import { SelectedFilterType } from "src/components/PeriodFilterPicker/props";
import { BuyOrderStatusEnum } from "src/interfaces/buyOrder";

import { BuyOrderFiltersContextType } from "./props";

export const BuyOrderFiltersContext = createContext(
  {} as BuyOrderFiltersContextType
);

export const useBuyOrderFiltersProvider: React.FC = props => {
  const { children } = props;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<BuyOrderStatusEnum | "">("");

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [initialFilterType, setInitialFilterType] =
    useState<SelectedFilterType>("empty");

  const [restartOrderList, setRestartOrderList] = useState(false);

  return (
    <BuyOrderFiltersContext.Provider
      value={{
        page,
        setPage,
        limit,
        setLimit,
        search,
        setSearch,
        status,
        setStatus,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        restartOrderList,
        setRestartOrderList,
        initialFilterType,
        setInitialFilterType,
      }}
    >
      {children}
    </BuyOrderFiltersContext.Provider>
  );
};

export const useBuyOrderFilters = () => {
  const context = useContext(BuyOrderFiltersContext);

  if (!context) {
    throw new Error(
      "useBuyOrderFilters must be used within a useBuyOrderFiltersProvider"
    );
  }

  return context;
};
