import React, { FC, useState } from "react";

import Divider from "@material-ui/core/Divider";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Tabs from "src/components/Tabs";
import { ItemTabsProps } from "src/components/Tabs/props";
import { DashboardProvider } from "src/hooks/dashboard/useDashboard";
import { ReportProvider } from "src/hooks/report/useReport";
import { withContext } from "src/utils/withContext";

import ClientReportsTab from "./components/ClientReportsTab";
import FinacialReportsTab from "./components/FinancialReportsTab";
import OrderReportsTab from "./components/OrderReportsTab";
import * as Styled from "./styles";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [{ label: "Relatórios" }];

const TABS: Array<ItemTabsProps> = [
  { key: "financial", label: "Financeiro" },
  { key: "orders", label: "Vendas" },
  { key: "clients", label: "Clientes" },
];

const Reports: FC = () => {
  const [type, setType] = useState<"financial" | "orders" | "clients">(
    "financial"
  );

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />
      <PageTitle
        label={`Relatórios - ${TABS.find(tab => tab.key === type)?.label}`}
      />

      <Styled.Container>
        <Tabs
          tab={type}
          setTab={setType}
          ariaLabel="Relatórios"
          tabs={TABS}
          tabPanels={[
            <FinacialReportsTab key="financial" />,
            <OrderReportsTab key="orders" />,
            <ClientReportsTab key="clients" />,
          ]}
        />
      </Styled.Container>
    </DashboardLayout>
  );
};

export default withContext(Reports, ReportProvider, DashboardProvider);
