import { Flex, Text, Circle } from "@chakra-ui/react";

import { BuyOrderType } from "src/interfaces/buyOrder";
import { Event } from "src/interfaces/events";
import { OrderListType } from "src/interfaces/order";
import { RecordType } from "src/interfaces/record";
import { SchedulingType } from "src/interfaces/scheduling";
import { formatPrice } from "src/utils/format";

interface TypeEvent {
  event: Event;
}

const today = new Date();

export default function MonthEvent({ event }: TypeEvent) {
  const scheduling = event.model as SchedulingType;
  const record = event.model as RecordType;
  const oc = event.model as BuyOrderType;
  const order = event.model as OrderListType;

  function renderType() {
    if (event.start < today) {
      return <Circle mt="2px" size={2} bg="error.500" />;
    }

    return <Circle mt="2px" size={2} bg="primary.500" />;
  }

  function renderSubTitle() {
    if (event.type === "scheduling") {
      return event.eventHours;
    }
    if (event.type === "record") {
      return formatPrice(record.value);
    }
    if (event.type === "orders") {
      return order.contact?.name;
    }
    if (event.type === "buyOrder") {
      return oc.provider?.name;
    }
    return event.subTitle;
    // if (event.type === "scheduling") {
    //   return event.eventHours;
    // }
    // return event.subTitle;
  }

  function renderTitle() {
    // if (event.type === "scheduling") {
    //   if (event.start < today) {
    //     return <Text fontSize="xs">Compromisso atrasado</Text>;
    //   }
    //   return (
    //     <Text fontSize="xs">Compromisso em {format(event.start, "dd/mm")}</Text>
    //   );
    // }
    // if (record.type === "expense") {
    //   if (event.start < today) {
    //     return (
    //       <Text fontSize="xs">
    //         Pagamento atrasado desde {format(event.start, "dd/mm")}
    //       </Text>
    //     );
    //   }
    //   return (
    //     <Text fontSize="xs"> Pagamento em {format(event.start, "dd/mm")}</Text>
    //   );
    // }
    // if (record.type === "income") {
    //   if (event.start < today) {
    //     return (
    //       <Text fontSize="xs">
    //         Recebimento atrasado desde {format(event.start, "dd/mm")}
    //       </Text>
    //     );
    //   }
    //   return (
    //     <Text fontSize="xs">
    //       {" "}
    //       Recebimento em {format(event.start, "dd/mm")}
    //     </Text>
    //   );
    // }
    // if (event.type === "orders") {
    //   if (event.start < today) {
    //     return (
    //       <Text fontSize="xs">
    //         {" "}
    //         Entrega atrasado desde {format(event.start, "dd/mm")}
    //       </Text>
    //     );
    //   }
    //   return (
    //     <Text fontSize="xs">Entrega em {format(event.start, "dd/mm")}</Text>
    //   );
    // }
    // if (event.type === "buyOrder") {
    //   if (event.start < today) {
    //     return (
    //       <Text fontSize="xs">
    //         {" "}
    //         Entrega atrasado desde {format(event.start, "dd/mm")}
    //       </Text>
    //     );
    //   }
    //   return (
    //     <Text fontSize="xs">Entrega em {format(event.start, "dd/mm")}</Text>
    //   );
    // }

    if (scheduling.isDoneAt) {
      return (
        <Text fontSize="xs" as="del">
          {event.title}
        </Text>
      );
    }
    return (
      <Text lineHeight={1} fontSize="xs">
        {event.title}
      </Text>
    );
  }

  return (
    <Flex gap={1}>
      {renderType()}
      <Flex flex={1} gap={1} direction="column">
        {renderTitle()}
        <Text
          lineHeight={1}
          textOverflow="ellipsis"
          overflow="hidden"
          noOfLines={1}
          fontSize="xs"
          color="gray"
        >
          {renderSubTitle()}
        </Text>
      </Flex>
    </Flex>
  );
}
