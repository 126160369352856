/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";

import {
  Flex,
  Text,
  Box,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
} from "@chakra-ui/react";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { format, isSameDay } from "date-fns";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { BuyOrderType } from "src/interfaces/buyOrder";
import { Event } from "src/interfaces/events";
import { OrderListType } from "src/interfaces/order";
import { RecordType } from "src/interfaces/record";
import { SchedulingType } from "src/interfaces/scheduling";
import { formatPrice } from "src/utils/format";

interface IDrawerDetailDay {
  selectDay: Date;
  event: Event[];
  handleSelected?: any;
}

const today = new Date();

export default function DrawerDetailDay({
  selectDay,
  event,
  handleSelected,
}: IDrawerDetailDay) {
  const dataEvent = format(selectDay, "dd / MMM");

  const [hasSchedule, setHasSchedule] = useState(false);
  const [hasPay, setHaspay] = useState(false);
  const [hasOrder, setHasOrder] = useState(false);
  const [hasBuyOrder, setHasBuyOrder] = useState(false);

  const eventDate = event.filter(event => isSameDay(event.start, selectDay));
  const orderType = eventDate.filter(o => o.type == "orders");
  const recordType = eventDate.filter(r => r.type == "record");
  const buyOrderType = eventDate.filter(bo => bo.type == "buyOrder");

  const { handleClose } = useBaseSwipeableDrawer();

  const customHeader = (
    <Flex alignItems="center" gap={2}>
      <Flex h="2" w="2" bg="primary.500" borderRadius="full" />
      <Text fontSize="lg" color="primary.900">
        {dataEvent}
      </Text>
    </Flex>
  );
  const customActions = <></>;

  const handleCustomClose = () => {
    handleClose("DetailDay");
  };

  function deadlineToday(deadline: string) {
    if (new Date(deadline) > new Date()) {
      return (
        <Text color="text" fontWeight="semibold">
          Entrega não finalizada
        </Text>
      );
    }
    return (
      <Text color="text" fontWeight="semibold">
        Entrega atrasada
      </Text>
    );
  }

  function renderSchedules() {
    return hasSchedule ? (
      <Accordion allowMultiple defaultIndex={[0]} borderColor="white">
        <AccordionItem>
          <AccordionButton h="4" _hover={{ bg: "white" }} px={0}>
            <Box as="span" textAlign="left" color="primary.500">
              Compromissos
            </Box>
            <Box flex="1" bg="primary.500" h="0.5" ml={2} />
            <AccordionIcon color="primary.500" />
          </AccordionButton>

          <AccordionPanel pb={4}>
            {eventDate.length ? (
              eventDate.map(event => {
                if (event.type == "scheduling") {
                  const schedule = event.model as SchedulingType;
                  return (
                    <Flex key={event.model.id} direction="column" mt="1.5">
                      <Box
                        p="1"
                        h="14"
                        borderLeftWidth="initial"
                        borderLeftColor={
                          event.start < today ? "error.500" : "primary.500"
                        }
                        bg="background"
                        borderRadius="base"
                        cursor="pointer"
                        onClick={() => {
                          handleClose("DetailDay");
                          handleSelected(event);
                        }}
                      >
                        <Flex justifyContent="space-between" w="100%">
                          <Text color="text" fontWeight="semibold">
                            {event.title}
                          </Text>
                          <Text fontSize="xs" color="gray.500">
                            {event.eventHours}
                          </Text>
                        </Flex>
                        <Flex justifyContent="space-between" w="100%">
                          <Text
                            fontSize="xs"
                            lineHeight={1}
                            textOverflow="ellipsis"
                            noOfLines={1}
                            color="gray"
                            pt={1}
                          >
                            {schedule.description}
                          </Text>
                          <Text>
                            <Icon
                              as={ChevronRight}
                              color="primary.500"
                              fontSize="24px"
                            />
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  );
                }
                return null;
              })
            ) : (
              <Text>sem Compromisso</Text>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    ) : (
      ""
    );
  }

  function renderPay() {
    return hasPay ? (
      <Accordion allowMultiple defaultIndex={[0]} borderColor="white">
        <AccordionItem>
          <AccordionButton h="4" _hover={{ bg: "white" }} px={0}>
            <Box as="span" textAlign="left" color="primary.500">
              Contas a pagar
            </Box>
            <Box flex="1" bg="primary.500" h="0.5" ml={2} />
            <AccordionIcon color="primary.500" />
          </AccordionButton>

          <AccordionPanel pb={4}>
            {recordType.map(recordType => {
              const record = recordType.model as RecordType;
              return (
                <Flex key={record.id} direction="column" mt="1.5">
                  <Box
                    p="1"
                    h="14"
                    borderLeftWidth="initial"
                    borderLeftColor={
                      recordType.start < today ? "error.500" : "primary.500"
                    }
                    bg="background"
                    borderRadius="base"
                    cursor="pointer"
                    onClick={() => {
                      handleClose("DetailDay");
                      handleSelected(recordType);
                    }}
                  >
                    <Flex justifyContent="space-between" w="100%">
                      <Text color="text" fontWeight="semibold">
                        {record.type === "expense"
                          ? "pagar conta"
                          : "receber pagamento"}
                      </Text>
                      <Text fontSize="sm" color="gray">
                        {format(new Date(record.paidDate), "dd / MM")}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" w="100%">
                      <Text fontSize="xs" color="gray.500" pt={1}>
                        {formatPrice(record.value)}
                      </Text>
                      <Icon
                        as={ChevronRight}
                        color="primary.500"
                        fontSize="24px"
                      />
                    </Flex>
                  </Box>
                </Flex>
              );
            })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    ) : (
      ""
    );
  }

  function renderOrder() {
    return hasOrder ? (
      <Accordion allowMultiple defaultIndex={[0]} borderColor="white">
        <AccordionItem>
          <AccordionButton h="4" _hover={{ bg: "white" }} px={0}>
            <Box as="span" textAlign="left" color="primary.500">
              Entregas
            </Box>
            <Box flex="1" bg="primary.500" h="0.5" ml={2} />
            <AccordionIcon color="primary.500" />
          </AccordionButton>

          <AccordionPanel pb={4}>
            {orderType.map(orderType => {
              const order = orderType.model as OrderListType;
              return (
                <Flex key={order.id} direction="column" mt="1.5">
                  <Box
                    p="1"
                    h="14"
                    borderLeftWidth="initial"
                    borderLeftColor={
                      orderType.start < today ? "error.500" : "primary.500"
                    }
                    bg="background"
                    borderRadius="base"
                    cursor="pointer"
                    onClick={() => {
                      handleClose("DetailDay");
                      handleSelected(orderType);
                    }}
                  >
                    <Flex justifyContent="space-between" w="100%">
                      {deadlineToday(order.deadline)}
                      <Text fontSize="xs" color="gray">
                        {format(new Date(order.deadline), "dd / MM")}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" w="100%">
                      <Text fontSize="xs" pt={1}>
                        Data estabelecida:&nbsp;
                        <Text as="span" fontSize="xs" color="gray">
                          {format(new Date(order.deadline), "dd / MM")}
                        </Text>
                      </Text>
                      <Icon
                        as={ChevronRight}
                        color="primary.500"
                        fontSize="24px"
                      />
                    </Flex>
                  </Box>
                </Flex>
              );
            })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    ) : (
      ""
    );
  }

  function renderBuyOrder() {
    return hasBuyOrder ? (
      <Accordion allowMultiple defaultIndex={[0]} borderColor="white">
        <AccordionItem>
          <AccordionButton h="4" _hover={{ bg: "white" }} px={0}>
            <Box as="span" textAlign="left" color="primary.500">
              Ordem de Compra
            </Box>
            <Box flex="1" bg="primary.500" h="0.5" ml={2} />
            <AccordionIcon color="primary.500" />
          </AccordionButton>

          <AccordionPanel pb={4}>
            {buyOrderType.map(buyorderType => {
              const buyorder = buyorderType.model as BuyOrderType;
              return (
                <Flex key={buyorder.id} direction="column" mt="1.5">
                  <Box
                    p="1"
                    h="14"
                    borderLeftWidth="initial"
                    borderLeftColor={
                      buyorderType.start < today ? "error.500" : "primary.500"
                    }
                    bg="background"
                    borderRadius="base"
                    cursor="pointer"
                    onClick={() => {
                      handleClose("DetailDay");
                      handleSelected(buyorderType);
                    }}
                  >
                    <Flex justifyContent="space-between" w="100%">
                      {deadlineToday(buyorder.deadline as string)}
                      <Text fontSize="xs" color="gray">
                        {format(
                          new Date(buyorder.deadline as string),
                          "dd / MM"
                        )}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" w="100%">
                      <Text fontSize="xs" pt={1}>
                        Data estabelecida:&nbsp;
                        <Text as="span" fontSize="xs" color="gray">
                          {format(
                            new Date(buyorder.deadline as string),
                            "dd / MM"
                          )}
                        </Text>
                      </Text>
                      <Icon
                        as={ChevronRight}
                        color="primary.500"
                        fontSize="24px"
                      />
                    </Flex>
                  </Box>
                </Flex>
              );
            })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    ) : (
      ""
    );
  }

  useEffect(() => {
    setHaspay(false);
    setHasOrder(false);
    setHasBuyOrder(false);
    setHasSchedule(false);
    eventDate.map(event => {
      if (event.type === "scheduling") {
        setHasSchedule(true);
      }
      if (event.type === "record") {
        setHaspay(true);
      }

      if (event.type === "orders") {
        setHasOrder(true);
      }
      if (event.type === "buyOrder") {
        setHasBuyOrder(true);
      }
    });
  }, [eventDate]);

  return (
    <BaseSwipeableDrawer
      tag="DetailDay"
      customHeader={customHeader}
      customActions={customActions}
      buttonProps={{
        form: "AddCompromisse",
      }}
      zIndex={1302}
      customHandleClose={handleCustomClose}
    >
      <Text color="gray" fontSize="small">
        Visualize abaixo a lista dos compromissos de hoje
      </Text>
      <Divider />
      <Flex
        w={["100%", "350px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="AddCompromisse"
        gap={4}
        direction="column"
      >
        <Text>Lista de compromissos</Text>
        <Flex direction="column" gap={4}>
          {renderSchedules()}
          {renderPay()}
          {renderOrder()}
          {renderBuyOrder()}
        </Flex>
      </Flex>
    </BaseSwipeableDrawer>
  );
}
