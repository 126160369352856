import {
  ListSchedulingsParamType,
  SchedulingDoneParamType,
  SchedulingType,
} from "src/interfaces/scheduling";
import api from "src/services/api";

/**
 * Listar agendamentos
 */
export const getSchedulings = (params?: ListSchedulingsParamType) =>
  api
    .get("v1/scheduling", {
      params,
    })
    .then(res => res.data);

/**
 * Criar agendamento
 */
export const createScheduling = (params: SchedulingType) =>
  api.post(`v1/scheduling`, params).then(res => res.data);

/**
 * Encontrar agendamento pelo Id
 */
export const getScheduling = (id: string) =>
  api.get(`v1/scheduling/${id}`).then(res => res.data);

/**
 * Atualizar agendamento
 */
export const updateScheduling = (params: SchedulingType) =>
  api.put(`v1/scheduling`, params).then(res => res.data);

/**
 * Marcar agendamento como concluído
 */
export const updateSchedulingDone = (params: SchedulingDoneParamType) =>
  api.put(`v1/scheduling/done`, params).then(res => res.data);

/**
 * Remover módulo
 */
export const deleteScheduling = (id: string) =>
  api
    .delete(`v1/scheduling`, {
      params: {
        id,
      },
    })
    .then(res => res.data);
