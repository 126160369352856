import * as yup from "yup";

import { ProductType } from "src/interfaces/product";
import { ServiceType } from "src/interfaces/service";

export const orderItemsModalSchema = yup.object({
  type: yup.string(),
  products: yup.array().of(
    yup.object({
      id: yup.string(),
      productId: yup.string(),
      product: yup.mixed<ProductType>(),
      stock: yup.string(),
      quantity: yup
        .number()
        .typeError("Quantidade é obrigatório")
        .test("is-number", "Valor é obrigatório", value => value !== undefined),
      price: yup
        .number()
        .typeError("Preço é obrigatório")
        .test("is-number", "Valor é obrigatório", value => value !== undefined),
      delete: yup.boolean(),
    })
  ),
  serviceIsRequired: yup.bool(),
  services: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        serviceId: yup.string(),
        service: yup.mixed<ServiceType>(),
        quantity: yup
          .number()
          .typeError("Quantidade é obrigatório")
          .test(
            "is-number",
            "Valor é obrigatório",
            value => value !== undefined
          ),
        price: yup
          .number()
          .typeError("Preço é obrigatório")
          .test(
            "is-number",
            "Valor é obrigatório",
            value => value !== undefined
          ),
        delete: yup.boolean(),
      })
    )
    .when(["serviceIsRequired", "type"], {
      is: (serviceIsRequired: boolean, type: string) =>
        serviceIsRequired && type === "serviceOrder",
      then: schema => schema.min(1, "Adicione no mínimo um serviço!"),
      otherwise: schema => schema.min(0),
    }),
});
