import { useStorage } from "src/hooks/storage/useStorage";

interface DocumentConfigurationItemType {
  title: string;
  description: string;
}

interface DocumentConfigurationType {
  serviceOrder: DocumentConfigurationItemType;
  serviceOrderBudget: DocumentConfigurationItemType;
  productOrder: DocumentConfigurationItemType;
  productOrderBudget: DocumentConfigurationItemType;
}

export const DOCUMENT_CONFIGURATION_STORAGE_KEY =
  "@DOCUMENT_CONFIGURATION_STORAGE";

export const DOCUMENT_CONFIGURATION_STORAGE: DocumentConfigurationType = {
  serviceOrder: {
    title: "",
    description: "",
  },
  serviceOrderBudget: {
    title: "",
    description: "",
  },
  productOrder: {
    title: "",
    description: "",
  },
  productOrderBudget: {
    title: "",
    description: "",
  },
};

export type DocumentConfigurationKeys = keyof DocumentConfigurationType;

export const saveConfigurationDocument = (
  key: DocumentConfigurationKeys,
  data: DocumentConfigurationItemType
) => {
  const { setItem, getItem } = useStorage();
  const configurationStorage = getItem(
    DOCUMENT_CONFIGURATION_STORAGE_KEY,
    DOCUMENT_CONFIGURATION_STORAGE
  ) as DocumentConfigurationType;

  const newConfigurationDocumentStorage = {
    ...configurationStorage,
    [key]: data,
  };

  setItem(DOCUMENT_CONFIGURATION_STORAGE_KEY, newConfigurationDocumentStorage);
};

export const getConfigurationDocument = () => {
  const { getItem } = useStorage();
  const configurationStorage = getItem(
    DOCUMENT_CONFIGURATION_STORAGE_KEY,
    DOCUMENT_CONFIGURATION_STORAGE
  ) as DocumentConfigurationType;

  return configurationStorage;
};
