import React from "react";

import Switch from "@material-ui/core/Switch";
import { useFormikContext } from "formik";

import Typography from "src/components/Typography";
import { DocumentConfigurationType } from "src/interfaces/documentConfiguration";

import { DocumentConfigurationOptionProps } from "./props";
import * as Styled from "./styles";

const DocumentConfigurationOption: React.FC<DocumentConfigurationOptionProps> =
  props => {
    const { name, label } = props;

    const { values, setFieldValue } =
      useFormikContext<DocumentConfigurationType>();

    const handleChange = () => {
      setFieldValue(name, !values[name as keyof DocumentConfigurationType]);
    };

    return (
      <Styled.DocumentConfigurationOptionContainer>
        <Typography variant="body2">{label}</Typography>
        <Switch
          checked={!!values[name as keyof DocumentConfigurationType]}
          onChange={handleChange}
          name={name}
          color="primary"
          inputProps={{ "aria-label": label }}
        />
      </Styled.DocumentConfigurationOptionContainer>
    );
  };

export default DocumentConfigurationOption;
