import { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";

import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { Input } from "src/components/NewComponents/Input";
import { Textarea } from "src/components/NewComponents/TextArea";
import { useCompany } from "src/hooks/company/useCompany";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";
import { uppercaseFirstLetter } from "src/utils/format";

import { ConfirmDeleteImage } from "./components/ConfirmDeleteImage";
import { ConfirmDeleteTechnicalReport } from "./components/ConfirmDeleteTechnicalReport";
import TechnicalReportImageForm, {
  TechnicalReportImageFormSchema,
} from "./components/TechnicalReportImageForm";

export type TechnicalReportForm = {
  gallery: TechnicalReportImageFormSchema[];
  data: { [key: string]: any };
};

function TechnicalReport() {
  const { handleClose, handleOpen } = useBaseSwipeableDrawer();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenDeleteTechnicalReport,
    onClose: onCloseDeleteTechnicalReport,
    onOpen: onOpenDeleteTechnicalReport,
  } = useDisclosure();

  const { methodsOrderData, orderCreated, currentStep, handleToNextStep } =
    useOrderForm();

  const [technicalReportImage, setTechnicalReportImage] = useState<
    TechnicalReportImageFormSchema | undefined
  >(undefined);

  const { userCompany } = useCompany();

  const defaultValues = {
    data:
      userCompany?.technicalReportFields.reduce(
        (obj, current) => ({ ...obj, [current.id as string]: "" }),
        {}
      ) ?? {},
    gallery: [],
  };

  const methods = useForm<TechnicalReportForm>({
    defaultValues,
  });

  const { handleSubmit, register, control, reset } = methods;

  const {
    fields: gallery,
    append: appendItem,
    update: updateItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: "gallery",
    keyName: "gallery",
  });

  useEffect(() => {
    if (orderCreated?.id) {
      reset(defaultValues);
    }
  }, [orderCreated]);

  const handleCustomClose = () => {
    handleClose("technicalReport");
    if (currentStep === 0) {
      handleToNextStep();
    }
  };

  const onSubmit = (data: TechnicalReportForm) => {
    methodsOrderData.setValue("technicalReport", {
      gallery: data.gallery,
      data: Object.entries(data?.data ?? {}).map(
        ([key, value]: [string, string]) => {
          const field = userCompany?.technicalReportFields.find(
            field => field.id === key
          );

          return {
            content: value,
            label: field?.label ?? "",
            type: field?.type ?? "",
          };
        }
      ),
    });
    handleCustomClose();
  };

  const baseActions = useMemo(() => {
    return (
      <>
        <Box width="100%" mb={2}>
          {methodsOrderData.watch("technicalReport") ? (
            <Button
              w="100%"
              variant="outline"
              colorScheme="error"
              mb={3}
              onClick={onOpenDeleteTechnicalReport}
            >
              Excluir laudo
            </Button>
          ) : null}

          <ConfirmDeleteTechnicalReport
            isOpen={isOpenDeleteTechnicalReport}
            onClose={onCloseDeleteTechnicalReport}
            onConfirm={() => {
              reset(defaultValues);
              onCloseDeleteTechnicalReport();
              methodsOrderData.setValue("technicalReport", undefined);
              handleClose("technicalReport");
              if (currentStep === 0) {
                handleToNextStep();
              }
            }}
          />
          <Button w="100%" type="submit" form="technicalReportForm">
            Salvar laudo
          </Button>
        </Box>
      </>
    );
  }, [isOpenDeleteTechnicalReport, defaultValues]);

  return (
    <BaseSwipeableDrawer
      tag="technicalReport"
      title="Laudo Técnico"
      customActions={baseActions}
      customHandleClose={handleCustomClose}
    >
      <FormProvider {...methods}>
        <Flex
          w={["100%", "350px"]}
          bg="white"
          borderRadius={10}
          border="solid 1px gray-200"
          as="form"
          id="technicalReportForm"
          onSubmit={handleSubmit(onSubmit)}
          direction="column"
        >
          <Text fontSize="xs" fontWeight={400} color="primary.500" mb={2}>
            Galeria de imagens
          </Text>
          <SimpleGrid columns={3} gap={3} mb={5}>
            {gallery.map(item => {
              return (
                <Box key={`${item.index}${item.name}`}>
                  <Flex
                    h="110px"
                    w="100%"
                    borderRadius={10}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    position="relative"
                  >
                    <HStack
                      zIndex={1}
                      position="absolute"
                      w="100%"
                      h="100%"
                      justifyContent="center"
                      _hover={{
                        display: "flex",
                        bgGradient:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))",
                        transition: "0.5s ease",

                        button: {
                          display: "flex",
                        },
                      }}
                    >
                      <IconButton
                        aria-label="Excluir imagem"
                        borderRadius="full"
                        bg="white"
                        _hover={{ bg: "secondary.50" }}
                        _active={{ bg: "secondary.20" }}
                        size="md"
                        display="none"
                        onClick={() => {
                          setTechnicalReportImage(item);
                          onOpen();
                        }}
                      >
                        <Icon
                          as={DeleteIcon}
                          color="primary.500"
                          fontSize="24px"
                        />
                      </IconButton>
                      <IconButton
                        aria-label="Editar imagem"
                        borderRadius="full"
                        bg="white"
                        _hover={{ bg: "secondary.50" }}
                        _active={{ bg: "secondary.20" }}
                        size="md"
                        display="none"
                        onClick={() => {
                          setTechnicalReportImage(item);
                          handleOpen("technicalReportImage");
                        }}
                      >
                        <Icon
                          as={CreateIcon}
                          color="primary.500"
                          fontSize="24px"
                        />
                      </IconButton>
                    </HStack>
                    <Image
                      w="100%"
                      h="100%"
                      objectFit="cover"
                      borderRadius={10}
                      src={URL.createObjectURL(item.image)}
                    />
                  </Flex>
                  <Text fontSize="xs" color="text" mt={1}>
                    {item.name}
                  </Text>
                </Box>
              );
            })}
            <Button
              variant="ghost"
              colorScheme="secondary"
              bg="secondary.100"
              p={3}
              h="110px"
              w="100%"
              onClick={() => handleOpen("technicalReportImage")}
            >
              <Box>
                <Icon
                  as={AddPhotoAlternateIcon}
                  fontSize="32px"
                  color="primary.500"
                />
                <Text fontSize="xs" color="primary.500" textAlign="center">
                  Adicionar
                </Text>
              </Box>
            </Button>
          </SimpleGrid>

          <Text fontSize="xs" fontWeight={400} color="primary.500" mb={2}>
            Informações do laudo
          </Text>

          <Flex flexDirection="column" gap={4}>
            {userCompany?.technicalReportFields.map(field => {
              const name = field.id as string;
              if (field.type === "text") {
                return (
                  <Input
                    label={uppercaseFirstLetter(field.label)}
                    {...register(`data.${name}`)}
                  />
                );
              }
              if (field.type === "textarea") {
                return (
                  <Textarea
                    label={uppercaseFirstLetter(field.label)}
                    {...register(`data.${name}`)}
                  />
                );
              }
              return null;
            })}
          </Flex>
        </Flex>
      </FormProvider>
      <ConfirmDeleteImage
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => {
          removeItem(technicalReportImage?.index as number);
          onClose();
        }}
      />
      <TechnicalReportImageForm
        technicalReportImage={technicalReportImage}
        setItem={data => {
          if (data.index === undefined) {
            appendItem({
              ...data,
              index: gallery.length,
            });
          } else {
            updateItem(data.index, data);
          }
        }}
        clearTechnicalReportImage={() => {
          setTechnicalReportImage(undefined);
        }}
        removeItem={index => {
          removeItem(index);
        }}
      />
    </BaseSwipeableDrawer>
  );
}

export default TechnicalReport;
