import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import BottomBar from "src/components/Layouts/BottomBar";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import PATHS from "src/constants/paths";
import { useCreateUser } from "src/services/hooks/user/useCreateUser";

import UserForm from "../components/UserForm";

function CreateUserPage() {
  const navigate = useNavigate();

  const { mutate: createUser, isSuccess, isLoading } = useCreateUser();

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
    { label: "Configurações", path: PATHS.SETTINGS },
    { label: "Novo usuário" },
  ];

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  return (
    <DashboardLayout selected="settings">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />
      <PageTitle label="Novo usuário" />

      <UserForm method="add" createUser={createUser} />

      <BottomBar>
        <Button
          size="md"
          type="submit"
          form="userForm"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Criar
        </Button>

        <Box width={20} />

        <Button size="md" variant="outline" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
      </BottomBar>
    </DashboardLayout>
  );
}

export default CreateUserPage;
