import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { GridCellParams } from "@material-ui/data-grid";

import { getRouteString } from "src/utils/urlHandler";

import { TableProps } from "./props";
import * as Styled from "./styles";

const ROWS_PAGE_SIZE_CHANGE: Array<number> = [10, 25, 50];

const Table: React.FC<TableProps> = props => {
  const {
    page,
    setPage,
    pageSize,
    onPageSizeChange,
    totalCount,
    detailLink,
    detailLinkParamName = "id",
    excludeCellClick = ["options"],
    ...rest
  } = props;

  const navigate = useNavigate();

  const pageTable = useMemo(() => Number(page) - 1, [page]);

  const onPageChange = (page: number) => {
    setPage(page + 1);
  };

  const handleOnCellClick = (params: GridCellParams) => {
    if (!excludeCellClick.includes(params.field) && detailLink) {
      navigate(getRouteString(detailLink, params.id, detailLinkParamName), {
        state: {
          data: params?.row,
        },
      });
    }
  };

  return (
    <Styled.ContainerTable>
      <Styled.DataGrid
        rowCount={totalCount}
        // Behaviors
        paginationMode="server"
        checkboxSelection={false}
        disableSelectionOnClick
        disableColumnSelector
        disableColumnMenu
        hideFooterSelectedRowCount
        autoHeight
        onCellClick={handleOnCellClick}
        // Page
        page={pageTable}
        onPageChange={onPageChange}
        // Page Size
        pageSize={pageSize}
        rowsPerPageOptions={ROWS_PAGE_SIZE_CHANGE}
        onPageSizeChange={onPageSizeChange}
        {...rest}
      />
    </Styled.ContainerTable>
  );
};

export default Table;
