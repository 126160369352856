import { useMemo } from "react";

import { Box, Button, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import NoteAdd from "@material-ui/icons/NoteAdd";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";

function TechnicalReportInfo() {
  const { handleToNextStep } = useOrderForm();
  const { handleClose, handleOpen } = useBaseSwipeableDrawer();

  const handleCustomClose = () => {
    handleToNextStep();
    handleClose("technicalReportInfo");
  };

  const baseActions = useMemo(() => {
    return (
      <>
        <Box width="100%" mb={2}>
          <Button
            variant="outline"
            w="100%"
            onClick={() => {
              handleCustomClose();
            }}
          >
            Adicionar depois
          </Button>
        </Box>
      </>
    );
  }, []);

  return (
    <BaseSwipeableDrawer
      tag="technicalReportInfo"
      title="Laudo Técnico"
      customActions={baseActions}
      customHandleClose={handleCustomClose}
    >
      <Flex
        w={["100%", "350px"]}
        bg="white"
        borderRadius={10}
        border="solid 1px gray-200"
        direction="column"
      >
        <Text fontSize="sm" opacity=".7" fontWeight={500}>
          Percebemos que você adicionou serviços, para um melhor detalhamento de
          informações para seu controle, Deseja adicionar o laudo técnico dos
          serviços?
        </Text>
        <Divider my={4} />
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg="white"
          borderRadius={10}
          p={4}
          mb={6}
        >
          <Icon as={NoteAdd} fontSize="56px" mb={2} color="#E3E1E1" />
          <Text fontWeight={400} color="primary.500">
            Nenhum Laudo adicionado
          </Text>
          <Text fontSize="xs" fontWeight={400} opacity=".7" mb={4}>
            Adicione laudo a ordem de serviço!
          </Text>
          <Button
            onClick={() => {
              handleClose("technicalReportInfo");
              handleOpen("technicalReport");
            }}
            size="sm"
          >
            Adicionar laudo
          </Button>
        </Flex>
      </Flex>
    </BaseSwipeableDrawer>
  );
}

export default TechnicalReportInfo;
