import { CompanyType } from "src/interfaces/company";
import api from "src/services/api";

import { CompanySettings } from "../interfaces/company";

/**
 * Atualiza dados da empresa do usuário
 */
export const updateUserCompany = (params: Partial<CompanyType>) =>
  api.put("v1/user/company", params).then(res => res.data);

/**
 * Atualiza configuração de condições de pagamento padrão
 */
export const updatePaymentTermsService = (params: Partial<CompanySettings>) =>
  api.patch("v1/company/update-settings", params).then(res => res.data);
