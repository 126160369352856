import { useForm } from "react-hook-form";

import { Flex } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { add, startOfDay } from "date-fns";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { DatePicker } from "src/components/NewComponents/DatePicker";
import { Input } from "src/components/NewComponents/Input";
import { Textarea } from "src/components/NewComponents/TextArea";
import { TimePicker } from "src/components/NewComponents/TimePicker";
import { useSchedulingCreate } from "src/hooks/scheduling/create/useSchedulingCreate";
import { formatDateToISO } from "src/utils/date";

import { schemaForm } from "./schema";

export type RecordFormSchema = yup.InferType<typeof schemaForm>;

export default function DrawerAddCompromisse() {
  const { handleClose } = useBaseSwipeableDrawer();
  const { create } = useSchedulingCreate();

  const defaultValues: RecordFormSchema = {
    title: "",
    place: "",
    description: "",
    data: new Date().toISOString(),
    hours: new Date().toISOString(),
  };

  const methods = useForm<RecordFormSchema>({
    defaultValues,
    resolver: yupResolver(schemaForm),
  });

  const {
    register,
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = methods;

  const handleCustomClose = () => {
    reset(defaultValues);
    handleClose("AddCompromisseHome");
  };

  const onSubmit = async (data: RecordFormSchema) => {
    const startDayOfDate = startOfDay(new Date(data.data));

    const hoursToAdd = new Date(data.hours).getHours();

    const minutesToAdd = new Date(data.hours).getMinutes();

    const startDate = add(startDayOfDate, {
      hours: hoursToAdd,
      minutes: minutesToAdd,
    });

    create({
      title: data.title,
      place: data?.place,
      startDate: formatDateToISO(startDate),
      description: data?.description,
    });
    reset(defaultValues);
    handleClose("AddCompromisseHome");
  };

  return (
    <BaseSwipeableDrawer
      tag="AddCompromisseHome"
      title="Novo Compromisso"
      buttonTitle="Salvar"
      buttonProps={{
        form: "addCompromisseHome",
      }}
      zIndex={1302}
      customHandleClose={handleCustomClose}
    >
      <Flex
        w={["100%", "350px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="addCompromisseHome"
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
        direction="column"
      >
        <Input label="Título" error={errors.title} {...register("title")} />
        <Input label="Local" error={errors.place} {...register("place")} />
        <Textarea
          label="Descrição"
          resize="none"
          error={errors.description}
          {...register("description")}
        />
        <Flex gap={2}>
          <DatePicker
            label="Data"
            disablePast
            error={errors.data}
            control={control}
            {...register("data")}
          />
          <TimePicker
            label="Hora"
            error={errors.hours}
            control={control}
            {...register("hours")}
          />
        </Flex>
      </Flex>
    </BaseSwipeableDrawer>
  );
}
