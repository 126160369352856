import * as XLSX from "xlsx";

export const generateXLSX = async (
  header: string[],
  data: string[][],
  title: string,
  fileName: string
) => {
  const workBook = XLSX.utils.book_new();

  const rows = [header, ...data];

  const workSheet = XLSX.utils.aoa_to_sheet(rows);
  XLSX.utils.book_append_sheet(workBook, workSheet, title);
  await XLSX.writeFile(workBook, fileName);
};
