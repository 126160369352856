import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface DeleteModuleCompanyType {
  id: string;
}

export const DeleteModuleCompany = (params: DeleteModuleCompanyType) =>
  api.delete("/v1/module/unsubscribe", { data: params }).then(res => res.data);

export function useDeleteModuleCompany(
  config?: UseMutationOptions<any, any, DeleteModuleCompanyType, unknown>
) {
  return useMutation<any, any, DeleteModuleCompanyType>(
    (data: DeleteModuleCompanyType) => DeleteModuleCompany(data),
    {
      onError: ({ response }) => {
        toast.error(response?.data?.message ?? "Ocorreu um problema");
      },
      ...config,
    }
  );
}
