import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { OrderForm } from "src/hooks/order/useOrderForm/props";
import { OrderType } from "src/interfaces/order";
import api from "src/services/api";

export const createOrder = (params: OrderForm) =>
  api.post<OrderType>(`v3/order`, params).then(res => res.data);

export function useCreateOrder(
  config?: UseMutationOptions<any, any, OrderForm, unknown>
) {
  return useMutation<any, any, OrderForm>(
    (data: OrderForm) => createOrder(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao pagar o lançamento"
        );
      },
      ...config,
    }
  );
}
