import React, { useRef } from "react";

import { Box, Divider } from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import NumberField from "src/components/Inputs/NumberField";
import SelectField from "src/components/Inputs/SelectField";
import Textarea from "src/components/Inputs/Textarea";
import TextField from "src/components/Inputs/TextField";
import Typography from "src/components/Typography";
import { MOVEMENT_TYPE } from "src/constants/movement";
import { useMovement } from "src/hooks/product/useMovement";
import { MovementFormType } from "src/interfaces/movement";
import { ProductType } from "src/interfaces/product";

import * as Styled from "./styles";

interface CreateMovementModalProps {
  product: ProductType;
  afterAction?: () => void;
}

const CreateMovementModal: React.FC<CreateMovementModalProps> = props => {
  const { product, afterAction } = props;

  const { createStatus, add } = useMovement();

  const { handleClose } = useBaseSwipeableDrawer();

  const formikRef = useRef<FormikProps<Partial<MovementFormType>>>(null);

  const submit = async (values: Partial<MovementFormType>) => {
    try {
      await add(values as MovementFormType);

      formikRef.current?.setValues({
        quantity: "" as unknown as number,
        reason: "",
        type: "input",
        source: "manual",
        productId: product.id,
      } as Partial<MovementFormType>);

      if (afterAction) {
        afterAction();
      }

      handleClose("createMovement");
      // eslint-disable-next-line no-empty
    } catch (_) {}
  };

  const customHandleClose = () => {
    if (createStatus.loading) return;
    handleClose("createMovement");
  };

  return (
    <BaseSwipeableDrawer
      tag="createMovement"
      title="Alterar quantidade"
      buttonTitle="Salvar"
      buttonProps={{
        loading: createStatus.loading,
        disabled: createStatus.loading,
        form: "createMovementForm",
      }}
      customHandleClose={customHandleClose}
    >
      <Formik
        initialValues={
          {
            source: "manual",
            type: "input",
            productId: product.id,
          } as Partial<MovementFormType>
        }
        onSubmit={submit}
        innerRef={formikRef}
        enableReinitialize
      >
        <Form id="createMovementForm">
          <Styled.Container>
            <Typography variant="body2" color="textSecondary">
              Selecione abaixo os produtos que você deseja colocar no catalogo
            </Typography>
            <Box width="100%" mb={2} mt={2}>
              <Divider />
            </Box>
            <Box width="100%" mb={2}>
              <TextField
                label="Produto"
                name="product"
                disableFormik
                value={product.name}
                disabled
              />
            </Box>
            <Box width="100%" mb={2}>
              <SelectField
                label="Tipo"
                name="type"
                items={MOVEMENT_TYPE}
                defaultValue=""
                backgroundwhite
                required
              />
            </Box>
            <Box width="100%" mb={2}>
              <NumberField
                decimalScale={product?.unitOfMeasure === "un" ? 0 : 2}
                label="Quantidade"
                name="quantity"
                required
                InputProps={{
                  endAdornment: (
                    <Styled.SuffixQuantityField
                      variant="subtitle2"
                      color="textPrimary"
                    >
                      {product?.unitOfMeasure?.toUpperCase()}
                    </Styled.SuffixQuantityField>
                  ),
                }}
              />
            </Box>
            <Box width="100%" mb={2}>
              <Textarea label="Observação" name="reason" rows={3} />
            </Box>
          </Styled.Container>
        </Form>
      </Formik>
    </BaseSwipeableDrawer>
  );
};

export default CreateMovementModal;
