import {
  FinancialContextTypeParams,
  FinancialOverviewTypeParams,
} from "src/hooks/financial/props";

import api from "./api";

/**
 * Listar extrato de contas
 */
export const getAccountStatement = (params: FinancialContextTypeParams) =>
  api
    .get("/v1/dashboard/account-statement", {
      params,
    })
    .then(res => res.data);

/**
 * Listar visão geral financeiro
 */
export const getFinancialOverview = (params: FinancialOverviewTypeParams) =>
  api
    .get("/v1/dashboard/overview", {
      params,
    })
    .then(res => res.data);
