import React from "react";

import { DialogContentProps, DialogTitleProps } from "@material-ui/core";
import { DrawerProps } from "@material-ui/core/Drawer";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

import Button from "../Button";
import { ButtonProps } from "../Button/props";
import * as Styled from "./styles";
import { useBaseSwipeableDrawer } from "./useBaseSwipeableDrawer";

export const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

interface BaseSwipeableDrawerProps extends Partial<DrawerProps> {
  tag: string;
  title?: string;
  customHeader?: React.ReactElement;
  buttonTitle?: string;
  buttonProps?: ButtonProps;
  customActions?: React.ReactElement;
  customHandleClose?(): void;
  zIndex?: number;
  dialogContentProps?: DialogContentProps;
  dialogHeaderProps?: DialogTitleProps;
}

const BaseSwipeableDrawer: React.FC<BaseSwipeableDrawerProps> = props => {
  const {
    children,
    tag,
    title,
    customHeader,
    buttonTitle = "Salvar",
    buttonProps = {},
    customActions,
    customHandleClose,
    zIndex = 1300,
    dialogContentProps = {},
    dialogHeaderProps = {},
    ...rest
  } = props;
  const { open, handleClose } = useBaseSwipeableDrawer();

  const closeModal = () => {
    if (customHandleClose) {
      customHandleClose();
    } else {
      handleClose(tag);
    }
  };

  return (
    <Styled.Drawer
      anchor="right"
      open={open.includes(tag)}
      onClose={closeModal}
      style={{ zIndex }}
      keepMounted
      {...rest}
    >
      {open.includes(tag) ? (
        <>
          <Styled.DialogHeader {...dialogHeaderProps}>
            {customHeader ?? <Styled.DialogTitle>{title}</Styled.DialogTitle>}
            <Styled.CloseButton
              onClick={customHandleClose || (() => handleClose(tag))}
            />
          </Styled.DialogHeader>
          <Styled.DialogContent {...dialogContentProps}>
            {children}
          </Styled.DialogContent>
          <Styled.DialogActions>
            {customActions ?? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                {...buttonProps}
              >
                {buttonTitle}
              </Button>
            )}
          </Styled.DialogActions>
        </>
      ) : null}
    </Styled.Drawer>
  );
};

export default BaseSwipeableDrawer;
