import React, { useState, useEffect } from "react";

import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { differenceInCalendarDays, format } from "date-fns";

import { ReactComponent as ExcelIcon } from "src/assets/icons/reports/excel.svg";
import Button from "src/components/Button";
import SearchByCustomer from "src/components/Inputs/AutoComplete";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import PATHS from "src/constants/paths";
import { ContactProvider, useContact } from "src/hooks/contact/useContact";
import { Can } from "src/hooks/permission/usePermission";
import { ReportProvider, useReport } from "src/hooks/report/useReport";
import { ContactType } from "src/interfaces/contact";
import { formatNumber, maskDocument } from "src/utils/format";
import { generateXLSX } from "src/utils/generateXLSX";
import { withContext } from "src/utils/withContext";

import InformationInitialReportState from "../components/InformationInitialReportState";
import ClientsTable from "./components/ClientsTable";
import LateClientsBottomBar from "./components/LateClientsBottomBar";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Clientes atrasados" },
];

const LIMIT = 10;

const LateClientsPage = () => {
  const { reportLateClients, status, getReportLateClients } = useReport();

  const [exportLoading, setExportLoading] = useState(false);
  const [options, setOptions] = useState<ContactType[]>([]);
  const [customer, setCustomer] = useState<ContactType | undefined>(undefined);
  const { list, contacts, status: contactStatus } = useContact();

  useEffect(() => {
    setOptions(contacts.contacts || []);
  }, [contacts]);

  const handleGenerateReport = () => {
    getReportLateClients({
      contactId: customer?.id ?? "",
    });
  };

  const searchQuery = (query: string) => {
    if (query.length) {
      list({ search: query, type: "customer", limit: LIMIT });
    } else {
      list({ type: "customer", limit: LIMIT });
    }
  };

  const initialRequest = () => list({ type: "customer", limit: LIMIT });

  const renderDocument = (contact?: ContactType) => {
    if (!contact) {
      return null;
    }

    if (contact?.cpf) {
      return maskDocument(contact?.cpf);
    }

    if (contact?.cnpj) {
      return maskDocument(contact?.cnpj);
    }

    return "-";
  };

  const exportToXLSX = async () => {
    if (exportLoading) return;
    if (reportLateClients?.result?.length) {
      setExportLoading(true);
      const header = [
        "Cliente",
        "CPF/CNPJ",
        "Atrasado desde",
        "Status",
        "Contas atrasadas",
        "Dias em atraso",
        "Valor",
      ];

      const data = reportLateClients.result.reduce<string[][]>(
        (data, contact) => {
          const row = [
            contact?.name ?? "-",
            renderDocument(contact),
            contact?.records?.firstDate
              ? format(new Date(contact?.records?.firstDate), "dd/MM/yyyy")
              : "-",
            contact?.status === "enabled" ? "Ativo" : "Inativo",
            contact?.records?.count,
            contact?.records?.firstDate
              ? differenceInCalendarDays(
                  new Date(),
                  new Date(contact?.records?.firstDate)
                )
              : "-",
            formatNumber(contact?.records.totalAmount),
          ] as Array<string>;
          data.push(row);
          return data;
        },
        []
      );

      const footer = [
        ["", "", "", "", "", "", ""],
        [
          "",
          "",
          "",
          "Total",
          `${reportLateClients.result.length}`,
          `${reportLateClients.totals?.count}`,
          formatNumber(reportLateClients?.totals?.totalAmount ?? 0),
        ],
      ];

      await generateXLSX(
        header,
        [...data, ...footer],
        "Cliente(s) atrasados",
        "Cliente(s) atrasados.xlsx"
      );
      setExportLoading(false);
    }
  };

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Clientes atrasados" />
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-end"
        width="100%"
        style={{ gap: ".5rem" }}
      >
        <Box minWidth={150} flexGrow={1}>
          <SearchByCustomer
            value={customer}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onChange={(_, newInputValue, __) => {
              setCustomer(newInputValue as ContactType);
            }}
            id="autocomplete-customer"
            options={options}
            setOptions={setOptions}
            loading={contactStatus.loading}
            searchQuery={searchQuery}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            initialRequest={initialRequest}
            searchFieldProps={{ placeholder: "Procurar cliente..." }}
            filterOptions={options => options}
          />
        </Box>
        <Button
          style={{ height: 56, marginLeft: 8 }}
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleGenerateReport}
        >
          Gerar relatório
        </Button>
        <Can subject="reportLateClients" action="export">
          <Button
            style={{ height: 56, marginLeft: 8, minWidth: 120 }}
            color="primary"
            variant="outlined"
            size="medium"
            onClick={exportToXLSX}
            loading={exportLoading}
          >
            Exportar
            <ExcelIcon style={{ marginLeft: 10 }} />
          </Button>
        </Can>
      </Box>
      <Box my={3}>
        <Divider />
      </Box>

      {status.initial ? <InformationInitialReportState /> : null}
      {!status.initial && !status.loading ? (
        <>
          <ClientsTable reportLateClients={reportLateClients} />
          <LateClientsBottomBar
            totalClients={reportLateClients.result?.length ?? 0}
            count={reportLateClients?.totals?.count ?? 0}
            totalAmount={reportLateClients?.totals?.totalAmount ?? 0}
          />
        </>
      ) : null}
      {status.loading ? (
        <Box height="100%">
          <Loader size="medium" />
        </Box>
      ) : null}
    </DashboardLayout>
  );
};

export default withContext(LateClientsPage, ContactProvider, ReportProvider);
