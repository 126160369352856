/* eslint-disable no-console */
import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import { Box, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CreateIcon from "@material-ui/icons/Create";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Formik } from "formik";

import { BaseModalProvider } from "src/components/BaseModal/useBaseModal";
import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import { ModalProvider } from "src/components/Modal/useModal";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import { useCompany } from "src/hooks/company/useCompany";
import {
  FinancialAccountProvider,
  useFinancialAccount,
} from "src/hooks/financialAccount/useFinancialAccount";
import { useModule } from "src/hooks/module/useModule";
import { RecordProvider, useRecord } from "src/hooks/record/useRecord";
import {
  BuyOrderFormType,
  BuyOrderType,
  ProductBuyOrderFormType,
} from "src/interfaces/buyOrder";
import { getRouteString } from "src/utils/urlHandler";
import { withContext } from "src/utils/withContext";

import AditionalInfoTextAreaSection from "../components/BuyOrderForm/parts/AditionalInfoTextAreaSection";
import ConditionPaymentSection from "../components/BuyOrderForm/parts/ConditionPaymentSection";
import DiscountSection from "../components/BuyOrderForm/parts/DiscountSection";
import ProductsBuyOrderManager from "../components/BuyOrderForm/parts/ProductsBuyOrderManager";
import CreateOrDeleteBuyOrderRecords from "./components/CreateOrDeleteBuyOrderRecords";
import CreateOrDeleteBuyOrderStock from "./components/CreateOrDeleteBuyOrderStock";
import DetailsBuyOrderBottomBar from "./components/DetailsBuyOrderBottomBar";
import MainDataCard from "./components/MainDataCard";
import MoreOptionsBuyOrderDetailModal from "./components/MoreOptionsOrderDetailModal";
import RecordsData from "./components/RecordsData";
import UpdateBuyOrderStatus from "./components/UpdateBuyOrderStatus";
import UpdateDisabledInfo from "./components/UpdateDisabledInfo";
import * as Styled from "./styles";

interface LocationStateProp {
  data?: BuyOrderType;
}

const BuyOrderDetailsPage: React.FC = () => {
  const { buyOrderId = "" } = useParams<{ buyOrderId: string }>();
  const { state: localtionState } = useLocation();
  const navigate = useNavigate();
  const { handleOpen } = useBaseSwipeableDrawer();

  const { buyOrder, setBuyOrder, getBuyOrder } = useBuyOrder();
  const { setRestartOrderList } = useBuyOrderFilters();

  const canUpdateBuyOrder =
    !buyOrder?.stockCreatedAt && !buyOrder?.recordsCreatedAt;

  const {
    createStatus,
    updateStatus,
    deleteStatus,
    payStatus,
    chargebackStatus,
  } = useRecord();

  const { list: listAccounts } = useFinancialAccount();
  const { hasModules } = useModule();
  const { userCompany } = useCompany();

  useEffect(() => {
    if (hasModules("accountManagement")) {
      listAccounts({ status: "enabled" });
    }
  }, [userCompany]);

  useEffect(() => {
    const state = localtionState as LocationStateProp;
    if (state?.data) {
      setBuyOrder(state.data);
    }
    getBuyOrder(buyOrderId);
  }, []);

  useEffect(() => {
    if (
      createStatus.success ||
      updateStatus.success ||
      deleteStatus.success ||
      payStatus.success ||
      chargebackStatus.success
    ) {
      setRestartOrderList(true);
    }
  }, [
    createStatus.success,
    updateStatus.success,
    deleteStatus.success,
    payStatus.success,
    chargebackStatus.success,
  ]);

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
    { label: "Produtos", path: PATHS.PRODUCTS_LIST },
    { label: "Ordens de compra", path: PATHS.BUY_ORDER_LIST },
    { label: `Ordem de compra #${buyOrder?.number ?? ""}` },
  ];

  const deadline = buyOrder?.deadline ? new Date(buyOrder.deadline) : undefined;

  const createdDate = buyOrder?.createdDate
    ? new Date(buyOrder?.createdDate)
    : undefined;

  const productsBuyOrder = useMemo(() => {
    if (buyOrder?.products) {
      return buyOrder.products.map<ProductBuyOrderFormType>(
        productBuyOrder => ({
          id: productBuyOrder.id,
          productSelected: productBuyOrder.product,
          product: {
            id: productBuyOrder.product.id,
            stock: productBuyOrder.product.stock?.id,
          },
          quantity: productBuyOrder.quantity,
          cost: productBuyOrder.cost,
          validity: productBuyOrder.validity,
          delete: false,
        })
      );
    }
    return [];
  }, [buyOrder]);

  const DetailCards = () => (
    <Grid container>
      <Grid item xs={12}>
        <MainDataCard />
      </Grid>

      <Grid item xs={12}>
        <ProductsBuyOrderManager
          productsBuyOrder={productsBuyOrder}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setProductsBuyOrder={() => {}}
          isViewMode
        />
      </Grid>

      {buyOrder?.recordsCreatedAt ? (
        <Grid item xs={12}>
          <RecordsData />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <ConditionPaymentSection
          enabled={false}
          total={buyOrder?.totalAmount ?? 0}
          method="post"
          buyOrder={{} as BuyOrderType}
        />
      </Grid>

      <Grid item xs={12}>
        <DiscountSection
          enabled={false}
          total={buyOrder?.grossAmount ?? 0}
          discount={buyOrder?.discount ?? 0}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setDiscount={() => {}}
        />
      </Grid>

      <Grid item xs={12}>
        <Box mt={2}>
          <AditionalInfoTextAreaSection
            name="additionalInfo"
            value={buyOrder?.additionalInfo}
            disabled
          />
        </Box>
      </Grid>
    </Grid>
  );

  const renderContent = useMemo(() => {
    return (
      <>
        <Breadcrumb data={BREADCRUMB_BUTTONS} />
        <Divider />
        <Box
          mt={1}
          mb={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="primary" variant="h6">
            Ordem de compra #{buyOrder?.number ?? ""}
          </Typography>
        </Box>
        <Box
          mb={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="row">
            {buyOrder ? (
              <>
                <ModalProvider>
                  <CreateOrDeleteBuyOrderStock buyOrder={buyOrder} />
                </ModalProvider>
                <ModalProvider>
                  <CreateOrDeleteBuyOrderRecords buyOrder={buyOrder} />
                </ModalProvider>
                {!canUpdateBuyOrder ? <UpdateDisabledInfo /> : null}
              </>
            ) : null}
          </Box>

          <Box display="flex" flexDirection="row">
            <UpdateBuyOrderStatus />
            <Styled.UpdateOrderButton
              color="primary"
              variant="contained"
              disabled={!canUpdateBuyOrder}
              onClick={() => {
                navigate(
                  getRouteString(
                    PATHS.BUY_ORDER_UPDATE,
                    buyOrderId,
                    "buyOrderId"
                  ),
                  {
                    state: {
                      data: buyOrder,
                    },
                  }
                );
              }}
            >
              <CreateIcon fontSize="medium" />
              <Typography variant="body2">Editar</Typography>
            </Styled.UpdateOrderButton>
            <Button
              onClick={() => handleOpen("moreOptionsBuyOrderDetailModal")}
            >
              Mais opções
              <MoreVertIcon fontSize="medium" />
            </Button>
          </Box>
        </Box>
        <Box mt={2}>
          <DetailCards />
        </Box>
      </>
    );
  }, [buyOrder, buyOrderId]);

  const updateProductOrder = (data: Partial<BuyOrderFormType>) => {
    console.log(data);
  };

  return (
    <DashboardLayout
      selected="register"
      subSelected="products"
      modules={["stock", "buyorder"]}
    >
      <Formik
        onSubmit={updateProductOrder}
        initialValues={
          {
            additionalInfo: buyOrder?.additionalInfo,
            discount: buyOrder?.discount,
            discountType: buyOrder?.discountType,

            downPaymentType: buyOrder?.downPaymentType,
            downPaymentValue: buyOrder?.downPaymentValue,
            remainingPaymentType: buyOrder?.remainingPaymentType,
            remainingPaymentValue: buyOrder?.remainingPaymentValue,

            products: productsBuyOrder,

            paymentType: buyOrder?.paymentType,

            deadline,
            createdDate,
          } as Partial<BuyOrderFormType>
        }
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {renderContent}
      </Formik>
      {buyOrder ? <MoreOptionsBuyOrderDetailModal buyOrder={buyOrder} /> : null}
      <DetailsBuyOrderBottomBar canUpdateBuyOrder={canUpdateBuyOrder} />
    </DashboardLayout>
  );
};

export default withContext(
  BuyOrderDetailsPage,
  BaseModalProvider,
  RecordProvider,
  FinancialAccountProvider,
  BaseSwipeableDrawerProvider
);
