import styled from "styled-components";

import ButtonUnstyled from "src/components/Button";
import Typography from "src/components/Typography";

export const Container = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding-top: 10px;
  padding-bottom: 16px;
  box-shadow: none;
`;

export const Button = styled(ButtonUnstyled)`
  height: 55px;
  svg {
    font-size: 30px;
    margin-right: 8px;
  }
`;

export const ButtonListViewType = styled(ButtonUnstyled)`
  height: 55px;
  width: 100%;
`;

export const ButtonListViewLabel = styled(Typography)<{ active?: boolean }>`
  margin-left: 10px;
  font-weight: ${({ active }) => (active ? 500 : 200)};
`;
