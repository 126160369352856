import React, { useState, useMemo } from "react";

import { Wrap, Box } from "@chakra-ui/react";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";
import { startOfMonth, endOfMonth, format } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

import { ReactComponent as ExcelIcon } from "src/assets/icons/reports/excel.svg";
import Button from "src/components/Button";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Panel from "src/components/Layouts/Panel";
import Loader from "src/components/Loader";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import { ReportProvider, useReport } from "src/hooks/report/useReport";
import { sortArrayByKeyASC, sumArrayValues } from "src/utils/arrayUtil";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { formatNumber, formatPrice } from "src/utils/format";
import { generateXLSX } from "src/utils/generateXLSX";
import { withContext } from "src/utils/withContext";

import DataCard from "../components/DataCard";
import InformationInitialReportState from "../components/InformationInitialReportState";
import StripedTable from "../components/StripedTable";
import * as Styled from "./styled";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Serviços" },
];

const today = new Date();

const formatCurrency = (params: GridValueFormatterParams) =>
  formatPrice(params.value as number);

const columnsMostSeller: GridColDef[] = [
  {
    field: "serviceName",
    headerName: "Serviços",
    flex: 4,
  },
  {
    field: "quantity",
    headerName: "Quantidade",
    flex: 4,
  },
  {
    field: "totalPrice",
    headerName: "Vendido",
    renderCell: formatCurrency,
    flex: 4,
  },
];

const columnsMostProfitable: GridColDef[] = [
  {
    field: "serviceName",
    headerName: "Serviços",
    flex: 4,
  },
  {
    field: "quantity",
    headerName: "Quantidade",
    flex: 4,
  },
  {
    field: "profit",
    headerName: "Lucro bruto",
    renderCell: formatCurrency,
    flex: 4,
  },
];

const ServicesReportPage = () => {
  const { getReportBestSellers, status, reportBestSellers } = useReport();
  const [exportLoading, setExportLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(endOfMonth(today));

  const [startDateLegend, setStartDateLegend] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDateLegend, setEndDateLegend] = useState<Date | undefined>(
    endOfMonth(today)
  );

  const handleGenerateReport = () => {
    setStartDateLegend(startDate);
    setEndDateLegend(endDate);
    getReportBestSellers({
      startDate: formatStartDateISO(startDate || today),
      endDate: formatEndDateISO(endDate || today),
      type: "serviceOrder",
    });
  };

  const servicesMostSellers = useMemo(
    () => sortArrayByKeyASC([...reportBestSellers], "quantity"),
    [reportBestSellers]
  );

  const servicesMostProfitable = useMemo(() => {
    const parsedData = reportBestSellers.map(item => ({
      ...item,
      profit: item.totalPrice - item.totalCosts,
    }));

    return sortArrayByKeyASC(parsedData, "profit");
  }, [reportBestSellers]);

  const totalSales = useMemo(() => {
    if (reportBestSellers.length > 0) {
      return sumArrayValues(reportBestSellers.map(item => item.totalPrice));
    }

    return 0;
  }, [reportBestSellers]);

  const totalCosts = useMemo(() => {
    if (reportBestSellers.length > 0) {
      return sumArrayValues(reportBestSellers.map(item => item.totalCosts));
    }

    return 0;
  }, [reportBestSellers]);

  const totalProfit = useMemo(() => {
    if (reportBestSellers.length > 0) {
      return sumArrayValues(reportBestSellers.map(item => item.totalProfit));
    }

    return 0;
  }, [reportBestSellers]);

  const exportToXLSX = async () => {
    if (exportLoading) return;
    if (reportBestSellers.length) {
      setExportLoading(true);
      const header = ["", "", ""];

      const dataExcel = servicesMostProfitable.reduce<string[][]>(
        (data, product) => {
          data.push([
            product.serviceName as string,
            product.quantity,
            product.totalPrice,
          ]);
          return data;
        },
        []
      );
      const dataExcel2 = servicesMostSellers.reduce<string[][]>(
        (data, product) => {
          data.push([
            product.serviceName as string,
            product.quantity,
            product.totalPrice,
          ]);
          return data;
        },
        []
      );

      const footer = [[""], ["Total", formatNumber(totalSales)]];

      await generateXLSX(
        header,
        [
          ["Serviços mais lucrativos", "Quantidade", "Vendido"],
          ...dataExcel,
          [""],
          ["Serviços mais vendidos", "Quantidade", "Vendido"],
          ...dataExcel2,
          ...footer,
        ],
        "Serviços",
        "Serviços.xlsx"
      );

      setExportLoading(false);
    }
  };

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Serviços" />
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-end"
        width="100%"
        style={{ gap: ".5rem" }}
      >
        <PeriodFilterPicker
          startDate={startDate || today}
          endDate={endDate || today}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          initialFilter="byMonth"
          options={["byDay", "perInterval", "byMonth"]}
          showingInPopover={false}
        />
        <Button
          style={{ height: 56, marginLeft: 8 }}
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleGenerateReport}
        >
          Gerar relatório
        </Button>
        <Button
          style={{ height: 56, marginLeft: 8, minWidth: 120 }}
          color="primary"
          variant="outlined"
          size="medium"
          onClick={exportToXLSX}
          loading={exportLoading}
        >
          Exportar
          <ExcelIcon style={{ marginLeft: 10 }} />
        </Button>
      </Box>
      <Box my={3}>
        <Divider />
      </Box>

      {status.initial ? <InformationInitialReportState /> : null}
      {!status.initial && !status.loading ? (
        <>
          <Styled.Legend color="primary" variant="h6">
            De
            <strong>
              {startDateLegend
                ? format(startDateLegend, " dd/MM/yyyy ", {
                    locale: ptBrLocale,
                  })
                : ""}
            </strong>
            até
            <strong>
              {endDateLegend
                ? format(endDateLegend, " dd/MM/yyyy", { locale: ptBrLocale })
                : ""}
            </strong>
          </Styled.Legend>
          <Wrap spacing={6} mb={6}>
            <Box w="min-cotnent">
              <DataCard
                title="Total de vendas"
                value={formatPrice(totalSales)}
              />
            </Box>
            <Box w="min-cotnent">
              <DataCard
                title="Total de custos"
                value={formatPrice(totalCosts)}
              />
            </Box>
            <Box w="min-cotnent">
              <DataCard
                title="Total de lucro"
                value={formatPrice(totalProfit)}
              />
            </Box>
          </Wrap>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Panel padding={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Serviços mais vendidos
                </Typography>
                <StripedTable
                  rows={servicesMostSellers}
                  columns={columnsMostSeller}
                />
              </Panel>
            </Grid>
            <Grid item xs={6}>
              <Panel padding={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Serviços mais lucrativos
                </Typography>
                <StripedTable
                  rows={servicesMostProfitable}
                  columns={columnsMostProfitable}
                />
              </Panel>
            </Grid>
          </Grid>
        </>
      ) : null}
      {status.loading ? (
        <Box height="100%">
          <Loader size="medium" />
        </Box>
      ) : null}
    </DashboardLayout>
  );
};

export default withContext(ServicesReportPage, ReportProvider);
