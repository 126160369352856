import React from "react";

import { Chip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import Button from "src/components/Button";
import Modal from "src/components/Modal";
import { useModal } from "src/components/Modal/useModal";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import { BuyOrderType } from "src/interfaces/buyOrder";

import { CreateOrDeleteBuyOrderRecordsModalProps } from "./props";
import * as Styled from "./styles";

const CreateOrDeleteBuyOrderRecordsModal: React.FC<CreateOrDeleteBuyOrderRecordsModalProps> =
  props => {
    const { id, recordsCreatedAt } = props;

    const {
      createBuyOrderRecords,
      createBuyOrderRecordsStatus,
      deleteBuyOrderRecordsStatus,
      deleteBuyOrderRecords,
    } = useBuyOrder();
    const { handleClose } = useModal();
    const { setRestartOrderList } = useBuyOrderFilters();

    const createOrDeleteBuyOrderRecordsService = async () => {
      if (!recordsCreatedAt && !createBuyOrderRecordsStatus.loading) {
        await createBuyOrderRecords(id);
      }
      if (recordsCreatedAt && !deleteBuyOrderRecordsStatus.loading) {
        await deleteBuyOrderRecords(id);
      }

      handleClose();
      setRestartOrderList(true);
    };

    return (
      <Modal>
        <Box p={3}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <div />
                {recordsCreatedAt ? (
                  <Styled.AlertIcon alignmentBaseline="middle" />
                ) : (
                  <Styled.InfoIcon alignmentBaseline="middle" />
                )}
                <Styled.CloseButton onClick={handleClose} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Styled.InfoText align="center" variant="h6">
                Tem certeza que deseja{" "}
                {recordsCreatedAt ? "estornar" : "lançar"} os pagamentos desta
                ordem de compra?
              </Styled.InfoText>
            </Grid>
            <Grid item xs={12}>
              {recordsCreatedAt ? (
                <Styled.DeleteButton
                  variant="contained"
                  onClick={createOrDeleteBuyOrderRecordsService}
                  fullWidth
                  size="large"
                  disabled={deleteBuyOrderRecordsStatus.loading}
                  loading={deleteBuyOrderRecordsStatus.loading}
                >
                  Estornar
                </Styled.DeleteButton>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={createOrDeleteBuyOrderRecordsService}
                  fullWidth
                  size="large"
                  disabled={createBuyOrderRecordsStatus.loading}
                  loading={createBuyOrderRecordsStatus.loading}
                >
                  Lançar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };

interface CreateOrDeleteBuyOrderRecordsProps {
  buyOrder: BuyOrderType;
}

const CreateOrDeleteBuyOrderRecords: React.FC<CreateOrDeleteBuyOrderRecordsProps> =
  props => {
    const { buyOrder } = props;
    const { handleOpen } = useModal();

    return (
      <>
        <Chip
          style={{
            backgroundColor: buyOrder.recordsCreatedAt
              ? "#14B738"
              : "#14B73880",
            color: "#FFF",
            fontWeight: 500,
            marginRight: "8px",
            lineHeight: 1,
          }}
          label={
            buyOrder.recordsCreatedAt ? "Contas lançadas" : "Lançar contas"
          }
          onClick={handleOpen}
          onDelete={buyOrder?.recordsCreatedAt ? handleOpen : undefined}
          deleteIcon={
            <CheckCircleIcon style={{ color: "#FFF" }} fontSize="small" />
          }
        />
        <CreateOrDeleteBuyOrderRecordsModal
          id={buyOrder.id}
          recordsCreatedAt={buyOrder.recordsCreatedAt}
        />
      </>
    );
  };

export default CreateOrDeleteBuyOrderRecords;
