import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ContactType } from "src/interfaces/contact";
import { GetResponseType } from "src/interfaces/request";
import api from "src/services/api";

interface ContactsReportParams {
  search: string;
  type: "customer" | "provider" | "others";
  page: number;
  limit: number;
  uf: string;
  tags: string;
}

interface GetContactsReportResponse extends GetResponseType {
  contacts: ContactType[];
}

export const getContactsReport = (params: ContactsReportParams) =>
  api
    .get<GetContactsReportResponse>("/v1/report/contacts", {
      params,
    })
    .then(res => res.data);

export function useContactsReport(
  params: ContactsReportParams,
  config?: UseQueryOptions<
    GetContactsReportResponse,
    Error,
    GetContactsReportResponse,
    unknown[]
  >
) {
  return useQuery<
    GetContactsReportResponse,
    Error,
    GetContactsReportResponse,
    unknown[]
  >(["reportContacts", params], () => getContactsReport(params), {
    refetchOnWindowFocus: false,
    enabled: false,
    ...(config || {}),
    initialData: {
      contacts: [],
      limit: params.limit,
      page: params.page,
      totalCount: 0,
    } as GetContactsReportResponse,
  });
}
