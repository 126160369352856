import styled from "styled-components";

export const Separator = styled.span`
  width: 10px;
`;

export const PhotoContainer = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-size: cover;
`;

export const PhotoOptions = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: 0.5s ease;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  cursor: pointer;

  button {
    display: none;
  }

  :hover {
    display: flex;
    background-color: rgba(1, 1, 1, 0.2);
    transition: 0.5s ease;

    button {
      display: block;
    }
  }
`;

export const ActionButton = styled.button`
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0px;
  border: none;
  cursor: pointer;
`;
