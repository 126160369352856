import React, { useMemo, useState } from "react";

import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";

import Typography from "src/components/Typography";
import { GalleryType } from "src/interfaces/order";

import AddImateGalleryModal from "../AddImateGalleryModal";
import PhotoPreview from "../PhotoPreview";
import { UploadButtonImageProps } from "./props";
import * as Styled from "./styles";

const UploadButtonImage: React.FC<UploadButtonImageProps> = props => (
  <Styled.UploadImageButton {...props} type="button">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Styled.PhotoIcon />
      <Box mt={2} />
      <Typography variant="caption" color="primary">
        Arraste a foto ou clique
      </Typography>
      <Typography variant="caption" color="primary">
        {" "}
        para adicionar uma foto
      </Typography>
    </Box>
  </Styled.UploadImageButton>
);

const Gallery: React.FC = () => {
  const [openUploadImageModal, setOpenUploadImageModal] = useState(false);
  const [galleryUpdate, setGalleryUpdate] = useState<GalleryType | undefined>(
    undefined
  );

  const { values, setFieldValue } =
    useFormikContext<{ gallery: GalleryType[] }>();

  const handleOpenImageUploadPostModal = () => {
    setGalleryUpdate(undefined);
    setOpenUploadImageModal(true);
  };

  const handleCloseImageUploadModal = () => {
    setOpenUploadImageModal(false);
    setGalleryUpdate(undefined);
  };

  const handleOpenImageUploadUpdateModal = (data: GalleryType) => {
    setGalleryUpdate({ ...data });
    setOpenUploadImageModal(true);
  };

  const gallery = useMemo(
    () => (values.gallery ? values.gallery : []),
    [values.gallery]
  );

  const handleDelete = (imageIndex?: number) => {
    const storagedGallery = gallery.filter(item => item.index !== imageIndex);

    setFieldValue("gallery", storagedGallery);
  };

  const renderPhotos = useMemo(() => {
    return (
      <Grid container spacing={2}>
        {gallery.map(item => {
          const image: any = item.imageUrl;
          const url =
            image instanceof File ? URL.createObjectURL(image) : image;
          return (
            <Grid item xs={6} key={url}>
              <Box height={120}>
                <PhotoPreview
                  url={url}
                  handleDelete={() => handleDelete(item.index)}
                  handleOpenImageUploadUpdateModal={() =>
                    handleOpenImageUploadUpdateModal(item)
                  }
                />
              </Box>
              <Typography>{item.name}</Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  }, [gallery]);

  return (
    <>
      <div>
        <Box mb={2} display="flex" flexDirection="column" width="100%">
          <Typography variant="subtitle1" color="textSecondary">
            Adicionar imagem
          </Typography>
          <Box>
            <UploadButtonImage onClick={handleOpenImageUploadPostModal} />
          </Box>
          <Box mt={1} display="flex" flexDirection="row-reverse">
            <Typography variant="caption">Máximo 3MB - PNG ou JPG</Typography>
          </Box>
        </Box>

        <Divider />
        <Box mt={2} />
        <Typography variant="subtitle1" color="textSecondary">
          Galeria de imagens
        </Typography>

        <Box mt={2} mb={2}>
          {renderPhotos}
        </Box>
      </div>
      <AddImateGalleryModal
        open={openUploadImageModal}
        handleClose={handleCloseImageUploadModal}
        data={galleryUpdate}
        method={galleryUpdate ? "update" : "post"}
      />
    </>
  );
};

export default Gallery;
