import * as yup from "yup";

export const itemsSchema = yup.object({
  quantity: yup
    .number()
    .typeError("Quantidade é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
  price: yup
    .number()
    .typeError("Preço é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
});
