import React, { useEffect, useMemo, useState } from "react";

import { Text } from "@chakra-ui/react";
import { Box } from "@material-ui/core";
import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";
import { format } from "date-fns";
import { Form, Formik } from "formik";

import Button from "src/components/Button";
import SelectField from "src/components/Inputs/SelectField";
import { MenuItemType } from "src/components/Inputs/SelectField/props";
import { ModalProvider } from "src/components/Modal/useModal";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import { SelectedFilterType } from "src/components/PeriodFilterPicker/props";
import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { MOVEMENT_SOURCE, MOVEMENT_TYPE } from "src/constants/movement";
import PATHS from "src/constants/paths";
import { useMovement } from "src/hooks/product/useMovement";
import { useProduct } from "src/hooks/product/useProduct";
import { MovementSources, MovementType } from "src/interfaces/movement";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { formatPrice } from "src/utils/format";
import { generateLocaleGridText } from "src/utils/localGridText";

import CreateMovementModal from "../../Detail/components/CreateMovementModal";
import MoreOptionsMenu from "../../Detail/components/MoreOptionsMenu";

const EmptyTableContent = () => (
  <div>
    <Typography variant="subtitle1">
      Você ainda nenhuma movimentação deste produto!
    </Typography>
  </div>
);

const today = new Date();

const Movements: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [periodFilterType, setPeriodFilterType] =
    useState<SelectedFilterType>("empty");

  const { product } = useProduct();
  const { status, movements, list } = useMovement();

  function listMovements() {
    list({
      limit,
      page,
      productId: product.id,
      status: "enabled",
      type,
      startDate:
        periodFilterType !== "empty" && startDate
          ? formatStartDateISO(startDate)
          : undefined,
      endDate:
        periodFilterType !== "empty" && endDate
          ? formatEndDateISO(endDate)
          : undefined,
    });
  }

  const renderOptionsButton = (params: GridValueFormatterParams) => (
    <ModalProvider>
      <MoreOptionsMenu
        source={params.row.source as MovementSources}
        id={params.id as string}
        afterAction={listMovements}
      />
    </ModalProvider>
  );

  useEffect(() => {
    listMovements();
  }, [page, limit]);

  const typeMovementItems = useMemo(() => {
    return [{ name: "Todos", value: "" }, ...MOVEMENT_TYPE] as MenuItemType[];
  }, [MOVEMENT_TYPE]);

  const columns = [
    {
      field: "type",
      headerName: "Operação",
      renderCell: ({ row: movement }: { row: MovementType }) => {
        if (!movement?.type) return "-";
        let color = "#000000DE";

        if (movement.type === "output") {
          color = "error.500";
        } else if (movement.type === "input") {
          color = "primary.500";
        }

        return (
          <Text fontSize="sm" fontWeight={400} color={color}>
            {MOVEMENT_TYPE.find(item => item.value === movement.type)?.name}
          </Text>
        );
      },
      flex: 2,
    },
    {
      field: "product.createdAt",
      headerName: "Data",
      renderCell: (params: any) =>
        params.row?.createdAt
          ? format(new Date(params.row?.createdAt), "dd/MM/yyyy HH:mm")
          : "-",
      sortComparator: (v1: string, v2: string) => {
        const date = (v1 as string)?.toLowerCase() ?? "-";
        const dateComparable = (v2 as string)?.toLowerCase() ?? "-";

        if (date < dateComparable) {
          return -1;
        }
        if (date > dateComparable) {
          return 1;
        }
        return 0;
      },
      flex: 2,
    },
    {
      field: "product.price",
      headerName: "Valor",
      renderCell: () => formatPrice(product.price),
      flex: 2,
    },
    {
      field: "quantity",
      headerName: "Quantidade",
      flex: 2,
    },
    {
      field: "source",
      headerName: "Origem",
      renderCell: ({ row: movement }: { row: MovementType }) =>
        MOVEMENT_SOURCE.find(item => item.value === movement.source)?.name ??
        "-",
      flex: 3,
    },
    {
      field: "reason",
      headerName: "Motivo",
      renderCell: ({ row: movement }: { row: MovementType }) => movement.reason,
      flex: 2,
    },
    {
      field: "options",
      headerName: " ",
      renderCell: renderOptionsButton,
      flex: 1,
      align: "right",
    },
  ] as Array<GridColDef>;

  return (
    <>
      <Formik
        // eslint-disable-next-line no-console
        onSubmit={v => console.log(v)}
        initialValues={{
          type,
        }}
      >
        {({ values }) => (
          <Form>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="flex-end"
              width="100%"
              style={{ gap: ".5rem" }}
              mb={3}
            >
              <PeriodFilterPicker
                startDate={startDate || today}
                endDate={endDate || today}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                initialFilter={periodFilterType}
                setSelectedFilterType={setPeriodFilterType}
                options={["empty", "perInterval", "byMonth"]}
                showingInPopover={false}
              />
              <Box width={250}>
                <SelectField
                  label="Tipo de movimentação"
                  name="type"
                  items={typeMovementItems}
                  defaultValue=""
                  backgroundwhite
                />
                {setType(values.type)}
              </Box>
              <Box display="flex" flex={1} justifyContent="flex-end">
                <Button
                  style={{ height: 56, marginLeft: 8, minWidth: 110 }}
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={listMovements}
                >
                  Filtrar
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <Table
        rows={movements.movements || []}
        columns={columns}
        page={page}
        setPage={setPage}
        pageSize={limit}
        onPageSizeChange={setLimit}
        filterMode="server"
        loading={status.loading}
        totalCount={movements.totalCount}
        localeText={generateLocaleGridText("movements")}
        detailLink={PATHS.PRODUCTS_DETAIL}
        components={{
          NoRowsOverlay: () => (
            <EmptyTable>
              <EmptyTableContent />
            </EmptyTable>
          ),
        }}
        // error={status.error}
      />
      <CreateMovementModal
        product={product}
        afterAction={() => {
          listMovements();
        }}
      />
    </>
  );
};

export default Movements;
