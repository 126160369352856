import { ComponentStyleConfig } from "@chakra-ui/react";

export const FormError: ComponentStyleConfig = {
  baseStyle: {
    text: {
      fontSize: "xs",
      color: "error.500",
      mt: 1,
    },
  },
};
