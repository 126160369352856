import { MenuItemType } from "src/components/Inputs/SelectField/props";

export const FINANCIAL_ACCOUNT_TYPES: MenuItemType[] = [
  {
    name: "Poupança",
    value: "savings",
  },
  {
    name: "Corrente",
    value: "checking",
  },
  {
    name: "Investimento",
    value: "investments",
  },
  {
    name: "Carteira",
    value: "wallet",
  },
  {
    name: "Caixa",
    value: "cash",
  },
];
