import { ComponentStyleConfig } from "@chakra-ui/react";

export const Switch: ComponentStyleConfig = {
  baseStyle: {
    track: {
      bg: "gray.200",
      borderRadius: 20,
      w: "2.5rem",
      h: 15,
      p: 0,
      display: "flex",
      alignItems: "center",
      transition: "all .2s",
      _focus: {
        boxShadow: "none",
      },
      _hover: {
        boxShadow: "none",
      },
      _checked: {
        boxShadow: "none",
        bg: "secondary.500",
      },
    },
    thumb: {
      bg: "white",
      boxShadow: " 0px 0.9375px 1.875px rgba(0, 0, 0, 0.25)",
      w: "20px",
      h: "20px",
      transition: "all .2s",
      _checked: {
        transform: "translateX(20px)",
        bg: "primary.500",
      },
    },
  },
};
