import styled from "styled-components";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import Typography from "src/components/Typography";

import { StatusTagProps } from "./props";

export const CodeBox = styled(Box)`
  border: 1px #1e2c47 solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
`;

export const Title = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 26px;
  color: #5b5c60;
  margin-bottom: 1rem;
`;

export const LabelText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  color: #73757a;
`;

export const LabelValue = styled(Typography)`
  font-weight: 500;
  font-size: 19px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StatusTag = styled(Box)<StatusTagProps>`
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 5px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 30px;
  padding: 20px;
  text-transform: capitalize;
`;

export const Loading = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.common.white};
`;
