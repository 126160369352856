import React, { useEffect } from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";

import { Flex, Spinner } from "@chakra-ui/react";

import { BaseSwipeableDrawerProvider } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { OrderProvider } from "src/hooks/order/useOrder";
import { OrderFiltersProvider } from "src/hooks/order/userOrderFilters";
import { StatusOrderProvider } from "src/hooks/order/useStatusOrder";
import { ViewOrderProvider } from "src/hooks/order/useViewOrder";
import { usePermission } from "src/hooks/permission/usePermission";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { useCashFlowOpened } from "src/services/hooks/cashFlow/useCashFlowOpened";
import { withContext } from "src/utils/withContext";

import { OrderTypeProvider, useOrderType } from "../../useOrderType";
import MainContent from "../components/MainContent";
import { OpenCashFlowInfo } from "../components/OpenCashFlowInfo";

const OrderServiceList: React.FC = () => {
  const match = useMatch(PATHS.SERVICE_ORDER_LIST);
  const { pathname } = useLocation();
  const { setType } = useOrderType();
  const { serviceOrderNameDefinitions } = useCompany();
  const { hasModules } = useModule();

  const { can } = usePermission();
  const { isOwner } = useAuth();

  const shouldOpenCashFlow =
    hasModules("cashflowcontrol") &&
    !isOwner &&
    can([{ subject: "cashFlow", action: "requestOpened" }]);

  const {
    data: cashFlowOpened,
    error,
    isLoading,
  } = useCashFlowOpened({
    enabled: hasModules("cashflowcontrol"),
    retry: 1,
    retryDelay: 300,
  });

  const hasCashFlowOpened = cashFlowOpened && !error;

  const showOrderContent =
    !hasModules("cashflowcontrol") || !shouldOpenCashFlow || hasCashFlowOpened;

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
    { label: "Vendas" },
    { label: serviceOrderNameDefinitions.titlePlural },
  ];

  useEffect(() => {
    setType("serviceOrder");
  }, []);

  return (
    <>
      <DashboardLayout
        selected="sales"
        subSelected="service-order"
        modules={["serviceOrder"]}
        hidden={match?.pathname !== pathname}
      >
        <Breadcrumb data={BREADCRUMB_BUTTONS} />
        <PageTitle label={serviceOrderNameDefinitions.titlePlural}>
          {/* {showOrderContent ? (
            <Styled.Button
              variant="contained"
              color="primary"
              to={PATHS.SERVICE_ORDER_ADD}
            >
              <AddCircleIcon />
              <Typography variant="body2">
                {serviceOrderNameDefinitions.title}
              </Typography>
            </Styled.Button>
          ) : null} */}
        </PageTitle>

        {isLoading && shouldOpenCashFlow ? (
          <Flex align="center" justify="center" pt={10}>
            <Spinner size="lg" color="primary.500" />{" "}
          </Flex>
        ) : null}

        {shouldOpenCashFlow && !hasCashFlowOpened && !isLoading ? (
          <>
            <OpenCashFlowInfo />
          </>
        ) : null}

        {showOrderContent ? (
          <MainContent isVisible={match?.pathname === pathname} />
        ) : null}
      </DashboardLayout>
      <Outlet />
    </>
  );
};

export default withContext(
  OrderServiceList,
  OrderProvider,
  OrderTypeProvider,
  StatusOrderProvider,
  ViewOrderProvider,
  { component: OrderFiltersProvider, props: { typeOrder: "serviceOrder" } },
  BaseSwipeableDrawerProvider
);
