import React from "react";

import Box from "@material-ui/core/Box";

import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

interface RecordsByClientBottomBarProps {
  count: number;
  totalAmount: number;
  totalExpense: number;
  totalIncome: number;
}

const RecordsByClientBottomBar: React.FC<RecordsByClientBottomBarProps> =
  props => {
    const { count, totalAmount, totalExpense, totalIncome } = props;

    return (
      <Styled.AppBar position="fixed" component="footer">
        <Styled.Container>
          <Box display="flex">
            <Box mr={4}>
              <Styled.LabelData>Quantidade</Styled.LabelData>
              <Styled.ValueData>{count}</Styled.ValueData>
            </Box>
            <Box mr={4}>
              <Styled.LabelData>
                Despesa
                <Styled.LabelIconMinus />
              </Styled.LabelData>
              <Styled.ValueData isRed>
                {formatPrice(totalExpense)}
              </Styled.ValueData>
            </Box>
            <Box pr={4} mr={4} borderRight="solid #D6D8DC 1px">
              <Styled.LabelData>
                Receita
                <Styled.LabelIconPlus />
              </Styled.LabelData>
              <Styled.ValueData>{formatPrice(totalIncome)}</Styled.ValueData>
            </Box>
            <Box>
              <Styled.LabelTotalValue>
                Valor total
                <Styled.LabelIconEquals />
              </Styled.LabelTotalValue>
              <Styled.ValueData>{formatPrice(totalAmount)}</Styled.ValueData>
            </Box>
          </Box>
        </Styled.Container>
      </Styled.AppBar>
    );
  };

export default RecordsByClientBottomBar;
