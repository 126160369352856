import { useEffect, useMemo } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";

import { Flex, Image } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { CurrencyInput } from "src/components/NewComponents/CurrencyInput";
import { DatePicker } from "src/components/NewComponents/DatePicker";
import { Input } from "src/components/NewComponents/Input";
import { Select } from "src/components/NewComponents/Select";
import { Textarea } from "src/components/NewComponents/TextArea";
import { useModule } from "src/hooks/module/useModule";
import { Option } from "src/interfaces/common";
import { FinancialAccountType } from "src/interfaces/financialAccount";
import { RecordType } from "src/interfaces/record";
import { useCashFlowOpened } from "src/services/hooks/cashFlow/useCashFlowOpened";
import { useActiveFinancialAccounts } from "src/services/hooks/financialAccount/useActiveFinancialAccounts";
import { useCreateTransfer } from "src/services/hooks/record/useCreateTransfer";
import { useUpdateTransfer } from "src/services/hooks/record/useUpdateTransfer";
import { formatStartDateISO } from "src/utils/date";
import { groupFinancialAccounts } from "src/utils/financialAccount";

import { schemaTransferForm } from "./schema";

export type TransferSchema = yup.InferType<typeof schemaTransferForm>;

export enum TransferFormOriginEnum {
  income,
  expense,
  cashFlow,
  accountManagement,
  accountStatement,
}

interface TransferFormModalType {
  record?: RecordType;
  handleCloseTransferFormModal?: () => void;
  onSuccess?: (record: RecordType) => void;
  transferFormOrigin: TransferFormOriginEnum;
}

function TransferFormModal({
  record,
  handleCloseTransferFormModal,
  onSuccess,
  transferFormOrigin,
}: TransferFormModalType) {
  const { hasModules } = useModule();
  const { handleClose } = useBaseSwipeableDrawer();

  const { data: accounts } = useActiveFinancialAccounts(undefined, {
    enabled: true,
    onSuccess() {
      reset({
        ...defaultValues,
        fromAccount:
          transferFormOrigin === TransferFormOriginEnum.cashFlow
            ? (cashFlowOpened?.financialAccount as unknown as string)
            : record?.type === "transferIn"
            ? ((record?.transferFrom?.financialAccount as FinancialAccountType)
                ?.id as string)
            : ((record?.transferTo?.financialAccount as FinancialAccountType)
                ?.id as string),
      });
    },
  });

  const { data: cashFlowOpened } = useCashFlowOpened({
    enabled:
      hasModules("cashflowcontrol") &&
      transferFormOrigin === TransferFormOriginEnum.cashFlow,
    retry: 1,
    retryDelay: 500,
    onSuccess(cashFlowOpened) {
      reset({
        ...defaultValues,
        fromAccount: cashFlowOpened?.financialAccount as unknown as string,
      });
    },
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      fromAccount:
        record?.type === "transferIn"
          ? ((record?.transferFrom?.financialAccount as FinancialAccountType)
              ?.id as string)
          : ((record?.transferTo?.financialAccount as FinancialAccountType)
              ?.id as string),
      toAccount: (record?.financialAccount as FinancialAccountType)
        ?.id as string,
    });
  }, [record]);

  const defaultValues: TransferSchema = {
    id: record?.id as string,
    title: record?.description || "",
    value: record?.value,
    date: formatStartDateISO(new Date()),
    fromAccount:
      transferFormOrigin === TransferFormOriginEnum.cashFlow
        ? (cashFlowOpened?.financialAccount as unknown as string)
        : record?.type === "transferIn"
        ? ((record?.transferFrom?.financialAccount as FinancialAccountType)
            ?.id as string)
        : ((record?.transferTo?.financialAccount as FinancialAccountType)
            ?.id as string),
    toAccount:
      transferFormOrigin === TransferFormOriginEnum.cashFlow
        ? ""
        : ((record?.financialAccount as FinancialAccountType)?.id as string),
    description: record?.note || "",
  };

  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
    register,
  } = useForm<TransferSchema>({
    defaultValues,
    resolver: yupResolver(schemaTransferForm),
  });

  const toAccount = useWatch({ name: "toAccount", control });
  const fromAccount = useWatch({ name: "fromAccount", control });

  const handleCustomClose = () => {
    reset(defaultValues);
    if (handleCloseTransferFormModal) {
      handleCloseTransferFormModal();
    }
    handleClose("transferForm");
  };

  const toFinancialAccountsGrouped = useMemo(() => {
    return groupFinancialAccounts(
      accounts.filter(account => account.id !== fromAccount)
    );
  }, [accounts, fromAccount]);

  const fromFinancialAccountsGrouped = useMemo(() => {
    return groupFinancialAccounts(
      accounts.filter(account => account.id !== toAccount)
    );
  }, [accounts, toAccount]);

  const { mutate: createTransfer, isLoading } = useCreateTransfer({
    onSuccess: record => {
      toast.success("Transferência feita com sucesso.");
      if (onSuccess) onSuccess(record);
      handleCustomClose();
    },
  });

  const { mutate: updateTransfer, isLoading: isLoadingUpdateTransfer } =
    useUpdateTransfer({
      onSuccess: record => {
        toast.success("Lançamento atualizado com sucesso.");
        if (onSuccess) onSuccess(record);
        handleCustomClose();
      },
    });

  const onSubmit = ({
    id,
    title,
    value,
    date,
    fromAccount,
    toAccount,
    description,
  }: TransferSchema) => {
    if (record) {
      updateTransfer({
        id: id as string,
        title,
        description,
      });
    } else {
      createTransfer({
        title,
        value: Number(value),
        date: formatStartDateISO(date),
        fromAccount,
        toAccount,
        description,
      });
    }
  };

  return (
    <BaseSwipeableDrawer
      tag="transferForm"
      title={record ? "Atualizar transferência" : "Transferência"}
      buttonTitle={record ? "Salvar" : "Fazer transferência"}
      buttonProps={{
        loading: isLoading || isLoadingUpdateTransfer,
        disabled: isLoading || isLoadingUpdateTransfer,
        form: "tranferForm",
      }}
      zIndex={1302}
      customHandleClose={handleCustomClose}
    >
      <Flex
        w={["100%", "350px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="tranferForm"
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
        direction="column"
      >
        <Input label="Título" error={errors.title} {...register("title")} />

        <CurrencyInput
          label="Valor"
          error={errors.value}
          control={control}
          name="value"
          isDisabled={!!record}
        />
        <DatePicker
          label="Data"
          error={errors.date}
          control={control}
          name="date"
          isDisabled={!!record}
        />

        <Controller
          control={control}
          name="fromAccount"
          render={({ field: { ref, onChange } }) => (
            <Select
              options={fromFinancialAccountsGrouped}
              inputRef={ref}
              error={!!errors.fromAccount}
              errorMessage={errors.fromAccount?.message}
              placeholder="Conta de saída"
              isClearable={false}
              isDisabled={
                transferFormOrigin === TransferFormOriginEnum.cashFlow ||
                !!record
              }
              formatOptionLabel={(data: unknown) => {
                const account = data as Option;
                return (
                  <Flex alignItems="center" gap={2}>
                    <Image
                      borderRadius="full"
                      boxSize="24px"
                      src={account?.url}
                      alt={account.label as string}
                    />
                    {account.label}
                  </Flex>
                );
              }}
              onChange={onChange}
              value={fromAccount}
            />
          )}
        />

        <Controller
          control={control}
          name="toAccount"
          render={({ field: { ref, onChange } }) => (
            <Select
              options={toFinancialAccountsGrouped}
              inputRef={ref}
              error={!!errors.toAccount}
              errorMessage={errors.toAccount?.message}
              placeholder="Conta de entrada"
              isClearable={false}
              isDisabled={!!record}
              formatOptionLabel={(data: unknown) => {
                const account = data as Option;
                return (
                  <Flex alignItems="center" gap={2}>
                    <Image
                      borderRadius="full"
                      boxSize="24px"
                      src={account?.url}
                      alt={account.label as string}
                    />
                    {account.label}
                  </Flex>
                );
              }}
              onChange={onChange}
              value={toAccount}
            />
          )}
        />

        <Textarea
          label="Observação"
          error={errors.description}
          {...register("description")}
        />
      </Flex>
    </BaseSwipeableDrawer>
  );
}

export default TransferFormModal;
