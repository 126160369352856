import React, { useMemo } from "react";

import { isValid } from "date-fns";
import { useField } from "formik";

import { formatDateToISO } from "src/utils/date";

import { KeyboardDatePickerFieldProps } from "./props";
import * as Styled from "./styles";

const KeyboardDatePicker: React.FC<KeyboardDatePickerFieldProps> = props => {
  const { name, ...rest } = props;

  const [, meta, helpers] = useField<string>(name);

  const date = useMemo(() => {
    if (meta.value) {
      return formatDateToISO(meta.value);
    }
    return null;
  }, [meta.value]);

  const handleChange = (date: Date | null) => {
    if (isValid(date) && date) {
      const value = formatDateToISO(date);
      helpers.setValue(value.toString());
    }
  };

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      };
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      };
    }
    return {};
  }, [meta.error, meta.initialError, meta.touched]);

  return (
    <Styled.KeyboardDatePicker
      value={date}
      onChange={handleChange}
      animateYearScrolling
      inputVariant="outlined"
      format="dd/MM/yyyy"
      cancelLabel="cancelar"
      {...errorProps}
      {...rest}
      DialogProps={{
        style: { zIndex: 1999 },
      }}
    />
  );
};

export default KeyboardDatePicker;
