import React, { FC } from "react";

import { GridColDef } from "@material-ui/data-grid";

import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { SalesComissionOperator } from "src/services/hooks/reports/sales/useSalesComissionReport";
import { formatPrice } from "src/utils/format";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Vendedor",
    flex: 1,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "count",
    headerName: "Qtd de vendas",
    renderCell: params => params.value,
    flex: 1,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "totalComission",
    headerName: "Valor da comissão",
    renderCell: params => formatPrice(params.value),
    flex: 1,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "totalAmount",
    headerName: "Valor de vendas",
    renderCell: params => formatPrice(params.value),
    flex: 1,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
];

export interface SalesComissionTableProps {
  operators: SalesComissionOperator[];
}

const SalesComissionTable: FC<SalesComissionTableProps> = ({ operators }) => {
  const [page, setPage] = React.useState(1);

  return (
    <Table
      sortingOrder={["desc", "asc"]}
      rows={operators.map((operator, index) => ({
        ...operator,
        id: `${index}${Math.random()}`,
      }))}
      columns={columns}
      page={page}
      setPage={setPage}
      pageSize={operators.length}
      onPageSizeChange={undefined}
      filterMode="server"
      totalCount={operators.length}
      hideFooter
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <Typography>Nenhuma relatório disponível</Typography>
          </EmptyTable>
        ),
      }}
    />
  );
};

export default SalesComissionTable;
