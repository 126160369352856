import { extendTheme } from "@chakra-ui/react";

const activeLabelStyles = (
  color: string,
  bgBefore: string,
  errorColor: string,
  isDisabled = false
) => {
  return {
    fontSize: "xs",
    top: "calc(-100% + 2px)",
    left: 5,
    px: 1,
    color: isDisabled ? `${color}80` : color,
    opacity: 1,
    _invalid: {
      color: isDisabled ? `${errorColor}80` : errorColor,
    },
    _before: {
      content: '""',
      height: "1px",
      width: "calc(100% + 8px)",
      position: "absolute",
      bottom: "50%",
      zIndex: -1,
      bg: bgBefore,
    },
  };
};

export const FloatingLabel = extendTheme({
  variants: {
    floating: {
      container: {
        position: "relative",
        _focusWithin: {
          label: {
            ...activeLabelStyles("primary.500", "white", "error.500"),
          },
        },
        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label,textarea:not(:placeholder-shown) + label":
          ({ colors }: any) => {
            return {
              ...activeLabelStyles(
                colors.primary[500],
                "white",
                colors.error[500]
              ),
            };
          },
        "input:not(:placeholder-shown)[disabled] + label,textarea:not(:placeholder-shown)[disabled] + label":
          ({ colors }: any) => {
            return {
              ...activeLabelStyles(
                colors.primary[500],
                "white",
                colors.error[500],
                true
              ),
            };
          },
        label: {
          top: 0,
          bottom: 0,
          left: 0,
          zIndex: 2,
          position: "absolute",
          backgroundColor: "transparent",
          pointerEvents: "none",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 4,
          color: "rgba(19, 34, 62, 0.7)",
          transition: "all .2s",
        },
      },
    },
    floatingWhite: {
      container: {
        position: "relative",
        _focusWithin: {
          label: {
            ...activeLabelStyles("white", "primary.500", "error.500"),
          },
        },
        "input:not(:placeholder-shown) + label,textarea:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
          {
            ...activeLabelStyles("white", "primary.500", "error.500"),
          },
        label: {
          top: 0,
          bottom: 0,
          left: 0,
          zIndex: 2,
          position: "absolute",
          backgroundColor: "transparent",
          pointerEvents: "none",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 4,
          transition: "all .2s",
        },
      },
    },
  },
});
