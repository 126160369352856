import { gridDefaultLocaleText } from "src/constants/grid";

const LABELS = {
  contact: {
    labelRowsPerPage: "Contatos por página",
  },
  product: {
    labelRowsPerPage: "Produtos por página",
  },
  service: {
    labelRowsPerPage: "Serviços por página",
  },
  orders: {
    labelRowsPerPage: "Vendas por página",
  },
  users: {
    labelRowsPerPage: "Usuários por página",
  },
  movements: {
    labelRowsPerPage: "Movimentações por página",
  },
  buyOrders: {
    labelRowsPerPage: "Ordens de compra por página",
  },
  cashOpened: {
    labelRowsPerPage: "Caixas abertos por página",
  },
  cashClosed: {
    labelRowsPerPage: "Caixas fechados por página",
  },
  cashFlowReport: {
    labelRowsPerPage: "Caixas por página",
  },
  lots: {
    labelRowsPerPage: "Lotes por página",
  },
};

/**
 *
 * Dicionário para tabela
 */
export const generateLocaleGridText = (
  table:
    | "contact"
    | "product"
    | "service"
    | "orders"
    | "users"
    | "movements"
    | "buyOrders"
    | "cashOpened"
    | "cashClosed"
    | "cashFlowReport"
    | "lots"
) => {
  return gridDefaultLocaleText(LABELS[table]);
};
