import React, { useMemo } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useField } from "formik";

import { SelectFieldProps } from "./props";
import * as Styled from "./styles";

const SelectField: React.FC<SelectFieldProps> = props => {
  const { name, items, defaultValue, label, ...rest } = props;

  const [field, meta] = useField<string | number>({
    name,
    defaultValue,
  });

  const menuItems = useMemo(
    () =>
      items.map(item => (
        <MenuItem value={item.value} key={`menu-item-${item.value}`}>
          {item.name}
        </MenuItem>
      )),
    [items]
  );

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      };
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      };
    }
    return {};
  }, [meta.error, meta.initialError, meta.touched]);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel
        htmlFor={name}
        id={`${name}-controlled-open-select-label`}
        disabled={rest?.disabled}
      >
        {label}
      </InputLabel>
      <Styled.Select
        inputProps={{
          id: name,
        }}
        defaultValue={defaultValue}
        {...field}
        {...rest}
        {...errorProps}
        MenuProps={{
          style: { zIndex: 1999 },
        }}
        labelId={`${name}-controlled-open-select-label`}
      >
        {menuItems}
      </Styled.Select>
    </FormControl>
  );
};

export default SelectField;
