import React, { useMemo } from "react";

import { Box, Divider, Text } from "@chakra-ui/react";

import { useModule } from "src/hooks/module/useModule";
import { ServiceOrderSettingType } from "src/interfaces/module";

import Client from "./components/Client";
import Company from "./components/Company";
import Products from "./components/Products";
import Resume from "./components/Resume";
import Services from "./components/Services";
import TechnicalReportFields from "./components/TechnicalReportFields";
import { OrderReceiptType } from "./props";

export const OrderReceipt = React.forwardRef<HTMLDivElement, OrderReceiptType>(
  (props, ref) => {
    const {
      company,
      customer,
      order,
      products,
      services,
      records,
      printCustomizedMessage,
      printTechnicalReport,
    } = props;
    const { technicalReports } = order;

    const { serviceOrderModule } = useModule();

    const settings = serviceOrderModule?.settings as ServiceOrderSettingType;

    const title = useMemo(() => {
      if (order.type === "serviceOrder") {
        return `${
          company?.occupationArea?.nameDefinitions?.serviceOrder
            ?.abbreviation ?? "O.S"
        } ${order.code}`;
      }

      return `Pedido ${order.code}`;
    }, [order]);

    const subContact = useMemo(() => {
      if (order?.subContact) {
        return `${
          company?.occupationArea?.nameDefinitions?.subContact?.title ?? ""
        }: ${order.subContact.data[0]?.content ?? ""}`;
      }

      return undefined;
    }, [order, company]);

    return (
      <Box display="none">
        <Box
          ref={ref}
          display="flex"
          flexDirection="column"
          alignItems="center"
          backgroundColor="#ffffff"
          sx={{
            color: "black",
          }}
        >
          <Text fontSize="14px" color="black" mb={3}>
            {title}
          </Text>

          <Company company={company} order={order} />

          {settings?.serviceSettingsPrint ? (
            printCustomizedMessage !== "" ? (
              <Text fontSize="10px" color="black" mb={3}>
                {printCustomizedMessage}
              </Text>
            ) : null
          ) : null}

          {customer ? (
            <>
              <Divider
                borderColor="black"
                opacity={0.3}
                borderBottomWidth=".3px"
                my={3}
              />
              <Client customer={customer} subContact={subContact} />
            </>
          ) : null}

          {order.type === "serviceOrder" ? (
            <>
              {technicalReports?.length && settings?.serviceSettingsPrint ? (
                printTechnicalReport === "true" ? (
                  <>
                    <Divider
                      borderColor="black"
                      opacity={0.3}
                      borderBottomWidth=".3px"
                      my={3}
                    />
                    <TechnicalReportFields
                      technicalReports={technicalReports}
                    />
                  </>
                ) : null
              ) : null}
              <Divider
                borderColor="black"
                opacity={0.3}
                borderBottomWidth=".3px"
                my={3}
              />
              <Services
                services={services}
                showTotal={Boolean(products.length)}
              />
            </>
          ) : null}

          <Divider
            borderColor="black"
            opacity={0.3}
            borderBottomWidth=".3px"
            my={3}
          />

          {products.length ? (
            <>
              <Products
                products={products}
                showTotal={Boolean(products.length) && Boolean(services.length)}
              />
              <Divider
                borderColor="black"
                opacity={0.3}
                borderBottomWidth=".3px"
                my={3}
              />
            </>
          ) : null}
          <Resume order={order} records={records} />
        </Box>
      </Box>
    );
  }
);
