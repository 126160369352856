import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { useQueryClient } from "@tanstack/react-query";

import { STATUS } from "src/constants/requestStatus";
import { SchedulingDoneParamType } from "src/interfaces/scheduling";
import { updateSchedulingDone } from "src/services/scheduling";

import { SchedulingDoneContextType } from "./props";

export const SchedulingDoneContext = createContext(
  {} as SchedulingDoneContextType
);

export const SchedulingDoneProvider: React.FC = props => {
  const { children } = props;
  const queryClient = useQueryClient();
  const [status, setStatus] = useState(STATUS.inital);

  const done = async (params: SchedulingDoneParamType) => {
    try {
      setStatus(STATUS.loading);

      await updateSchedulingDone(params);

      setStatus(STATUS.success);

      toast.success("Agendamento finalizado com sucesso");
      queryClient.invalidateQueries({
        queryKey: ["schedulingAllTypes"],
      });
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao finalizar esse agendamento");
    }
  };

  return (
    <SchedulingDoneContext.Provider
      value={{
        status,
        done,
      }}
    >
      {children}
    </SchedulingDoneContext.Provider>
  );
};

export const useSchedulingDone = () => {
  const context = useContext(SchedulingDoneContext);

  if (!context) {
    throw new Error("useScheduling must be used within a SchedulingProvider");
  }

  return context;
};
