import React from "react";

import Box from "@material-ui/core/Box";

import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

interface InventoryBottomBarProps {
  totalAmount: number;
}

const InventoryBottomBar: React.FC<InventoryBottomBarProps> = props => {
  const { totalAmount } = props;

  return (
    <Styled.AppBar position="fixed" component="footer">
      <Styled.Container>
        <Box display="flex">
          <Box>
            <Styled.LabelTotalValue>Total</Styled.LabelTotalValue>
            <Styled.ValueData>{formatPrice(totalAmount)}</Styled.ValueData>
          </Box>
        </Box>
      </Styled.Container>
    </Styled.AppBar>
  );
};

export default InventoryBottomBar;
