import styled from "styled-components";

import AppBarUnstyled from "@material-ui/core/AppBar";

import Typography from "src/components/Typography";

export const AppBar = styled(AppBarUnstyled)`
  top: auto;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-top: 1px solid #d6d8dc;
  box-shadow: none;
  color: unset;
`;

export const Container = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 36px 25px;
`;

export const LabelData = styled(Typography)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 14px;
  text-align: left;
`;

export const ValueData = styled(Typography)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
  text-align: left;
`;
