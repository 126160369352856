import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Modal from "src/components/Modal";
import { useModal } from "src/components/Modal/useModal";
import { useOrder } from "src/hooks/order/useOrder";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";

import { useOrderType } from "../../useOrderType";
import { DeleteModalProps } from "./props";
import * as Styled from "./styles";

const DeleteModal: React.FC<DeleteModalProps> = props => {
  const { id, afterActions } = props;

  const { remove, order, deleteStatus } = useOrder();
  const { handleClose } = useModal();
  const { setRestartOrderList } = useOrderFilters();

  const { type } = useOrderType();

  const deleteService = async () => {
    await remove(id, type ?? order.type);
    handleClose();
    afterActions();
    setRestartOrderList(true);
  };

  return (
    <Modal>
      <Box p={3}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <div />
              <Styled.AlertIcon alignmentBaseline="middle" />
              <Styled.CloseButton onClick={handleClose} />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Styled.InfoText align="center" variant="h6">
              Tem certeza que deseja desabilitar essa venda?
            </Styled.InfoText>
          </Grid>
          <Grid item xs={12}>
            <Styled.DeleteButton
              variant="contained"
              onClick={deleteService}
              fullWidth
              size="large"
              disabled={deleteStatus.loading}
              loading={deleteStatus.loading}
            >
              Excluir
            </Styled.DeleteButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
