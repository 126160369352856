import React, { useContext, useState, createContext, useMemo } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import { useAuth } from "src/hooks/user/auth/useAuth";
import {
  CompanyType,
  CompanySettings,
  PaymentTermsType,
} from "src/interfaces/company";
import { RequestStatusType } from "src/interfaces/request";
import {
  updateUserCompany,
  updatePaymentTermsService,
} from "src/services/company";
import { extractNumbers } from "src/utils/extractNumbers";

import { CompanyContextType } from "./props";

export const CompayContext = createContext({} as CompanyContextType);

export const CompanyProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState<RequestStatusType>(STATUS.inital);
  const [statusPaymentTerms, setStatusPaymentTerms] =
    useState<RequestStatusType>(STATUS.inital);

  const { setUser, user } = useAuth();

  const userCompany = useMemo(() => {
    return user?.company ?? ({} as CompanyType);
  }, [user]);

  const update = async (params: Partial<CompanyType>) => {
    try {
      setStatus(STATUS.loading);

      const response = await updateUserCompany({
        ...params,
        cep: extractNumbers(params?.cep),
        cnpj: extractNumbers(params?.cnpj),
        cpf: extractNumbers(params?.cpf),
        phone: extractNumbers(params?.phone),
      });

      const dataUpdated = { ...userCompany, ...response };

      setUser({
        ...user,
        company: dataUpdated,
      });

      setStatus(STATUS.success);

      toast.success("Dados atualizados com sucesso");
    } catch (error) {
      toast.error("Falha ao alterar seus dados, tente novamente mais tarde");
      setStatus(STATUS.error);
    }
  };

  const updatePaymentTerms = async (params: Partial<PaymentTermsType>) => {
    try {
      setStatusPaymentTerms(STATUS.loading);

      const response = await updatePaymentTermsService({
        defaultPaymentTerm: params,
      } as CompanySettings);

      const dataUpdated = { ...userCompany, ...response.company };

      setUser({
        ...user,
        company: dataUpdated,
      });

      setStatusPaymentTerms(STATUS.success);

      toast.success("Condição de pagamento atualizada com sucesso");
    } catch (error) {
      toast.error(
        "Falha ao selecionar condição de pagamento, tente novamente mais tarde"
      );
      setStatusPaymentTerms(STATUS.error);
    }
  };

  const subContactNameDefinitions = useMemo(() => {
    return (
      userCompany?.occupationArea?.nameDefinitions?.subContact ?? {
        title: "Subcontato",
        abbreviation: "Subcontato",
        titlePlural: "Sub contatos",
      }
    );
  }, [userCompany]);

  const serviceOrderNameDefinitions = useMemo(() => {
    return (
      userCompany?.occupationArea?.nameDefinitions?.serviceOrder ?? {
        title: "Ordem de serviço",
        abbreviation: "OS",
        titlePlural: "Ordens de serviço",
      }
    );
  }, [userCompany]);

  const operatorNameDefinitions = useMemo(() => {
    return (
      userCompany?.occupationArea?.nameDefinitions?.operator ?? {
        title: "Vendedor",
        abbreviation: "Vd",
        titlePlural: "Vendedores",
      }
    );
  }, [userCompany]);

  const getNameDefinitionsFromKey = (key?: string) => {
    switch (key) {
      case "serviceOrder":
        return serviceOrderNameDefinitions;
      default:
        return undefined;
    }
  };

  return (
    <CompayContext.Provider
      value={{
        status,
        userCompany,
        update,
        updatePaymentTerms,
        statusPaymentTerms,
        subContactNameDefinitions,
        serviceOrderNameDefinitions,
        operatorNameDefinitions,
        getNameDefinitionsFromKey,
      }}
    >
      {children}
    </CompayContext.Provider>
  );
};

export const useCompany = () => {
  const context = useContext(CompayContext);

  if (!context) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }

  return context;
};
