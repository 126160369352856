import React, { useState, useEffect, useMemo } from "react";

import { Grid } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import { useFormikContext } from "formik";

import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import SearchByCustomer from "src/components/Inputs/AutoComplete";
import Typography from "src/components/Typography";
import { ContactProvider, useContact } from "src/hooks/contact/useContact";
import { ContactType } from "src/interfaces/contact";
import ContactManagerModal from "src/pages/Contacts/components/ContactManagerModal";
import { withContext } from "src/utils/withContext";

import * as Styled from "./styles";

const LIMIT = 10;

interface ProviderSectionProps {
  provider?: ContactType;
  setProvider: (value: ContactType) => void;
}

const ProviderSection: React.FC<ProviderSectionProps> = ({
  provider,
  setProvider,
}) => {
  const [options, setOptions] = useState<ContactType[]>([]);

  const { handleOpen } = useBaseSwipeableDrawer();

  const { list, contacts, status } = useContact();

  const [method, setMehtod] = useState<"add" | "update">("add");

  const { submitCount } = useFormikContext();

  useEffect(() => {
    setOptions(contacts.contacts || []);
  }, [contacts]);

  const searchQuery = (query: string) => {
    if (query.length) {
      list({ search: query, type: "provider", limit: LIMIT });
    } else {
      list({ type: "provider", limit: LIMIT });
    }
  };

  const renderValidText = useMemo(() => {
    if (!provider?.id && submitCount > 0) {
      return (
        <Styled.ValidationText>No mínimo um fornecedor</Styled.ValidationText>
      );
    }

    return null;
  }, [provider, submitCount]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          Fornecedor
        </Typography>
      </Grid>
      <Grid item xs={12} md={provider?.id ? 8 : 10}>
        <SearchByCustomer
          value={provider}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onChange={(_, newInputValue, __) => {
            const contact = newInputValue as ContactType;
            setProvider(contact);
          }}
          id="autocomplete-provider"
          options={options}
          setOptions={setOptions}
          loading={status.loading}
          searchQuery={searchQuery}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={option => option.name}
          initialRequest={() => searchQuery("")}
          searchFieldProps={{ placeholder: "Procurar fornecedor..." }}
          filterOptions={options => options}
        />
        {renderValidText}
      </Grid>
      {provider?.id ? (
        <Grid item xs={12} md={2}>
          <Styled.Button
            variant="contained"
            color="primary"
            onClick={() => {
              setMehtod("update");
              handleOpen("createContactModal");
            }}
            fullWidth
          >
            <EditIcon />
            <Typography variant="body1" firstletter>
              Editar
            </Typography>
          </Styled.Button>
        </Grid>
      ) : null}

      <Grid item xs={12} md={2}>
        <Styled.Button
          variant="contained"
          color="primary"
          onClick={() => {
            setMehtod("add");
            handleOpen("createContactModal");
          }}
          fullWidth
        >
          <AddCircleIcon />
          <Typography variant="body1" firstletter>
            Fornecedor
          </Typography>
        </Styled.Button>
        <ContactManagerModal
          method={method}
          contact={provider}
          setContact={setProvider}
          contactType="provider"
        />
      </Grid>
    </Grid>
  );
};

export default withContext(
  ProviderSection,
  ContactProvider,
  BaseSwipeableDrawerProvider
);
