import styled from "styled-components";

import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const DialogHeader = styled(MuiDialogTitle)`
  h2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 0;
  }
  padding: 24px 32px 14px;
`;

export const DialogTitle = styled(Typography)`
  width: 100%;
  text-align: center;
  color: #5b5c60;
  font-size: 20px;
  font-weight: 500;
`;

export const CloseButton = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  position: absolute;
  right: 0;
`;

export const DialogContent = styled(MuiDialogContent)`
  padding: 0;
`;

export const DialogActions = styled(MuiDialogActions)`
  padding: 16px 32px;
  flex-direction: column;
  &.MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 0;
  }
`;
