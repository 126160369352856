import React, { useState } from "react";
import { toast } from "react-toastify";

import { Formik } from "formik";

import { BaseSwipeableDrawerProvider } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { SigninRequestType } from "src/interfaces/user";
import { withContext } from "src/utils/withContext";

import PasswordForm from "./components/PasswordForm";
import SectionMockup from "./components/SectionMockup";
import UsernameForm from "./components/UsernameForm";
import schemaSignIn from "./schema";
import * as Styled from "./styles";

const SignIn: React.FC = () => {
  const { signIn, status, validateUsernameCode, signInCode } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data: SigninRequestType) => {
    try {
      if (!showPassword) {
        await validateUsernameCode(data.username.toLowerCase());
        setShowPassword(true);
      } else {
        if (data.password.length === 4) {
          signInCode(data);
        }
        if (data.password.length === 5) {
          toast("Verifique os Dados");
        }
        if (data.password.length > 5) {
          signIn(data);
        }
      }
    } catch (error) {
      toast.error(error as string);
    }
  };

  return (
    <Styled.Container maxWidth={false}>
      <Styled.Paper>
        <SectionMockup />
        <Styled.Logo />
        <Styled.SectionForm>
          <Formik
            onSubmit={onSubmit}
            initialValues={{ username: "", password: "" }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={schemaSignIn}
          >
            <Styled.Form>
              {showPassword ? <PasswordForm /> : <UsernameForm />}

              <Styled.SignInButton
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                loading={status.loading}
                disabled={status.loading}
                loadingColor="#074EE8"
              >
                {showPassword ? "Entrar" : "Próximo"}
              </Styled.SignInButton>

              {showPassword ? (
                <Styled.SignInButton
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={() => setShowPassword(false)}
                >
                  Voltar
                </Styled.SignInButton>
              ) : null}
            </Styled.Form>
          </Formik>
        </Styled.SectionForm>
        <Styled.FooterText>
          <strong>Azulou Tecnologia LTDA.</strong> CNPJ 42.827.632/0001-73.
          <br />
          Todos os direitos reservados.
        </Styled.FooterText>
      </Styled.Paper>
    </Styled.Container>
  );
};

export default withContext(SignIn, BaseSwipeableDrawerProvider);
