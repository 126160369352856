import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ProductType } from "src/interfaces/product";
import { GetResponseType } from "src/interfaces/request";
import api from "src/services/api";

export interface GetProductsResponseType extends GetResponseType {
  products: ProductType[];
}

interface ProductsParams {
  name?: string;
  page?: number;
  limit?: number;
}

export const getProducts = (params: ProductsParams) =>
  api
    .get<GetProductsResponseType>("v1/product", {
      params,
    })
    .then(res => res.data);

export function useProducts(
  params: ProductsParams,
  config?: UseQueryOptions<
    GetProductsResponseType,
    Error,
    GetProductsResponseType,
    unknown[]
  >
) {
  return useQuery<
    GetProductsResponseType,
    Error,
    GetProductsResponseType,
    unknown[]
  >(["products", params], () => getProducts(params), {
    refetchOnWindowFocus: false,
    ...(config || {}),
    initialData: {
      products: [],
      limit: 10,
      page: 1,
      totalCount: 0,
    },
  });
}
