import styled from "styled-components";

import { ReactComponent as ServiceIconUnstyled } from "src/assets/icons/emptyTable/services.svg";

export const ServiceIcon = styled(ServiceIconUnstyled)`
  margin-bottom: 15px;
`;

export const MarkedText = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;
