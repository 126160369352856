import * as yup from "yup";

export const schemaOpenCashFlowForm = yup.object({
  initMoney: yup
    .number()
    .required("Valor é obrigatório")
    .typeError("Valor é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
  actualMoney: yup
    .number()
    .required("Valor é obrigatório")
    .typeError("Valor é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
  financialAccount: yup.string().required("Conta é obrigatória"),
});
