import styled from "styled-components";

import DividerMUI from "@material-ui/core/Divider";

import ButtonUnstyled from "src/components/Button";
import Typography from "src/components/Typography";

export const Button = styled(ButtonUnstyled)`
  height: 50px;
  svg {
    font-size: 25px;
    margin-right: 8px;
  }
`;

export const Title = styled(Typography)`
  font-size: 1rem;
  color: #5b5c60;
  font-weight: 500;
`;

export const Divider = styled(DividerMUI)`
  margin-bottom: 1rem;
`;
