import { useState } from "react";

import { IconButton, InputGroup, InputRightElement } from "@chakra-ui/react";
import { Avatar, Box } from "@material-ui/core";
import DomainIcon from "@material-ui/icons/Domain";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useFormikContext } from "formik";

import { useAuth } from "src/hooks/user/auth/useAuth";
import { SigninRequestType } from "src/interfaces/user";

import * as Styled from "../styles";

const PasswordForm = () => {
  const { companyFromUsername } = useAuth();

  const { values } = useFormikContext<SigninRequestType>();

  const [showPasswordInput, setShowPasswordInput] = useState(false);

  return (
    <Styled.ContainerTop>
      <Box display="flex" justifyContent="space-between" mt="20px">
        <Box>
          <Styled.WelcomeText color="primary" variant="h5">
            Área do Cliente
          </Styled.WelcomeText>
          <Styled.FriendlyText color="primary" variant="body2">
            Seja bem-vindo!
          </Styled.FriendlyText>
          <Styled.FriendlyText color="primary" variant="body2">
            {values.username}
          </Styled.FriendlyText>
        </Box>
        <Box width={90} height={90}>
          <Avatar
            alt="company"
            style={{ width: "100%", height: "100%" }}
            src={companyFromUsername.logo}
          >
            <DomainIcon fontSize="large" />
          </Avatar>
        </Box>
      </Box>

      <InputGroup>
        <Styled.PasswordField
          name="password"
          label="Insira seu Codigo ou Senha"
          fullWidth
          required
          autoFocus
          type={showPasswordInput ? "text" : "password"}
        />
        <InputRightElement
          top="24px"
          height="calc(var(--chakra-sizes-12) - 2px)"
          w="auto"
          px={4}
          color="white"
        >
          <IconButton
            size="sm"
            aria-label="Mostrar/Esconder senha"
            onClick={() => setShowPasswordInput(prev => !prev)}
          >
            {!showPasswordInput ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </InputRightElement>
      </InputGroup>
    </Styled.ContainerTop>
  );
};

export default PasswordForm;
