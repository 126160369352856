import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@material-ui/core/Box";

import { useUpdateBuyOrder } from "src/hooks/buy_order/useUpdateBuyOrder";
import { sumArrayValues } from "src/utils/arrayUtil";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const AddBuyOrderBottomBar: React.FC = () => {
  const navigate = useNavigate();

  const { updateBuyOrderStatus, productsBuyOrder, discount } =
    useUpdateBuyOrder();

  const productsTotal = useMemo(() => {
    const productsTotal = productsBuyOrder.map(
      productBuyOrder => productBuyOrder.cost * productBuyOrder.quantity
    );

    return sumArrayValues(productsTotal);
  }, [productsBuyOrder]);

  const quantityProducts = useMemo(() => {
    const quantity = productsBuyOrder.map(
      productBuyOrder => productBuyOrder.quantity
    );

    return sumArrayValues(quantity);
  }, [productsBuyOrder]);

  const renderTotalValue = (
    <Box>
      <Styled.LabelTotalValue>
        Valor total
        <Styled.LabelIconEquals />
      </Styled.LabelTotalValue>
      <Styled.ValueData>
        {formatPrice(productsTotal - discount)}
      </Styled.ValueData>
    </Box>
  );

  const renderResume = useMemo(() => {
    return (
      <>
        <Box mr={4}>
          <Styled.LabelData>Qtd. de produtos</Styled.LabelData>
          <Styled.ValueData>{quantityProducts}</Styled.ValueData>
        </Box>
        <Box mr={4}>
          <Styled.LabelData>
            Valor em produtos
            <Styled.LabelIconPlus />
          </Styled.LabelData>
          <Styled.ValueData>{formatPrice(productsTotal)}</Styled.ValueData>
        </Box>
        <Box pr={4} mr={4} borderRight="solid #D6D8DC 1px">
          <Styled.LabelData>
            Desconto
            <Styled.LabelIconMinus />
          </Styled.LabelData>
          <Styled.ValueData isRed>{formatPrice(discount)}</Styled.ValueData>
        </Box>
        {renderTotalValue}
      </>
    );
  }, [productsTotal, quantityProducts, discount]);

  const renderButtons = useMemo(() => {
    return (
      <Box display="flex">
        <Box mr={1}>
          <Styled.Button
            color="primary"
            variant="outlined"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Styled.Button>
        </Box>
        <Box>
          <Styled.Button
            color="primary"
            variant="contained"
            type="submit"
            form="createBuyOrderForm"
            disabled={updateBuyOrderStatus.loading}
          >
            Salvar
          </Styled.Button>
        </Box>
      </Box>
    );
  }, [updateBuyOrderStatus]);

  return (
    <Styled.AppBar position="fixed" component="footer">
      <Styled.Container>
        <Box display="flex">{renderResume}</Box>
        {renderButtons}
      </Styled.Container>
    </Styled.AppBar>
  );
};

export default AddBuyOrderBottomBar;
