import { SigninRequestType } from "src/interfaces/user";
import api from "src/services/api";

/**
 * Autenticar usuário
 */
export const signIn = (params: SigninRequestType) =>
  api.post("v1/user/login", params).then(res => res.data);

/**
 * Autenticar usuário com Codigo
 */
export const signInCode = (params: SigninRequestType) =>
  api.post("v2/user/login", params).then(res => res.data);

/**
 * Reseta a senha do usuário
 */
export const validateUsernameService = (username: string) =>
  api.get(`v1/user/${username}`).then(res => res.data);

/**
 * validar userName com o codigo
 */
export const validateUsernameServiceCode = (username: string) =>
  api.get(`v2/user/${username}`).then(res => res.data);
