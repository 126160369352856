import { ProductType } from "src/interfaces/product";
import { ServiceType } from "src/interfaces/service";

export interface ProductOrderForm {
  id?: string;
  productId?: string;
  product?: ProductType;
  stock?: string;
  quantity: number;
  price: number;
  delete?: boolean;
  index?: number;
}

export interface ServiceOrderForm {
  id?: string;
  serviceId?: string;
  service?: ServiceType;
  quantity: number;
  price: number;
  delete?: boolean;
  index?: number;
}

export function isServiceOrderForm(
  data: ProductOrderForm | ServiceOrderForm
): data is ServiceOrderForm {
  return (data as ServiceOrderForm)?.service !== undefined;
}

export interface TechnicalReportDataForm {
  label: string;
  type: string;
  content: string;
}

export interface TechnicalReportGalleryForm {
  image?: File;
  index?: number;
  name: string;
  description?: string;
  imageUrl?: string;
}

export interface TechnicalReportForm {
  data: Array<TechnicalReportDataForm>;
  gallery: Array<TechnicalReportGalleryForm>;
  id?: string;
  delete?: boolean;
}

export interface OrderForm {
  // COMMON
  id?: string;
  type: string;
  // PRODUCT AND SERVICES
  products: Array<ProductOrderForm>;
  services: Array<ServiceOrderForm>;
  // ORDER DATA
  contactId?: string;
  subContactId?: string;
  operatorId?: string;

  deadline?: string;
  createdDate?: string;

  additionalInfo?: string;
  technicalReport?: TechnicalReportForm;
  // ODER PAYMENT
  paymentType?: string;

  discount?: number;
  discountType?: string;

  downPaymentType?: string;
  downPaymentValue?: number;

  remainingPaymentType?: string;
  remainingPaymentValue?: number;

  paymentMethod?: string;
  financialAccount?: string;
}
