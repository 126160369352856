import React, { useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import { format } from "date-fns";

import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { useOrder } from "src/hooks/order/useOrder";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const BasicData: React.FC = () => {
  const { order } = useOrder();
  const { operatorNameDefinitions } = useCompany();

  const { serviceOrderModule, productOrderModule } = useModule();

  const formatterDate = (date?: string, withoutHour?: boolean) => {
    if (date) {
      return format(
        new Date(date),
        withoutHour ? "dd/MM/yyyy" : "dd/MM/yyyy - HH:mm"
      );
    }
    return "-";
  };

  const createdDateFormatter = useMemo(() => {
    if (order.type === "productOrder") {
      return formatterDate(
        order.createdDate,
        productOrderModule?.settings?.createdAtType === "date"
      );
    }
    return formatterDate(
      order.createdDate,
      serviceOrderModule?.settings?.createdAtType === "date"
    );
  }, [order, serviceOrderModule, productOrderModule]);

  const deadlineFormatter = useMemo(() => {
    if (order.type === "productOrder") {
      return formatterDate(
        order.deadline,
        productOrderModule?.settings?.dueDateType === "date"
      );
    }
    return formatterDate(
      order.deadline,
      serviceOrderModule?.settings?.dueDateType === "date"
    );
  }, [order, serviceOrderModule, productOrderModule]);

  return (
    <>
      <Grid item>
        <Styled.LabelText>Data de criação</Styled.LabelText>
        <Styled.LabelValue>{createdDateFormatter}</Styled.LabelValue>
      </Grid>
      <Grid item>
        <Styled.LabelText>Prazo de entrega</Styled.LabelText>
        <Styled.LabelValue>{deadlineFormatter}</Styled.LabelValue>
      </Grid>
      <Grid item>
        <Styled.LabelText>Valor total</Styled.LabelText>
        <Styled.LabelValue>{formatPrice(order.totalAmount)}</Styled.LabelValue>
      </Grid>

      {order.operator ? (
        <Grid item>
          <Styled.LabelText>{operatorNameDefinitions.title}</Styled.LabelText>
          <Styled.LabelValue>{order.operator?.name}</Styled.LabelValue>
        </Grid>
      ) : null}
    </>
  );
};

export default BasicData;
