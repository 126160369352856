import React, { useMemo } from "react";

import Grid from "@material-ui/core/Grid";

import Textarea from "src/components/Inputs/Textarea";
import TextField from "src/components/Inputs/TextField";
import { useCompany } from "src/hooks/company/useCompany";
import { uppercaseFirstLetter } from "src/utils/format";

const TechnicalReportFields: React.FC = () => {
  const { userCompany } = useCompany();

  const fields = userCompany?.technicalReportFields;

  const renderTextFields = useMemo(() => {
    if (fields?.length) {
      return fields
        .filter(item => item.type === "text")
        .map(field => (
          <Grid item xs={12} key={field.id}>
            <TextField
              name={field.label}
              label={uppercaseFirstLetter(field.label)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        ));
    }

    return null;
  }, [fields]);

  const renderTextareaFields = useMemo(() => {
    if (fields?.length) {
      return fields
        .filter(item => item.type === "textarea")
        .map(field => (
          <Grid item xs={12} key={field.id}>
            <Textarea
              name={field.label}
              label={uppercaseFirstLetter(field.label)}
              rows={3}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        ));
    }

    return null;
  }, [fields]);

  return (
    <Grid container spacing={2}>
      {renderTextFields}
      {renderTextareaFields}
    </Grid>
  );
};

export default TechnicalReportFields;
