import * as yup from "yup";

import { PASSWORD_SIZE_MESSAGE } from "src/constants/password";

export const schemaUserForm = yup.object({
  id: yup.string(),
  name: yup.string().required("O nome é obrigatório."),
  email: yup
    .string()
    .email("E-mail inválido.")
    .required("O e-mail é obrigatório."),
  password: yup.string().when("id", (id, schema) => {
    return schema.test({
      test: (password = "") =>
        (id !== undefined && !password.length) || password.length >= 6,
      message: PASSWORD_SIZE_MESSAGE,
    });
  }),
  username: yup
    .string()
    .required("O campo usuário é obrigatório")
    .min(3, "Usuário deve conter no mínimo 3 caracteres")
    .max(36, "Usuário deve conter no máximo 36 caracteres")
    .matches(/^[a-z0-9]+$/, "Usuário deve conter somente letras e números."),
  operator: yup.object({
    comission: yup.string(),
  }),
});
