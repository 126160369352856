import styled from "styled-components";

import MuiContainer from "@material-ui/core/Container";
import { Form as FormUnstyled } from "formik";

import { ReactComponent as LogoUnstyled } from "src/assets/azulou-logo.svg";
import Button from "src/components/Button";
import PasswordFieldUnstyled from "src/components/Inputs/PasswordField";
import TextFieldUnstyled from "src/components/Inputs/TextField";
import Typography from "src/components/Typography";

export const Container = styled(MuiContainer)`
  width: 100%;
  min-height: 100vh;
  padding: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #e5e5e5;
  }
`;

export const Paper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: grid;
  grid-template-areas: "logo" "mockup" "content";
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    border-radius: 10px;
    max-width: 850px;
    max-height: 470px;
    grid-template-areas:
      "mockup logo"
      "mockup content";
    grid-template-columns: 450px 1fr;
    grid-template-rows: 100px 1fr;
    box-shadow: 0px 16px 46px 16px rgba(39, 72, 145, 0.25);
  }
`;

export const SignInButton = styled(Button)`
  width: 100%;
  height: 50px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: #fff;
  margin-top: 16px;

  &.MuiButton-contained.Mui-disabled {
    background-color: white;
    opacity: 0.5;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const SignWithGoogle = styled(Button)`
  width: 100%;
  height: 50px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: #fff;

  &.MuiButton-contained.Mui-disabled {
    background-color: white;
    opacity: 0.5;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Logo = styled(LogoUnstyled)`
  path {
    fill: white;
  }
  grid-area: logo;
  width: 160px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 120px;
    margin: unset;
    margin-top: 30px;
  }
`;

export const WelcomeText = styled(Typography)`
  font-weight: bolder;
  color: white;
`;

export const FriendlyText = styled(Typography)`
  line-height: 28px;
  font-size: 14px;
  font-weight: 500;
  color: white;
`;

export const FooterText = styled(Typography)`
  color: white;
  text-align: center;
  font-size: 12px;
  margin: 1.5rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #b3b6bc;
  }
`;

export const SectionForm = styled.div`
  grid-area: content;
`;

export const Form = styled(FormUnstyled)`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 0 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 320px;
    padding: 0;
  }
`;

export const ContainerTop = styled.div`
  width: 100%;
`;

export const ForgetPasswordContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const TextField = styled(TextFieldUnstyled)`
  margin-top: 50px;
  & label {
    color: white;
    background-color: transparent;
  }
  & label.Mui-focused {
    color: white;
    background-color: transparent;
  }

  & .MuiInputBase-input {
    color: white;
    border-color: white;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
`;
export const PasswordField = styled(PasswordFieldUnstyled)`
  margin-top: 20px;
  position: relative;
  & label {
    color: white;
    background-color: transparent;
  }
  & label.Mui-focused {
    color: white;
    background-color: transparent;
  }

  & .MuiInputBase-input {
    color: white;
    border-color: white;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
`;
