import styled from "styled-components";

import { Box } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpwardRounded";

import Typography from "src/components/Typography";

export const BalanceValue = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
`;

export const CurrencyText = styled(Typography)<{ redCurrency?: boolean }>`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme, redCurrency }) =>
    redCurrency ? theme.palette.error.main : theme.palette.primary.main};
`;

export const CurrencyLabel = styled(Typography)`
  font-size: 10px;
  font-weight: 400;
  color: #5b5c60;
`;

export const ArrowUpward = styled(ArrowUpwardIcon)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

export const ArrowDownward = styled(ArrowDownwardIcon)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.error.main};
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

export const TooltipContent = styled(Box)`
  padding: 8px;
  background-color: #5b5c60;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
`;

export const TooltipText = styled(Typography)`
  font-size: 10px;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const ResponsiveContainerOverflow = styled(Box)`
  overflow-x: scroll;
  overflow-y: hidden;
`;
