import React, { FC } from "react";

import { Flex, Heading } from "@chakra-ui/react";

import PATHS from "src/constants/paths";
import { Can, usePermission } from "src/hooks/permission/usePermission";

import ReportCard from "../ReportCard";

const ClientReportsTab: FC = () => {
  const { can } = usePermission();

  if (
    !can([
      { subject: "reportSalesByClient", action: "view" },
      { subject: "reportLateClients", action: "view" },
      { subject: "reportClients", action: "view" },
      { subject: "reportProviders", action: "view" },
    ])
  ) {
    return (
      <Flex flex={1} justify="center" align="center">
        <Heading as="h3" fontSize="md" textAlign="center">
          Você não tem permissão para acessar os relatórios de clientes.
        </Heading>
      </Flex>
    );
  }

  return (
    <>
      <Can subject="reportSalesByClient" action="view">
        <ReportCard
          title="Vendas por cliente"
          subtitle="Gere um relatório de vendas por cliente e faça uma análise detalhada sobre cada cliente, é possível também exportar essas informações .xls."
          path={PATHS.REPORTS_SALES_BY_CLIENT}
        />
      </Can>
      <Can subject="reportLateClients" action="view">
        <ReportCard
          title="Clientes atrasados"
          subtitle="Gere um relatório de clientes atrasados com informações sobre data de atraso, dias em atraso e valores, é possível também exportar essas informações em .xls."
          path={PATHS.REPORTS_LATE_CLIENTS}
        />
      </Can>
      <Can subject="reportClients" action="view">
        <ReportCard
          title="Lista de clientes"
          subtitle="Visualize os seus clientes e suas informações, é possível também exportar essas informações em .xls."
          path={PATHS.REPORTS_CLIENTS}
        />
      </Can>
      <Can subject="reportProviders" action="view">
        <ReportCard
          title="Lista de fornecedores"
          subtitle="Visualize os seus fornecedores e suas informações, é possível também exportar essas informações em .xls."
          path={PATHS.REPORTS_PROVIDERS}
        />
      </Can>
    </>
  );
};

export default ClientReportsTab;
