import React, { useState, useEffect } from "react";

import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { startOfMonth, endOfMonth, format } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { Form, Formik } from "formik";

import { ReactComponent as ExcelIcon } from "src/assets/icons/reports/excel.svg";
import Button from "src/components/Button";
import SearchByCustomer from "src/components/Inputs/AutoComplete";
import SelectField from "src/components/Inputs/SelectField";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import { ContactProvider, useContact } from "src/hooks/contact/useContact";
import { Can } from "src/hooks/permission/usePermission";
import { ReportProvider, useReport } from "src/hooks/report/useReport";
import { ContactType } from "src/interfaces/contact";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { formatNumber, maskDocument } from "src/utils/format";
import { generateXLSX } from "src/utils/generateXLSX";
import { withContext } from "src/utils/withContext";

import InformationInitialReportState from "../components/InformationInitialReportState";
import ClientsTable from "./components/ClientsTable";
import SalesByClientBottomBar from "./components/SalesByClientBottomBar";
import * as Styled from "./styled";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Vendas por cliente" },
];

const today = new Date();
const LIMIT = 10;

const SalesByClientPage = () => {
  const { reportSales, status, getReportSales } = useReport();
  const { userCompany } = useCompany();
  const STATUSES = [
    { name: "Todos", value: "" },
    ...(userCompany?.status?.map(status => ({
      name: status.name,
      value: status.id,
    })) ?? []),
  ];

  const [exportLoading, setExportLoading] = useState(false);
  const [statusOrder, setStatusOrder] = useState("");
  const [options, setOptions] = useState<ContactType[]>([]);
  const [customer, setCustomer] = useState<ContactType | undefined>(undefined);
  const { list, contacts, status: contactStatus } = useContact();

  const [startDate, setStartDate] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(endOfMonth(today));

  const [startDateLegend, setStartDateLegend] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDateLegend, setEndDateLegend] = useState<Date | undefined>(
    endOfMonth(today)
  );

  useEffect(() => {
    setOptions(contacts.contacts || []);
  }, [contacts]);

  const handleGenerateReport = () => {
    setStartDateLegend(startDate);
    setEndDateLegend(endDate);
    getReportSales({
      startDate: formatStartDateISO(startDate || today),
      endDate: formatEndDateISO(endDate || today),
      contactId: customer?.id ?? "",
      status: statusOrder,
    });
  };

  const searchQuery = (query: string) => {
    if (query.length) {
      list({ search: query, type: "customer", limit: LIMIT });
    } else {
      list({ type: "customer", limit: LIMIT });
    }
  };

  const initialRequest = () => list({ type: "customer", limit: LIMIT });

  const renderDocument = (contact?: ContactType) => {
    if (!contact) {
      return null;
    }

    if (contact?.cpf) {
      return maskDocument(contact?.cpf);
    }

    if (contact?.cnpj) {
      return maskDocument(contact?.cnpj);
    }

    return "-";
  };

  const exportToXLSX = async () => {
    if (exportLoading) return;
    if (reportSales?.result?.length) {
      setExportLoading(true);
      const header = [
        "Nº",
        "Data da venda",
        "Cliente",
        "CPF/CNPJ",
        "Status",
        "Valor bruto",
        "Desconto",
        "Valor",
        "Forma de pagamento",
      ];
      const data = reportSales.result.reduce<string[][]>((data, sale) => {
        const row = [
          sale.code,
          sale?.createdDate
            ? format(new Date(sale?.createdDate), "dd/MM/yyyy")
            : "-",
          sale?.contact?.name ?? "-",
          renderDocument(sale?.contact),
          sale?.status?.name ?? "-",
          formatNumber(sale.grossAmount),
          formatNumber(sale.discount),
          formatNumber(sale.totalAmount),
          sale?.paymentMethods?.join(", ") ?? "-",
        ] as Array<string>;
        data.push(row);
        return data;
      }, []);

      const footer = [
        ["", "", "", "", "", "", "", ""],
        [
          "",
          "",
          "",
          "",
          "Total",
          formatNumber(reportSales?.totals?.grossAmount ?? 0),
          formatNumber(reportSales?.totals?.discount ?? 0),
          formatNumber(reportSales?.totals?.totalAmount ?? 0),
        ],
      ];

      await generateXLSX(
        header,
        [...data, ...footer],
        "Vendas por cliente",
        "Vendas por cliente.xlsx"
      );
      setExportLoading(false);
    }
  };

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Vendas por cliente" />
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-end"
        width="100%"
        style={{ gap: ".5rem" }}
      >
        <PeriodFilterPicker
          startDate={startDate || today}
          endDate={endDate || today}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          initialFilter="byMonth"
          options={["perInterval", "byMonth"]}
          showingInPopover={false}
        />
        <Box minWidth={150} flexGrow={1}>
          <SearchByCustomer
            value={customer}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onChange={(_, newInputValue, __) => {
              setCustomer(newInputValue as ContactType);
            }}
            id="autocomplete-customer"
            options={options}
            setOptions={setOptions}
            loading={contactStatus.loading}
            searchQuery={searchQuery}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            initialRequest={initialRequest}
            searchFieldProps={{ placeholder: "Procurar cliente..." }}
            filterOptions={options => options}
          />
        </Box>
        <Box minWidth={150} flexGrow={1}>
          <Formik
            // eslint-disable-next-line no-console
            onSubmit={v => console.log(v)}
            initialValues={{
              statusOrder,
            }}
          >
            {({ values }) => (
              <Form>
                <SelectField
                  defaultValue=""
                  label="Status"
                  name="statusOrder"
                  items={STATUSES}
                  style={{ backgroundColor: "white" }}
                />
                {setStatusOrder(values.statusOrder)}
              </Form>
            )}
          </Formik>
        </Box>
        <Box mt={1} display="flex" width="100%" justifyContent="flex-end">
          <Button
            style={{ height: 56, marginLeft: 8 }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={handleGenerateReport}
          >
            Gerar relatório
          </Button>
          <Can subject="reportSalesByClient" action="export">
            <Button
              style={{ height: 56, marginLeft: 8, minWidth: 120 }}
              color="primary"
              variant="outlined"
              size="medium"
              onClick={exportToXLSX}
              loading={exportLoading}
            >
              Exportar
              <ExcelIcon style={{ marginLeft: 10 }} />
            </Button>
          </Can>
        </Box>
      </Box>
      <Box my={3}>
        <Divider />
      </Box>

      {status.initial ? <InformationInitialReportState /> : null}
      {!status.initial && !status.loading ? (
        <>
          <Styled.Legend color="primary" variant="h6">
            De
            <strong>
              {startDateLegend
                ? format(startDateLegend, " dd/MM/yyyy ", {
                    locale: ptBrLocale,
                  })
                : ""}
            </strong>
            até
            <strong>
              {endDateLegend
                ? format(endDateLegend, " dd/MM/yyyy", { locale: ptBrLocale })
                : ""}
            </strong>
          </Styled.Legend>
          <ClientsTable reportSales={reportSales} />
          <SalesByClientBottomBar
            grossAmount={reportSales?.totals?.grossAmount ?? 0}
            discount={reportSales?.totals?.discount ?? 0}
            totalAmount={reportSales?.totals?.totalAmount ?? 0}
          />
        </>
      ) : null}
      {status.loading ? (
        <Box height="100%">
          <Loader size="medium" />
        </Box>
      ) : null}
    </DashboardLayout>
  );
};

export default withContext(SalesByClientPage, ContactProvider, ReportProvider);
