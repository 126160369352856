import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";
import InputMask, { Props as InputMaskProps } from "react-input-mask";

import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormLabel,
  FormLabelProps,
  Textarea as ChakraTextarea,
  TextareaProps,
} from "@chakra-ui/react";

interface ChakraTextareaProps extends TextareaProps {
  name: string;
  label?: string;
  error?: FieldError;
  inputMaskProps?: InputMaskProps;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  formErrorMessageProps?: FormErrorMessageProps;
}

const ChakraTextareaBase: ForwardRefRenderFunction<
  HTMLInputElement,
  ChakraTextareaProps
> = (
  {
    name,
    label,
    error = null,
    isDisabled,
    formControlProps = {},
    formLabelProps = {},
    formErrorMessageProps = {},
    inputMaskProps = {},
    ...rest
  },
  ref
) => {
  return (
    <FormControl
      isInvalid={!!error}
      isDisabled={isDisabled}
      variant="floating"
      {...formControlProps}
    >
      <Box w="100%" position="relative">
        <ChakraTextarea
          id={name}
          name={name}
          variant="outline"
          ref={ref}
          placeholder=" "
          sx={{
            borderColor: "rgba(19, 34, 62, 0.25)",
            borderRadius: 10,
            "&:not(:placeholder-shown)": {
              borderColor: "primary.500",
              color: "primary.500",
            },
            _disabled: {
              opacity: 0.5,
            },
            _focus: {
              borderColor: "primary.500",
              boxShadow: "none",
            },
            _hover: {
              borderColor: "primary.600",
            },
            _invalid: {
              borderColor: "error.500",
              boxShadow: "none",
              color: "error.500",
              "&:not(:placeholder-shown)": {
                color: "error.500",
                borderColor: "error.500",
              },
              _focusVisible: {
                boxShadow: "none",
                borderColor: "error.500",
              },
            },
          }}
          as={Object.keys(inputMaskProps).length ? InputMask : undefined}
          {...(inputMaskProps as any)}
          {...rest}
        />
        {!!label && (
          <FormLabel htmlFor={name} {...formLabelProps}>
            {label}
          </FormLabel>
        )}
      </Box>
      {!!error && (
        <FormErrorMessage {...formErrorMessageProps}>
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export const Textarea = forwardRef(ChakraTextareaBase);
