import styled from "styled-components";

import { Box } from "@material-ui/core";

import Typography from "src/components/Typography";

export const Container = styled(Box)`
  max-width: 450px;
  width: 100%;
`;

export const SuffixQuantityField = styled(Typography)`
  background-color: #d9d9d9;
  padding: 1rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }
`;
