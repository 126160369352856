import styled from "styled-components";

import DialogUnstyled from "@material-ui/core/Dialog";
import MuiPaper from "@material-ui/core/Paper";

export const Dialog = styled(DialogUnstyled)`
  .MuiDialog-paper {
    overflow-x: hidden;
    width: 600px;
  }
`;

export const Container = styled(MuiPaper)`
  padding: 20px;
  width: 100%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: none;
`;
