import { isAfter } from "date-fns";
import * as yup from "yup";

export const schemaCreateOrderRecordForm = yup.object({
  // Common Section //
  value: yup
    .number()
    .typeError("Valor é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
  hasAccountManagement: yup.bool(),
  financialAccount: yup
    .string()
    .when("hasAccountManagement", hasAccountManagement => {
      if (hasAccountManagement) {
        return yup.string().required("Conta financeira é obrigatória");
      }
      return yup.string();
    }),
  // Payment Section //
  paidStatus: yup.string().required("O status do pagamento é obrigatório"),
  paidDate: yup
    .string()
    .when(["paidStatus"], paidStatus => {
      if (paidStatus === "paid") {
        return yup
          .string()
          .required("Data de pagamento é obrigatória")
          .test("is-valid-date", "Data de pagamento inválida", value => {
            const isValidDate =
              !!value && !isAfter(new Date(value), new Date());
            return isValidDate;
          });
      }
      return yup.string().required("Prazo é obrigatório");
    })
    .required("Data é obrigatório"),
  paymentMethod: yup
    .string()
    .when(["paidStatus", "type"], (paidStatus, type) => {
      if (paidStatus === "paid" && type === "income") {
        return yup.string().required("Forma de pagamento é obrigatória");
      }
      return yup.string();
    }),

  // Advanced Section //
  emissionDate: yup.string().required("Data de emissão é obrigatória"),
});
