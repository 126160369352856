export const useStorage = () => {
  function setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify({ value }));
  }

  function getItem<T>(key: string, otherwise?: T): T | null {
    const data: string | null = localStorage.getItem(key);

    if (data !== null) {
      return JSON.parse(data).value;
    }

    if (otherwise) {
      return otherwise;
    }

    return null;
  }

  function removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  function clear(): void {
    localStorage.clear();
  }

  return { getItem, setItem, removeItem, clear };
};
