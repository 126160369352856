import styled from "styled-components";

import MuiDrawer from "@material-ui/core/Drawer";

import Typography from "src/components/Typography";

const drawerWidth = 250;

export const Root = styled.div<{ hidden?: boolean }>`
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visibility")};
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  height: ${({ hidden }) => (hidden ? 0 : "auto")};
  display: flex;
  flex-direction: column;
`;

export const Drawer = styled(MuiDrawer)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: ${drawerWidth}px;

  .MuiDrawer-paper {
    height: 100vh;
    width: ${drawerWidth}px;
    // Adicionar ao Tema
    background-color: #f8f9fa;
    box-shadow: 0px 8px 12px rgba(104, 104, 104, 0.16);
    border: none;
  }
`;

export const ContentDrawer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const Main = styled.main`
  flex-grow: 1;
  padding-left: ${drawerWidth}px;
  padding-bottom: 100px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding-left: 0px;
  }
`;

export const HelpText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  svg {
    margin-left: 3px;
    font-size: 15px;
  }
  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
