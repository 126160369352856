import React, { useEffect, useState, useMemo, useRef } from "react";

import { Divider, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { startOfMonth, endOfMonth, format, getMonth } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

import { BaseModalProvider } from "src/components/BaseModal/useBaseModal";
import { BaseSwipeableDrawerProvider } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import { useCompany } from "src/hooks/company/useCompany";
import { DashboardProvider } from "src/hooks/dashboard/useDashboard";
import {
  FinancialProvider,
  useFinancial,
} from "src/hooks/financial/useFinancial";
import {
  FinancialAccountProvider,
  useFinancialAccount,
} from "src/hooks/financialAccount/useFinancialAccount";
import { useModule } from "src/hooks/module/useModule";
import { RecordProvider, useRecord } from "src/hooks/record/useRecord";
import { FinancialAccountType } from "src/interfaces/financialAccount";
import { formatPrice } from "src/utils/format";
import { withContext } from "src/utils/withContext";

import FinancialAccountField from "../components/FinancialAccountField";
import ChargebackRecordModal from "../components/Modals/ChargebackRecordModal";
import DeleteRecordModal from "../components/Modals/DeleteRecordModal";
import DeleteTransferModal from "../components/Modals/DeleteTransferModal";
import PayRecordModal from "../components/Modals/PayRecordModal";
import RecordFormModal, {
  RecordFormOriginEnum,
} from "../components/Modals/RecordFormModal";
import TransferFormModal, {
  TransferFormOriginEnum,
} from "../components/Modals/TransferFormModal";
import RecordCard from "../components/RecordCard";
import * as Styled from "./styles";

const today = new Date();

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Extrato de contas" },
];

const AccountStatementPage: React.FC = () => {
  const {
    updateStatus,
    deleteStatus,
    payStatus,
    chargebackStatus,
    record,
    setRecord,
    restartRecordList,
    handleRestartRecordList,
  } = useRecord();

  const { list, status, rows } = useFinancial();
  const { hasModules } = useModule();
  const { userCompany } = useCompany();
  const { list: listAccounts, accounts } = useFinancialAccount();

  const [startDate, setStartDate] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(endOfMonth(today));
  const [financialAccount, setFinancialAccount] = useState<string | undefined>(
    "all"
  );

  const loaderRef = useRef<HTMLDivElement>(null);
  const [sliceAt, setSliceAt] = useState(2);

  useEffect(() => {
    if (hasModules("accountManagement")) {
      listAccounts({ status: "enabled" });
    }
  }, [userCompany]);

  useEffect(() => {
    if (!startDate) return;
    list({
      monthNumber: getMonth(startDate),
      financialAccount:
        financialAccount === "all" ? undefined : financialAccount,
    });
    setSliceAt(2);
  }, [
    startDate,
    endDate,
    updateStatus.success,
    deleteStatus.success,
    payStatus.success,
    chargebackStatus.success,
    financialAccount,
    restartRecordList,
  ]);

  const rowsSplitted = useMemo(() => rows.slice(0, sliceAt), [rows, sliceAt]);

  const renderRecordGroupCards = useMemo(() => {
    if (status.loading) {
      return <Loader size="medium" />;
    }

    return (
      <>
        {rowsSplitted.map((group, index) => {
          return (
            <Styled.ContainerGroup
              key={index.toString()}
              isExpense={group.balance < 0}
              initial={group.initial}
            >
              <Styled.DateTitle>
                {format(new Date(group.date), "LLL dd", { locale: ptBrLocale })}{" "}
                - Saldo do dia:{" "}
                <Styled.TotalLabel isExpense={group.balance < 0}>
                  {formatPrice(group.balance)}
                </Styled.TotalLabel>
              </Styled.DateTitle>
              {group.records?.map(record => (
                <RecordCard key={record.id} isAccountStatement {...record} />
              ))}
            </Styled.ContainerGroup>
          );
        })}
        <div ref={loaderRef}>
          {rows.length !== rowsSplitted.length ? (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <CircularProgress size={30} />
            </Box>
          ) : null}
        </div>
      </>
    );
  }, [rowsSplitted, status, loaderRef, rows]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "45% 0px 0px 0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(entities => {
      const target = entities[0];

      if (target.isIntersecting && rows.length !== rowsSplitted.length) {
        setSliceAt(prev => prev + 1);
      }
    }, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [rows, rowsSplitted, status]);

  return (
    <DashboardLayout
      selected="financial"
      subSelected="financial-account-statement"
      modules="accountManagement"
    >
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />

      <PageTitle label="Extrato de contas">
        <Box display="flex">
          <PeriodFilterPicker
            startDate={startDate || new Date()}
            endDate={endDate || new Date()}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            initialFilter="byMonth"
            options={["byMonth"]}
          />
          <Box width={200} ml={2}>
            <FinancialAccountField
              required
              value={financialAccount}
              setValue={(value: FinancialAccountType | null) =>
                setFinancialAccount(value?.id)
              }
              placeholder="Conta"
              label="Conta"
              accounts={[
                {
                  name: "Todas",
                  id: "all",
                  isDefault: true,
                } as FinancialAccountType,
                ...accounts,
              ]}
            />
          </Box>
        </Box>
      </PageTitle>
      <Styled.Container>{renderRecordGroupCards}</Styled.Container>
      <ChargebackRecordModal
        record={record}
        onSuccess={recordResponse => {
          setRecord(recordResponse);
          handleRestartRecordList();
        }}
      />
      <PayRecordModal
        record={record}
        onSuccess={recordResponse => {
          setRecord(recordResponse);
          handleRestartRecordList();
        }}
      />
      <RecordFormModal
        handleCloseRecordFormModal={() => {
          setRecord(undefined);
        }}
        onSuccess={() => {
          handleRestartRecordList();
        }}
        record={record}
        recordFormOrigin={RecordFormOriginEnum.accountStatement}
      />
      <DeleteRecordModal
        record={record}
        onSuccess={() => {
          setRecord(undefined);
          handleRestartRecordList();
        }}
      />
      <TransferFormModal
        onSuccess={() => {
          handleRestartRecordList();
        }}
        handleCloseTransferFormModal={() => {
          setRecord(undefined);
        }}
        record={record}
        transferFormOrigin={TransferFormOriginEnum.accountStatement}
      />
      <DeleteTransferModal
        record={record}
        onSuccess={() => {
          setRecord(undefined);
          handleRestartRecordList();
        }}
      />
    </DashboardLayout>
  );
};

export default withContext(
  AccountStatementPage,
  RecordProvider,
  FinancialAccountProvider,
  BaseModalProvider,
  DashboardProvider,
  FinancialProvider,
  BaseSwipeableDrawerProvider
);
