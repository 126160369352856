import styled from "styled-components";

import { Box } from "@material-ui/core";

import Button from "src/components/Button";

export const StatusIndicator = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 12px;
`;

export const SelectStatus = styled(Button)`
  width: 220px;
  height: 50px;
  padding: 0;
  margin-right: 8px;
`;

export const MenuOverlay = styled(Box)`
  border-radius: 3px;
  position: relative;
  padding-bottom: 1rem;
`;
export const MenuContainer = styled(Box)`
  max-width: 200px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.3);
`;

export const ButtonOption = styled(Button)`
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  border-radius: 0px;
  text-align: left;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: #fff;
  }
`;
