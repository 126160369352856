import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface ChangeCompanySlugType {
  slug: string;
}

export const changeCompanySlug = (params: ChangeCompanySlugType) =>
  api.patch(`/v1/company/slug`, params).then(res => res.data);

export function useChangeCompanySlug(
  config?: UseMutationOptions<any, any, ChangeCompanySlugType, unknown>
) {
  return useMutation<any, any, ChangeCompanySlugType>(
    (data: ChangeCompanySlugType) => changeCompanySlug(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ??
            "Ocorreu um problema ao alterar o usuário empresa"
        );
      },
      ...config,
    }
  );
}
