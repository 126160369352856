import React, { FC, useMemo } from "react";

import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import { BarChart, Bar, XAxis, Legend, ResponsiveContainer } from "recharts";

import Panel from "src/components/Layouts/Panel";
import { SHORT_NAME_MONTHS } from "src/constants/months";
import { useDashboard } from "src/hooks/dashboard/useDashboard";

const ExpenseChart: FC = () => {
  const { financialResumeRecords } = useDashboard();
  const { palette } = useTheme();

  const data = useMemo(
    () =>
      financialResumeRecords.map(month => ({
        name: SHORT_NAME_MONTHS[month.month - 1],
        expense: month.expensePaid,
        income: month.incomePaid,
      })),
    [financialResumeRecords]
  );

  return (
    <Panel padding={3}>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart width={150} height={40} data={data}>
          <XAxis dataKey="name" />
          <Bar dataKey="income" fill={palette.primary.main} />
          <Bar dataKey="expense" fill={palette.error.main} />
          <Legend
            iconType="circle"
            wrapperStyle={{ bottom: -20 }}
            payload={[
              {
                value: "Receita",
                id: "income",
                color: palette.primary.main,
              },
              {
                value: "Despesa",
                id: "expense",
                color: palette.error.main,
              },
            ]}
          />
        </BarChart>
      </ResponsiveContainer>
      <Box height={10} />
    </Panel>
  );
};

export default ExpenseChart;
