import * as React from "react";

import Box from "@material-ui/core/Box";

import Panel from "src/components/Layouts/Panel";
import Typography from "src/components/Typography";

import * as Styled from "./styles";

const EmptyLaunchTable = () => {
  return (
    <Panel padding={3}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Styled.FinancialIcon />
        <Typography variant="subtitle1">
          Nenhum lançamento encontrado
        </Typography>
      </Box>
    </Panel>
  );
};

export default EmptyLaunchTable;
