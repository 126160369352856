import React, { useEffect, useMemo, useState } from "react";
import Zoom from "react-medium-image-zoom";

import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { format } from "date-fns";
import { Form, Formik } from "formik";

import "react-medium-image-zoom/dist/styles.css";

import Button from "src/components/Button";
import SelectField from "src/components/Inputs/SelectField";
import { useModal } from "src/components/Modal/useModal";
import RenderModule from "src/components/RenderModule";
import Typography from "src/components/Typography";
import { COLOR_STATUS } from "src/constants/orders";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { useStatusOrder } from "src/hooks/order/useStatusOrder";

import { DetailOrderProps } from "./props";
import * as Styled from "./styles";

const DetailOrderModal: React.FC<DetailOrderProps> = props => {
  const { order } = props;

  const [touchedStatusField, setTouchedStatusField] = useState(false);
  const [status, setStatus] = useState(order.status.id);

  const { userCompany } = useCompany();
  const { updateStatus, updateRequestStatus } = useStatusOrder();

  const { serviceOrderModule } = useModule();

  const { palette } = useTheme();

  useEffect(() => {
    if (status !== order.status.id) {
      updateStatus(order.id, status);
    }
  }, [touchedStatusField, status]);

  const ORDER_STATUS = useMemo(() => {
    return (
      userCompany?.status.map(item => ({
        name: item.name,
        value: item.id,
      })) ?? []
    );
  }, [userCompany]);

  const technicalReport = order.technicalReports?.length
    ? [...order.technicalReports].shift()
    : undefined;

  const { handleClose } = useModal();

  const renderLoadingUpdateStatus = useMemo(() => {
    if (updateRequestStatus.loading) {
      return (
        <CircularProgress style={{ color: palette.common.white }} size={20} />
      );
    }

    return null;
  }, [updateRequestStatus]);

  const renderDeadline = useMemo(() => {
    if (order.deadline) {
      return (
        <Box display="flex" flexDirection="column" marginLeft={2}>
          <Styled.Date color="textSecondary">Entrega:</Styled.Date>
          <Styled.Date color="textSecondary">
            {format(
              new Date(order.deadline),
              serviceOrderModule?.settings?.dueDateType === "date"
                ? "dd/MM"
                : "dd/MM - HH:mm"
            )}
          </Styled.Date>
        </Box>
      );
    }
    return null;
  }, [order]);

  const renderTechnicalReportFields = useMemo(() => {
    if (technicalReport) {
      const fieldsInTechnicalReportWithContent = technicalReport.data.filter(
        item => item?.content
      );

      return fieldsInTechnicalReportWithContent.map(item => (
        <Grid item xs={12} key={item.id}>
          <Styled.TopicTitle variant="caption" color="textSecondary">
            {item.label}
          </Styled.TopicTitle>
          <Styled.TopicDescription variant="body2" color="textSecondary">
            {item.content}
          </Styled.TopicDescription>
        </Grid>
      ));
    }

    return null;
  }, [technicalReport]);

  const renderServices = useMemo(() => {
    if (order?.services && order?.services?.length) {
      return order.services.map(
        (item, index, list) =>
          `${item.serviceName}${index + 1 < list?.length ? `,` : ``} `
      );
    }

    return "-";
  }, [order]);

  const renderImagesSection = useMemo(() => {
    if (technicalReport?.gallery?.length) {
      return (
        <Grid item xs={12}>
          <Styled.TechnicalReportTitleSection variant="h6">
            Imagens
          </Styled.TechnicalReportTitleSection>
          <Grid container>
            {technicalReport?.gallery.map(item => (
              <Grid item xs={4} key={`image-${item.imageUrl}`}>
                <Zoom>
                  <Styled.PreviewPhoto src={item.imageUrl} />
                </Zoom>
                <Typography variant="caption">{item.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      );
    }

    return null;
  }, [technicalReport?.gallery]);

  return (
    <Styled.Modal maxWidth="md">
      <Styled.Container>
        <Styled.Header backgroundColor={COLOR_STATUS[order.status.type]}>
          <Box display="flex" flexDirection="row">
            <Styled.HeaderLabel color="initial">
              #{order.code}
            </Styled.HeaderLabel>
            <Box width={20} />
            {renderLoadingUpdateStatus}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Styled.HeaderLabel>{order.statusName}</Styled.HeaderLabel>
            <div>
              <Button onClick={handleClose}>
                <Styled.CloseIcon />
              </Button>
            </div>
          </Box>
        </Styled.Header>

        <Box padding={2}>
          <Box height={40}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={8}>
                <Typography variant="h6" color="textSecondary">
                  {order?.contact?.name}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  flexDirection="row"
                >
                  <div>
                    <Styled.Date color="textSecondary">
                      Data de criação:
                    </Styled.Date>
                    <Styled.Date color="textSecondary">
                      {format(new Date(order.createdAt), "dd/MM")}
                    </Styled.Date>
                  </div>
                  {renderDeadline}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={2}>
            <Divider />
          </Box>

          <Grid container spacing={3}>
            <Styled.Column item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Styled.TopicTitle color="textSecondary">
                    Serviços:
                  </Styled.TopicTitle>
                  <Styled.TopicDescription variant="h6" color="textSecondary">
                    {renderServices}
                  </Styled.TopicDescription>
                </Grid>
                <Grid item xs={12}>
                  <Styled.TopicTitle color="textSecondary">
                    Observação:
                  </Styled.TopicTitle>
                  <Styled.TopicDescription variant="h6" color="textSecondary">
                    {order?.additionalInfo ? order.additionalInfo : "-"}
                  </Styled.TopicDescription>
                </Grid>
                <RenderModule modules={["technicalReport"]}>
                  <Grid item xs={12}>
                    {renderImagesSection}
                  </Grid>
                </RenderModule>

                <Grid item xs={12}>
                  <Formik
                    // eslint-disable-next-line no-console
                    onSubmit={v => console.log(v)}
                    initialValues={{
                      status: order.status.id,
                    }}
                  >
                    {({ values, touched }) => (
                      <Form>
                        <SelectField
                          defaultValue={order.status.id}
                          label="Alterar status"
                          name="status"
                          items={ORDER_STATUS}
                          backgroundwhite
                        />
                        {setTouchedStatusField(Boolean(touched?.status))}
                        {setStatus(values.status)}
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Styled.Column>

            <RenderModule modules={["technicalReport"]}>
              <Styled.Column item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Styled.TechnicalReportTitleSection color="textPrimary">
                      Laudo Técnico
                    </Styled.TechnicalReportTitleSection>
                  </Grid>
                  {renderTechnicalReportFields}
                </Grid>
              </Styled.Column>
            </RenderModule>
          </Grid>
        </Box>
      </Styled.Container>
    </Styled.Modal>
  );
};

export default DetailOrderModal;
