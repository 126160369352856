import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  InputGroup,
  InputRightElement,
  Box,
  Flex,
  Button,
} from "@chakra-ui/react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SearchIcon from "@material-ui/icons/Search";

import { Input } from "src/components/NewComponents/Input";
import { Select } from "src/components/NewComponents/Select";
import PATHS from "src/constants/paths";
import { useUser } from "src/hooks/user/user/useUser";
import { debounce } from "src/utils/debounce";

interface OptionPanelProps {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  status: string;
}

function OptionsPanel({ setSearch, setStatus, status }: OptionPanelProps) {
  const navigate = useNavigate();

  const { list } = useUser();

  const searchContact = useCallback(
    debounce((query: string) => {
      list({ search: query, status });
      setSearch(query);
    }, 1000),
    []
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    searchContact(e.target.value);
  };

  return (
    <Flex mb={4} gap={[4, 6]} direction={["column", "row"]}>
      <InputGroup flex={1}>
        <Input
          bg="white"
          name="search"
          label="Pesquisar usuário"
          onChange={onChange}
        />
        <InputRightElement>
          <SearchIcon color="primary" />
        </InputRightElement>
      </InputGroup>
      <Box w={[null, null, 130]}>
        <Select
          value={status}
          isClearable={false}
          onChange={value => setStatus(value as string)}
          options={[
            { value: "enabled", label: "Ativo" },
            { value: "disabled", label: "Inativo" },
          ]}
        />
      </Box>
      <Button
        w={[null, null, 150]}
        rightIcon={<AddCircleIcon />}
        onClick={() => {
          navigate(PATHS.SETTINGS_USER_ADD);
        }}
      >
        Novo usuário
      </Button>
    </Flex>
  );
}

export default OptionsPanel;
