import React, { useRef } from "react";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { useCompany } from "src/hooks/company/useCompany";
import { useContact } from "src/hooks/contact/useContact";
import { SubContactFieldType, SubContactType } from "src/interfaces/contact";

import SubContactForm from "../SubContactForm";
import * as Styled from "./styles";

interface AddSubContactModalProps {
  contactId: string;
  setSubContactCreated?: (subContact: SubContactType) => void;
}

function AddSubContactModal({
  contactId,
  setSubContactCreated,
}: AddSubContactModalProps) {
  const { handleClose } = useBaseSwipeableDrawer();
  const { subContactNameDefinitions } = useCompany();
  const { subContactFields, addSubContact, createSubContactStatus } =
    useContact();
  const formikRef = useRef(null);

  const customHandleClose = () => {
    handleClose("createSubContact");
  };

  const onSubmit = async (data: any) => {
    const fieldsIds = Object.keys(data);

    const dataParsed = fieldsIds.map((fieldId: string) => {
      const field = subContactFields.find(
        field => field.id === fieldId
      ) as SubContactFieldType;

      return {
        label: field.label,
        type: field.type,
        content: data[fieldId],
        selectType: field.selectOptions?.type,
      };
    });

    const result = await addSubContact({
      data: dataParsed,
      contact: contactId as string,
    });

    if (setSubContactCreated && result) {
      setSubContactCreated(result);
      customHandleClose();
      if ((formikRef.current as any)?.values) {
        const initialValues = {} as { [key: string]: any };
        Object.keys((formikRef.current as any)?.values).forEach(
          (key: string) => {
            initialValues[key] = "";
          }
        );
        (formikRef.current as any).setValues(initialValues);
      }
    }
  };

  return (
    <BaseSwipeableDrawer
      tag="createSubContact"
      title={`Adicionar ${subContactNameDefinitions.title.toLowerCase()}`}
      buttonTitle="Salvar"
      buttonProps={{
        loading: createSubContactStatus.loading,
        disabled: createSubContactStatus.loading,
        form: "subContactForm",
      }}
      customHandleClose={customHandleClose}
    >
      <Styled.Container>
        <SubContactForm onSubmit={onSubmit} formikRef={formikRef} isModal />
      </Styled.Container>
    </BaseSwipeableDrawer>
  );
}

export default AddSubContactModal;
