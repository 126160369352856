import React, { useEffect } from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";

import AddCircleIcon from "@material-ui/icons/AddCircle";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import {
  useBuyOrder,
  useBuyOrderProvider,
} from "src/hooks/buy_order/useBuyOrder";
import {
  useBuyOrderFiltersProvider,
  useBuyOrderFilters,
} from "src/hooks/buy_order/useBuyOrderFilters";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { withContext } from "src/utils/withContext";

import BuyOrderOptionsPanel from "./components/BuyOrderOptionsPanel";
import BuyOrdersTable from "./components/BuyOrdersTable";
import BuyOrderStatusTabs from "./components/BuyOrderStatusTabs";
import * as Styled from "./styles";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Produtos" },
  { label: "Ordens de compra" },
];

const BuyOrderListPage: React.FC = () => {
  const match = useMatch(PATHS.BUY_ORDER_LIST);
  const { pathname } = useLocation();
  const { getBuyOrderStatuesMeta, getBuyOrders } = useBuyOrder();
  const {
    page,
    setPage,
    limit,
    search,
    status,
    startDate,
    endDate,
    restartOrderList,
    setRestartOrderList,
  } = useBuyOrderFilters();

  const handleGetBuyOrderStatuesMeta = () => {
    getBuyOrderStatuesMeta({
      startDate,
      endDate,
    });
  };

  const handleGetBuyOrders = (pageNumber?: number) => {
    getBuyOrders({
      limit,
      page: pageNumber ?? page,
      ...(search.length && { search }),
      ...(status && { status }),
      ...(startDate && { startDate: formatStartDateISO(startDate) }),
      ...(endDate && { endDate: formatEndDateISO(endDate) }),
    });
  };

  useEffect(() => {
    setPage(1);
    handleGetBuyOrders(1);
  }, [limit, search, status, startDate, endDate]);

  useEffect(() => {
    handleGetBuyOrderStatuesMeta();
  }, [startDate]);

  useEffect(() => {
    if (restartOrderList && match?.pathname === pathname) {
      handleGetBuyOrders();
      handleGetBuyOrderStatuesMeta();
      setRestartOrderList(false);
    }
  }, [restartOrderList, match?.pathname, pathname]);

  return (
    <>
      <DashboardLayout
        selected="register"
        subSelected="products"
        modules={["stock", "buyorder"]}
        hidden={match?.pathname !== pathname}
      >
        <Breadcrumb data={BREADCRUMB_BUTTONS} />

        <PageTitle label="Ordens de compra" mt={0} mb={3}>
          <Styled.Button
            variant="contained"
            color="primary"
            to={PATHS.BUY_ORDER_ADD}
          >
            <AddCircleIcon />
            <Typography variant="body2">Nova ordem de compra</Typography>
          </Styled.Button>
        </PageTitle>
        <BuyOrderOptionsPanel />
        <BuyOrderStatusTabs />
        <BuyOrdersTable handleGetBuyOrders={handleGetBuyOrders} />
      </DashboardLayout>
      <Outlet />
    </>
  );
};

export default withContext(
  BuyOrderListPage,
  useBuyOrderFiltersProvider,
  useBuyOrderProvider
);
