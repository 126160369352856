export const REGEX = {
  validEmail:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  validCPF: /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/,
  validCNPJ: /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/,
  containSpecialCharacter: /^(?=.*[@!#$%^&*()/\\])/,
  containNumberCharacter: /[0-9]/,
  containCapitalCharacter: /[A-Z]/,
  containSmallCharacter: /[a-z]/,
};
