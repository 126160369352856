import styled from "styled-components";

import TextField from "@material-ui/core/TextField";

export const SearchField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  .MuiIconButton-label {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
