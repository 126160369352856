import { RouteObject } from "react-router-dom";

import PATHS from "src/constants/paths";
import AddBuyOrderPage from "src/pages/BuyOrders/Add";
import BuyOrderDetailsPage from "src/pages/BuyOrders/Details";
import BuyOrderListPage from "src/pages/BuyOrders/List";
import UpdateBuyOrderPage from "src/pages/BuyOrders/Update";
import ProductAddPage from "src/pages/Products/Add";
import ProductDetailPage from "src/pages/Products/Detail";
import ProductListPage from "src/pages/Products/List";

export const productRoutes: RouteObject[] = [
  {
    path: PATHS.PRODUCTS_LIST,
    element: <ProductListPage />,
  },
  {
    path: PATHS.BUY_ORDER_LIST,
    element: <BuyOrderListPage />,
    children: [
      {
        path: PATHS.BUY_ORDER_ADD,
        element: <AddBuyOrderPage />,
      },
      {
        path: PATHS.BUY_ORDER_DETAIL,
        element: <BuyOrderDetailsPage />,
      },
      {
        path: PATHS.BUY_ORDER_UPDATE,
        element: <UpdateBuyOrderPage />,
      },
    ],
  },
  {
    path: PATHS.PRODUCTS_DETAIL,
    element: <ProductDetailPage />,
  },
  {
    path: PATHS.PRODUCTS_ADD,
    element: <ProductAddPage />,
  },
];
