import React, { useState } from "react";

import { Box } from "@chakra-ui/react";

import OptionsPanel from "./components/OptionsPanel";
import UserTable from "./components/UserTable";

function Users() {
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState("enabled");

  return (
    <Box>
      <OptionsPanel
        setSearch={setSearch}
        status={status}
        setStatus={setStatus}
      />
      <UserTable search={search} status={status} />
    </Box>
  );
}

export default Users;
