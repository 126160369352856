import { toast } from "react-toastify";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { UserToAddType } from "src/interfaces/user";
import api from "src/services/api";

export const createUser = (params: Partial<UserToAddType>) =>
  api.post("v1/user/create", params).then(res => res.data);

export function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation((user: Partial<UserToAddType>) => createUser(user), {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast.success("Usuário criado com sucesso.");
    },
    onError: ({ response }) => {
      toast.error(
        response?.data?.message ??
          "Ocorreu um problema ao cadastrar esse usuário"
      );
    },
  });
}
