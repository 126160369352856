import React, { useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import {
  BaseModalProvider,
  useBaseModal,
} from "src/components/BaseModal/useBaseModal";
import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Button from "src/components/Button";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import Typography from "src/components/Typography";
import {
  FinancialAccountProvider,
  useFinancialAccount,
} from "src/hooks/financialAccount/useFinancialAccount";
import { Can } from "src/hooks/permission/usePermission";
import { RecordProvider, useRecord } from "src/hooks/record/useRecord";
import { withContext } from "src/utils/withContext";

import TransferFormModal, {
  TransferFormOriginEnum,
} from "../components/Modals/TransferFormModal";
import AccountsList from "./components/AccountsList";
import FinancialAccountArchiveOrUnarchiveModal from "./components/FinancialAccountArchiveOrUnarchiveModal";
import FinancialAccountDeleteModal from "./components/FinancialAccountDeleteModal";
import FinancialAccountFormModal from "./components/FinancialAccountFormModal";
import * as Styled from "./styles";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [{ label: "Contas" }];

const FinancialAccount: React.FC = () => {
  const {
    list,
    status,
    createStatus,
    updateStatus,
    accounts,
    setFinancialAccount,
    listInstitution,
  } = useFinancialAccount();

  const { createTransferStatus } = useRecord();

  const { handleOpen } = useBaseModal();
  const { handleOpen: handleOpenSwipeableDrawer } = useBaseSwipeableDrawer();

  const [showAccountArchived, setShowAccountArchived] = useState(false);

  useEffect(() => {
    listInstitution({
      search: "",
    });
  }, []);

  useEffect(() => {
    list({}, true);
  }, [
    createStatus.success,
    updateStatus.success,
    createTransferStatus.success,
  ]);

  const defaultAccounts = useMemo(
    () =>
      accounts.filter(
        account => account.isDefault && account.status === "enabled"
      ),
    [accounts]
  );

  const othersAccounts = useMemo(
    () =>
      accounts.filter(
        account => !account.isDefault && account.status === "enabled"
      ),
    [accounts]
  );

  const archivedAccounts = useMemo(
    () => accounts.filter(account => account.status === "disabled"),
    [accounts]
  );

  const renderAccounts = useMemo(() => {
    if (status.loading) {
      return <Loader size="medium" />;
    }

    if (status.success && accounts.length === 0) {
      return <p>Nenhuma conta</p>;
    }

    return (
      <>
        {!showAccountArchived ? (
          <>
            <Styled.Title>Conta padrão</Styled.Title>
            <Styled.Divider />
            <AccountsList accounts={defaultAccounts} />
            <Styled.Title>Outras contas</Styled.Title>
            <Styled.Divider />
            <AccountsList accounts={othersAccounts} />
          </>
        ) : (
          <>
            <Styled.Title>Contas arquivadas</Styled.Title>
            <Styled.Divider />
            <AccountsList accounts={archivedAccounts} />
          </>
        )}
        {archivedAccounts.length || showAccountArchived ? (
          <Box mt={2}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => setShowAccountArchived(prev => !prev)}
            >
              {showAccountArchived ? "Contas ativas" : "Contas arquivadas"}
            </Button>
          </Box>
        ) : null}
      </>
    );
  }, [
    defaultAccounts,
    othersAccounts,
    archivedAccounts,
    status,
    showAccountArchived,
  ]);

  return (
    <DashboardLayout
      selected="financial"
      subSelected="financial-accounts"
      modules="accountManagement"
    >
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />
      <PageTitle label="Contas">
        <Can action="create" subject="financialAccount">
          <Box display="flex">
            <Box mr={2}>
              <Styled.Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenSwipeableDrawer("transferForm");
                }}
              >
                <AddCircleIcon />
                <Typography variant="body2">Nova transferência</Typography>
              </Styled.Button>
            </Box>
            <Styled.Button
              variant="contained"
              color="primary"
              onClick={() => {
                setFinancialAccount(undefined);
                handleOpen("financialAccount");
              }}
            >
              <AddCircleIcon />
              <Typography variant="body2">Nova conta</Typography>
            </Styled.Button>
          </Box>
        </Can>
      </PageTitle>
      <Grid container>
        <Grid item xs={12}>
          {renderAccounts}
        </Grid>
      </Grid>
      <FinancialAccountFormModal />
      <FinancialAccountArchiveOrUnarchiveModal />
      <FinancialAccountDeleteModal />

      <TransferFormModal
        onSuccess={() => {
          list({}, true);
        }}
        transferFormOrigin={TransferFormOriginEnum.accountManagement}
      />
    </DashboardLayout>
  );
};

export default withContext(
  FinancialAccount,
  RecordProvider,
  FinancialAccountProvider,
  BaseModalProvider,
  BaseSwipeableDrawerProvider
);
