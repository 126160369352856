import React, { FC, useState } from "react";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { Formik, Form } from "formik";

import Button from "src/components/Button";
import RadioSimple from "src/components/Inputs/RadioSimple";
import RenderModule from "src/components/RenderModule";
import Typography from "src/components/Typography";
import { useModule } from "src/hooks/module/useModule";
import { ServiceOrderSettingType } from "src/interfaces/module";
import SectionCard from "src/pages/Settings/components/SectionCard";

import SwitchSettings from "../../SwitchSettings";

const ServiceOrderSection: FC = () => {
  const { updateSettingsModule } = useModule();

  const { serviceOrderModule } = useModule();

  const [status, setStatus] = useState(false);

  const settings = serviceOrderModule?.settings as ServiceOrderSettingType;

  if (!serviceOrderModule) {
    return null;
  }

  const onSubmit = async (settings: ServiceOrderSettingType) => {
    setStatus(true);
    await updateSettingsModule(serviceOrderModule.module.id, settings);
    setStatus(false);
  };

  return (
    <Formik
      initialValues={
        {
          createdAtType: settings?.createdAtType,
          dueDateType: settings?.dueDateType,
          serviceRequired: settings?.serviceRequired ?? true,
          allowSetCreatedDate: settings?.allowSetCreatedDate ?? "deny",
          createRecordsManually: settings?.createRecordsManually ?? false,
          createStockManually: settings?.createStockManually ?? false,
          blockOrderUpdate: settings?.blockOrderUpdate ?? false,
          serviceSettingsPrint: settings?.serviceSettingsPrint ?? false,
        } as ServiceOrderSettingType
      }
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <Form>
          <SectionCard title="Ordem de serviço">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                <Typography variant="subtitle1">
                  Vizualização da data do prazo:
                </Typography>
              </div>
              <Box display="flex" flexDirection="row">
                <RadioSimple
                  label="Data"
                  name="dueDateType"
                  value="date"
                  disabled={status}
                />
                <RadioSimple
                  label="Data e hora"
                  name="dueDateType"
                  value="dateHour"
                  disabled={status}
                />
              </Box>
            </Box>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                <Typography variant="subtitle1">
                  Vizualização da data de criação:
                </Typography>
              </div>
              <Box display="flex" flexDirection="row">
                <RadioSimple
                  label="Data"
                  name="createdAtType"
                  value="date"
                  disabled={status}
                />
                <RadioSimple
                  label="Data e hora"
                  name="createdAtType"
                  value="dateHour"
                  disabled={status}
                />
              </Box>
            </Box>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
            <Box mb={2}>
              <SwitchSettings
                name="allowSetCreatedDate"
                checked={values.allowSetCreatedDate === "allow"}
                onChange={(_, checked) => {
                  setFieldValue(
                    "allowSetCreatedDate",
                    checked ? "allow" : "deny"
                  );
                }}
                label="Permitir escolher a data de criação"
                disabled={status}
              />
            </Box>
            <Box mb={2}>
              <SwitchSettings
                name="serviceRequired"
                label="Obrigatório selecionar pelo menos um serviço"
                disabled={status}
              />
            </Box>
            <Box mb={2}>
              <SwitchSettings
                name="createRecordsManually"
                label="Lançar contas manualmente"
                disabled={status}
              />
            </Box>

            <RenderModule modules={["stock"]}>
              <Box mb={2}>
                <SwitchSettings
                  name="createStockManually"
                  label="Lançar estoque manualmente"
                  disabled={status}
                />
              </Box>
            </RenderModule>
            <Box mb={2}>
              <SwitchSettings
                name="blockOrderUpdate"
                label="Obrigatório estornar para editar"
                disabled={status}
              />
            </Box>
            <Box mb={2}>
              <SwitchSettings
                name="serviceSettingsPrint"
                label="Habilitar configuração de impressão"
                disabled={status}
              />
            </Box>
            <Box display="flex" flexDirection="row-reverse">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                  fullWidth
                  loading={status}
                >
                  Salvar
                </Button>
              </div>
            </Box>
          </SectionCard>
        </Form>
      )}
    </Formik>
  );
};

export default ServiceOrderSection;
