import React from "react";

import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

import Button from "../Button";
import { ButtonProps } from "../Button/props";
import * as Styled from "./styles";
import { useBaseModal } from "./useBaseModal";

export const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

interface BaseModalProps extends Partial<DialogProps> {
  tag: string;
  title?: string;
  customHeader?: React.ReactElement;
  buttonTitle?: string;
  buttonProps?: ButtonProps;
  customActions?: React.ReactElement;
  customHandleClose?(): void;
  zIndex?: number;
}

const BaseModal: React.FC<BaseModalProps> = props => {
  const {
    children,
    tag,
    title,
    customHeader,
    buttonTitle = "Salvar",
    buttonProps = {},
    customActions,
    customHandleClose,
    zIndex = 1300,
    ...rest
  } = props;
  const { open, handleClose } = useBaseModal();

  return (
    <Dialog
      open={open.includes(tag)}
      TransitionComponent={Transition}
      keepMounted
      onClose={customHandleClose || (() => handleClose(tag))}
      scroll="paper"
      style={{ zIndex }}
      {...rest}
    >
      <Styled.DialogHeader>
        {customHeader ?? <Styled.DialogTitle>{title}</Styled.DialogTitle>}
        <Styled.CloseButton
          onClick={customHandleClose || (() => handleClose(tag))}
        />
      </Styled.DialogHeader>
      <Styled.DialogContent>{children}</Styled.DialogContent>
      <Styled.DialogActions>
        {customActions ?? (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            type="submit"
            {...buttonProps}
          >
            {buttonTitle}
          </Button>
        )}
      </Styled.DialogActions>
    </Dialog>
  );
};

export default BaseModal;
