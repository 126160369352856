/**
 * retorna token do usuário
 */
export const getToken = () => {
  const accessToken = localStorage.getItem("@azulou:accessToken") as string;

  if (accessToken) {
    return accessToken;
  }

  const data = localStorage.getItem("userData") as string;
  if (data) {
    const userData = JSON.parse(data);

    if (userData?.token) {
      localStorage.setItem("@azulou:accessToken", userData?.token);
      return userData?.token;
    }

    return undefined;
  }

  return undefined;
};
