import {
  Box,
  Flex,
  GridItem,
  Image,
  SimpleGrid,
  Text,
  Wrap,
} from "@chakra-ui/react";

import { TechnicalReportForm } from "src/hooks/order/useOrderForm/props";

interface TechnicalReportResumeProps {
  technicalReport: TechnicalReportForm | undefined;
}

export function TechnicalReportResume({
  technicalReport,
}: TechnicalReportResumeProps) {
  return (
    <SimpleGrid columns={2}>
      <GridItem as={Wrap} spacing={4}>
        {technicalReport?.data.map(field => {
          return (
            <Box
              key={`${field.label}${field.type}`}
              w={field?.type === "textarea" ? "100%" : "unset"}
            >
              <Text>{field.label}</Text>
              <Text fontSize="xs" color="gray.500">
                {field.content}
              </Text>
            </Box>
          );
        })}
      </GridItem>
      <GridItem as={Wrap} spacing={4}>
        {technicalReport?.gallery.map(item => {
          return (
            <Box key={`${item.index}${item.name}`}>
              <Flex
                h="110px"
                w="100%"
                borderRadius={10}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                position="relative"
              >
                <Image
                  w="100%"
                  h="100%"
                  objectFit="cover"
                  borderRadius={10}
                  src={URL.createObjectURL(item.image as File)}
                />
              </Flex>
              <Text fontSize="xs" color="text" mt={1}>
                {item.name}
              </Text>
            </Box>
          );
        })}
      </GridItem>
    </SimpleGrid>
  );
}
