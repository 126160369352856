import React, { useCallback, useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";

import TextField from "src/components/Inputs/TextField";
import { useContact } from "src/hooks/contact/useContact";
import { useModule } from "src/hooks/module/useModule";
import { AutocompleteField } from "src/pages/Contacts/Detail/styles";
import { debounce } from "src/utils/debounce";

import { ContactListFilters } from "../..";
import { useContactType } from "../../useContactType";
import * as Styled from "./styles";

export interface FilterTagProp {
  name: string;
  value: string;
}

interface OptionsPanelProps {
  filters: ContactListFilters;
  setFilters: (value: ContactListFilters) => void;
}

const OptionsPanel = (props: OptionsPanelProps) => {
  const { filters, setFilters } = props;
  const { type } = useContactType();
  const { hasModules } = useModule();

  const { tags } = useContact();

  const searchContact = useCallback(
    debounce((query: string) => {
      setFilters({
        ...filters,
        search: query,
        page: 1,
      });
    }, 1000),
    [type, filters]
  );

  const filterTags = useMemo(() => {
    const tagsMapped = tags.map(tag => ({
      name: tag.label,
      value: tag.id,
    }));

    return [
      {
        name: "Todos",
        value: "",
      },
      tagsMapped,
    ].flat();
  }, [tags]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    searchContact(e.target.value);
  };

  return (
    <Styled.Container>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} md={hasModules("contactTags") ? 9 : 12}>
          <TextField
            disableFormik
            label="Pesquisar contato"
            name="name"
            onChange={onChange}
            icon={<SearchIcon color="primary" />}
            backgroundwhite
          />
        </Grid>
        {hasModules("contactTags") ? (
          <Grid item xs={12} md={3}>
            <Autocomplete
              value={filters.tagSelected}
              onChange={(event, data) => {
                if (data) {
                  setFilters({
                    ...filters,
                    tagSelected: data,
                    page: 1,
                  });
                }
              }}
              options={filterTags}
              getOptionLabel={option => option.name}
              defaultValue={undefined}
              filterSelectedOptions
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              noOptionsText=""
              disableClearable
              renderInput={params => (
                <AutocompleteField
                  {...params}
                  variant="outlined"
                  fullWidth
                  label="Marcador"
                  placeholder="Pesquisar marcador"
                />
              )}
            />
          </Grid>
        ) : (
          false
        )}
      </Grid>
    </Styled.Container>
  );
};

export default OptionsPanel;
