import styled from "styled-components";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import Typography from "src/components/Typography";

import { StatusTagProps } from "../BasicData/props";

export const ContainerRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px #d6d8dc solid;
  box-shadow: none;
  padding: 20px;
`;

export const HorizontalLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #d6d8dc;
  margin: 0 1rem;
`;

export const ControlNumberBox = styled(Box)`
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  min-width: 45px;
  height: 40px;
  margin-right: 1rem;
  font-weight: bold;
  font-size: 23px;
  line-height: 34px;
`;

export const CodeBox = styled(Box)`
  border: 1px #1e2c47 solid;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
`;

export const StatusTag = styled(Box)<StatusTagProps>`
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 30px;
  padding: 20px;
  text-transform: capitalize;
`;

export const Loading = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.common.white};
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  row-gap: 16px;
  column-gap: 8px;
`;

export const Title = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 26px;
  color: #5b5c60;
  margin-bottom: 1rem;
`;
