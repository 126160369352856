import styled from "styled-components";

import MuiPaper from "@material-ui/core/Paper";
import { DataGrid as MuiDataGrid } from "@material-ui/data-grid";

export const ContainerTable = styled(MuiPaper)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid #d6d8dc;
  padding: 10px;
  box-shadow: none;
`;

export const DataGrid = styled(MuiDataGrid)`
  border: none;
  .MuiCheckbox-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: 0;
  }
  &.MuiDataGrid-root .MuiDataGrid-renderingZone {
    max-height: none !important;
  }
  &.MuiDataGrid-root .MuiDataGrid-cell {
    line-height: unset !important;
    max-height: none !important;
    white-space: normal;
    display: flex;
    align-items: center;
  }
  &.MuiDataGrid-root .MuiDataGrid-row {
    max-height: none !important;
  }
  &.MuiDataGrid-root .MuiDataGrid-row {
    cursor: pointer;
  }
  &.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0px;
  }
  &.MuiDataGrid-root .MuiDataGrid-footerContainer {
    justify-content: flex-end;
  }
  &.MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none;
  }
  .MuiSelect-icon {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiIconButton-colorInherit {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
  }
`;

export const EmptyContainer = styled.div`
  height: "100%";
  padding-top: 180px;
  padding-bottom: 150px;
  margin-bottom: -150px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
