import styled from "styled-components";

import { ReactComponent as ContactsIconUnstyled } from "src/assets/icons/emptyTable/contacts.svg";

export const ContactsIcon = styled(ContactsIconUnstyled)`
  margin-bottom: 15px;
`;

export const MarkedText = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;
