import React, { FC, useMemo } from "react";

import { Box, Grid } from "@material-ui/core";
import {
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  ReferenceLine,
  Line,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

import Panel from "src/components/Layouts/Panel";
import Typography from "src/components/Typography";
import { useDashboard } from "src/hooks/dashboard/useDashboard";
import { DashboardInvervalEnum } from "src/interfaces/dashboard";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const TicketChart: FC<{ interval: keyof typeof DashboardInvervalEnum }> = ({
  interval,
}) => {
  const { dashboardSales } = useDashboard();

  const data = useMemo(() => {
    return (
      dashboardSales.ticketByPeriodActual?.map((sale, index) => {
        return {
          ...sale,
          valuePast: dashboardSales.ticketByPeriodPast[index]?.value ?? 0,
          labelPast: dashboardSales.ticketByPeriodPast[index]?.label ?? "",
          values: [
            dashboardSales.salesByPeriodPast[index]?.value ?? 0,
            sale?.value ?? 0,
          ],
        };
      }) ?? []
    );
  }, [dashboardSales.ticketByPeriodActual]);

  const widthChart = useMemo(() => {
    return data.length * 70;
  }, [data]);

  const longestLabelLength = useMemo(() => {
    return (
      data
        .map(item => formatPrice(item.value))
        .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0) ?? 10
    );
  }, [data]);

  function CustomizedTick(props: any) {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} fill="#666">
          {interval !== "week" ? (
            <tspan
              fontSize={10}
              textAnchor="middle"
              x="0"
              color="rgba(19, 34, 62, 0.7)"
            >
              {data[payload.index].labelPast}
            </tspan>
          ) : null}
          <tspan
            fontSize={10}
            textAnchor="middle"
            x="0"
            dy="12"
            color="rgba(19, 34, 62, 0.7)"
          >
            {data[payload.index].label}
          </tspan>
        </text>
      </g>
    );
  }

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      return (
        <Styled.TooltipContent>
          <Styled.TooltipText>
            <strong>{payload[0].payload.labelPast}</strong>
          </Styled.TooltipText>
          <Styled.TooltipText>
            <strong>Valor anterior:</strong>{" "}
            {formatPrice(payload[0].payload.valuePast)}
          </Styled.TooltipText>
          <Styled.TooltipText>
            <strong>{payload[0].payload.label}</strong>
          </Styled.TooltipText>
          <Styled.TooltipText>
            <strong>Valor atual:</strong>{" "}
            {formatPrice(payload[0].payload.value)}
          </Styled.TooltipText>
        </Styled.TooltipContent>
      );
    }
    return null;
  };

  return (
    <Grid item xs={12} md={6}>
      <Typography color="primary" variant="subtitle2">
        Tícket médio
      </Typography>
      <Box marginBottom={2} />
      <Grid container>
        <Grid item xs={12}>
          <Panel padding={2} marginBottom={2} border="solid 1px #D6D8DC">
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography color="textSecondary" variant="subtitle1">
                Anterior:{" "}
                <Styled.CurrencyLabelSpan>
                  {formatPrice(dashboardSales?.ticketTotalPast)}
                </Styled.CurrencyLabelSpan>
              </Typography>
              <Box marginRight={1} />
              <Typography color="textSecondary" variant="subtitle1">
                Atual:{" "}
                <Styled.CurrencyLabelSpan>
                  {formatPrice(dashboardSales?.ticketTotalActual)}
                </Styled.CurrencyLabelSpan>
              </Typography>
              {typeof dashboardSales?.ticketTotalPercentChange === "number" ? (
                <Styled.LabelPercentage
                  redCurrency={
                    (dashboardSales?.ticketTotalPercentChange ?? 0) < 0
                  }
                >
                  {(dashboardSales?.ticketTotalPercentChange ?? 0) > 0
                    ? "+"
                    : null}
                  {dashboardSales?.ticketTotalPercentChange} %
                </Styled.LabelPercentage>
              ) : null}
            </Box>
            <Styled.ResponsiveContainerOverflow>
              <ResponsiveContainer
                minHeight={300}
                maxHeight={300}
                minWidth={widthChart}
                width="100%"
              >
                <ComposedChart data={data}>
                  <CartesianGrid
                    stroke="#ccc"
                    strokeDasharray=".5 .5"
                    vertical={false}
                  />
                  <XAxis
                    dataKey="label"
                    scale="point"
                    padding={{ left: 30, right: 30 }}
                    fontSize={10}
                    tickLine={false}
                    stroke="rgba(19, 34, 62, 0.7)"
                    tick={<CustomizedTick />}
                  />
                  <YAxis
                    dataKey="values"
                    fontSize={10}
                    stroke="rgba(19, 34, 62, 0.7)"
                    tickFormatter={formatPrice}
                    tickLine={false}
                    axisLine={false}
                    width={longestLabelLength * 7}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <ReferenceLine stroke="#000" />
                  <Line
                    type="monotone"
                    dataKey="value"
                    dot={{
                      stroke: "#fff",
                      fill: "#65AEED",
                      r: 4,
                    }}
                    stroke="#1A5DF2"
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="valuePast"
                    dot={{
                      stroke: "#fff",
                      fill: "#65AEED",
                      r: 4,
                    }}
                    strokeDasharray="3"
                    stroke="#04349A"
                    strokeWidth={2}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Styled.ResponsiveContainerOverflow>
          </Panel>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TicketChart;
