import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import MuiTooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import * as yup from "yup";

import { ReactComponent as FinancialIcon } from "src/assets/icons/sidebar/financial.svg";
import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { Textarea } from "src/components/NewComponents/TextArea";
import { useModule } from "src/hooks/module/useModule";
import { Can } from "src/hooks/permission/usePermission";
import { SubCategoryRecordType } from "src/interfaces/categoryRecord";
import { FinancialAccountType } from "src/interfaces/financialAccount";
import { PaymentMethodType } from "src/interfaces/payment";
import { RecordType } from "src/interfaces/record";
import { useCashFlowOpened } from "src/services/hooks/cashFlow/useCashFlowOpened";
import { useActiveFinancialAccounts } from "src/services/hooks/financialAccount/useActiveFinancialAccounts";
import { useCreateRecord } from "src/services/hooks/record/useCreateRecord";
import { useRecordCategories } from "src/services/hooks/record/useRecordCategories";
import { useUpdateRecord } from "src/services/hooks/record/useUpdateRecord";
import { formatStartDateISO } from "src/utils/date";
import { formatEmissionDateToISO, formatPaidDateToISO } from "src/utils/record";

import { FormRecord } from "./parts/FormRecord";
import { FormRecordAdvanced } from "./parts/FormRecordAdvanced";
import { FormRecordPayment } from "./parts/FormRecordPayment";
import { schemaRecordForm } from "./schema";

export type RecordFormSchema = yup.InferType<typeof schemaRecordForm>;

export enum RecordFormOriginEnum {
  income,
  expense,
  accountStatement,
  cashFlow,
}

interface RecordFormModalType {
  recordFormOrigin: RecordFormOriginEnum;
  record?: RecordType;
  recordType?: "expense" | "income";
  handleCloseRecordFormModal: () => void;
  onSuccess?: (value: RecordType) => void;
}

const today = new Date();

function RecordFormModal({
  record,
  recordType = "expense",
  handleCloseRecordFormModal,
  onSuccess,
  recordFormOrigin,
}: RecordFormModalType) {
  const { hasModules } = useModule();
  const { handleOpen, handleClose } = useBaseSwipeableDrawer();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data: cashFlowOpened } = useCashFlowOpened({
    enabled:
      hasModules("cashflowcontrol") &&
      recordFormOrigin === RecordFormOriginEnum.cashFlow,
    retry: 1,
    retryDelay: 500,
    onSuccess(cashFlowOpened) {
      reset({
        ...defaultValues,
        financialAccount: cashFlowOpened?.financialAccount as unknown as string,
      });
    },
  });

  const { mutate: createRecord, isLoading } = useCreateRecord({
    onSuccess: record => {
      toast.success("Lançamento criado com sucesso!");
      handleCustomClose();
      if (onSuccess) onSuccess(record);
    },
  });

  const { mutate: updateRecord, isLoading: isLoadingUpdating } =
    useUpdateRecord({
      onSuccess: record => {
        toast.success("Lançamento atualizado com sucesso!");
        handleCustomClose();
        if (onSuccess) onSuccess(record);
      },
    });

  const { data: categories } = useRecordCategories({
    type: (record?.type as "expense" | "income") || recordType,
  });

  const { data: accounts } = useActiveFinancialAccounts(undefined, {
    enabled: hasModules("accountManagement"),
    onSuccess(accounts) {
      if (recordFormOrigin !== RecordFormOriginEnum.cashFlow) {
        reset({
          ...defaultValues,
          financialAccount:
            (record?.financialAccount as FinancialAccountType)?.id ||
            accounts.find(account => account.isDefault)?.id,
        });
      }
    },
  });

  const getFinancialAccountId = () => {
    if (recordFormOrigin === RecordFormOriginEnum.cashFlow) {
      return (
        (cashFlowOpened?.financialAccount as unknown as string) ?? undefined
      );
    }

    if (typeof record?.financialAccount === "string") {
      return record?.financialAccount;
    }

    return (
      (record?.financialAccount as FinancialAccountType)?.id ||
      accounts.find(account => account.isDefault)?.id
    );
  };

  const defaultValues: RecordFormSchema = {
    // Common Section //
    buyOrderId: record?.buyOrder?.id || record?.buyOrder?.toString() || "",
    orderId: record?.order?.id || record?.order?.toString() || "",
    id: record?.id || "",
    type: record?.type || recordType,
    description: record?.description || "",
    value: record?.value || ("" as unknown as number),
    category: (record?.category as SubCategoryRecordType)?.id || "",
    financialAccount: getFinancialAccountId(),
    hasAccountManagement: hasModules("accountManagement"),
    // Payment Section //
    paidStatus:
      recordFormOrigin === RecordFormOriginEnum.cashFlow
        ? "paid"
        : record?.paidStatus || "pending",
    paidDate: record?.paidDate || formatStartDateISO(today),
    paymentMethod: (record?.paymentMethod as PaymentMethodType)?.id,

    // Advanced Section //
    recurrenceType: record?.recurrenceType,
    recurrenceCycle: record?.recurrenceCycle || "daily",
    parcels: String(record?.parcels || 1),
    emissionDate: record?.emissionDate || formatStartDateISO(today),
    note: record?.note || "",
  };

  const methods = useForm<RecordFormSchema>({
    defaultValues,
    resolver: yupResolver(schemaRecordForm),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    register,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset({
      ...defaultValues,
    });
  }, [record]);

  const handleCustomClose = () => {
    reset(defaultValues);
    handleCloseRecordFormModal();
    handleClose("recordForm");
  };

  const onSubmit = ({
    id,
    value,
    paidStatus,
    paidDate,
    emissionDate,
    description,
    category,
    recurrenceType,
    recurrenceCycle,
    parcels,
    note,
    financialAccount,
    paymentMethod,
    type,
  }: RecordFormSchema) => {
    createRecord({
      id,
      value: Number(value),
      paidStatus,
      paidDate: formatPaidDateToISO(paidDate),
      emissionDate: formatEmissionDateToISO(emissionDate),
      description,
      category: category as string,
      ...(recurrenceType && {
        recurrenceType,
        recurrenceCycle,
        parcels:
          parcels && recurrenceType === "installments"
            ? Number(parcels)
            : undefined,
      }),
      note,
      financialAccount,
      paymentMethod,
      type: type as string,
    });
  };

  const onUpdate = (updateType: "current" | "currentAndNext" = "current") => {
    const {
      id,
      value,
      paidDate,
      emissionDate,
      description,
      category,
      note,
      financialAccount,
    } = getValues();
    updateRecord({
      id: id as string,
      value: Number(value),
      description,
      paidDate: formatPaidDateToISO(paidDate),
      emissionDate: formatEmissionDateToISO(emissionDate),
      category,
      note,
      financialAccount,
      updateType,
    });
  };

  const title = useMemo(() => {
    if (!record) {
      return recordType === "income"
        ? "Adicionar receita"
        : "Adicionar despesa";
    }

    if (record?.type === "income") {
      return "Editar receita";
    }

    return "Editar despesa";
  }, [record, recordType]);

  const buttonTitle = useMemo(() => {
    if (!record) {
      return recordType === "income" ? "Criar receita" : "Criar despesa";
    }

    if (record?.type === "income") {
      return "Salvar receita";
    }

    return "Salvar despesa";
  }, [record]);

  const baseActions = useMemo(() => {
    if (!record) {
      return (
        <Button
          w="100%"
          type="submit"
          isLoading={isLoading || isLoadingUpdating}
          form="recordForm"
        >
          {buttonTitle}
        </Button>
      );
    }
    return (
      <>
        <Box width="100%" mb={2}>
          {record?.paidStatus === "paid" ? (
            <Can action="chargeback" subject="record">
              <Button
                w="100%"
                variant="outline"
                colorScheme="error"
                onClick={() => handleOpen("chargebackRecord")}
                sx={{
                  "& > .custom__tooltip": {
                    backgroundColor: "red",
                  },
                }}
                position="relative"
              >
                Estornar pagamento
                <MuiTooltip
                  classes={{
                    tooltip: "custom__tooltip",
                  }}
                  title={
                    <>
                      <Text as="span" fontWeight={500} color="white">
                        Edição desabilitada para alguns campos.{" "}
                      </Text>
                      <Text as="span" fontWeight={400} color="#d6d8dc">
                        Não é possivel editar data de pagamento, valor e data de
                        emissão com pagamento já lançado!
                      </Text>
                    </>
                  }
                  placement="bottom-end"
                  arrow
                >
                  <Icon
                    position="absolute"
                    right={4}
                    as={InfoIcon}
                    color="error.500"
                  />
                </MuiTooltip>
              </Button>
            </Can>
          ) : (
            <Can action="pay" subject="record">
              <Button
                w="100%"
                variant="outline"
                onClick={() => handleOpen("payRecord")}
              >
                Realizar pagamento
              </Button>
            </Can>
          )}
        </Box>
        <Can action="edit" subject="record">
          <Button
            w="100%"
            isLoading={isLoading || isLoadingUpdating}
            type="submit"
            form="recordForm"
          >
            {buttonTitle}
          </Button>
        </Can>
      </>
    );
  }, [buttonTitle, isLoading, isLoadingUpdating, record]);

  return (
    <BaseSwipeableDrawer
      tag="recordForm"
      title={title}
      buttonTitle={record ? "Atualizar" : "Criar"}
      buttonProps={{
        loading: isLoading || isLoadingUpdating,
        disabled: isLoading || isLoadingUpdating,
        form: "recordForm",
      }}
      customActions={baseActions}
      customHandleClose={handleCustomClose}
    >
      <FormProvider {...methods}>
        <Flex
          w={["100%", "350px"]}
          bg="white"
          pt={4}
          borderRadius={10}
          border="solid 1px gray-200"
          as="form"
          id="recordForm"
          onSubmit={handleSubmit(data => {
            if (!!record && record?.recurrenceType) {
              onOpen();
            } else if (record) {
              onUpdate();
            } else {
              onSubmit(data);
            }
          })}
          gap={4}
          direction="column"
        >
          <FormRecord
            accounts={accounts}
            categories={categories}
            record={record}
            recordFormOrigin={recordFormOrigin}
            cashFlow={cashFlowOpened}
          />

          {recordFormOrigin === RecordFormOriginEnum.cashFlow ? (
            <Textarea
              label="Observação"
              error={errors.note}
              {...register("note")}
            />
          ) : (
            <>
              <FormRecordPayment accounts={accounts} record={record} />

              <Divider />

              <FormRecordAdvanced record={record} />
            </>
          )}
        </Flex>
      </FormProvider>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" pb={0}>
            <Icon as={FinancialIcon} color="primary.500" />
          </ModalHeader>
          <ModalCloseButton color="primary.500" />
          <ModalBody pb={6}>
            <Text textAlign="center" mb={7}>
              Deseja salvar somente
              <br /> lançamento atual? Ou todas?
            </Text>
            <Button
              w="100%"
              variant="outline"
              mb={3}
              onClick={() => {
                onUpdate("current");
                onClose();
              }}
            >
              Somente atual
            </Button>
            <Button
              w="100%"
              onClick={() => {
                onUpdate("currentAndNext");
                onClose();
              }}
            >
              Atual e próximos
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </BaseSwipeableDrawer>
  );
}

export default RecordFormModal;
