import { isAfter } from "date-fns";
import * as yup from "yup";

export const schemaRecordForm = yup.object({
  // Common Section //
  buyOrderId: yup.string(),
  orderId: yup.string(),
  id: yup.string(),
  type: yup.string(),
  description: yup
    .string()
    .when(["buyOrderId", "orderId"], (buyOrderId, orderId) => {
      if (orderId || buyOrderId) {
        return yup.string();
      }
      return yup.string().required("Título é obrigatório");
    }),
  value: yup
    .number()
    .typeError("Valor é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
  category: yup.string().required("Categoria é obrigatória"),
  hasAccountManagement: yup.bool(),
  financialAccount: yup
    .string()
    .when("hasAccountManagement", hasAccountManagement => {
      if (hasAccountManagement) {
        return yup.string().required("Conta financeira é obrigatória");
      }
      return yup.string();
    }),
  // Payment Section //
  paidStatus: yup.string().required("O status do pagamento é obrigatório"),
  paidDate: yup
    .string()
    .when(["paidStatus"], paidStatus => {
      if (paidStatus === "paid") {
        return yup
          .string()
          .required("Data de pagamento é obrigatória")
          .test("is-valid-date", "Data de pagamento inválida", value => {
            const isValidDate =
              !!value && !isAfter(new Date(value), new Date());
            return isValidDate;
          });
      }
      return yup.string().required("Prazo é obrigatório");
    })
    .required("Data é obrigatório"),
  paymentMethod: yup
    .string()
    .when(["paidStatus", "type"], (paidStatus, type) => {
      if (paidStatus === "paid" && type === "income") {
        return yup.string().required("Forma de pagamento é obrigatória");
      }
      return yup.string();
    }),

  // Advanced Section //
  recurrenceType: yup.string(),
  recurrenceCycle: yup.string().when("recurrenceType", recurrenceType => {
    if (recurrenceType) {
      return yup.string().required("Fequencia do parcelamento é obrigatória");
    }
    return yup.string();
  }),
  parcels: yup.string().when("recurrenceType", recurrenceType => {
    if (recurrenceType === "installments") {
      return yup.string().required("Número de parcelas é obrigatório");
    }
    return yup.string();
  }),
  emissionDate: yup.string().required("Data de emissão é obrigatória"),
  note: yup.string(),
});
