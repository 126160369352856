import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { BaseSwipeableDrawerProvider } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Button from "src/components/Button";
import BottomBar from "src/components/Layouts/BottomBar";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Panel from "src/components/Layouts/Panel";
import { ModalProvider, useModal } from "src/components/Modal/useModal";
import Tabs from "src/components/Tabs";
import { ItemTabsProps } from "src/components/Tabs/props";
import { CONTACT_LABELS } from "src/constants/contacts";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import { useContact } from "src/hooks/contact/useContact";
import { useModule } from "src/hooks/module/useModule";
import { OrderProvider } from "src/hooks/order/useOrder";
import { OrderFiltersProvider } from "src/hooks/order/userOrderFilters";
import { StatusOrderProvider } from "src/hooks/order/useStatusOrder";
import { ViewOrderProvider } from "src/hooks/order/useViewOrder";
import MainContent from "src/pages/Orders/List/components/MainContent";
import { OrderTypeProvider } from "src/pages/Orders/useOrderType";
import { getRouteString } from "src/utils/urlHandler";
import { withContext } from "src/utils/withContext";

import ContactForm from "../components/ContactForm";
import DeleteModal from "../components/DeleteModal";
import Subcontacts from "./components/Subcontacts";
import * as Styled from "./styles";

export const CONTACTS_DETAILS_TABS = (
  subContact: string,
  hasSubContactModule = true
): Array<ItemTabsProps> =>
  [
    { key: "", label: "Dados" },
    { key: "vendas", label: "Vendas" },
    ...(hasSubContactModule
      ? [
          {
            key: subContact.toLowerCase(),
            label: subContact,
          },
        ]
      : []),
  ] as Array<ItemTabsProps>;

const ContactsDetail: React.FC = () => {
  const { id = "", tab = "" } = useParams<{ id: string; tab: string }>();
  const navigate = useNavigate();
  const { subContactNameDefinitions } = useCompany();
  const { hasModules } = useModule();

  const { detail, updateStatus, contact } = useContact();

  const { handleOpen } = useModal();

  useEffect(() => {
    detail(id);
  }, [id]);

  const afterAction = () => {
    navigate(PATHS.CONTACTS_LIST, { replace: true });
  };

  const title = useMemo(() => {
    if (contact.type) {
      return CONTACT_LABELS[contact.type].detail.pageTitle;
    }

    return "";
  }, [contact]);

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
    { label: "Contatos", path: PATHS.CONTACTS_LIST },
    { label: title },
  ];

  return (
    <DashboardLayout selected="register" subSelected="contacts">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />
      <PageTitle label={title}>
        <Box>
          <Styled.DeleteButton
            variant="outlined"
            size="medium"
            onClick={handleOpen}
          >
            Excluir contato
          </Styled.DeleteButton>

          <DeleteModal id={id} afterActions={afterAction} type={contact.type} />
        </Box>
      </PageTitle>

      <Tabs
        tab={tab}
        setTab={tab =>
          navigate(`${getRouteString(PATHS.CONTACTS_DETAIL, id)}/${tab}`, {
            replace: true,
          })
        }
        ariaLabel="Contatos"
        tabs={CONTACTS_DETAILS_TABS(
          subContactNameDefinitions.titlePlural,
          hasModules("subcustomers")
        )}
        tabPanels={[
          <React.Fragment key="contact_data">
            <Panel padding={4}>
              <ContactForm method="update" data={contact} />
            </Panel>

            <BottomBar>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                form="contactForm"
                disabled={updateStatus.loading}
              >
                Salvar
              </Button>

              <Box width={20} />

              <Button
                color="primary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                Cancelar
              </Button>
            </BottomBar>
          </React.Fragment>,
          <React.Fragment key="orders_data">
            <MainContent contactId={id} />
          </React.Fragment>,
          hasModules("subcustomers") ? (
            <React.Fragment key="pets">
              <Subcontacts />
            </React.Fragment>
          ) : undefined,
        ].filter(item => item)}
      />
    </DashboardLayout>
  );
};

export default withContext(
  ContactsDetail,
  ModalProvider,
  OrderProvider,
  OrderTypeProvider,
  StatusOrderProvider,
  ViewOrderProvider,
  { component: OrderFiltersProvider, props: { typeOrder: "serviceOrder" } },
  BaseSwipeableDrawerProvider
);
