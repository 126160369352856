import styled from "styled-components";

import AddCircleIconUnstyled from "@material-ui/icons/AddCircle";

import ButtonUnstyled from "src/components/Button";
import Typography from "src/components/Typography";

export const Button = styled(ButtonUnstyled)`
  height: 55px;
  svg {
    font-size: 30px;
    margin-right: 8px;
  }
`;

export const ValidationText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 12px;
  margin-top: 10px;
`;

export const AddCircleIcon = styled(AddCircleIconUnstyled)`
  margin-left: 6px;
`;
