import React, { useState, createContext, useContext } from "react";

import { STATUS } from "src/constants/requestStatus";
import {
  GetReportBestSellersParamType,
  GetReportBestSellersResponseType,
  GetReportPaymentParamType,
  GetReportPaymentResponseType,
  GetReportDREParamType,
  GetReportDreResponseType,
  GetReportSalesParamType,
  GetReportSalesResponseType,
  GetReportLateClientsParamType,
  GetReportLateClientsResponseType,
  GetReportRecordsParamType,
  GetReportRecordType,
} from "src/interfaces/report";
import {
  getReportBestSellersService,
  getReportPaymentService,
  getDREService,
  getReportSalesService,
  getReportLateClientsService,
  getReportRecordsService,
} from "src/services/report";

import { ReportContextType } from "./props";

export const ReportContext = createContext({} as ReportContextType);

export const ReportProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState(STATUS.inital);
  const [reportSales, setReportSales] = useState<GetReportSalesResponseType>(
    {} as GetReportSalesResponseType
  );
  const [reportLateClients, setReportLateClients] =
    useState<GetReportLateClientsResponseType>(
      {} as GetReportLateClientsResponseType
    );
  const [reportPayments, setReportPayments] =
    useState<GetReportPaymentResponseType>([]);
  const [reportBestSellers, setReportBestSellers] =
    useState<GetReportBestSellersResponseType>([]);
  const [dre, setDre] = useState<GetReportDreResponseType>([]);
  const [reportRecords, setReportRecords] = useState<GetReportRecordType>(
    {} as GetReportRecordType
  );

  const getReportPayments = async (params: GetReportPaymentParamType) => {
    try {
      setStatus(STATUS.loading);

      const response: GetReportPaymentResponseType =
        await getReportPaymentService(params);

      setReportPayments(response);

      setStatus(STATUS.success);
    } catch (e) {
      setReportPayments([]);
      setStatus(STATUS.error);
    }
  };

  const getReportBestSellers = async (
    params: GetReportBestSellersParamType
  ) => {
    try {
      setStatus(STATUS.loading);

      const response: GetReportBestSellersResponseType =
        await getReportBestSellersService(params);

      setReportBestSellers(response);

      setStatus(STATUS.success);
    } catch (e) {
      setReportBestSellers([]);
      setStatus(STATUS.error);
    }
  };

  const getDRE = async (params: GetReportDREParamType) => {
    try {
      setStatus(STATUS.loading);

      const response: GetReportDreResponseType = await getDREService(params);

      setDre(response);

      setStatus(STATUS.success);
    } catch (e) {
      setReportBestSellers([]);
      setStatus(STATUS.error);
    }
  };

  const getReportSales = async (params: GetReportSalesParamType) => {
    try {
      setStatus(STATUS.loading);

      const response: GetReportSalesResponseType = await getReportSalesService(
        params
      );

      setReportSales(response);

      setStatus(STATUS.success);
    } catch (e) {
      setReportPayments([]);
      setStatus(STATUS.error);
    }
  };

  const getReportLateClients = async (
    params: GetReportLateClientsParamType
  ) => {
    try {
      setStatus(STATUS.loading);

      const response: GetReportLateClientsResponseType =
        await getReportLateClientsService(params);

      setReportLateClients(response);

      setStatus(STATUS.success);
    } catch (e) {
      setReportPayments([]);
      setStatus(STATUS.error);
    }
  };

  const getReportRecords = async (params: GetReportRecordsParamType) => {
    try {
      setStatus(STATUS.loading);

      const response: GetReportRecordType = await getReportRecordsService(
        params
      );

      setReportRecords(response);

      setStatus(STATUS.success);
    } catch (e) {
      setReportPayments([]);
      setStatus(STATUS.error);
    }
  };

  return (
    <ReportContext.Provider
      value={{
        status,

        reportPayments,
        getReportPayments,

        reportBestSellers,
        getReportBestSellers,

        dre,
        getDRE,

        reportSales,
        getReportSales,

        reportLateClients,
        getReportLateClients,

        reportRecords,
        getReportRecords,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const useReport = () => {
  const context = useContext(ReportContext);

  if (!context) {
    throw new Error("useReport must be used within a ReportProvider");
  }

  return context;
};
