import styled from "styled-components";

import Typography from "src/components/Typography";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

export const Account = styled.button`
  background-color: white;
  border-radius: 10px;
  padding: 0.75rem;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const AccountHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const AccountHeaderRow = styled.div`
  display: flex;
  align-items: center;
`;

export const AccountLogo = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const AccountName = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  text-align: left;
`;

export const AccountType = styled(Typography)`
  font-size: 10px;
  color: #92969c;
  margin: 0;
  text-align: left;
`;

export const AccountFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccountBalance = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  color: #1e2c47;
  margin: 0;
`;
