import styled from "styled-components";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpwardRounded";
import Skeleton from "@material-ui/lab/Skeleton";

import Typography from "src/components/Typography";

export const LabelValue = styled(Typography)<{ redCurrency?: boolean }>`
  font-size: 23px;
  font-weight: 700;
  color: ${({ theme, redCurrency }) =>
    redCurrency ? theme.palette.error.main : theme.palette.primary.main};
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const LabelPercentage = styled(Typography)<{ redCurrency?: boolean }>`
  font-size: 10px;
  font-weight: 600;
  color: ${({ theme, redCurrency }) =>
    redCurrency ? theme.palette.error.main : theme.palette.primary.main};
  line-height: 1;
  background-color: rgba(172, 226, 246, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 6px;
`;

export const Label = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  color: #92969c;
  line-height: 1;
  margin-bottom: 8px;
`;

export const ArrowUpward = styled(ArrowUpwardIcon)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 9999px;
  width: 32px;
  height: 32px;
`;

export const ArrowDownward = styled(ArrowDownwardIcon)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.error.main};
  border-radius: 9999px;
  width: 32px;
  height: 32px;
`;

export const Loading = styled(Skeleton)`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.secondary.light};
`;
