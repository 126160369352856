import React from "react";

import Box from "@material-ui/core/Box";

import { PageTitleProps } from "./props";
import * as Styled from "./styles";

const PageTitle: React.FC<PageTitleProps> = props => {
  const { children, label, ...rest } = props;

  return (
    <Box
      mt={3}
      mb={3}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Styled.Typography variant="h5" color="primary">
        {label}
      </Styled.Typography>
      <div>{children}</div>
    </Box>
  );
};

export default PageTitle;
