/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";

import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { endOfMonth, format, startOfMonth } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

// import AzulouLogo from "../../../assets/azulou-logo.png";
import Button from "src/components/Button";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Panel from "src/components/Layouts/Panel";
import Loader from "src/components/Loader";
import GeneratePDF from "src/components/NewComponents/GeneratePDF";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import PATHS from "src/constants/paths";
import { ReportProvider, useReport } from "src/hooks/report/useReport";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { formatPrice } from "src/utils/format";
import { withContext } from "src/utils/withContext";

import InformationInitialReportState from "../components/InformationInitialReportState";
import DreItemCard from "./components/DreItemCard";
import * as Styled from "./styled";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "DRE" },
];
const styles = StyleSheet.create({
  content: {
    padding: 20,
    paddingBottom: 40,
    paddingHorizontal: 30,
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
  },
  body: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderBottomWidth: "0.5px",
    borderColor: "gray",
  },
  text: {
    fontSize: "12px",
    color: "#5B5C60",
    fontWeight: 400,
    marginBottom: 10,
  },
});

const today = new Date();

const DrePage = () => {
  const { dre, status, getDRE } = useReport();
  const { user } = useAuth();

  const [startDate, setStartDate] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(endOfMonth(today));

  const [startDateLegend, setStartDateLegend] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDateLegend, setEndDateLegend] = useState<Date | undefined>(
    endOfMonth(today)
  );

  const getColor = (type: string, total: number) => {
    switch (type) {
      case "income":
        return "#074EE8";
      case "expense":
        return "#E54848";
      default:
        return total < 0 ? "#E54848" : "#14B738";
    }
  };

  const getSymbol = (type: string) => {
    switch (type) {
      case "income":
        return "(+) ";
      case "expense":
        return "(-) ";
      default:
        return "(=) ";
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.content}>
        <View style={styles.body}>
          <Text
            style={{
              marginBottom: 20,
              fontSize: 19,
              color: "#13223E",
              fontWeight: "bold",
            }}
          >
            Relatório de DRE
          </Text>
          <Text
            style={{
              marginBottom: 10,
              fontWeight: 500,
              fontSize: 15,
              color: "#5B5C60",
              width: "100%",
            }}
          >
            {user.company.fantasyName}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <View>
              <Text style={styles.text}>
                {user.company.cnpj
                  ? `CNPJ: ${user.company.cnpj}`
                  : `CPF: ${user.company.cpf}`}
              </Text>
              <Text style={styles.text}>
                {user.company.neighborhood} {user.company.number}
              </Text>
              <Text style={styles.text}>{user.company.phone}</Text>
              <Text style={styles.text}>{user.company.email}</Text>
            </View>
            <View>
              <Text style={styles.text}>
                Emitido em: {format(new Date(), "MM/dd/yyyy")}
              </Text>
              <Text style={styles.text}>
                Hora: {format(new Date(), "H:mm")}
              </Text>
            </View>
          </View>
        </View>
        <View>
          {dre.map(item => (
            <View
              key={item.title}
              style={{
                borderBottomWidth: "0.5px",
                borderColor: "gray",
                gap: 8,
                paddingVertical: 8,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text style={{ fontSize: "13px", color: "#5B5C60" }}>
                    {getSymbol(item.type)}
                  </Text>
                  <Text style={{ fontSize: "13px", color: "#5B5C60" }}>
                    {item.title}
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: "13px",
                    color: getColor(item.type, item.total as number),
                  }}
                >
                  <Text>{formatPrice(item.total)}</Text>
                </View>
              </View>
              {item?.data?.map(dre => (
                <View
                  key={item.title}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "12px",
                      color: "#5B5C60",
                      fontWeight: 400,
                    }}
                  >
                    {dre.name}
                  </Text>
                  <Text
                    style={{
                      fontSize: "12px",
                      color: getColor(dre.type, dre.total as number),
                      fontWeight: 400,
                    }}
                  >
                    {formatPrice(dre.total)}
                  </Text>
                </View>
              ))}
            </View>
          ))}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-end",
            paddingTop: 20,
            alignItems: "flex-end",
          }}
        >
          <Text style={{ color: "#5B5C60", fontSize: "12px" }}>Criado em </Text>
          <Text style={{ color: "#13223E", fontSize: "12px" }}>Azulou</Text>
        </View>
      </Page>
    </Document>
  );

  const handleGenerateReport = () => {
    setStartDateLegend(startDate);
    setEndDateLegend(endDate);
    getDRE({
      startDate: formatStartDateISO(startDate || today),
      endDate: formatEndDateISO(endDate || today),
    });
  };

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Financeiro de DRE" />
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-end"
        width="100%"
        style={{ gap: ".5rem" }}
      >
        <PeriodFilterPicker
          startDate={startDate || today}
          endDate={endDate || today}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          initialFilter="byMonth"
          options={["perInterval", "byMonth"]}
          showingInPopover={false}
        />
        <Button
          style={{ height: 56, marginLeft: 8 }}
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleGenerateReport}
        >
          Gerar relatório
        </Button>
        <GeneratePDF
          doc={<MyDocument />}
          fileName="Relatorio DRE.pdf"
          buttonName="gerar PDF"
        />
      </Box>
      <Box my={3}>
        <Divider />
      </Box>

      {status.initial ? <InformationInitialReportState /> : null}
      {!status.initial && !status.loading ? (
        <>
          <Styled.Legend color="primary" variant="h6">
            De
            <strong>
              {startDateLegend
                ? format(startDateLegend, " dd/MM/yyyy ", {
                    locale: ptBrLocale,
                  })
                : ""}
            </strong>
            até
            <strong>
              {endDateLegend
                ? format(endDateLegend, " dd/MM/yyyy", { locale: ptBrLocale })
                : ""}
            </strong>
          </Styled.Legend>
          <Panel padding={3}>
            {dre.map(item => (
              <DreItemCard key={item.title} dre={item} />
            ))}
          </Panel>
        </>
      ) : null}
      {status.loading ? (
        <Box height="100%">
          <Loader size="medium" />
        </Box>
      ) : null}
    </DashboardLayout>
  );
};

export default withContext(DrePage, ReportProvider);
