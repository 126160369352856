import {
  Box,
  Button,
  Divider,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Wrap,
} from "@chakra-ui/react";

import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import CloseCashFlowModal from "src/pages/Financial/CashFlow/components/Modals/CloseCashFlowModal";
import OpenCashFlowModal from "src/pages/Financial/CashFlow/components/Modals/OpenCashFlowModal";
import RecordFormModal, {
  RecordFormOriginEnum,
} from "src/pages/Financial/components/Modals/RecordFormModal";
import TransferFormModal, {
  TransferFormOriginEnum,
} from "src/pages/Financial/components/Modals/TransferFormModal";
import { useCashFlowOpened } from "src/services/hooks/cashFlow/useCashFlowOpened";
import { useCashFlowResume } from "src/services/hooks/cashFlow/useCashFlowResume";
import { formatPrice } from "src/utils/format";

export function CashFlowActions() {
  const { hasModules } = useModule();
  const { userCompany } = useCompany();

  const { open, handleOpen, handleClose } = useBaseSwipeableDrawer();

  const { data: cashFlowResume, refetch } = useCashFlowResume({
    enabled: hasModules("cashflowcontrol"),
  });

  const {
    data: cashFlowOpened,
    error,
    isLoading,
  } = useCashFlowOpened({
    enabled: hasModules("cashflowcontrol"),
    retry: 1,
    retryDelay: 300,
  });

  const hasCashFlowOpened = cashFlowOpened && !error;

  return (
    <>
      {isLoading ? null : !hasCashFlowOpened ? (
        <Button
          onClick={() => {
            handleOpen("openCashFlowModal");
          }}
        >
          Abrir caixa
        </Button>
      ) : (
        <Popover
          onOpen={() => {
            refetch();
          }}
        >
          <PopoverTrigger>
            <Button>Informações de caixa</Button>
          </PopoverTrigger>
          <Portal>
            <Box zIndex="popover" w="full" h="full" position="relative">
              <PopoverContent>
                <PopoverHeader
                  px={6}
                  pt={5}
                  border="none"
                  fontSize="lg"
                  fontWeight={700}
                  color="primary.500"
                >
                  Caixa aberto
                </PopoverHeader>
                <PopoverCloseButton
                  fontSize="md"
                  color="primary.500"
                  top={4}
                  right={6}
                />
                <PopoverBody px={6}>
                  <Box>
                    <Text fontSize="sm" fontWeight={700} color="primary.500">
                      Entradas:
                    </Text>
                    {!cashFlowResume?.incomes.length ? (
                      <Text flex={1} fontSize="xs" color="black" mt={2}>
                        Nenhuma receita vinculada.
                      </Text>
                    ) : null}
                    {cashFlowResume?.incomes.map(income => {
                      return (
                        <Wrap key={income.paymentMethod}>
                          <Text flex={1} fontSize="xs" color="text">
                            {userCompany.paymentMethods?.find(
                              paymentMethod =>
                                paymentMethod.slug === income.paymentMethod
                            )?.title ?? ""}
                          </Text>
                          <Text fontSize="xs" color="primary.500">
                            {formatPrice(income.total)}
                          </Text>
                        </Wrap>
                      );
                    })}
                  </Box>
                  <Divider my={2} />
                  <Box>
                    <Text fontSize="sm" fontWeight={700} color="error.500">
                      Saídas:
                    </Text>
                    {!cashFlowResume?.expenses.length ? (
                      <Text flex={1} fontSize="xs" color="black" mt={2}>
                        Nenhuma despesa vinculada.
                      </Text>
                    ) : null}
                    {cashFlowResume?.expenses.map(expense => {
                      return (
                        <Wrap
                          key={`${expense.financialAccount}${Math.random()}`}
                        >
                          <Text flex={1} fontSize="xs" color="text">
                            {expense.type === "expense"
                              ? "Despesas"
                              : "Sangria"}
                            {" - "}
                            {expense.financialAccountType === "cash"
                              ? "Dinheiro"
                              : expense.financialAccount}
                          </Text>
                          <Text fontSize="xs" color="error.500">
                            {formatPrice(expense.total)}
                          </Text>
                        </Wrap>
                      );
                    })}
                  </Box>
                  <Divider my={2} />
                  <Box>
                    <Text fontSize="sm" fontWeight={700} color="primary.500">
                      Resumo do caixa:
                    </Text>
                    <Wrap>
                      <Text flex={1} fontSize="xs" color="text">
                        Fundo de caixa
                      </Text>
                      <Text fontSize="xs" color="primary.500">
                        {formatPrice(cashFlowResume?.initMoney ?? 0)}
                      </Text>
                    </Wrap>
                    <Wrap>
                      <Text flex={1} fontSize="xs" color="text">
                        Entrada espécie
                      </Text>
                      <Text fontSize="xs" color="primary.500">
                        {formatPrice(cashFlowResume?.incomesMoney ?? 0)}
                      </Text>
                    </Wrap>
                    <Wrap>
                      <Text flex={1} fontSize="xs" color="text">
                        Sangria/Despesas
                      </Text>
                      <Text fontSize="xs" color="error.500">
                        {formatPrice(cashFlowResume?.expensesMoney ?? 0)}
                      </Text>
                    </Wrap>
                  </Box>
                </PopoverBody>
                <PopoverFooter
                  as={Wrap}
                  gap={4}
                  px={6}
                  pb={6}
                  mt={4}
                  border="none"
                >
                  <Button
                    flexGrow={1}
                    onClick={() => {
                      handleOpen("transferForm");
                    }}
                  >
                    Sangria
                  </Button>
                  <Button
                    flexGrow={1}
                    colorScheme="error"
                    onClick={() => {
                      handleOpen("recordForm");
                    }}
                  >
                    Despesa
                  </Button>
                  <Button
                    w="100%"
                    variant="outline"
                    colorScheme="error"
                    onClick={() => {
                      handleOpen("closeCashFlowModal");
                    }}
                  >
                    Fechar caixa
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Box>
          </Portal>
        </Popover>
      )}
      <RecordFormModal
        handleCloseRecordFormModal={() => {
          handleClose("recordForm");
        }}
        recordFormOrigin={RecordFormOriginEnum.cashFlow}
        recordType="expense"
      />
      <TransferFormModal transferFormOrigin={TransferFormOriginEnum.cashFlow} />
      {open.includes("openCashFlowModal") ? <OpenCashFlowModal /> : null}
      {open.includes("closeCashFlowModal") ? <CloseCashFlowModal /> : null}
    </>
  );
}
