import styled from "styled-components";

import Chip from "@material-ui/core/Chip";

import { ReactComponent as OrdersIconUnstyled } from "src/assets/icons/emptyTable/orders.svg";
import Typography from "src/components/Typography";

import { ChipOrderProps, StatusTextProps } from "./props";

export const StatusText = styled(Typography)<StatusTextProps>`
  color: ${({ statusColor }) => statusColor};
`;

export const OrderIcon = styled(OrdersIconUnstyled)`
  margin-bottom: 15px;
`;

export const MarkedText = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const ChipOrder = styled<ChipOrderProps>(Chip)`
  font-size: 13px;
  font-weight: 500;
  color: white;
  background-color: ${({ background }) => background};
  border-radius: 99999px;
  height: 30px;
  display: flex;
  align-items: center;
`;
