import styled from "styled-components";

import Box from "@material-ui/core/Box";
import InputBase from "@material-ui/core/InputBase";

import Textarea from "src/components/Inputs/Textarea";

export const ContainerTextArea = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid #d6d8dc;
  box-shadow: none;
  padding: 25px;
  min-height: 250px;
`;

export const AditionalInfoTextArea = styled(Textarea)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

export const BasicTextArea = styled(InputBase)`
  margin-top: 10px;
  width: 100%;
  .MuiInputBase-input.Mui-disabled {
    color: ${({ theme }) => theme.palette.common.black};
  }
`;
