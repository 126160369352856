import styled from "styled-components";

import { DateTimePicker, DatePicker } from "@material-ui/pickers";

export const Container = styled.div`
  & * {
    border-style: none;
    cursor: pointer;
  }
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.palette.grey[400]};
  border-style: solid;
  width: 100%;
`;

export const DeadlineDatePicker = styled(DatePicker)`
  & * {
    border-style: none;
  }
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const DeadlineDateTimePicker = styled(DateTimePicker)`
  & * {
    border-style: none;
  }
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
