import styled from "styled-components";

import MuiDialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";

import Button from "src/components/Button";
import { boxShadow } from "src/styles/mixins/shadows";

export const Dialog = styled(MuiDialog)`
  .MuiDialog-paper {
    overflow-x: hidden;
  }
`;

export const Container = styled.div`
  ${boxShadow}
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`;

export const CloseButton = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export const DeleteButton = styled(Button)`
  border-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.error.main};
  margin-bottom: 8px;
`;
