import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";

import { Box, Button, Divider, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { Input } from "src/components/NewComponents/Input";
import { Select } from "src/components/NewComponents/Select";
import { BRAZIL_UF } from "src/constants/uf";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { CompanyType } from "src/interfaces/company";
import {
  BusinessUpdateType,
  useBusinessUpdate,
} from "src/services/hooks/auth/useBusinessUpdate";

import { ChangeCompanyLogo } from "./ChangeCompanyLogo";
import { schemaMyBusinessForm } from "./schema";

export type MyBusinessScheme = BusinessUpdateType;

const UFItems = BRAZIL_UF.map(item => ({
  label: item.uf,
  value: item.uf,
}));

function MyBusiness() {
  const { user, setUser, isOwner } = useAuth();

  const defaultValues: MyBusinessScheme = {
    type: user.company.type,
    cpf: user.company?.cpf || "",
    cnpj: user.company?.cnpj || "",
    fantasyName: user.company?.companyName || "",
    email: user.company?.email || "",
    phone: user.company?.phone || "",
    cep: user.company?.cep || "",
    street: user.company?.street || "",
    number: user.company?.number || "",
    neighborhood: user.company?.neighborhood || "",
    uf: user.company?.uf || "",
    city: user.company?.city || "",
    complement: user.company?.complement || "",
  };

  const methods = useForm<MyBusinessScheme>({
    defaultValues,
    resolver: yupResolver(schemaMyBusinessForm),
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    getValues,
    control,
  } = methods;

  const { mutate: businessUpdate, isLoading } = useBusinessUpdate({
    onSuccess: () => {
      const values = getValues();
      reset(values);
      setUser({
        ...user,
        company: {
          ...user.company,
          ...(values as CompanyType),
        },
      });
      toast.success("Dados atualizados com sucesso.");
    },
  });

  const onSubmit = (values: MyBusinessScheme) => {
    const data: MyBusinessScheme = {
      ...values,
      email: values.email?.toLowerCase(),
      cnpj: values.type === "PJ" ? values.cnpj.replace(/[^0-9]+/gi, "") : "",
      cpf: values.type === "PF" ? values.cpf.replace(/[^0-9]+/gi, "") : "",
      cep: values.cep.replace(/[^0-9]+/gi, ""),
      phone: values.phone.replace(/[^0-9]+/gi, ""),
    };

    businessUpdate(data);
  };

  return (
    <>
      <Box
        mb={6}
        p={6}
        bg="white"
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text color="primary.500" fontWeight="bold">
          Informações da empresa
        </Text>

        <Divider my={4} />

        <ChangeCompanyLogo />

        <SimpleGrid columns={[1, 1, 2]} gap={4}>
          {user.company.type === "PJ" ? (
            <Input
              as={InputMask}
              label="CPNJ"
              error={errors.cnpj}
              inputMaskProps={{
                mask: "99.999.999/9999-99",
              }}
              disabled={!isOwner}
              {...register("cnpj")}
            />
          ) : (
            <Input
              as={InputMask}
              label="CPF"
              error={errors.cpf}
              inputMaskProps={{
                mask: "999.999.999-99",
              }}
              disabled={!isOwner}
              {...register("cpf")}
            />
          )}
          <Input
            label="Empresa"
            error={errors.fantasyName}
            disabled={!isOwner}
            {...register("fantasyName")}
          />
          <Input
            label="Telefone empresarial"
            error={errors.phone}
            id="companyPhone"
            inputMaskProps={{
              mask: "(99) 99999-9999",
            }}
            disabled={!isOwner}
            {...register("phone")}
          />
          <Input
            label="E-mail corporativo"
            error={errors.email}
            id="companyEmail"
            disabled={!isOwner}
            {...register("email")}
          />
          <Input
            label="CEP"
            error={errors.cep}
            inputMaskProps={{
              mask: "99999-999",
            }}
            disabled={!isOwner}
            {...register("cep")}
          />
          <Input
            label="Endereço"
            error={errors.street}
            disabled={!isOwner}
            {...register("street")}
          />
          <Input
            label="Número"
            error={errors.number}
            disabled={!isOwner}
            {...register("number")}
          />
          <Input
            label="Bairro"
            error={errors.neighborhood}
            disabled={!isOwner}
            {...register("neighborhood")}
          />
          <Controller
            control={control}
            name="uf"
            render={({ field: { ref, ...props } }) => (
              <Select
                options={UFItems}
                inputRef={ref}
                error={!!errors.uf}
                placeholder="Selecione a UF"
                isDisabled={!isOwner}
                {...props}
              />
            )}
          />
          <Input
            label="Cidade"
            error={errors.city}
            disabled={!isOwner}
            {...register("city")}
          />
          <Input
            label="Complemento"
            error={errors.complement}
            disabled={!isOwner}
            {...register("complement")}
          />
        </SimpleGrid>
        {isDirty ? (
          <Flex justifyContent="flex-end" mt={4}>
            <Button size="md" type="submit" isLoading={isLoading}>
              Salvar
            </Button>
          </Flex>
        ) : null}
      </Box>
    </>
  );
}

export default MyBusiness;
