import styled from "styled-components";

import MuiTab from "@material-ui/core/Tab";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTabScrollButton from "@material-ui/core/TabScrollButton";

export const Tabs = styled(MuiTabs)`
  width: min-content;
  border-bottom: 2px solid #d6d8dc;
`;

export const Tab = styled(MuiTab)`
  text-transform: initial;
  background-color: #f8f9fa;
`;

export const TabPanel = styled.div`
  background-color: aliceblue;
  padding: 0px;
`;

export const TabScrollButton = styled(MuiTabScrollButton)`
  width: 28px;
  overflow: hidden;
  transition: width 0.5s;
  &.Mui-disabled {
    width: 0px;
  }
`;
