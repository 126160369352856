import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import {
  Box,
  Button,
  Circle,
  Divider,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import PrintIcon from "@material-ui/icons/Print";

import { PrinterGuideTemplate } from "./components/PrinterGuideTemplate";

function PrintGuide() {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Box
        mb={6}
        p={6}
        bg="white"
        borderRadius={10}
        border="solid 1px gray-200"
      >
        <Text color="primary.500" fontWeight="bold">
          Teste de impressão
        </Text>

        <Divider my={4} />

        <HStack>
          <Circle size="64px" bg="gray.100">
            <Icon boxSize="24px" color="primary.500" as={PrintIcon} />
          </Circle>

          <Text>
            Para imprimir um guia de tamanhos e verifcar qual o que melhor se
            adequa a sua impressora.{" "}
            <Button
              variant="link"
              fontSize="md"
              fontWeight={500}
              onClick={handlePrint}
            >
              Clique aqui para imprimir o guia.
            </Button>
          </Text>
        </HStack>

        <PrinterGuideTemplate ref={componentRef} />
      </Box>
    </>
  );
}

export default PrintGuide;
