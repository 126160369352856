import styled from "styled-components";

import Typography from "src/components/Typography";

export const Legend = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

export const YearSelectorContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 56px;
  width: 200px;
`;
