import React, { createContext, useContext, useState } from "react";

import { TypeOfContact } from "src/interfaces/contact";

import { ContactTypeContextProps } from "./props";

export const ContactTypeContext = createContext({} as ContactTypeContextProps);

export const ContactTypeProvider: React.FC = props => {
  const { children } = props;

  const [type, setType] = useState<TypeOfContact>("customer");

  return (
    <ContactTypeContext.Provider
      value={{
        type,
        setType,
      }}
    >
      {children}
    </ContactTypeContext.Provider>
  );
};

export const useContactType = () => {
  const context = useContext(ContactTypeContext);

  if (!context) {
    throw new Error("useContactType must be used within a ContactTypeProvider");
  }

  return context;
};
