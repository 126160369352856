import styled from "styled-components";

import AppBarUnstyled from "@material-ui/core/AppBar";

export const AppBar = styled(AppBarUnstyled)`
  top: auto;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-top: 1.5px solid #d6d8dc;
  box-shadow: none;
  color: unset;
`;

export const Container = styled.div`
  margin-left: 250px;
  min-height: 90px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 25px;
`;
