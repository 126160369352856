import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { FinancialAccountType } from "src/interfaces/financialAccount";
import api from "src/services/api";

export interface UpdateRecordParams {
  id: string;
  value: number;
  description?: string;
  paidDate: string;
  emissionDate: string;
  category: string;
  note?: string;
  financialAccount?: string;
  updateType: "current" | "currentAndNext";
}

export const updateRecord = (params: UpdateRecordParams) =>
  api
    .put<FinancialAccountType>(`v1/record/${params.id}`, params)
    .then(res => res.data);

export function useUpdateRecord(
  config?: UseMutationOptions<any, any, UpdateRecordParams, unknown>
) {
  return useMutation<any, any, UpdateRecordParams>(
    (data: UpdateRecordParams) => updateRecord(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ??
            "Ocorreu um problema ao atualizar lançamento"
        );
      },
      ...config,
    }
  );
}
