import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { FinancialAccountType } from "src/interfaces/financialAccount";
import api from "src/services/api";

export interface CreateRecordParams {
  id?: string;
  value: number;
  type: string;
  paidStatus: string;
  paidDate: string;
  emissionDate: string;
  description?: string;
  category: string;
  recurrenceType?: string;
  recurrenceCycle?: string;
  parcels?: number;
  note?: string;

  financialAccount?: string;
  paymentMethod?: string;
  orderId?: string;
  buyOrderId?: string;
  contact?: string;
}

export const createRecord = (params: CreateRecordParams) =>
  api.post<FinancialAccountType>(`v1/record`, params).then(res => res.data);

export function useCreateRecord(
  config?: UseMutationOptions<any, any, CreateRecordParams, unknown>
) {
  return useMutation<any, any, CreateRecordParams>(
    (data: CreateRecordParams) => createRecord(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao criar lançamento"
        );
      },
      ...config,
    }
  );
}
