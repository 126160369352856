import React, { FC } from "react";

import { BoxProps } from "@material-ui/core/Box";

import * as Styled from "./styles";

const Panel: FC<BoxProps> = props => {
  const { children, ...rest } = props;

  return <Styled.Panel {...rest}>{children}</Styled.Panel>;
};

export default Panel;
