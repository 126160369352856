import styled from "styled-components";

import { KeyboardTimePicker as KeyboardTimePickerUnstyled } from "@material-ui/pickers";

export const KeyboardTimePicker = styled(KeyboardTimePickerUnstyled)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 100%;
  .MuiIconButton-root {
    padding: 0px;
  }
`;
