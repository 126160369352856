import * as yup from "yup";

export const schemaCloseCashFlowForm = yup.object({
  cashFlow: yup.string().required("CashFlow é obrigatório"),
  initMoney: yup
    .number()
    .required("Valor é obrigatório")
    .typeError("Valor é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
  closeMoney: yup
    .number()
    .required("Valor é obrigatório")
    .typeError("Valor é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
  actualMoney: yup
    .number()
    .required("Valor é obrigatório")
    .typeError("Valor é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
  note: yup.string(),
});
