import {
  PaidStatusType,
  RecordTypeType,
  FieldDateType,
} from "src/interfaces/record";

type RecordStatusType = {
  [key in PaidStatusType]: {
    name: string;
    color: string;
  };
};

export const RECORD_TYPE: { [key in RecordTypeType]: string } = {
  expense: "Despesa",
  income: "Receita",
  transferOut: "Trasferência - Saída",
  transferIn: "Trasferência - Entrada",
};

export const RECORD_FITER_DATE_TYPE: { [key in FieldDateType]: string } = {
  createdAt: "Criação",
  emissionDate: "Emissão",
  paidDate: "Pagamento",
};

export const RECORD_STATUS: RecordStatusType = {
  paid: {
    name: "Pago",
    color: "#1A5DF2",
  },
  pending: {
    name: "Não pago",
    color: "#e54848",
  },
  canceled: {
    name: "Cancelado",
    color: "#e54848",
  },
};

export const RECORD_TYPE_COLOR = {
  expense: "#e54848",
  income: "#1A5DF2",
  transferOut: "#e54848",
  transferIn: "#1A5DF2",
};
