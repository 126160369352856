import React, { FC } from "react";

import Button from "src/components/Button";

import * as Styled from "./styles";

interface ReportCardProps {
  title: string;
  subtitle: string;
  path: string;
}

const ReportCard: FC<ReportCardProps> = ({ title, subtitle, path }) => {
  return (
    <Styled.Container>
      <Styled.Article>
        <Styled.Title>{title}</Styled.Title>
        <Styled.SubTitle>{subtitle}</Styled.SubTitle>
      </Styled.Article>
      <Button
        to={path}
        variant="contained"
        color="primary"
        size="medium"
        type="submit"
      >
        Ir para relatório
      </Button>
    </Styled.Container>
  );
};

export default ReportCard;
