import PATHS from "src/constants/paths";
import AccountStatementPage from "src/pages/Financial/AccountStatement";
import BillsToPayPage from "src/pages/Financial/BillsToPay";
import BillsToReceivePage from "src/pages/Financial/BillsToReceive";
import FinancialAccountPage from "src/pages/Financial/FinancialAccount";

import { RouteObjectProps } from "..";

export const financialRoutes: RouteObjectProps[] = [
  {
    path: PATHS.BILL_TO_RECEIVE,
    element: <BillsToReceivePage />,
    permissions: [
      {
        subject: "record",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.BILL_TO_PAY,
    element: <BillsToPayPage />,
    permissions: [
      {
        subject: "record",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.FINANCIAL_ACCOUNT_STATEMENT,
    element: <AccountStatementPage />,
    permissions: [
      {
        subject: "record",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.FINANCIAL_ACCOUNTS,
    element: <FinancialAccountPage />,
    permissions: [
      {
        subject: "financialAccount",
        action: "view",
      },
    ],
  },
];
