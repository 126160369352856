import React, { FC } from "react";

import { Box } from "@material-ui/core";

import Typography from "src/components/Typography";
import { OrderListType } from "src/interfaces/order";

interface RenderContactCellProps {
  order: OrderListType;
}

const RenderContactCell: FC<RenderContactCellProps> = ({ order }) => {
  return (
    <div>
      <Typography style={{ fontSize: "14px" }}>
        {order.contact?.name ?? "-"}
      </Typography>
      {!!order?.subContact && (
        <Typography
          color="textSecondary"
          style={{ marginTop: "4px", fontSize: "8px" }}
        >
          <Box
            component="span"
            bgcolor="#ACE2F6"
            color="#074EE8"
            borderRadius="9999px"
            marginRight={0.5}
            paddingX={1}
            paddingY={0.5}
          >
            {order.subContact.data[0].content}
          </Box>
        </Typography>
      )}
    </div>
  );
};

export default RenderContactCell;
