import { toast } from "react-toastify";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "src/services/api";

export const activeUser = (id: string) =>
  api.patch(`/v1/user/restricted/${id}/enable `).then(res => res);

export function useActiveUser() {
  const queryClient = useQueryClient();

  return useMutation((id: string) => activeUser(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast.success("Usuário ativado com sucesso.");
    },
    onError: ({ response }) => {
      toast.error(
        response?.data?.message ?? "Ocorreu um problema ao ativar esse usuário"
      );
    },
  });
}
