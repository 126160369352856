import { useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { Flex, Image } from "@chakra-ui/react";

import { CurrencyInput } from "src/components/NewComponents/CurrencyInput";
import { Input } from "src/components/NewComponents/Input";
import { Select } from "src/components/NewComponents/Select";
import RenderModule from "src/components/RenderModule";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { CashFlow } from "src/interfaces/cashFlow";
import { CategoryRecordType } from "src/interfaces/categoryRecord";
import { GroupOption, Option } from "src/interfaces/common";
import { FinancialAccountType } from "src/interfaces/financialAccount";
import { RecordType } from "src/interfaces/record";
import { groupFinancialAccounts } from "src/utils/financialAccount";

import { RecordFormOriginEnum, RecordFormSchema } from "../..";

interface FormReportProps {
  record?: RecordType;
  accounts: FinancialAccountType[];
  categories: CategoryRecordType[];
  recordFormOrigin?: RecordFormOriginEnum;
  cashFlow?: CashFlow;
}

export function FormRecord({
  record,
  accounts,
  categories,
  recordFormOrigin,
  cashFlow,
}: FormReportProps) {
  const { userCompany } = useCompany();
  const { hasModules } = useModule();

  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext<RecordFormSchema>();

  const paymentMethod = useWatch({ name: "paymentMethod", control });
  const financialAccount = useWatch({ name: "financialAccount", control });

  const financialAccountsGrouped = useMemo(() => {
    return groupFinancialAccounts(
      accounts.filter(account => {
        if (cashFlow) {
          return (
            account.type !== "cash" ||
            account.id === (cashFlow.financialAccount as unknown as string)
          );
        }
        return true;
      })
    );
  }, [accounts, cashFlow]);

  const categoriesGrouped = useMemo(() => {
    return categories.reduce<GroupOption[]>((group, categorie) => {
      const label = categorie.name;

      return [
        ...group,
        {
          label,
          options: categorie.subCategories.map(subCategory => ({
            label: subCategory.name,
            value: subCategory.id,
          })),
        },
      ];
    }, []);
  }, [categories]);

  return (
    <>
      {!!record?.order || !!record?.buyOrder ? null : (
        <Input
          label="Título"
          error={errors.description}
          {...register("description")}
        />
      )}

      <CurrencyInput
        label="Valor"
        error={errors.value}
        control={control}
        isDisabled={record && record?.paidStatus === "paid"}
        name="value"
      />

      <Controller
        control={control}
        name="category"
        render={({ field: { ref, ...props } }) => {
          return (
            <Select
              options={categoriesGrouped}
              inputRef={ref}
              error={!!errors.category}
              isDisabled={!!record?.order || !!record?.buyOrder}
              errorMessage={errors.category?.message}
              placeholder="Selecione a categoria"
              isClearable={false}
              {...props}
            />
          );
        }}
      />

      {!record ||
      record?.paidStatus === "pending" ||
      !!record?.financialAccount ? (
        <RenderModule modules={["accountManagement"]}>
          <Controller
            control={control}
            name="financialAccount"
            render={({ field: { ref, onChange } }) => (
              <Select
                options={financialAccountsGrouped}
                inputRef={ref}
                error={!!errors.financialAccount}
                errorMessage={errors.financialAccount?.message}
                placeholder="Conta"
                isClearable={false}
                isDisabled={
                  record?.paidStatus === "paid" ||
                  recordFormOrigin === RecordFormOriginEnum.cashFlow
                }
                formatOptionLabel={(data: unknown) => {
                  const account = data as Option;
                  return (
                    <Flex alignItems="center" gap={2}>
                      <Image
                        borderRadius="full"
                        boxSize="24px"
                        src={account?.url}
                        alt={account.label as string}
                      />
                      {account.label}
                    </Flex>
                  );
                }}
                onChange={financialAccount => {
                  onChange(financialAccount);
                  if (hasModules("cashflowcontrol")) {
                    const paymentMethodSlugSelected =
                      userCompany.paymentMethods?.find(
                        method => method.id === paymentMethod
                      )?.slug;

                    const financialAccountTypeSelected = accounts.find(
                      account => account.id === financialAccount
                    )?.type;

                    if (
                      (paymentMethodSlugSelected === "money" &&
                        financialAccountTypeSelected !== "cash") ||
                      (paymentMethodSlugSelected !== "money" &&
                        financialAccountTypeSelected === "cash")
                    ) {
                      setValue("paymentMethod", undefined);
                    }
                  }
                }}
                value={financialAccount}
              />
            )}
          />
        </RenderModule>
      ) : null}
    </>
  );
}
