import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";
import InputMask, { Props as InputMaskProps } from "react-input-mask";

import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormLabel,
  FormLabelProps,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: FieldError;
  inputMaskProps?: InputMaskProps;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  formErrorMessageProps?: FormErrorMessageProps;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    error = null,
    isDisabled,
    formControlProps = {},
    formLabelProps = {},
    formErrorMessageProps = {},
    inputMaskProps = {},
    ...rest
  },
  ref
) => {
  return (
    <FormControl
      isInvalid={!!error}
      isDisabled={isDisabled}
      variant="floating"
      {...formControlProps}
    >
      <Box w="100%" position="relative" bg={rest.bg}>
        <ChakraInput
          id={name}
          name={name}
          variant="outline"
          ref={ref}
          placeholder=" "
          as={Object.keys(inputMaskProps).length ? InputMask : undefined}
          {...(inputMaskProps as any)}
          {...rest}
        />
        {!!label && (
          <FormLabel htmlFor={name} {...formLabelProps}>
            {label}
          </FormLabel>
        )}
      </Box>
      {!!error && (
        <FormErrorMessage {...formErrorMessageProps}>
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
