import { useMemo } from "react";

import { SimpleGrid, Text, TextProps } from "@chakra-ui/react";

import { OrderDetailType } from "src/interfaces/order";
import { RecordType } from "src/interfaces/record";
import { formatPrice } from "src/utils/format";

export default function Resume({
  order,
  records,
}: {
  order: OrderDetailType;
  records: RecordType[];
}) {
  const renderPaidValue = useMemo(() => {
    return records.reduce((calc, cur) => {
      if (cur.paidStatus === "paid") {
        return calc + cur.value;
      }
      return calc;
    }, 0);
  }, [records]);

  const CustomText = ({ children, ...props }: TextProps) => {
    return (
      <Text fontWeight={400} lineHeight={1.1} {...props}>
        {children}
      </Text>
    );
  };

  return (
    <>
      <SimpleGrid columns={2} w="100%" spacingY={0.5}>
        <CustomText fontSize="10px" color="black">
          Subtotal
        </CustomText>
        <CustomText fontSize="10px" color="black" textAlign="right">
          {formatPrice(order.grossAmount)}
        </CustomText>

        <CustomText fontSize="10px" color="black">
          Desconto
        </CustomText>
        <CustomText fontSize="10px" color="black" textAlign="right">
          {formatPrice(order.grossAmount - order.totalAmount)}
        </CustomText>

        <CustomText fontSize="10px" color="black">
          Total
        </CustomText>
        <CustomText fontSize="10px" color="black" textAlign="right">
          {formatPrice(order.totalAmount)}
        </CustomText>

        <CustomText fontSize="10px" color="black">
          Já pago
        </CustomText>
        <CustomText fontSize="10px" color="black" textAlign="right">
          {formatPrice(renderPaidValue)}
        </CustomText>
      </SimpleGrid>
    </>
  );
}
