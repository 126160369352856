import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface ChangePasswordType {
  oldPassword: string;
  newPassword: string;
}

export const changePassword = (params: ChangePasswordType) =>
  api.post(`/v1/user/update-password`, params).then(res => res.data);

export function useChangePassword(
  config?: UseMutationOptions<any, any, ChangePasswordType, unknown>
) {
  return useMutation<any, any, ChangePasswordType>(
    (data: ChangePasswordType) => changePassword(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao alterar senha"
        );
      },
      ...config,
    }
  );
}
