import PATHS from "src/constants/paths";
import ReportsPage from "src/pages/Reports";
import CashFlowReportPage from "src/pages/Reports/CashFlow";
import ClientsReportPage from "src/pages/Reports/Clients";
import DrePage from "src/pages/Reports/Dre";
import InventoryPage from "src/pages/Reports/Inventory";
import LateClientsPage from "src/pages/Reports/LateClients";
import PredictedAndAccomplishedPage from "src/pages/Reports/PredictedAndAccomplished";
import ProductsReportPage from "src/pages/Reports/Products";
import ProvidersReportPage from "src/pages/Reports/Providers";
import RecordsByClientPage from "src/pages/Reports/RecordsByClient";
import SalesReportPage from "src/pages/Reports/Sales";
import SalesByClientPage from "src/pages/Reports/SalesByClient";
import SalesByPeriodPage from "src/pages/Reports/SalesByPeriod";
import SalesComissionPage from "src/pages/Reports/SalesComission";
import ServicesReportPage from "src/pages/Reports/Services";

import { RouteObjectProps } from "..";

export const reportRoutes: RouteObjectProps[] = [
  {
    path: PATHS.REPORTS,
    element: <ReportsPage />,
  },
  {
    path: PATHS.REPORTS_DRE,
    element: <DrePage />,
    permissions: [
      {
        subject: "reportFinancialDre",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_PREDICTED_AND_ACCOMPLISHED,
    element: <PredictedAndAccomplishedPage />,
    permissions: [
      {
        subject: "reportFinancialPredictedAndAccomplished",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_PRODUCTS,
    element: <ProductsReportPage />,
    permissions: [
      {
        subject: "reportOrderProducts",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_SERVICES,
    element: <ServicesReportPage />,
    permissions: [
      {
        subject: "reportOrderServices",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_SALES,
    element: <SalesReportPage />,
    permissions: [
      {
        subject: "reportPaymentMethods",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_SALES_BY_CLIENT,
    element: <SalesByClientPage />,
    permissions: [
      {
        subject: "reportSalesByClient",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_LATE_CLIENTS,
    element: <LateClientsPage />,
    permissions: [
      {
        subject: "reportLateClients",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_RECORDS,
    element: <RecordsByClientPage />,
    permissions: [
      {
        subject: "reportFinancialRecords",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_CASHFLOW,
    element: <CashFlowReportPage />,
    permissions: [
      {
        subject: "reportCashFlowControl",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_CLIENTS,
    element: <ClientsReportPage />,
    permissions: [
      {
        subject: "reportClients",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_PROVIDERS,
    element: <ProvidersReportPage />,
    permissions: [
      {
        subject: "reportProviders",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_SALES_COMISSION,
    element: <SalesComissionPage />,
    permissions: [
      {
        subject: "reportOrderComission",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_SALES_BY_PERIOD,
    element: <SalesByPeriodPage />,
    permissions: [
      {
        subject: "reportSalesByPeriod",
        action: "view",
      },
    ],
  },
  {
    path: PATHS.REPORTS_INVENTORY,
    element: <InventoryPage />,
    permissions: [
      {
        subject: "reportInventory",
        action: "view",
      },
    ],
  },
];
