import { Controller, useFormContext, useWatch } from "react-hook-form";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";

import { DatePicker } from "src/components/NewComponents/DatePicker";
import { Input } from "src/components/NewComponents/Input";
import { Select } from "src/components/NewComponents/Select";
import { Textarea } from "src/components/NewComponents/TextArea";
import { CYCLES } from "src/constants/recurrenceCycles";
import { RecordType, RecurrenceCycleType } from "src/interfaces/record";

import { RecordFormSchema } from "../..";

const RECURRENCE_CYCLE_ITEMS = Object.keys(CYCLES).map(cycle => ({
  label: CYCLES[cycle as RecurrenceCycleType],
  value: cycle,
}));

interface FormRecordAdvancedProps {
  record?: RecordType;
}

export function FormRecordAdvanced({ record }: FormRecordAdvancedProps) {
  const {
    control,
    formState: { errors },
    setValue,
    register,
  } = useFormContext<RecordFormSchema>();

  const recurrenceType = useWatch({ name: "recurrenceType", control });
  const paidStatus = useWatch({ name: "paidStatus", control });

  return (
    <>
      <Accordion
        defaultIndex={
          !!record && Boolean(record?.recurrenceType) ? [0] : undefined
        }
        allowToggle
      >
        <AccordionItem border="none">
          <AccordionButton p={0} _hover={{ bg: "transparent" }}>
            <Text as="span" fontSize="sm" flex="1" textAlign="left">
              Avançado
            </Text>
            <AccordionIcon color="primary.500" />
          </AccordionButton>
          <AccordionPanel px={0} display="flex" flexDirection="column" gap={4}>
            <Text lineHeight={1} fontSize="xs" color="gray.500">
              Repetir lançamento
            </Text>
            <Flex gap={2}>
              <Button
                variant={recurrenceType === "fixed" ? "solid" : "outline"}
                size="sm"
                onClick={() => {
                  setValue(
                    "recurrenceType",
                    recurrenceType === "fixed" ? "" : "fixed"
                  );
                }}
                isDisabled={!!record}
              >
                Fixa
              </Button>
              <Button
                variant={
                  recurrenceType === "installments" ? "solid" : "outline"
                }
                size="sm"
                onClick={() => {
                  setValue(
                    "recurrenceType",
                    recurrenceType === "installments" ? "" : "installments"
                  );
                }}
                isDisabled={!!record}
              >
                Parcelado
              </Button>
            </Flex>
            {recurrenceType === "installments" ? (
              <Input
                label="Quantidade de parcelas"
                error={errors.parcels}
                {...register("parcels", {
                  onChange: e => {
                    e.target.value = e.target.value.replace(/[^0-9]+/gi, "");

                    if (e.target.value && !Number(e.target.value)) {
                      e.target.value = "";
                    }
                  },
                })}
                isDisabled={!!record}
              />
            ) : null}
            {recurrenceType ? (
              <Controller
                control={control}
                name="recurrenceCycle"
                render={({ field: { ref, ...props } }) => (
                  <Select
                    options={RECURRENCE_CYCLE_ITEMS}
                    inputRef={ref}
                    error={!!errors.recurrenceCycle}
                    isClearable={false}
                    isDisabled={!!record}
                    placeholder="Frequencia do parcelamento"
                    {...props}
                  />
                )}
              />
            ) : null}

            <DatePicker
              label="Data de emissão"
              name="emissionDate"
              error={errors.emissionDate}
              control={control}
              isDisabled={!!record && paidStatus === "paid"}
            />

            <Textarea
              label="Observação"
              error={errors.note}
              {...register("note")}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}
