import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";

import Panel from "src/components/Layouts/Panel";

import { SectionCardProps } from "./props";
import * as Styled from "./styles";

const SectionCard: FC<SectionCardProps> = props => {
  const { title, loading, dividerHeader = true, rightHeader, children } = props;

  return (
    <Panel padding={3}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Styled.Title>{title}</Styled.Title>
          {loading && <CircularProgress size={20} />}
        </Box>
        {rightHeader}
      </Box>
      {dividerHeader && (
        <Box mb={2}>
          <Divider />
        </Box>
      )}
      {children}
    </Panel>
  );
};

export default SectionCard;
