import React, { useMemo, useState } from "react";

import { Box, Text } from "@chakra-ui/react";

import { Select } from "src/components/NewComponents/Select";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";
import { ProductType } from "src/interfaces/product";
import { ServiceType } from "src/interfaces/service";
import { useProducts } from "src/services/hooks/product/useProducts";
import { useServices } from "src/services/hooks/service/useServices";
import { useDebounce } from "src/utils/debounce";
import { groupProductsAndServices } from "src/utils/order";

interface ProdructOrServiceSearchProps {
  setItem: (
    item: ProductType | ServiceType,
    type: "product" | "service"
  ) => void;
}

export function ProdructOrServiceSearch({
  setItem,
}: ProdructOrServiceSearchProps) {
  const { type } = useOrderForm();

  const [search, setSearch] = useState("");

  const searchValue = useDebounce<string>(search);

  const {
    data: { products },
    isLoading: isLoadingProducts,
    isFetching: isFetchingProducts,
  } = useProducts({
    page: 1,
    limit: 10,
    name: searchValue,
  });

  const {
    data: { services },
    isLoading: isLoadingServices,
    isFetching: isFetchingServices,
  } = useServices(
    {
      page: 1,
      limit: 10,
      name: searchValue,
    },
    {
      enabled: type === "serviceOrder",
    }
  );

  const placeholder = useMemo(() => {
    if (type === "serviceOrder") {
      return "Procurar produto ou serviço...";
    }
    return "Procurar produto...";
  }, [type]);

  const productsAndServices = useMemo(() => {
    if (type === "serviceOrder") {
      return groupProductsAndServices(products, services);
    }
    return groupProductsAndServices(products, []);
  }, [products, services]);

  const filterOptions = (
    option: { label: string; value: string; data: any },
    input: string
  ) => {
    const barCode = option.data?.barCode;
    if (input) {
      return (
        option.label?.toLowerCase()?.includes(input?.toLowerCase()) ||
        barCode?.includes(input?.toLowerCase())
      );
    }

    return true;
  };

  return (
    <Box w="100%">
      <Box w="100%">
        <Select
          autoFocus
          options={productsAndServices}
          isLoading={
            isLoadingProducts ||
            isFetchingProducts ||
            isLoadingServices ||
            isFetchingServices
          }
          filterOption={filterOptions}
          onInputChange={inputValue => {
            setSearch(inputValue);
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onMenuOpen={() => {}}
          onChange={newInputValue => {
            const service = services.find(
              service => service.id === newInputValue
            );

            if (service) {
              setItem(service, "service");
              return;
            }

            const product = products.find(
              product => product.id === newInputValue
            );

            if (product) {
              setItem(product, "product");
            }
          }}
          placeholder={placeholder}
          showFloatingLabel={false}
        />
      </Box>
      <Text mt={1} color="primary.500" fontSize="xs" fontWeight={700}>
        Comece a digitar ou use o leitor de códigos de barras
      </Text>
    </Box>
  );
}
