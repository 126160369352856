/* eslint-disable no-unused-expressions */
import { useState, useMemo } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";

import { Button, Flex, Text } from "@chakra-ui/react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  parse,
  startOfWeek,
  getDay,
  format,
  startOfMonth,
  endOfMonth,
  endOfWeek,
} from "date-fns";
import pt from "date-fns/locale/pt-BR";

import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import { RecordProvider, useRecord } from "src/hooks/record/useRecord";
import { SchedulingCreateProvider } from "src/hooks/scheduling/create/useSchedulingCreate";
import { SchedulingDeleteProvider } from "src/hooks/scheduling/delete/useSchedulingDelete";
import { SchedulingDoneProvider } from "src/hooks/scheduling/done/useSchedulingDone";
import { SchedulingListProvider } from "src/hooks/scheduling/list/useSchedulingList";
import { RecordType } from "src/interfaces/record";
import { useSchedulesAllTypes } from "src/services/hooks/schedule/useSchedulesAllTypes";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { useDebounce } from "src/utils/debounce";
import { withContext } from "src/utils/withContext";

import { Event } from "../../interfaces/events";
import DateHeader from "./components/DateHeader";
import ChargebackRecordModal from "./components/Modals/ChargebackRecordModal";
import DrawerAddCompromisse from "./components/Modals/DrawerAddCompromisse";
import DrawerDetailCompromisse from "./components/Modals/DrawerDetailCompromisse";
import DrawerDetailDay from "./components/Modals/DrawerDetailDay";
import PayRecordModal from "./components/Modals/PayRecordModal";
import RecordFormModal, {
  RecordFormOriginEnum,
} from "./components/Modals/RecordFormModal";
import MonthEvent from "./components/MonthEvent";
import MonthHeader from "./components/MonthHeader";
import Toobar from "./components/Toobar";
import WeekEvent from "./components/WeekEvent";
import WeekHeader from "./components/WeekHeader";

const locales = {
  "pt-BR": pt,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function SchedulePage() {
  const { handleOpen } = useBaseSwipeableDrawer();

  const [rangeDate, setRageDate] = useState({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  });

  const rangeDateDebounced = useDebounce(rangeDate, 750);

  const {
    data: { buyOrder: buyOrders, orders, record: records, schedules },
  } = useSchedulesAllTypes({
    startDate: formatStartDateISO(rangeDateDebounced.start),
    endDate: formatEndDateISO(rangeDateDebounced.end),
  });

  const { setRecord, handleRestartRecordList, record } = useRecord();
  const [selected, setSelected] = useState<Event>();
  const [selectDay, setSelectDay] = useState<Date>(new Date());

  const handleSelected = (event: Event) => {
    if (event.type === "record") {
      setRecord(event.model as RecordType);
      handleOpen("recordForm");
    } else {
      setSelected(event);
      handleOpen("DetailCompromisse");
    }
  };

  function handleDateSelect(date: Date) {
    setSelectDay(date);

    handleOpen("DetailDay");
  }

  const eventsBySchedules = useMemo(() => {
    return schedules.map<Event>(schedule => {
      const startDate = new Date(schedule.startDate);
      const startDay = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      );

      const hours = format(new Date(schedule.startDate), "HH : mm");
      return {
        title: schedule.title,
        description: schedule.description,
        start: startDay,
        end: startDay,
        eventHours: hours,
        place: schedule.place,
        model: schedule,
        type: "scheduling",
      };
    });
  }, [schedules]);

  const eventsByRecord = useMemo(() => {
    return records.map<Event>(record => {
      const startDate = format(new Date(record.paidDate), "yyyy, M, d");

      return {
        title: record.type === "expense" ? "Despesa" : "Receita",
        subTitle:
          record.type === "expense" ? "Conta a pagar" : "Conta a receber",
        createdAt: record.createdAt,
        start: new Date(startDate),
        end: new Date(startDate),
        model: record,
        type: "record",
      };
    });
  }, [records]);

  const eventsByOrder = useMemo(() => {
    return orders.map<Event>(order => {
      return {
        title:
          order.type === "serviceOrder"
            ? `OS Nº ${order.code}`
            : `Pedido Nº ${order.code}`,

        start: new Date(order.deadline),
        end: new Date(order.deadline),
        model: order,
        type: "orders",
      };
    });
  }, [orders]);

  const eventsByBuyOrder = useMemo(() => {
    return buyOrders.map<Event>(buyOrders => {
      return {
        title: `Compra N°${buyOrders.number}`,
        subTitle: buyOrders.status === "open" ? "Aberto" : "Aguardando entrega",
        start: new Date(buyOrders.deadline as string),
        end: new Date(buyOrders.deadline as string),
        model: buyOrders,
        type: "buyOrder",
      };
    });
  }, [buyOrders]);

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [{ label: "Agenda" }];

  return (
    <>
      <DashboardLayout selected="schedule">
        <Breadcrumb data={BREADCRUMB_BUTTONS} />
        <Flex
          h="100%"
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <Flex pt="8" w="100%">
            <Flex w="50%" alignItems="center">
              <Text fontSize="lg" color="primary.500" fontWeight="bold">
                Agenda
              </Text>
            </Flex>
            <Flex w="50%" justifyContent="flex-end">
              <Button
                rightIcon={<AddCircleIcon />}
                onClick={() => {
                  handleOpen("AddCompromisse");
                }}
              >
                Novo Compromisso
              </Button>
            </Flex>
          </Flex>
          <DrawerAddCompromisse />
          {selected ? <DrawerDetailCompromisse eventDetail={selected} /> : ""}

          <Calendar
            localizer={localizer}
            components={{
              toolbar: Toobar,
              month: {
                header: MonthHeader,
                dateHeader: props => (
                  <DateHeader handleDateSelect={handleDateSelect} {...props} />
                ),
                event: MonthEvent,
              },
              week: {
                header: WeekHeader,
                event: WeekEvent,
              },
            }}
            onView={() => {
              const start = startOfMonth(rangeDate.start);
              const end = endOfMonth(rangeDate.start);
              setRageDate({
                start,
                end,
              });
            }}
            onNavigate={(date, view) => {
              if (view === "week") {
                const start = startOfWeek(date);
                const end = endOfWeek(date);
                setRageDate({
                  start,
                  end,
                });
              }
              if (view === "month") {
                const start = startOfMonth(date);
                const end = endOfMonth(date);
                setRageDate({
                  start,
                  end,
                });
              }
            }}
            culture="pt-BR"
            views={["month", "week"]}
            selected={selected}
            onSelectEvent={handleSelected}
            eventPropGetter={() => {
              return {
                style: {
                  backgroundColor: "transparent",
                  color: "#000",
                },
              };
            }}
            popup
            messages={{ showMore: (count: number) => `Ver mais (${count})` }}
            events={[
              ...eventsBySchedules,
              ...eventsByRecord,
              ...eventsByOrder,
              ...eventsByBuyOrder,
            ]}
            defaultView="month"
            startAccessor="start"
            endAccessor="end"
          />
        </Flex>
      </DashboardLayout>
      <DrawerDetailDay
        handleSelected={handleSelected}
        selectDay={selectDay}
        event={[
          ...eventsBySchedules,
          ...eventsByRecord,
          ...eventsByOrder,
          ...eventsByBuyOrder,
        ]}
      />
      <RecordFormModal
        handleCloseRecordFormModal={() => {
          setRecord(undefined);
        }}
        onSuccess={() => {
          handleRestartRecordList();
        }}
        record={record}
        recordFormOrigin={RecordFormOriginEnum.accountStatement}
      />
      <PayRecordModal
        record={record}
        onSuccess={recordResponse => {
          setRecord(recordResponse);
          handleRestartRecordList();
        }}
      />
      <ChargebackRecordModal
        record={record}
        onSuccess={recordResponse => {
          setRecord(recordResponse);
          handleRestartRecordList();
        }}
      />
    </>
  );
}

export default withContext(
  SchedulePage,
  RecordProvider,
  BaseSwipeableDrawerProvider,
  SchedulingCreateProvider,
  SchedulingDeleteProvider,
  SchedulingListProvider,
  SchedulingDoneProvider
);
