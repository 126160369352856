import { useMemo } from "react";

import { SimpleGrid, Text, TextProps } from "@chakra-ui/react";
import { format } from "date-fns";

import { CompanyType } from "src/interfaces/company";
import { OrderDetailType } from "src/interfaces/order";
import {
  formatCEP,
  formatCPF,
  formatCPNJ,
  formatPhone,
} from "src/utils/format";

export default function Company({
  company,
  order,
}: {
  company: CompanyType;
  order: OrderDetailType;
}) {
  const address = useMemo(() => {
    const street = company.street ? `${company.street}, ` : "";
    const number = company.number ? `${company.number}, ` : "";
    const complement = company.complement ? `${company.complement}, ` : "";
    const neighborhood = company.neighborhood
      ? `${company.neighborhood}, `
      : "";
    const city = company.city ? `${company.city}` : "";
    const state = company.uf ? ` - ${company.uf},` : "";
    const cep = company.cep ? ` ${formatCEP(company.cep)}` : "";

    if (
      !street &&
      !number &&
      !complement &&
      !neighborhood &&
      !city &&
      !state &&
      !cep
    )
      return null;

    return `Endereço: ${street}${number}${complement}${neighborhood}${city}${state}${cep}`;
  }, [company]);

  const cpfOrCnpj = useMemo(() => {
    if (company?.type === "PJ" && company?.cnpj?.length) {
      return `CNPJ: ${formatCPNJ(company.cnpj)}`;
    }
    if (company?.cpf?.length) {
      return `CPF: ${formatCPF(company.cpf)}`;
    }

    return null;
  }, [company]);

  const CustomText = ({ children, ...props }: TextProps) => {
    return (
      <Text
        fontWeight={400}
        lineHeight={1.1}
        color="black"
        textAlign="center"
        {...props}
      >
        {children}
      </Text>
    );
  };

  return (
    <>
      <CustomText fontSize="12px">{company.fantasyName}</CustomText>
      {cpfOrCnpj ? <CustomText fontSize="10px">{cpfOrCnpj}</CustomText> : null}
      <CustomText fontSize="10px">
        Telefone: {formatPhone(company.phone)}
      </CustomText>
      <CustomText
        fontSize="10px"
        whiteSpace="pre-wrap"
        mb={3}
        textAlign="center"
      >
        {address}
      </CustomText>

      <SimpleGrid columns={2} w="100%">
        <CustomText fontSize="10px" textAlign="left">
          Emitido em:{" "}
        </CustomText>
        <CustomText fontSize="10px" textAlign="right">
          <b>
            {order?.createdDate
              ? format(new Date(order.createdDate), "dd/MM/yyyy")
              : null}
          </b>
        </CustomText>
      </SimpleGrid>

      {order.deadline ? (
        <SimpleGrid columns={2} w="100%">
          <CustomText fontSize="10px" textAlign="left">
            Entrega prevista:{" "}
          </CustomText>
          <CustomText fontSize="10px" textAlign="right">
            <b>{format(new Date(order.deadline), "dd/MM/yyyy")}</b>
          </CustomText>
        </SimpleGrid>
      ) : null}
    </>
  );
}
