import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SubContactType } from "src/interfaces/contact";
import api from "src/services/api";

export type GetSubContactsResponseType = SubContactType[];

interface SubContactsParams {
  contact: string;
}

export const getSubContacts = (params: SubContactsParams) =>
  api
    .get<GetSubContactsResponseType>("v1/subcontact", {
      params,
    })
    .then(res => res.data);

export function useSubContacts(
  params: SubContactsParams,
  config?: UseQueryOptions<
    GetSubContactsResponseType,
    Error,
    GetSubContactsResponseType,
    unknown[]
  >
) {
  return useQuery<
    GetSubContactsResponseType,
    Error,
    GetSubContactsResponseType,
    unknown[]
  >(["subContacts", params], () => getSubContacts(params), {
    refetchOnWindowFocus: false,
    ...(config || {}),
    initialData: [],
  });
}
