import styled from "styled-components";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AddCircleIconUnstyled from "@material-ui/icons/AddCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Typography from "src/components/Typography";

export const TitleSection = styled(Typography)`
  font-size: 15px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

export const AddCircleIcon = styled(AddCircleIconUnstyled)`
  margin-right: 10px;
`;

export const ExpandIcon = styled(ExpandMoreIcon)`
  transform: rotate(-90deg);
`;

export const Accordion = styled(MuiAccordion)`
  border-radius: 10px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.MuiAccordion-root:before {
    border: 0;
    background-color: transparent;
  }
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
    margin-bottom: 1rem;
  }
  .MuiAccordionSummary-content {
    .Mui-expanded {
      margin: 0;
    }
    margin: 0;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 8px 0;
  min-height: 40px;

  &:hover {
    background-color: rgba(101, 174, 237, 0.1);
  }

  &.Mui-expanded {
    margin-bottom: 8px;
    min-height: 40px;
  }
  .MuiIconButton-root {
    padding: 0;
    margin-right: 0;
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(90deg);
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  width: 100%;
  flex-direction: column;
  &.MuiAccordionDetails-root {
    padding: 0;
  }
`;
