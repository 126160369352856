import { STATUS } from "src/constants/requestStatus";
import { RequestStatusType } from "src/interfaces/request";

export enum StatusRecordActionEnum {
  LIST = "LIST",
  PAY = "PAY",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  CHARGEBACK = "CHARGEBACK",
  TRANSFER = "TRANSFER",
}

interface StatusRecordAction {
  type: StatusRecordActionEnum;
  payload: RequestStatusType;
}

interface StatusRecordState {
  listStatus: RequestStatusType;
  payStatus: RequestStatusType;
  createStatus: RequestStatusType;
  updateStatus: RequestStatusType;
  deleteStatus: RequestStatusType;
  chargebackStatus: RequestStatusType;
  createTransferStatus: RequestStatusType;
}

export function statusRecordReducer(
  state: StatusRecordState,
  action: StatusRecordAction
) {
  const { type, payload } = action;
  switch (type) {
    case StatusRecordActionEnum.LIST:
      return {
        ...state,
        listStatus: payload,
        payStatus: STATUS.inital,
        createStatus: STATUS.inital,
        updateStatus: STATUS.inital,
        deleteStatus: STATUS.inital,
        chargebackStatus: STATUS.inital,
        createTransferStatus: STATUS.inital,
      };
    case StatusRecordActionEnum.PAY:
      return {
        ...state,
        payStatus: payload,
      };
    case StatusRecordActionEnum.CREATE:
      return {
        ...state,
        createStatus: payload,
      };
    case StatusRecordActionEnum.UPDATE:
      return {
        ...state,
        updateStatus: payload,
      };
    case StatusRecordActionEnum.DELETE:
      return {
        ...state,
        deleteStatus: payload,
      };
    case StatusRecordActionEnum.CHARGEBACK:
      return {
        ...state,
        chargebackStatus: payload,
      };
    case StatusRecordActionEnum.TRANSFER:
      return {
        ...state,
        createTransferStatus: payload,
      };

    default:
      return state;
  }
}
