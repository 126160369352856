import React, { createContext, useContext, useState } from "react";

import { STATUS } from "src/constants/requestStatus";
import {
  DashboardInvervalEnum,
  DashboardSaleType,
  FinacialResumeRecordsType,
  FinancialHealthType,
  FinancialResumeType,
} from "src/interfaces/dashboard";
import {
  getDashboardFinancialResume,
  getDashboardFinancialResumeRecords,
  getDashboardSalesService,
  getFinancialHealthData,
} from "src/services/dashboard";

import { DashboardContextType } from "./props";

export const DashboardContext = createContext({} as DashboardContextType);

export const DashboardProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState(STATUS.inital);

  const [financialResume, setFinancialResume] = useState<FinancialResumeType>(
    {} as FinancialResumeType
  );

  const [financialResumeRecords, setFinancialResumeRecords] =
    useState<FinacialResumeRecordsType>([]);

  // @DEPECRATED
  const [financialHealth, setFinancialHealth] = useState<FinancialHealthType>(
    {} as FinancialHealthType
  );

  const [dashboardSales, setDashboardSales] = useState<DashboardSaleType>(
    {} as DashboardSaleType
  );

  const getDashboardSales = async (
    interval: keyof typeof DashboardInvervalEnum
  ) => {
    try {
      setStatus(STATUS.loading);

      const response: DashboardSaleType = await getDashboardSalesService(
        interval
      );

      setDashboardSales(response);

      setStatus(STATUS.success);
    } catch (e) {
      setDashboardSales({} as DashboardSaleType);
      setStatus(STATUS.error);
    }
  };

  const getFinacialResume = async () => {
    try {
      setStatus(STATUS.loading);

      const response: FinancialResumeType = await getDashboardFinancialResume();

      setFinancialResume(response);

      setStatus(STATUS.success);
    } catch (e) {
      setFinancialResume({} as FinancialResumeType);
      setStatus(STATUS.error);
    }
  };

  const getFinancialResumeRecords = async (year: number) => {
    try {
      setStatus(STATUS.loading);

      const response: FinacialResumeRecordsType =
        await getDashboardFinancialResumeRecords({ year });

      setFinancialResumeRecords(response);

      setStatus(STATUS.success);
    } catch (e) {
      setFinancialResumeRecords([]);
      setStatus(STATUS.error);
    }
  };

  // @DEPECRATED
  const getFinancialHealth = async () => {
    try {
      setStatus(STATUS.loading);

      const response: FinancialHealthType = await getFinancialHealthData();

      setFinancialHealth(response);

      setStatus(STATUS.success);
    } catch (e) {
      setFinancialHealth({} as FinancialHealthType);
      setStatus(STATUS.error);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        status,
        financialResume,
        getFinacialResume,
        financialResumeRecords,
        getFinancialResumeRecords,
        financialHealth,
        getFinancialHealth,
        dashboardSales,
        getDashboardSales,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }

  return context;
};
