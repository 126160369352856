import React, { useState, createContext, useContext } from "react";

import { STATUS } from "src/constants/requestStatus";
import { RecordType } from "src/interfaces/record";
import { getRecordsByOrder } from "src/services/order";

import { RecordsOrderContextType } from "./props";

export const RecordsOrderContext = createContext({} as RecordsOrderContextType);

export const RecordsOrderProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState(STATUS.inital);

  const [records, setRecords] = useState<RecordType[]>([]);

  const getOrderRecords = async (id: string) => {
    if (status.loading) return;
    try {
      setStatus(STATUS.loading);
      const response: RecordType[] = await getRecordsByOrder(id);

      setStatus(STATUS.success);
      setRecords(response);
    } catch (e) {
      setStatus(STATUS.error);
      setRecords([]);
    }
  };

  return (
    <RecordsOrderContext.Provider
      value={{
        status,

        records,
        setRecords,
        getOrderRecords,
      }}
    >
      {children}
    </RecordsOrderContext.Provider>
  );
};

export const useRecordsOrder = () => {
  const context = useContext(RecordsOrderContext);

  if (!context) {
    throw new Error(
      "useRecordsOrder must be used within a RecordsOrderProvider"
    );
  }

  return context;
};
