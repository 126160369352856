import { SelectedFilterType } from "src/components/PeriodFilterPicker/props";

import { useStorage } from "../storage/useStorage";

interface PeriodFilterType {
  serviceOrder?: SelectedFilterType;
  productOrder?: SelectedFilterType;
}

export const usePeriodFilterType = () => {
  const { getItem, setItem } = useStorage();

  const getPeriodTypeCache = (
    type: keyof PeriodFilterType
  ): SelectedFilterType => {
    const periodFilterType = getItem<PeriodFilterType>(
      "periodFilterType",
      {}
    ) as PeriodFilterType;
    const periodFilterTypeValue = periodFilterType[type];
    if (periodFilterTypeValue) {
      return periodFilterTypeValue;
    }
    return "empty";
  };

  const setPeriodTypeCache = (
    type: keyof PeriodFilterType,
    selectedFilterType: SelectedFilterType
  ): void => {
    const periodFilterType = getItem<PeriodFilterType>(
      "periodFilterType",
      {}
    ) as PeriodFilterType;
    periodFilterType[type] = selectedFilterType;
    setItem("periodFilterType", periodFilterType);
  };

  return { getPeriodTypeCache, setPeriodTypeCache };
};
