import React, { useCallback } from "react";

import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";

import TextField from "src/components/Inputs/TextField";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import { debounce } from "src/utils/debounce";

import * as Styled from "./styles";

const BuyOrderOptionsPanel: React.FC = () => {
  const {
    setSearch,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    initialFilterType,
    setInitialFilterType,
  } = useBuyOrderFilters();

  const searchByProvider = useCallback(
    debounce((query: string) => {
      setSearch(query);
    }, 1000),
    []
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    searchByProvider(e.target.value);
  };

  return (
    <Styled.Container>
      <Grid container spacing={2} justifyContent="space-between">
        {/* <Grid item xs={12} md={gridSearchField}> */}
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            label="Pesquisar por número ou fornecedor"
            name="name"
            disableFormik
            onChange={onChange}
            backgroundwhite
            icon={<SearchIcon color="primary" />}
          />
        </Grid>

        <Grid item xs={12} md={3} lg={2}>
          <PeriodFilterPicker
            startDate={startDate || new Date()}
            endDate={endDate || new Date()}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            initialFilter={initialFilterType}
            setSelectedFilterType={setInitialFilterType}
          />
        </Grid>
      </Grid>
    </Styled.Container>
  );
};

export default BuyOrderOptionsPanel;
