import { Navigate, RouteObject } from "react-router-dom";

import PATHS from "src/constants/paths";
import ProducctOrderAddPage from "src/pages/Orders/Add/Product";
import ProductOrderDetailPage from "src/pages/Orders/Detail/Product";
import ProductOrderListPage from "src/pages/Orders/List/Product";
import ProductOrderUpdatePage from "src/pages/Orders/Update/Product";

export const productOrderRoutes: RouteObject[] = [
  {
    path: PATHS.PRODUCT_ORDER_LIST,
    element: <ProductOrderListPage />,
    children: [
      {
        path: PATHS.PRODUCT_ORDER_ADD,
        element: <ProducctOrderAddPage />,
      },
      {
        path: PATHS.PRODUCT_ORDER_DETAIL,
        element: <ProductOrderDetailPage />,
      },
      {
        path: PATHS.PRODUCT_ORDER_UPDATE,
        element: <ProductOrderUpdatePage />,
      },
      {
        path: "*",
        element: <Navigate to={PATHS.PRODUCT_ORDER_LIST} replace />,
      },
    ],
  },
];
