import { useEffect, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";

import { Select } from "src/components/NewComponents/Select";
import RenderModule from "src/components/RenderModule";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { Option } from "src/interfaces/common";
import { useCashFlowOpened } from "src/services/hooks/cashFlow/useCashFlowOpened";
import { useActiveFinancialAccounts } from "src/services/hooks/financialAccount/useActiveFinancialAccounts";
import { groupFinancialAccounts } from "src/utils/financialAccount";

import { OrderPaymentSchema } from "../..";

export function PaymentReceiveSection() {
  const { userCompany } = useCompany();
  const { hasModules, cashFlowControlModule } = useModule();

  const restrictMoneyPayment =
    cashFlowControlModule?.settings?.restrictMoneyPayment ?? false;

  const { data: accounts } = useActiveFinancialAccounts(undefined, {
    enabled: hasModules("accountManagement"),
  });

  const { data: cashFlowOpened } = useCashFlowOpened({
    enabled: hasModules("cashflowcontrol"),
    retry: 1,
    retryDelay: 500,
  });

  const {
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useFormContext<OrderPaymentSchema>();

  const getInitialFinancialAccount = () => {
    if (cashFlowOpened) {
      return cashFlowOpened?.financialAccount as unknown as string;
    }
    if (accounts.length) {
      return accounts.find(
        account => account.type !== "cash" && account.isDefault
      )?.id;
    }
    return undefined;
  };

  const getInitialPaymentMethod = () => {
    if (cashFlowOpened) {
      return userCompany?.paymentMethods
        ?.filter(paymentMethod => paymentMethod.status === "enabled")
        .find(paymentMethod => paymentMethod.slug === "money")?.id;
    }

    return undefined;
  };

  useEffect(() => {
    reset({
      ...getValues(),
      financialAccount: getInitialFinancialAccount(),
      paymentMethod: getInitialPaymentMethod(),
    });
  }, [cashFlowOpened, accounts]);

  const paymentMethodsItems = useMemo(() => {
    return (
      userCompany?.paymentMethods
        ?.filter(item => item.status === "enabled")
        .map(method => ({
          label: method.title,
          value: method.id,
        })) ?? []
    );
  }, [userCompany]);

  const financialAccountsGrouped = useMemo(() => {
    return groupFinancialAccounts(
      accounts.filter(account => {
        if (cashFlowOpened) {
          return (
            account.type !== "cash" ||
            account.id ===
              (cashFlowOpened?.financialAccount as unknown as string)
          );
        }
        return true;
      })
    );
  }, [accounts, cashFlowOpened]);

  const paymentMethod = useWatch({ name: "paymentMethod", control });
  const financialAccount = useWatch({ name: "financialAccount", control });

  return (
    <>
      <Text color="text" mb={2.5}>
        Forma de recebimento
      </Text>

      <SimpleGrid columns={3} gap={4} mt={4}>
        <Controller
          control={control}
          name="paymentMethod"
          render={({ field: { ref, onChange } }) => (
            <Select
              options={paymentMethodsItems}
              inputRef={ref}
              error={!!errors.paymentMethod}
              errorMessage={errors.paymentMethod?.message}
              placeholder="Forma de pagamento"
              isClearable={false}
              onChange={paymentMethod => {
                if (hasModules("cashflowcontrol") && restrictMoneyPayment) {
                  const paymentMethodSlugSelected =
                    userCompany.paymentMethods?.find(
                      method => method.id === paymentMethod
                    )?.slug;

                  const financialAccountTypeSelected = accounts.find(
                    account => account.id === financialAccount
                  )?.type;

                  if (
                    (financialAccountTypeSelected === "cash" &&
                      paymentMethodSlugSelected !== "money") ||
                    (financialAccountTypeSelected !== "cash" &&
                      paymentMethodSlugSelected === "money")
                  ) {
                    setValue("financialAccount", undefined);
                  }
                }
                onChange(paymentMethod);
              }}
              value={paymentMethod}
            />
          )}
        />

        <RenderModule modules={["accountManagement"]}>
          <Controller
            control={control}
            name="financialAccount"
            render={({ field: { ref, onChange } }) => (
              <Select
                options={financialAccountsGrouped}
                inputRef={ref}
                error={!!errors.financialAccount}
                errorMessage={errors.financialAccount?.message}
                placeholder="Conta"
                isClearable={false}
                formatOptionLabel={(data: unknown) => {
                  const account = data as Option;
                  return (
                    <Flex alignItems="center" gap={2}>
                      <Image
                        borderRadius="full"
                        boxSize="24px"
                        src={account?.url}
                        alt={account.label as string}
                      />
                      {account.label}
                    </Flex>
                  );
                }}
                onChange={financialAccount => {
                  onChange(financialAccount);
                  if (hasModules("cashflowcontrol") && restrictMoneyPayment) {
                    const paymentMethodSlugSelected =
                      userCompany.paymentMethods?.find(
                        method => method.id === paymentMethod
                      )?.slug;

                    const financialAccountTypeSelected = accounts.find(
                      account => account.id === financialAccount
                    )?.type;

                    if (
                      (paymentMethodSlugSelected === "money" &&
                        financialAccountTypeSelected !== "cash") ||
                      (paymentMethodSlugSelected !== "money" &&
                        financialAccountTypeSelected === "cash")
                    ) {
                      setValue("paymentMethod", undefined);
                    }
                  }
                }}
                value={financialAccount}
              />
            )}
          />
        </RenderModule>
      </SimpleGrid>
    </>
  );
}
