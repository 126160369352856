import React, { useMemo } from "react";

import { Box } from "@chakra-ui/react";

import { Select } from "src/components/NewComponents/Select";
import { useCompany } from "src/hooks/company/useCompany";
import { useOrder } from "src/hooks/order/useOrder";
import { UserType } from "src/interfaces/user";
import { useUsers } from "src/services/hooks/user/useUsers";

const ComissionSection: React.FC = () => {
  const { operator, setOperator } = useOrder();
  const { operatorNameDefinitions } = useCompany();

  const { data: rows, isLoading } = useUsers(
    {
      limit: 10000,
      page: 1,
      status: "enabled",
    },
    { staleTime: 0 }
  );

  const users = useMemo(() => {
    return rows?.users ?? [];
  }, [rows]);

  return (
    <Box
      ml={4}
      w="100%"
      sx={{
        ".azulou_select__control": {
          minHeight: "56px !important",
        },
      }}
    >
      <Select
        value={operator?.id}
        options={users.map(user => ({
          label: user.name,
          value: user.id,
        }))}
        isLoading={isLoading}
        onChange={newInputValue => {
          const user = users.find(user => user.id === newInputValue);
          setOperator(user as UserType);
        }}
        placeholder={`Adicionar ${operatorNameDefinitions.title.toLowerCase()}...`}
        showFloatingLabel={false}
      />
    </Box>
  );
};

export default ComissionSection;
