import React, { FC, useState } from "react";
import { toast } from "react-toastify";

import { BoxProps } from "@material-ui/core";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { usePopupState, bindToggle } from "material-ui-popup-state/hooks";

import { COLOR_STATUS } from "src/constants/orders";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";
import { useStatusOrder } from "src/hooks/order/useStatusOrder";
import { OrderListType } from "src/interfaces/order";
import StatusesPopover from "src/pages/Orders/Detail/components/StatusesPopover";

import * as Styled from "./styles";

interface RenderOrderStatusProps extends BoxProps {
  order: OrderListType;
}

const RenderOrderStatus: FC<RenderOrderStatusProps> = props => {
  const { order, ...rest } = props;
  const { updateStatus: update } = useStatusOrder();
  const [loading, setLoading] = useState(false);
  const { setRestartOrderList } = useOrderFilters();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "update-status-order",
  });

  const updateStatus = async (orderId: string, status: string) => {
    try {
      popupState.close();
      setLoading(true);
      await update(orderId, status);
      setRestartOrderList(true);
    } catch (error) {
      toast.error("Falha ao alterar status, tente novamente");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StatusesPopover
        orderId={order?.id}
        updateStatus={updateStatus}
        popupState={popupState}
      />
      <Styled.StatusTag
        {...bindToggle(popupState)}
        color={COLOR_STATUS[order?.status?.type]}
        height={30}
        {...rest}
      >
        {loading ? (
          <Styled.LoadingContainer>
            <Styled.Loading size={20} />
          </Styled.LoadingContainer>
        ) : (
          <>
            {order?.status?.name}
            <KeyboardArrowDownRoundedIcon color="inherit" />
          </>
        )}
      </Styled.StatusTag>
    </>
  );
};

export default RenderOrderStatus;
