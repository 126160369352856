import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import api from "src/services/api";

interface SalesComissionReportParams {
  startDate: string;
  endDate: string;
  operatorId?: string;
}

export interface SalesComissionOperator {
  count: number;
  totalAmount: number;
  totalComission: number;
  name: string;
}

interface GetSalesComissionReportResponse {
  result: Array<{
    period: string;
    operators: Array<SalesComissionOperator>;
  }>;
  totals: {
    totalAmount: number;
    count: number;
  };
}

export const salesComissionReport = (params: SalesComissionReportParams) =>
  api
    .get<GetSalesComissionReportResponse>("/v1/report/sales-comission", {
      params,
    })
    .then(res => res.data);

export function useSalesComissionReport(
  params: SalesComissionReportParams,
  config?: UseQueryOptions<
    GetSalesComissionReportResponse,
    Error,
    GetSalesComissionReportResponse,
    unknown[]
  >
) {
  return useQuery<
    GetSalesComissionReportResponse,
    Error,
    GetSalesComissionReportResponse,
    unknown[]
  >(["salesComissionReport", params], () => salesComissionReport(params), {
    refetchOnWindowFocus: false,
    ...(config || {}),
    initialData: {
      result: [],
      totals: {
        totalAmount: 0,
        count: 0,
      },
    } as GetSalesComissionReportResponse,
  });
}
