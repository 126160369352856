import styled from "styled-components";

import TextField from "@material-ui/core/TextField";

export const SearchField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  .MuiIconButton-label {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Logo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 4px;
`;
