import React, { FC } from "react";

import { Link } from "@material-ui/core";

import appStoreImage from "src/assets/pictures/app-store.png";
import mockupImage from "src/assets/pictures/mockup.png";
import playStoreImage from "src/assets/pictures/play-store.png";

import * as Styled from "./styles";

const SectionMockup: FC = () => {
  return (
    <Styled.MockupWrapper>
      <Styled.Mockup src={mockupImage} />
      <Styled.LinksGroup>
        <Link
          target="_blank"
          href="https://apps.apple.com/us/app/azulou/id1584214688"
        >
          <Styled.LinkImage
            src={appStoreImage}
            alt="Logo referente a acesso ao Azulou app na app-store"
          />
        </Link>
        <Link
          target="_blank"
          href="https://play.google.com/store/apps/details?id=br.com.azulou.app"
        >
          <Styled.LinkImage
            src={playStoreImage}
            alt="Logo referente a acesso ao Azulou app na play-store"
          />
        </Link>
      </Styled.LinksGroup>
    </Styled.MockupWrapper>
  );
};

export default SectionMockup;
