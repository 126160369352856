import React, { useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  add,
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  sub,
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import Typography from "src/components/Typography";

import PeriodFilterContent from "./components/PeriodFilterContent";
import PeriodFilterOptions from "./components/PeriodFilterOptions";
import { PeriodFilterPickerProps, SelectedFilterType } from "./props";
import * as Styled from "./styles";

const PeriodFilterPicker: React.FC<PeriodFilterPickerProps> = props => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    initialFilter = "empty",
    initialFilterForType = "empty",
    options = [
      "empty",
      "byDay",
      "byMonth",
      "perInterval",
      "byUpdate",
      "byCreated",
    ],
    setFilterFieldDate,
    showingInPopover = true,
  } = props;

  // Data especifica
  const [selectedDay, setSelectedDay] = useState(new Date());

  // Mês
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  // Intervalo
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const [selectedFilter, setSelectedFilter] = useState(initialFilter);
  const [selectedFilterForType, setSelectedFilterForType] =
    useState(initialFilterForType);

  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    setSelectedFilter(initialFilter);
  }, [initialFilter]);

  useEffect(() => {
    setSelectedFilterForType(initialFilterForType);
  }, [initialFilterForType]);

  const calcDay = (date: Date, operator?: "add" | "sub") => {
    if (operator === "add") {
      return add(date, { days: 1 });
    }

    if (operator === "sub") {
      return sub(date, { days: 1 });
    }
    return date;
  };

  const handleDayChange = (date: Date | null, operator?: "add" | "sub") => {
    if (date) {
      const calculatedDay = calcDay(date, operator);
      setSelectedDay(calculatedDay);
      setSelectedStartDate(startOfDay(calculatedDay));
      setSelectedEndDate(endOfDay(calculatedDay));
      if (!showingInPopover) {
        setStartDate(calculatedDay);
        setEndDate(calculatedDay);
      }
    }
  };

  const handleMonthChange = (date: Date | null) => {
    if (date) {
      const startDate = startOfMonth(date);
      const endDate = endOfMonth(date);
      setSelectedMonth(date);
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
      if (!showingInPopover) {
        setStartDate(startDate);
        setEndDate(endDate);
      }
    }
  };

  const handleStartDate = (date: Date | null, operator?: "add" | "sub") => {
    if (date) {
      const startDate = calcDay(date, operator);
      setSelectedStartDate(startDate);
      if (!showingInPopover) {
        setStartDate(startDate);
      }
    }
  };

  const handleEndDate = (date: Date | null, operator?: "add" | "sub") => {
    if (date) {
      const endDate = calcDay(date, operator);
      setSelectedEndDate(endDate);
      if (!showingInPopover) {
        setEndDate(endDate);
      }
    }
  };

  const applyFilter = () => {
    if (selectedFilter === "empty") {
      setStartDate(undefined);
      setEndDate(undefined);
    } else {
      setStartDate(selectedStartDate);
      setEndDate(selectedEndDate);
    }

    if (selectedFilterForType === "byUpdate") {
      setFilterFieldDate("updatedAt");
    } else if (selectedFilterForType === "empty") {
      setFilterFieldDate("createdDate");
    }
  };

  const renderTypeSelectedFilter = () => {
    if (selectedFilter === "byDay") {
      return (
        <Text fontSize="xs" color="white" textAlign="center">
          {format(selectedDay, "dd/MM/yyyy")}
        </Text>
      );
    }
    if (selectedFilter === "byMonth") {
      return (
        <Text fontSize="xs" color="white" textAlign="center">
          {format(selectedMonth, "MMMM", { locale: ptBR })}
        </Text>
      );
    }
    if (selectedFilter === "perInterval") {
      return (
        <Text fontSize="xs" color="white" textAlign="center">
          De: {format(startDate, "dd/MM/yyyy")} até:{" "}
          {format(endDate, "dd/MM/yyyy")}
        </Text>
      );
    }
    return null;
  };

  const renderClearSelectedFilterButton = useMemo(() => {
    if (selectedFilter !== "empty" && options.includes("empty")) {
      return (
        <Button
          rounded="50px"
          py={2}
          px={3}
          onClick={() => {
            setSelectedFilter("empty");
            setStartDate(undefined);
            setEndDate(undefined);
          }}
          textAlign="center"
          gap={2}
        >
          {renderTypeSelectedFilter()}
          <Styled.CloseButton />
        </Button>
      );
    }

    return null;
  }, [selectedFilter, options]);

  const renderSelectedFilterForTypeButton = useMemo(() => {
    if (selectedFilterForType !== "empty" && options.includes("empty")) {
      return (
        <Button
          rounded="50px"
          py={2}
          px={3}
          onClick={() => [
            setSelectedFilterForType("empty"),
            setFilterFieldDate("createdDate"),
            setStartDate(undefined),
            setEndDate(undefined),
          ]}
          textAlign="center"
          gap={2}
        >
          <Text fontSize="xs" color="white" textAlign="center">
            {selectedFilterForType === "byUpdate"
              ? "últimos modificados"
              : "  últimos criados"}
          </Text>
          <Styled.CloseButton />
        </Button>
      );
    }

    return null;
  }, [selectedFilterForType, options]);

  const handleSetSelectedFilter = (selectedFilter: SelectedFilterType) => {
    setSelectedFilter(selectedFilter);

    if (selectedFilter === "byDay") {
      handleDayChange(selectedDay);
    } else if (selectedFilter === "byMonth") {
      handleMonthChange(selectedMonth);
    } else if (selectedFilter === "perInterval") {
      handleStartDate(selectedStartDate);
      handleEndDate(selectedEndDate);
    }
  };

  return showingInPopover ? (
    <>
      <Box
        display="flex"
        mt={4}
        flexDirection="row"
        gap={4}
        alignItems="center"
      >
        <Popover
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
          placement="right"
          arrowSize={0}
        >
          <PopoverTrigger>
            <Button rounded="50px" py={2} px={5} display="flex" gap={2}>
              Filtros
            </Button>
          </PopoverTrigger>
          <PopoverContent width="100%" padding={0.5} boxShadow="2xl">
            <PopoverHeader>
              <Typography color="primary" variant="h6">
                Filtros
              </Typography>
            </PopoverHeader>
            <PopoverCloseButton size="md" color="primary.500" />
            <PopoverBody maxH="300px" overflow="auto">
              <Box width="100%" padding={2}>
                {options.length > 1 ? (
                  <PeriodFilterOptions
                    options={options}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={handleSetSelectedFilter}
                    showingInPopover={showingInPopover}
                    setFilterFieldDate={setFilterFieldDate}
                    selectedFilterForType={selectedFilterForType}
                    setSelectedFilterForType={setSelectedFilterForType}
                    setSelectedFilterType={setFilterFieldDate}
                  />
                ) : null}

                <PeriodFilterContent
                  showingInPopover={showingInPopover}
                  handleDayChange={handleDayChange}
                  handleEndDate={handleEndDate}
                  handleMonthChange={handleMonthChange}
                  handleStartDate={handleStartDate}
                  selectedDay={selectedDay}
                  selectedEndDate={selectedEndDate}
                  selectedFilter={selectedFilter}
                  selectedMonth={selectedMonth}
                  selectedStartDate={selectedStartDate}
                />
              </Box>
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent="flex-end">
              <Button w="full" onClick={() => [applyFilter(), onClose()]}>
                Aplicar
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
        {renderClearSelectedFilterButton}
        {renderSelectedFilterForTypeButton}
      </Box>
    </>
  ) : (
    <>
      <PeriodFilterOptions
        options={options}
        selectedFilter={selectedFilter}
        setSelectedFilter={handleSetSelectedFilter}
        showingInPopover={showingInPopover}
        setFilterFieldDate={setFilterFieldDate}
        selectedFilterForType={selectedFilterForType}
        setSelectedFilterForType={setSelectedFilterForType}
        setSelectedFilterType={setFilterFieldDate}
      />
      <PeriodFilterContent
        showingInPopover={showingInPopover}
        handleDayChange={handleDayChange}
        handleEndDate={handleEndDate}
        handleMonthChange={handleMonthChange}
        handleStartDate={handleStartDate}
        selectedDay={selectedDay}
        selectedEndDate={selectedEndDate}
        selectedFilter={selectedFilter}
        selectedMonth={selectedMonth}
        selectedStartDate={selectedStartDate}
      />
    </>
  );
};

export default PeriodFilterPicker;
