import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { FinancialAccountType } from "src/interfaces/financialAccount";
import api from "src/services/api";

export interface CreateTransferParams {
  title: string;
  value: number;
  date: string;
  fromAccount: string;
  toAccount: string;
  description?: string;
}

export const createTransfer = (params: CreateTransferParams) =>
  api.post<FinancialAccountType>("v1/transfer", params).then(res => res);

export function useCreateTransfer(
  config?: UseMutationOptions<any, any, CreateTransferParams, unknown>
) {
  return useMutation<any, any, CreateTransferParams>(
    (data: CreateTransferParams) => createTransfer(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ??
            "Ocorreu um problema ao fazer transferência"
        );
      },
      ...config,
    }
  );
}
