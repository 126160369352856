import { ReactNode } from "react";

import { Box, useRadio, UseRadioProps } from "@chakra-ui/react";

interface TypeConditionButtonProps extends UseRadioProps {
  children: ReactNode;
}

export function TypeConditionButton({
  children,
  ...radioProps
}: TypeConditionButtonProps) {
  const { getInputProps, getCheckboxProps, state } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" h="48px" w="auto" cursor="pointer">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius={5}
        px={5}
        py={2}
        fontSize="md"
        fontWeight={state.isChecked ? 600 : 400}
        color={state.isChecked ? "white" : "gray.400"}
        borderWidth="1px"
        borderColor="primary.500"
        bg={state.isChecked ? "primary.500" : "white"}
        _hover={{ bg: state.isChecked ? "primary.600" : "primary.50" }}
        _focus={{ bg: state.isChecked ? "shadows.outline" : "white" }}
        _active={{ bg: state.isChecked ? "primary.700" : "primary.100" }}
      >
        {children}
      </Box>
    </Box>
  );
}
