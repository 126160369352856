import styled from "styled-components";

import { Box } from "@material-ui/core";

import Typography from "src/components/Typography";

export const Container = styled(Box)`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(139, 149, 164, 0.24);
  padding: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Article = styled(Box)`
  flex: 1;
  margin-right: 0.5rem;
`;

export const Title = styled(Typography)`
  font-size: 1rem;
  font-weight: bold;
  color: #667384;
  margin-bottom: 0.75rem;
`;

export const SubTitle = styled(Typography)`
  font-size: 0.75rem;
  color: #2a2f36;
`;
