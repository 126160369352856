import pkg from "../../package.json";

export const logVersion = () => {
  if (process.env.NODE_ENV !== "development") {
    // eslint-disable-next-line no-console
    console.log(
      `%c Azulou - versão ${pkg.version} `,
      "color: #074EE8; background-color: #fff; font-weight: bold; font-size: 18px"
    );
  }
};
