import React, { useMemo, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";

import Typography from "src/components/Typography";
import { useCompany } from "src/hooks/company/useCompany";
import { useOrder } from "src/hooks/order/useOrder";
import { useViewOrder } from "src/hooks/order/useViewOrder";
import { PaymentTermsType } from "src/interfaces/company";
import { OrderType } from "src/interfaces/order";

import { ConditionPaymentSectionProps } from "./props";
import * as Styled from "./styles";

export const PAYMENT_CONDITION_ITEMS = [
  { name: "A vista", value: "A Vista" },
  { name: "50% / 50%", value: "50%/50%" },
  { name: "Com entrada", value: "Com entrada" },
  { name: "Parcelado", value: "Parcelado" },
];

export const DOWN_PAYMENT_ITEMS = [
  { name: "Valor fixo", value: "value" },
  { name: "Porcentagem", value: "percentage" },
];

const ConditionPaymentSection: React.FC<ConditionPaymentSectionProps> =
  props => {
    const { enabled = true } = props;

    const { values, setFieldValue } = useFormikContext<Partial<OrderType>>();
    const { total } = useViewOrder();
    const { order, method } = useOrder();
    const { userCompany } = useCompany();

    useEffect(() => {
      if (method === "update") {
        handleSetPaymentTypeAndResetFlow(
          order.paymentType,
          order.downPaymentType?.toString(),
          order.downPaymentValue?.toString(),
          order.remainingPaymentType?.toString(),
          order.remainingPaymentValue?.toString()
        );
      }
    }, [order, method]);

    useEffect(() => {
      const defaultPaymentTerm = (userCompany.settings?.defaultPaymentTerm ??
        {}) as PaymentTermsType;
      if (enabled && defaultPaymentTerm?.status && method !== "update") {
        let downPaymentType;
        let downPaymentValue;
        let remainingPaymentType;
        let remainingPaymentValue;
        if (defaultPaymentTerm.coditionType === "A Vista") {
          downPaymentType = "percentage";
          downPaymentValue = "100";
          remainingPaymentType = undefined;
          remainingPaymentValue = "";
        }
        if (defaultPaymentTerm.coditionType === "50%/50%") {
          downPaymentType = "percentage";
          downPaymentValue = "50";
          remainingPaymentType = "percentage";
          remainingPaymentValue = "50";
        }
        if (defaultPaymentTerm.coditionType === "Com entrada") {
          downPaymentType = defaultPaymentTerm.inputType;
          downPaymentValue =
            defaultPaymentTerm.inputType === "percentage"
              ? defaultPaymentTerm.percentValue
              : defaultPaymentTerm.priceValue;
          remainingPaymentType = "installments";
          remainingPaymentValue = defaultPaymentTerm.parcelValue;
        }
        if (defaultPaymentTerm.coditionType === "Parcelado") {
          downPaymentType = undefined;
          downPaymentValue = "";
          remainingPaymentType = "installments";
          remainingPaymentValue = defaultPaymentTerm.parcelValue;
        }

        handleSetPaymentTypeAndResetFlow(
          defaultPaymentTerm?.coditionType ?? "A Vista",
          downPaymentType,
          downPaymentValue,
          remainingPaymentType,
          remainingPaymentValue
        );
      }
    }, [userCompany?.settings]);

    const handleSetPaymentTypeAndResetFlow = (
      paymentType: string,
      downPaymentType?: string,
      downPaymentValue?: string,
      remainingPaymentType?: string,
      remainingPaymentValue?: string
    ) => {
      setFieldValue("paymentType", paymentType);
      if (paymentType === "A Vista") {
        setFieldValue("downPaymentType", downPaymentType ?? "percentage");
        setFieldValue("downPaymentValue", downPaymentValue ?? 100);
        setFieldValue(
          "remainingPaymentType",
          remainingPaymentType ?? undefined
        );
        setFieldValue("remainingPaymentValue", remainingPaymentValue ?? "");
      }
      if (paymentType === "50%/50%") {
        setFieldValue("downPaymentType", downPaymentType ?? "percentage");
        setFieldValue("downPaymentValue", downPaymentValue ?? 50);
        setFieldValue(
          "remainingPaymentType",
          remainingPaymentType ?? "percentage"
        );
        setFieldValue("remainingPaymentValue", remainingPaymentValue ?? 50);
      }
      if (paymentType === "Com entrada") {
        setFieldValue("downPaymentType", downPaymentType ?? "value");
        setFieldValue("downPaymentValue", downPaymentValue ?? "");
        setFieldValue(
          "remainingPaymentType",
          remainingPaymentType ?? "installments"
        );
        setFieldValue("remainingPaymentValue", remainingPaymentValue ?? "");
      }
      if (paymentType === "Parcelado") {
        setFieldValue("downPaymentType", downPaymentType ?? undefined);
        setFieldValue("downPaymentValue", downPaymentValue ?? "");
        setFieldValue(
          "remainingPaymentType",
          remainingPaymentType ?? "installments"
        );
        setFieldValue("remainingPaymentValue", remainingPaymentValue ?? "");
      }
    };

    const installmentValue = useMemo(() => {
      const downPayment =
        values?.downPaymentType === "value"
          ? Number(values?.downPaymentValue || 0)
          : (Number(values?.downPaymentValue || 0) * total) / 100;

      const installment = Number(values?.remainingPaymentValue);

      const value = (total - downPayment) / installment;

      if (!installment) {
        return "";
      }

      return value;
    }, [
      total,
      values.downPaymentType,
      values.downPaymentValue,
      values?.remainingPaymentValue,
    ]);

    const renderInstallmentField = (
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <Styled.CurrencyField
          label="Valor das parcelas"
          name=""
          value={installmentValue}
          disabled
          disableFormik
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    );

    const renderPaymentValueField = useMemo(() => {
      if (values.downPaymentType === "value") {
        return (
          <Styled.CurrencyField
            label="Valor da entrada"
            name="downPaymentValue"
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      }

      if (values.downPaymentType === "percentage") {
        return (
          <Styled.PercentField
            label="Valor da entrada"
            name="downPaymentValue"
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      }

      return null;
    }, [values?.downPaymentType]);

    const renderFields = useMemo(() => {
      if (values.paymentType === "A Vista") {
        return null;
      }

      if (values.paymentType === "50%/50%") {
        return (
          <>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Styled.CurrencyField
                label="Valor do primeira"
                name=""
                value={total / 2}
                disabled
                disableFormik
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Styled.CurrencyField
                label="Valor da segunda"
                name=""
                value={total / 2}
                disabled
                disableFormik
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>
        );
      }

      if (values.paymentType === "Com entrada") {
        return (
          <>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Styled.SelectField
                label="Tipo de entrada"
                name="downPaymentType"
                items={DOWN_PAYMENT_ITEMS}
                defaultValue="value"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              {renderPaymentValueField}
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Styled.TextField
                label="Qtd de parcelas restantes"
                name="remainingPaymentValue"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {renderInstallmentField}
          </>
        );
      }

      if (values.paymentType === "Parcelado") {
        return (
          <>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Styled.TextField
                label="Quantidade de parcelas"
                name="remainingPaymentValue"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {renderInstallmentField}
          </>
        );
      }

      return null;
    }, [values, total, installmentValue]);

    return (
      <>
        <Box mt={2} mb={2}>
          <Typography variant="body1" color="textSecondary">
            Condições de pagamento
          </Typography>
        </Box>
        <Styled.Container enabled={enabled}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Styled.SelectField
                label="Condição de pagamento"
                name="paymentType"
                items={PAYMENT_CONDITION_ITEMS}
                defaultValue="A Vista"
                onChange={event => {
                  handleSetPaymentTypeAndResetFlow(
                    event.target.value as string
                  );
                }}
              />
            </Grid>
            {renderFields}
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Styled.CurrencyField
                label="Total da venda"
                name=""
                value={total}
                disabled
                disableFormik
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Styled.Container>
      </>
    );
  };

export default ConditionPaymentSection;
