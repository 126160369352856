import React from "react";

import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  usePopupState,
  bindToggle,
  bindPopover,
} from "material-ui-popup-state/hooks";

import Button from "src/components/Button";
import { useModal } from "src/components/Modal/useModal";

import DeleteMovementModal from "../DeleteMovementModal";
import { MoreOptionsMenuProps } from "./props";

const MoreOptionsMenu: React.FC<MoreOptionsMenuProps> = props => {
  const { id, source, afterAction } = props;

  const { handleOpen } = useModal();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-options-menu-table",
  });

  if (source !== "manual") {
    return <Box />;
  }

  return (
    <>
      <Button {...bindToggle(popupState)}>
        <MoreVertIcon color="primary" />
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box>
          <Button
            fullWidth
            onClick={() => {
              popupState.close();
              handleOpen();
            }}
          >
            Deletar
          </Button>
        </Box>
      </Popover>
      <DeleteMovementModal id={id} afterAction={afterAction} />
    </>
  );
};

export default MoreOptionsMenu;
