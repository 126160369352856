import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { GetCompanyModulesResponseType } from "src/interfaces/module";
import api from "src/services/api";

export const getModulesCompany = () =>
  api
    .get<GetCompanyModulesResponseType>("v1/module/company")
    .then(res => res.data);

export function useModulesCompany(
  config?: UseQueryOptions<
    GetCompanyModulesResponseType,
    Error,
    GetCompanyModulesResponseType,
    unknown[]
  >
) {
  return useQuery<
    GetCompanyModulesResponseType,
    Error,
    GetCompanyModulesResponseType,
    unknown[]
  >(["modulesCompany"], () => getModulesCompany(), {
    refetchOnWindowFocus: false,
    ...(config || {}),
  });
}
