import React, { createContext, useCallback, useContext, useMemo } from "react";

import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { BoundCanProps, createContextualCan } from "@casl/react";

import { FeatureAction, FeaturesSubjects } from "src/interfaces/permissions";

import { useCompany } from "../company/useCompany";
import { useAuth } from "../user/auth/useAuth";
import { PermissionContextProps } from "./props";

export const PermissionContext = createContext<PermissionContextProps>(
  {} as PermissionContextProps
);

const CanAbility = createContextualCan<PermissionContextProps>(
  PermissionContext.Consumer
);

type CanComponentFunction = ({
  ...rest
}: Omit<BoundCanProps<PermissionContextProps>, "I" | "a"> &
  FeatureAction) => JSX.Element;

export type CanCallback = (
  features: FeatureAction[],
  allFeatures?: boolean
) => boolean;

export const Can: CanComponentFunction = ({
  children,
  action,
  subject,
  ...rest
}) => {
  const { user } = useAuth();
  const { userCompany } = useCompany();

  return (
    <CanAbility
      passThrough={user?.id === userCompany?.owner}
      I={action}
      a={subject}
      {...rest}
    >
      {children}
    </CanAbility>
  );
};

export const PermissionProvider: React.FC = props => {
  const { children } = props;
  const { user } = useAuth();

  const defineAbility = useCallback(() => {
    const { can, build } = new AbilityBuilder<PermissionContextProps>(
      createMongoAbility
    );

    if (user && user.permissions) {
      Object.entries(user.permissions).forEach(([permission, actions]) => {
        can(actions ?? [], permission as FeaturesSubjects);
      });
    }

    return build();
  }, [user]);

  const providerValues = useMemo(() => defineAbility(), [defineAbility, user]);

  return (
    <PermissionContext.Provider value={providerValues}>
      {children}
    </PermissionContext.Provider>
  );
};

export function usePermission() {
  const context = useContext(PermissionContext);
  const { user } = useAuth();
  const { userCompany } = useCompany();

  const can: CanCallback = (features: FeatureAction[], allFeatures = false) => {
    const validate = ({ subject, action }: FeatureAction) => {
      if (user?.id === userCompany?.owner) return true;

      if (!action && !subject) return true;

      return context.can(action, subject);
    };

    if (allFeatures) {
      return features.every(validate);
    }
    return features.some(validate);
  };

  if (context) {
    return { can };
  }

  throw new Error(
    "usePermission must be used within a PermissionContextProvider"
  );
}
