import styled from "styled-components";

import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

export const UploadImageButton = styled.button`
  width: 100%;
  height: 250px;
  background-color: #e4f1fa;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px dashed ${({ theme }) => theme.palette.primary.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const PhotoIcon = styled(AddPhotoAlternateIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 70px;
`;
