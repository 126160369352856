import Axios from "axios";

import { getToken } from "./getToken";

const api = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});

api.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default api;
