import styled from "styled-components";

import Typography from "src/components/Typography";

export const SuffixQuantityField = styled(Typography)`
  background-color: #d9d9d9;
  padding: 1rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }
`;
