import React, { useState, createContext, useContext } from "react";

import { STATUS } from "src/constants/requestStatus";
import {
  GetSchedulingsResponseType,
  ListSchedulingsParamType,
} from "src/interfaces/scheduling";
import { getSchedulings } from "src/services/scheduling";

import { SchedulingListContextType } from "./props";

export const SchedulingListContext = createContext(
  {} as SchedulingListContextType
);

export const SchedulingListProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState(STATUS.inital);
  const [schedulings, setSchedulings] = useState<GetSchedulingsResponseType>(
    {} as GetSchedulingsResponseType
  );

  const list = async (params?: ListSchedulingsParamType) => {
    try {
      setSchedulings({} as GetSchedulingsResponseType);
      setStatus(STATUS.loading);

      const response: GetSchedulingsResponseType = await getSchedulings(params);

      setSchedulings(response);

      setStatus(STATUS.success);
    } catch (e) {
      setSchedulings({} as GetSchedulingsResponseType);
      setStatus(STATUS.error);
    }
  };

  return (
    <SchedulingListContext.Provider
      value={{
        status,
        schedulings,
        list,
      }}
    >
      {children}
    </SchedulingListContext.Provider>
  );
};

export const useSchedulingList = () => {
  const context = useContext(SchedulingListContext);

  if (!context) {
    throw new Error(
      "useSchedulingList must be used within a SchedulingListProvider"
    );
  }

  return context;
};
