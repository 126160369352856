import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ThemeProvider as StyledProvider } from "styled-components";

import "react-toastify/dist/ReactToastify.css";
import { ChakraProvider } from "@chakra-ui/react";
import DateFnsUtils from "@date-io/date-fns";
import {
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
  StylesProvider,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ptBrLocale from "date-fns/locale/pt-BR";

import Providers from "src/hooks";
import Routes from "src/routes";
import { chakraTheme } from "src/styles/themes/chakra-ui";

import GlobalStyles from "../styles/global";
import materialUi from "../styles/themes/material-ui";

const queryClient = new QueryClient();

const WebApp: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={materialUi}>
        <StylesProvider injectFirst>
          <StyledProvider theme={materialUi}>
            <ChakraProvider resetCSS theme={chakraTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                <Providers>
                  <GlobalStyles />
                  {/* uma elegante, consistente e simples base para construção de aplicativos. */}
                  <CssBaseline />
                  <ToastContainer
                    hideProgressBar
                    closeOnClick
                    closeButton={false}
                    toastClassName="custom-toast-azulou"
                  />
                  <BrowserRouter>
                    <Routes />
                  </BrowserRouter>
                </Providers>
              </MuiPickersUtilsProvider>
            </ChakraProvider>
          </StyledProvider>
        </StylesProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
};

export default WebApp;
