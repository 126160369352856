import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import InfoIconMUI from "@material-ui/icons/Info";

import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import OpenCashFlowModal from "src/pages/Financial/CashFlow/components/Modals/OpenCashFlowModal";

export function OpenCashFlowInfo() {
  const { open, handleOpen } = useBaseSwipeableDrawer();
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={5}
    >
      <Icon as={InfoIconMUI} color="gray.200" fontSize="100px" />
      <Text textAlign="center" color="primary.500" maxW="660px">
        Você ainda não possui o CAIXA ABERTO, comece{" "}
        <Text as="strong" fontSize="lg" color="primary.500">
          ABRINDO O CAIXA
        </Text>{" "}
        abaixo para poder começar as vendas do dia!
      </Text>
      <Button
        minW="150px"
        w="min-content"
        onClick={() => {
          handleOpen("openCashFlowModal");
        }}
      >
        Abrir caixa
      </Button>
      {open.includes("openCashFlowModal") ? <OpenCashFlowModal /> : null}
    </Flex>
  );
}
