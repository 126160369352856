import { HeaderProps } from "react-big-calendar";

import { Flex, Text } from "@chakra-ui/react";

export default function MonthHeader({ label }: HeaderProps) {
  return (
    <Flex
      w="100%"
      justifyContent="center"
      alignItems="center"
      fontWeight="normal"
      h="10"
    >
      <Text casing="capitalize">{label}</Text>
    </Flex>
  );
}
