import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { CategoryRecordType } from "src/interfaces/categoryRecord";
import api from "src/services/api";

interface RecordCategoriesParams {
  type: "income" | "expense";
}

type RecordCategoriesResponse = CategoryRecordType[];

export const getRecordCategories = (params: RecordCategoriesParams) =>
  api
    .get<
      RecordCategoriesResponse,
      AxiosResponse<{ categories: RecordCategoriesResponse }>
    >("v1/record-category/company", {
      params: {
        ...params,
        limit: 100,
      },
    })
    .then(res => res.data.categories);

export function useRecordCategories(
  params: RecordCategoriesParams,
  config?: UseQueryOptions<
    RecordCategoriesResponse,
    Error,
    RecordCategoriesResponse,
    unknown[]
  >
) {
  return useQuery<
    RecordCategoriesResponse,
    Error,
    RecordCategoriesResponse,
    unknown[]
  >(["recordCategories", params], () => getRecordCategories(params), {
    refetchOnWindowFocus: false,
    ...(config || {}),
    initialData: [] as RecordCategoriesResponse,
  });
}
