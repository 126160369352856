import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface OpenCashflowParams {
  initMoney: number;
  financialAccount: string;
}

export const openCashFlow = (params: OpenCashflowParams) =>
  api.post(`/v1/cashflow/open`, params).then(res => res.data);

export function useOpenCashFlow(
  config?: UseMutationOptions<any, any, OpenCashflowParams, unknown>
) {
  return useMutation<any, any, OpenCashflowParams>(
    (data: OpenCashflowParams) => openCashFlow(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao abrir caixa"
        );
      },
      ...config,
    }
  );
}
