import React, { FC, useMemo } from "react";

import { Popover } from "@material-ui/core";
import { bindPopover, PopupState } from "material-ui-popup-state/hooks";

import {
  BUY_ORDER_COLOR_STATUS,
  BUY_ORDER_STATUS_NAME,
} from "src/constants/orders";
import { BuyOrderStatusEnum } from "src/interfaces/buyOrder";

import * as Styled from "./styles";

interface BuyOrderStatusesPopoverProps {
  updateStatus: (buyOrderId: string, statusId: BuyOrderStatusEnum) => void;
  buyOrderId: string;
  popupState: PopupState;
}

const BuyOrderStatusesPopover: FC<BuyOrderStatusesPopoverProps> = ({
  buyOrderId,
  updateStatus,
  popupState,
}) => {
  const renderButtons = useMemo(() => {
    return Object.keys(BuyOrderStatusEnum).map(status => {
      return (
        <Styled.ButtonOption
          key={status}
          onClick={() => {
            updateStatus(buyOrderId, status as BuyOrderStatusEnum);
          }}
        >
          <Styled.StatusIndicator
            color={BUY_ORDER_COLOR_STATUS[status as BuyOrderStatusEnum]}
          />

          {BUY_ORDER_STATUS_NAME[status as BuyOrderStatusEnum]}
        </Styled.ButtonOption>
      );
    });
  }, [buyOrderId]);

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: 0,
        },
      }}
    >
      <Styled.MenuOverlay>
        <Styled.MenuContainer>{renderButtons}</Styled.MenuContainer>
      </Styled.MenuOverlay>
    </Popover>
  );
};

export default BuyOrderStatusesPopover;
