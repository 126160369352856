import React from "react";

import {
  Modal as ChakraModal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface IModalProps {
  onClose: () => void;
  isOpen: boolean;
  title?: string;
  children: React.ReactNode;
}

export default function Modal({
  onClose,
  isOpen,
  title,
  children,
}: IModalProps) {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {title && <ModalHeader>{title}</ModalHeader>}
        {children}
      </ModalContent>
    </ChakraModal>
  );
}
