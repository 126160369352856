import React from "react";

import { Chip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import Button from "src/components/Button";
import Modal from "src/components/Modal";
import { useModal } from "src/components/Modal/useModal";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import { BuyOrderType } from "src/interfaces/buyOrder";

import { CreateOfDeleteBuyOrderStockModalProps } from "./props";
import * as Styled from "./styles";

const CreateOfDeleteBuyOrderStockModal: React.FC<CreateOfDeleteBuyOrderStockModalProps> =
  props => {
    const { id, stockCreatedAt } = props;

    const {
      createBuyOrderStock,
      createBuyOrderStockStatus,
      deleteBuyOrderStockStatus,
      deleteBuyOrderStock,
    } = useBuyOrder();
    const { handleClose } = useModal();
    const { setRestartOrderList } = useBuyOrderFilters();

    const createOrDeleteBuyOrderStockService = async () => {
      if (!stockCreatedAt && !createBuyOrderStockStatus.loading) {
        await createBuyOrderStock(id);
      }
      if (stockCreatedAt && !deleteBuyOrderStockStatus.loading) {
        await deleteBuyOrderStock(id);
      }

      handleClose();
      setRestartOrderList(true);
    };

    return (
      <Modal>
        <Box p={3}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <div />
                {stockCreatedAt ? (
                  <Styled.AlertIcon alignmentBaseline="middle" />
                ) : (
                  <Styled.InfoIcon alignmentBaseline="middle" />
                )}
                <Styled.CloseButton onClick={handleClose} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Styled.InfoText align="center" variant="h6">
                Tem certeza que deseja {stockCreatedAt ? "estornar" : "lançar"}{" "}
                o estoque desta ordem de compra?
              </Styled.InfoText>
            </Grid>
            <Grid item xs={12}>
              {stockCreatedAt ? (
                <Styled.DeleteButton
                  variant="contained"
                  onClick={createOrDeleteBuyOrderStockService}
                  fullWidth
                  size="large"
                  disabled={deleteBuyOrderStockStatus.loading}
                  loading={deleteBuyOrderStockStatus.loading}
                >
                  Estornar
                </Styled.DeleteButton>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={createOrDeleteBuyOrderStockService}
                  fullWidth
                  size="large"
                  disabled={createBuyOrderStockStatus.loading}
                  loading={createBuyOrderStockStatus.loading}
                >
                  Lançar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };

interface CreateOrDeleteBuyOrderStockProps {
  buyOrder: BuyOrderType;
}

const CreateOrDeleteBuyOrderStock: React.FC<CreateOrDeleteBuyOrderStockProps> =
  props => {
    const { buyOrder } = props;
    const { handleOpen } = useModal();

    return (
      <>
        <Chip
          style={{
            backgroundColor: buyOrder.stockCreatedAt ? "#F9A82F" : "#F9A82F80",
            color: "#FFF",
            fontWeight: 500,
            marginRight: "8px",
            lineHeight: 1,
          }}
          label={buyOrder.stockCreatedAt ? "Estoque" : "Lançar estoque"}
          onClick={handleOpen}
          onDelete={buyOrder?.stockCreatedAt ? handleOpen : undefined}
          deleteIcon={
            <CheckCircleIcon style={{ color: "#FFF" }} fontSize="small" />
          }
        />
        <CreateOfDeleteBuyOrderStockModal
          id={buyOrder.id}
          stockCreatedAt={buyOrder.stockCreatedAt}
        />
      </>
    );
  };

export default CreateOrDeleteBuyOrderStock;
