import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { Box, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CreateIcon from "@material-ui/icons/Create";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Formik } from "formik";

import { BaseModalProvider } from "src/components/BaseModal/useBaseModal";
import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import Loader from "src/components/Loader";
import { ModalProvider } from "src/components/Modal/useModal";
import { ConfirmActionsModal } from "src/components/NewComponents/ConfirmActionsModal/ConfirmActionsModal";
import RenderModule from "src/components/RenderModule";
import Typography from "src/components/Typography";
import { DocumentConfigurationKeys } from "src/constants/documentConfiguration";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import {
  FinancialAccountProvider,
  useFinancialAccount,
} from "src/hooks/financialAccount/useFinancialAccount";
import { useModule } from "src/hooks/module/useModule";
import { useOrder } from "src/hooks/order/useOrder";
import {
  RecordsOrderProvider,
  useRecordsOrder,
} from "src/hooks/order/useRecordsOrder";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";
import { RecordProvider, useRecord } from "src/hooks/record/useRecord";
import { OrderType } from "src/interfaces/order";
import { getRouteString } from "src/utils/urlHandler";
import { withContext } from "src/utils/withContext";

import AditionalInfoTextArea from "../../components/AditionalInfoTextArea";
import ConditionPaymentSection from "../../components/ConditionPaymentSection";
import DetailsBottomBar from "../../components/DetailsBottomBar";
import DiscountSection from "../../components/DiscountSection";
import ProductManager from "../../components/ProductManager";
import ServiceManager from "../../components/ServiceManager";
import CreateOrDeleteOrderRecords from "../components/CreateOrDeleteOrderRecords";
import CreateOrDeleteOrderStock from "../components/CreateOrDeleteOrderStock";
import DocumentConfigurationModal from "../components/DocumentConfigurationModal";
import MainDataCard from "../components/MainDataCard";
import MoreOptionsOrderDetailModal from "../components/MoreOptionsOrderDetailModal";
import { OrderReceipt } from "../components/OrderTemplate";
import PrintSettingsModal from "../components/PrintSettingsModal";
import RecordsData from "../components/RecordsData";
import TechnicalReportCard from "../components/TechnicalReportCard";
import UpdateDisabledInfo from "../components/UpdateDisabledInfo";

const ServiceOrderDetail: React.FC = () => {
  const { orderId = "" } = useParams<{ orderId: string }>();

  const componentRef = useRef(null);
  const { handleOpen } = useBaseSwipeableDrawer();

  const [documentType, setDocumentType] = useState<
    DocumentConfigurationKeys | ""
  >("");

  const { userCompany, serviceOrderNameDefinitions } = useCompany();
  const {
    detail,
    order,
    products,
    detailStatus,
    customer,
    services,
    deleteOrderStockStatus,
    deleteOrderStock,
    deleteOrderRecordsStatus,
    deleteOrderRecords,
  } = useOrder();
  const { setRestartOrderList } = useOrderFilters();
  const { records, getOrderRecords } = useRecordsOrder();
  const { list: listAccounts } = useFinancialAccount();
  const { hasModules, serviceOrderModule } = useModule();

  const blockOrderUpdate =
    serviceOrderModule?.settings?.blockOrderUpdate ?? false;

  const canUpdateOrder =
    (!hasModules("stock") || !order?.stockCreatedAt) &&
    !order?.recordsCreatedAt;

  const deadline = order?.deadline ? new Date(order.deadline) : undefined;

  const createdDate = order?.createdDate
    ? new Date(order?.createdDate)
    : undefined;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const {
    createStatus,
    updateStatus,
    deleteStatus,
    payStatus,
    chargebackStatus,
  } = useRecord();

  useEffect(() => {
    if (hasModules("accountManagement")) {
      listAccounts({ status: "enabled" });
    }
  }, [userCompany]);

  useEffect(() => {
    detail(orderId);
    getOrderRecords(orderId);
  }, [orderId]);

  useEffect(() => {
    if (
      createStatus.success ||
      updateStatus.success ||
      deleteStatus.success ||
      payStatus.success ||
      chargebackStatus.success
    ) {
      setRestartOrderList(true);
    }
  }, [
    createStatus.success,
    updateStatus.success,
    deleteStatus.success,
    payStatus.success,
    chargebackStatus.success,
  ]);

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
    { label: "vendas", path: PATHS.SERVICE_ORDER_LIST },
    {
      label: serviceOrderNameDefinitions.titlePlural,
      path: PATHS.SERVICE_ORDER_LIST,
    },
    { label: `${serviceOrderNameDefinitions.title} #${order.code}` },
  ];

  const chargebackStockAndRecord = async () => {
    if (hasModules("stock") && order?.stockCreatedAt) {
      await deleteOrderStock(order?.id as string);
    }

    if (order?.recordsCreatedAt) {
      await deleteOrderRecords(order?.id as string, false);
    }

    onClose();
    navigateToEditOrder();
    setRestartOrderList(true);
  };

  const navigateToEditOrder = () => {
    navigate(getRouteString(PATHS.SERVICE_ORDER_UPDATE, orderId, "orderId"));
  };

  const [customizedMessage, setCustomizedMessage] = useState("");
  const [printTechnicalReport, setPrintTechnicalReport] = useState("false");

  const detailCards = () => (
    <Grid container>
      <Grid item xs={12}>
        <MainDataCard />
      </Grid>
      <Grid item xs={12}>
        <ServiceManager isViewMode />
      </Grid>
      {products.length > 0 && (
        <Grid item xs={12}>
          <ProductManager isViewMode />
        </Grid>
      )}

      {order.recordsCreatedAt ? (
        <Grid item xs={12}>
          <Box mt={2}>
            <RecordsData />
          </Box>
        </Grid>
      ) : null}

      <Box width="100%" mt={2}>
        <TechnicalReportCard />
      </Box>

      <Grid item xs={12}>
        <ConditionPaymentSection enabled={false} />
      </Grid>

      <Grid item xs={12}>
        <DiscountSection enabled={false} />
      </Grid>

      <Grid item xs={12}>
        <Box mt={2}>
          <AditionalInfoTextArea
            orderType="serviceOrder"
            value={order.additionalInfo}
            disabled
          />
        </Box>
      </Grid>
    </Grid>
  );

  const renderContent = useMemo(() => {
    if (detailStatus.loading) {
      return <Loader />;
    }

    return (
      <>
        <Breadcrumb data={BREADCRUMB_BUTTONS} />
        <Divider />
        <Box
          mt={1}
          mb={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="primary" variant="h6">
            {serviceOrderNameDefinitions.title} #{order.code}
          </Typography>
        </Box>
        <Box
          mt={3}
          mb={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          style={{ rowGap: 8 }}
        >
          <Box display="flex" flexDirection="row">
            <RenderModule modules={["stock"]}>
              <ModalProvider>
                <CreateOrDeleteOrderStock order={order} />
              </ModalProvider>
            </RenderModule>
            <ModalProvider>
              <CreateOrDeleteOrderRecords order={order} />
            </ModalProvider>
            {!canUpdateOrder ? <UpdateDisabledInfo type={order.type} /> : null}
          </Box>
          <Flex display="flex" gap={4}>
            <Button
              rightIcon={<CreateIcon fontSize="medium" />}
              onClick={() => {
                if (
                  !blockOrderUpdate ||
                  (hasModules("stock") &&
                    !order?.stockCreatedAt &&
                    !order?.recordsCreatedAt) ||
                  (!hasModules("stock") && !order?.recordsCreatedAt)
                ) {
                  navigateToEditOrder();
                } else {
                  onOpen();
                }
              }}
            >
              Editar
            </Button>
            <Button onClick={() => handleOpen("moreOptionsOrderDetailModal")}>
              Mais opções
              <MoreVertIcon fontSize="medium" />
            </Button>
          </Flex>
        </Box>

        <Box mt={2}>{detailCards()}</Box>
        <MoreOptionsOrderDetailModal
          setDocumentType={setDocumentType}
          handlePrint={handlePrint}
          order={order}
        />

        <OrderReceipt
          ref={componentRef}
          company={userCompany}
          order={order}
          products={products}
          services={services}
          customer={customer}
          records={records}
          printCustomizedMessage={customizedMessage}
          printTechnicalReport={printTechnicalReport}
        />
      </>
    );
  }, [
    detailStatus,
    order,
    userCompany,
    customer,
    products,
    services,
    orderId,
    customizedMessage,
    printTechnicalReport,
  ]);

  const updateServiceOrder = (data: Partial<OrderType>) => {
    if (services.length > 0 && customer?.id) {
      // eslint-disable-next-line no-console
      console.log(data);
    }
  };

  return (
    <DashboardLayout
      selected="sales"
      subSelected="service-order"
      modules={["serviceOrder"]}
    >
      <Formik
        onSubmit={updateServiceOrder}
        initialValues={
          {
            additionalInfo: order.additionalInfo,
            discount: order.discount,
            discountType: order.discountType,

            downPaymentType: order.downPaymentType,
            downPaymentValue: order.downPaymentValue,
            remainingPaymentType: order.remainingPaymentType,
            remainingPaymentValue: order.remainingPaymentValue,

            services,
            products,

            paymentType: order.paymentType,

            deadline,
            createdDate,
          } as Partial<OrderType>
        }
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {renderContent}
      </Formik>
      <DetailsBottomBar />
      <DocumentConfigurationModal
        documentType={documentType as DocumentConfigurationKeys}
        orderType="serviceOrder"
        orderId={order.id}
      />
      <PrintSettingsModal
        handlePrint={handlePrint}
        setCustomizedMessage={setCustomizedMessage}
        setPrintTechnicalReport={setPrintTechnicalReport}
      />
      <ConfirmActionsModal
        title="Deseja estornar contas/estoque e continuar editando?"
        onClose={
          deleteOrderRecordsStatus.loading || deleteOrderStockStatus.loading
            ? // eslint-disable-next-line @typescript-eslint/no-empty-function
              () => {}
            : onClose
        }
        isOpen={isOpen}
        actions={[
          {
            label: "Sim, estonar",
            variant: "outline",
            colorScheme: "error",
            isLoading:
              deleteOrderRecordsStatus.loading ||
              deleteOrderStockStatus.loading,
            onClick: () => {
              chargebackStockAndRecord();
            },
          },
          {
            label: "Não",
            colorScheme: "error",
            disabled:
              deleteOrderRecordsStatus.loading ||
              deleteOrderStockStatus.loading,
            onClick: () => {
              onClose();
            },
          },
        ]}
      />
    </DashboardLayout>
  );
};

export default withContext(
  ServiceOrderDetail,
  ModalProvider,
  BaseSwipeableDrawerProvider,
  RecordsOrderProvider,
  BaseModalProvider,
  RecordProvider,
  FinancialAccountProvider
);
