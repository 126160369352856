import React, { FC } from "react";
import { toast } from "react-toastify";

import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { useContact } from "src/hooks/contact/useContact";
import { TagProvider, useTag } from "src/hooks/tag/useTag";
import { TagType } from "src/interfaces/tag";
import { withContext } from "src/utils/withContext";

import * as Styled from "./styles";

const filter = createFilterOptions<TagType>();

interface TagsFieldProps {
  tags: TagType[];
  setTags: (value: TagType[]) => void;
}

const TagsField: FC<TagsFieldProps> = ({
  tags: selectedTags,
  setTags: setSelectedTags,
}) => {
  const { createTag, createTagLoading } = useTag();
  const { tags, getTags, tagStatus } = useContact();
  const [inputValue, setInputValue] = React.useState("");

  const handleCreateTag = async (label: string) => {
    try {
      const result = await createTag({
        label,
        type: "contact",
      });
      if (result) {
        setSelectedTags([...selectedTags, result]);
        toast.success("Marcador cadastrado");
        getTags({ search: "", type: "contact" });
      }
    } catch (error) {
      toast.error(
        "Ocorreu um problema ao cadastrar esse marcador, tente novamente."
      );
    }
  };

  const handleCreateNewTag = async (newTags: TagType[]) => {
    const tag = newTags[newTags.length - 1];

    if (!tag?.id && newTags.length) {
      handleCreateTag(inputValue);
    } else {
      setSelectedTags(newTags);
    }
  };

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      value={selectedTags}
      disabled={tagStatus.loading || createTagLoading.loading}
      onChange={(event, newTags) => {
        handleCreateNewTag(newTags);
      }}
      options={tags}
      getOptionLabel={option => option.label}
      defaultValue={undefined}
      filterSelectedOptions
      getOptionSelected={(option, value) => option.id === value.id}
      onOpen={() => {
        if (!tags.length) {
          getTags({ search: "", type: "contact" });
        }
      }}
      noOptionsText=""
      renderTags={(value: TagType[], getTagProps) =>
        value.map((option: TagType, index: number) => (
          <Chip
            key={option.id}
            style={{
              backgroundColor: "#ACE2F6",
              color: "#074EE8",
            }}
            label={option.label}
            deleteIcon={
              <CloseIcon style={{ color: "#074EE8" }} fontSize="small" />
            }
            {...getTagProps({ index })}
          />
        ))
      }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;

        const isExisting = tags.some(
          tag => inputValue.toLowerCase() === tag.label.toLowerCase()
        );

        if (inputValue !== "" && !isExisting) {
          filtered.push({
            label: `Nenhum resultado correspondente a "${inputValue}". Clique aqui para criar um novo marcador.
              `,
          } as TagType);
        }

        return filtered;
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <Styled.SearchField
          {...params}
          variant="outlined"
          fullWidth
          label="Marcador"
          placeholder="Pesquisar marcador"
          // onChange={onInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {tagStatus.loading || createTagLoading.loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default withContext(TagsField, TagProvider);
