import React, { FC, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";
import { format } from "date-fns";

import { ModalProvider } from "src/components/Modal/useModal";
import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import PATHS from "src/constants/paths";
import { useContact } from "src/hooks/contact/useContact";
import { SubContactFieldDataType } from "src/interfaces/contact";
import { getRouteString } from "src/utils/urlHandler";

import MoreOptionsMenu from "../MoreOptionsMenu";

const SubcontactsTable: FC = () => {
  const { id = "", tab = "" } = useParams<{ id: string; tab: string }>();

  const path = `${getRouteString(
    PATHS.CONTACTS_DETAIL,
    id,
    "id"
  )}/${tab}/:subContactId/editar`;

  const [page, setPage] = useState(1);

  const { subContactFields, subContacts, subContactsStatus, getSubContacts } =
    useContact();

  function handleGetSubContacts() {
    getSubContacts({
      contact: id,
    });
  }

  useEffect(() => {
    handleGetSubContacts();
  }, [id]);

  const renderFieldValue = (field: SubContactFieldDataType) => {
    if (field.type === "date") {
      return format(new Date(field.content), "dd/MM/yyyy");
    }
    return field.content ?? "-";
  };

  const renderOptionsButton = (params: GridValueFormatterParams) => (
    <ModalProvider>
      <MoreOptionsMenu
        id={params.id as string}
        handleGetSubContacts={handleGetSubContacts}
      />
    </ModalProvider>
  );

  const columns: GridColDef[] = useMemo(() => {
    return [
      ...subContactFields
        .filter(field => field.type !== "image")
        .map(field => {
          return {
            field: field.id,
            headerName: field.label,
            renderCell: (params: GridValueFormatterParams) => {
              if (!params.row?.data) return "-";

              const fielData = params.row.data.find(
                (field: SubContactFieldDataType) => {
                  return field.referId === params.field;
                }
              );

              if (!fielData) return "-";

              return renderFieldValue(fielData);
            },
            flex: 1,
          };
        }),
      {
        field: "options",
        headerName: " ",
        renderCell: renderOptionsButton,
        flex: 1,
        align: "right",
      },
    ];
  }, [subContactFields, subContacts]);

  return (
    <Table
      rows={subContacts}
      columns={columns}
      page={page}
      setPage={setPage}
      filterMode="server"
      loading={subContactsStatus.loading}
      totalCount={subContacts.length}
      detailLink={path}
      detailLinkParamName="subContactId"
      hideFooter
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>{/* <EmptyContactTable /> */}</EmptyTable>
        ),
      }}
    />
  );
};

export default SubcontactsTable;
