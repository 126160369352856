import React, { useMemo } from "react";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloseIcon from "@material-ui/icons/Close";
import { useField } from "formik";

import Button from "src/components/Button";
import { formatDateToISO } from "src/utils/date";

import * as Styled from "./styles";

const DeadlineSection: React.FC = () => {
  const [meta, , helpers] = useField("deadline");

  const { value } = meta;
  const { setValue } = helpers;

  const date = useMemo(() => {
    if (value) {
      return formatDateToISO(value);
    }
    return null;
  }, [value]);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setValue(date);
    }
  };

  const handleDelete = (event: { stopPropagation(): void }) => {
    event.stopPropagation();
    setValue(null);
  };

  const inputProps = useMemo(() => {
    if (date) {
      return {
        endAdornment: (
          <div>
            <Button onClick={handleDelete} size="small">
              <CloseIcon color="primary" />
            </Button>
          </div>
        ),
      };
    }

    return {
      endAdornment: <CalendarTodayIcon color="primary" />,
    };
  }, [date]);

  return (
    <Styled.Container>
      <Styled.DeadlineDateTimePicker
        value={date}
        onChange={handleDateChange}
        animateYearScrolling
        inputVariant="outlined"
        ampm={false}
        format="dd/MM/yyyy - HH:mm"
        InputProps={inputProps}
        cancelLabel="cancelar"
        label="Prazo de entrega"
        fullWidth
      />
    </Styled.Container>
  );
};

export default DeadlineSection;
