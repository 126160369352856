import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";

import {
  Avatar,
  Box,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { CurrencyInput } from "src/components/NewComponents/CurrencyInput";
import { Input } from "src/components/NewComponents/Input";
import { PRODUCTS_MEASURES, SERVICES_MEASURES } from "src/constants/measures";
import {
  isServiceOrderForm,
  ProductOrderForm,
  ServiceOrderForm,
} from "src/hooks/order/useOrderForm/props";
import { formatDecimalInput, formatPrice } from "src/utils/format";

import { itemsSchema } from "./schema";

interface AddOrEditItemFormProps {
  item: ProductOrderForm | ServiceOrderForm;
  setProductOrder: (productOrder: ProductOrderForm) => void;
  setServiceOrder: (serviceOrder: ServiceOrderForm) => void;
  removeProductOrder: (index: number) => void;
  removeServiceOrder: (index: number) => void;
}

type ItemsSchema = yup.InferType<typeof itemsSchema>;

export function AddOrEditItemForm({
  item,
  setProductOrder,
  setServiceOrder,
  removeProductOrder,
  removeServiceOrder,
}: AddOrEditItemFormProps) {
  const data = useMemo(() => {
    if (isServiceOrderForm(item)) {
      return {
        ...item,
        name: item.service?.name,
        url: item.service?.image,
        quantity: item.quantity,
        price: item.price,
      };
    }
    return {
      ...item,
      name: item.product?.name,
      url: item.product?.image,
      quantity: item.quantity,
      price: item.price,
    };
  }, [item]);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ItemsSchema>({
    defaultValues: {
      quantity: data.quantity,
      price: data.price,
    },
    resolver: yupResolver(itemsSchema),
  });

  useEffect(() => {
    reset({
      quantity: data.quantity,
      price: data.price,
    });
  }, [data]);

  const quantity = useWatch({
    name: "quantity",
    control,
  });

  const price = useWatch({
    name: "price",
    control,
  });

  const totalPrice = useMemo(() => {
    return Number(quantity ?? 1) * Number(price ?? 0);
  }, [quantity, price]);

  const onSubmit = ({ quantity, price }: ItemsSchema) => {
    if (isServiceOrderForm(item)) {
      setServiceOrder({
        ...item,
        quantity: Number(quantity ?? 1),
        price: Number(price ?? data.price),
      });
    } else {
      setProductOrder({
        ...item,
        quantity: Number(quantity ?? 1),
        price: Number(price ?? data.price),
      });
    }
  };

  return (
    <>
      {item.index === undefined ? (
        <WrapItem w="100%" pb={2}>
          <Text w="100%" fontWeight={500} lineHeight={1}>
            {isServiceOrderForm(item) ? "Serviço" : "Produto"}
          </Text>
        </WrapItem>
      ) : null}
      <Tr
        {...(item.index === undefined && {
          w: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          overflowX: {
            base: "auto",
            xl: "unset",
          },
          overflowY: "hidden",
        })}
      >
        <Td minW="200px" w="30%">
          <Wrap>
            <Avatar
              size="xl"
              name={data.name}
              borderRadius={10}
              src={data.url}
              mr={5}
            />
            <Box w="min-content">
              <Text color="primary.500" fontWeight={400} noOfLines={2}>
                {data.name}
              </Text>
              <Text fontSize="xs" fontWeight={700} noOfLines={1}>
                {isServiceOrderForm(item)
                  ? item.service?.unitOfMeasure
                    ? SERVICES_MEASURES[item.service?.unitOfMeasure]
                    : ""
                  : item.product?.unitOfMeasure
                  ? PRODUCTS_MEASURES[item.product?.unitOfMeasure]
                  : ""}
              </Text>
            </Box>
          </Wrap>
        </Td>
        <Td
          minW={{
            base: "200px",
            md: "150px",
          }}
        >
          <Box w="80%">
            <Input
              error={errors.quantity}
              autoComplete="off"
              label="Quantidade"
              autoFocus
              {...register("quantity", {
                onChange: e => {
                  e.target.value = formatDecimalInput({
                    value: e.target.value,
                    beforeRange: 9,
                    range: 3,
                  });
                },
                onBlur: e => {
                  e.target.value = formatDecimalInput({
                    value: e.target.value,
                    beforeRange: 9,
                    range: 3,
                  });
                },
              })}
            />
          </Box>
        </Td>
        <Td
          minW={{
            base: "200px",
            md: "150px",
          }}
        >
          <Box w="80%">
            <CurrencyInput
              label="Preço Un."
              error={errors.price}
              control={control}
              name="price"
            />
          </Box>
        </Td>
        <Td
          minW={{
            base: "200px",
            md: "150px",
          }}
        >
          <Box w="80%">
            <Input
              label="Total"
              value={formatPrice(totalPrice)}
              isDisabled
              name="totalPrice"
            />
          </Box>
        </Td>
        <Td textAlign="right">
          <Flex flexDirection="column" alignItems="flex-end" gap={2}>
            {data.index !== undefined ? (
              <Button
                w="100px"
                colorScheme="error"
                variant="outline"
                onClick={() => {
                  if (isServiceOrderForm(item)) {
                    removeServiceOrder(data.index as number);
                  } else {
                    removeProductOrder(data.index as number);
                  }
                }}
              >
                Remover
              </Button>
            ) : null}
            <Button w="100px" onClick={() => handleSubmit(onSubmit)()}>
              {data.index !== undefined ? "Atualizar" : "Adicionar"}
            </Button>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
