import styled from "styled-components";

import Dialog from "@material-ui/core/Dialog";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIconUnstyled from "@material-ui/icons/CloudUpload";

import Button from "src/components/Button";

import { ImageBackgroundProps } from "./props";

export const UploadImageButton = styled.button`
  width: 100%;
  height: 150px;
  background-color: #e4f1fa;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px dashed ${({ theme }) => theme.palette.primary.dark};
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const Separator = styled.span`
  width: 10px;
`;

export const PreviewPhoto = styled.img`
  width: 100%;
  height: 250px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px dashed ${({ theme }) => theme.palette.primary.dark};
  object-fit: cover;
`;

export const PhotoContainer = styled.div`
  div {
    height: 30px;
    width: 30px;
    background-color: red;
    :hover {
      display: flex;
      background-color: blue;
    }
  }
`;

export const Photo = styled.div<ImageBackgroundProps>`
  width: 100%;
  height: 120px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-size: cover;
  background-image: url(${({ imageUrl }) => imageUrl});

  div {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;

    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    button {
      display: none;
    }

    :hover {
      display: flex;
      background-color: rgba(1, 1, 1, 0.2);
      transition: 0.5s ease;

      button {
        display: block;
      }
    }
  }
`;

export const ActionButton = styled.button`
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0px;
  border: none;
  cursor: pointer;
`;

export const PhotoIcon = styled(AddPhotoAlternateIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 50px;
`;

export const UploadIcon = styled(CloudUploadIconUnstyled)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 40px;
`;

export const CloseButton = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export const LeftSpace = styled.div`
  width: 30px;
`;

export const AddPictureButton = styled(Button)`
  svg {
    margin-right: 10px;
  }
`;

export const Modal = styled(Dialog)`
  .MuiDialog-paper {
    overflow: hidden;
    padding: 20px 30px;
  }
`;
