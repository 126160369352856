import styled from "styled-components";

import TextField from "@material-ui/core/TextField";

import Button from "src/components/Button";

export const DeleteButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-color: #e54848;
  color: #e54848;
`;

export const AutocompleteField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  .MuiIconButton-label {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
