/* eslint-disable no-unused-expressions */
import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Flex } from "@chakra-ui/react";
import { Divider } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import PrintIcon from "@material-ui/icons/Print";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { ModalProvider } from "src/components/Modal/useModal";
import Typography from "src/components/Typography";
import { DocumentConfigurationKeys } from "src/constants/documentConfiguration";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { useOrder } from "src/hooks/order/useOrder";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";
import { ServiceOrderSettingType } from "src/interfaces/module";
import { OrderDetailType } from "src/interfaces/order";

import CreateOrDeleteOrderRecords from "../CreateOrDeleteOrderRecords";
import CreateOrDeleteOrderStock from "../CreateOrDeleteOrderStock";
import DeleteOrder from "../DeleteOrder";
import UpdateQuickOrderModal from "../UpdateQuickOrderModal";

interface MoreOptionsOrderDetailModalProps {
  order: OrderDetailType;
  handlePrint: () => void;
  setDocumentType: React.Dispatch<
    React.SetStateAction<DocumentConfigurationKeys | "">
  >;
}

const MoreOptionsOrderDetailModal: React.FC<MoreOptionsOrderDetailModalProps> =
  ({ order, handlePrint, setDocumentType }) => {
    const navigate = useNavigate();
    const { handleOpen } = useBaseSwipeableDrawer();
    const { serviceOrderNameDefinitions } = useCompany();
    const { setOrder } = useOrder();
    const { setRestartOrderList } = useOrderFilters();
    const { serviceOrderModule } = useModule();
    const settings = serviceOrderModule?.settings as ServiceOrderSettingType;

    const detaulStyleButton = {
      justifyContent: "flex-start",
      size: "sm",
      variant: "ghost",
      color: "text",
    };

    const backToList = () => {
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    };
    const onClickDownloadOrderBudget = () => {
      handleOpen("configDocument");
      setDocumentType(`${order?.type}Budget`);
    };

    const onClickDownloadOrderDocument = () => {
      handleOpen("configDocument");
      setDocumentType(order?.type);
    };

    const PrintOrSettings = () => {
      settings?.serviceSettingsPrint
        ? handleOpen("printSettings")
        : handlePrint();
    };

    return (
      <BaseSwipeableDrawer
        tag="moreOptionsOrderDetailModal"
        title="Mais opções"
        zIndex={1299}
        dialogHeaderProps={{
          style: {
            paddingBottom: 0,
          },
        }}
        dialogContentProps={{
          style: {
            padding: 16,
          },
        }}
        customActions={<></>}
      >
        <Box px={2} maxWidth="450px">
          <Typography variant="body2" color="textSecondary">
            Selecione abaixo as ações que você deseja realizar
          </Typography>
          <Box mb={2} mt={2}>
            <Divider />
          </Box>
        </Box>
        <Flex direction="column" gap={2}>
          <Button
            {...detaulStyleButton}
            leftIcon={<PrintIcon color="primary" fontSize="small" />}
            onClick={() => PrintOrSettings()}
          >
            Imprimir
          </Button>
          <DeleteOrder order={order} afterActions={backToList} />
          <Button
            {...detaulStyleButton}
            leftIcon={<Edit color="primary" fontSize="small" />}
            onClick={() => handleOpen("updateQuickOrder")}
          >
            Edição rápida
          </Button>
          <UpdateQuickOrderModal
            order={order}
            onSuccess={order => {
              setOrder(prev => ({
                ...prev,
                deadline: order.deadline,
                additionalInfo: order.additionalInfo,
                operator: order.operator,
              }));
              setRestartOrderList(true);
            }}
          />
          <Button
            {...detaulStyleButton}
            leftIcon={<ArrowDownwardIcon color="primary" fontSize="small" />}
            onClick={onClickDownloadOrderBudget}
          >
            Baixar orçamento em PDF
          </Button>
          <Button
            {...detaulStyleButton}
            leftIcon={<ArrowDownwardIcon color="primary" fontSize="small" />}
            onClick={onClickDownloadOrderDocument}
          >
            Baixar{" "}
            {order?.type === "serviceOrder"
              ? serviceOrderNameDefinitions.title.toLowerCase()
              : "pedido de venda"}{" "}
            em PDF
          </Button>
          <Box mb={1} mt={1}>
            <Divider />
          </Box>
          <ModalProvider>
            <CreateOrDeleteOrderRecords order={order} showType="button" />
          </ModalProvider>
          <ModalProvider>
            <CreateOrDeleteOrderStock order={order} showType="button" />
          </ModalProvider>
        </Flex>
      </BaseSwipeableDrawer>
    );
  };

export default MoreOptionsOrderDetailModal;
