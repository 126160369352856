import styled from "styled-components";

import { Typography } from "@material-ui/core";
import GridUnstyled from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AddCircleIconUnstyled from "@material-ui/icons/AddCircle";
import BlockIconUnstyled from "@material-ui/icons/Block";

import AutoComplete from "src/components/Inputs/AutoComplete";
import CurrencyFieldBase from "src/components/Inputs/CurrencyField";
import MUITextField from "src/components/Inputs/TextField";

const RADIUS_CARD = {
  topLeft: "8px 0 0 0",
  topRight: "0 8px 0 0",
  bottomLeft: "0 0 0 8px",
  bottomRight: "0 0 8px 0",
};

export const Container = styled(Paper)`
  box-shadow: none;
  border: 1.5px solid #d6d8dc;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: 15px;
`;

export const AddCircleIcon = styled(AddCircleIconUnstyled)`
  margin-right: 10px;
`;

export const CancelIcon = styled(BlockIconUnstyled)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const Grid = styled(GridUnstyled)<{
  center?: boolean;
  radiusType?: keyof typeof RADIUS_CARD;
}>`
  display: flex;
  align-items: ${({ center }) => (center ? "center" : "initial")};
  justify-content: ${({ center }) => (center ? "center" : "initial")};
  border-radius: ${({ radiusType }) =>
    radiusType ? RADIUS_CARD[radiusType] : "0"};
  border: #d6d8dc 1px solid;
  padding: 8px;
`;

export const SearchProduct = styled(AutoComplete)`
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const TextField = styled(MUITextField)`
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const CurrencyField = styled(CurrencyFieldBase)`
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const TypographyNumber = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
`;
