import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import {
  GetOrdersStatusParamType,
  GetOrdersStatusResponseType,
  OrderStatusType,
} from "src/interfaces/order";
import { updateOrderStatus, getOrderStatus } from "src/services/order";
import { sumArrayValues } from "src/utils/arrayUtil";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";

import { useOrder } from "../useOrder";
import { StatusOrderContextType } from "./props";

const StatusOrderContext = createContext({} as StatusOrderContextType);

export const StatusOrderProvider: React.FC = props => {
  const { children } = props;

  const [getRequestStatus, setGetRequestStatus] = useState(STATUS.inital);
  const [updateRequestStatus, setUpdateRequestStatus] = useState(STATUS.inital);

  const [updateOrderId, setUpdateOrderId] = useState<string | null>(null);

  const { order, setOrder } = useOrder();

  const [ordersStatus, setOrdersStatus] = useState<GetOrdersStatusResponseType>(
    []
  );

  const getStatus = async (params: GetOrdersStatusParamType) => {
    if (getRequestStatus.loading) return;
    try {
      setGetRequestStatus(STATUS.loading);

      const response: GetOrdersStatusResponseType = await getOrderStatus({
        ...params,
        ...(params.startDate && {
          startDate: formatStartDateISO(params.startDate),
        }),
        ...(params.endDate && { endDate: formatEndDateISO(params.endDate) }),
      });

      const defaultOption: OrderStatusType = {
        _id: "",
        name: "Todos",
        orderCount: sumArrayValues(response.map(item => item.orderCount)),
        orderTotalAmount: sumArrayValues(
          response.map(item => item.orderTotalAmount)
        ),
        type: "success",
      };

      const statuses = [defaultOption, ...response];

      setOrdersStatus(statuses);

      setGetRequestStatus(STATUS.success);
    } catch (e) {
      setOrdersStatus([]);
      setGetRequestStatus(STATUS.error);
    }
  };

  const updateStatus = async (orderId: string, status: string) => {
    try {
      setUpdateRequestStatus(STATUS.loading);
      setUpdateOrderId(orderId);

      const data = await updateOrderStatus(orderId, status);

      if (order?.id) {
        const orderResult = {
          ...order,
          status: data.status,
          statusName: data.statusName,
        };
        setOrder(orderResult);
      }

      setUpdateRequestStatus(STATUS.success);
      toast.success("Status alterado com sucesso!");
    } catch (e) {
      setUpdateRequestStatus(STATUS.error);
      toast.error(`Ocorreu um problema esse status`);
    } finally {
      setUpdateOrderId(null);
      setUpdateRequestStatus(STATUS.inital);
    }
  };

  return (
    <StatusOrderContext.Provider
      value={{
        ordersStatus,

        getStatus,
        getRequestStatus,

        updateStatus,
        updateRequestStatus,
        updateOrderId,
      }}
    >
      {children}
    </StatusOrderContext.Provider>
  );
};

export const useStatusOrder = () => {
  const context = useContext(StatusOrderContext);

  if (!context) {
    throw new Error("useStatusOrder must be used within a StatusOrderProvider");
  }

  return context;
};
