import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { FinancialAccountType } from "src/interfaces/financialAccount";
import api from "src/services/api";

interface ActiveFinancialAccountsType {
  status: "enabled";
  search?: string;
}

export const getActiveFinancialAccounts = (
  params: ActiveFinancialAccountsType
) =>
  api
    .get<FinancialAccountType[]>("v1/financial-account", {
      params,
    })
    .then(res => res.data);

export function useActiveFinancialAccounts(
  search?: string,
  config?: UseQueryOptions<
    FinancialAccountType[],
    Error,
    FinancialAccountType[],
    unknown[]
  >
) {
  const params: ActiveFinancialAccountsType = {
    status: "enabled",
    search,
  };

  return useQuery<
    FinancialAccountType[],
    Error,
    FinancialAccountType[],
    unknown[]
  >(
    ["activeFinancialAccounts", params],
    () => getActiveFinancialAccounts(params),
    {
      refetchOnWindowFocus: false,
      ...(config || {}),
      initialData: [] as FinancialAccountType[],
    }
  );
}
