import React, { FC, useMemo } from "react";

import Box from "@material-ui/core/Box";

import { PASSWORD_STATUS_METER } from "src/constants/password";
import { REGEX } from "src/constants/regex";
import { PasswordStatusType } from "src/interfaces/password";

import { PasswordStrengthMeterProps } from "./props";
import * as Styled from "./styles";

const PasswordStrengthMeter: FC<PasswordStrengthMeterProps> = props => {
  const { password, confirmationPassword, setValid } = props;

  const condition = {
    initial:
      password.length < 6 ||
      confirmationPassword.length < 6 ||
      password !== confirmationPassword,

    specialCharacters:
      REGEX.containSpecialCharacter.test(password) &&
      REGEX.containSpecialCharacter.test(confirmationPassword),

    capitalCharacters:
      REGEX.containCapitalCharacter.test(password) &&
      REGEX.containCapitalCharacter.test(confirmationPassword),

    smallCharacters:
      REGEX.containSmallCharacter.test(password) &&
      REGEX.containSmallCharacter.test(confirmationPassword),
  };

  const status: PasswordStatusType = useMemo(() => {
    if (condition.initial) {
      if (setValid) setValid(false);
      return PASSWORD_STATUS_METER.empty;
    }

    if (setValid) setValid(true);

    if (
      condition.capitalCharacters &&
      condition.smallCharacters &&
      condition.specialCharacters
    ) {
      return PASSWORD_STATUS_METER.stronger;
    }

    if (
      condition.capitalCharacters &&
      condition.smallCharacters &&
      !condition.specialCharacters
    ) {
      return PASSWORD_STATUS_METER.insecure;
    }

    if (
      (condition.capitalCharacters || condition.smallCharacters) &&
      condition.specialCharacters
    ) {
      return PASSWORD_STATUS_METER.secure;
    }
    if (
      condition.capitalCharacters ||
      condition.smallCharacters ||
      condition.specialCharacters
    ) {
      return PASSWORD_STATUS_METER.lower;
    }

    return PASSWORD_STATUS_METER.insecure;
  }, [password, confirmationPassword]);

  return (
    <Styled.Container>
      <Box display="flex" flexDirection="row">
        <Styled.LabelText>Senha</Styled.LabelText>
        <Styled.PasswordMeterText uppercase colorText={status.color}>
          {status.label}
        </Styled.PasswordMeterText>
      </Box>
      <Styled.PasswordMeterStatus
        variant="determinate"
        value={status.value}
        colorBar={status.color}
      />
    </Styled.Container>
  );
};

export default PasswordStrengthMeter;
