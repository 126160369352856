import React, { FC, useMemo } from "react";

import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";
import { format } from "date-fns";

import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { CategoryRecordType } from "src/interfaces/categoryRecord";
import { ContactType } from "src/interfaces/contact";
import { PaymentMethodType } from "src/interfaces/payment";
import { RecordType } from "src/interfaces/record";
import { GetReportRecordType } from "src/interfaces/report";
import { formatPrice, maskDocument } from "src/utils/format";

export const renderContactNameWithDocument = (contact?: ContactType) => {
  if (!contact) {
    return "-";
  }

  if (contact?.cpf) {
    return `${contact.name} - ${maskDocument(contact?.cpf)}`;
  }

  if (contact?.cnpj) {
    return `${contact.name} - ${maskDocument(contact?.cnpj)}`;
  }

  return contact?.name ?? "-";
};

export const renderContactEmail = (contact?: ContactType) => {
  if (!contact) {
    return "-";
  }

  if (contact?.email && contact?.email.length > 0) {
    return `${contact?.email}`;
  }

  return "-";
};

const renderTypeRecord = (params: GridValueFormatterParams) => {
  if (params.row?.type === "income") {
    return "Receita";
  }
  if (params.row?.type === "expense") {
    return "Despesa";
  }
  return "Transferência";
};

const renderTitleRelease = (record?: RecordType) => {
  if (record?.description === "") {
    if (record.order?.type === "serviceOrder") {
      return `OS ${record.order.code}` ?? "-";
    }
    if (record.order?.type === "productOrder") {
      return `Recebimento Pedido ${record.order.code}` ?? "-";
    }
  }

  return record?.description ?? "-";
};

export interface RecordsTableProps {
  reportRecords: GetReportRecordType;
}

const RecordsTable: FC<RecordsTableProps> = ({ reportRecords }) => {
  const { result: records } = reportRecords;
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState<number>(records.length);

  const columns = useMemo(() => {
    const hasAnyOrder = records.some(record => !!record.order);
    const hasAnyFinancialAccount = records.some(
      record => !!record.financialAccount
    );

    return [
      {
        field: "emissionDate",
        headerName: "Data de emissão",
        renderCell: (params: any) =>
          params.row?.emissionDate
            ? format(new Date(params.row?.emissionDate), "dd/MM/yyyy")
            : "-",
        sortComparator: (v1: string, v2: string) => {
          const name = (v1 as string)?.toLowerCase() ?? "-";
          const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

          if (name < nameComparable) {
            return -1;
          }
          if (name > nameComparable) {
            return 1;
          }
          return 0;
        },
        width: 180,
      },
      {
        field: "type",
        headerName: "Tipo",
        renderCell: renderTypeRecord,
        width: 120,
        sortComparator: (v1: ContactType, v2: ContactType) => {
          const name = (v1 as ContactType)?.name?.toLowerCase() ?? "-";
          const nameComparable =
            (v2 as ContactType)?.name?.toLowerCase() ?? "-";

          if (name < nameComparable) {
            return -1;
          }
          if (name > nameComparable) {
            return 1;
          }
          return 0;
        },
      },
      {
        field: "paidDate",
        headerName: "Data de pagamento",
        renderCell: (params: any) =>
          params.row?.paidDate
            ? format(new Date(params.row?.paidDate), "dd/MM/yyyy")
            : "-",
        width: 190,
        sortComparator: (v1: string, v2: string) => {
          const name = (v1 as string)?.toLowerCase() ?? "-";
          const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

          if (name < nameComparable) {
            return -1;
          }
          if (name > nameComparable) {
            return 1;
          }
          return 0;
        },
      },
      {
        field: "category",
        headerName: "Categoria",
        renderCell: (params: any) => params.row?.category?.name ?? "-",
        width: 200,
        sortComparator: (v1: CategoryRecordType, v2: CategoryRecordType) => {
          const category = (v1 as CategoryRecordType).name.toLowerCase();
          const categoryComparable = (
            v2 as CategoryRecordType
          ).name.toLowerCase();

          if (category < categoryComparable) {
            return -1;
          }
          if (category > categoryComparable) {
            return 1;
          }
          return 0;
        },
      },
      {
        field: "paymentMethod",
        headerName: "Forma de pagamento",
        renderCell: (params: any) => params.row?.paymentMethod?.title ?? "-",
        width: 200,
        sortComparator: (v1: PaymentMethodType, v2: PaymentMethodType) => {
          const paymentMethod = (v1 as PaymentMethodType).title.toLowerCase();
          const paymentMethodComparable = (
            v2 as PaymentMethodType
          ).title.toLowerCase();

          if (paymentMethod < paymentMethodComparable) {
            return -1;
          }
          if (paymentMethod > paymentMethodComparable) {
            return 1;
          }
          return 0;
        },
      },
      {
        field: "paidStatus",
        headerName: "Status",
        renderCell: (params: any) =>
          params.row?.paidStatus === "paid" ? "Pago" : "Não pago",
        width: 120,
      },
      {
        field: "value",
        headerName: "Valor",
        renderCell: (params: any) => formatPrice(params.value),
        sortComparator: (v1: number, v2: number) =>
          (v1 as number) - (v2 as number),
        width: 120,
      },
      hasAnyFinancialAccount && [
        {
          field: "financialAccount",
          headerName: "Conta",
          renderCell: (params: any) =>
            params.row?.financialAccount?.name ?? "-",
          sortComparator: (v1: string, v2: string) => {
            const name = (v1 as string)?.toLowerCase() ?? "-";
            const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

            if (name < nameComparable) {
              return -1;
            }
            if (name > nameComparable) {
              return 1;
            }
            return 0;
          },
          width: 120,
        },
      ],
      hasAnyOrder && [
        {
          field: "orderCode",
          headerName: "Título de lançamento",
          renderCell: (params: any) =>
            renderTitleRelease(params.row as RecordType),
          sortComparator: (v1: number, v2: number) =>
            (v1 as number) - (v2 as number),
          width: 200,
        },
        {
          field: "contact",
          headerName: "Cliente",
          renderCell: (params: any) =>
            renderContactNameWithDocument(params.row?.order?.contact),
          sortComparator: (v1: string, v2: string) => {
            const name = (v1 as string)?.toLowerCase() ?? "-";
            const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

            if (name < nameComparable) {
              return -1;
            }
            if (name > nameComparable) {
              return 1;
            }
            return 0;
          },
          width: 200,
        },
      ],
    ]
      .filter(col => col)
      .flat();
  }, []);

  return (
    <Table
      sortingOrder={["desc", "asc"]}
      rows={records}
      columns={columns as GridColDef[]}
      page={page}
      setPage={setPage}
      pageSize={limit}
      onPageSizeChange={setLimit}
      filterMode="server"
      totalCount={records.length}
      hideFooter
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <Typography>Nenhuma relatório disponível</Typography>
          </EmptyTable>
        ),
      }}
    />
  );
};

export default RecordsTable;
