import React, { useState } from "react";

import { GridColDef } from "@material-ui/data-grid";

import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import { useUsers } from "src/services/hooks/user/useUsers";
import { generateLocaleGridText } from "src/utils/localGridText";

interface UserTableProps {
  search: string;
  status: string;
}

function UserTable({ search, status }: UserTableProps) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);
  const { userCompany } = useCompany();

  const { data: rows, isLoading } = useUsers({
    limit,
    page,
    status,
    ...(search.length && { search }),
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 4,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 3,
    },
    {
      field: "username",
      headerName: "Usuário",
      valueFormatter: ({ row }) => `${row?.username}@${userCompany?.slug}`,
      flex: 2,
    },
  ];

  return (
    <Table
      rows={rows?.users || []}
      columns={columns}
      page={page}
      setPage={setPage}
      pageSize={limit}
      onPageSizeChange={setLimit}
      filterMode="server"
      loading={isLoading}
      totalCount={rows?.totalCount ?? 0}
      localeText={generateLocaleGridText("users")}
      detailLink={PATHS.SETTINGS_USER_UPDATE}
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>{/* <EmptyContactTable /> */}</EmptyTable>
        ),
      }}
    />
  );
}

export default UserTable;
