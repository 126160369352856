import React, { FC, useMemo, useRef, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { add, startOfDay } from "date-fns";
import { Form, Formik, FormikProps } from "formik";

import Button from "src/components/Button";
import CloseButton from "src/components/CommonButtons/CloseButton";
import KeyboardDatePicker from "src/components/Inputs/KeyboardDatePicker";
import KeyboardTimePicker from "src/components/Inputs/KeyboardTimerPicker";
import Textarea from "src/components/Inputs/Textarea";
import TextField from "src/components/Inputs/TextField";
import Typography from "src/components/Typography";
import { useSchedulingCreate } from "src/hooks/scheduling/create/useSchedulingCreate";
import { useSchedulingUpdate } from "src/hooks/scheduling/update/useSchedulingUpdate";
import { formatDateToISO } from "src/utils/date";

import { DataType, SchedulingFormProps } from "./props";
import { schemaSchedulingForm } from "./schema";

const SchedulingForm: FC<SchedulingFormProps> = props => {
  const { method, data, open, handleClose } = props;

  const formikRef = useRef<FormikProps<DataType>>(null);

  const { status: statusCreate, create } = useSchedulingCreate();
  const { status: statusUpdate, update } = useSchedulingUpdate();

  useEffect(() => {
    if (!open) {
      formikRef.current?.resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (statusCreate.success || statusUpdate.success) {
      handleClose();
    }
  }, [statusCreate, statusUpdate]);

  const title = useMemo(() => {
    if (method === "add") {
      return "Novo compromisso";
    }

    return "Atualizar despesa";
  }, [method]);

  const onSubmit = async (dataForm: DataType) => {
    const hoursToAdd = new Date(dataForm.hour).getHours();

    const minutesToAdd = new Date(dataForm.hour).getMinutes();

    const startDayOfDate = startOfDay(new Date(dataForm.date));

    const startDate = add(startDayOfDate, {
      hours: hoursToAdd,
      minutes: minutesToAdd,
    });

    if (method === "add") {
      create({
        title: dataForm.title,
        place: dataForm?.place,
        startDate: formatDateToISO(startDate),
        description: dataForm?.description,
      });
    }

    if (method === "update") {
      update({
        ...data,
        title: dataForm.title,
        place: dataForm?.place,
        startDate: formatDateToISO(startDate),
        description: dataForm?.description,
      });
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={
        {
          id: data?.id || "",
          title: data?.title || "",
          place: data?.place || "",
          date: data?.startDate,
          hour: data?.startDate,
          description: data?.description || "",
        } as DataType
      }
      enableReinitialize
      validationSchema={schemaSchedulingForm}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
    >
      <Form>
        <Box
          display="flex"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box width={40} />
          <Typography color="primary" variant="h6">
            {title}
          </Typography>
          <CloseButton onClick={handleClose} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Título" name="title" />
          </Grid>

          <Grid item xs={12}>
            <TextField label="Local" name="place" />
          </Grid>

          <Grid item xs={6}>
            <KeyboardDatePicker label="Data" name="date" />
          </Grid>

          <Grid item xs={6}>
            <KeyboardTimePicker label="Hora" name="hour" />
          </Grid>

          <Grid item xs={12}>
            <Textarea label="Descrição" name="description" rows={3} />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              loading={statusCreate.loading || statusUpdate.loading}
              disabled={statusCreate.loading || statusUpdate.loading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default SchedulingForm;
