import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Flex, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { add, startOfDay } from "date-fns";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { DatePicker } from "src/components/NewComponents/DatePicker";
import { Input } from "src/components/NewComponents/Input";
import { Textarea } from "src/components/NewComponents/TextArea";
import { TimePicker } from "src/components/NewComponents/TimePicker";
import { useUpdateSchedule } from "src/services/hooks/schedule/useUpdateSchedule";
import { formatDateToISO } from "src/utils/date";

import { SchedulingUpdateModalProps } from "./props";
import { schemaForm } from "./schema";

export type RecordFormSchema = yup.InferType<typeof schemaForm>;

const SchedulingUpdateModal: React.FC<SchedulingUpdateModalProps> = props => {
  const { data } = props;
  const { handleClose } = useBaseSwipeableDrawer();
  const queryClient = useQueryClient();
  const { mutate: updateSchedule } = useUpdateSchedule({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["schedulingAllTypes"],
      });
      toast.success("Compromisso atualizado com sucesso!");
      handleClose("DrawerUpdateScheduling");
      handleClose("DetailCompromisse");
    },
  });
  const defaultValues: RecordFormSchema = {
    title: data.title,
    place: data.place as string,
    description: data.description as string,
    data: data.startDate,
    hours: data.startDate,
  };

  const methods = useForm<RecordFormSchema>({
    defaultValues,
    resolver: yupResolver(schemaForm),
  });

  const {
    register,
    formState: { errors },
    control,

    handleSubmit,
  } = methods;

  const onSubmit = async (dataform: RecordFormSchema) => {
    const startDayOfDate = startOfDay(new Date(dataform.data));

    const hoursToAdd = new Date(dataform.hours).getHours();

    const minutesToAdd = new Date(dataform.hours).getMinutes();

    const startDate = add(startDayOfDate, {
      hours: hoursToAdd,
      minutes: minutesToAdd,
    });

    updateSchedule({
      ...data,
      title: dataform.title,
      place: dataform.place,
      startDate: formatDateToISO(startDate),
      description: dataform?.description,
    });
  };

  const customHeader = (
    <Flex w="100%">
      <Flex bg="white" w="100%" justifyContent="center" alignItems="center">
        <Text color="primary.500" fontSize="md">
          Atualizar Compromisso
        </Text>
      </Flex>
    </Flex>
  );
  return (
    <BaseSwipeableDrawer
      tag="DrawerUpdateScheduling"
      buttonTitle="Salvar"
      zIndex={1302}
      customHeader={customHeader}
      buttonProps={{
        form: "UpdateCompromisse",
      }}
      customHandleClose={() => handleClose("DrawerUpdateScheduling")}
    >
      <Flex
        w={["100%", "350px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        gap={4}
        onSubmit={handleSubmit(onSubmit)}
        id="UpdateCompromisse"
        as="form"
        direction="column"
      >
        <Input label="Título" error={errors.title} {...register("title")} />
        <Input label="Local" error={errors.place} {...register("place")} />
        <Flex gap={2}>
          <DatePicker
            label="Data"
            disablePast
            error={errors.data}
            control={control}
            {...register("data")}
          />
          <TimePicker
            label="Hora"
            error={errors.hours}
            control={control}
            {...register("hours")}
          />
        </Flex>
        <Textarea
          label="Descrição"
          resize="none"
          error={errors.description}
          {...register("description")}
        />
      </Flex>
    </BaseSwipeableDrawer>
  );
};

export default SchedulingUpdateModal;
