import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface DeleteTransferParams {
  id: string;
}

export const deleteTransfer = (params: DeleteTransferParams) =>
  api.delete(`v1/transfer/${params.id}`).then(res => res.data);

export function useDeleteTransfer(
  config?: UseMutationOptions<any, any, DeleteTransferParams, unknown>
) {
  return useMutation<any, any, DeleteTransferParams>(
    (data: DeleteTransferParams) => deleteTransfer(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ??
            "Ocorreu um problema ao deletar transferência"
        );
      },
      ...config,
    }
  );
}
