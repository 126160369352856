import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import api from "src/services/api";

interface SalesByPeriodReportParams {
  startDate: string;
  endDate: string;
  operatorId?: string;
}

export interface SalesByPeriodSale {
  count: number;
  totalAmount: number;
  label: string;
}

interface GetSalesByPeriodReportResponse {
  result: Array<SalesByPeriodSale>;
  totals: {
    totalAmount: number;
    count: number;
  };
}

export const salesByPeriodReport = (params: SalesByPeriodReportParams) =>
  api
    .get<GetSalesByPeriodReportResponse>("/v1/report/sales-by-period", {
      params,
    })
    .then(res => res.data);

export function useSalesByPeriodReport(
  params: SalesByPeriodReportParams,
  config?: UseQueryOptions<
    GetSalesByPeriodReportResponse,
    Error,
    GetSalesByPeriodReportResponse,
    unknown[]
  >
) {
  return useQuery<
    GetSalesByPeriodReportResponse,
    Error,
    GetSalesByPeriodReportResponse,
    unknown[]
  >(["salesByPeriodReport", params], () => salesByPeriodReport(params), {
    refetchOnWindowFocus: false,
    ...(config || {}),
    initialData: {
      result: [],
      totals: {
        totalAmount: 0,
        count: 0,
      },
    } as GetSalesByPeriodReportResponse,
  });
}
