import styled from "styled-components";

import MuiPaper from "@material-ui/core/Paper";
import InfoIcon from "@material-ui/icons/Info";

import Button from "src/components/Button";
import { boxShadow } from "src/styles/mixins/shadows";

export const Container = styled(MuiPaper)`
  ${boxShadow}
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 600px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AlertIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 32px;
  margin: 0 auto;
`;

export const DeleteButton = styled(Button)`
  background-color: #e54848;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 15px;
  font-weight: 500;

  :hover {
    background-color: ${({ theme }) => theme.palette.error.main};
  }

  &.MuiButton-contained.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.error.main};
    opacity: 0.5;
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const DeleteButtonOutlined = styled(Button)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-color: #e54848;
  color: #e54848;
  font-size: 15px;
  font-weight: 500;
  :hover {
    border-color: #e54848;
  }
`;
