import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface AddModuleCompanyType {
  id: string;
}

export const AddModuleCompany = ({ id }: AddModuleCompanyType) =>
  api.post("/v1/module/subscribe", { id }).then(res => res.data);

export function useAddModuleCompany(
  config?: UseMutationOptions<any, any, AddModuleCompanyType, unknown>
) {
  return useMutation<any, any, AddModuleCompanyType>(
    (data: AddModuleCompanyType) => AddModuleCompany(data),
    {
      onError: ({ response }) => {
        toast.error(response?.data?.message ?? "Ocorreu um problema");
      },
      ...config,
    }
  );
}
