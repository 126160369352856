import React from "react";

import { Box, Text, Divider } from "@chakra-ui/react";

import PermissionActionSwitch from "./components/PermissionActionSwitch";
import PermissionsAbility from "./components/PermissionsAbility";

export function ReportPermissions() {
  return (
    <Box>
      <Text fontSize="sm" color="gray.500">
        Gerenciar ações
      </Text>

      <Divider my={4} />

      <PermissionActionSwitch
        permissions={[
          "reportFinancialDre",
          "reportFinancialPredictedAndAccomplished",
          "reportFinancialRecords",
          "reportPaymentMethods",
          "reportCashFlowControl",
          "reportInventory",
        ]}
        title="Gerenciar relatórios financeiros"
        subtitle="O usuário pode visualizar ou exportar relatórios financeiros"
      />

      <PermissionActionSwitch
        permissions={[
          "reportOrderProducts",
          "reportOrderServices",
          "reportSalesByPeriod",
          "reportOrderComission",
        ]}
        title="Gerenciar relatórios de vendas"
        subtitle="O usuário pode visualizar ou exportar relatórios de vendas"
      />

      <PermissionActionSwitch
        permissions={[
          "reportSalesByClient",
          "reportLateClients",
          "reportClients",
          "reportProviders",
        ]}
        title="Gerenciar relatórios de clientes"
        subtitle="O usuário pode visualizar ou exportar relatórios de clientes"
      />

      <Divider my={4} />

      <Text fontSize="sm" color="gray.500">
        Relatórios financeiros
      </Text>

      <PermissionsAbility
        permission="reportFinancialDre"
        title="Demonstração do Resultado do Exercício - DRE"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportFinancialPredictedAndAccomplished"
        title="Previsto x Realizado"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportFinancialRecords"
        title="Lançamentos"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportPaymentMethods"
        title="Formas de pagamento"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportCashFlowControl"
        title="Controle de caixa"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportInventory"
        title="Inventário"
        subtitle="O usuário pode"
      />

      <Divider my={4} />

      <Text fontSize="sm" color="gray.500">
        Relatórios de vendas
      </Text>

      <PermissionsAbility
        permission="reportOrderProducts"
        title="Produtos"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportOrderServices"
        title="Serviços"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportSalesByPeriod"
        title="Vendas por período"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportOrderComission"
        title="Comissão de vendedores"
        subtitle="O usuário pode"
      />

      <Divider my={4} />

      <Text fontSize="sm" color="gray.500">
        Relatórios de clientes
      </Text>

      <PermissionsAbility
        permission="reportSalesByClient"
        title="Vendas por cliente"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportLateClients"
        title="Clientes atrasados"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportClients"
        title="Clientes"
        subtitle="O usuário pode"
      />

      <PermissionsAbility
        permission="reportProviders"
        title="Fornecedores"
        subtitle="O usuário pode"
      />
    </Box>
  );
}
