import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { FinancialAccountType } from "src/interfaces/financialAccount";
import api from "src/services/api";

export interface ChargebackRecordParams {
  id: string;
}

export const chargebackRecord = (params: ChargebackRecordParams) =>
  api
    .post<FinancialAccountType>(`v1/record/${params.id}/chargeback`)
    .then(res => res.data);

export function useChargebackRecord(
  config?: UseMutationOptions<any, any, ChargebackRecordParams, unknown>
) {
  return useMutation<any, any, ChargebackRecordParams>(
    (data: ChargebackRecordParams) => chargebackRecord(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ??
            "Ocorreu um problema ao estornar o lançamento"
        );
      },
      ...config,
    }
  );
}
