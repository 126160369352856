import React from "react";

import Switch from "@material-ui/core/Switch";
import { useFormikContext } from "formik";

import Typography from "src/components/Typography";
import { ServiceOrderSettingType } from "src/interfaces/module";

import { SwitchManageStockOptionProps } from "./props";
import * as Styled from "./styles";

const SwitchManageStock: React.FC<SwitchManageStockOptionProps> = props => {
  const { name, label, ...rest } = props;

  const { values, setFieldValue } = useFormikContext<ServiceOrderSettingType>();

  const handleChange = () => {
    setFieldValue(name, !values[name as keyof ServiceOrderSettingType]);
  };

  return (
    <Styled.SwitchManageStockContainer>
      <Typography variant="subtitle1" color="primary">
        {label}
      </Typography>
      <Switch
        checked={!!values[name as keyof ServiceOrderSettingType]}
        onChange={handleChange}
        name={name}
        color="primary"
        inputProps={{ "aria-label": label }}
        {...rest}
      />
    </Styled.SwitchManageStockContainer>
  );
};

export default SwitchManageStock;
