import { StylesConfig } from "react-select";

export const getSelectStyle = ({
  error,
  placeholder,
  showFloatingLabel,
}: {
  showFloatingLabel?: boolean;
  error?: boolean;
  placeholder?: string;
}): StylesConfig<unknown> => ({
  container: (base, { isFocused }) => ({
    ...base,
    width: "100%",
    ...(isFocused
      ? {
          // boxShadow: "0px 4px 20px 0px #00000040",
          borderRadius: 10,
        }
      : {}),
  }),
  control: (base, { isFocused, hasValue, isDisabled }) => ({
    ...base,
    minHeight: "var(--chakra-sizes-12)",
    fontSize: "var(--chakra-fontSizes-md)",
    boxShadow: "none",
    borderColor:
      !isFocused && !hasValue && !error
        ? "rgba(19, 34, 62, 0.25)"
        : error
        ? "var(--chakra-colors-error-500)"
        : "var(--chakra-colors-primary-500)",
    opacity: isDisabled ? 0.5 : 1,
    backgroundColor: "white",
    color: error
      ? "var(--chakra-colors-error-500)"
      : "var(--chakra-colors-primary-500)",
    borderRadius: 10,
    "&:hover": {
      borderColor: error
        ? "var(--chakra-colors-error-600)"
        : "var(--chakra-colors-primary-600)",
    },
    zIndex: 2,
    "&::after": {
      content: `"${placeholder}"`,
      top: 0,
      left: 16,
      bottom: 0,
      zIndex: 2,
      position: "absolute",
      backgroundColor: "transparent",
      pointerEvents: "none",
      margin: "0 auto",
      display: placeholder ? "flex" : "none",
      color:
        !isFocused && !hasValue && !error
          ? "rgba(19, 34, 62, 0.7)"
          : error
          ? "var(--chakra-colors-error-500)"
          : "var(--chakra-colors-primary-500)",
      alignItems: "center",
      justifyContent: "center",
      transition: "all .2s",
      ...(isFocused || hasValue
        ? {
            content: `"${showFloatingLabel ? placeholder : ""}"`,
            lineHeight: "var(--chakra-fontSizes-xs)",
            fontSize: "var(--chakra-fontSizes-xs)",
            top: "calc(-100%)",
            left: 20,
            padding: "0 4px",
          }
        : {}),
    },
    "&::before": {
      content: `"${placeholder}"`,
      color: "transparent",
      linHeight: "1px",
      top: 0,
      left: 0,
      bottom: 0,
      zIndex: 2,
      height: "1px",
      position: "absolute",
      backgroundColor: "transparent",
      display: placeholder ? "flex" : "none",
      ...(isFocused || hasValue
        ? {
            display: showFloatingLabel && placeholder ? "flex" : "none",
            backgroundColor: "white",
            lineHeight: "var(--chakra-fontSizes-xs)",
            fontSize: "var(--chakra-fontSizes-xs)",
            top: -1,
            left: 20,
            padding: "0 4px",
          }
        : {}),
    },
  }),
  menu: base => ({
    ...base,
    margin: 0,
    padding: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: "none",
    backgroundColor: "white",
    transition: "all .2s",
    "::before": {
      content: "''",
      width: "100%",
      height: 10,
      background: "red",
      position: "absolute",
      top: -10,
      backgroundColor: "white",
    },
    zIndex: 2000,
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    fontSize: "var(--chakra-fontSizes-xs)",
    fontWeight: 500,
    height: "2rem",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    transition: "all .1s",
    color: isSelected
      ? "var(--chakra-colors-primary-500)"
      : "var(--chakra-colors-black)",
    backgroundColor: isFocused
      ? "var(--chakra-colors-gray-100)"
      : "transparent",
    "&:hover": {
      backgroundColor: isFocused
        ? "var(--chakra-colors-gray-100)"
        : "transparent",
    },
    "&:active": {
      backgroundColor: isFocused
        ? "var(--chakra-colors-gray-100)"
        : "transparent",
    },
    cursor: "pointer",
  }),
  clearIndicator: base => ({
    ...base,
    paddingRight: 0,
    cursor: "pointer",
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingRight: "0.75rem",
    cursor: "pointer",
  }),
  valueContainer: base => ({
    ...base,
    position: "unset",
    paddingInlineStart: "var(--chakra-space-4)",
    paddingInlineEnd: "var(--chakra-space-4)",
    color: error
      ? "var(--chakra-colors-error-500)"
      : "var(--chakra-colors-primary-500)",
    overflowX: "auto",
    flexWrap: "unset",
  }),
  singleValue: base => ({
    ...base,
    color: error
      ? "var(--chakra-colors-error-500)"
      : "var(--chakra-colors-primary-500)",
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "transparent",
    flex: "1 0 auto",
  }),
  multiValueLabel: base => ({
    ...base,
    backgroundColor: "var(--chakra-colors-secondary-100)",
    color: "var(--chakra-colors-primary-500)",
    fontWeight: 500,
    fontSize: "var(--chakra-fontSizes-xs)",
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    paddingTop: "var(--chakra-space-1)",
    paddingBottom: "var(--chakra-space-1)",
    paddingInlineStart: "var(--chakra-space-3)",
  }),
  multiValueRemove: base => ({
    ...base,
    backgroundColor: "var(--chakra-colors-secondary-100)",
    color: "var(--chakra-colors-primary-500)",
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "&:hover": {
      backgroundColor: "var(--chakra-colors-secondary-100)",
      color: "var(--chakra-colors-primary-500)",
    },
  }),
  noOptionsMessage: base => ({
    ...base,
    textAlign: "left",
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999,
  }),
});
