import React, { useState, createContext, useContext } from "react";

import { usePeriodFilterType } from "src/hooks/periodFilterType/usePeriodFilterType";
import { usePreferencesStorage } from "src/hooks/storage/usePreferenceStorage";
import { TypeFilterFieldDate, TypeOfOrder } from "src/interfaces/order";

import { OrderFiltersContextType } from "./props";

export const OrderFiltersContext = createContext({} as OrderFiltersContextType);

export const OrderFiltersProvider: React.FC<{ typeOrder: TypeOfOrder }> =
  props => {
    const { children, typeOrder } = props;

    const { getPeriodTypeCache } = usePeriodFilterType();

    const getDefaultFilterDate = () => {
      const initialDate =
        getPeriodTypeCache("serviceOrder") === "byDay" ? new Date() : undefined;
      return {
        startDate: initialDate,
        endDate: initialDate,
      };
    };

    const defaultFilterDate = getDefaultFilterDate();

    const { getPreferencesStorage } = usePreferencesStorage();
    const preferencesStorage = getPreferencesStorage();

    const [type, setType] = useState(typeOrder);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState<number>(
      type === "serviceOrder"
        ? preferencesStorage?.serviceOrder?.limit ?? 10
        : preferencesStorage?.productOrder?.limit ?? 10
    );

    const [search, setSearch] = useState("");
    const [status, setStatus] = useState<string>("");

    const [startDate, setStartDate] = useState<Date | undefined>(
      defaultFilterDate.startDate
    );
    const [endDate, setEndDate] = useState<Date | undefined>(
      defaultFilterDate.endDate
    );
    const [filterFieldDate, setFilterFieldDate] = useState<
      TypeFilterFieldDate | undefined
    >();

    const [restartOrderList, setRestartOrderList] = useState(false);

    return (
      <OrderFiltersContext.Provider
        value={{
          page,
          setPage,
          limit,
          setLimit,
          search,
          setSearch,
          status,
          setStatus,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
          type,
          setType,
          restartOrderList,
          setRestartOrderList,
          filterFieldDate,
          setFilterFieldDate,
        }}
      >
        {children}
      </OrderFiltersContext.Provider>
    );
  };

export const useOrderFilters = () => {
  const context = useContext(OrderFiltersContext);

  if (!context) {
    throw new Error(
      "useOrderFilters must be used within a OrderFiltersProvider"
    );
  }

  return context;
};
