import React, { useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import BaseModal from "src/components/BaseModal";
import { useBaseModal } from "src/components/BaseModal/useBaseModal";
import Typography from "src/components/Typography";
import { useFinancialAccount } from "src/hooks/financialAccount/useFinancialAccount";

import * as Styled from "./styles";

const FinancialAccountDeleteModal: React.FC = () => {
  const { financialAccount, deleteStatus, remove } = useFinancialAccount();

  const { handleClose } = useBaseModal();

  useEffect(() => {
    if (deleteStatus.success) {
      handleClose(["financialAccountDelete", "financialAccountUpdate"]);
    }
  }, [deleteStatus]);

  const onSubmit = async () => {
    if (!financialAccount) return;
    try {
      await remove(financialAccount?.id as string);
    } catch (error) {
      toast.error("Ocorreu um problema ao excluir essa conta");
    }
  };

  const baseActions = useMemo(() => {
    return (
      <Styled.DeleteButton
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        onClick={onSubmit}
        loading={deleteStatus.loading}
        disabled={deleteStatus.loading}
      >
        Excluir
      </Styled.DeleteButton>
    );
  }, [financialAccount, deleteStatus]);

  return (
    <BaseModal
      tag="financialAccountDelete"
      zIndex={1301}
      customHeader={<Styled.AlertIcon />}
      customHandleClose={() => handleClose("financialAccountDelete")}
      customActions={baseActions}
    >
      <Styled.Container>
        <Typography color="textPrimary" variant="h6" align="center">
          Tem certeza que deseja excluir esta conta?
        </Typography>
      </Styled.Container>
    </BaseModal>
  );
};

export default FinancialAccountDeleteModal;
