import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { SimpleGrid, Text } from "@chakra-ui/react";

import { CurrencyInput } from "src/components/NewComponents/CurrencyInput";
import { Input } from "src/components/NewComponents/Input";
import { Select } from "src/components/NewComponents/Select";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";
import { PAYMENT_CONDITION_ITEMS } from "src/pages/BuyOrders/components/BuyOrderForm/parts/ConditionPaymentSection";
import { DOWN_PAYMENT_ITEMS } from "src/pages/Orders/components/ConditionPaymentSection";
import { formatDecimalInput, formatPrice } from "src/utils/format";

import { OrderPaymentSchema } from "../..";

interface ConditionPaymentSectionProps {
  resetConditionPaymentConfig: (
    conditionType: string,
    customConditionType?: string
  ) => void;
}

export function ConditionPaymentSection({
  resetConditionPaymentConfig,
}: ConditionPaymentSectionProps) {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<OrderPaymentSchema>();

  const { subtotal } = useOrderForm();

  const {
    conditionType,
    paymentType,
    downPaymentType,
    downPaymentValue,
    remainingPaymentType,
    remainingPaymentValue,
  } = watch();

  const installmentValue = useMemo(() => {
    const downPayment =
      downPaymentType === "value"
        ? Number(downPaymentValue || 0)
        : (Number(downPaymentValue || 0) * subtotal) / 100;

    const installment = Number(remainingPaymentValue);

    const value = (subtotal - downPayment) / installment;

    if (!installment) {
      return "";
    }

    return formatPrice(value);
  }, [subtotal, downPaymentType, downPaymentValue, remainingPaymentValue]);

  const downPaymentValueField = useMemo(() => {
    if (downPaymentType === "value") {
      return (
        <CurrencyInput
          error={errors.downPaymentValue}
          control={control}
          name="downPaymentValue"
          bg="white"
          label="Valor da entrada"
        />
      );
    }

    if (downPaymentType === "percentage") {
      return (
        <Input
          error={errors.downPaymentValue}
          autoComplete="off"
          {...register("downPaymentValue", {
            onChange: e => {
              e.target.value = formatDecimalInput({
                value: e.target.value,
                beforeRange: 9,
                range: 2,
                maxValue: 100,
              });
            },
            onBlur: e => {
              e.target.value = formatDecimalInput({
                value: e.target.value,
                beforeRange: 9,
                range: 2,
                maxValue: 100,
              });
            },
          })}
          bg="white"
          label="Valor da entrada"
        />
      );
    }

    return null;
  }, [downPaymentType, errors]);

  const renderFields = useMemo(() => {
    if (paymentType === "A Vista") {
      return null;
    }

    if (paymentType === "50%/50%") {
      return (
        <>
          <Input
            value={formatPrice(subtotal / 2)}
            isDisabled
            name="downPaymentValue"
            bg="white"
            label="Valor da primeira"
          />
          <Input
            value={formatPrice(subtotal / 2)}
            isDisabled
            name="remainingPaymentType"
            bg="white"
            label="Valor da segunda"
          />
          <Input
            value={formatPrice(subtotal)}
            isDisabled
            name="subtotal"
            bg="white"
            label="Valor subtotal"
          />
        </>
      );
    }

    if (paymentType === "Com entrada") {
      return (
        <>
          <Controller
            control={control}
            name="downPaymentType"
            render={({ field: { ref, onChange, ...props } }) => {
              return (
                <Select
                  options={DOWN_PAYMENT_ITEMS.map(condition => ({
                    label: condition.name,
                    value: condition.value,
                  }))}
                  inputRef={ref}
                  error={!!errors.downPaymentType}
                  errorMessage={errors.downPaymentType?.message}
                  placeholder="Tipo de entrada"
                  isClearable={false}
                  onChange={value => {
                    onChange(value);
                    setValue("downPaymentValue", "");
                  }}
                  {...props}
                />
              );
            }}
          />
          {downPaymentValueField}
          <Input
            error={errors.remainingPaymentValue}
            autoComplete="off"
            {...register("remainingPaymentValue", {
              onChange: e => {
                e.target.value = formatDecimalInput({
                  value: e.target.value,
                  beforeRange: 9,
                  range: 0,
                  minValue: 1,
                });
              },
              onBlur: e => {
                e.target.value = formatDecimalInput({
                  value: e.target.value,
                  beforeRange: 9,
                  range: 0,
                  minValue: 1,
                });
              },
            })}
            bg="white"
            label="Quantidade de parcelas"
          />
          <Input
            value={installmentValue}
            isDisabled
            name="subtotal"
            bg="white"
            label="Valor das parcelas"
          />
          <Input
            value={formatPrice(subtotal)}
            isDisabled
            name="subtotal"
            bg="white"
            label="Valor subtotal"
          />
        </>
      );
    }

    if (paymentType === "Parcelado") {
      return (
        <>
          <Input
            label="Quantidade de parcelas"
            error={errors.remainingPaymentValue}
            autoComplete="off"
            {...register("remainingPaymentValue", {
              onChange: e => {
                e.target.value = formatDecimalInput({
                  value: e.target.value,
                  beforeRange: 9,
                  range: 0,
                  minValue: 1,
                });
              },
              onBlur: e => {
                e.target.value = formatDecimalInput({
                  value: e.target.value,
                  beforeRange: 9,
                  range: 0,
                  minValue: 1,
                });
              },
            })}
            bg="white"
          />
          <Input
            value={
              remainingPaymentValue && Number(remainingPaymentValue)
                ? formatPrice(subtotal / Number(remainingPaymentValue))
                : ""
            }
            isDisabled
            name="subtotal"
            bg="white"
            label="Valor das parcelas"
          />
        </>
      );
    }

    return null;
  }, [
    conditionType,
    paymentType,
    subtotal,
    downPaymentType,
    downPaymentValue,
    remainingPaymentType,
    remainingPaymentValue,
    installmentValue,
    downPaymentValueField,
    errors,
  ]);

  return (
    <>
      {conditionType === "Pagar depois" ? (
        <Text color="text" mb={2.5}>
          Condições de pagamento
        </Text>
      ) : null}

      <SimpleGrid
        columns={{
          base: 2,
          md: 3,
          xl: 4,
        }}
        gap={4}
        mt={
          conditionType === "Pagar depois"
            ? 4
            : paymentType === "A Vista"
            ? 0
            : 3
        }
        mb={2.5}
      >
        {conditionType === "Pagar depois" ? (
          <Controller
            control={control}
            name="paymentType"
            render={({ field: { ref, onChange, ...props } }) => {
              return (
                <Select
                  options={PAYMENT_CONDITION_ITEMS.map(condition => ({
                    label: condition.name,
                    value: condition.value,
                  }))}
                  inputRef={ref}
                  error={!!errors.paymentType}
                  errorMessage={errors.paymentType?.message}
                  placeholder="Condição"
                  isClearable={false}
                  onChange={value => {
                    onChange(value);
                    resetConditionPaymentConfig(
                      value as string,
                      "Pagar depois"
                    );
                  }}
                  {...props}
                />
              );
            }}
          />
        ) : null}
        {renderFields}
      </SimpleGrid>
    </>
  );
}
