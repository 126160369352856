import { FC } from "react";

import { Box, Icon, Text } from "@chakra-ui/react";
import MuiTooltip from "@material-ui/core/Tooltip";
import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";
import InfoIcon from "@material-ui/icons/Info";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { format } from "date-fns";

import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { CashFlow } from "src/interfaces/cashFlow";
import { formatPrice } from "src/utils/format";
import { generateLocaleGridText } from "src/utils/localGridText";

import MoreOptionsMenu from "../../components/MoreOptionsMenu";

const getDivergenceOfCashFlowTooltip = (
  value: number,
  hasDivergence: boolean,
  divergenceValue: number
) => {
  if (!hasDivergence) {
    return <Box>{formatPrice(value ?? 0)}</Box>;
  }

  return (
    <Box>
      {formatPrice(value ?? 0)}
      {hasDivergence ? (
        <MuiTooltip
          classes={{
            tooltip: "custom__tooltip",
          }}
          title={
            divergenceValue < 0 ? (
              <Text as="span" fontWeight={500} color="white">
                Houve uma divergência na abertura/fechamento do seu caixa, com{" "}
                {formatPrice(divergenceValue)} faltando em relação ao saldo real
                em conta.
              </Text>
            ) : (
              <Text as="span" fontWeight={500} color="white">
                Houve uma divergência na abertura/fechamento do seu caixa, com{" "}
                {formatPrice(divergenceValue)} acima do saldo real em conta
              </Text>
            )
          }
          placement="bottom-end"
          arrow
        >
          <Icon
            position="absolute"
            right={4}
            as={InfoIcon}
            color={divergenceValue < 0 ? "error.500" : "yellow.500"}
          />
        </MuiTooltip>
      ) : null}
    </Box>
  );
};

const renderOptionsButton = (params: GridValueFormatterParams) => (
  <MoreOptionsMenu id={params.id as string} cashFlow={params.row as CashFlow}>
    <MoreVertIcon color="primary" />
  </MoreOptionsMenu>
);

const columns: GridColDef[] = [
  {
    field: "financialAccount",
    headerName: "Caixa",
    renderCell: params =>
      params.row?.financialAccount ? params.row?.financialAccount?.name : "-",
    valueGetter: params =>
      params.row?.financialAccount ? params.row?.financialAccount?.name : "-",
    width: 150,
    sortComparator: (v1, v2) => {
      const name = (v1 as string)?.toLowerCase() ?? "-";
      const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

      return name.localeCompare(nameComparable);
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: params =>
      params.row?.status === "open" ? "Aberto" : "Fechado",
    valueGetter: params =>
      params.row?.status === "open" ? "Aberto" : "Fechado",
    sortComparator: (v1, v2) => {
      const document = v1 as string;
      const documentComparable = v2 as string;

      return document.localeCompare(documentComparable);
    },
  },
  {
    field: "user",
    headerName: "Vendedor",
    width: 150,
    renderCell: params => params.row?.user?.name,
    valueGetter: params => params.row?.user?.name,
    sortComparator: (v1, v2) => {
      const document = v1 as string;
      const documentComparable = v2 as string;

      return document.localeCompare(documentComparable);
    },
  },
  {
    field: "createdAt",
    headerName: "Data de abertura",
    renderCell: params =>
      params.row?.createdAt
        ? format(new Date(params.row?.createdAt), "dd/MM/yyyy - HH:mm")
        : "-",
    width: 170,
    sortComparator: (v1, v2) => {
      const name = (v1 as string)?.toLowerCase() ?? "-";
      const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

      if (name < nameComparable) {
        return -1;
      }
      if (name > nameComparable) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "finishDate",
    headerName: "Data de fechamento",
    renderCell: params =>
      params.row?.finishDate
        ? format(new Date(params.row?.finishDate), "dd/MM/yyyy - HH:mm")
        : "-",
    width: 200,
    sortComparator: (v1, v2) => {
      const name = (v1 as string)?.toLowerCase() ?? "-";
      const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

      if (name < nameComparable) {
        return -1;
      }
      if (name > nameComparable) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "initMoney",
    headerName: "Abertura",
    renderCell: ({ row }) => {
      const cashFlow = row as CashFlow;
      return getDivergenceOfCashFlowTooltip(
        cashFlow.initMoney,
        !!cashFlow.initDivergence?.hasDivergence,
        cashFlow.initDivergence?.divergenceValue ?? 0
      );
    },
    width: 150,
  },
  {
    field: "finishIncomes",
    headerName: "Entrada",
    renderCell: params => formatPrice(params?.value ?? 0),
    width: 150,
  },
  {
    field: "finishMoney",
    headerName: "Fechamento",
    renderCell: ({ row }) => {
      const cashFlow = row as CashFlow;

      if (cashFlow.status === "open") {
        return "-";
      }

      return getDivergenceOfCashFlowTooltip(
        cashFlow.finishMoney,
        !!cashFlow.finishDivergence?.hasDivergence,
        cashFlow.finishDivergence?.divergenceValue ?? 0
      );
    },
    width: 150,
  },
  {
    field: "options",
    headerName: " ",
    renderCell: renderOptionsButton,
    width: 150,
  },
];

export interface CashFlowTableProps {
  cashflows: CashFlow[];
  totalCount: number;
  page: number;
  setPage: (value: number) => void;
  limit: number;
  setLimit: (value: number) => void;
  isLoading: boolean;
}

const CashFlowTable: FC<CashFlowTableProps> = ({
  cashflows,
  page,
  setPage,
  limit,
  setLimit,
  totalCount,
  isLoading,
}) => {
  return (
    <Table
      sortingOrder={["desc", "asc"]}
      rows={cashflows}
      columns={columns}
      page={page}
      setPage={setPage}
      pageSize={limit}
      onPageSizeChange={setLimit}
      filterMode="server"
      totalCount={totalCount}
      loading={isLoading}
      localeText={generateLocaleGridText("cashFlowReport")}
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <Typography>Nenhuma relatório disponível</Typography>
          </EmptyTable>
        ),
      }}
    />
  );
};

export default CashFlowTable;
