import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import Loader from "src/components/Loader";
import { ConfirmActionsModal } from "src/components/NewComponents/ConfirmActionsModal/ConfirmActionsModal";
import RenderModule from "src/components/RenderModule";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import { useModule } from "src/hooks/module/useModule";
import { useOrder } from "src/hooks/order/useOrder";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";
import { OrderType } from "src/interfaces/order";
import ConditionPaymentSection from "src/pages/Orders/components/ConditionPaymentSection";
import CreatedAtSection from "src/pages/Orders/components/CreatedAtSection";
import DiscountSection from "src/pages/Orders/components/DiscountSection";
import formUtil from "src/utils/formUtil";

import AditionalInfoTextArea from "../../components/AditionalInfoTextArea";
import BottomBar from "../../components/BottomBar";
import ComissionSection from "../../components/ComissionSection";
import CustomerSection from "../../components/CustomerSection";
import DeadlineSection from "../../components/DeadlineSection";
import ProductManager from "../../components/ProductManager";
import ServiceManager from "../../components/ServiceManager";
import TechnicalReportManager from "../../components/TechnicalReportManager";
import { useOrderType } from "../../useOrderType";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "vendas", path: PATHS.SERVICE_ORDER_LIST },
  { label: "editar ordem de serviço" },
];

const UpdateServiceOrder: React.FC = () => {
  const { orderId = "" } = useParams<{ orderId: string }>();
  const navigate = useNavigate();

  const [orderData, setOrderData] = useState<Partial<OrderType> | undefined>(
    undefined
  );

  const { setRestartOrderList } = useOrderFilters();

  const { detail, order, update, setMethod, services, products, detailStatus } =
    useOrder();

  const { serviceOrderModule, hasModules } = useModule();
  const { setType } = useOrderType();

  const blockOrderUpdate = useMemo(() => {
    return serviceOrderModule?.settings?.blockOrderUpdate ?? false;
  }, [serviceOrderModule]);

  const deadline = order?.deadline ? new Date(order.deadline) : undefined;

  const createdDate = order?.createdDate
    ? new Date(order?.createdDate)
    : undefined;

  const serviceRequired = serviceOrderModule?.settings?.serviceRequired ?? true;
  const allowSetCreatedDate =
    serviceOrderModule?.settings?.allowSetCreatedDate === "allow";

  useEffect(() => {
    detail(orderId);
    setMethod("update");
    setType("serviceOrder");
  }, [orderId]);

  useEffect(() => {
    if (blockOrderUpdate) {
      if (
        detailStatus.success &&
        order &&
        (order.recordsCreatedAt ||
          (order.stockCreatedAt && hasModules("stock")))
      ) {
        toast.error(
          "É preciso estornar o estoque e pagamento para editar esta ordem de serviço"
        );
        navigate(-1);
      }
    }
  }, [order, detailStatus.success]);

  const updateServiceOrder = (data: Partial<OrderType>) => {
    if (serviceRequired && services.length === 0) {
      toast.error("Adicione no mínimo um serviço!");
    }

    if (services.length > 0 || !serviceRequired) {
      if (blockOrderUpdate) {
        setOrderData(data);
      } else {
        update(data as Partial<OrderType>, "serviceOrder", false);
        setRestartOrderList(true);
        setOrderData(undefined);
      }
    }
  };

  const renderContent = () => {
    if (detailStatus.loading) {
      return <Loader />;
    }

    return (
      <Formik
        onSubmit={updateServiceOrder}
        initialValues={
          {
            additionalInfo: order.additionalInfo,
            discount: order.discount,
            discountType: order.discountType,

            downPaymentType: order.downPaymentType,
            downPaymentValue: order.downPaymentValue,
            remainingPaymentType: order.remainingPaymentType,
            remainingPaymentValue: order.remainingPaymentValue,

            services,
            products,

            paymentType: order.paymentType,

            deadline,
            createdDate,
          } as Partial<OrderType>
        }
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        <Form id="form-order" onKeyDown={formUtil.preventEnterOnKeyDown}>
          <Divider />

          <Box mt={2} mb={2} display="flex" justifyContent="space-between">
            <Typography color="primary" variant="h6">
              Ordem de serviço #{order.code}
            </Typography>
          </Box>

          <Grid container>
            <CustomerSection />

            <Grid item xs={12}>
              <Box mt={2} />
            </Grid>

            <Grid container>
              <Grid item xs={12} md={5} lg={3}>
                <DeadlineSection />
              </Grid>
              {allowSetCreatedDate ? (
                <Grid item xs={12} md={5} lg={3}>
                  <CreatedAtSection />
                </Grid>
              ) : null}
              <RenderModule modules={["operator"]}>
                <Grid item xs={12} md={5} lg={3}>
                  <ComissionSection />
                </Grid>
              </RenderModule>
            </Grid>

            <Grid item xs={12}>
              <ServiceManager />
            </Grid>

            <Grid item xs={12}>
              <ProductManager />
            </Grid>

            <RenderModule modules={["technicalReport"]}>
              <Grid item xs={12}>
                <TechnicalReportManager />
              </Grid>
            </RenderModule>

            <Grid item xs={12}>
              <ConditionPaymentSection />
            </Grid>

            <Grid item xs={12}>
              <DiscountSection />
            </Grid>

            <Grid item xs={12}>
              <Box mt={2} />
            </Grid>

            <AditionalInfoTextArea orderType="serviceOrder" />
          </Grid>
        </Form>
      </Formik>
    );
  };

  return (
    <DashboardLayout
      selected="sales"
      subSelected="service-order"
      modules={["serviceOrder"]}
    >
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      {renderContent()}
      <ConfirmActionsModal
        title="Deseja confirma a edição e lançar contas/estoque?"
        onClose={() => setOrderData(undefined)}
        isOpen={!!orderData}
        actions={[
          {
            label: "Sim, lançar",
            variant: "outline",
            colorScheme: "error",
            onClick: () => {
              update(orderData as Partial<OrderType>, "serviceOrder", true);
              setRestartOrderList(true);
              setOrderData(undefined);
            },
          },
          {
            label: "Não, apenas editar",
            colorScheme: "error",
            onClick: () => {
              update(orderData as Partial<OrderType>, "serviceOrder", false);
              setRestartOrderList(true);
              setOrderData(undefined);
            },
          },
        ]}
      />
      <BottomBar orderType="serviceOrder" />
    </DashboardLayout>
  );
};

export default UpdateServiceOrder;
