import * as yup from "yup";

import { REGEX } from "src/constants/regex";
import { extractNumbers } from "src/utils/extractNumbers";

export const schemaContactForm = yup.object({
  name: yup.string().required("Campo obrigatório"),
  email: yup.string().matches(REGEX.validEmail, { message: "E-mail inválido" }),
  phone: yup
    .string()
    .test(
      "length",
      "Telefone inválido",
      val =>
        extractNumbers(val).length === 11 || extractNumbers(val).length === 0
    ),
  alternativePhones: yup.array().of(
    yup.object().shape({
      number: yup
        .string()
        .test(
          "length",
          "Telefone inválido",
          val =>
            extractNumbers(val).length === 11 ||
            extractNumbers(val).length === 0
        ),
    })
  ),
  cpf: yup.string().matches(REGEX.validCPF, { message: "CPF inválido" }),
  cnpj: yup.string().matches(REGEX.validCNPJ, { message: "CNPJ inválido" }),
});
