import React from "react";

/**
 * `debounce` força a função esperar um certo tempo antes de ser executada novamente.
 * Limita o número de vezes que uma função pode ser chamada.
 *
 * NOTA: utilizar dentro de um useCallback
 *
 * @param {function} fn função que será executada pelo debounce
 * @param {number} delay milissegundos de intervalo para chamar a função
 *
 * @return {function}
 */
export const debounce = (fn: any, delay: number) => {
  let timeoutID: any;

  return (...args: any) => {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }

    timeoutID = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

/**
 * Função de delay simplificada, pode ser utilizada com then/catch
 *
 * @param {number} time milissegundos de intervalo
 *
 * @return {Promise<unknown>}
 */
export const delay = (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time));
};

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

  React.useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
