import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Form, Formik, FormikHelpers } from "formik";

import Button from "src/components/Button";
import { Transition } from "src/components/Modal";
import { useModal } from "src/components/Modal/useModal";
import Typography from "src/components/Typography";
import { useCompany } from "src/hooks/company/useCompany";
import { useOrder } from "src/hooks/order/useOrder";

import Gallery from "./components/Gallery";
import TechnicalReportFields from "./components/TechnicalReportFields";
import * as Styled from "./styles";

const TechnicalReportModal: React.FC = () => {
  const { setTechnicalReport, technicalReport, method } = useOrder();
  const { open, handleClose } = useModal();
  const { userCompany } = useCompany();

  const fields = userCompany?.technicalReportFields;

  const technicalReportInitialValues = useMemo(() => {
    if (method === "update") {
      return (
        technicalReport?.data?.reduce(
          (obj, current) => ({ ...obj, [current.label]: current.content }),
          {}
        ) ??
        fields?.reduce((obj, current) => ({ ...obj, [current.label]: "" }), {})
      );
    }
    if (fields?.length && method === "post" && technicalReport) {
      return technicalReport?.data?.reduce(
        (obj, current) => ({ ...obj, [current.label]: current.content }),
        {}
      );
    }
    if (fields?.length && method === "post" && !technicalReport) {
      return fields.reduce(
        (obj, current) => ({ ...obj, [current.label]: "" }),
        {}
      );
    }
    return {};
  }, [fields, technicalReport, method]);

  const onSubmit = (data: any, { resetForm }: FormikHelpers<any>) => {
    const { gallery, ...technicalReportFieldsObject } = data;

    const technicalReportFieldsData = fields.map(field => {
      const content = Object.entries(technicalReportFieldsObject).find(
        key => key[0] === field.label
      );

      return {
        label: field.label,
        type: field.type,
        content: content?.length ? (content[1] as string) : "",
      };
    });

    setTechnicalReport({ data: technicalReportFieldsData, gallery });
    handleClose();
    resetForm();
  };

  const handleDeleteTechnicalReport = () => {
    setTechnicalReport(undefined);
    handleClose();
  };

  return (
    <Styled.Dialog
      open={open}
      TransitionComponent={Transition}
      // keepMounted
      fullWidth
      maxWidth="md"
      onClose={handleClose}
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          ...technicalReportInitialValues,
          gallery: technicalReport?.gallery || [],
        }}
        enableReinitialize
      >
        <Form>
          <Styled.Container>
            <Box
              display="flex"
              mb={2}
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <div />
              <Typography variant="h6" color="primary">
                Laudo Técnico
              </Typography>
              <Styled.CloseButton onClick={handleClose} />
            </Box>
            <Grid container>
              <Grid item xs={6}>
                <TechnicalReportFields />
              </Grid>
              <Grid item xs={6}>
                <Box
                  ml={2}
                  width="100%"
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Gallery />
                  <Box>
                    {technicalReport ? (
                      <Styled.DeleteButton
                        variant="outlined"
                        fullWidth
                        size="large"
                        onClick={handleDeleteTechnicalReport}
                      >
                        Excluir
                      </Styled.DeleteButton>
                    ) : null}
                    <Button
                      size="large"
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Salvar laudo
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Styled.Container>
        </Form>
      </Formik>
    </Styled.Dialog>
  );
};

export default TechnicalReportModal;
