import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import {
  GetProductResponseType,
  ProductFormType,
  ProductType,
} from "src/interfaces/product";
import { ListParamsType } from "src/interfaces/request";
import {
  getProduct,
  addProduct,
  updateProduct,
  deleteProduct,
  getProducts,
} from "src/services/product";

import { useImage } from "../image/useImage";
import { ProductContextType } from "./props";

export const ProductContext = createContext({} as ProductContextType);

export const ProductProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState(STATUS.inital);
  const [products, setProducts] = useState<GetProductResponseType>(
    {} as GetProductResponseType
  );
  const [product, setProduct] = useState<ProductType>({} as ProductType);
  const { uploadAndReturnUrl } = useImage();

  const detail = async (id: string) => {
    try {
      setStatus(STATUS.loading);

      const response: ProductType = await getProduct(id);

      setProduct(response);

      setStatus(STATUS.success);
    } catch (e) {
      setProduct({} as ProductType);
      setStatus(STATUS.error);
    }
  };

  const list = async (params: ListParamsType) => {
    try {
      setProducts({} as GetProductResponseType);
      setStatus(STATUS.loading);

      const response: GetProductResponseType = await getProducts(params);

      setProducts(response);

      setStatus(STATUS.success);
    } catch (e) {
      toast.error(
        "Ocorreu um problema ao carregar os produtos. Por favor verifique sua conexão e tente novamnete."
      );
      setProducts({} as GetProductResponseType);
      setStatus(STATUS.error);
    }
  };

  const uploadImage = async (image: string | File) => {
    let file = image;
    if (file instanceof File) {
      file = (await uploadAndReturnUrl(file))?.url ?? "";
    }
    return file;
  };

  const add = async (params: ProductFormType) => {
    try {
      setProducts({} as GetProductResponseType);
      setStatus(STATUS.loading);

      const {
        cost,
        price,
        image,
        minQuantity,
        quantity,
        manageStock,
        ...rest
      } = params;

      const priceNumber = Number(price);
      const costNumber = Number(cost);
      const quantityNumber = Number(quantity);
      const minQuantityNumber = Number(minQuantity);

      const fileURL = await uploadImage(image);

      const response = await addProduct({
        ...rest,
        price: priceNumber,
        ...(costNumber && {
          cost: costNumber,
        }),
        ...(manageStock && {
          manageStock,
          quantity: quantityNumber,
          minQuantity: minQuantityNumber,
        }),
        image: fileURL,
      } as ProductFormType);

      setProduct(response);

      setStatus(STATUS.success);
      toast.success("Produto cadastrado");
    } catch (e: any) {
      setStatus(STATUS.error);
      toast.error(
        e?.response?.data?.message ??
          "Ocorreu um problema ao cadastrar esse produto"
      );
    }
  };

  const update = async (params: ProductFormType) => {
    try {
      setProducts({} as GetProductResponseType);
      setStatus(STATUS.loading);

      const {
        cost,
        price,
        minQuantity,
        quantity,
        manageStock,
        image,
        ...rest
      } = params;

      const priceNumber = Number(price);
      const costNumber = Number(cost);
      const quantityNumber = Number(quantity);
      const minQuantityNumber = Number(minQuantity);

      const fileURL = await uploadImage(image);

      const response: ProductType = await updateProduct({
        ...rest,
        price: priceNumber,
        ...(costNumber && {
          cost: costNumber,
        }),
        ...(manageStock && {
          minQuantity: minQuantityNumber,
          manageStock,
          quantity: quantityNumber,
        }),
        image: fileURL,
      } as ProductFormType);

      setProduct(response);

      setStatus(STATUS.success);

      toast.success("Produto atualizado");
    } catch (e: any) {
      setStatus(STATUS.error);
      toast.error(
        e?.response?.data?.message ??
          "Ocorreu um problema ao atualizar esse produto"
      );
    }
  };

  const remove = async (id: string) => {
    try {
      setStatus(STATUS.loading);

      await deleteProduct(id);

      setStatus(STATUS.success);

      if (products?.products?.length > 0) {
        setProducts({
          ...products,
          products: products.products.filter(item => item?.id !== id),
        });
      }

      toast.success("Produto deletado");
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao deletar esse produto");
    }
  };

  return (
    <ProductContext.Provider
      value={{
        status,
        product,
        products,
        list,
        add,
        update,
        detail,
        remove,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error("useProduct must be used within a ProductProvider");
  }

  return context;
};
