import React from "react";

import * as Styled from "./styles";

export const PrinterGuideTemplate = React.forwardRef<HTMLDivElement>(
  (_, ref) => {
    const getGuide = (size: number, isBold = false) => {
      return (
        <Styled.Row>
          <Styled.Cell fontSize={size} isBold={isBold} align="left">
            {isBold ? "Bold" : "Normal"}
          </Styled.Cell>
          <Styled.Cell fontSize={size} isBold={isBold} align="right">
            {size}
          </Styled.Cell>
        </Styled.Row>
      );
    };

    const getParagraph = (size: number, isBold = false) => {
      return (
        <Styled.Paragraph fontSize={size} isBold={isBold}>
          {isBold ? "Bold " : "Normal "}
          {size}
        </Styled.Paragraph>
      );
    };

    return (
      <Styled.Main>
        <Styled.Print ref={ref}>
          <Styled.Table>
            {getGuide(20)}
            {getGuide(20, true)}
            {getGuide(18)}
            {getGuide(18, true)}
            {getGuide(16)}
            {getGuide(16, true)}
            {getGuide(14)}
            {getGuide(14, true)}
            {getGuide(12)}
            {getGuide(12, true)}
            {getGuide(10)}
            {getGuide(10, true)}
            {getGuide(8)}
            {getGuide(8, true)}
            {getGuide(6)}
            {getGuide(6, true)}
            {getParagraph(20)}
            {getParagraph(20, true)}
            {getParagraph(18)}
            {getParagraph(18, true)}
            {getParagraph(16)}
            {getParagraph(16, true)}
            {getParagraph(14)}
            {getParagraph(14, true)}
            {getParagraph(12)}
            {getParagraph(12, true)}
            {getParagraph(10)}
            {getParagraph(10, true)}
            {getParagraph(8)}
            {getParagraph(8, true)}
            {getParagraph(6)}
            {getParagraph(6, true)}
          </Styled.Table>
        </Styled.Print>
      </Styled.Main>
    );
  }
);
