import React, { useMemo } from "react";

import { useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import {
  BUY_ORDER_COLOR_STATUS,
  BUY_ORDER_STATUS_NAME,
} from "src/constants/orders";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import { BuyOrderStatusEnum } from "src/interfaces/buyOrder";

import { TabLabelProps, TabPanelProps } from "./props";
import * as Styled from "./styles";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={3}>{children}</Box>}
    </div>
  );
};

const TabLabel: React.FC<TabLabelProps> = props => {
  const { status, activeStatus } = props;

  const { palette } = useTheme();

  const enabled = activeStatus === status.status;

  const colorIndicator = status.status
    ? BUY_ORDER_COLOR_STATUS[status.status]
    : undefined;

  const colorText = status.status
    ? BUY_ORDER_COLOR_STATUS[status.status]
    : palette.common.black;

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Styled.IndicatorStatus color={colorIndicator} />
        {status.status && <Box width={10} />}

        <Styled.TabLabelText
          statuscolor={colorText}
          active={enabled.toString()}
        >
          {status.status ? BUY_ORDER_STATUS_NAME[status.status] : "Todos"}
        </Styled.TabLabelText>
      </Box>
      <Styled.TabLabelText statuscolor={colorText} active={enabled.toString()}>
        {status.count}
      </Styled.TabLabelText>
    </Box>
  );
};

const BuyOrderStatusTabs: React.FC = props => {
  const { children } = props;

  const { buyOrdersStatusesMeta } = useBuyOrder();
  const { status, setStatus } = useBuyOrderFilters();

  const tabs = useMemo(
    () =>
      buyOrdersStatusesMeta.map(item => ({
        key: item.status,
        label: item.status ? BUY_ORDER_STATUS_NAME[item.status] : "Todos",
      })),
    [buyOrdersStatusesMeta]
  );

  const handleChange = (
    _: React.ChangeEvent<unknown>,
    newStatus: BuyOrderStatusEnum | ""
  ) => {
    setStatus(newStatus);
  };

  const renderTabs = useMemo(
    () =>
      buyOrdersStatusesMeta.map(item => (
        <Styled.Tab
          label={<TabLabel status={item} activeStatus={status} />}
          {...a11yProps(item.status)}
          key={`tab-${item.status}`}
          value={item.status}
        />
      )),
    [buyOrdersStatusesMeta, status]
  );

  const renderTabPanels = buyOrdersStatusesMeta.map((tabPanel, index) => (
    <TabPanel
      value={status}
      index={tabs[index].key}
      key={`tabpanel-${tabs[index].key}`}
    >
      {children}
    </TabPanel>
  ));

  return (
    <>
      <Styled.Tabs
        value={status}
        onChange={handleChange}
        aria-label="status-tabs"
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={Styled.TabScrollButton}
      >
        {renderTabs}
      </Styled.Tabs>
      {renderTabPanels}
    </>
  );
};

export default BuyOrderStatusTabs;
