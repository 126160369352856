import styled from "styled-components";

import { DataGrid as MuiDataGrid } from "@material-ui/data-grid";

export const DataGrid = styled(MuiDataGrid)`
  border: none;

  .MuiDataGrid-row {
    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.palette.secondary.contrastText};
    }
    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .MuiCheckbox-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: 0;
  }
  &.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0px;
  }
  &.MuiDataGrid-root .MuiDataGrid-footerContainer {
    justify-content: flex-end;
  }
  &.MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none;
  }
  .MuiSelect-icon {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiIconButton-colorInherit {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
  }
`;
