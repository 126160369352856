import { RouteObject } from "react-router-dom";

import PATHS from "src/constants/paths";
import SchedulePage from "src/pages/Schedule";

export const scheduleRoutes: RouteObject[] = [
  {
    path: PATHS.SCHEDULE,
    element: <SchedulePage />,
  },
];
