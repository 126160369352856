import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import { Button, Flex, Image } from "@chakra-ui/react";

import { useAuth } from "src/hooks/user/auth/useAuth";
import { useChangeCompanyLogo } from "src/services/hooks/auth/useChangeCompanyLogo";
import { useUploadImage } from "src/services/hooks/image/useUploadImage";

export function ChangeCompanyLogo() {
  const { user, setUser, isOwner } = useAuth();
  const { mutate: changeLogo, isLoading } = useChangeCompanyLogo({
    onSuccess: (_, params) => {
      setUser({
        ...user,
        company: {
          ...user.company,
          logo: params.logo,
        },
      });
      toast.success("Logo atualizada com sucesso.");
    },
  });

  const { mutate: uploadImage, isLoading: isLoadingUploadImage } =
    useUploadImage({
      onSuccess: ({ url }) => {
        changeLogo({ logo: url, type: user.company.type });
      },
    });

  const onDrop = useCallback(files => {
    if (files.length) {
      uploadImage(files[0]);
    } else {
      toast.error(
        "Ocorreu um problema ao selecionar imagem, verique o tipo e tamanho para prosseguir. Tamanho máximo de 3mb"
      );
    }
  }, []);

  const { open } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 3 * 1024 * 1024,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
  });

  return (
    <Flex mb={4} alignItems="center">
      <Image
        border="2px solid var(--chakra-colors-primary-500)"
        borderRadius="10px"
        boxSize="100px"
        objectFit="cover"
        src={user.company.logo}
        alt={`Logo ${user.company.fantasyName}`}
      />
      {isOwner ? (
        <Button
          ml={2}
          size="sm"
          variant="link"
          textDecoration="none"
          _hover={{ textDecoration: "none" }}
          onClick={open}
          isLoading={isLoading || isLoadingUploadImage}
        >
          Alterar logomarca da empresa
        </Button>
      ) : null}
    </Flex>
  );
}
