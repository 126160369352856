import React, { createContext, useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import { CompanyType } from "src/interfaces/company";
import { RequestStatusType } from "src/interfaces/request";
import { SigninRequestType, UserType } from "src/interfaces/user";
import { getToken } from "src/services/getToken";
import { getMe, useMe } from "src/services/hooks/auth/useMe";
import {
  signIn as signinRequest,
  signInCode as signinRequestCode,
  validateUsernameService,
  validateUsernameServiceCode,
} from "src/services/signin";
import { updateUser } from "src/services/user";

import { AuthContextType } from "./props";

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState<RequestStatusType>(STATUS.inital);

  const [user, setUser] = useState<UserType>({} as UserType);
  const [companyFromUsername, setCompanyFromUsername] = useState(
    {} as CompanyType
  );

  const isAuthenticated = useMemo(
    () => Boolean(getToken()) && user.status === "enabled",
    [user]
  );

  const isOwner = useMemo(() => user?.id === user?.company?.owner, [user]);

  const { isLoading, fetchStatus } = useMe({
    enabled: !isAuthenticated,
    onSuccess: user => {
      setUser(user);
    },
  });

  const signIn = async (data: SigninRequestType) => {
    try {
      setStatus(STATUS.loading);

      const { token } = await signinRequest({
        ...data,
        username: data.username.toLowerCase(),
        company: companyFromUsername.id,
      });
      localStorage.setItem("@azulou:accessToken", token);

      const user = await getMe();
      setUser(user);

      setStatus(STATUS.success);
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? "Falha ao fazer login.");
      setUser({} as UserType);
      setStatus(STATUS.error);
    }
  };

  const signInCode = async (data: SigninRequestType) => {
    try {
      setStatus(STATUS.loading);

      const { token } = await signinRequestCode({
        ...data,
        username: data.username.toLowerCase(),
        company: companyFromUsername.id,
      });
      localStorage.setItem("@azulou:accessToken", token);

      const user = await getMe();
      setUser(user);

      setStatus(STATUS.success);
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? "Falha ao fazer login.");
      setUser({} as UserType);
      setStatus(STATUS.error);
    }
  };

  const validateUsername = async (username: string) => {
    try {
      setStatus(STATUS.loading);

      const response = await validateUsernameService(username);
      setCompanyFromUsername(response as CompanyType);
      setStatus(STATUS.success);
    } catch (error: any) {
      setStatus(STATUS.error);
      throw error?.response?.data?.message ?? "Usuário não existe.";
    }
  };
  const validateUsernameCode = async (username: string) => {
    try {
      setStatus(STATUS.loading);

      const response = await validateUsernameServiceCode(username);
      setCompanyFromUsername(response as CompanyType);
      setStatus(STATUS.success);
    } catch (error: any) {
      setStatus(STATUS.error);
      throw error?.response?.data?.message ?? "Usuário não existe.";
    }
  };

  const update = async (data: CompanyType) => {
    try {
      setStatus(STATUS.loading);

      const response = await updateUser(data);

      setUser({ ...user, ...response, company: user.company });

      setStatus(STATUS.success);

      toast.success("Dados atualizados com sucesso");
    } catch (error) {
      toast.error("Falha ao alterar seus dados, tente novamente mais tarde");
      setStatus(STATUS.error);
    }
  };

  const logout = () => {
    setUser({} as UserType);
    localStorage.removeItem("@azulou:accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("userCompany");
  };

  return (
    <AuthContext.Provider
      value={{
        status,
        user,
        setUser,
        isAuthenticated,
        update,
        signIn,
        signInCode,
        logout,
        validateUsernameCode,
        validateUsername,
        companyFromUsername,
        isLoading: isLoading && fetchStatus === "fetching",
        isOwner,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
};
