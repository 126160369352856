import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ProductType } from "src/interfaces/product";
import api from "src/services/api";

export type InventoryFilterType = "default" | "minQuantity" | "withoutStock";

interface InventoryReportParams {
  filter: InventoryFilterType;
}

interface GetInventoryReportResponse {
  result: Array<ProductType>;
  totals: {
    totalAmount: number;
  };
}

export const inventoryReport = (params: InventoryReportParams) =>
  api
    .get<GetInventoryReportResponse>("/v1/report/inventory", {
      params,
    })
    .then(res => res.data);

export function useInventoryReport(
  params: InventoryReportParams,
  config?: UseQueryOptions<
    GetInventoryReportResponse,
    Error,
    GetInventoryReportResponse,
    unknown[]
  >
) {
  return useQuery<
    GetInventoryReportResponse,
    Error,
    GetInventoryReportResponse,
    unknown[]
  >(["inventoryReport", params], () => inventoryReport(params), {
    refetchOnWindowFocus: false,
    ...(config || {}),
    initialData: {
      result: [],
      totals: {
        totalAmount: 0,
      },
    } as GetInventoryReportResponse,
  });
}
