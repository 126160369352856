import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Text, Switch, FormControl, FormLabel } from "@chakra-ui/react";

import {
  PERMISSIONS,
  FeaturesSubjects,
  UserPermissions,
} from "src/interfaces/permissions";

interface PermissionActionSwitchProps {
  title: string;
  subtitle: string;
  permissions: FeaturesSubjects[];
}

function PermissionActionSwitch(props: PermissionActionSwitchProps) {
  const { title, subtitle, permissions } = props;

  const { setValue, watch } = useFormContext();

  const value: UserPermissions = watch("permissions") ?? {};

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const permissionsChanged = permissions.reduce((permissions, permission) => {
      return {
        ...permissions,
        [permission]: event.target.checked
          ? PERMISSIONS[permission]?.map(permission => permission.action)
          : [],
      };
    }, {});

    setValue("permissions", {
      ...value,
      ...permissionsChanged,
    });
  };

  const isChecked = useMemo(() => {
    return permissions.some(permission => {
      return !!value[permission]?.length;
    });
  }, [permissions, value]);

  return (
    <FormControl display="flex" justifyContent="space-between" mt={4}>
      <FormLabel htmlFor={`${permissions.join("-")}-switch`}>
        <Text color="gray.500">{title}</Text>
        <Text fontSize="sm" color="gray.400">
          {subtitle}
        </Text>
      </FormLabel>
      <Switch
        id={`${permissions.join("-")}-switch`}
        isChecked={isChecked}
        checked={isChecked}
        onChange={handleChange}
        name={permissions.join("-")}
      />
    </FormControl>
  );
}

export default PermissionActionSwitch;
