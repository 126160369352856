import React, { useMemo, useRef } from "react";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Formik, Form, FormikProps, useFormikContext } from "formik";

import Button from "src/components/Button";
import ImageUploadField from "src/components/Inputs/ImageUploadField";
import Textarea from "src/components/Inputs/Textarea";
import TextField from "src/components/Inputs/TextField";
import { Transition } from "src/components/Modal";
import Typography from "src/components/Typography";
import { GalleryType } from "src/interfaces/order";

import { AddImateGalleryModalProps } from "./props";
import * as Styled from "./styles";

interface FormImageUpload {
  index?: number;
  image: string | File;
  name: string;
  description: string;
}

const AddImateGalleryModal: React.FC<AddImateGalleryModalProps> = props => {
  const { open, handleClose, method, data } = props;

  const formikRef = useRef<FormikProps<FormImageUpload>>(null);

  const { values, setFieldValue } =
    useFormikContext<{ gallery: GalleryType[] | [] }>();

  const gallery = useMemo(() => values.gallery || [], [values.gallery]);

  const onSubmit = (data: FormImageUpload) => {
    const { image: imageUrl, name, description, index: id } = data;
    if (!imageUrl) {
      toast.error("Adicione uma foto para continuar.");
      return;
    }

    if (method === "post") {
      setFieldValue("gallery", [
        ...gallery,
        { index: id, imageUrl, name, description },
      ]);
    }

    if (method === "update") {
      const storagedGallery = gallery.map(item => {
        if (item.index === id) {
          return { index: data.index, imageUrl, name, description };
        }
        return item;
      });

      setFieldValue("gallery", [...storagedGallery]);
    }
    handleClose();
    formikRef.current?.resetForm();
  };

  return (
    <Styled.Modal
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          index: data?.index,
          image: data?.imageUrl || "",
          name: data?.name || "",
          description: data?.description || "",
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={formikRef}
      >
        <Form id="form-image">
          <Box display="flex" justifyContent="space-between" mt={1} mb={2}>
            <Styled.LeftSpace />

            <Typography variant="h6" color="textSecondary">
              Fotos
            </Typography>
            <Styled.CloseButton onClick={handleClose} />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ImageUploadField name="image" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nome da imagem"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Textarea
                name="description"
                label="Descrição da imagem"
                rows={5}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                type="submit"
                form="form-image"
              >
                {method === "post" ? "Adicionar foto" : "Atualizar foto"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Styled.Modal>
  );
};

export default AddImateGalleryModal;
