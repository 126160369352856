import { useMemo } from "react";

import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import InfoIconMUI from "@material-ui/icons/Info";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import { ReactComponent as LogoUnstyled } from "src/assets/azulou-logo.svg";
import appStoreImage from "src/assets/pictures/app-store.png";
import playStoreImage from "src/assets/pictures/play-store.png";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { useActiveTrial } from "src/services/hooks/subscription/useActiveTrial";

export default function PlanExpired() {
  const [isLargerThan960] = useMediaQuery("(min-width: 960px)");
  const { logout, isOwner, user, setUser } = useAuth();

  const { mutate: activeTrial, isLoading } = useActiveTrial({
    onSuccess: data => {
      setUser({
        ...user,
        company: {
          ...user.company,
          planSignature: data,
        },
      });
    },
  });

  const title = useMemo(() => {
    if (!user.company.planSignature) {
      return "Assine o plano Azulou!";
    }

    return "Seu plano expirou!";
  }, [user]);

  const description = useMemo(() => {
    if (!isOwner) {
      return "Sua organização tem um problema de pagamento, contate seu administrador!";
    }

    if (!user.company.planSignature) {
      return "Em breve você não terar mais acesso ao Azulou sem plano, assine já pelo app!";
    }

    return "A assinatura do seu negócio expirou, atualize já seu plano pelo app AZULOU.";
  }, [isOwner, user]);

  return (
    <Flex
      minH="100vh"
      w="100%"
      bg={isLargerThan960 ? "#E5E5E5" : "primary.500"}
      direction="column"
      position="relative"
      sx={
        isLargerThan960
          ? {
              alignItems: "center",
              justifyContent: "center",
            }
          : undefined
      }
    >
      <Flex
        p={10}
        alignItems="center"
        direction="column"
        width="100%"
        bg="primary.500"
        sx={{
          height: "100%",
          maxWidth: "850px",
          borderRadius: "10px",
          boxShadow: "0px 16px 46px 16px rgba(39, 72, 145, 0.25)",
        }}
      >
        <Box
          marginBottom={14}
          width={isLargerThan960 ? "120px" : "160px"}
          sx={{
            svg: {
              path: {
                fill: "white",
              },
            },
          }}
        >
          <LogoUnstyled
            width={isLargerThan960 ? "120px" : "160px"}
            height={isLargerThan960 ? "25px" : "35px"}
          />
        </Box>

        <Flex
          alignItems="center"
          direction="column"
          py={6}
          px={5}
          borderRadius="10px"
          bg="white"
          w="100%"
          maxWidth="350px"
        >
          <Icon color="error.500" as={InfoIconMUI} fontSize="36px" mb={5} />
          <Text color="primary.500" fontSize="lg" fontWeight="600">
            {title}
          </Text>
          <Text color="primary.500" fontSize="sm" textAlign="center" mb={6}>
            {description}
          </Text>

          <HStack>
            <Link
              target="_blank"
              href="https://apps.apple.com/us/app/azulou/id1584214688"
            >
              <Image
                src={appStoreImage}
                alt="Logo referente a acesso ao Azulou app na app-store"
              />
            </Link>
            <Link
              target="_blank"
              href="https://play.google.com/store/apps/details?id=br.com.azulou.app"
            >
              <Image
                src={playStoreImage}
                alt="Logo referente a acesso ao Azulou app na play-store"
              />
            </Link>
          </HStack>
        </Flex>

        {!user.company.planSignature ? (
          <Button
            w="100%"
            maxWidth="350px"
            mt={4}
            width="100%"
            variant="solid"
            color="primary.500"
            bg="white"
            fontSize="sm"
            fontWeight="600"
            size="lg"
            colorScheme="whiteAlpha"
            isLoading={isLoading}
            onClick={() => activeTrial()}
          >
            Experimentar por 15 dias grátis
          </Button>
        ) : null}

        <Button
          w="100%"
          maxWidth="350px"
          mt={6}
          variant="ghost"
          fontSize="sm"
          fontWeight="500"
          color="white"
          leftIcon={<KeyboardBackspaceIcon />}
          colorScheme="whiteAlpha"
          onClick={logout}
        >
          Sair da conta
        </Button>

        <Text
          fontSize="10px"
          color={isLargerThan960 ? "gray.300" : "white"}
          my={6}
          textAlign="center"
          sx={
            isLargerThan960
              ? {
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                }
              : undefined
          }
        >
          <Text fontSize="10px" as="span" fontWeight="800">
            Azulou Tecnologia LTDA.
          </Text>{" "}
          CNPJ 42.827.632/0001-73. {isLargerThan960 ? null : <br />}Todos os
          direitos reservados.
        </Text>
      </Flex>
    </Flex>
  );
}
