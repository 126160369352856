import React from "react";
import NumberFormat from "react-number-format";

import { NumberFormatCustomProps } from "./props";

const NumberFormatCustom: React.FC<NumberFormatCustomProps> = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalSeparator=","
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix=" %"
    />
  );
};

export default NumberFormatCustom;
