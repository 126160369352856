import { toast } from "react-toastify";

import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { ReactComponent as FinancialIcon } from "src/assets/icons/sidebar/financial.svg";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { RecordType } from "src/interfaces/record";
import { useChargebackRecord } from "src/services/hooks/record/useChargebackRecord";

interface ChargebackRecordModalType {
  record?: RecordType;
  onSuccess?: (value: RecordType) => void;
}

function ChargebackRecordModal({
  record,
  onSuccess,
}: ChargebackRecordModalType) {
  const { open, handleClose } = useBaseSwipeableDrawer();

  const { mutate: chargeBack, isLoading } = useChargebackRecord({
    onSuccess: record => {
      toast.success("Lançamento estornado com com sucesso!");
      handleOnClose();
      if (onSuccess) onSuccess(record);
    },
  });

  const handleOnClose = () => {
    handleClose("chargebackRecord");
  };

  const handleChargebackRecord = () => {
    chargeBack({ id: record?.id as string });
  };

  return (
    <Modal
      isOpen={open.includes("chargebackRecord")}
      onClose={handleOnClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" pb={4}>
          <Icon
            as={FinancialIcon}
            sx={{
              path: {
                fill: "error.500",
              },
            }}
          />
        </ModalHeader>
        <ModalCloseButton color="error.500" />
        <ModalBody pb={6}>
          <Text textAlign="center" mb={8}>
            Tem certeza que deseja estornar o valor do
            <br />
            pagamento?
          </Text>
          <Button
            w="100%"
            colorScheme="error"
            onClick={() => {
              handleChargebackRecord();
            }}
            isLoading={isLoading}
          >
            Realizar estorno
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ChargebackRecordModal;
