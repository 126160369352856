import * as React from "react";

import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";

import { ModalProvider } from "src/components/Modal/useModal";
import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { SERVICES_MEASURES } from "src/constants/measures";
import PATHS from "src/constants/paths";
import { useService } from "src/hooks/service/useService";
import { ServiceMeasureType } from "src/interfaces/service";
import MoreOptionsMenu from "src/pages/Services/List/components/MoreOptionsMenu";
import { formatPrice } from "src/utils/format";
import { generateLocaleGridText } from "src/utils/localGridText";

import * as Styled from "./styles";

const renderOptionsButton = (params: GridValueFormatterParams) => (
  <ModalProvider>
    <MoreOptionsMenu id={params.id as string} />
  </ModalProvider>
);

const renderValue = (params: GridValueFormatterParams) =>
  formatPrice(params.value as number);

const renderCost = (params: GridValueFormatterParams) => (
  <Typography color="error">- {formatPrice(params.value as number)}</Typography>
);

const renderMeasure = (params: GridValueFormatterParams) =>
  SERVICES_MEASURES[params.value as ServiceMeasureType];

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Nome do serviço",
    flex: 2,
  },
  {
    field: "cost",
    headerName: "Custo",
    renderCell: renderCost,
    flex: 2,
  },
  {
    field: "price",
    headerName: "Preço",
    renderCell: renderValue,
    flex: 2,
  },
  {
    field: "unitOfMeasure",
    headerName: "Medida",
    renderCell: renderMeasure,
    flex: 2,
  },
  {
    field: "description",
    headerName: "Descrição",
    description: "Descrição",
    flex: 3,
    editable: true,
  },
  {
    field: "options",
    headerName: " ",
    renderCell: renderOptionsButton,
    flex: 1,
    align: "right",
  },
];

const EmptyTableContent = () => (
  <div>
    <Styled.ServiceIcon />
    <Typography variant="subtitle1">
      Você ainda não possui serviços para serem visualizados nesta lista!
    </Typography>
    <Typography variant="subtitle1">
      comece{" "}
      <Styled.MarkedText> cadastrando os seus serviços</Styled.MarkedText>
    </Typography>
  </div>
);

const ServiceTable = () => {
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState<number>(10);

  const { list, services, status } = useService();

  React.useEffect(() => {
    list({
      limit,
      page,
    });
  }, [page, limit]);

  return (
    <Table
      rows={services.services || []}
      columns={columns}
      page={page}
      setPage={setPage}
      pageSize={limit}
      onPageSizeChange={setLimit}
      filterMode="server"
      loading={status.loading}
      totalCount={services.totalCount}
      localeText={generateLocaleGridText("service")}
      detailLink={PATHS.SERVICES_DETAIL}
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <EmptyTableContent />
          </EmptyTable>
        ),
      }}
      // error={status.error}
    />
  );
};

export default ServiceTable;
