import React from "react";
import ReactDOM from "react-dom";

import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { logVersion } from "./utils/logVersion";

const appRoot = document.getElementById("root");
appRoot?.setAttribute("notranslate", "true");

ReactDOM.render(<App />, appRoot);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
logVersion();
