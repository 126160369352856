import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Modal from "src/components/Modal";
import { useModal } from "src/components/Modal/useModal";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";

import { BuyOrderDeleteModalProps } from "./props";
import * as Styled from "./styles";

const BuyOrderDeleteModal: React.FC<BuyOrderDeleteModalProps> = props => {
  const { id, afterActions } = props;

  const { deleteBuyOrder, deleteBuyOrderStatus } = useBuyOrder();
  const { handleClose } = useModal();
  const { setRestartOrderList } = useBuyOrderFilters();

  const deleteBuyOrderService = async () => {
    await deleteBuyOrder(id);
    handleClose();
    afterActions();
    setRestartOrderList(true);
  };

  return (
    <Modal>
      <Box p={3}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <div />
              <Styled.AlertIcon alignmentBaseline="middle" />
              <Styled.CloseButton onClick={handleClose} />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Styled.InfoText align="center" variant="h6">
              Tem certeza que deseja excluir esta ordem de compra?
            </Styled.InfoText>
          </Grid>
          <Grid item xs={12}>
            <Styled.DeleteButton
              variant="contained"
              onClick={deleteBuyOrderService}
              fullWidth
              size="large"
              disabled={deleteBuyOrderStatus.loading}
              loading={deleteBuyOrderStatus.loading}
            >
              Excluir
            </Styled.DeleteButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default BuyOrderDeleteModal;
