import * as React from "react";

import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";

import { ModalProvider } from "src/components/Modal/useModal";
import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import PATHS from "src/constants/paths";
import { useContact } from "src/hooks/contact/useContact";
import { ContactType } from "src/interfaces/contact";
import { formatPhone, maskDocument } from "src/utils/format";
import { generateLocaleGridText } from "src/utils/localGridText";

import { ContactListFilters } from "../../..";
import EmptyContactTable from "../../EmptyContactTable";
import LabelWithTags from "../../LabelWithTags";
import MoreOptionsMenu from "../../MoreOptionsMenu";

const renderOptionsButton = (params: GridValueFormatterParams) => (
  <ModalProvider>
    <MoreOptionsMenu id={params.id as string} />
  </ModalProvider>
);
export const renderDocument = (params: GridValueFormatterParams) => {
  if (params.value === null) {
    return null;
  }
  const contact = params.row as ContactType;

  if (contact?.cpf) {
    return maskDocument(contact?.cpf);
  }

  if (contact?.cnpj) {
    return maskDocument(contact?.cnpj);
  }

  return "-";
};

const renderPhone = (params: GridValueFormatterParams) =>
  formatPhone(params.value);

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Nome do fornecedor",
    renderCell: ({ row }) => (
      <LabelWithTags label={row?.name ?? "-"} tags={row?.tags ?? []} />
    ),
    flex: 4,
  },
  {
    field: "phone",
    headerName: "Número",
    renderCell: renderPhone,
    flex: 2,
  },
  {
    field: "email",
    headerName: "E-mail",
    flex: 3,
  },
  {
    field: "cpf",
    headerName: "CPF/CNPJ",
    renderCell: renderDocument,
    flex: 2,
  },
  {
    field: "options",
    headerName: " ",
    renderCell: renderOptionsButton,
    flex: 1,
    align: "right",
  },
];

interface ProviderTableProps {
  filters: ContactListFilters;
  setFilters: (value: ContactListFilters) => void;
}

const ProviderTable = ({ filters, setFilters }: ProviderTableProps) => {
  const {
    list,
    contacts: rows,
    status,
    createStatus,
    updateStatus,
  } = useContact();

  React.useEffect(() => {
    list({
      limit: filters.limit,
      page: filters.page,
      type: "provider",
      search: filters.search,
      tags: JSON.stringify(
        filters.tagSelected.value ? [filters.tagSelected.value] : []
      ),
    });
  }, [filters, updateStatus.success, createStatus.success]);

  return (
    <Table
      rows={rows.contacts || []}
      columns={columns}
      page={filters.page}
      setPage={page => setFilters({ ...filters, page })}
      pageSize={filters.limit}
      onPageSizeChange={limit => setFilters({ ...filters, limit })}
      filterMode="server"
      loading={status.loading}
      totalCount={rows.totalCount}
      localeText={generateLocaleGridText("contact")}
      detailLink={PATHS.CONTACTS_DETAIL}
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <EmptyContactTable />
          </EmptyTable>
        ),
      }}
      // error={status.error}
    />
  );
};

export default ProviderTable;
