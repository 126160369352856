import React, { FC } from "react";

import { GridColDef } from "@material-ui/data-grid";

import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { BRAZIL_UF } from "src/constants/uf";
import { ContactType } from "src/interfaces/contact";
import { formatPhone, renderDocument } from "src/utils/format";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Nome",
    renderCell: params => (params.row?.name ? params.row?.name : "-"),
    flex: 1,
    sortComparator: (v1, v2) => {
      const name = (v1 as string)?.toLowerCase() ?? "-";
      const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

      return name.localeCompare(nameComparable);
    },
  },
  {
    field: "cpf",
    headerName: "CPF/CNPJ",
    flex: 1,
    renderCell: params => renderDocument(params.row?.cpf, params.row?.cnpj),
    valueGetter: params => renderDocument(params.row?.cpf, params.row?.cnpj),
    sortComparator: (v1, v2) => {
      const document = v1 as string;
      const documentComparable = v2 as string;

      return document.localeCompare(documentComparable);
    },
  },
  {
    field: "email",
    headerName: "E-mail",
    renderCell: params => (params.row?.email ? params.row?.email : "-"),
    flex: 1,
    sortComparator: (v1, v2) => {
      const email = (v1 as string)?.toLowerCase();
      const emailComparable = (v2 as string)?.toLowerCase();

      return email.localeCompare(emailComparable);
    },
  },
  {
    field: "phone",
    headerName: "Telefone",
    renderCell: params => (params.value ? formatPhone(params.value) : "-"),
    flex: 1,
  },
  {
    field: "uf",
    headerName: "Estado",
    renderCell: params =>
      BRAZIL_UF.find(uf => params.value === uf.uf)?.name ?? "-",
    flex: 1,
  },
  {
    field: "tags",
    headerName: "Marcadores",
    renderCell: params =>
      Array.isArray(params.value)
        ? params.value.map(tag => tag.label).join(", ")
        : "-",
    flex: 1,
  },
];

export interface ClientsTableProps {
  clients: ContactType[];
  page: number;
  setPage: (value: number) => void;
  limit: number;
  setLimit: (value: number) => void;
}

const ClientsTable: FC<ClientsTableProps> = ({
  clients,
  page,
  setPage,
  limit,
  setLimit,
}) => {
  return (
    <Table
      sortingOrder={["desc", "asc"]}
      rows={clients}
      columns={columns}
      page={page}
      setPage={setPage}
      pageSize={limit}
      onPageSizeChange={setLimit}
      filterMode="server"
      totalCount={clients.length}
      hideFooter
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <Typography>Nenhuma relatório disponível</Typography>
          </EmptyTable>
        ),
      }}
    />
  );
};

export default ClientsTable;
