import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { LotType } from "src/interfaces/product";
import { GetResponseType } from "src/interfaces/request";
import api from "src/services/api";

export interface GetLotsResponseType extends GetResponseType {
  movements: LotType[];
}

interface LotsParams {
  id: string;
  page?: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
}

export const getLots = (params: LotsParams) =>
  api
    .get<GetLotsResponseType>(`v1/product/${params.id}/lots`, {
      params,
    })
    .then(res => res.data);

export function useLots(
  params: LotsParams,
  config?: UseQueryOptions<
    GetLotsResponseType,
    Error,
    GetLotsResponseType,
    unknown[]
  >
) {
  return useQuery<GetLotsResponseType, Error, GetLotsResponseType, unknown[]>(
    ["lots", params],
    () => getLots(params),
    {
      refetchOnWindowFocus: false,
      ...(config || {}),
      initialData: {
        movements: [],
        limit: 10,
        page: 1,
        totalCount: 0,
      },
    }
  );
}
