import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ContactType, TypeOfContact } from "src/interfaces/contact";
import { GetResponseType } from "src/interfaces/request";
import api from "src/services/api";

export interface GetContactsResponseType extends GetResponseType {
  contacts: ContactType[];
}

interface ContactsParams {
  page?: number;
  limit?: number;
  search?: string;
  type?: TypeOfContact;
  tags?: string;
}

export const getContacts = (params: ContactsParams) =>
  api
    .get<GetContactsResponseType>("v2/contact", {
      params,
    })
    .then(res => res.data);

export function useContacts(
  params: ContactsParams,
  config?: UseQueryOptions<
    GetContactsResponseType,
    Error,
    GetContactsResponseType,
    unknown[]
  >
) {
  return useQuery<
    GetContactsResponseType,
    Error,
    GetContactsResponseType,
    unknown[]
  >(["contacts", params], () => getContacts(params), {
    refetchOnWindowFocus: false,
    ...(config || {}),
    initialData: {
      contacts: [],
      limit: 10,
      page: 1,
      totalCount: 0,
    },
  });
}
