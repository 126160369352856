import styled from "styled-components";

import MuiTooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

import ButtonUnstyled from "src/components/Button";

export const TooltipIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 24px;
  position: absolute;
  right: 2rem;
`;

export const Tooltip = styled(MuiTooltip)`
  & > .custom__tooltip {
    background-color: red;
  }
`;

export const DeleteButton = styled(ButtonUnstyled)`
  padding: 12px;
  height: 50px;
  color: ${({ theme }) => theme.palette.error.main};
  border-color: ${({ theme }) => theme.palette.error.main};
  &.Mui-disabled {
    opacity: 0.5;
    color: ${({ theme }) => theme.palette.error.main} !important;
    border-color: ${({ theme }) => theme.palette.error.main} !important;
  }
  &:hover {
    border-color: ${({ theme }) => theme.palette.error.main} !important;
  }
`;
