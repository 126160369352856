import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { BuyOrderType } from "src/interfaces/buyOrder";
import api from "src/services/api";

export interface UpdateQuickBuyOrderParams {
  id: string;
  deadline?: string | null;
  additionalInfo?: string | null;
}

export const updateQuickBuyOrder = (params: UpdateQuickBuyOrderParams) =>
  api.patch<BuyOrderType>(`v1/buy-order`, params).then(res => res.data);

export function useUpdateQuickBuyOrder(
  config?: UseMutationOptions<any, any, UpdateQuickBuyOrderParams, unknown>
) {
  return useMutation<any, any, UpdateQuickBuyOrderParams>(
    (data: UpdateQuickBuyOrderParams) => updateQuickBuyOrder(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ??
            "Ocorreu um problema ao editar ordem de compra"
        );
      },
      ...config,
    }
  );
}
