import React from "react";

import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { format } from "date-fns";

import { Event } from "src/interfaces/events";
import { SchedulingType } from "src/interfaces/scheduling";

interface TypeEvent {
  event: Event;
}

export default function WeekEvent({ event }: TypeEvent) {
  const Scheduling = event.model as SchedulingType;
  const data = format(event.start, "d MMM yyyy");

  function renderSubTitle() {
    if (event.type === "scheduling") {
      return event.eventHours;
    }
    return event.subTitle;
  }
  function renderTitle() {
    if (Scheduling.isDoneAt) {
      return (
        <Text fontSize="xs" as="del">
          {event.title}
        </Text>
      );
    }
    return <Text fontSize="xs">{event.title}</Text>;
  }

  return (
    <Flex
      h="40"
      bg="secondary.100"
      borderRadius="md"
      borderWidth="thin"
      borderColor="gray.700"
      direction="column"
    >
      <Box bg="gray.700" w="4" h="3" borderBottomRightRadius="lg" />
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="space-around"
        h="100%"
      >
        <Center flexDirection="column">
          <Text color="gray" fontSize="xs" fontWeight="bold">
            {renderTitle()}
          </Text>
          <Text fontSize="xs" color="gray" fontWeight="medium">
            {data}
          </Text>
        </Center>
        <Text fontSize="xs" fontWeight="bold">
          {event.title}
        </Text>
        <Center flexDirection="column">
          <Text fontSize="xs"> {renderSubTitle()}</Text>
        </Center>
      </Flex>
    </Flex>
  );
}
