import { Navigate, RouteObject } from "react-router-dom";

import PATHS from "src/constants/paths";
import ServiceOrderAddPage from "src/pages/Orders/Add/Service";
import ServiceOrderDetailPage from "src/pages/Orders/Detail/Service";
import ServiceOrderListPage from "src/pages/Orders/List/Service";
import ServiceOrderUpdatePage from "src/pages/Orders/Update/Service";

export const serviceOrderRoutes: RouteObject[] = [
  {
    path: PATHS.SERVICE_ORDER_LIST,
    element: <ServiceOrderListPage />,
    children: [
      {
        path: PATHS.SERVICE_ORDER_ADD,
        element: <ServiceOrderAddPage />,
      },
      {
        path: PATHS.SERVICE_ORDER_DETAIL,
        element: <ServiceOrderDetailPage />,
      },
      {
        path: PATHS.SERVICE_ORDER_UPDATE,
        element: <ServiceOrderUpdatePage />,
      },
      {
        path: "*",
        element: <Navigate to={PATHS.SERVICE_ORDER_LIST} replace />,
      },
    ],
  },
];
