/* eslint-disable react/no-array-index-key */
import React, { FC, useRef } from "react";

import Grid from "@material-ui/core/Grid";
import { FieldArray, Form, Formik, FormikProps } from "formik";

import Button from "src/components/Button";
import CloseButton from "src/components/CommonButtons/CloseButton";
import KeyboardDatePicker from "src/components/Inputs/KeyboardDatePicker";
import SelectField from "src/components/Inputs/SelectField";
import Textarea from "src/components/Inputs/Textarea";
import TextField from "src/components/Inputs/TextField";
import Loader from "src/components/Loader";
import { CONTACT_TYPE } from "src/constants/contacts";
import { useContact } from "src/hooks/contact/useContact";
import { useModule } from "src/hooks/module/useModule";
import { ContactType } from "src/interfaces/contact";
import { extractNumbers } from "src/utils/extractNumbers";
import formUtil from "src/utils/formUtil";

import TagsField from "../TagsField/TagsField";
import AddressFields from "./AddressFields";
import DocumentField from "./DocumentField";
import { ContactFormProps } from "./props";
import { schemaContactForm } from "./schema";
import * as Styled from "./styles";

const ContactForm: FC<ContactFormProps> = props => {
  const { method, data, isModal = false, contactType } = props;

  const { hasModules } = useModule();

  const formikRef = useRef<FormikProps<ContactType>>(null);

  const { add, update, createStatus, updateStatus, status } = useContact();

  const onSubmit = async (data: ContactType) => {
    const removedEmptyEntries = data?.alternativePhones?.filter(
      phone => phone.number !== ""
    );

    const normalizedAlternativePhones = removedEmptyEntries?.map(phone => ({
      ...phone,
      number: extractNumbers(phone.number),
    }));

    data.alternativePhones = normalizedAlternativePhones;

    if (method === "add") {
      await add(data);
      formikRef.current?.resetForm();
    }

    if (method === "update") {
      await update(data);
    }
  };

  if (
    (createStatus.loading || updateStatus.loading || status.loading) &&
    !isModal
  )
    return <Loader size="medium" />;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={
        {
          id: data?.id,
          type: contactType || data?.type || "customer",
          name: data?.name || "",
          phone: data?.phone,
          email: data?.email,
          street: data?.street || "",
          cpf: data?.cpf,
          cnpj: data?.cnpj,
          birthDate: data?.birthDate,
          observation: data?.observation,
          cep: data?.cep || "",
          number: data?.number || "",
          uf: data?.uf || "",
          neighborhood: data?.neighborhood || "",
          city: data?.city || "",
          complement: data?.complement || "",
          alternativePhones: data?.alternativePhones || [],
          tags: data?.tags || [],
        } as ContactType
      }
      validationSchema={schemaContactForm}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <Form id="contactForm" onKeyDown={formUtil.preventEnterOnKeyDown}>
          <Styled.Accordion
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded
            square
          >
            <Styled.AccordionSummary
              expandIcon={<Styled.ExpandIcon color="primary" />}
            >
              <Styled.TitleSection>Dados pessoais</Styled.TitleSection>
            </Styled.AccordionSummary>

            <Styled.AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={isModal ? 12 : 6}>
                  <SelectField
                    label="Tipo de contato"
                    name="type"
                    items={CONTACT_TYPE}
                    displayEmpty
                    defaultValue="customer"
                    disabled={method === "update" || !!contactType}
                  />
                </Grid>
                <Grid item xs={isModal ? 12 : 6}>
                  <TextField label="Nome" name="name" required />
                </Grid>
                <Grid item xs={isModal ? 12 : 6}>
                  <KeyboardDatePicker
                    label="Data de nascimento"
                    name="birthDate"
                  />
                </Grid>
                <Grid item xs={isModal ? 12 : 6}>
                  <TextField label="E-mail" name="email" />
                </Grid>
                <Grid item xs={isModal ? 12 : 6}>
                  <DocumentField />
                </Grid>
                {hasModules("contactTags") ? (
                  <Grid item xs={isModal ? 12 : 6}>
                    <TagsField
                      tags={values.tags}
                      setTags={tags => setFieldValue("tags", tags)}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Styled.AccordionDetails>
          </Styled.Accordion>

          <Styled.Accordion
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded={!isModal}
            square
          >
            <Styled.AccordionSummary
              expandIcon={<Styled.ExpandIcon color="primary" />}
            >
              <Styled.TitleSection>Endereço</Styled.TitleSection>
            </Styled.AccordionSummary>

            <Styled.AccordionDetails>
              <Grid container spacing={2}>
                <AddressFields isModal={isModal} />
              </Grid>
            </Styled.AccordionDetails>
          </Styled.Accordion>

          <Styled.Accordion
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded={!isModal}
            square
          >
            <Styled.AccordionSummary
              expandIcon={<Styled.ExpandIcon color="primary" />}
            >
              <Styled.TitleSection>Contatos</Styled.TitleSection>
            </Styled.AccordionSummary>

            <Styled.AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={isModal ? 12 : 6}>
                  <TextField
                    label="Whatsapp"
                    name="phone"
                    mask="(99) 99999-9999"
                  />
                </Grid>

                <FieldArray
                  name="alternativePhones"
                  render={arrayHelpers => (
                    <>
                      {values?.alternativePhones?.map((_, index) => (
                        <Grid item xs={isModal ? 12 : 6} key={index}>
                          <TextField
                            label="Telefone alternativo"
                            name={`alternativePhones[${index}].number`}
                            mask="(99) 99999-9999"
                            InputProps={{
                              endAdornment: (
                                <CloseButton
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                />
                              ),
                            }}
                          />
                        </Grid>
                      ))}

                      {values?.alternativePhones?.length < 10 && (
                        <Grid item xs={isModal ? 12 : 6}>
                          <Button
                            onClick={() => {
                              arrayHelpers.push({
                                type: "whatsapp",
                                number: "",
                              });
                            }}
                          >
                            <Styled.AddCircleIcon color="primary" />
                            Adicionar telefone
                          </Button>
                        </Grid>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Styled.AccordionDetails>
          </Styled.Accordion>

          <Styled.Accordion
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded={!isModal}
            square
          >
            <Styled.AccordionSummary
              expandIcon={<Styled.ExpandIcon color="primary" />}
            >
              <Styled.TitleSection>Outras informações</Styled.TitleSection>
            </Styled.AccordionSummary>

            <Styled.AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={isModal ? 12 : 6}>
                  <Textarea
                    label="Observação"
                    name="observation"
                    placeholder="Este local é para informações adicionais sobre o contato"
                    rows={7}
                  />
                </Grid>
              </Grid>
            </Styled.AccordionDetails>
          </Styled.Accordion>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
