import styled from "styled-components";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpwardRounded";

import Typography from "src/components/Typography";

export const LabelPendencies = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  color: #92969c;
  margin: 0;
`;

export const QuantityPendencies = styled(Typography)<{ redColor?: boolean }>`
  font-size: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme, redColor }) =>
    redColor ? theme.palette.error.main : theme.palette.primary.main};
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const PendenciesValue = styled(Typography)<{ redColor?: boolean }>`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme, redColor }) =>
    redColor ? theme.palette.error.main : theme.palette.primary.main};

  span {
    display: block;
    font-size: 10px;
    font-weight: 400;
    color: #92969c;
  }
`;

export const IncomePendenciesIcon = styled(ArrowUpwardIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;
export const ExpensePendenciesIcon = styled(ArrowDownwardIcon)`
  color: ${({ theme }) => theme.palette.error.main};
`;
