import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface DeleteRecordParams {
  id: string;
  deleteType: "current" | "currentAndNext";
}

export const deleteRecord = (params: DeleteRecordParams) =>
  api.delete(`v1/record/${params.id}`, { params }).then(res => res.data);

export function useDeleteRecord(
  config?: UseMutationOptions<any, any, DeleteRecordParams, unknown>
) {
  return useMutation<any, any, DeleteRecordParams>(
    (data: DeleteRecordParams) => deleteRecord(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ??
            "Ocorreu um problema ao deletar lançamento(s)"
        );
      },
      ...config,
    }
  );
}
