import React, { useMemo } from "react";

import { Box, Grid } from "@material-ui/core";
import { Form } from "formik";

import { BuyOrderType, ProductBuyOrderFormType } from "src/interfaces/buyOrder";
import { ContactType } from "src/interfaces/contact";
import { sumArrayValues } from "src/utils/arrayUtil";
import formUtil from "src/utils/formUtil";

import AditionalInfoTextAreaSection from "./parts/AditionalInfoTextAreaSection";
import ConditionPaymentSection from "./parts/ConditionPaymentSection";
import CreatedDateSection from "./parts/CreatedDateSection";
import DeadlineSection from "./parts/DeadlineSection";
import DiscountSection from "./parts/DiscountSection";
import ProductBuyOrderManager from "./parts/ProductsBuyOrderManager";
import ProviderSection from "./parts/ProviderSection";

interface BuyOrderFormProps {
  productsBuyOrder: ProductBuyOrderFormType[];
  setProductsBuyOrder: (value: ProductBuyOrderFormType[]) => void;
  discount: number;
  setDiscount: (value: number) => void;
  provider?: ContactType;
  setProvider: (value: ContactType) => void;
  buyOrder: BuyOrderType;
  method: "post" | "update";
}

const BuyOrderForm: React.FC<BuyOrderFormProps> = props => {
  const {
    productsBuyOrder,
    setProductsBuyOrder,
    discount,
    setDiscount,
    provider,
    setProvider,
    buyOrder,
    method,
  } = props;

  const productsTotal = useMemo(() => {
    const productsTotal = productsBuyOrder.map(
      productBuyOrder => productBuyOrder.cost * productBuyOrder.quantity
    );

    return sumArrayValues(productsTotal);
  }, [productsBuyOrder]);

  return (
    <Form id="createBuyOrderForm" onKeyDown={formUtil.preventEnterOnKeyDown}>
      <ProviderSection provider={provider} setProvider={setProvider} />

      <Box mt={2} />

      <Grid container>
        <Grid item xs={12} md={5} lg={3}>
          <DeadlineSection />
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          <CreatedDateSection />
        </Grid>
      </Grid>

      <ProductBuyOrderManager
        setProductsBuyOrder={setProductsBuyOrder}
        productsBuyOrder={productsBuyOrder}
      />

      <Grid item xs={12}>
        <ConditionPaymentSection
          total={productsTotal - discount}
          buyOrder={buyOrder}
          method={method}
        />
      </Grid>

      <Grid item xs={12}>
        <DiscountSection
          total={productsTotal}
          discount={discount}
          setDiscount={setDiscount}
        />
      </Grid>

      <Box mt={2} />

      <Grid item xs={12}>
        <AditionalInfoTextAreaSection />
      </Grid>
    </Form>
  );
};

export default BuyOrderForm;
