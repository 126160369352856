import { useEffect, useMemo } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";

import { Flex, Image } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { CurrencyInput } from "src/components/NewComponents/CurrencyInput";
import { DatePicker } from "src/components/NewComponents/DatePicker";
import { Select } from "src/components/NewComponents/Select";
import RenderModule from "src/components/RenderModule";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { Option } from "src/interfaces/common";
import { RecordType } from "src/interfaces/record";
import { useCashFlowOpened } from "src/services/hooks/cashFlow/useCashFlowOpened";
import { useActiveFinancialAccounts } from "src/services/hooks/financialAccount/useActiveFinancialAccounts";
import { usePayRecord } from "src/services/hooks/record/usePayRecord";
import { formatDateToISO } from "src/utils/date";
import { groupFinancialAccounts } from "src/utils/financialAccount";
import { formatPaidDateToISO } from "src/utils/record";

import { schemaPayRecordForm } from "./schema";

export type PayRecordSchema = yup.InferType<typeof schemaPayRecordForm>;

interface PayRecordModalType {
  record?: RecordType;
  handleClosePayRecordModal?: () => void;
  onSuccess?: (record: RecordType) => void;
}

function PayRecordModal({
  record,
  handleClosePayRecordModal,
  onSuccess,
}: PayRecordModalType) {
  const queryClient = useQueryClient();
  const { userCompany } = useCompany();
  const { hasModules } = useModule();
  const { handleClose } = useBaseSwipeableDrawer();

  const { data: accounts } = useActiveFinancialAccounts(undefined, {
    enabled: hasModules("accountManagement"),
  });

  const { data: cashFlowOpened } = useCashFlowOpened({
    enabled: hasModules("cashflowcontrol"),
    retry: 1,
    retryDelay: 500,
  });

  const getInitialFinancialAccount = () => {
    if (cashFlowOpened) {
      return cashFlowOpened?.financialAccount as unknown as string;
    }
    if (accounts.length) {
      return accounts.find(
        account => account.type !== "cash" && account.isDefault
      )?.id;
    }
    return undefined;
  };

  const getInitialPaymentMethod = () => {
    if (cashFlowOpened && record?.type === "income") {
      return userCompany?.paymentMethods
        ?.filter(paymentMethod => paymentMethod.status === "enabled")
        .find(paymentMethod => paymentMethod.slug === "money")?.id;
    }

    return undefined;
  };

  useEffect(() => {
    reset({
      ...defaultValues,
      financialAccount: getInitialFinancialAccount(),
      paymentMethodId: getInitialPaymentMethod(),
    });
  }, [cashFlowOpened, record, accounts]);

  const defaultValues: PayRecordSchema = {
    id: record?.id as string,
    type: record?.type || "income",
    value: record?.value,
    paidDate: formatDateToISO(new Date()),
    hasAccountManagement: hasModules("accountManagement"),
    financialAccount: getInitialFinancialAccount(),
    paymentMethodId: getInitialPaymentMethod(),
  };

  const paymentMethodsItems = useMemo(() => {
    return (
      userCompany?.paymentMethods
        ?.filter(item => item.status === "enabled")
        .map(method => ({
          label: method.title,
          value: method.id,
        })) ?? []
    );
  }, [userCompany]);

  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm<PayRecordSchema>({
    defaultValues,
    resolver: yupResolver(schemaPayRecordForm),
  });

  const paymentMethodId = useWatch({ name: "paymentMethodId", control });
  const financialAccount = useWatch({ name: "financialAccount", control });
  const type = useWatch({ name: "type", control });

  const handleCustomClose = () => {
    reset(defaultValues);
    if (handleClosePayRecordModal) {
      handleClosePayRecordModal();
    }
    handleClose("payRecord");
  };

  const financialAccountsGrouped = useMemo(() => {
    return groupFinancialAccounts(
      accounts.filter(account => {
        if (cashFlowOpened) {
          return (
            account.type !== "cash" ||
            account.id ===
              (cashFlowOpened?.financialAccount as unknown as string)
          );
        }
        return true;
      })
    );
  }, [accounts, cashFlowOpened]);

  const { mutate: payRecord, isLoading } = usePayRecord({
    onSuccess: record => {
      toast.success("Lançamento pago com sucesso.");
      if (onSuccess) onSuccess(record);
      handleCustomClose();
      queryClient.invalidateQueries({
        queryKey: ["schedulingAllTypes"],
      });
    },
  });

  const onSubmit = ({
    id,
    paymentMethodId,
    paidDate,
    value,
    financialAccount,
  }: PayRecordSchema) => {
    payRecord({
      id,
      paymentMethodId,
      paidDate: formatPaidDateToISO(paidDate),
      value: Number(value),
      financialAccount,
    });
  };

  return (
    <BaseSwipeableDrawer
      tag="payRecord"
      title="Pagamento"
      buttonTitle="Confirmar pagamento"
      buttonProps={{
        loading: isLoading,
        disabled: isLoading,
        form: "payRecordForm",
      }}
      zIndex={1302}
      customHandleClose={handleCustomClose}
    >
      <Flex
        w={["100%", "350px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="payRecordForm"
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
        direction="column"
      >
        <CurrencyInput
          label="Valor"
          error={errors.value}
          control={control}
          name="value"
        />
        <DatePicker
          label="Data de pagamento"
          error={errors.paidDate}
          control={control}
          name="paidDate"
        />
        {type === "income" ? (
          <Controller
            control={control}
            name="paymentMethodId"
            render={({ field: { ref, onChange } }) => (
              <Select
                options={paymentMethodsItems}
                inputRef={ref}
                error={!!errors.paymentMethodId}
                errorMessage={errors.paymentMethodId?.message}
                placeholder="Forma de pagamento"
                isClearable={false}
                onChange={paymentMethodId => {
                  if (hasModules("cashflowcontrol")) {
                    const paymentMethodSlugSelected =
                      userCompany.paymentMethods?.find(
                        method => method.id === paymentMethodId
                      )?.slug;

                    const financialAccountTypeSelected = accounts.find(
                      account => account.id === financialAccount
                    )?.type;

                    if (
                      (financialAccountTypeSelected === "cash" &&
                        paymentMethodSlugSelected !== "money") ||
                      (financialAccountTypeSelected !== "cash" &&
                        paymentMethodSlugSelected === "money")
                    ) {
                      setValue("financialAccount", undefined);
                    }
                  }
                  onChange(paymentMethodId);
                }}
                value={paymentMethodId}
              />
            )}
          />
        ) : null}
        <RenderModule modules={["accountManagement"]}>
          <Controller
            control={control}
            name="financialAccount"
            render={({ field: { ref, onChange } }) => (
              <Select
                options={financialAccountsGrouped}
                inputRef={ref}
                error={!!errors.financialAccount}
                errorMessage={errors.financialAccount?.message}
                placeholder="Conta"
                isClearable={false}
                formatOptionLabel={(data: unknown) => {
                  const account = data as Option;
                  return (
                    <Flex alignItems="center" gap={2}>
                      <Image
                        borderRadius="full"
                        boxSize="24px"
                        src={account?.url}
                        alt={account.label as string}
                      />
                      {account.label}
                    </Flex>
                  );
                }}
                onChange={financialAccount => {
                  onChange(financialAccount);
                  if (hasModules("cashflowcontrol")) {
                    const paymentMethodSlugSelected =
                      userCompany.paymentMethods?.find(
                        method => method.id === paymentMethodId
                      )?.slug;

                    const financialAccountTypeSelected = accounts.find(
                      account => account.id === financialAccount
                    )?.type;

                    if (
                      (paymentMethodSlugSelected === "money" &&
                        financialAccountTypeSelected !== "cash") ||
                      (paymentMethodSlugSelected !== "money" &&
                        financialAccountTypeSelected === "cash")
                    ) {
                      setValue("paymentMethodId", undefined);
                    }
                  }
                }}
                value={financialAccount}
              />
            )}
          />
        </RenderModule>
      </Flex>
    </BaseSwipeableDrawer>
  );
}

export default PayRecordModal;
