import React, { useMemo, useState } from "react";

import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { PAGE_ORDER_TITLES } from "src/constants/orders";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { useOrder } from "src/hooks/order/useOrder";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";
import { useViewOrder } from "src/hooks/order/useViewOrder";
import { OrderListType } from "src/interfaces/order";
import { RecordType } from "src/interfaces/record";
import PayRecordModal from "src/pages/Financial/components/Modals/PayRecordModal";
import { useOrderType } from "src/pages/Orders/useOrderType";
import { generateLocaleGridText } from "src/utils/localGridText";
import { withContext } from "src/utils/withContext";

import RenderOrderStatus from "../../../components/RenderOrderStatus";
import MoreOptionsMenu from "../MoreOptionsMenu";
import { PaymentColumn } from "./components/PaymentColumn";
import RenderContactCell from "./components/RenderContactCell";
import {
  renderCurrencyValue,
  renderDate,
  renderDateAndHour,
  renderDocument,
  renderLocation,
  renderSimpleValue,
} from "./formatters";
import { FormatterMapType, OrderTableProps } from "./props";
import * as Styled from "./styles";

const renderOptionsButton = (params: GridValueFormatterParams) => (
  <MoreOptionsMenu id={params.id as string} order={params.row as OrderListType}>
    <MoreVertIcon color="primary" />
  </MoreOptionsMenu>
);

const EmptyTableContent = () => (
  <div>
    <Styled.OrderIcon />
    <Typography variant="subtitle1">
      Você ainda não possui vendas para serem visualizados nesta lista!
    </Typography>
    <Typography variant="subtitle1">
      comece <Styled.MarkedText> cadastrando as suas vendas</Styled.MarkedText>
    </Typography>
  </div>
);

const OrderTable: React.FC<OrderTableProps> = props => {
  const { page, setPage, limit, setLimit, totalCount } = props;
  const { orders, status } = useOrder();
  const { serviceOrderNameDefinitions } = useCompany();
  const { type } = useOrderType();
  const { serviceOrderModule, productOrderModule } = useModule();
  const { tableFields } = useViewOrder();
  const { setRestartOrderList } = useOrderFilters();

  const { handleOpen } = useBaseSwipeableDrawer();
  const [recordSelected, setRecordSelected] = useState<undefined | RecordType>(
    undefined
  );

  const serviceOrderDeadlineFormatter = useMemo(() => {
    if (serviceOrderModule?.settings?.dueDateType === "date") {
      return renderDate;
    }

    return renderDateAndHour;
  }, [serviceOrderModule]);

  const serviceOrderCreatedDateFormatter = useMemo(() => {
    if (serviceOrderModule?.settings?.createdAtType === "date") {
      return renderDate;
    }

    return renderDateAndHour;
  }, [serviceOrderModule]);

  const productOrderDeadlineFormatter = useMemo(() => {
    if (productOrderModule?.settings?.dueDateType === "date") {
      return renderDate;
    }

    return renderDateAndHour;
  }, [productOrderModule]);

  const productOrderCreatedDateFormatter = useMemo(() => {
    if (productOrderModule?.settings?.createdAtType === "date") {
      return renderDate;
    }

    return renderDateAndHour;
  }, [productOrderModule]);

  const formatterMap: FormatterMapType = {
    code: undefined,
    controlNumber: renderSimpleValue,
    contact: ({ row }) => <RenderContactCell order={row as OrderListType} />,
    document: renderDocument,
    location: renderLocation,
    createdDate: serviceOrderCreatedDateFormatter,
    deadline: serviceOrderDeadlineFormatter,
    status: ({ row }) => <RenderOrderStatus order={row as OrderListType} />,
    isPaid: ({ row }) => (
      <PaymentColumn
        order={row as OrderListType}
        handlePayRecord={(record: RecordType) => {
          setRecordSelected(record);
          handleOpen("payRecord");
        }}
      />
    ),
    discount: renderCurrencyValue,
    totalAmount: renderCurrencyValue,
    additionalInfo: undefined,
  };

  const serviceOrderColumns = useMemo(() => {
    const enabledFields = tableFields.filter(field => field.enabled);
    const fields: GridColDef[] = enabledFields.map(field => {
      return {
        field: field.name,
        headerName:
          field.name === "code"
            ? `Nº ${serviceOrderNameDefinitions.abbreviation}`
            : field.label,
        valueFormatter: formatterMap[field.name],
        renderCell: ["status", "isPaid", "contact"].includes(field.name)
          ? formatterMap[field.name]
          : undefined,
        flex: field.flex,
      };
    });

    fields.push({
      field: "options",
      headerName: " ",
      renderCell: renderOptionsButton,
      width: 10,
    });

    return fields;
  }, [tableFields]);

  const productOrderColumns: GridColDef[] = [
    {
      field: "code",
      headerName: PAGE_ORDER_TITLES()[type].tableHeader,
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Cliente",
      renderCell: ({ row }) => (
        <RenderContactCell order={row as OrderListType} />
      ),
      flex: 3,
    },
    {
      field: "totalAmount",
      headerName: "Valor",
      renderCell: renderCurrencyValue,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Data de criação",
      valueFormatter: productOrderCreatedDateFormatter,
      flex: 2,
    },
    {
      field: "deadline",
      headerName: "Prazo de entrega",
      valueFormatter: productOrderDeadlineFormatter,
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => (
        <RenderOrderStatus order={row as OrderListType} />
      ),
      headerAlign: "center",
      align: "center",
      flex: 3,
    },
    {
      field: "isPaid",
      headerName: "Pagamento",
      renderCell: ({ row }) => (
        <PaymentColumn
          order={row as OrderListType}
          handlePayRecord={(record: RecordType) => {
            setRecordSelected(record);
            handleOpen("payRecord");
          }}
        />
      ),
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "options",
      headerName: " ",
      renderCell: renderOptionsButton,
      align: "right",
      flex: 1,
    },
  ];

  return (
    <>
      {recordSelected ? (
        <PayRecordModal
          record={recordSelected}
          handleClosePayRecordModal={() => {
            setRecordSelected(undefined);
          }}
          onSuccess={() => {
            setRestartOrderList(true);
          }}
        />
      ) : null}

      <Table
        rows={orders.orders || []}
        columns={
          type === "productOrder" ? productOrderColumns : serviceOrderColumns
        }
        page={page}
        setPage={setPage}
        pageSize={limit}
        onPageSizeChange={setLimit}
        filterMode="server"
        loading={status.loading}
        totalCount={totalCount}
        localeText={generateLocaleGridText("orders")}
        detailLink={PAGE_ORDER_TITLES()[type].paths.detail}
        detailLinkParamName="orderId"
        excludeCellClick={["options", "status", "isPaid"]}
        components={{
          NoRowsOverlay: () => (
            <EmptyTable>
              <EmptyTableContent />
            </EmptyTable>
          ),
        }}
        // error={status.error}
      />
    </>
  );
};

export default withContext(OrderTable, BaseSwipeableDrawerProvider);
