import React, { FC } from "react";

import { Transition } from "src/components/Modal";

import SchedulingForm from "../../SchedulingForm";
import { SchedulingFormModalProps } from "./props";
import * as Styled from "./styles";

const SchedulingFormModal: FC<SchedulingFormModalProps> = props => {
  const { handleClose, open, method, data } = props;

  return (
    <Styled.Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="md"
    >
      <Styled.Container>
        <SchedulingForm
          method={method}
          open={open}
          data={data}
          handleClose={handleClose}
        />
      </Styled.Container>
    </Styled.Dialog>
  );
};

export default SchedulingFormModal;
