import React, { useState, createContext, useContext, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { PAGE_ORDER_TITLES } from "src/constants/orders";
import PATHS from "src/constants/paths";
import { STATUS } from "src/constants/requestStatus";
import { useCompany } from "src/hooks/company/useCompany";
import { useImage } from "src/hooks/image/useImage";
import { useModule } from "src/hooks/module/useModule";
import { ContactType, SubContactType } from "src/interfaces/contact";
import {
  GetOrderResponseType,
  OrderType,
  TypeOfOrder,
  OrderListParamsType,
  ProductOrderType,
  ServiceOrderType,
  OrderListType,
  OrderDetailType,
  TechnicalReportOrderType,
  GalleryType,
} from "src/interfaces/order";
import { UserType } from "src/interfaces/user";
import { setLogEvent } from "src/services/firebase";
import {
  getOrders,
  getOrder,
  updateOrder,
  deleteOrder,
  addOrder,
  createOrderStockService,
  deleteOrderStockService,
  deleteOrderRecordsService,
  createOrderRecordsService,
} from "src/services/order";
import { formatDateToISO, formatEndDateISO } from "src/utils/date";
import { getRouteString } from "src/utils/urlHandler";

import { MethodType, OrderContextType } from "./props";
import { StatusOrderActionEnum, statusOrderReducer } from "./reducer";

export const OrderContext = createContext({} as OrderContextType);

export const OrderProvider: React.FC = props => {
  const { children } = props;

  const { serviceOrderNameDefinitions } = useCompany();

  const { serviceOrderModule, hasModules } = useModule();
  const { uploadAndReturnUrl } = useImage();

  const navigate = useNavigate();
  const [stateStatuses, dispatch] = useReducer(statusOrderReducer, {
    updateStatus: STATUS.inital,
    listStatus: STATUS.inital,
    createStatus: STATUS.inital,
    deleteStatus: STATUS.inital,
    detailStatus: STATUS.inital,
  });

  const [order, setOrder] = useState<OrderDetailType>({} as OrderDetailType);
  const [orders, setOrders] = useState<GetOrderResponseType>(
    {} as GetOrderResponseType
  );

  const [subContact, setSubContact] = useState({} as SubContactType);
  const [customer, setCustomer] = useState({} as ContactType);
  const [operator, setOperator] = useState({} as UserType);
  const [products, setProducts] = useState<ProductOrderType[]>([]);
  const [productsRegistered, setProductsRegistered] = useState<
    ProductOrderType[]
  >([]);

  const [services, setServices] = useState<ServiceOrderType[]>([]);
  const [servicesRegistered, setServicesRegistered] = useState<
    ServiceOrderType[]
  >([]);

  const [technicalReport, setTechnicalReport] = useState<
    TechnicalReportOrderType | undefined
  >(undefined);
  const [technicalReportRegistered, setTechnicalReportRegistered] = useState<
    TechnicalReportOrderType | undefined
  >(undefined);

  const [method, setMethod] = useState<MethodType>("post");

  const formatServicesToOrder = () => {
    if (services.length > 0) {
      return services.map(service => ({
        id: service.id,
        quantity: service.quantity,
        price: service.servicePrice,
      }));
    }

    return undefined;
  };

  const formatProductsToOrder = () => {
    if (products.length > 0) {
      return products.map(product => {
        const stock = hasModules("stock")
          ? product.product?.stock?.id
          : undefined;
        return {
          id: product.id,
          quantity: product.quantity,
          price: product.productPrice,
          stock,
        };
      });
    }

    return undefined;
  };

  const formatProductsToUpdateOrder = () => {
    const productsToUpdate: ProductOrderType[] = [];

    productsRegistered.forEach(product => {
      const productToDelete = !products.some(item => item.id === product.id);

      if (productToDelete) {
        const data: any = {
          id: product.id,
          price: product.productPrice,
          quantity: product.quantity,
          delete: true,
        };

        productsToUpdate.push(data);
      }
    });

    products.forEach(product => {
      const productToCreate = productsRegistered.every(
        item => item.id !== product.id
      );

      const productToUpdate = productsRegistered.some(
        item => item.id === product.id
      );

      const data: any = {
        price: product.productPrice,
        quantity: product.quantity,
      };

      if (productToCreate) {
        data.productId = product.product.id;
        productsToUpdate.push(data);
      }

      if (productToUpdate) {
        data.id = product.id;
        productsToUpdate.push(data);
      }
    });

    return productsToUpdate;
  };

  const formatServicesToUpdateOrder = () => {
    const servicesToUpdate: ServiceOrderType[] = [];

    servicesRegistered.forEach(service => {
      const serviceToDelete = !services.some(item => item.id === service.id);

      if (serviceToDelete) {
        const data: any = {
          id: service.id,
          price: service.servicePrice,
          quantity: service.quantity,
          delete: true,
        };

        servicesToUpdate.push(data);
      }
    });

    services.forEach(service => {
      const serviceToCreate = servicesRegistered.every(
        item => item.id !== service.id
      );

      const serviceToUpdate = servicesRegistered.some(
        item => item.id === service.id
      );

      const data: any = {
        price: service.servicePrice,
        quantity: service.quantity,
      };

      if (serviceToCreate) {
        data.serviceId = service.service.id;
        servicesToUpdate.push(data);
      }

      if (serviceToUpdate) {
        data.id = service.id;
        servicesToUpdate.push(data);
      }
    });

    return servicesToUpdate;
  };

  const formatTechnicalReportToUpdateOrder = async () => {
    const technicalReportToUpdate = technicalReport || {
      data: [],
      gallery: [],
    };

    if (!technicalReport) {
      return {
        ...technicalReportToUpdate,
        ...(technicalReportRegistered?.id && {
          id: technicalReportRegistered.id,
        }),
        delete: true,
      };
    }

    if (technicalReportToUpdate) {
      technicalReportToUpdate.gallery = await uploadImagesAndReturnList(
        technicalReportToUpdate?.gallery ?? ([] as GalleryType[])
      );
    }

    return {
      ...technicalReportToUpdate,
      ...(technicalReportRegistered?.id && {
        id: technicalReportRegistered.id,
      }),
    };
  };

  const redirectToDetailPage = (
    type: TypeOfOrder,
    id: string,
    isUpdated: boolean
  ) => {
    if (isUpdated) {
      navigate(-1);
    } else {
      if (type === "serviceOrder") {
        navigate(getRouteString(PATHS.SERVICE_ORDER_DETAIL, id, "orderId"), {
          replace: true,
        });
      }

      if (type === "productOrder") {
        navigate(getRouteString(PATHS.PRODUCT_ORDER_DETAIL, id, "orderId"), {
          replace: true,
        });
      }
    }
  };

  const detail = async (id: string) => {
    try {
      dispatch({
        type: StatusOrderActionEnum.DETAIL,
        payload: STATUS.loading,
      });

      const response: OrderDetailType = await getOrder(id);

      setOrder(response);

      const products =
        response.products && response?.products?.length
          ? response.products
          : [];

      const services =
        response.services && response?.services?.length
          ? response.services
          : [];

      const technicalReports = response?.technicalReports?.length
        ? [...response.technicalReports].shift()
        : undefined;

      if (technicalReports && technicalReports?.gallery) {
        technicalReports.gallery = technicalReports?.gallery?.map(
          (item, index) => ({
            ...item,
            index,
          })
        );
      }

      setProducts(products);
      setProductsRegistered(products);

      setServices(services);
      setServicesRegistered(services);

      setTechnicalReport(technicalReports);
      setTechnicalReportRegistered(technicalReports);

      setCustomer(response.contact);
      setSubContact(response.subContact ?? ({} as SubContactType));
      setOperator(response.operator ?? ({} as UserType));

      dispatch({
        type: StatusOrderActionEnum.DETAIL,
        payload: STATUS.success,
      });
    } catch (e) {
      setOrder({} as OrderDetailType);
      dispatch({
        type: StatusOrderActionEnum.DETAIL,
        payload: STATUS.error,
      });
    }
  };

  const list = async (params: OrderListParamsType) => {
    if (stateStatuses.listStatus.loading) return;
    try {
      setOrders({} as GetOrderResponseType);
      dispatch({
        type: StatusOrderActionEnum.LIST,
        payload: STATUS.loading,
      });

      const response: GetOrderResponseType = await getOrders(params);

      setOrders(response);

      dispatch({
        type: StatusOrderActionEnum.LIST,
        payload: STATUS.success,
      });
    } catch (e) {
      setOrders({} as GetOrderResponseType);
      dispatch({
        type: StatusOrderActionEnum.LIST,
        payload: STATUS.error,
      });
    }
  };

  const uploadImagesAndReturnList = async (gallery: GalleryType[]) => {
    const data = [] as GalleryType[];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of gallery) {
      let imageUrl = item.imageUrl as any;
      if (imageUrl instanceof File) {
        // eslint-disable-next-line no-await-in-loop
        imageUrl = (await uploadAndReturnUrl(imageUrl))?.url ?? "";
      }
      data.push({
        ...item,
        imageUrl,
      });
    }
    return data;
  };

  const create = async (params: Partial<OrderType>, type: TypeOfOrder) => {
    try {
      dispatch({
        type: StatusOrderActionEnum.CREATE,
        payload: STATUS.loading,
      });

      const technicalReportFormatterd = technicalReport;
      if (type === "serviceOrder" && technicalReportFormatterd) {
        technicalReportFormatterd.gallery = await uploadImagesAndReturnList(
          technicalReport?.gallery ?? ([] as GalleryType[])
        );
      }

      const response = await addOrder({
        ...params,
        contactId: customer.id,
        operatorId: operator?.id,
        ...(subContact?.id && { subContactId: subContact.id }),
        services: formatServicesToOrder(),
        products: formatProductsToOrder(),
        ...(technicalReportFormatterd && {
          technicalReport: technicalReportFormatterd,
        }),
        ...(params?.deadline && {
          deadline:
            serviceOrderModule?.settings?.dueDateType === "date"
              ? formatEndDateISO(params.deadline)
              : formatDateToISO(params.deadline),
        }),
        ...(params?.createdDate && {
          createdDate:
            serviceOrderModule?.settings?.createdAtType === "date"
              ? formatEndDateISO(params.createdDate)
              : formatDateToISO(params.createdDate),
        }),
        downPaymentValue: params?.downPaymentValue
          ? Number(params.downPaymentValue)
          : undefined,
        remainingPaymentValue: params?.remainingPaymentValue
          ? Number(params.remainingPaymentValue)
          : undefined,
        type,
      });

      setOrder(response.data);

      setLogEvent("order_created", { type });

      toast.success(
        PAGE_ORDER_TITLES(
          type === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[type].message.create.success
      );

      redirectToDetailPage(type, response.data.id as string, false);

      dispatch({
        type: StatusOrderActionEnum.CREATE,
        payload: STATUS.success,
      });
    } catch (e) {
      dispatch({
        type: StatusOrderActionEnum.CREATE,
        payload: STATUS.error,
      });

      toast.error(
        PAGE_ORDER_TITLES(
          type === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[type].message.create.success
      );
    }
  };

  const update = async (
    params: OrderListType,
    type: TypeOfOrder,
    createStockAndOrders: boolean
  ) => {
    try {
      dispatch({
        type: StatusOrderActionEnum.UPDATE,
        payload: STATUS.loading,
      });

      let technicalReport;
      if (type === "serviceOrder") {
        technicalReport = await formatTechnicalReportToUpdateOrder();
      }

      const data = {
        ...params,
        downPaymentValue: params.downPaymentValue
          ? Number(params.downPaymentValue)
          : undefined,
        remainingPaymentValue: params.remainingPaymentValue
          ? Number(params.remainingPaymentValue)
          : undefined,
        id: order.id,
        contactId: customer?.id ?? null,
        operatorId: operator?.id ?? null,
        subContactId: subContact?.id ?? null,
        ...(params?.deadline && {
          deadline: formatDateToISO(params.deadline),
        }),
        ...(params?.createdDate && {
          createdDate: formatDateToISO(params.createdDate),
        }),
        services: formatServicesToUpdateOrder(),
        products: formatProductsToUpdateOrder(),
        technicalReport,
        type,
      };

      await updateOrder(data);

      if (createStockAndOrders) {
        if (hasModules("stock") && data.products?.length) {
          await createOrderStock(data.id, false);
        }
        await createOrderRecords(data.id, false);
      }

      dispatch({
        type: StatusOrderActionEnum.UPDATE,
        payload: STATUS.success,
      });

      toast.success(
        PAGE_ORDER_TITLES(
          type === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[type].message.update.success
      );

      redirectToDetailPage(type, order.id as string, true);
    } catch (e) {
      // setUpdateStatus(STATUS.error);
      dispatch({
        type: StatusOrderActionEnum.UPDATE,
        payload: STATUS.error,
      });
      toast.error(
        PAGE_ORDER_TITLES(
          type === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[type].message.update.error
      );
    }
  };

  const remove = async (id: string, type: TypeOfOrder) => {
    try {
      dispatch({
        type: StatusOrderActionEnum.DELETE,
        payload: STATUS.loading,
      });

      await deleteOrder(id);

      dispatch({
        type: StatusOrderActionEnum.DELETE,
        payload: STATUS.success,
      });

      toast.success(
        PAGE_ORDER_TITLES(
          type === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[type].message.delete.success
      );
    } catch (e) {
      dispatch({
        type: StatusOrderActionEnum.DELETE,
        payload: STATUS.error,
      });

      toast.error(
        PAGE_ORDER_TITLES(
          type === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[type].message.delete.error
      );
    }
  };

  const [createOrderStockStatus, setCreateOrderStockStatus] = useState(
    STATUS.inital
  );

  const createOrderStock = async (id: string, updateOrder = true) => {
    try {
      setCreateOrderStockStatus(STATUS.loading);

      await createOrderStockService(id);
      if (updateOrder) {
        setOrder(prev => ({
          ...prev,
          stockCreatedAt: new Date().toLocaleDateString(),
        }));
      }

      setCreateOrderStockStatus(STATUS.success);
      toast.success("Estoque lançado com sucesso");
    } catch (e) {
      setCreateOrderStockStatus(STATUS.error);
      toast.error("Falha ao lançar estoque, tente novamente");
    }
  };

  const [deleteOrderStockStatus, setDeleteOrderStockStatus] = useState(
    STATUS.inital
  );

  const deleteOrderStock = async (id: string) => {
    try {
      setDeleteOrderStockStatus(STATUS.loading);

      await deleteOrderStockService(id);
      setOrder(prev => ({
        ...prev,
        stockCreatedAt: null,
      }));

      setDeleteOrderStockStatus(STATUS.success);
      toast.success("Estoque estornado com sucesso");
    } catch (e) {
      setDeleteOrderStockStatus(STATUS.error);
      toast.error("Falha ao estornar estoque, tente novamente");
      throw e;
    }
  };

  const [createOrderRecordsStatus, setCreateOrderRecordsStatus] = useState(
    STATUS.inital
  );

  const createOrderRecords = async (id: string, updateOrder = true) => {
    try {
      setCreateOrderRecordsStatus(STATUS.loading);

      await createOrderRecordsService(id);
      if (updateOrder) {
        await detail(id);
      }

      setCreateOrderRecordsStatus(STATUS.success);
      toast.success("Contas lançadas com sucesso");
    } catch (e: any) {
      setCreateOrderRecordsStatus(STATUS.error);
      toast.error(
        e?.response?.data?.message ?? "Falha ao lançar contas, tente novamente"
      );
    }
  };

  const [deleteOrderRecordsStatus, setDeleteOrderRecordsStatus] = useState(
    STATUS.inital
  );

  const deleteOrderRecords = async (id: string, updateOrder = true) => {
    try {
      setDeleteOrderRecordsStatus(STATUS.loading);

      await deleteOrderRecordsService(id);
      if (updateOrder) {
        await detail(id);
      } else {
        setOrder(prev => ({
          ...prev,
          recordsCreatedAt: null,
        }));
      }

      setDeleteOrderRecordsStatus(STATUS.success);
      toast.success("Contas estornadas com sucesso");
    } catch (e: any) {
      setDeleteOrderRecordsStatus(STATUS.error);
      toast.error(
        e?.response?.data?.message ??
          "Falha ao estornar contas, tente novamente."
      );
      throw e;
    }
  };

  return (
    <OrderContext.Provider
      value={{
        status: stateStatuses.listStatus,
        createStatus: stateStatuses.createStatus,
        updateStatus: stateStatuses.updateStatus,
        deleteStatus: stateStatuses.deleteStatus,
        detailStatus: stateStatuses.detailStatus,

        order,
        setOrder,
        orders,

        list,
        create,
        update,
        detail,
        remove,

        customer,
        setCustomer,

        subContact,
        setSubContact,

        operator,
        setOperator,

        products,
        setProducts,

        services,
        setServices,

        technicalReport,
        setTechnicalReport,

        method,
        setMethod,

        createOrderStockStatus,
        createOrderStock,
        deleteOrderStockStatus,
        deleteOrderStock,
        createOrderRecordsStatus,
        createOrderRecords,
        deleteOrderRecordsStatus,
        deleteOrderRecords,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error("useOrder must be used within a OrderProvider");
  }

  return context;
};
