import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { Input } from "src/components/NewComponents/Input";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { useChangeCompanySlug } from "src/services/hooks/auth/useChangeCompanySlug";

import { schemaMyUsernameForm } from "./schema";

export interface MyUsernameScheme {
  slug: string;
}

export function MyUsername() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, setUser } = useAuth();

  const defaultValues: MyUsernameScheme = {
    slug: user.username,
  };

  const methods = useForm<MyUsernameScheme>({
    defaultValues,
    resolver: yupResolver(schemaMyUsernameForm),
    reValidateMode: "onChange",
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    getValues,
  } = methods;

  const { mutate: changeSlug, isLoading } = useChangeCompanySlug({
    onSuccess: (_, params) => {
      const values = getValues();
      reset(values);
      setUser({
        ...user,
        username: params.slug,
        company: {
          ...user.company,
          slug: params.slug,
        },
      });
      toast.success("Usuário empresa atualizado com sucesso.");
    },
  });

  const onSubmit = (values: MyUsernameScheme) => {
    if (values.slug === user.username) {
      toast.info("O nome de usuário já esá vinculado a sua empresa.");
    } else {
      onOpen();
    }
  };

  return (
    <>
      <Box
        mb={6}
        p={6}
        bg="white"
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="myUsernameForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text color="primary.500" fontWeight="bold">
          Usuário empresa
        </Text>
        <Divider my={4} />

        <SimpleGrid columns={[1, 1, 2]} gap={4}>
          <Input
            label="Usuário empresa"
            error={errors.slug}
            {...register("slug", {
              onChange: e => {
                e.target.value = e.target.value
                  .toLowerCase()
                  .replace(/[^0-9a-z]+/gi, "");
                return e;
              },
            })}
          />
          <VStack alignItems="flex-start">
            <Text fontWeight="600" fontSize="sm">
              Exemplo de usuário:{" "}
              <Text fontWeight="400" as="span">
                joao@docemania
              </Text>
            </Text>
            <Text fontSize="sm" color="primary.500">
              Caso seja alterado, você pode perder esse nome, caso seja
              escolhido por outra empresa
            </Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text fontWeight="600" fontSize="sm" color="primary.500">
              Atenção!
            </Text>
            <Text fontSize="sm" color="primary.500">
              O nome de usuário da empresa está diretamente ligado na criação de
              usuários futuros do seu negócio, portanto se alterar, também
              mudará o seu nome de usuário e do seus funcionário no Azulou.
            </Text>
          </VStack>
        </SimpleGrid>
        {isDirty ? (
          <Flex justifyContent="flex-end" mt={4}>
            <Button
              size="md"
              form-id="myUsernameForm"
              type="submit"
              isLoading={isLoading}
            >
              Salvar
            </Button>
          </Flex>
        ) : null}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
        <ModalOverlay />
        <ModalContent bg="background">
          <ModalHeader>Confirmar alteração</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Deseja realmente alterar o usuário administrador?</Text>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button
              variant="outline"
              size="md"
              colorScheme="primary"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="primary"
              size="md"
              onClick={() => {
                onClose();
                changeSlug(getValues());
              }}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
