import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { GetResponseType } from "src/interfaces/request";
import { ServiceType } from "src/interfaces/service";
import api from "src/services/api";

export interface GetServicesResponseType extends GetResponseType {
  services: ServiceType[];
}

interface ServicesParams {
  name?: string;
  page?: number;
  limit?: number;
}

export const getServices = (params: ServicesParams) =>
  api
    .get<GetServicesResponseType>("v1/service", {
      params,
    })
    .then(res => res.data);

export function useServices(
  params: ServicesParams,
  config?: UseQueryOptions<
    GetServicesResponseType,
    Error,
    GetServicesResponseType,
    unknown[]
  >
) {
  return useQuery<
    GetServicesResponseType,
    Error,
    GetServicesResponseType,
    unknown[]
  >(["services", params], () => getServices(params), {
    refetchOnWindowFocus: false,
    ...(config || {}),
    initialData: {
      services: [],
      limit: 10,
      page: 1,
      totalCount: 0,
    },
  });
}
