import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { PlanSignature } from "src/interfaces/company";
import api from "src/services/api";

export const activeTrial = () =>
  api.post(`/v1/plan-signature/trial`).then<PlanSignature>(res => res.data);

export function useActiveTrial(
  config?: UseMutationOptions<PlanSignature, any, void, unknown>
) {
  const mutationResponse = useMutation<PlanSignature, any, void, unknown>(
    () => activeTrial(),
    {
      ...config,
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ??
            "Ocorreu um problema ao ativar período de testes"
        );
      },
    }
  );

  return mutationResponse;
}
