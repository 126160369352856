import React, { createContext, useContext, useReducer, useState } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import {
  CreateOrEditFinancialAccountType,
  FinancialAccountParamType,
  FinancialAccountType,
  FinancialInstitutionParamType,
  FinancialInstitutionType,
} from "src/interfaces/financialAccount";
import {
  addFinancialAccount,
  deleteFinancialAccount,
  getFinancialAccounts,
  getFinancialAccountsWithBalance,
  getFinancialInstitutions,
  updateFinancialAccount,
  updateStatusFinancialAccount,
} from "src/services/financialAccount";

import { FinancialAccountContextType } from "./props";
import {
  StatusFinancialAccountActionEnum,
  statusFinancialAccountReducer,
} from "./reducer";

export const FinancialAccountContext = createContext(
  {} as FinancialAccountContextType
);

export const FinancialAccountProvider: React.FC = props => {
  const { children } = props;

  const [state, dispatch] = useReducer(statusFinancialAccountReducer, {
    listStatus: STATUS.inital,
    createStatus: STATUS.inital,
    updateStatus: STATUS.inital,
    deleteStatus: STATUS.inital,
    archiveOrUnarchiveStatus: STATUS.inital,
  });

  const [accounts, setAccounts] = useState<Array<FinancialAccountType>>([]);
  const [institutions, setInstitutions] = useState<
    Array<FinancialInstitutionType>
  >([]);
  const [financialAccount, setFinancialAccount] = useState<
    CreateOrEditFinancialAccountType | undefined
  >(undefined);

  const list = async (
    params: FinancialAccountParamType,
    withBalance = false
  ) => {
    try {
      if (state.listStatus.loading) return;
      dispatch({
        type: StatusFinancialAccountActionEnum.LIST,
        payload: STATUS.loading,
      });

      const response: Array<FinancialAccountType> = await (withBalance
        ? getFinancialAccountsWithBalance(params)
        : getFinancialAccounts(params));

      setAccounts(response);

      dispatch({
        type: StatusFinancialAccountActionEnum.LIST,
        payload: STATUS.success,
      });
    } catch (e) {
      setAccounts([]);
      dispatch({
        type: StatusFinancialAccountActionEnum.LIST,
        payload: STATUS.error,
      });
    }
  };

  const listInstitution = async (params: FinancialInstitutionParamType) => {
    try {
      const response: Array<FinancialInstitutionType> =
        await getFinancialInstitutions(params);

      setInstitutions(response);
    } catch (e) {
      setInstitutions([]);
    }
  };

  const add = async (
    params: CreateOrEditFinancialAccountType,
    action?: () => void
  ) => {
    try {
      dispatch({
        type: StatusFinancialAccountActionEnum.CREATE,
        payload: STATUS.loading,
      });

      await addFinancialAccount(params);

      dispatch({
        type: StatusFinancialAccountActionEnum.CREATE,
        payload: STATUS.success,
      });

      if (action) {
        action();
      }

      toast.success("Conta criada com sucesso!");
    } catch (e) {
      dispatch({
        type: StatusFinancialAccountActionEnum.CREATE,
        payload: STATUS.error,
      });

      toast.error("Ocorreu um problema ao criar conta");
    }
  };

  const update = async (
    id: string,
    params: CreateOrEditFinancialAccountType
  ) => {
    try {
      dispatch({
        type: StatusFinancialAccountActionEnum.UPDATE,
        payload: STATUS.loading,
      });

      const response: CreateOrEditFinancialAccountType =
        await updateFinancialAccount(id, params);

      setFinancialAccount(response);

      dispatch({
        type: StatusFinancialAccountActionEnum.UPDATE,
        payload: STATUS.success,
      });

      toast.success("Conta atualizada");
    } catch (e) {
      dispatch({
        type: StatusFinancialAccountActionEnum.UPDATE,
        payload: STATUS.error,
      });

      toast.error("Ocorreu um problema ao atualizar essa conta");
    }
  };

  const remove = async (id: string) => {
    try {
      dispatch({
        type: StatusFinancialAccountActionEnum.DELETE,
        payload: STATUS.loading,
      });

      await deleteFinancialAccount(id);

      setFinancialAccount(undefined);
      setAccounts(accounts => accounts.filter(account => account.id !== id));

      dispatch({
        type: StatusFinancialAccountActionEnum.DELETE,
        payload: STATUS.success,
      });

      toast.success("Conta excluida");
    } catch (e) {
      dispatch({
        type: StatusFinancialAccountActionEnum.DELETE,
        payload: STATUS.error,
      });

      toast.error("Ocorreu um problema ao excluir essa conta");
    }
  };

  const archive = async (id: string) => {
    try {
      dispatch({
        type: StatusFinancialAccountActionEnum.ARCHIVE_OR_UNARCHIVE,
        payload: STATUS.loading,
      });

      await updateStatusFinancialAccount(id, {
        status: "disabled",
      });

      const financialAccountUpdated = {
        ...financialAccount,
        status: "disabled",
      } as FinancialAccountType;

      setFinancialAccount(financialAccountUpdated);
      updateFinancialAccountInAccountsList(financialAccountUpdated);

      dispatch({
        type: StatusFinancialAccountActionEnum.ARCHIVE_OR_UNARCHIVE,
        payload: STATUS.success,
      });

      toast.success("Conta arquivada");
    } catch (e) {
      dispatch({
        type: StatusFinancialAccountActionEnum.ARCHIVE_OR_UNARCHIVE,
        payload: STATUS.error,
      });

      toast.error("Ocorreu um problema ao arquivar essa conta");
    }
  };

  const unarchive = async (id: string) => {
    try {
      dispatch({
        type: StatusFinancialAccountActionEnum.ARCHIVE_OR_UNARCHIVE,
        payload: STATUS.loading,
      });

      await updateStatusFinancialAccount(id, {
        status: "enabled",
      });

      const financialAccountUpdated = {
        ...financialAccount,
        status: "enabled",
      } as FinancialAccountType;

      setFinancialAccount(financialAccountUpdated);
      updateFinancialAccountInAccountsList(financialAccountUpdated);

      dispatch({
        type: StatusFinancialAccountActionEnum.ARCHIVE_OR_UNARCHIVE,
        payload: STATUS.success,
      });

      toast.success("Conta arquivada");
    } catch (e) {
      dispatch({
        type: StatusFinancialAccountActionEnum.ARCHIVE_OR_UNARCHIVE,
        payload: STATUS.error,
      });

      toast.error("Ocorreu um problema ao arquivar essa conta");
    }
  };

  const updateFinancialAccountInAccountsList = (
    financialAccount: FinancialAccountType
  ) => {
    const indexAccount = accounts.findIndex(
      account => account.id === (financialAccount?.id as string)
    );

    const listAccountsUpdated = accounts;
    listAccountsUpdated[indexAccount] = financialAccount;
    setAccounts([...listAccountsUpdated]);
  };

  return (
    <FinancialAccountContext.Provider
      value={{
        status: state.listStatus,
        createStatus: state.createStatus,
        updateStatus: state.updateStatus,
        archiveOrUnarchiveStatus: state.archiveOrUnarchiveStatus,
        deleteStatus: state.deleteStatus,
        accounts,
        list,
        add,
        update,
        financialAccount,
        setFinancialAccount,
        listInstitution,
        institutions,
        archive,
        unarchive,
        remove,
      }}
    >
      {children}
    </FinancialAccountContext.Provider>
  );
};

export const useFinancialAccount = () => {
  const context = useContext(FinancialAccountContext);

  if (!context) {
    throw new Error(
      "useFinancialAccount must be used within a FinancialAccountProvider"
    );
  }

  return context;
};
