import React, { FC } from "react";

import { Flex, Heading } from "@chakra-ui/react";

import RenderModule from "src/components/RenderModule";
import PATHS from "src/constants/paths";
import { Can, usePermission } from "src/hooks/permission/usePermission";

import ReportCard from "../ReportCard";

const FinacialReportsTab: FC = () => {
  const { can } = usePermission();

  if (
    !can([
      { subject: "reportFinancialDre", action: "view" },
      { subject: "reportFinancialPredictedAndAccomplished", action: "view" },
      { subject: "reportFinancialRecords", action: "view" },
      { subject: "reportPaymentMethods", action: "view" },
      { subject: "reportCashFlowControl", action: "view" },
      { subject: "reportInventory", action: "view" },
    ])
  ) {
    return (
      <Flex flex={1} justify="center" align="center">
        <Heading as="h3" fontSize="md" textAlign="center">
          Você não tem permissão para acessar os relatórios financeiros.
        </Heading>
      </Flex>
    );
  }

  return (
    <>
      <Can subject="reportFinancialDre" action="view">
        <ReportCard
          title="Demonstração  do Resultado do Exercício - DRE"
          subtitle="Resumo das operações financeiras da empresa em um determinado período de tempo para deixar claro se ela teve lucro ou prejuízo"
          path={PATHS.REPORTS_DRE}
        />
      </Can>
      <Can subject="reportFinancialPredictedAndAccomplished" action="view">
        <ReportCard
          title="Previsto x Realizado"
          subtitle="Visualize as despesas e receitas que estão previstas ou realizadas. Mês a mês"
          path={PATHS.REPORTS_PREDICTED_AND_ACCOMPLISHED}
        />
      </Can>
      <Can subject="reportFinancialRecords" action="view">
        <ReportCard
          title="Lançamentos"
          subtitle="Gere relatórios de receitas e despesas com filtros por cliente, por status de pagamento."
          path={PATHS.REPORTS_RECORDS}
        />
      </Can>
      <Can subject="reportPaymentMethods" action="view">
        <ReportCard
          title="Formas de pagamento"
          subtitle="Visualize as formas de pagamento mais rentaveis"
          path={PATHS.REPORTS_SALES}
        />
      </Can>
      <RenderModule modules={["cashflowcontrol"]}>
        <Can subject="reportCashFlowControl" action="view">
          <ReportCard
            title="Controle de caixa"
            subtitle="Visualize caixas abertos e fechados do seu negócio"
            path={PATHS.REPORTS_CASHFLOW}
          />
        </Can>
      </RenderModule>
      <RenderModule modules={["stock"]}>
        <Can subject="reportInventory" action="view">
          <ReportCard
            title="Inventário"
            subtitle="Visualize seu inventário"
            path={PATHS.REPORTS_INVENTORY}
          />
        </Can>
      </RenderModule>
    </>
  );
};

export default FinacialReportsTab;
