import React from "react";

import { STATUS } from "src/constants/requestStatus";
import { searchCEP } from "src/services/signup";

interface AddressType {
  cep: string;
  city: string;
  complement: string;
  neighborhood: string;
  street: string;
  uf: string;
}

export const useAddress = () => {
  const [status, setStatus] = React.useState(STATUS.inital);
  const [address, setAddress] = React.useState<AddressType | null>(null);

  const getAddress = async (cep: string) => {
    try {
      setStatus(STATUS.loading);

      const response = await searchCEP(cep);

      setAddress(response);

      setStatus(STATUS.success);
    } catch {
      setStatus(STATUS.error);
    }
  };

  return {
    status,
    address,
    getAddress,
  };
};
