import React, { FC, useMemo, useState } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

import Typography from "src/components/Typography";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { usePermission } from "src/hooks/permission/usePermission";

import { ButtonSidebarProps } from "./props";
import * as Styled from "./styles";

const ButtonSidebar: FC<ButtonSidebarProps> = props => {
  const {
    selected,
    subSelected,
    label,
    to,
    icon,
    iconActive,
    options,
    ...rest
  } = props;

  const { hasModules } = useModule();
  const { userCompany, getNameDefinitionsFromKey } = useCompany();
  const { can } = usePermission();

  const [expanded, setExpanded] = useState<boolean>(
    options?.some(item => item.key === subSelected) || false
  );

  const renderIcon = (
    active: boolean,
    iconComponent?: FC,
    iconActiveComponent?: FC
  ) => {
    if (active) {
      return iconActiveComponent ? (
        <SvgIcon component={iconActiveComponent} />
      ) : null;
    }

    return iconComponent ? <SvgIcon component={iconComponent} /> : null;
  };

  const renderArrow = useMemo(() => {
    if (!options) {
      return undefined;
    }

    if (expanded) {
      return <Styled.BottomIcon selected={selected} />;
    }

    return <Styled.RightIcon selected={selected} />;
  }, [expanded, options, selected]);

  const renderOptions = useMemo(() => {
    if (options) {
      return options
        .filter(item => hasModules(item.moduleSlug))
        .filter(
          button =>
            !button.permissions ||
            can(button.permissions, button.allPermissions)
        )
        .map(option => {
          const selectedOption = option.key === subSelected;
          const label =
            getNameDefinitionsFromKey(option.nameDefinition)?.titlePlural ??
            option.label;

          return (
            <Styled.Link to={option.path} key={option.key}>
              <Styled.OptionButton
                {...rest}
                disableElevation
                selected={selectedOption}
              >
                {renderIcon(selectedOption, option.icon, option.iconActive)}
                <Typography variant="body2" style={{ textTransform: "none" }}>
                  {label}
                </Typography>
              </Styled.OptionButton>
            </Styled.Link>
          );
        });
    }

    return null;
  }, [options, subSelected, userCompany]);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const ButtonContent = (
    <Styled.Container elevation={0} expanded={expanded} onChange={handleChange}>
      <Styled.AccordionSummary disableTouchRipple disableRipple>
        <Styled.Button {...rest} disableElevation selected={selected}>
          <Styled.ContentLeftButton>
            {renderIcon(Boolean(selected), icon, iconActive)}
            <Typography variant="body2">{label}</Typography>
          </Styled.ContentLeftButton>
          {renderArrow}
        </Styled.Button>
      </Styled.AccordionSummary>
      <Styled.AccordionDetails>{renderOptions}</Styled.AccordionDetails>
    </Styled.Container>
  );

  if (to && !options) {
    return <Styled.Link to={to}>{ButtonContent}</Styled.Link>;
  }

  return ButtonContent;
};

export default ButtonSidebar;
