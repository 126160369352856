import { Button } from "@chakra-ui/react";
import { PDFDownloadLink } from "@react-pdf/renderer";

interface IGeneratePDF {
  fileName: string;
  buttonName: string;
  doc: any;
}

const GeneratePDF = ({ buttonName, fileName, doc }: IGeneratePDF) => {
  return (
    <PDFDownloadLink document={doc} fileName={fileName}>
      <Button h="56px" ml="8px">
        {buttonName}
      </Button>
    </PDFDownloadLink>
  );
};

export default GeneratePDF;
