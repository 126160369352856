import { toast } from "react-toastify";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "src/services/api";

export const disableUser = (id: string) =>
  api.delete(`/v1/user/restricted/${id}/delete`).then(res => res);

export function useDisableUser() {
  const queryClient = useQueryClient();

  return useMutation((id: string) => disableUser(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast.success("Usuário desabilitado com sucesso.");
    },
    onError: ({ response }) => {
      toast.error(
        response?.data?.message ??
          "Ocorreu um problema ao desabilitar esse usuário"
      );
    },
  });
}
