import cashIcon from "src/assets/icons/financialAccounts/cash.svg";
import walletIcon from "src/assets/icons/financialAccounts/wallet.svg";
import { GroupOption } from "src/interfaces/common";
import { FinancialAccountType } from "src/interfaces/financialAccount";

export const groupFinancialAccounts = (accounts: FinancialAccountType[]) => {
  return accounts
    .reduce<GroupOption[]>((group, account) => {
      const label = account.isDefault ? "Conta padrão" : "Outras contas";
      const hasGroup = group.some(g => g.label === label);
      const getUrl = (account: FinancialAccountType) => {
        if (account.type === "wallet") return walletIcon;
        if (account.type === "cash") return cashIcon;
        return account.institution?.image;
      };

      if (hasGroup) {
        return group.map(group => {
          if (group.label === label) {
            return {
              ...group,
              options: [
                ...group.options,
                {
                  label: account.name,
                  value: account.id,
                  url: getUrl(account),
                },
              ],
            };
          }
          return group;
        });
      }
      return [
        ...group,
        {
          label,
          options: [
            {
              label: account.name,
              value: account.id,
              url: getUrl(account),
            },
          ],
        },
      ];
    }, [])
    .sort((a, b) => a.label.localeCompare(b.label));
};
