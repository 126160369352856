import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { useFormikContext } from "formik";

import Typography from "src/components/Typography";
import {
  PaymentMethodParamType,
  UpdatePaymentMethodParamType,
} from "src/interfaces/payment";

import { PaymentOptionProps } from "./props";
import * as Styled from "./styles";

const PaymentOption: React.FC<PaymentOptionProps> = props => {
  const { id, label } = props;

  const { values, setFieldValue } =
    useFormikContext<UpdatePaymentMethodParamType>();

  const checked = useMemo(
    () =>
      values.companyPaymentMethods.some(
        item => item.id === id && item.status === "enabled"
      ),
    [values]
  );

  const toggleStatus = (itemId: string, itemStatus: string) => {
    if (itemId === id) {
      return itemStatus === "enabled" ? "disabled" : "enabled";
    }

    return itemStatus;
  };

  const handleChange = () => {
    const newValues = values.companyPaymentMethods.map(item => ({
      ...item,
      status: toggleStatus(item.id, item.status),
    }));

    setFieldValue(
      "companyPaymentMethods",
      newValues as PaymentMethodParamType[]
    );
  };

  return (
    <Styled.OptionPayment container>
      <Grid item xs={1}>
        <MonetizationOnIcon color="primary" />
      </Grid>

      <Grid item xs={9}>
        <Typography>{label}</Typography>
      </Grid>

      <Grid item xs={2}>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          alignContent="flex-end"
          width="100%"
        >
          <Switch
            checked={checked}
            onChange={handleChange}
            name="check"
            color="primary"
            inputProps={{ "aria-label": label }}
          />
        </Box>
      </Grid>
    </Styled.OptionPayment>
  );
};

export default PaymentOption;
