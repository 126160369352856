import React, { useEffect, useMemo } from "react";

import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";

import SelectField from "src/components/Inputs/SelectField";
import { useCompany } from "src/hooks/company/useCompany";
import { useOrder } from "src/hooks/order/useOrder";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";
import { useStatusOrder } from "src/hooks/order/useStatusOrder";
import { usePreferencesStorage } from "src/hooks/storage/usePreferenceStorage";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";

import BottomBar from "../BottomBar";
import FieldsManager from "../FieldsManager";
import OptionsPanel from "../NewOptionPanel";
import StatusTabs from "../StatusTabs";
import OrderTable from "../Table";
import { MainContentProps } from "./props";

const MainContent: React.FC<MainContentProps> = props => {
  const { contactId, isVisible = true } = props;

  const {
    page,
    setPage,
    limit,
    setLimit,
    search,
    setSearch,
    status,
    setStatus,
    startDate,
    setStartDate,
    filterFieldDate,
    setFilterFieldDate,
    endDate,
    setEndDate,
    type,
    setType,
    restartOrderList,
    setRestartOrderList,
  } = useOrderFilters();

  const { saveParamsByKey } = usePreferencesStorage();

  const { list, orders } = useOrder();
  const { serviceOrderNameDefinitions } = useCompany();
  // const { listView } = useViewOrder();

  const { getStatus, ordersStatus } = useStatusOrder();

  const listOrders = (pageNumber?: number) => {
    saveParamsByKey(type, { limit });
    list({
      limit,
      page: pageNumber ?? page,
      type,
      queryType: "full",
      ...(search.length && { search }),
      ...(status && { status }),
      ...(startDate && { startDate: formatStartDateISO(startDate) }),
      ...(endDate && { endDate: formatEndDateISO(endDate) }),
      ...(startDate && endDate && { filterFieldDate: "createdDate" }),
      ...(contactId && { contactId }),
      ...(filterFieldDate && { filterFieldDate }),
    });
  };

  const listStatusMeta = () => {
    getStatus({
      startDate,
      endDate,
      type,
      ...(contactId && { contactId }),
    });
  };

  useEffect(() => {
    setPage(1);
    listOrders(1);
  }, [search, status, startDate, endDate, type, limit, filterFieldDate]);

  useEffect(() => {
    listStatusMeta();
  }, [startDate, type, search, filterFieldDate]);

  useEffect(() => {
    if (isVisible && restartOrderList) {
      listOrders();
      listStatusMeta();
      setRestartOrderList(false);
    }
  }, [isVisible, restartOrderList]);

  const orderTotalCount = useMemo(
    () =>
      ordersStatus.find(orderStatus => orderStatus._id === status)
        ?.orderCount ?? 0,
    [status, orders, ordersStatus]
  );

  const renderStatusBar = () => {
    return (
      <Box
        mb={1}
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <StatusTabs status={status} setStatus={setStatus} />
        {contactId ? (
          <Box ml={2} width={190} bgcolor="white">
            <Formik
              // eslint-disable-next-line no-console
              onSubmit={v => console.log(v)}
              initialValues={{
                type,
              }}
            >
              {({ values }) => (
                <Form>
                  <SelectField
                    defaultValue={type}
                    label=""
                    name="type"
                    items={[
                      {
                        value: "serviceOrder",
                        name: serviceOrderNameDefinitions.title,
                      },
                      { value: "productOrder", name: "Pedido de venda" },
                    ]}
                  />
                  {setType(values.type)}
                </Form>
              )}
            </Formik>
          </Box>
        ) : null}
        {type === "serviceOrder" && !contactId ? <FieldsManager /> : null}
      </Box>
    );
  };

  const renderList = () => {
    return (
      <Grid container direction="column">
        {renderStatusBar()}

        <Grid item xs={12}>
          <OrderTable
            page={page}
            setPage={page => {
              listOrders(page);
              setPage(page);
            }}
            limit={limit}
            setLimit={setLimit}
            totalCount={orderTotalCount}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {!contactId ? (
        <OptionsPanel
          setSearch={setSearch}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          filterFieldDate={filterFieldDate}
          setFilterFieldDate={setFilterFieldDate}
        />
      ) : null}
      {renderList()}

      <BottomBar orderType={type} />
    </>
  );
};

export default MainContent;
