import React, { FC } from "react";

import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";
import { format } from "date-fns";

import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { ContactType } from "src/interfaces/contact";
import { StatusType } from "src/interfaces/order";
import { GetReportSalesResponseType } from "src/interfaces/report";
import { formatPrice, maskDocument } from "src/utils/format";

export const renderDocument = (params: GridValueFormatterParams) => {
  if (params.value === null) {
    return null;
  }
  const contact = (params.row?.contact ?? {}) as ContactType;

  return renderDocumentContact(contact);
};

export const renderDocumentContact = (contact?: ContactType) => {
  if (!contact) {
    return "-";
  }

  if (contact?.cpf) {
    return maskDocument(contact?.cpf);
  }

  if (contact?.cnpj) {
    return maskDocument(contact?.cnpj);
  }

  return "-";
};

const renderContactName = (params: GridValueFormatterParams) =>
  params.row?.contact?.name ?? "-";

const columns: GridColDef[] = [
  {
    field: "code",
    headerName: "Nº",
    width: 80,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "createdDate",
    headerName: "Data da venda",
    renderCell: params =>
      params.row?.createdDate
        ? format(new Date(params.row?.createdDate), "dd/MM/yyyy")
        : "-",
    width: 150,
    sortComparator: (v1, v2) => {
      const name = (v1 as string)?.toLowerCase() ?? "-";
      const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

      if (name < nameComparable) {
        return -1;
      }
      if (name > nameComparable) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "contact",
    headerName: "Cliente",
    renderCell: renderContactName,
    width: 150,
    sortComparator: (v1, v2) => {
      const name = (v1 as ContactType)?.name?.toLowerCase() ?? "-";
      const nameComparable = (v2 as ContactType)?.name?.toLowerCase() ?? "-";

      if (name < nameComparable) {
        return -1;
      }
      if (name > nameComparable) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "cpf",
    headerName: "CPF/CNPJ",
    renderCell: renderDocument,
    width: 130,
    valueGetter: params => params.row?.contact,
    sortComparator: (contact, contactComparable) => {
      const document = renderDocumentContact(
        contact as ContactType
      )?.toLowerCase();
      const documentComparable = renderDocumentContact(
        contactComparable as ContactType
      )?.toLowerCase();

      if (document < documentComparable) {
        return -1;
      }
      if (document > documentComparable) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: params => params.row?.status?.name ?? "-",
    width: 140,
    sortComparator: (v1, v2) => {
      const status = (v1 as StatusType).name.toLowerCase();
      const statusComparable = (v2 as StatusType).name.toLowerCase();

      if (status < statusComparable) {
        return -1;
      }
      if (status > statusComparable) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "grossAmount",
    headerName: "Valor bruto",
    renderCell: params => formatPrice(params.value),
    width: 130,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "discount",
    headerName: "Desconto",
    renderCell: params => formatPrice(params.value),
    width: 120,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "totalAmount",
    headerName: "Valor",
    renderCell: params => formatPrice(params.value),
    width: 130,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "paymentMethods",
    headerName: "Pagamento",
    renderCell: params => params.row?.paymentMethods?.join(", ") ?? "-",
    width: 180,
  },
];

export interface ClientsTableProps {
  reportSales: GetReportSalesResponseType;
}

const ClientsTable: FC<ClientsTableProps> = ({ reportSales }) => {
  const { result: orders } = reportSales;
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState<number>(orders.length);

  return (
    <Table
      sortingOrder={["desc", "asc"]}
      rows={orders}
      columns={columns}
      page={page}
      setPage={setPage}
      pageSize={limit}
      onPageSizeChange={setLimit}
      filterMode="server"
      totalCount={orders.length}
      hideFooter
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <Typography>Nenhuma relatório disponível</Typography>
          </EmptyTable>
        ),
      }}
    />
  );
};

export default ClientsTable;
