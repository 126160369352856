import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 80px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-style: solid;

  background-color: ${({ theme }) => theme.palette.secondary.contrastText};
  padding: 10px;
`;
