import { Control, Controller, FieldError } from "react-hook-form";
import NumberFormat from "react-number-format";

import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormLabel,
  FormLabelProps,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";

interface CurrencyInputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: FieldError | null;
  control: Control<any, any>;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  formErrorMessageProps?: FormErrorMessageProps;
}

export function CurrencyInput({
  name,
  label,
  control,
  error = null,
  isDisabled,
  formControlProps = {},
  formLabelProps = {},
  formErrorMessageProps = {},
  ...rest
}: CurrencyInputProps) {
  return (
    <FormControl
      isInvalid={!!error}
      isDisabled={isDisabled}
      variant="floating"
      {...formControlProps}
    >
      <Box w="100%" position="relative" bg={rest.bg}>
        <Controller
          control={control}
          name={name}
          {...rest}
          render={({ field: { onChange, value, ref } }) => (
            <ChakraInput
              variant="outline"
              placeholder=" "
              as={NumberFormat}
              getInputRef={ref}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              allowNegative={false}
              fixedDecimalScale
              isNumericString
              prefix="R$ "
              value={value}
              onValueChange={(values: any) => {
                onChange(values.value);
              }}
            />
          )}
        />

        {!!label && (
          <FormLabel htmlFor={name} {...formLabelProps}>
            {label}
          </FormLabel>
        )}
      </Box>
      {!!error && (
        <FormErrorMessage {...formErrorMessageProps}>
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
