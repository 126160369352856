import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Typography from "src/components/Typography";
import { CYCLES } from "src/constants/recurrenceCycles";
import { SubCategoryRecordType } from "src/interfaces/categoryRecord";
import { FinancialAccountType } from "src/interfaces/financialAccount";
import { RecordType } from "src/interfaces/record";
import { formatPrice } from "src/utils/format";

import { logo } from "../../index";
import * as Styled from "../../styles";

interface RecordCardIncomeProps {
  record: Partial<RecordType>;
  paidDate: string;
  emissionDate: string;
  hasAccountManagementModule: boolean;
}

const RecordCardIncome: React.FC<RecordCardIncomeProps> = props => {
  const { record, paidDate, emissionDate, hasAccountManagementModule } = props;
  const category = record?.category as SubCategoryRecordType;

  const paidDateLabel = useMemo(() => {
    if (record.paidStatus === "paid") {
      return "Data de pagamento:";
    }
    return `Prazo da receita:`;
  }, [record]);

  return (
    <Grid container spacing={2}>
      {record.order ? (
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            Nome do cliente:
          </Typography>
          <Box mt={1}>
            <Typography variant="body2">
              {record.order.contact?.name?.length
                ? record.order.contact?.name
                : "Não informado"}
            </Typography>
          </Box>
        </Grid>
      ) : null}

      <Grid item xs={4}>
        <Typography variant="body2" color="textSecondary">
          Categoria:
        </Typography>
        <Box mt={1}>
          <Typography variant="body2">{category?.name}</Typography>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Typography variant="body2" color="textSecondary">
          Valor:
        </Typography>
        <Box mt={1}>
          <Typography variant="body2">{formatPrice(record.value)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" color="textSecondary">
          {paidDateLabel}
        </Typography>
        <Box mt={1}>
          <Typography variant="body2">{paidDate}</Typography>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Typography variant="body2" color="textSecondary">
          Data da receita:
        </Typography>
        <Box mt={1}>
          <Typography variant="body2">{emissionDate}</Typography>
        </Box>
      </Grid>

      {hasAccountManagementModule && record.financialAccount ? (
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            Conta:
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Styled.Logo
              src={logo(
                (record.financialAccount as FinancialAccountType).type,
                (record.financialAccount as FinancialAccountType)?.institution
                  ?.image
              )}
              loading="eager"
            />
            <Typography variant="body2">
              {(record.financialAccount as FinancialAccountType)?.name}
            </Typography>
          </Box>
        </Grid>
      ) : null}

      {record.recurrenceType === "fixed" ? (
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            Tipo da receita:
          </Typography>
          <Box mt={1}>
            <Typography variant="body2">
              Fixa -{" "}
              {record.recurrenceCycle ? CYCLES[record.recurrenceCycle] : ""}
            </Typography>
          </Box>
        </Grid>
      ) : null}

      {record.recurrenceType === "installments" ? (
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            Tipo da receita:
          </Typography>
          <Box mt={1}>
            <Typography variant="body2">
              Parcelada - Parcela {record.recurrenceNumber}
            </Typography>
          </Box>
        </Grid>
      ) : null}

      <Grid item xs={record.recurrenceType ? 4 : 8}>
        <Typography variant="body2" color="textSecondary">
          Observação:
        </Typography>
        <Box mt={1}>
          <Typography variant="body2">
            {record?.note ? record.note : "-"}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RecordCardIncome;
