import {
  CashFlowControlSettingType,
  ProductOrderSettingType,
  ServiceOrderSettingType,
} from "src/interfaces/module";
import { ListParamsType } from "src/interfaces/request";
import api from "src/services/api";

/**
 * Listar todos os módulos
 */
export const getModules = (params: Partial<ListParamsType>) =>
  api
    .get("v1/module", {
      params,
    })
    .then(res => res.data);

/**
 * Listar módulos associados a empresa
 */
export const getCompanyModules = () =>
  api.get("v1/module/company").then(res => res.data);

/**
 * Atualizar configuração de um módulo
 */
export const patchSettingsModule = (
  id: string,
  params:
    | ServiceOrderSettingType
    | ProductOrderSettingType
    | CashFlowControlSettingType
) =>
  api
    .patch(`v1/module/${id}/settings`, { settings: params })
    .then(res => res.data);

/**
 * Instalar módulo
 */
export const subscribeModule = (id: string) =>
  api
    .post(`v1/module/subscribe`, {
      id,
    })
    .then(res => res.data);

/**
 * Remover módulo
 */
export const unsubscribeModule = (id: string) =>
  api
    .delete(`v1/module/unsubscribe`, {
      params: {
        id,
      },
    })
    .then(res => res.data);
