import styled from "styled-components";

import Grid from "@material-ui/core/Grid";

import Button from "src/components/Button";
import Typography from "src/components/Typography";

export const OpenFilterButton = styled(Button)`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 0px;
  margin-left: 8px;
  svg {
    font-size: 20px;
  }
`;

export const Container = styled.div`
  width: 350px;
  height: 100%;
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const FieldOptionContainer = styled(Grid)`
  align-items: center;
  display: flex;
  height: 60px;
`;
