import { ActionMapData } from "src/hooks/props";
import { RequestStatusType } from "src/interfaces/request";
import {
  CompleteSignUpType,
  UserToAddType,
  UserType,
} from "src/interfaces/user";

export interface RequestSignUpType {
  token: string;
  user: UserType;
}

export type CreateAccountSectionsType =
  | "welcome"
  | "createAccount"
  | "createPassword"
  | "pincode"
  | "knowingBetter"
  | "meetingYourBusiness"
  | "atuationArea"
  | "aloneOrTeam"
  | "success";

export type CreateAccountState = {
  token?: string;
  user: UserType;

  section: CreateAccountSectionsType;

  status: {
    signUp: {
      ready: boolean;
    };
    completeSignUp: {
      ready: boolean;
    };
  };

  signUpRequestStatus: RequestStatusType;
  SMSConfirmRequestStatus: RequestStatusType;
  SMSResendRequestStatus: RequestStatusType;
  completeSignUpRequestStatus: RequestStatusType;

  userToAdd: Partial<UserToAddType>;
  completeSignUp: Partial<CompleteSignUpType>;
};

export enum CreateAccountActions {
  SetSectionSignup = "SET_SECTION_SIGNUP",

  RequestSignUp = "REQUEST_SIGNUP",
  RequestSignUpSuccess = "REQUEST_SUCCESS_SIGNUP",
  RequestSignUpError = "REQUEST_ERROR_SIGNUP",

  RequestSMSConfirm = "REQUEST_SMS_CONFIRM",
  RequestSMSConfirmSuccess = "REQUEST_SMS_CONFIRM_SUCCESS",
  RequestSMSConfirmError = "REQUEST_SMS_CONFIRM_ERROR",

  RequestSMSResend = "REQUEST_SMS_RESEND",
  RequestSMSResendSuccess = "REQUEST_SMS_RESEND_SUCCESS",
  RequestSMSResendError = "REQUEST_SMS_RESEND_ERROR",

  RequestCompleteSignUp = "REQUEST_COMPLETE_SIGN_UP",
  RequestCompleteSignUpSuccess = "REQUEST_COMPLETE_SIGN_UP_SUCCESS",
  RequestCompleteSignUpError = "REQUEST_COMPLETE_SIGN_UP_ERROR",

  SetAccountData = "SET_ACCOUNT_DATA",
  setPasswordData = "SET_PASSWORD_DATA",
  SetCompleteSignUpData = "SET_COMPLETE_SIGNUP_DATA",

  Reset = "RESET",
}

export type CreateAccountPayload = {
  [CreateAccountActions.SetSectionSignup]: CreateAccountSectionsType;

  [CreateAccountActions.RequestSignUp]: undefined;
  [CreateAccountActions.RequestSignUpSuccess]: RequestSignUpType;
  [CreateAccountActions.RequestSignUpError]: undefined;

  [CreateAccountActions.RequestSMSConfirm]: undefined;
  [CreateAccountActions.RequestSMSConfirmSuccess]: undefined;
  [CreateAccountActions.RequestSMSConfirmError]: undefined;

  [CreateAccountActions.RequestSMSResend]: undefined;
  [CreateAccountActions.RequestSMSResendSuccess]: undefined;
  [CreateAccountActions.RequestSMSResendError]: undefined;

  [CreateAccountActions.RequestCompleteSignUp]: undefined;
  [CreateAccountActions.RequestCompleteSignUpSuccess]: RequestSignUpType;
  [CreateAccountActions.RequestCompleteSignUpError]: undefined;

  [CreateAccountActions.SetAccountData]: Partial<UserToAddType>;
  [CreateAccountActions.setPasswordData]: Partial<UserToAddType>;
  [CreateAccountActions.SetCompleteSignUpData]: {
    data: Partial<CompleteSignUpType>;
    ready?: boolean;
  };

  [CreateAccountActions.Reset]: undefined;
};

export type CreateAccountContextProps = CreateAccountState & {
  /*
   * Manipula index da seção exibida no cadastro
   */
  setSection(payload: CreateAccountSectionsType): void;

  agreeTerms: boolean;

  setAgreeTerms: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * Armazena nome, telefone e e-mail(opcional) do usuário
   */
  setAccountData(data: Partial<UserToAddType>): void;

  /**
   * Armazena senha e confirmação de senha do usuário e chama request de signUp
   */
  setPasswordData(data: Partial<UserToAddType>): any;

  /**
   * Envia código de 4 digitos para confirmação da conta
   */
  SMSConfirmRequest(code: string): Promise<void>;

  /**
   * Reenvia código de confirmação da conta
   */
  SMSResendRequest(): Promise<void>;

  /**
   * Armazena dados para completar o cadastro do usuário
   */
  setCompleteSignUpData(
    data: Partial<CompleteSignUpType>,
    ready?: boolean
  ): void;

  /**
   * Reseta Estado
   */
  resetState(): void;
};

export type CreateAccountActionMap = ActionMapData<CreateAccountPayload>;
