import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface BusinessUpdateType {
  type: string;
  cpf: string;
  cnpj: string;
  fantasyName: string;
  email: string;
  phone: string;
  cep: string;
  street: string;
  number: string;
  neighborhood: string;
  uf: string;
  city: string;
  complement: string;
}

export const businessUpdate = (params: BusinessUpdateType) =>
  api.put(`/v1/user/company`, params).then(res => res.data);

export function useBusinessUpdate(
  config?: UseMutationOptions<any, any, BusinessUpdateType, unknown>
) {
  return useMutation<any, any, BusinessUpdateType>(
    (data: BusinessUpdateType) => businessUpdate(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao atualizar dados"
        );
      },
      ...config,
    }
  );
}
