import { useMemo } from "react";
import { useWatch, FormProvider } from "react-hook-form";

import {
  TabPanel,
  Wrap,
  Button,
  useRadioGroup,
  Divider,
} from "@chakra-ui/react";
import * as yup from "yup";

import {
  OrderForm,
  TechnicalReportForm,
} from "src/hooks/order/useOrderForm/props";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";
import { OrderDetailType } from "src/interfaces/order";
import { useUploadImage } from "src/services/hooks/image/useUploadImage";
import { useCreateOrder } from "src/services/hooks/order/useCreateOrder";
import { formatDateToISO } from "src/utils/date";

import { CreateOrderBottomBar } from "../CreateOrderBottomBar";
import { ItemsOfOrderButton } from "../ItemsOfOrderButton";
import { ConditionPaymentSection } from "./components/ConditionPaymentSection";
import { DiscountSection } from "./components/DiscountSection";
import { PaymentReceiveSection } from "./components/PaymentReceiveSection";
import { TypeConditionButton } from "./components/TypeConditionButton";
import { orderPaymentSchema } from "./schema";

export type OrderPaymentSchema = yup.InferType<typeof orderPaymentSchema>;

interface OrderPaymentFormProps {
  [key: string]: any;
}

export function OrderPaymentForm({ ...props }: OrderPaymentFormProps) {
  const {
    handleToPreviousStep,
    handleToNextStep,
    subtotal,
    order,
    setOrder,
    setOrderCreated,
    methodsOrderPayment,
    type,
    isDirectSale,
  } = useOrderForm();

  const orderFilters = isDirectSale ? undefined : useOrderFilters();

  const { mutateAsync, isLoading } = useCreateOrder({
    onSuccess: (order: OrderDetailType) => {
      setOrderCreated(order);

      if (!isDirectSale && orderFilters) {
        orderFilters.setRestartOrderList(true);
      }
      handleToNextStep();
    },
  });

  const { mutateAsync: uploadImage, isLoading: isLoadingUploadImage } =
    useUploadImage();

  const { control, reset, getValues, handleSubmit } = methodsOrderPayment;

  const conditionType = useWatch({
    name: "conditionType",
    control,
  });

  const { discount, discountType } = useWatch({
    control,
  });

  const discountParsed = useMemo(() => {
    return discountType === "money"
      ? Number(discount || 0)
      : (Number(discount || 0) * subtotal) / 100;
  }, [discount, discountType, subtotal]);

  const resetConditionPaymentConfig = (
    conditionType: string,
    customConditionType?: string
  ) => {
    let downPaymentType;
    let downPaymentValue;
    let remainingPaymentType;
    let remainingPaymentValue;

    if (conditionType === "A Vista" || conditionType === "Pagar depois") {
      downPaymentType = "percentage";
      downPaymentValue = "100";
      remainingPaymentType = "";
      remainingPaymentValue = "";
    }
    if (conditionType === "50%/50%") {
      downPaymentType = "percentage";
      downPaymentValue = "50";
      remainingPaymentType = "percentage";
      remainingPaymentValue = "50";
    }
    if (conditionType === "Com entrada") {
      downPaymentType = "value";
      downPaymentValue = "";
      remainingPaymentType = "installments";
      remainingPaymentValue = "";
    }
    if (conditionType === "Parcelado") {
      downPaymentType = "";
      downPaymentValue = "";
      remainingPaymentType = "installments";
      remainingPaymentValue = "";
    }

    const data = {
      conditionType: customConditionType || conditionType,
      downPaymentType,
      downPaymentValue,
      remainingPaymentType,
      remainingPaymentValue,
      paymentType: conditionType === "Pagar depois" ? "A Vista" : conditionType,
    };

    reset({
      ...getValues(),
      ...(conditionType === "Pagar depois" && {
        financialAccount: undefined,
        paymentMethod: undefined,
      }),
      ...data,
    });
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "conditionType",
    value: conditionType,
  });

  const onSubmit = async ({
    paymentType,
    downPaymentType,
    downPaymentValue,
    remainingPaymentType,
    remainingPaymentValue,
    paymentMethod,
    financialAccount,
    discount,
    discountType,
  }: OrderPaymentSchema) => {
    const orderData = order as OrderForm;

    const products = orderData.products.map(productOrder => ({
      ...productOrder,
      product: undefined,
    }));

    const services = orderData.services.map(serviceOrder => ({
      ...serviceOrder,
      service: undefined,
    }));

    const paymentMethodSelect =
      conditionType === "Pagar depois" ? undefined : paymentMethod;
    const financialAccountSelect =
      conditionType === "Pagar depois" ? undefined : financialAccount;

    let technicalReport: TechnicalReportForm | undefined;

    if (order && order.technicalReport) {
      const { gallery } = order.technicalReport;
      const galleryWithImageUrl = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const item of gallery) {
        if (item.image instanceof File) {
          // eslint-disable-next-line no-await-in-loop
          const { url } = await uploadImage(item.image);

          galleryWithImageUrl.push({
            ...item,
            imageUrl: url as string,
          });
        }
      }

      technicalReport = {
        ...order.technicalReport,
        gallery: galleryWithImageUrl,
      };
    }

    const body = {
      // COMMON
      type,
      // PRODUCT AND SERVICES
      products,
      services,
      // ORDER DATA
      contactId: orderData?.contactId,
      subContactId: orderData?.subContactId,
      operatorId: orderData?.operatorId,

      deadline: orderData?.deadline
        ? formatDateToISO(orderData?.deadline)
        : undefined,
      createdDate: orderData?.createdDate
        ? formatDateToISO(orderData?.createdDate)
        : undefined,

      additionalInfo: orderData.additionalInfo,

      technicalReport: technicalReport
        ? {
            data: technicalReport.data,
            gallery: technicalReport.gallery.map(item => ({
              imageUrl: item.imageUrl,
              name: item.name,
              description: item.description,
            })),
          }
        : undefined,
      // ODER PAYMENT
      paymentType,

      discount: discount ? Number(discount) : undefined,
      discountType,

      downPaymentType,
      downPaymentValue: downPaymentValue ? Number(downPaymentValue) : undefined,

      remainingPaymentType,
      remainingPaymentValue: remainingPaymentValue
        ? Number(remainingPaymentValue)
        : undefined,

      paymentMethod: paymentMethodSelect,
      financialAccount: financialAccountSelect,
    };

    setOrder(prev => ({
      ...(prev && prev),
      paymentType,

      discount: discount ? Number(discount) : undefined,
      discountType,

      downPaymentType,
      downPaymentValue: downPaymentValue ? Number(downPaymentValue) : undefined,

      remainingPaymentType,
      remainingPaymentValue: remainingPaymentValue
        ? Number(remainingPaymentValue)
        : undefined,

      paymentMethod: paymentMethodSelect,
      financialAccount: financialAccountSelect,

      technicalReport,
    }));
    await mutateAsync(body);
  };

  return (
    <FormProvider {...methodsOrderPayment}>
      <TabPanel {...props} p={0} as="form" onSubmit={handleSubmit(onSubmit)}>
        <Divider my={4} />

        <Wrap {...getRootProps()}>
          <TypeConditionButton
            {...getRadioProps({ value: "A Vista" })}
            onChange={() => {
              resetConditionPaymentConfig("A Vista");
            }}
          >
            A vista
          </TypeConditionButton>
          <TypeConditionButton
            {...getRadioProps({ value: "Parcelado" })}
            onChange={() => {
              resetConditionPaymentConfig("Parcelado");
            }}
          >
            Parcelado
          </TypeConditionButton>
          <TypeConditionButton
            {...getRadioProps({ value: "50%/50%" })}
            onChange={() => {
              resetConditionPaymentConfig("50%/50%");
            }}
          >
            50% / 50%
          </TypeConditionButton>
          <TypeConditionButton
            {...getRadioProps({ value: "Com entrada" })}
            onChange={() => {
              resetConditionPaymentConfig("Com entrada");
            }}
          >
            Com entrada
          </TypeConditionButton>
          <TypeConditionButton
            {...getRadioProps({ value: "Pagar depois" })}
            onChange={() => {
              resetConditionPaymentConfig("Pagar depois");
            }}
          >
            Pagar depois
          </TypeConditionButton>
        </Wrap>

        <ConditionPaymentSection
          resetConditionPaymentConfig={resetConditionPaymentConfig}
        />

        {conditionType !== "Pagar depois" ? <PaymentReceiveSection /> : null}

        <DiscountSection />
        <CreateOrderBottomBar
          subtotal={subtotal}
          discount={discountParsed}
          total={subtotal - discountParsed}
          right={
            <Wrap spacing={4}>
              <ItemsOfOrderButton />
              <Button
                variant="outline"
                onClick={() => {
                  handleToPreviousStep();
                }}
                isDisabled={isLoading || isLoadingUploadImage}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                isLoading={isLoading || isLoadingUploadImage}
              >
                Finalizar
              </Button>
            </Wrap>
          }
        />
      </TabPanel>
    </FormProvider>
  );
}
