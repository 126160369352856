import React from "react";

import Box from "@material-ui/core/Box";

import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

interface SalesByClientBottomBarProps {
  grossAmount: number;
  discount: number;
  totalAmount: number;
}

const SalesByClientBottomBar: React.FC<SalesByClientBottomBarProps> = props => {
  const { grossAmount, discount, totalAmount } = props;

  return (
    <Styled.AppBar position="fixed" component="footer">
      <Styled.Container>
        <Box display="flex">
          <Box mr={4}>
            <Styled.LabelData>Subtotal</Styled.LabelData>
            <Styled.ValueData>{formatPrice(grossAmount)}</Styled.ValueData>
          </Box>
          <Box pr={4} mr={4} borderRight="solid #D6D8DC 1px">
            <Styled.LabelData>
              Desconto
              <Styled.LabelIconMinus />
            </Styled.LabelData>
            <Styled.ValueData isRed>{formatPrice(discount)}</Styled.ValueData>
          </Box>
          <Box>
            <Styled.LabelTotalValue>
              Valor total
              <Styled.LabelIconEquals />
            </Styled.LabelTotalValue>
            <Styled.ValueData>{formatPrice(totalAmount)}</Styled.ValueData>
          </Box>
        </Box>
      </Styled.Container>
    </Styled.AppBar>
  );
};

export default SalesByClientBottomBar;
