import React from "react";

import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";

import Button from "src/components/Button";
import { ButtonProps } from "src/components/Button/props";

const CloseButton: React.FC<ButtonProps> = props => {
  const { ...rest } = props;

  return (
    <Box>
      <Button {...rest}>
        <CloseIcon color="primary" />
      </Button>
    </Box>
  );
};

export default CloseButton;
