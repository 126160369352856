import React, { useMemo } from "react";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloseIcon from "@material-ui/icons/Close";
import { DatePickerProps } from "@material-ui/pickers";
import { useField } from "formik";

import Button from "src/components/Button";
import { formatDateToISO } from "src/utils/date";

import * as Styled from "./styles";

interface ValidityFieldProps extends Partial<DatePickerProps> {
  name: string;
}

const ValidityField: React.FC<ValidityFieldProps> = ({ name, ...rest }) => {
  const [meta, , helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;

  const date = useMemo(() => {
    if (value) {
      return formatDateToISO(value);
    }
    return null;
  }, [value]);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setValue(date);
    }
  };

  const handleDelete = (event: { stopPropagation(): void }) => {
    event.stopPropagation();
    setValue(null);
    if (rest.onChange) {
      rest.onChange(null);
    }
  };

  const inputProps = useMemo(() => {
    if ((date && !rest?.disabled) || rest.value) {
      return {
        endAdornment: (
          <div>
            <Button onClick={handleDelete} size="small">
              <CloseIcon fontSize="small" color="primary" />
            </Button>
          </div>
        ),
      };
    }

    return {
      endAdornment: (
        <CalendarTodayIcon
          fontSize="small"
          color="primary"
          style={{
            opacity: rest?.disabled ? 0.5 : 1,
          }}
        />
      ),
    };
  }, [date, rest?.disabled, rest.value]);

  return (
    <Styled.Container>
      <Styled.DeadlineDatePicker
        value={date}
        onChange={handleDateChange}
        animateYearScrolling
        inputVariant="outlined"
        format="dd/MM/yyyy"
        InputProps={inputProps}
        cancelLabel="cancelar"
        fullWidth
        {...rest}
      />
    </Styled.Container>
  );
};

export default ValidityField;
