import styled from "styled-components";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

import Typography from "src/components/Typography";
import { RECORD_STATUS, RECORD_TYPE_COLOR } from "src/constants/record";
import { RecordType, RecordTypeType } from "src/interfaces/record";

export const Accordion = styled(MuiAccordion)`
  border-radius: 10px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  &.MuiAccordion-root:before {
    border: 0;
    background-color: transparent;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  height: 60px;
  width: 100%;
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  width: 100%;
`;

export const ExpenseCardDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const Dot = styled.div<{ type: RecordTypeType }>`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${({ type, theme }) =>
    type ? RECORD_TYPE_COLOR[type] : theme.palette.common.white};
  margin: 0px 20px 0px 0px;
`;

export const PayedBadge = styled(Typography)<Pick<RecordType, "paidStatus">>`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ paidStatus, theme }) =>
    paidStatus ? RECORD_STATUS[paidStatus].color : theme.palette.common.white};
  text-align: center;
  border-radius: 5px;
  align-items: center;
  width: 80px;
  padding: 2px 0px;
  margin: 0px 20px;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 12px;
  margin-right: 4px;
`;
