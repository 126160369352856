import React, { useMemo, useEffect } from "react";

import { Switch } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";

import Typography from "src/components/Typography";
import { useViewOrder } from "src/hooks/order/useViewOrder";
import { DiscountType, OrderType } from "src/interfaces/order";

import { DiscountSectionProps } from "./props";
import * as Styled from "./styles";

export const DISCOUNT_ITEMS = [
  { name: "Valor fixo", value: "money" },
  { name: "Porcentagem", value: "percentage" },
];

const DiscountSection: React.FC<DiscountSectionProps> = props => {
  const { enabled = true } = props;

  const { values, setFieldValue } = useFormikContext<Partial<OrderType>>();

  const { total, totalWithoutDiscount, discount, setDiscount } = useViewOrder();

  useEffect(() => {
    const discountValue = Number(values?.discount || 0);

    if (values?.discountType === "money") {
      setDiscount(discountValue);
    }

    if (values?.discountType === "percentage") {
      setDiscount((discountValue * totalWithoutDiscount) / 100);
    }

    if (!values?.discountType) {
      setDiscount(0);
    }
  }, [values.discountType, values.discount, total, totalWithoutDiscount]);

  const enabledContainer = useMemo(
    () => enabled && Boolean(values?.discountType),
    [enabled, values?.discountType]
  );

  const totalValue = useMemo(() => {
    if (!discount) {
      return "";
    }

    if (total > 0) {
      return total;
    }

    return 0;
  }, [discount, total]);

  const renderFields = useMemo(() => {
    if (values.discountType === "money") {
      return (
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <Styled.CurrencyField
            label="Valor do desconto"
            name="discount"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      );
    }

    if (values.discountType === "percentage") {
      return (
        <>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <Styled.PercentField
              label="Porcentagem"
              name="discount"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <Styled.CurrencyField
              label="Valor do desconto"
              name=""
              value={discount}
              disabled
              disableFormik
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </>
      );
    }

    return null;
  }, [values.discount, values.discountType, totalValue, discount]);

  const switchDiscounthandleChange = () => {
    setFieldValue("discountType", values.discountType ? undefined : "money");
  };

  const discountTypeHandleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setFieldValue("discountType", event.target.value as DiscountType);
    setFieldValue("discount", "");
  };

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" mt={2} mb={2}>
        <Typography variant="body1" color="textSecondary">
          Desconto
        </Typography>
        {enabled ? (
          <Switch
            checked={Boolean(values?.discountType)}
            onChange={switchDiscounthandleChange}
            color="primary"
            name="discount"
            inputProps={{ "aria-label": "discount" }}
          />
        ) : null}
      </Box>
      <Styled.Container enabled={enabledContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <Styled.SelectField
              label="Desconto"
              name="discountType"
              items={DISCOUNT_ITEMS}
              defaultValue="money"
              onChange={discountTypeHandleChange}
            />
          </Grid>
          {renderFields}
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <Styled.CurrencyField
              label="Total com desconto"
              name=""
              value={totalValue}
              disabled
              disableFormik
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Styled.Container>
    </>
  );
};

export default DiscountSection;
