import React from "react";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import { LoaderProps } from "./size";

const Loader: React.FC<LoaderProps> = props => {
  const { size = "fullPage" } = props;

  if (size === "medium") {
    return (
      <Box
        width="100%"
        height="250px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={50} />
    </Box>
  );
};

export default Loader;
