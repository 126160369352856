import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { format, isPast } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Typography from "src/components/Typography";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useRecord } from "src/hooks/record/useRecord";
import { RecordType } from "src/interfaces/record";
import ChargebackRecordModal from "src/pages/Financial/components/Modals/ChargebackRecordModal";
import CreateOrderRecordModal from "src/pages/Financial/components/Modals/CreateOrderRecordModal";
import DeleteRecordModal from "src/pages/Financial/components/Modals/DeleteRecordModal";
import PayRecordModal from "src/pages/Financial/components/Modals/PayRecordModal";
import RecordFormModal, {
  RecordFormOriginEnum,
} from "src/pages/Financial/components/Modals/RecordFormModal";
import RecordCardOptionsMenu from "src/pages/Financial/components/RecordCardOptionsMenu";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const RecordsData: React.FC = () => {
  const navigate = useNavigate();

  const { record, setRecord } = useRecord();

  const { buyOrder, setBuyOrder } = useBuyOrder();
  const records = buyOrder?.records ?? [];

  const { handleOpen } = useBaseSwipeableDrawer();

  const { palette } = useTheme();

  const handleChangeRecordInBuyOrder = (record: RecordType) => {
    if (!buyOrder) return;

    const records = (
      buyOrder?.records.map(recordItem => {
        if (recordItem.id === record.id) return record;
        return recordItem;
      }) ?? []
    ).sort((a, b) => {
      const dateA = a?.paidDate ? new Date(a?.paidDate).getTime() : 0;
      const dateB = b?.paidDate ? new Date(b?.paidDate).getTime() : 0;
      return dateA - dateB;
    });

    const newBuyOrder = {
      ...buyOrder,
      records,
    };

    setBuyOrder(newBuyOrder);
    navigate(".", { replace: true, state: { data: newBuyOrder } });
  };

  const handleDeleteRecordInBuyOrder = (record: RecordType) => {
    if (!buyOrder) return;

    const records =
      buyOrder?.records.filter(recordItem => recordItem.id !== record.id) ?? [];

    const newBuyOrder = {
      ...buyOrder,
      records,
    };

    setBuyOrder(newBuyOrder);
    navigate(".", { replace: true, state: { data: newBuyOrder } });
  };

  const handleCreateRecordInBuyOrder = (record: RecordType) => {
    if (!buyOrder) return;

    const records = [buyOrder?.records ?? [], record].flat().sort((a, b) => {
      const dateA = a?.paidDate ? new Date(a?.paidDate).getTime() : 0;
      const dateB = b?.paidDate ? new Date(b?.paidDate).getTime() : 0;
      return dateA - dateB;
    });

    const newBuyOrder = {
      ...buyOrder,
      records,
    };

    setBuyOrder(newBuyOrder);
    navigate(".", { replace: true, state: { data: newBuyOrder } });
  };

  const renderRecord = (record: RecordType, index: number) => {
    const renderIndicatorColor = () => {
      if (record.paidStatus === "paid") {
        return palette.primary.main;
      }
      if (record.paidDate && isPast(new Date(record.paidDate))) {
        return palette.error.main;
      }

      return palette.warning.main;
    };

    const renderPaidDate = () => {
      if (record.paidDate) {
        const dateISO = new Date(record.paidDate);
        return format(dateISO, "d MMM", { locale: ptBrLocale });
      }

      return null;
    };

    const paid = record.paidStatus === "paid";

    return (
      <Styled.LineRecord item xs={3}>
        <Styled.LineRecord item xs={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={20}
          >
            <Styled.IndicatorRecord
              color={renderIndicatorColor()}
              paid={paid}
            />
          </Box>
        </Styled.LineRecord>
        <Styled.LineRecord item xs={2}>
          <Styled.Text paid={paid}>{index + 1}º</Styled.Text>
        </Styled.LineRecord>

        <Styled.LineRecord item xs={3}>
          <Styled.Text uppercase paid={paid}>
            {renderPaidDate()}
          </Styled.Text>
        </Styled.LineRecord>
        <Styled.LineRecord item xs={4}>
          <Styled.Text align="right" paid={paid}>
            {formatPrice(record.value)}
          </Styled.Text>
        </Styled.LineRecord>
        <Styled.LineRecord item xs={1}>
          <RecordCardOptionsMenu data={record} />
        </Styled.LineRecord>
      </Styled.LineRecord>
    );
  };

  const renderRecords = useMemo(() => {
    if (records?.length > 0) {
      return records.map((record, index) => renderRecord(record, index));
    }
    return null;
  }, [records]);

  const valuePaid = useMemo(() => {
    if (records?.length > 0) {
      return records.reduce((value, record) => {
        if (record.paidStatus === "paid") {
          return value + record.value;
        }
        return value;
      }, 0);
    }
    return 0;
  }, [records]);

  const valueRemaining = useMemo(() => {
    if (!buyOrder) return 0;

    const remaining = buyOrder.totalAmount - valuePaid;
    if (remaining > 0) {
      return remaining;
    }
    return 0;
  }, [valuePaid, buyOrder?.totalAmount]);

  return (
    <Styled.Container>
      <Box width="100%" display="flex" justifyContent="space-between" mb={2}>
        <Typography color="primary">Pagamentos</Typography>
        <Styled.Button
          color="primary"
          variant="outlined"
          onClick={() => handleOpen("orderRecordForm")}
        >
          <Typography>Novo pagamento</Typography>
        </Styled.Button>
      </Box>
      <Styled.GridContainer>
        <Grid item>
          <Styled.LabelText>Valor pago</Styled.LabelText>
          <Styled.LabelValue>{formatPrice(valuePaid)}</Styled.LabelValue>
        </Grid>
        <Grid item>
          <Styled.LabelText>Valor restante</Styled.LabelText>
          <Styled.LabelValue>{formatPrice(valueRemaining)}</Styled.LabelValue>
        </Grid>
      </Styled.GridContainer>
      <Grid container spacing={2}>
        {renderRecords}
      </Grid>
      <ChargebackRecordModal
        record={record}
        onSuccess={recordResponse => {
          handleChangeRecordInBuyOrder(recordResponse);
        }}
      />
      <PayRecordModal
        record={record}
        onSuccess={recordResponse => {
          handleChangeRecordInBuyOrder(recordResponse);
        }}
      />
      <RecordFormModal
        handleCloseRecordFormModal={() => {
          setRecord(undefined);
        }}
        onSuccess={recordResponse => {
          handleChangeRecordInBuyOrder(recordResponse);
        }}
        record={record}
        recordFormOrigin={RecordFormOriginEnum.expense}
        recordType="expense"
      />
      <DeleteRecordModal
        record={record}
        onSuccess={() => {
          handleDeleteRecordInBuyOrder(record as RecordType);
        }}
      />
      <CreateOrderRecordModal
        recordType="expense"
        buyOrderId={buyOrder?.id}
        onSuccess={recordResponse => {
          handleCreateRecordInBuyOrder(recordResponse);
        }}
      />
    </Styled.Container>
  );
};

export default RecordsData;
