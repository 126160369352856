import React, { FC, useState } from "react";

import Box from "@material-ui/core/Box";
import { Formik, Form } from "formik";

import Button from "src/components/Button";
import { useModule } from "src/hooks/module/useModule";
import { BuyOrderSettingType } from "src/interfaces/module";
import SectionCard from "src/pages/Settings/components/SectionCard";

import SwitchSettings from "../../SwitchSettings";

const BuyOrderSection: FC = () => {
  const { updateSettingsModule } = useModule();

  const { buyOrderModule } = useModule();

  const [status, setStatus] = useState(false);

  const settings = buyOrderModule?.settings as BuyOrderSettingType;

  if (!buyOrderModule) {
    return null;
  }

  const onSubmit = async (settings: BuyOrderSettingType) => {
    setStatus(true);
    await updateSettingsModule(buyOrderModule.module.id, settings);
    setStatus(false);
  };

  return (
    <Formik
      initialValues={
        {
          createRecordsManually: settings?.createRecordsManually ?? false,
          createStockManually: settings?.createStockManually ?? false,
        } as BuyOrderSettingType
      }
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <SectionCard title="Ordem de compra">
          <Box mb={2}>
            <SwitchSettings
              name="createRecordsManually"
              label="Lançar contas manualmente"
              disabled={status}
            />
          </Box>
          <Box mb={2}>
            <SwitchSettings
              name="createStockManually"
              label="Lançar estoque manualmente"
              disabled={status}
            />
          </Box>
          <Box display="flex" flexDirection="row-reverse">
            <div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="small"
                fullWidth
                loading={status}
              >
                Salvar
              </Button>
            </div>
          </Box>
        </SectionCard>
      </Form>
    </Formik>
  );
};

export default BuyOrderSection;
