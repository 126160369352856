import { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import { uploadImage } from "src/services/image";

import { ImagePostResponseType } from "./props";

export const useImage = () => {
  const [status, setStatus] = useState(STATUS.inital);

  const [image, setImage] = useState<ImagePostResponseType>(
    {} as ImagePostResponseType
  );

  const upload = useCallback(async (file: File) => {
    try {
      setStatus(STATUS.loading);

      const formData = new FormData();
      formData.append("image", file);

      const response: ImagePostResponseType = await uploadImage(formData);

      setImage(response);

      setStatus(STATUS.success);
    } catch (error) {
      setImage({} as ImagePostResponseType);
      toast.error("Ocorreu um problema ao fazer o upload dessa imagem");
      setStatus(STATUS.error);
    }
  }, []);

  const uploadAndReturnUrl = useCallback(
    async (file: File): Promise<ImagePostResponseType | undefined> => {
      try {
        const formData = new FormData();
        formData.append("image", file);

        const response: ImagePostResponseType = await uploadImage(formData);

        return response;
      } catch (error) {
        toast.error("Ocorreu um problema ao fazer o upload dessa imagem");
      }
      return undefined;
    },
    []
  );

  const reset = () => {
    setImage({} as ImagePostResponseType);
    setStatus(STATUS.inital);
  };

  return {
    status,
    image,
    setImage,
    upload,
    reset,
    uploadAndReturnUrl,
  };
};
