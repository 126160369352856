import React from "react";

import Box from "@material-ui/core/Box";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import Button from "src/components/Button";

import { VisibilityButtonProps } from "./props";

const VisibilityButton: React.FC<VisibilityButtonProps> = props => {
  const { visible, ...rest } = props;

  return (
    <Box>
      <Button color="primary" {...rest}>
        {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </Button>
    </Box>
  );
};

export default VisibilityButton;
