import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Grid,
  GridItem,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SearchIcon from "@material-ui/icons/Search";

import { Input } from "src/components/NewComponents/Input";
import PeriodFilterPicker from "src/components/NewComponents/PeriodFilterPicker";
import { SelectedFilterType } from "src/components/PeriodFilterPicker/props";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import { usePeriodFilterType } from "src/hooks/periodFilterType/usePeriodFilterType";
import { useOrderType } from "src/pages/Orders/useOrderType";
import { debounce } from "src/utils/debounce";

import { OptionsPanelsProps } from "./props";
import * as Styled from "./styles";

const OptionsPanel: React.FC<OptionsPanelsProps> = props => {
  const {
    setSearch,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterFieldDate,
    setFilterFieldDate,
  } = props;
  const navigate = useNavigate();
  const { type } = useOrderType();
  const { serviceOrderNameDefinitions } = useCompany();

  const { getPeriodTypeCache, setPeriodTypeCache } = usePeriodFilterType();

  const initialFilterType = useMemo(
    () => (getPeriodTypeCache(type) === "byDay" ? "byDay" : "empty"),
    [type]
  );

  const applyFilterType = (periodType: SelectedFilterType) => {
    setPeriodTypeCache(type, periodType);
  };

  const searchByCustomer = useCallback(
    debounce((query: string) => {
      setSearch(query);
    }, 1000),
    []
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    searchByCustomer(e.target.value);
  };

  return (
    <Styled.Container>
      <Grid templateColumns="repeat(6, 1fr)" gap={6}>
        <GridItem colSpan={5}>
          <InputGroup>
            <InputRightElement pointerEvents="none">
              <SearchIcon color="primary" />
            </InputRightElement>

            <Input
              label="Pesquisar por número ou cliente"
              name="name"
              bg="white"
              onChange={onChange}
            />
          </InputGroup>
        </GridItem>
        <GridItem colSpan={1}>
          <Button
            rightIcon={<AddCircleIcon />}
            w="100%"
            onClick={() =>
              type === "serviceOrder"
                ? navigate(PATHS.SERVICE_ORDER_ADD)
                : navigate(PATHS.PRODUCT_ORDER_ADD)
            }
          >
            {type === "serviceOrder"
              ? serviceOrderNameDefinitions.title
              : "Novo pedido de venda"}
          </Button>
        </GridItem>
      </Grid>
      <PeriodFilterPicker
        startDate={startDate || new Date()}
        endDate={endDate || new Date()}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        initialFilter={initialFilterType}
        setSelectedFilterType={applyFilterType}
        filterFieldDate={filterFieldDate}
        setFilterFieldDate={setFilterFieldDate}
      />
    </Styled.Container>
  );
};

export default OptionsPanel;
