import { useMemo } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Flex, Text, Wrap } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { CurrencyInput } from "src/components/NewComponents/CurrencyInput";
import { Select } from "src/components/NewComponents/Select";
import { useModule } from "src/hooks/module/useModule";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { Option } from "src/interfaces/common";
import { FinancialAccountType } from "src/interfaces/financialAccount";
import { useOpenCashFlow } from "src/services/hooks/cashFlow/useOpenCashFlow";
import { useActiveFinancialAccountsWithBalance } from "src/services/hooks/financialAccount/useActiveFinancialAccountsWithBalance";

import { schemaOpenCashFlowForm } from "./schema";

type OpenCashFlowSchema = yup.InferType<typeof schemaOpenCashFlowForm>;

function OpenCashFlowModal() {
  const { open, handleClose } = useBaseSwipeableDrawer();
  const queryClient = useQueryClient();

  const { user } = useAuth();
  const { hasModules } = useModule();

  const actualDate = useMemo(() => {
    return new Date();
  }, [open]);

  const { mutate: openCashFlow, isLoading } = useOpenCashFlow({
    onSuccess: () => {
      queryClient.invalidateQueries(["cashflowOpened"]);
      queryClient.invalidateQueries(["cashflowResume"]);
      toast.success("Caixa aberto com sucesso!");
      handleCustomClose();
    },
  });

  const { data: accounts } = useActiveFinancialAccountsWithBalance(undefined, {
    enabled: hasModules(["accountManagement", "cashflowcontrol"], true),
  });

  const defaultValues: OpenCashFlowSchema = {
    initMoney: accounts.find(account => account.type === "cash")?.balance ?? 0,
    actualMoney:
      accounts.find(account => account.type === "cash")?.balance ?? 0,
    financialAccount:
      accounts.find(account => account.type === "cash")?.id ?? "",
  };

  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<OpenCashFlowSchema>({
    defaultValues,
    resolver: yupResolver(schemaOpenCashFlowForm),
  });

  const initMoney = useWatch({ name: "initMoney", control });
  const actualMoney = useWatch({ name: "actualMoney", control });
  const financialAccount = useWatch({ name: "financialAccount", control });

  const handleCustomClose = () => {
    reset(defaultValues);
    handleClose("openCashFlowModal");
  };

  const financialAccounts = useMemo(() => {
    return accounts
      .filter(account => account.type === "cash")
      .map(account => ({ label: account.name, value: account.id }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [accounts]);

  const onSubmit = (values: OpenCashFlowSchema) => {
    openCashFlow({
      initMoney: values.initMoney,
      financialAccount: values.financialAccount,
    });
  };

  return (
    <BaseSwipeableDrawer
      tag="openCashFlowModal"
      title="Abrir caixa"
      buttonTitle="Abrir caixa"
      buttonProps={{
        loading: isLoading,
        disabled: isLoading,
        form: "openCashFlowForm",
      }}
      customHandleClose={handleCustomClose}
    >
      <Flex
        w={["100%", "400px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="openCashFlowForm"
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
        direction="column"
      >
        <Text fontSize="sm" color="gray.400">
          Informações do caixa
        </Text>
        <Wrap gap={2}>
          <Box flex={2}>
            <Text fontSize="sm" color="gray.500" fontWeight={700}>
              Venderdor
            </Text>
            <Text fontSize="xs" color="text" fontWeight={500}>
              {user.name}
            </Text>
          </Box>
          <Box flex={2}>
            <Text fontSize="sm" color="gray.500" fontWeight={700}>
              Data
            </Text>
            <Text fontSize="xs" color="text" fontWeight={500}>
              {format(actualDate, "dd/MM/yyyy")}
            </Text>
          </Box>
          <Box flex={1}>
            <Text fontSize="sm" color="gray.500" fontWeight={700}>
              Hora
            </Text>
            <Text fontSize="xs" color="text" fontWeight={500}>
              {format(actualDate, "HH:mm")}
            </Text>
          </Box>
        </Wrap>
        <Text fontSize="sm" color="gray.300">
          Dados do caixa
        </Text>
        <Controller
          control={control}
          name="financialAccount"
          render={({ field: { ref, onChange, ...props } }) => (
            <Select
              options={financialAccounts}
              inputRef={ref}
              error={!!errors.financialAccount}
              errorMessage={errors.financialAccount?.message}
              placeholder="Conta"
              isClearable={false}
              formatOptionLabel={(data: unknown) => {
                const account = data as Option;
                return (
                  <Flex alignItems="center" gap={2}>
                    {account.label}
                  </Flex>
                );
              }}
              onChange={financialAccountId => {
                const account = accounts.find(
                  account => account.id === financialAccountId
                ) as FinancialAccountType;
                reset({
                  actualMoney: account?.balance,
                  initMoney: account?.balance,
                  financialAccount: financialAccountId as string,
                });
                onChange(financialAccountId);
              }}
              {...props}
            />
          )}
        />
        <CurrencyInput
          label="Saldo em conta"
          control={control}
          name="actualMoney"
          isDisabled
        />
        <CurrencyInput
          label="Fundo de caixa"
          control={control}
          name="initMoney"
        />

        {financialAccount && Number(actualMoney) !== Number(initMoney) ? (
          <Box>
            <Text fontSize="md" color="error.500" mb={2}>
              Atenção:
            </Text>
            <Text fontSize="sm" fontWeight={400} textAlign="justify">
              O valor digitado para abertura de caixa é divergente do saldo da
              conta. Verifique ou refaça a contagem. Um alerta será fixado nessa
              operação para análise posterior.
            </Text>
          </Box>
        ) : null}
      </Flex>
    </BaseSwipeableDrawer>
  );
}

export default OpenCashFlowModal;
