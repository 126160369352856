import React, { useMemo, useEffect, useState } from "react";

import { Popper } from "@material-ui/core";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete";

import cashIcon from "src/assets/icons/financialAccounts/cash.svg";
import walletIcon from "src/assets/icons/financialAccounts/wallet.svg";
import {
  FinancialAccountType,
  FinancialAccountTypeEnum,
} from "src/interfaces/financialAccount";

import * as Styled from "./styles";

interface FinancialAccountTypeProps extends FinancialAccountType {
  firstLetter: string;
}

interface FinancialAccountFieldProps {
  required?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
  value: string | FinancialAccountType | undefined;
  setValue: (account: FinancialAccountTypeProps | null) => void;
  accounts: FinancialAccountType[];
  placeholder?: string;
  label?: string;
}

const FinancialAccountField: React.FC<FinancialAccountFieldProps> = props => {
  const {
    required,
    disabled,
    accounts,
    value,
    setValue,
    placeholder = "Escolher a conta financeira",
    label,
    disableClearable = true,
  } = props;

  const [accountSelected, setAccountSelected] =
    useState<FinancialAccountTypeProps | null>({} as FinancialAccountTypeProps);

  const options = useMemo(() => {
    return accounts.map(option => {
      let firstLetter = "";

      if (option.type) {
        firstLetter = option.isDefault ? "Conta padrão" : "Outras contas";
      }

      return {
        firstLetter,
        ...option,
      } as FinancialAccountTypeProps;
    });
  }, [accounts]);

  useEffect(() => {
    if (!value) {
      setAccountSelected(null);
      return;
    }

    let account;
    if (typeof value === "object") {
      account = value;
    } else {
      account = options.find(account => account.id === value);
    }

    if (account) {
      let firstLetter = "";

      if (account.id) {
        firstLetter = account.isDefault ? "Conta padrão" : "Outras contas";
      }

      setAccountSelected({
        ...account,
        firstLetter,
      });
    }
  }, [value, options]);

  const logo = (
    accountType: keyof typeof FinancialAccountTypeEnum,
    logo?: string
  ) => {
    if (accountType === "wallet") return walletIcon;
    if (accountType === "cash") return cashIcon;

    return logo;
  };

  const renderOption = (option: FinancialAccountTypeProps) => {
    return (
      <>
        <Styled.Logo
          src={logo(option.type, option?.institution?.image)}
          loading="eager"
        />
        {option?.name ?? ""}
      </>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <Styled.SearchField
        {...params}
        variant="outlined"
        fullWidth
        placeholder={placeholder}
        label={label}
        required={required}
        InputProps={{
          ...params.InputProps,
          startAdornment:
            accountSelected?.id && accountSelected?.type ? (
              <Styled.Logo
                src={logo(
                  accountSelected.type,
                  accountSelected.institution?.image
                )}
                loading="eager"
              />
            ) : null,
        }}
      />
    );
  };

  return (
    <Autocomplete
      selectOnFocus
      id="autocomplete-financialAccount"
      options={options.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      )}
      groupBy={(option: any) => option.firstLetter}
      getOptionSelected={(option, value) => option?.id === value?.id}
      getOptionLabel={(option: any) => option?.name ?? ""}
      value={accountSelected}
      disableClearable={disableClearable}
      onChange={(_, newValue) => {
        setAccountSelected(newValue);
        setValue(newValue);
      }}
      renderOption={renderOption}
      renderInput={renderInput}
      disabled={disabled}
      PopperComponent={({ style, ...props }) => (
        <Popper {...props} style={{ ...style, zIndex: 1999 }} />
      )}
    />
  );
};

export default FinancialAccountField;
