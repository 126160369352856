import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import Button from "src/components/Button";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";

import { BreadcrumbProps } from "./props";
import * as Styled from "./styles";

const Breadcrumb: React.FC<BreadcrumbProps> = props => {
  const { data, home, children } = props;

  const homeButton = useMemo(() => {
    if (home) {
      return (
        <Styled.BreadActive
          size="small"
          variant="contained"
          color="secondary"
          to={PATHS.HOME}
        >
          <Typography color="primary" variant="subtitle2" firstletter>
            Início
          </Typography>
        </Styled.BreadActive>
      );
    }
    return (
      <Button size="small" to={PATHS.HOME}>
        <Typography color="textSecondary" variant="subtitle2" firstletter>
          Início
        </Typography>
      </Button>
    );
  }, [home]);

  const buttons = useMemo(() => {
    if (home) return null;

    return data?.map((button, index) => {
      if (index + 1 === data?.length) {
        return (
          <Styled.BreadActive
            size="small"
            variant="contained"
            color="secondary"
            key={button.label}
          >
            <Typography color="primary" variant="subtitle2" firstletter>
              {button.label}
            </Typography>
          </Styled.BreadActive>
        );
      }
      return (
        <Button size="small" to={button.path} key={button.label}>
          <Typography color="textSecondary" variant="subtitle2" firstletter>
            {button.label}
          </Typography>
        </Button>
      );
    });
  }, [data]);

  return (
    <Box
      mt={1}
      mb={1}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box width="80%">
        <Breadcrumbs aria-label="breadcrumb" separator={<Styled.Arrow />}>
          {homeButton}
          {buttons}
        </Breadcrumbs>
      </Box>
      {children}
    </Box>
  );
};

export default Breadcrumb;
