import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Typography from "src/components/Typography";
import { FinancialAccountType } from "src/interfaces/financialAccount";
import { RecordType } from "src/interfaces/record";
import { formatPrice } from "src/utils/format";

import { logo } from "../../index";
import * as Styled from "../../styles";

interface RecordCardTransferProps {
  record: Partial<RecordType>;
  paidDate: string;
  hasAccountManagementModule: boolean;
}

const RecordCardTransfer: React.FC<RecordCardTransferProps> = props => {
  const { record, paidDate, hasAccountManagementModule } = props;

  const fromAcccount = useMemo(() => {
    return (
      record?.type === "transferIn"
        ? record?.transferFrom?.financialAccount
        : record?.financialAccount
    ) as FinancialAccountType;
  }, [record]);

  const toAcccount = useMemo(() => {
    return (
      record?.type === "transferIn"
        ? record?.financialAccount
        : record?.transferTo?.financialAccount
    ) as FinancialAccountType;
  }, [record]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="body2" color="textSecondary">
          Valor:
        </Typography>
        <Box mt={1}>
          <Typography variant="body2">{formatPrice(record.value)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" color="textSecondary">
          Data da transferência:
        </Typography>
        <Box mt={1}>
          <Typography variant="body2">{paidDate}</Typography>
        </Box>
      </Grid>

      {hasAccountManagementModule && fromAcccount ? (
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            Conta de saida:
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Styled.Logo
              src={logo(fromAcccount.type, fromAcccount?.institution?.image)}
              loading="eager"
            />
            <Typography variant="body2">{fromAcccount?.name}</Typography>
          </Box>
        </Grid>
      ) : null}

      {hasAccountManagementModule && toAcccount ? (
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            Conta de entrada:
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Styled.Logo
              src={logo(toAcccount.type, toAcccount?.institution?.image)}
              loading="eager"
            />
            <Typography variant="body2">{toAcccount?.name}</Typography>
          </Box>
        </Grid>
      ) : null}

      <Grid item xs={8}>
        <Typography variant="body2" color="textSecondary">
          Observação:
        </Typography>
        <Box mt={1}>
          <Typography variant="body2">
            {record?.note ? record.note : "-"}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RecordCardTransfer;
