import {
  Button,
  ButtonProps,
  Icon,
  IconProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  TextProps,
} from "@chakra-ui/react";
import InfoIconMUI from "@material-ui/icons/Info";

interface ActionButtonProps extends ButtonProps {
  label: string;
}

interface ConfirmActionsModalProps extends Omit<ModalProps, "children"> {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string;
  actions?: Array<ActionButtonProps>;
  customIcon?: React.ReactNode;
  iconProps?: IconProps;
  titleProps?: TextProps;
  subTitleProps?: TextProps;
}

export function ConfirmActionsModal(props: ConfirmActionsModalProps) {
  const {
    isOpen,
    onClose,
    customIcon,
    title,
    subtitle,
    iconProps = {},
    titleProps = {},
    subTitleProps = {},
    actions,
    ...rest
  } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg" {...rest}>
      <ModalOverlay />
      <ModalContent borderRadius={10}>
        <ModalHeader justifyContent="center" textAlign="center">
          {customIcon ?? (
            <Icon color="error.500" fontSize={40} {...iconProps}>
              <InfoIconMUI />
            </Icon>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={0}>
          <Text
            fontSize="lg"
            fontWeight={600}
            color="text"
            textAlign="center"
            {...titleProps}
          >
            {title}
          </Text>
          {subtitle ? (
            <Text
              mt={4}
              fontSize="sm"
              fontWeight={600}
              color="primary.500"
              textAlign="center"
              {...subTitleProps}
            >
              {subtitle}
            </Text>
          ) : null}
        </ModalBody>
        <ModalFooter flexDirection="column" gap={2}>
          {actions?.map(({ label, ...props }, index) => {
            return (
              <Button
                w="100%"
                {...props}
                key={`button_${Math.random()}_modal_${index?.toString()}`}
              >
                {label}
              </Button>
            );
          })}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
