import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useAuth } from "src/hooks/user/auth/useAuth";
import { CashFlowResume } from "src/interfaces/cashFlow";
import api from "src/services/api";

export const getCashFlowResume = () =>
  api.get<CashFlowResume>("v1/cashflow/resume").then(res => res.data);

export function useCashFlowResume(
  config?: UseQueryOptions<CashFlowResume, Error, CashFlowResume, unknown[]>
) {
  const { user } = useAuth();
  return useQuery<CashFlowResume, Error, CashFlowResume, unknown[]>(
    ["cashflowResume", user.id],
    () => getCashFlowResume(),
    {
      refetchOnWindowFocus: false,
      ...(config || {}),
    }
  );
}
