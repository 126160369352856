import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Button, Divider, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronRight } from "@material-ui/icons";

import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { Input } from "src/components/NewComponents/Input";
import { useAuth } from "src/hooks/user/auth/useAuth";
import {
  ProfileUpdateType,
  useProfileUpdate,
} from "src/services/hooks/auth/useProfileUpdate";
import { formatPhone } from "src/utils/format";
import { withContext } from "src/utils/withContext";

import ChangePasswordModal from "./ChangePasswordModal";
import { schemaMyAccountForm } from "./schema";

export type MyAccountScheme = ProfileUpdateType;

function MyAccount() {
  const { user, setUser, isOwner } = useAuth();
  const { handleOpen } = useBaseSwipeableDrawer();

  const defaultValues: MyAccountScheme = {
    name: user?.name || "",
    email: user?.email || "",
  };

  const methods = useForm<MyAccountScheme>({
    defaultValues,
    resolver: yupResolver(schemaMyAccountForm),
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    getValues,
  } = methods;

  const { mutate: profileUpdate, isLoading } = useProfileUpdate({
    onSuccess: () => {
      const values = getValues();
      setUser({ ...user, ...values });
      reset(values);
      toast.success("Dados atualizados com sucesso.");
    },
  });

  const onSubmit = (user: MyAccountScheme) => {
    const data = {
      ...user,
      email: user.email?.toLowerCase(),
    };

    profileUpdate(data);
  };

  return (
    <>
      <Box
        mb={6}
        p={6}
        bg="white"
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text color="primary.500" fontWeight="bold">
          Informações de conta
        </Text>

        <Divider my={4} />

        <SimpleGrid columns={[1, 1, 2]} gap={4}>
          <Input label="Nome" error={errors.name} {...register("name")} />
          <Input label="E-mail" error={errors.email} {...register("email")} />
        </SimpleGrid>
        {isOwner ? (
          <SimpleGrid columns={[1, 1, 2]} gap={4} mt={4}>
            <Input
              label="Telefone"
              name="phone"
              value={formatPhone(user.phoneNumber)}
              isDisabled
            />
          </SimpleGrid>
        ) : null}

        {isDirty ? (
          <Flex justifyContent="flex-end">
            <Button size="md" type="submit" isLoading={isLoading}>
              Salvar
            </Button>
          </Flex>
        ) : null}

        <Text color="primary.500" fontWeight="bold" mt={6}>
          Segurança da conta
        </Text>

        <Divider my={4} />

        <Button ml={-3} w="100%" size="sm" variant="ghost">
          <Box
            textAlign="left"
            as="span"
            flex={1}
            onClick={() => handleOpen("changePassword")}
          >
            Alterar senha
          </Box>
          <ChevronRight />
        </Button>
      </Box>
      <ChangePasswordModal />
    </>
  );
}

export default withContext(MyAccount, BaseSwipeableDrawerProvider);
