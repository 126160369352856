import { ComponentStyleConfig } from "@chakra-ui/react";

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    _focus: {
      boxShadow: "none",
    },
  },
  defaultProps: {
    colorScheme: "primary",
  },
};
