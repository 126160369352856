import React, { FC } from "react";

import { Box } from "@material-ui/core";

import Typography from "src/components/Typography";
import { useModule } from "src/hooks/module/useModule";
import { TagType } from "src/interfaces/tag";

interface LabelWithTags {
  label: string;
  tags: Array<TagType>;
}

const LabelWithTags: FC<LabelWithTags> = ({ label, tags }) => {
  const { hasModules } = useModule();
  return (
    <div>
      <Typography style={{ fontSize: "14px" }}>{label}</Typography>
      <Typography
        color="textSecondary"
        style={{ marginTop: "4px", fontSize: "8px" }}
      >
        {hasModules("contactTags")
          ? tags.map((tag: TagType) => {
              return (
                <Box
                  component="span"
                  bgcolor="#ACE2F6"
                  color="#074EE8"
                  borderRadius="9999px"
                  marginRight={0.5}
                  paddingX={1}
                  paddingY={0.5}
                  key={tag.id}
                >
                  {tag.label}
                </Box>
              );
            })
          : null}
      </Typography>
    </div>
  );
};

export default LabelWithTags;
