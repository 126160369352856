import { STATUS } from "src/constants/requestStatus";
import { RequestStatusType } from "src/interfaces/request";

export enum StatusOrderActionEnum {
  LIST = "LIST",
  UPDATE = "UPDATE",
  CREATE = "CREATE",
  DELETE = "DELETE",
  DETAIL = "DETAIL",
}

interface StatusOrderAction {
  type: StatusOrderActionEnum;
  payload: RequestStatusType;
}

interface StatusOrderState {
  listStatus: RequestStatusType;
  createStatus: RequestStatusType;
  updateStatus: RequestStatusType;
  deleteStatus: RequestStatusType;
  detailStatus: RequestStatusType;
}

export function statusOrderReducer(
  state: StatusOrderState,
  action: StatusOrderAction
) {
  const { type, payload } = action;
  switch (type) {
    case StatusOrderActionEnum.LIST:
      return {
        ...state,
        listStatus: payload,
        updateStatus: STATUS.inital,
        deleteStatus: STATUS.inital,
        createStatus: STATUS.inital,
      };
    case StatusOrderActionEnum.CREATE:
      return {
        ...state,
        createStatus: payload,
      };
    case StatusOrderActionEnum.UPDATE:
      return {
        ...state,
        updateStatus: payload,
      };
    case StatusOrderActionEnum.DELETE:
      return {
        ...state,
        deleteStatus: payload,
      };
    case StatusOrderActionEnum.DETAIL:
      return {
        ...state,
        detailStatus: payload,
      };
    default:
      return state;
  }
}
