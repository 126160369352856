import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Flex, Spinner, Text, Wrap } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { CurrencyInput } from "src/components/NewComponents/CurrencyInput";
import { useCompany } from "src/hooks/company/useCompany";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { useCashFlowOpened } from "src/services/hooks/cashFlow/useCashFlowOpened";
import { useCashFlowResume } from "src/services/hooks/cashFlow/useCashFlowResume";
import { useCloseCashFlow } from "src/services/hooks/cashFlow/useCloseCashFlow";
import { useActiveFinancialAccountsWithBalance } from "src/services/hooks/financialAccount/useActiveFinancialAccountsWithBalance";
import { formatPrice } from "src/utils/format";

import { schemaCloseCashFlowForm } from "./schema";
import { usePrintResumeCashFlow } from "./usePrintResumeCashFlow";

type CloseCashFlowSchema = yup.InferType<typeof schemaCloseCashFlowForm>;

function CloseCashFlowModal() {
  const { handleClose } = useBaseSwipeableDrawer();
  const queryClient = useQueryClient();
  const { userCompany } = useCompany();
  const { user } = useAuth();

  const { mutate: closeCashFlow, isLoading } = useCloseCashFlow({
    onSuccess: () => {
      handlePrint();
      toast.success("Caixa fechado com sucesso!");
      handleCustomClose();
    },
  });

  const {
    data: accounts,
    refetch: refetchActiveFiancialAccounts,
    isFetching: isLoadingActiveFinancialAccountsWithBalance,
  } = useActiveFinancialAccountsWithBalance(undefined, {
    enabled: false,
    cacheTime: 0,
    onSuccess() {
      refetchCashFlowResume();
    },
  });

  const {
    data: cashFlowResume,
    refetch: refetchCashFlowResume,
    isFetching: isLoadingCashFlowResume,
  } = useCashFlowResume({
    enabled: false,
    onSuccess() {
      refetchCashFlowOpened();
    },
  });

  const {
    refetch: refetchCashFlowOpened,

    isFetching: isLoadingCashFlowOpened,
  } = useCashFlowOpened({
    enabled: false,
    onSuccess(cashFlow) {
      const financialAccount = accounts?.find(
        account =>
          account.id === (cashFlow?.financialAccount as unknown as string)
      );

      reset({
        ...defaultValues,
        cashFlow: cashFlow?.id,
        actualMoney: financialAccount?.balance ?? 0,
        closeMoney: financialAccount?.balance ?? 0,
        initMoney: cashFlowResume?.initMoney ?? 0,
      });
    },
  });

  useEffect(() => {
    refetchActiveFiancialAccounts();
  }, []);

  const defaultValues: CloseCashFlowSchema = {
    initMoney: "" as unknown as number,
    actualMoney: "" as unknown as number,
    closeMoney: "" as unknown as number,
    cashFlow: "",
    note: "",
  };

  const { handleSubmit, reset, control } = useForm<CloseCashFlowSchema>({
    defaultValues,
    resolver: yupResolver(schemaCloseCashFlowForm),
  });

  const closeMoney = useWatch({ name: "closeMoney", control });
  const actualMoney = useWatch({ name: "actualMoney", control });

  const { PrintResumeCashFlowComponent, handlePrint } = usePrintResumeCashFlow({
    cashFlowResume,
    paymentMethods: userCompany.paymentMethods,
    operatorName: user.name,
    cashflowClosedMoney: Number(closeMoney ?? 0),
    onAfterPrint: () => {
      queryClient.invalidateQueries(["cashflowOpened", user.id]);
    },
  });

  const handleCustomClose = () => {
    reset(defaultValues);
    handleClose("closeCashFlowModal");
  };

  const onSubmit = ({ closeMoney, cashFlow, note }: CloseCashFlowSchema) => {
    closeCashFlow({
      cashFlow,
      closeMoney: Number(closeMoney),
      note,
    });
  };

  return (
    <BaseSwipeableDrawer
      tag="closeCashFlowModal"
      title="Fechar caixa"
      buttonTitle="Fechar caixa"
      buttonProps={{
        loading: isLoading,
        disabled:
          isLoading ||
          isLoadingCashFlowOpened ||
          isLoadingActiveFinancialAccountsWithBalance ||
          isLoadingCashFlowResume,
        form: "closeCashFlowForm",
      }}
      customHandleClose={handleCustomClose}
    >
      <Flex
        w={["100%", "400px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="closeCashFlowForm"
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
        direction="column"
      >
        <Box>
          <Text fontSize="sm" fontWeight={700} color="primary.500" mb={2}>
            Resumo do caixa:
          </Text>
          <Wrap>
            <Text flex={1} fontSize="xs" color="text">
              Fundo de caixa
            </Text>
            <Text fontSize="xs" color="primary.500">
              {formatPrice(cashFlowResume?.initMoney ?? 0)}
            </Text>
          </Wrap>
          <Wrap>
            <Text flex={1} fontSize="xs" color="text">
              Entrada espécie
            </Text>
            <Text fontSize="xs" color="primary.500">
              {formatPrice(cashFlowResume?.incomesMoney ?? 0)}
            </Text>
          </Wrap>
          <Wrap>
            <Text flex={1} fontSize="xs" color="text">
              Sangria/Despesas
            </Text>
            <Text fontSize="xs" color="error.500">
              {formatPrice(cashFlowResume?.expensesMoney ?? 0)}
            </Text>
          </Wrap>
        </Box>
        <Text fontSize="sm" color="gray.300">
          Dados do caixa
        </Text>
        {isLoading ||
        isLoadingCashFlowOpened ||
        isLoadingActiveFinancialAccountsWithBalance ||
        isLoadingCashFlowResume ? (
          <Flex justifyContent="center">
            <Spinner color="primary.500" />
          </Flex>
        ) : (
          <>
            <CurrencyInput
              label="Saldo em caixa"
              control={control}
              name="actualMoney"
              isDisabled
            />
            <CurrencyInput
              label="Saldo de fechamento"
              control={control}
              name="closeMoney"
            />
          </>
        )}

        {!isLoadingCashFlowOpened &&
        Number(actualMoney) !== Number(closeMoney) ? (
          <Box>
            <Text fontSize="md" color="error.500" mb={2}>
              Atenção:
            </Text>
            <Text fontSize="sm" fontWeight={400} textAlign="justify">
              O valor digitado para fechamento de caixa é divergente do saldo da
              conta. Verifique ou refaça a contagem. Um alerta será fixado nessa
              operação para análise posterior.
            </Text>
          </Box>
        ) : null}
      </Flex>
      {PrintResumeCashFlowComponent}
    </BaseSwipeableDrawer>
  );
}

export default CloseCashFlowModal;
