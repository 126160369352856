import styled from "styled-components";

import Dialog from "@material-ui/core/Dialog";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIconUnstyled from "@material-ui/icons/CloudUpload";

import Button from "src/components/Button";

export const UploadImageButton = styled.button`
  width: 100%;
  height: 150px;
  background-color: #e4f1fa;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px dashed ${({ theme }) => theme.palette.primary.dark};
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const PhotoIcon = styled(AddPhotoAlternateIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 50px;
`;

export const UploadIcon = styled(CloudUploadIconUnstyled)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 40px;
`;

export const CloseButton = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export const LeftSpace = styled.div`
  width: 30px;
`;

export const AddPictureButton = styled(Button)`
  svg {
    margin-right: 10px;
  }
`;

export const Modal = styled(Dialog)`
  .MuiDialog-paper {
    overflow: hidden;
    padding: 20px 30px;
  }
`;
