import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Flex, InputGroup, InputRightElement } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import VisibilityButton from "src/components/CommonButtons/VisibilityButton";
import { Input } from "src/components/NewComponents/Input";
import PasswordStrengthMeter from "src/components/PasswordStrengthMeter";
import {
  ChangePasswordType,
  useChangePassword,
} from "src/services/hooks/auth/useChangePassword";

import { schemaChangePasswordForm } from "./schema";

export type ChangePasswordScheme = ChangePasswordType & {
  confirmPassword: string;
};

function ChangePasswordModal() {
  const { handleClose } = useBaseSwipeableDrawer();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const defaultValues: ChangePasswordScheme = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const methods = useForm<ChangePasswordScheme>({
    defaultValues,
    resolver: yupResolver(schemaChangePasswordForm),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = methods;

  const newPassword = watch("newPassword");
  const confirmPassword = watch("confirmPassword");

  const handleCustomClose = () => {
    reset(defaultValues);
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    handleClose("changePassword");
  };

  const { mutate: changePassword, isLoading } = useChangePassword({
    onSuccess: () => {
      toast.success("Senha alterada com sucesso.");
      handleCustomClose();
    },
  });

  const onSubmit = (values: ChangePasswordScheme) => {
    changePassword({
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    });
  };

  return (
    <BaseSwipeableDrawer
      tag="changePassword"
      title="Alterar senha"
      buttonTitle="Alterar senha"
      buttonProps={{
        loading: isLoading,
        disabled: isLoading,
        form: "changePasswordForm",
      }}
      customHandleClose={handleCustomClose}
    >
      <Flex
        w={["100%", "350px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="changePasswordForm"
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
        direction="column"
      >
        <InputGroup>
          <Input
            label="Senha atual"
            error={errors.oldPassword}
            type={showOldPassword ? "text" : "password"}
            {...register("oldPassword")}
          />
          <InputRightElement
            right="1px"
            top="1px"
            height="calc(var(--chakra-sizes-12) - 2px)"
            w="auto"
            px={4}
          >
            <VisibilityButton
              visible={!showOldPassword}
              onClick={() => setShowOldPassword(prev => !prev)}
            />
          </InputRightElement>
        </InputGroup>
        <InputGroup>
          <Input
            label="Nova senha"
            error={errors.newPassword}
            type={showNewPassword ? "text" : "password"}
            {...register("newPassword")}
          />
          <InputRightElement
            right="1px"
            top="1px"
            height="calc(var(--chakra-sizes-12) - 2px)"
            w="auto"
            px={4}
          >
            <VisibilityButton
              visible={!showNewPassword}
              onClick={() => setShowNewPassword(prev => !prev)}
            />
          </InputRightElement>
        </InputGroup>
        <InputGroup>
          <Input
            type={showConfirmPassword ? "text" : "password"}
            label="Confirmar nova senha"
            error={errors.confirmPassword}
            {...register("confirmPassword")}
          />
          <InputRightElement
            right="1px"
            top="1px"
            height="calc(var(--chakra-sizes-12) - 2px)"
            w="auto"
            px={4}
          >
            <VisibilityButton
              visible={!showConfirmPassword}
              onClick={() => setShowConfirmPassword(prev => !prev)}
            />
          </InputRightElement>
        </InputGroup>

        <PasswordStrengthMeter
          password={newPassword}
          confirmationPassword={confirmPassword}
        />
      </Flex>
    </BaseSwipeableDrawer>
  );
}

export default ChangePasswordModal;
