import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { OrderType } from "src/interfaces/order";
import api from "src/services/api";

export interface UpdateQuickOrderParams {
  id: string;
  deadline?: string | null;
  additionalInfo?: string | null;
  operator?: string | null;
}

export const updateQuickOrder = (params: UpdateQuickOrderParams) =>
  api.patch<OrderType>(`v1/order`, params).then(res => res.data);

export function useUpdateQuickOrder(
  config?: UseMutationOptions<any, any, UpdateQuickOrderParams, unknown>
) {
  return useMutation<any, any, UpdateQuickOrderParams>(
    (data: UpdateQuickOrderParams) => updateQuickOrder(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao editar venda"
        );
      },
      ...config,
    }
  );
}
