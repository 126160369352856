import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import CloseButton from "src/components/CommonButtons/CloseButton";
import { Transition } from "src/components/Modal";
import { useSchedulingDelete } from "src/hooks/scheduling/delete/useSchedulingDelete";

import { SchedulingDeleteModalProps } from "./props";
import * as Styled from "./styles";

const SchedulingDeleteModal: React.FC<SchedulingDeleteModalProps> = props => {
  const { id, open, handleClose } = props;

  const { remove } = useSchedulingDelete();

  const deleteService = async () => {
    remove(id);
    handleClose();
  };

  return (
    <Styled.Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <Box p={3}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <div />
              <Styled.AlertIcon alignmentBaseline="middle" />
              <CloseButton onClick={handleClose} />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Styled.InfoText align="center" variant="h6">
              Tem certeza que deseja excluir este compromisso?
            </Styled.InfoText>
          </Grid>
          <Grid item xs={12}>
            <Styled.DeleteButton
              variant="contained"
              onClick={deleteService}
              fullWidth
              size="large"
              // loading={status.loading}
              // disabled={status.loading}
            >
              Excluir
            </Styled.DeleteButton>
          </Grid>
        </Grid>
      </Box>
    </Styled.Dialog>
  );
};

export default SchedulingDeleteModal;
