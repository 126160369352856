import React, { useState, useEffect } from "react";

import { Box, HStack, Text, Button, Flex } from "@chakra-ui/react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";

import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { Select } from "src/components/NewComponents/Select";
import { useCompany } from "src/hooks/company/useCompany";
import { ContactProvider, useContact } from "src/hooks/contact/useContact";
import { useModule } from "src/hooks/module/useModule";
import { useOrder } from "src/hooks/order/useOrder";
import { ContactType, SubContactType } from "src/interfaces/contact";
import ContactManagerModal from "src/pages/Contacts/components/ContactManagerModal";
import AddSubContactModal from "src/pages/Contacts/Detail/components/AddSubContactModal";
import { debounce } from "src/utils/debounce";
import { withContext } from "src/utils/withContext";

import * as Styled from "./styles";

const LIMIT = 10;

const CustomerSection: React.FC = () => {
  const [options, setOptions] = useState<ContactType[]>([]);
  const [method, setMehtod] = useState<"add" | "update">("add");

  const { subContactNameDefinitions } = useCompany();
  const { handleOpen: handleOpenBaseSwipeableDrawer } =
    useBaseSwipeableDrawer();

  const { hasModules } = useModule();

  const {
    list,
    contacts,
    status,
    getSubContacts,
    subContacts,
    setSubContacts,
  } = useContact();

  const {
    order,
    detailStatus,
    customer,
    setCustomer,
    subContact,
    setSubContact,
  } = useOrder();

  useEffect(() => {
    if (
      detailStatus.success &&
      order?.contact?.id &&
      hasModules("subcustomers")
    ) {
      getSubContacts({
        contact: order?.contact?.id,
      });
    }
  }, []);

  useEffect(() => {
    setOptions(contacts.contacts || [customer].filter(item => item?.id));
  }, [contacts]);

  const searchQuery = debounce((query: string) => {
    list({
      search: query.length ? query : undefined,
      type: "customer",
      limit: LIMIT,
    });
  }, 500);

  return (
    <Box w="100%">
      <Text color="primary.500" mb={2.5}>
        Cliente
      </Text>
      <HStack align="flex-start" w="100%">
        <Box w="100%">
          <Select
            value={customer?.id}
            options={options.map(customer => ({
              label: customer.name,
              value: customer.id,
              ...customer,
            }))}
            isLoading={status.loading}
            onInputChange={(inputValue, event) => {
              if (
                event.action !== "input-blur" &&
                event.action !== "menu-close" &&
                event.action !== "set-value"
              ) {
                searchQuery(inputValue);
              }
            }}
            onMenuOpen={() => {
              if (!options?.length) {
                searchQuery("");
              }
            }}
            onChange={newInputValue => {
              const contact = options.find(
                customer => customer.id === newInputValue
              );

              setCustomer(contact as ContactType);
              setSubContact({} as SubContactType);
              setSubContacts([]);
              if (contact && hasModules("subcustomers")) {
                getSubContacts({
                  contact: contact.id,
                });
              }
            }}
            placeholder="Procurar cliente..."
            showFloatingLabel={false}
          />
          {hasModules("subcustomers") ? (
            <Flex flexWrap="wrap" columnGap={2} rowGap={1} mt={2}>
              {subContacts.map(item => {
                const isSelected = item.id === subContact?.id;

                let label = "-";

                if (item.data.length) {
                  label = `${item.data[0].content}${
                    item.data.length > 1 ? ` - ${item.data[1].content}` : ""
                  }`;
                }

                const bg = isSelected ? "#ACE2F6" : "#D6D8DC";

                return (
                  <Button
                    key={item.id}
                    borderRadius="full"
                    variant="solid"
                    bg={bg}
                    _hover={{
                      bg,
                    }}
                    _focus={{
                      bg,
                    }}
                    _active={{
                      bg,
                    }}
                    color={isSelected ? "#074EE8" : "#5B5C60"}
                    size="sm"
                    onClick={() => {
                      if (isSelected) {
                        setSubContact({} as SubContactType);
                      } else {
                        setSubContact(item);
                      }
                    }}
                    rightIcon={
                      isSelected ? (
                        <CheckCircleIcon
                          style={{ color: "#074EE8" }}
                          fontSize="small"
                        />
                      ) : undefined
                    }
                  >
                    {label}
                  </Button>
                );
              })}
              {!!customer?.id && (
                <>
                  <Button
                    onClick={() =>
                      handleOpenBaseSwipeableDrawer("createSubContact")
                    }
                    color="primary.500"
                    variant="ghost"
                    size="sm"
                    borderRadius="full"
                  >
                    Adicionar {subContactNameDefinitions.title.toLowerCase()}
                    <Styled.AddCircleIcon color="primary" />
                  </Button>
                  <AddSubContactModal
                    contactId={customer.id}
                    setSubContactCreated={subcontact => {
                      setSubContact(subcontact);
                      setSubContacts(prev => [...prev, subcontact]);
                    }}
                  />
                </>
              )}
            </Flex>
          ) : null}
        </Box>

        {customer?.id ? (
          <Button
            onClick={() => {
              setMehtod("update");
              handleOpenBaseSwipeableDrawer("createContactModal");
            }}
            leftIcon={<EditIcon />}
            minWidth={150}
          >
            Editar
          </Button>
        ) : null}
        <Button
          onClick={() => handleOpenBaseSwipeableDrawer("createContactModal")}
          leftIcon={<AddCircleIcon />}
          minWidth={150}
        >
          Cliente
        </Button>
      </HStack>
      <ContactManagerModal
        method={method}
        contact={customer}
        setContact={customer => {
          setCustomer(customer);

          if (method === "add") {
            setSubContact({} as SubContactType);
            setSubContacts([]);
            setOptions(options => [...options, customer]);
          } else {
            setOptions(options =>
              options.map(option =>
                option.id === customer.id ? customer : option
              )
            );
          }
        }}
        contactType="customer"
      />
    </Box>
  );
};

export default withContext(
  CustomerSection,
  ContactProvider,
  BaseSwipeableDrawerProvider
);
