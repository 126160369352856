import React, { useEffect } from "react";

import { Box, Grid } from "@material-ui/core";

import { useDashboard } from "src/hooks/dashboard/useDashboard";
import { DashboardInvervalEnum } from "src/interfaces/dashboard";

import FinancialData from "../components/FinancialData";
import SalesChart from "../components/SalesChart";
import TicketChart from "../components/TicketChart";

const Sales: React.FC<{ interval: keyof typeof DashboardInvervalEnum }> = ({
  interval,
}) => {
  const { getDashboardSales } = useDashboard();

  useEffect(() => {
    getDashboardSales(interval);
  }, [interval]);

  return (
    <>
      <FinancialData />
      <Box marginBottom={2} />
      <Grid container spacing={2}>
        <SalesChart interval={interval} />
        <TicketChart interval={interval} />
      </Grid>
    </>
  );
};

export default Sales;
