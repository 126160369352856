import styled from "styled-components";

import Typography from "src/components/Typography";

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  font-size: 15px;
  margin-right: 20px;
`;
