import {
  Box,
  Button,
  Circle,
  Flex,
  forwardRef,
  Icon,
  Text,
  useTab,
} from "@chakra-ui/react";
import { Check } from "@material-ui/icons";

export const CustomTab = forwardRef((props, ref) => {
  const { index, availableSteps, label, tabsCount, disable, ...tabPropsRest } =
    props;

  const tabProps = useTab({ ...tabPropsRest, ref });

  const isAvailable = availableSteps.includes(index);
  const isDisabled = !isAvailable || disable;
  const isSelected = !!tabProps["aria-selected"];
  const isFinished = isAvailable && !isSelected;

  return (
    <Flex
      opacity={isDisabled ? 0.5 : 1}
      w="min-content"
      align="center"
      justifyContent="space-between"
    >
      <Button
        {...tabProps}
        h="32px"
        px={0}
        flexShrink={0}
        disabled={!isAvailable || isDisabled}
        fontSize="10px"
        variant="unstyled"
        display="flex"
        _disabled={{
          cursor: "not-allowed",
          boxShadow: "var(--chakra-shadows-none)",
        }}
        opacity={isFinished ? 0.8 : 1}
      >
        {isFinished ? (
          <Circle size="32px" bg="primary.500" mr={1.5}>
            <Icon as={Check} color="white" />
          </Circle>
        ) : (
          <>
            <Circle
              size="32px"
              bg={isAvailable ? "primary.500" : "transparent"}
              border={
                isAvailable
                  ? "unset"
                  : "solid 2px var(--chakra-colors-primary-500)"
              }
              mr={1.5}
            >
              <Text color={isAvailable ? "white" : "primary.500"} fontSize="sm">
                {index + 1}
              </Text>
            </Circle>
          </>
        )}
        <Text fontSize="sm" color="primary.500">
          {label}
        </Text>
      </Button>
      {tabsCount - 1 !== index ? (
        <Box
          as="hr"
          w="48px"
          borderTopWidth="3px"
          borderTopColor="primary.500"
          borderTopStyle={isAvailable ? "solid" : "dashed"}
          bg="transparent"
          mx="32px"
        />
      ) : null}
    </Flex>
  );
});
