/**
 * Constrói a URL substituindo parâmetros
 */
export const getRouteString = (
  route: string,
  value: string | number,
  param?: string
) => {
  const paramToReplace = param || "id";

  return route.replace(`:${paramToReplace}`, value?.toString());
};
