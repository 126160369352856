import * as yup from "yup";

export const orderPaymentSchema = yup.object({
  paymentMethod: yup.string().when("conditionType", conditionType => {
    if (conditionType !== "Pagar depois") {
      return yup.string().required("Forma de pagamento é obrigatória");
    }
    return yup.string();
  }),

  financialAccount: yup
    .string()
    .when(
      ["hasAccountManagement", "conditionType"],
      (hasAccountManagement, conditionType) => {
        if (hasAccountManagement && conditionType !== "Pagar depois") {
          return yup.string().required("Conta financeira é obrigatória");
        }
        return yup.string();
      }
    ),
  hasAccountManagement: yup.bool(),

  conditionType: yup.string().required("A condição é obrigatória"),
  paymentType: yup.string(),
  downPaymentType: yup.string().when("paymentType", paymentType => {
    if (paymentType === "Parcelado") {
      return yup.string();
    }
    return yup.string().required("Campo é obrigatório");
  }),
  downPaymentValue: yup.string().when("paymentType", paymentType => {
    if (paymentType === "Parcelado") {
      return yup.string();
    }
    return yup.string().required("Campo é obrigatório");
  }),
  remainingPaymentType: yup.string().when("paymentType", paymentType => {
    if (paymentType === "A Vista") {
      return yup.string();
    }
    return yup.string().required("Campo é obrigatório");
  }),
  remainingPaymentValue: yup.string().when("paymentType", paymentType => {
    if (paymentType === "A Vista") {
      return yup.string();
    }
    return yup.string().required("Campo é obrigatório");
  }),

  hasDiscount: yup.bool(),
  discountType: yup.string().when("hasDiscount", hasDiscount => {
    if (hasDiscount) {
      return yup.string().required("Campo é obrigatório");
    }
    return yup.string();
  }),
  discount: yup.string().when("hasDiscount", hasDiscount => {
    if (hasDiscount) {
      return yup.string().required("Campo é obrigatório");
    }
    return yup.string();
  }),
});
