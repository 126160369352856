import { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { UpdatePaymentMethodParamType } from "src/interfaces/payment";
import { updatePaymentMethod } from "src/services/payment";

export const usePaymentMethods = () => {
  const { user, setUser } = useAuth();
  const [status, setStatus] = useState(STATUS.inital);

  const update = useCallback(async (params: UpdatePaymentMethodParamType) => {
    try {
      setStatus(STATUS.loading);

      await updatePaymentMethod(params);

      const paymentMethods = user.company.paymentMethods.map(paymentMethod => ({
        ...paymentMethod,
        status:
          params.companyPaymentMethods.find(
            companyPaymentMethod => companyPaymentMethod.id === paymentMethod.id
          )?.status ?? "disabled",
      }));

      setUser({
        ...user,
        company: {
          ...user.company,
          paymentMethods,
        },
      });

      setStatus(STATUS.success);

      toast.success("Formas de pagamento atualizada com sucesso");
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao atualizar as formas de pagamento");
    }
  }, []);

  return {
    status,
    update,
  };
};
