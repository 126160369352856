import React, { useState, createContext, useContext } from "react";

import { ModalContextType } from "./props";

export const ModalContext = createContext({} as ModalContextType);

export const ModalProvider: React.FC = props => {
  const { children } = props;
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        open,
        handleOpen,
        handleClose,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
};
