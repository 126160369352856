import {
  Control,
  Controller,
  FieldError,
  useController,
} from "react-hook-form";

import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormLabel,
  FormLabelProps,
  Icon,
  Input,
  InputProps as ChakraInputProps,
  InputRightElement,
} from "@chakra-ui/react";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Close from "@material-ui/icons/Close";
import {
  DatePicker as DatePickerUnstyled,
  DateTimePicker as DateTimePickerUnstyled,
  DatePickerProps,
  DateTimePickerProps,
} from "@material-ui/pickers";

interface CurrencyInputProps extends ChakraInputProps {
  format?: string;
  name: string;
  label?: string;
  disablePast?: boolean;
  error?: FieldError | null;
  control: Control<any, any>;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  formErrorMessageProps?: FormErrorMessageProps;
  withHour?: boolean;
  isClearable?: boolean;
}

export function DatePicker({
  format = "dd/MM/yyyy",
  name,
  label,
  control,
  disablePast,
  error = null,
  isDisabled,
  formControlProps = {},
  formLabelProps = {},
  formErrorMessageProps = {},
  withHour = false,
  isClearable = false,
  ...rest
}: CurrencyInputProps) {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl
      isInvalid={!!error}
      isDisabled={isDisabled}
      variant="floating"
      {...formControlProps}
    >
      {isClearable && value ? (
        <InputRightElement
          cursor="pointer"
          right={2}
          top={3}
          onClick={() => onChange(null)}
        >
          <Icon as={Close} color="primary.500" />
        </InputRightElement>
      ) : (
        <InputRightElement
          cursor="pointer"
          right={2}
          top={3}
          pointerEvents="none"
        >
          <Icon as={CalendarToday} color="primary.500" />
        </InputRightElement>
      )}
      <Box w="100%" position="relative">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => {
            const props: DatePickerProps | DateTimePickerProps = {
              clearable: isClearable,
              value: value ?? null,
              onChange: date => {
                onChange(date);
              },
              disablePast,
              animateYearScrolling: true,
              format,
              clearLabel: "limpar",
              cancelLabel: "cancelar",
              inputVariant: "standard",
              DialogProps: {
                style: { zIndex: 1999 },
              },
              inputProps: {
                placeholder: " ",
              },
              TextFieldComponent: ({
                inputProps,
                onClick,
                inputRef,
                value,
              }) => {
                return (
                  <Input
                    {...inputProps}
                    ref={inputRef}
                    name={name}
                    value={String(value)}
                    onClick={onClick}
                    readOnly
                    cursor="pointer"
                    {...rest}
                  />
                );
              },
            };

            if (withHour) {
              return (
                <DateTimePickerUnstyled {...(props as DateTimePickerProps)} />
              );
            }
            return <DatePickerUnstyled {...(props as DatePickerProps)} />;
          }}
        />

        {!!label && (
          <FormLabel htmlFor={name} {...formLabelProps}>
            {label}
          </FormLabel>
        )}
      </Box>
      {!!error && (
        <FormErrorMessage {...formErrorMessageProps}>
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
