import React, { useState, useMemo } from "react";

import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";
import { startOfMonth, endOfMonth, format } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

import Button from "src/components/Button";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Panel from "src/components/Layouts/Panel";
import Loader from "src/components/Loader";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import PATHS from "src/constants/paths";
import { ReportProvider, useReport } from "src/hooks/report/useReport";
import { sumArrayValues } from "src/utils/arrayUtil";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { formatPrice } from "src/utils/format";
import { withContext } from "src/utils/withContext";

import DataCard from "../components/DataCard";
import InformationInitialReportState from "../components/InformationInitialReportState";
import StripedTable from "../components/StripedTable";
import * as Styled from "./styled";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Vendas" },
];

const today = new Date();

const renderValue = (params: GridValueFormatterParams) =>
  formatPrice(params.value as number);

const columns: GridColDef[] = [
  {
    field: "title",
    headerName: "Forma de pagamento",
    flex: 4,
  },
  {
    field: "quantity",
    headerName: "Quantidade",
    flex: 4,
  },
  {
    field: "total",
    headerName: "Valor",
    renderCell: renderValue,
    flex: 4,
  },
];

const SalesReportPage = () => {
  const { getReportPayments, status, reportPayments } = useReport();

  const [startDate, setStartDate] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(endOfMonth(today));

  const [startDateLegend, setStartDateLegend] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDateLegend, setEndDateLegend] = useState<Date | undefined>(
    endOfMonth(today)
  );

  const handleGenerateReport = () => {
    setStartDateLegend(startDate);
    setEndDateLegend(endDate);
    getReportPayments({
      startDate: formatStartDateISO(startDate || today),
      endDate: formatEndDateISO(endDate || today),
    });
  };

  const quantitySales = useMemo(() => {
    if (reportPayments.length > 0) {
      return sumArrayValues(reportPayments.map(item => item.quantity));
    }

    return 0;
  }, [reportPayments]);

  const totalValueSales = useMemo(() => {
    if (reportPayments.length > 0) {
      return sumArrayValues(reportPayments.map(item => item.total));
    }

    return 0;
  }, [reportPayments]);

  const averageTicket = useMemo(
    () => totalValueSales / quantitySales,
    [totalValueSales, quantitySales]
  );

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Vendas" />
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-end"
        width="100%"
        style={{ gap: ".5rem" }}
      >
        <PeriodFilterPicker
          startDate={startDate || today}
          endDate={endDate || today}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          initialFilter="byMonth"
          options={["byDay", "perInterval", "byMonth"]}
          showingInPopover={false}
        />
        <Button
          style={{ height: 56, marginLeft: 8 }}
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleGenerateReport}
        >
          Gerar relatório
        </Button>
      </Box>
      <Box my={3}>
        <Divider />
      </Box>

      {status.initial ? <InformationInitialReportState /> : null}
      {!status.initial && !status.loading ? (
        <>
          <Styled.Legend color="primary" variant="h6">
            De
            <strong>
              {startDateLegend
                ? format(startDateLegend, " dd/MM/yyyy ", {
                    locale: ptBrLocale,
                  })
                : ""}
            </strong>
            até
            <strong>
              {endDateLegend
                ? format(endDateLegend, " dd/MM/yyyy", { locale: ptBrLocale })
                : ""}
            </strong>
          </Styled.Legend>
          <Grid container>
            <Grid item xs={12}>
              <Panel padding={3}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <DataCard
                      title="Total de vendas"
                      value={formatPrice(totalValueSales)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <DataCard title="Qtd vendas" value={quantitySales} />
                  </Grid>

                  <Grid item xs={4}>
                    <DataCard
                      title="Ticket médio"
                      value={formatPrice(averageTicket)}
                    />
                  </Grid>
                </Grid>

                <Box mt={4} mb={1}>
                  <Divider />
                </Box>

                <StripedTable rows={reportPayments} columns={columns} />
              </Panel>
            </Grid>
          </Grid>
        </>
      ) : null}
      {status.loading ? (
        <Box height="100%">
          <Loader size="medium" />
        </Box>
      ) : null}
    </DashboardLayout>
  );
};

export default withContext(SalesReportPage, ReportProvider);
