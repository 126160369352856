import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { FinancialAccountType } from "src/interfaces/financialAccount";
import api from "src/services/api";

export interface PayRecordParams {
  id: string;
  paymentMethodId?: string;
  paidDate: string | null;
  value: number;
  financialAccount?: string;
}

export const payRecord = (params: PayRecordParams) =>
  api
    .post<FinancialAccountType>(`v1/record/${params.id}/pay`, params)
    .then(res => res.data);

export function usePayRecord(
  config?: UseMutationOptions<any, any, PayRecordParams, unknown>
) {
  return useMutation<any, any, PayRecordParams>(
    (data: PayRecordParams) => payRecord(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao pagar o lançamento"
        );
      },
      ...config,
    }
  );
}
