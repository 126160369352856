import { ProductType } from "src/interfaces/product";
import { ServiceType } from "src/interfaces/service";

export const groupProductsAndServices = (
  products: ProductType[],
  services: ServiceType[]
) => {
  return [
    {
      label: "Serviços",
      options: services
        .map(service => ({
          label: service.name,
          value: service.id,
          barCode: null,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      label: "Produtos",
      options: products
        .map(product => ({
          label: product.name,
          value: product.id,
          barCode: product.barCode,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
  ];
};
