import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { format } from "date-fns";

import cashIcon from "src/assets/icons/financialAccounts/cash.svg";
import walletIcon from "src/assets/icons/financialAccounts/wallet.svg";
import { ModalProvider } from "src/components/Modal/useModal";
import Typography from "src/components/Typography";
import { useModule } from "src/hooks/module/useModule";
import { SubCategoryRecordType } from "src/interfaces/categoryRecord";
import { FinancialAccountTypeEnum } from "src/interfaces/financialAccount";
import { RecordTypeType } from "src/interfaces/record";
import { formatPrice } from "src/utils/format";

import RecordCardOptionsMenuProps from "../RecordCardOptionsMenu";
import RecordCardExpense from "./components/RecordCardExpense";
import RecordCardIncome from "./components/RecordCardIncome";
import RecordCardTransfer from "./components/RecordCardTransfer";
import { RecordCardProps } from "./props";
import * as Styled from "./styles";

export const logo = (
  accountType: keyof typeof FinancialAccountTypeEnum,
  logo?: string
) => {
  if (accountType === "wallet") return walletIcon;
  if (accountType === "cash") return cashIcon;

  return logo;
};

const RecordCard: React.FC<RecordCardProps> = props => {
  const { isAccountStatement, ...record } = props;

  const category = record?.category as SubCategoryRecordType;
  const { hasModules } = useModule();

  const emissionDate = useMemo(() => {
    if (record.emissionDate) {
      const date = new Date(record.emissionDate);
      return format(date, "dd/MM/yyyy");
    }
    return "-";
  }, [record.emissionDate]);

  const paidDate = useMemo(() => {
    if (record.paidDate) {
      const date = new Date(record.paidDate);
      return format(date, "dd/MM/yyyy");
    }
    return "-";
  }, [record.paidDate]);

  const payedBadge = useMemo(() => {
    if (isAccountStatement) return null;
    if (record.paidStatus === "paid") {
      return (
        <Styled.PayedBadge paidStatus={record.paidStatus}>
          Pago
        </Styled.PayedBadge>
      );
    }

    return (
      <Styled.PayedBadge paidStatus={record.paidStatus}>
        Não pago
      </Styled.PayedBadge>
    );
  }, [record, isAccountStatement]);

  const renderValue = useMemo(() => {
    if (record?.type && ["transferOut", "expense"].includes(record.type)) {
      return `- ${formatPrice(record.value)}`;
    }
    return formatPrice(record.value);
  }, [record.type, record.value]);

  const renderDescription = useMemo(() => {
    if (record.order) {
      const prefix = record.order.type === "serviceOrder" ? "OS" : "Pedido";
      return `${prefix} ${record.order?.code} ${
        record.order?.contact?.name ? ` - ${record.order?.contact?.name}` : ""
      }`;
    }

    if (record.buyOrder) {
      return `OC ${record.buyOrder?.number} ${
        record.buyOrder?.provider?.name
          ? ` - ${record.buyOrder?.provider?.name}`
          : ""
      }`;
    }

    return record.description ? record.description : "-";
  }, [record]);

  return (
    <Styled.Accordion TransitionProps={{ unmountOnExit: true }} square>
      <Box display="flex" flexDirection="row">
        <Box display="flex" width="95%">
          <Styled.AccordionSummary>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} container spacing={0} alignItems="center">
                <Styled.Dot type={record.type as RecordTypeType} />
                <Box flex={1}>
                  <Typography color="textSecondary">
                    {renderDescription}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Typography color="textSecondary">{paidDate}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="textSecondary">
                  {record?.type &&
                  ["transferOut", "transferIn"].includes(record.type)
                    ? "Transferência entre contas"
                    : category?.name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" flexDirection="row-reverse">
                  <Typography>{renderValue}</Typography>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  flexDirection="row"
                >
                  {payedBadge}
                  <Styled.Button>
                    <ExpandMoreIcon color="primary" />
                  </Styled.Button>
                </Box>
              </Grid>
            </Grid>
          </Styled.AccordionSummary>
        </Box>

        <Box display="flex" width="5%">
          <Box display="flex" alignItems="center" justifyContent="center">
            <ModalProvider>
              <RecordCardOptionsMenuProps data={record} />
            </ModalProvider>
          </Box>
        </Box>
      </Box>

      <Styled.AccordionDetails>
        {record.type === "income" ? (
          <RecordCardIncome
            record={record}
            emissionDate={emissionDate}
            hasAccountManagementModule={hasModules("accountManagement")}
            paidDate={paidDate}
          />
        ) : null}
        {record.type === "expense" ? (
          <RecordCardExpense
            record={record}
            emissionDate={emissionDate}
            hasAccountManagementModule={hasModules("accountManagement")}
            paidDate={paidDate}
          />
        ) : null}
        {record.type === "transferIn" || record.type === "transferOut" ? (
          <RecordCardTransfer
            record={record}
            hasAccountManagementModule={hasModules("accountManagement")}
            paidDate={paidDate}
          />
        ) : null}
      </Styled.AccordionDetails>
    </Styled.Accordion>
  );
};

export default RecordCard;
