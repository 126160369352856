import React, { useCallback } from "react";

import SearchIcon from "@material-ui/icons/Search";

import TextField from "src/components/Inputs/TextField";
import { useProduct } from "src/hooks/product/useProduct";
import { debounce } from "src/utils/debounce";

import * as Styled from "./styles";

const OptionsPanel = () => {
  const { list } = useProduct();

  const searchByName = useCallback(
    debounce((query: string) => {
      if (query.length) {
        list({ name: query });
      } else {
        list({});
      }
    }, 1000),
    []
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    searchByName(e.target.value);
  };

  return (
    <Styled.Container>
      <TextField
        disableFormik
        label="Pesquisar produto"
        name="name"
        icon={<SearchIcon color="primary" />}
        onChange={onChange}
        backgroundwhite
      />
    </Styled.Container>
  );
};

export default OptionsPanel;
