import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@material-ui/core/Box";

import { useOrder } from "src/hooks/order/useOrder";
import { useViewOrder } from "src/hooks/order/useViewOrder";
import { formatPrice } from "src/utils/format";

import { BottomBarProps } from "./props";
import * as Styled from "./styles";

const BottomBar: React.FC<BottomBarProps> = props => {
  const { orderType } = props;
  const navigate = useNavigate();

  const { method, order, createStatus, updateStatus } = useOrder();
  const { total, productsTotal, quantityProducts, servicesTotal, discount } =
    useViewOrder();

  const renderTotalValue = (
    <Box>
      <Styled.LabelTotalValue>
        Valor total
        <Styled.LabelIconEquals />
      </Styled.LabelTotalValue>
      <Styled.ValueData>{formatPrice(total)}</Styled.ValueData>
    </Box>
  );

  const renderResume = useMemo(() => {
    if (orderType === "serviceOrder") {
      return (
        <>
          <Box mr={4}>
            <Styled.LabelData>
              Valor em serviços
              <Styled.LabelIconPlus />
            </Styled.LabelData>
            <Styled.ValueData>{formatPrice(servicesTotal)}</Styled.ValueData>
          </Box>
          <Box mr={4}>
            <Styled.LabelData>
              Valor em produtos
              <Styled.LabelIconPlus />
            </Styled.LabelData>
            <Styled.ValueData>{formatPrice(productsTotal)}</Styled.ValueData>
          </Box>
          <Box pr={4} mr={4} borderRight="solid #D6D8DC 1px">
            <Styled.LabelData>
              Desconto
              <Styled.LabelIconMinus />
            </Styled.LabelData>
            <Styled.ValueData isRed>{formatPrice(discount)}</Styled.ValueData>
          </Box>
          {renderTotalValue}
        </>
      );
    }
    if (orderType === "productOrder") {
      return (
        <>
          <Box mr={4}>
            <Styled.LabelData>Qtd. de produtos</Styled.LabelData>
            <Styled.ValueData>{quantityProducts}</Styled.ValueData>
          </Box>
          <Box mr={4}>
            <Styled.LabelData>
              Valor em produtos
              <Styled.LabelIconPlus />
            </Styled.LabelData>
            <Styled.ValueData>{formatPrice(productsTotal)}</Styled.ValueData>
          </Box>
          <Box pr={4} mr={4} borderRight="solid #D6D8DC 1px">
            <Styled.LabelData>
              Desconto
              <Styled.LabelIconMinus />
            </Styled.LabelData>
            <Styled.ValueData isRed>{formatPrice(discount)}</Styled.ValueData>
          </Box>
          {renderTotalValue}
        </>
      );
    }
    return null;
  }, [orderType, total, productsTotal, quantityProducts, servicesTotal]);

  const renderButtons = useMemo(() => {
    if (method === "post") {
      return (
        <Box display="flex">
          <Box mr={1}>
            <Styled.Button
              color="primary"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Styled.Button>
          </Box>
          <Box>
            <Styled.Button
              color="primary"
              variant="contained"
              type="submit"
              form="form-order"
              disabled={createStatus.loading}
            >
              Criar
            </Styled.Button>
          </Box>
        </Box>
      );
    }

    if (method === "update") {
      return (
        <Box display="flex">
          <Box mr={1}>
            <Styled.Button
              color="primary"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Styled.Button>
          </Box>
          <Box>
            <Styled.Button
              color="primary"
              variant="contained"
              form="form-order"
              type="submit"
              disabled={updateStatus.loading}
            >
              Salvar
            </Styled.Button>
          </Box>
        </Box>
      );
    }

    return null;
  }, [method, order, orderType, updateStatus, createStatus]);

  return (
    <Styled.AppBar position="fixed" component="footer">
      <Styled.Container>
        <Box display="flex">{renderResume}</Box>
        {renderButtons}
      </Styled.Container>
    </Styled.AppBar>
  );
};

export default BottomBar;
