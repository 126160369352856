import api from "src/services/api";

import {
  BuyOrderFormType,
  BuyOrderListParamsType,
  BuyOrderStatuesMetaParamsType,
  UpdateBuyOrderStatusParams,
} from "../interfaces/buyOrder";

/**
 * Listar ordens de compra
 */
export const getBuyOrdersService = (params: BuyOrderListParamsType) =>
  api
    .get(`/v1/buy-order`, {
      params,
    })
    .then(res => res.data);

/**
 * Listar ordens de compra
 */
export const getBuyOrderService = (id: string) =>
  api.get(`/v1/buy-order/${id}`).then(res => res.data);

/**
 * Listar ordens de compra
 */
export const getBuyOrdersStatusesMeta = (
  params: BuyOrderStatuesMetaParamsType
) =>
  api
    .get(`/v1/buy-order/meta`, {
      params,
    })
    .then(res => res.data);

/*
 * Atualizar status de ordem de compra
 */
export const updateBuyOrderStatusService = (
  params: UpdateBuyOrderStatusParams
) => api.put(`v1/buy-order/${params.id}/status`, params).then(res => res.data);

/**
 * Deletar ordem de compra
 */
export const deleteBuyOrdersService = (id: string) =>
  api.delete(`v1/buy-order/${id}`).then(res => res);

/**
 * Cadastrar ordem de compra
 */
export const addBuyOrderService = (params: BuyOrderFormType) =>
  api.post("v1/buy-order", params).then(res => res);

/**
 * Atualizar ordem de compra
 */
export const updateBuyOrderService = (params: BuyOrderFormType) =>
  api.put("v1/buy-order", params).then(res => res.data);

/**
 * Lançar estoque de uma ordem de compra
 */
export const createBuyOrderStockService = (id: string) =>
  api.post(`v1/buy-order/${id}/stock`).then(res => res);

/**
 * Estornar estoque de uma ordem de compra
 */
export const deleteBuyOrderStockService = (id: string) =>
  api.delete(`v1/buy-order/${id}/stock`).then(res => res);

/**
 * Lançar contas de uma ordem de compra
 */
export const createBuyOrderRecordsService = (id: string) =>
  api.post(`v1/buy-order/${id}/records`).then(res => res);

/**
 * Estornar contas de uma ordem de compra
 */
export const deleteBuyOrderRecordsService = (id: string) =>
  api.delete(`v1/buy-order/${id}/records`).then(res => res);
