import React, { FC } from "react";

import Box, { BoxProps } from "@material-ui/core/Box";

import * as Styled from "./styles";

type BottomBarProps = BoxProps;

const BottomBar: FC<BottomBarProps> = props => {
  const { children, ...rest } = props;

  return (
    <Styled.AppBar position="fixed">
      <Styled.Container>
        <Box display="flex" flexDirection="row-reverse" {...rest}>
          {children}
        </Box>
      </Styled.Container>
    </Styled.AppBar>
  );
};

export default BottomBar;
