import React, { FC, useMemo } from "react";

import { Grid } from "@material-ui/core";
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  ReferenceLine,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
} from "recharts";

import Panel from "src/components/Layouts/Panel";
import { useFinancial } from "src/hooks/financial/useFinancial";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const CashFlowChart: FC = () => {
  const { financialOverview } = useFinancial();

  const data = useMemo(() => {
    return (
      financialOverview.accountStatement?.map(model => ({
        ...model,
        totalExpense: -model.totalExpense,
        totalExpenseExpected: -model.totalExpenseExpected,
        values: [
          model.value,
          model.totalIncome,
          model.totalIncomeExpected,
          -model.totalExpense,
          -model.totalExpenseExpected,
        ],
      })) ?? []
    );
  }, [financialOverview.accountStatement]);

  const widthChart = useMemo(() => {
    return data.length * 60;
  }, [data]);

  const longestLabelLength = useMemo(() => {
    return (
      data
        .map(item => formatPrice(item.value))
        .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0) ?? 10
    );
  }, [data]);

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <Styled.TooltipContent>
          <Styled.TooltipText>
            <strong>{label}</strong>
          </Styled.TooltipText>
          <Styled.TooltipText>
            <strong>Saldo em conta:</strong>{" "}
            {formatPrice(payload[0].payload.value)}
          </Styled.TooltipText>
          <Styled.TooltipText>
            <strong>Entradas pagas:</strong>{" "}
            {formatPrice(payload[0].payload.totalIncome)}
          </Styled.TooltipText>
          <Styled.TooltipText>
            <strong>Entradas previstas:</strong>{" "}
            {formatPrice(payload[0].payload.totalIncomeExpected)}
          </Styled.TooltipText>
          <Styled.TooltipText>
            <strong>Saídas pagas:</strong>{" "}
            {formatPrice(payload[0].payload.totalExpense)}
          </Styled.TooltipText>
          <Styled.TooltipText>
            <strong>Saídas previstas:</strong>{" "}
            {formatPrice(payload[0].payload.totalExpenseExpected)}
          </Styled.TooltipText>
        </Styled.TooltipContent>
      );
    }
    return null;
  };

  return (
    <Grid item xs={12}>
      <Panel padding={2} marginBottom={2}>
        <Styled.ResponsiveContainerOverflow>
          <ResponsiveContainer
            minHeight={300}
            maxHeight={300}
            minWidth={widthChart}
            width="100%"
          >
            <ComposedChart data={data}>
              <CartesianGrid
                stroke="#ccc"
                strokeDasharray=".5 .5"
                vertical={false}
              />
              <XAxis
                dataKey="label"
                scale="point"
                padding={{ left: 30, right: 30 }}
                fontSize={10}
                tickLine={false}
                stroke="rgba(19, 34, 62, 0.7)"
              />
              <YAxis
                dataKey="values"
                fontSize={10}
                stroke="rgba(19, 34, 62, 0.7)"
                tickFormatter={formatPrice}
                tickLine={false}
                axisLine={false}
                width={longestLabelLength * 7}
              />
              <Tooltip content={<CustomTooltip />} />
              <ReferenceLine stroke="#000" />
              <Bar dataKey="totalIncome" barSize={40} stackId="income">
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-totalIncome-${index.toString()}`}
                    fill="#1A5DF2"
                  />
                ))}
              </Bar>
              <Bar dataKey="totalIncomeExpected" barSize={40} stackId="income">
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-totalIncomeExpected-${index.toString()}`}
                    fill="#1A5DF280"
                  />
                ))}
              </Bar>
              <Bar dataKey="totalExpense" barSize={40} stackId="expense">
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-totalExpense-${index.toString()}`}
                    fill="#E54848"
                  />
                ))}
              </Bar>
              <Bar
                dataKey="totalExpenseExpected"
                barSize={40}
                stackId="expense"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-totalExpenseExpected-${index.toString()}`}
                    fill="#E5484880"
                  />
                ))}
              </Bar>
              <Line
                type="monotone"
                dataKey="value"
                dot={{
                  stroke: "#fff",
                  fill: "#65AEED",
                  r: 4,
                }}
                stroke="#1E2C47"
                strokeWidth={2}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Styled.ResponsiveContainerOverflow>
      </Panel>
    </Grid>
  );
};

export default CashFlowChart;
