import { TypeOfContact } from "src/interfaces/contact";

interface ContactLabellType {
  name: string;
  add: {
    pageTitle: string;
  };
  detail: {
    pageTitle: string;
  };
  delete: {
    modalText: string;
  };
}

export const CONTACT_TYPE = [
  {
    value: "customer",
    name: "Cliente",
  },
  {
    value: "provider",
    name: "Fornecedor",
  },
  {
    value: "others",
    name: "Outro",
  },
];

export const CONTACT_LABELS: { [key in TypeOfContact]: ContactLabellType } = {
  customer: {
    name: "cliente",
    add: {
      pageTitle: "Criação do cliente",
    },
    detail: {
      pageTitle: "Detalhes do cliente",
    },
    delete: {
      modalText: "Tem certeza que deseja desabilitar esse cliente?",
    },
  },
  provider: {
    name: "fornecedor",
    add: {
      pageTitle: "Criação do fornecedor",
    },
    detail: {
      pageTitle: "Detalhes do fornecedor",
    },
    delete: {
      modalText: "Tem certeza que deseja desabilitar esse fornecedor?",
    },
  },
  others: {
    name: "outros",
    add: {
      pageTitle: "Criação do contato",
    },
    detail: {
      pageTitle: "Detalhes do contato",
    },
    delete: {
      modalText: "Tem certeza que deseja desabilitar esse contato?",
    },
  },
};
