import styled from "styled-components";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import Button from "src/components/Button";

export const BreadActive = styled(Button)`
  background-color: rgba(172, 226, 246, 0.25);
  :hover {
    background-color: #65aeed;
  }
`;

export const Arrow = styled(ArrowForwardIosIcon)`
  color: #d6d8dc;
  font-size: 15px;
`;
