import {
  CreateOrEditFinancialAccountType,
  FinancialAccountParamType,
  FinancialInstitutionParamType,
} from "src/interfaces/financialAccount";
import api from "src/services/api";

/**
 * Cadastrar nova conta
 */
export const addFinancialAccount = (params: CreateOrEditFinancialAccountType) =>
  api.post("v1/financial-account", params).then(res => res.data);

/**
 * Listar contas
 */
export const getFinancialAccounts = (params: FinancialAccountParamType) =>
  api
    .get("v1/financial-account", {
      params,
    })
    .then(res => res.data);

/**
 * Listar contas
 */
export const getFinancialAccountsWithBalance = (
  params: FinancialAccountParamType
) =>
  api
    .get("v1/financial-account-with-balance", {
      params,
    })
    .then(res => res.data);

/**
 * Listar instituições financeiras
 */
export const getFinancialInstitutions = (
  params: FinancialInstitutionParamType
) =>
  api
    .get("v1/financial-institution", {
      params,
    })
    .then(res => res.data);

/**
 * Encontrar conta pelo Id
 */
export const getFinancialAccount = (id: string) =>
  api.get(`v1/financial-account/${id}`).then(res => res.data);

/**
 * Deletar conta
 */
export const deleteFinancialAccount = (id: string) =>
  api.delete(`v1/financial-account/${id}`).then(res => res);

/**
 * Atualizar conta
 */
export const updateFinancialAccount = (
  id: string,
  params: CreateOrEditFinancialAccountType
) => api.put(`v1/financial-account/${id}`, params).then(res => res.data);

/**
 * Atualizar conta
 */
export const updateStatusFinancialAccount = (
  id: string,
  params: { status: string }
) =>
  api.patch(`v1/financial-account/${id}/status`, params).then(res => res.data);
