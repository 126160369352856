import {
  GetReportBestSellersParamType,
  GetReportPaymentParamType,
  GetReportDREParamType,
  GetReportSalesParamType,
  GetReportLateClientsParamType,
  GetReportRecordsParamType,
} from "src/interfaces/report";
import api from "src/services/api";

/**
 * Listagem de registros por tipo de pagamento
 */
export const getReportPaymentService = (params: GetReportPaymentParamType) =>
  api.get("v1/report/payment-type", { params }).then(res => res.data);

/**
 * Relatório gerencial de produtos e serviços mais vendidos
 */
export const getReportBestSellersService = (
  params: GetReportBestSellersParamType
) => api.get("v1/report/best-sellers", { params }).then(res => res.data);

/**
 * Relatório dre
 */
export const getDREService = (params: GetReportDREParamType) =>
  api.get("v1/report/dre", { params }).then(res => res.data);

/**
 * Relatório de vendas
 */
export const getReportSalesService = (params: GetReportSalesParamType) =>
  api.get("v1/report/sales", { params }).then(res => res.data);

/**
 * Relatório clientes atrasados
 */
export const getReportLateClientsService = (
  params: GetReportLateClientsParamType
) => api.get("v1/report/late-customers", { params }).then(res => res.data);

/**
 * Relatório de lançamentos
 */
export const getReportRecordsService = (params: GetReportRecordsParamType) =>
  api.get("v1/report/records", { params }).then(res => res.data);
