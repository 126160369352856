import React from "react";

import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

import { useModal } from "./useModal";

export const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const Modal: React.FC<Partial<DialogProps>> = props => {
  const { children, ...rest } = props;
  const { open, handleClose } = useModal();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      {...rest}
    >
      {children}
    </Dialog>
  );
};

export default Modal;
