import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { GetResponseType } from "src/interfaces/request";
import { UserListParamType, UserType } from "src/interfaces/user";
import api from "src/services/api";

interface GetUserResponseType extends GetResponseType {
  users: UserType[];
}

export const getUsers = (params: UserListParamType) =>
  api
    .get<GetUserResponseType>("v1/company/user", {
      params,
    })
    .then(res => res.data);

export function useUsers(
  params: UserListParamType,
  config?: UseQueryOptions<
    GetUserResponseType,
    Error,
    GetUserResponseType,
    unknown[]
  >
) {
  return useQuery<GetUserResponseType, Error, GetUserResponseType, unknown[]>(
    ["users", params],
    () => getUsers(params),
    {
      staleTime: 1000 * 30, // 30 seconds
      refetchOnWindowFocus: false,
      ...(config || {}),
    }
  );
}
