import React, { useEffect, useMemo, useState } from "react";

import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { endOfMonth, startOfMonth } from "date-fns";

import { BaseModalProvider } from "src/components/BaseModal/useBaseModal";
import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import RenderModule from "src/components/RenderModule";
import Typography from "src/components/Typography";
import { useCompany } from "src/hooks/company/useCompany";
import { DashboardProvider } from "src/hooks/dashboard/useDashboard";
import {
  FinancialAccountProvider,
  useFinancialAccount,
} from "src/hooks/financialAccount/useFinancialAccount";
import { useModule } from "src/hooks/module/useModule";
import { Can } from "src/hooks/permission/usePermission";
import { RecordProvider, useRecord } from "src/hooks/record/useRecord";
import { PaidStatusType } from "src/interfaces/record";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { withContext } from "src/utils/withContext";

import EmptyLaunchTable from "../components/EmptyLaunchTable";
import FilterCard from "../components/FilterCard";
import ChargebackRecordModal from "../components/Modals/ChargebackRecordModal";
import DeleteRecordModal from "../components/Modals/DeleteRecordModal";
import DeleteTransferModal from "../components/Modals/DeleteTransferModal";
import PayRecordModal from "../components/Modals/PayRecordModal";
import RecordFormModal, {
  RecordFormOriginEnum,
} from "../components/Modals/RecordFormModal";
import TransferFormModal, {
  TransferFormOriginEnum,
} from "../components/Modals/TransferFormModal";
import RecordCard from "../components/RecordCard";
import * as Styled from "./styles";

const today = new Date();

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Contas a pagar" },
];

const BillsToPayPage: React.FC = () => {
  const {
    restartRecordList,
    handleRestartRecordList,
    list,
    records: rows,
    record,
    setRecord,
    status,
    createStatus,
    updateStatus,
    deleteStatus,
    payStatus,
    chargebackStatus,
    createTransferStatus,
  } = useRecord();
  const { list: listAccounts } = useFinancialAccount();
  const { hasModules } = useModule();
  const { userCompany } = useCompany();

  const { handleOpen } = useBaseSwipeableDrawer();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);

  const [search, setSearch] = useState<string>("");
  const [paidStatus, setPaidStatus] = useState<string | undefined>(undefined);
  const [category, setCategory] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(endOfMonth(today));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (hasModules("accountManagement")) {
      listAccounts({ status: "enabled" });
    }
  }, [userCompany]);

  useEffect(() => {
    list({
      limit,
      page,
      type: ["expense", "transferOut"],
      ...(search.length && { search }),
      ...(paidStatus && { paidStatus: paidStatus as PaidStatusType }),
      ...(startDate && { startDateFilter: formatStartDateISO(startDate) }),
      ...(endDate && { endDateFilter: formatEndDateISO(endDate) }),
      ...(startDate && endDate && { filterFieldDate: "paidDate" }),
      ...(category && { categories: category }),
    });
  }, [
    limit,
    page,
    search,
    paidStatus,
    category,
    startDate,
    endDate,
    createStatus.success,
    updateStatus.success,
    deleteStatus.success,
    payStatus.success,
    chargebackStatus.success,
    createTransferStatus.success,
    restartRecordList,
  ]);

  const renderRecordCards = useMemo(() => {
    if (status.loading) {
      return <Loader size="medium" />;
    }

    if (status.success && rows.records.length === 0) {
      return <EmptyLaunchTable />;
    }

    return rows.records?.map(record => (
      <RecordCard key={record.id} {...record} />
    ));
  }, [rows, status]);

  return (
    <DashboardLayout selected="financial" subSelected="bills-to-pay">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />

      <PageTitle label="Contas a pagar">
        <Can action="create" subject="record">
          <>
            <Box display="flex">
              <RenderModule modules={["accountManagement"]}>
                <Box mr={2}>
                  <Styled.Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setRecord(undefined);
                      handleOpen("transferForm");
                    }}
                  >
                    <AddCircleIcon />
                    <Typography variant="body2">Nova transferência</Typography>
                  </Styled.Button>
                </Box>
              </RenderModule>
              <Styled.Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setRecord(undefined);
                  handleOpen("recordForm");
                }}
              >
                <AddCircleIcon />
                <Typography variant="body2">Nova despesa</Typography>
              </Styled.Button>
            </Box>
          </>
        </Can>
      </PageTitle>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FilterCard
            type="expense"
            setSearch={setSearch}
            paidStatus={paidStatus}
            setPaidStatus={setPaidStatus}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            record={record}
            category={category}
            setCategory={setCategory}
          />
        </Grid>
        <Grid item xs={12}>
          {renderRecordCards}
          <Box mt={2}>
            <TablePagination
              component="div"
              color="primary"
              count={rows.totalCount}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Lançamentos por página"
            />
          </Box>
        </Grid>
      </Grid>
      <ChargebackRecordModal
        record={record}
        onSuccess={recordResponse => {
          setRecord(recordResponse);
          handleRestartRecordList();
        }}
      />
      <PayRecordModal
        record={record}
        onSuccess={recordResponse => {
          setRecord(recordResponse);
          handleRestartRecordList();
        }}
      />
      <RecordFormModal
        handleCloseRecordFormModal={() => {
          setRecord(undefined);
        }}
        onSuccess={() => {
          handleRestartRecordList();
        }}
        record={record}
        recordFormOrigin={RecordFormOriginEnum.expense}
        recordType="expense"
      />
      <DeleteRecordModal
        record={record}
        onSuccess={() => {
          setRecord(undefined);
          handleRestartRecordList();
        }}
      />
      <TransferFormModal
        onSuccess={() => {
          handleRestartRecordList();
        }}
        handleCloseTransferFormModal={() => {
          setRecord(undefined);
        }}
        record={record}
        transferFormOrigin={TransferFormOriginEnum.expense}
      />
      <DeleteTransferModal
        record={record}
        onSuccess={() => {
          setRecord(undefined);
          handleRestartRecordList();
        }}
      />
    </DashboardLayout>
  );
};

export default withContext(
  BillsToPayPage,
  RecordProvider,
  FinancialAccountProvider,
  BaseModalProvider,
  DashboardProvider,
  BaseSwipeableDrawerProvider
);
