import styled from "styled-components";

export const MockupWrapper = styled.div`
  grid-area: mockup;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Mockup = styled.img`
  width: 250px;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: unset;
    max-width: 400px;
    margin-top: -50px;
  }
`;

export const LinksGroup = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: flex;
    justify-content: center;
    column-gap: 16px;
  }
`;

export const LinkImage = styled.img`
  width: 120px;
  height: 36px;
`;
