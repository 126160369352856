import React, { useCallback, useMemo } from "react";

import { Box, Wrap } from "@chakra-ui/react";

import { Input } from "src/components/NewComponents/Input";
import { Select } from "src/components/NewComponents/Select";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import { RECORD_STATUS } from "src/constants/record";
import { GroupOption } from "src/interfaces/common";
import { PaymentMethodType } from "src/interfaces/payment";
import { PaidStatusType, RecordType } from "src/interfaces/record";
import { useRecordCategories } from "src/services/hooks/record/useRecordCategories";
import { debounce } from "src/utils/debounce";

interface IFilterCard {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  paidStatus?: string;
  setPaidStatus: React.Dispatch<React.SetStateAction<string | undefined>>;
  startDate?: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  endDate?: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  paymentMethods?: PaymentMethodType[];
  payMethods?: string;
  setPayMethods?: React.Dispatch<React.SetStateAction<string | undefined>>;
  record: RecordType | undefined;
  type: "expense" | "income";
  category?: string;
  setCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function FilterCard({
  setSearch,
  paidStatus,
  setPaidStatus,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  type,
  paymentMethods,
  payMethods,
  setPayMethods,
  record,
  category,
  setCategory,
}: IFilterCard) {
  const { data: categories } = useRecordCategories({
    type: (record?.type as "expense" | "income") || type,
  });
  const categoriesGrouped = useMemo(() => {
    return categories.reduce<GroupOption[]>((group, categorie) => {
      const label = categorie.name;

      return [
        ...group,
        {
          label,
          options: categorie.subCategories.map(subCategory => ({
            label: subCategory.name,
            value: subCategory.id,
          })),
        },
      ];
    }, []);
  }, [categories]);

  const paymentMethodsItems = useMemo(() => {
    return (
      paymentMethods
        ?.filter(item => item.status === "enabled")
        .map(method => ({
          label: method.title,
          value: method.id,
        })) ?? []
    );
  }, [paymentMethods]);

  const paidStatusItems = useMemo(() => {
    const items = Object.keys(RECORD_STATUS).map(status => ({
      label: RECORD_STATUS[status as PaidStatusType].name,
      value: status,
    }));

    const parsedItems = items.filter(item => item.label !== "Cancelado");

    return parsedItems;
  }, [RECORD_STATUS]);

  const searchByQuery = useCallback(
    debounce((query: string) => {
      setSearch(query);
    }, 1000),
    []
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    searchByQuery(e.target.value);
  };

  return (
    <Wrap gap={2}>
      <Box flex={1} minW="140px">
        <Input
          name="receita"
          onChange={onChange}
          label="Pesquisar por receita"
          bg="white"
        />
      </Box>
      {type === "income" && (
        <Box w="300px">
          <Select
            value={payMethods}
            options={paymentMethodsItems}
            onChange={e => setPayMethods && setPayMethods(e as string)}
            placeholder="Forma de pagamento"
            isMulti
          />
        </Box>
      )}
      <Box w="200px">
        <Select
          value={paidStatus}
          options={paidStatusItems}
          onChange={e => setPaidStatus(e as string)}
          placeholder="Situação"
        />
      </Box>

      <Box w="200px">
        <Select
          value={category}
          options={categoriesGrouped}
          onChange={e => setCategory(e as string)}
          placeholder="Categoria"
          isMulti
        />
      </Box>
      <Box>
        <PeriodFilterPicker
          startDate={startDate || new Date()}
          endDate={endDate || new Date()}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          initialFilter="byMonth"
          options={["byDay", "byMonth", "perInterval"]}
        />
      </Box>
    </Wrap>
  );
}
