import * as yup from "yup";

export const schemaTransferForm = yup.object({
  id: yup.string(),
  title: yup.string().required("Título é obrigatório"),
  value: yup
    .number()
    .typeError("Valor é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
  date: yup.string().required("Data é obrigatória"),
  fromAccount: yup.string().required("Conta de saída é obrigatória"),
  toAccount: yup.string().required("Conta de entrada é obrigatória"),
  description: yup.string(),
});
