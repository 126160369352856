import { GridValueFormatterParams } from "@material-ui/data-grid";
import { format } from "date-fns-tz";

import {
  COLOR_PAYMENT_STATUS,
  NAME_PAYMENT_STATUS,
} from "src/constants/orders";
import { PaymentStatusNameType, PaymentStatusType } from "src/interfaces/order";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

export const renderCurrencyValue = (params: GridValueFormatterParams) =>
  formatPrice(params.value as number);

export const renderDate = (params: GridValueFormatterParams) => {
  if (params.value) {
    return format(new Date(params.value as string), "dd/MM/yyyy");
  }

  return "-";
};

export const renderPaymentStatus = (params: GridValueFormatterParams) => {
  const isPaid = String(
    params.value as PaymentStatusType
  ) as PaymentStatusNameType;

  return (
    <Styled.ChipOrder
      label={NAME_PAYMENT_STATUS[isPaid]}
      background={COLOR_PAYMENT_STATUS[isPaid]}
    />
  );
};
