/**
 * formata o numero de acordo com o locale passado
 */
export const formatNumber = (
  value: any,
  locale = "pt-br",
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
): string => {
  const valueParsed = value ? Number(value) : 0;

  return new Intl.NumberFormat(locale, {
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(valueParsed);
};

/**
 * formata o preço no valor do Real
 */
export const formatPrice = (price: any): string => {
  const priceValue = price ? Number(price) : 0;

  return priceValue.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
};

/**
 * formatar telefone. e.x.: (88) 12345-6789
 */
export const formatPhone = (phone: any) => {
  const phoneString = String(phone);

  if (phoneString.length > 12) {
    return phoneString.replace(
      /^([0-9]{2})([0-9]{2})([0-9]{4,5})([0-9]{4})$/,
      "($2) $3-$4"
    );
  }

  return phoneString.replace(
    /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/,
    "($1) $2-$3"
  );
};

/**
 * formatar CPF. e.x.: 123.123.123-99
 */
export const formatCPF = (document: any) => {
  const documentString = String(document);

  return documentString.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

/**
 * formatar CNPJ. e.x.: 33.389.444/0001-10
 */
export const formatCPNJ = (document: any) => {
  const documentString = String(document);

  return documentString.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
};

/**
 * formatar CEP. e.x.:63040-055
 */
export const formatCEP = (document: any) => {
  const documentString = String(document);

  return documentString.replace(
    /^([\d]{2})\.*([\d]{3})-*([\d]{3})/,
    "$1.$2-$3"
  );
};

/**
 * capitaliza a primeira letra
 */
export const uppercaseFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1);

/**
 * Formata documento CNPJ/CPF
 */
export const maskDocument = (document: string) => {
  document = document.replace(/\D/g, "");

  if (document.length <= 11) {
    document = document.replace(/(\d{3})(\d)/, "$1.$2");
    document = document.replace(/(\d{3})(\d)/, "$1.$2");
    document = document.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    document = document.replace(/^(\d{2})(\d)/, "$1.$2");
    document = document.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    document = document.replace(/\.(\d{3})(\d)/, ".$1/$2");
    document = document.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return document;
};

export const renderDocument = (cpf?: string, cnpj?: string) => {
  if (cpf) {
    return maskDocument(cpf);
  }

  if (cnpj) {
    return maskDocument(cnpj);
  }

  return "-";
};

export const formatDecimalInput = ({
  value,
  range = 2,
  beforeRange = 11,
  maxValue = Number.MAX_VALUE,
  minValue,
}: {
  value: string;
  range: number;
  beforeRange: number;
  maxValue?: number;
  minValue?: number;
}) => {
  if (!value) {
    return "";
  }

  const shouldRange = range > 0;
  const regex = shouldRange ? /[^0-9.]+/gi : /[^0-9]+/gi;

  const oldValue = value.substring(0, value.length - 1);
  const truncated = value.replace(regex, "");

  if (truncated && minValue && Number(truncated) < minValue) {
    return oldValue;
  }

  const dotQuantity = truncated
    .split("")
    .filter(letter => letter === ".").length;

  if (dotQuantity > 1 || Number(truncated) > maxValue) {
    return Number(oldValue) > maxValue ? "" : oldValue;
  }

  const containsDot = truncated.includes(".");

  if (!containsDot && truncated.length > beforeRange) return oldValue;

  const isMoreOfBeforeRange =
    containsDot &&
    truncated.substring(0, truncated.indexOf(".")).length > beforeRange;

  if (isMoreOfBeforeRange) {
    return oldValue;
  }

  const containsDotAndIsMoreOfRange =
    truncated.includes(".") &&
    truncated.substring(truncated.indexOf(".") + 1).length > range;

  if (containsDotAndIsMoreOfRange) {
    return oldValue;
  }

  return truncated;
};
