import React from "react";

import { useOrder } from "src/hooks/order/useOrder";
import RenderOrderStatus from "src/pages/Orders/components/RenderOrderStatus";

import BasicData from "../BasicData";
import ContactData from "../ContactData";
import * as Styled from "./styles";

const MainDataCard: React.FC = () => {
  const { order } = useOrder();

  return (
    <>
      <Styled.ContainerRow>
        {order.controlNumber ? (
          <Styled.ControlNumberBox>
            {order.controlNumber}
          </Styled.ControlNumberBox>
        ) : null}
        <Styled.CodeBox>Nº {order.code}</Styled.CodeBox>
        <Styled.HorizontalLine />
        <RenderOrderStatus
          order={order}
          width={250}
          height={40}
          padding="20px"
          style={{ cursor: "pointer" }}
        />
      </Styled.ContainerRow>

      <Styled.Container>
        <Styled.Title>
          Informações{" "}
          {order.type === "serviceOrder" ? "da O.S" : "do pedido de venda"}
        </Styled.Title>
        <Styled.GridContainer>
          <ContactData />
          <BasicData />
        </Styled.GridContainer>
      </Styled.Container>
    </>
  );
};

export default MainDataCard;
