import { TagFormType, TagParamType } from "src/interfaces/tag";
import api from "src/services/api";

/**
 * Lista de tags
 */
export const getTagsService = (params: TagParamType) =>
  api
    .get("v1/tag", {
      params,
    })
    .then(res => res.data);

/**
 * Criar nova tag
 */
export const createTagsService = (data: TagFormType) =>
  api.post("v1/tag", data).then(res => res.data);
