import { useQuery } from "@tanstack/react-query";

import { GetFAQResponseType } from "src/interfaces/FAQ";
import api from "src/services/api";

export const getFaq = () =>
  api
    .get<GetFAQResponseType>("v1/faq", { params: { limit: 1000 } })
    .then(res => res.data);

export function useFaq() {
  return useQuery(["faq"], () => getFaq(), {
    staleTime: 1000 * 60 * 30, // 30 seconds
    refetchOnWindowFocus: false,
  });
}
