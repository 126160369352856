import { toast } from "react-toastify";

import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { ReactComponent as FinancialIcon } from "src/assets/icons/sidebar/financial.svg";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { RecordType } from "src/interfaces/record";
import { useDeleteTransfer } from "src/services/hooks/record/useDeleteTransfer";

interface DeleteTransferModalType {
  record?: RecordType;
  onSuccess?: () => void;
}

function DeleteTransferModal({ record, onSuccess }: DeleteTransferModalType) {
  const { open, handleClose } = useBaseSwipeableDrawer();

  const { mutate: deleteTransfer, isLoading } = useDeleteTransfer({
    onSuccess: () => {
      toast.success("Transferência excluída com com sucesso!");
      handleOnClose();
      if (onSuccess) onSuccess();
    },
  });

  const handleOnClose = () => {
    handleClose("deleteTransfer");
  };

  const handleDeleteTransfer = () => {
    deleteTransfer({
      id: record?.id as string,
    });
  };

  return (
    <Modal
      isOpen={open.includes("deleteTransfer")}
      onClose={handleOnClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" pb={0}>
          <Icon
            as={FinancialIcon}
            sx={{
              path: {
                fill: "error.500",
              },
            }}
          />
        </ModalHeader>
        <ModalCloseButton color="error.500" />
        <ModalBody pb={6}>
          <Text textAlign="center" mb={7}>
            Tem certeza que deseja excluir essa transferência?
          </Text>

          <Button
            w="100%"
            colorScheme="error"
            onClick={() => {
              handleDeleteTransfer();
            }}
            isLoading={isLoading}
          >
            Excluir
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DeleteTransferModal;
