import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Flex } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { DatePicker } from "src/components/NewComponents/DatePicker";
import { Select } from "src/components/NewComponents/Select";
import { Textarea } from "src/components/NewComponents/TextArea";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import {
  ProductOrderSettingType,
  ServiceOrderSettingType,
} from "src/interfaces/module";
import { OrderDetailType } from "src/interfaces/order";
import { useUpdateQuickOrder } from "src/services/hooks/order/useUpdateQuickOrder";
import { useUsers } from "src/services/hooks/user/useUsers";
import { formatDateToISO } from "src/utils/date";

import { schemaUpdateQuickModalForm } from "./schema";

export type UpdateQuickOrderModalSchema = yup.InferType<
  typeof schemaUpdateQuickModalForm
>;

interface UpdateQuickOrderModalType {
  order: OrderDetailType;
  onSuccess?: (order: OrderDetailType) => void;
}

function UpdateQuickOrderModal({
  order,
  onSuccess,
}: UpdateQuickOrderModalType) {
  const { serviceOrderModule, productOrderModule } = useModule();
  const { handleClose, open } = useBaseSwipeableDrawer();
  const { operatorNameDefinitions } = useCompany();
  const { data: rows, isLoading: isLoadingUsers } = useUsers(
    {
      limit: 10000,
      page: 1,
      status: "enabled",
    },
    { staleTime: 0 }
  );

  const serviceOrderModuleSettings =
    serviceOrderModule?.settings as ServiceOrderSettingType;
  const productOrderModuleSettings =
    productOrderModule?.settings as ProductOrderSettingType;

  const deadlineWithHour =
    (order.type === "serviceOrder" &&
      serviceOrderModuleSettings?.dueDateType === "dateHour") ||
    (order.type === "productOrder" &&
      productOrderModuleSettings?.dueDateType === "dateHour");

  const defaultValues: UpdateQuickOrderModalSchema = {
    id: order.id,
    deadline: order.deadline ? formatDateToISO(order.deadline) : order.deadline,
    additionalInfo: order.additionalInfo ?? "",
    operator: order?.operator?.id,
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    reset,
  } = useForm<UpdateQuickOrderModalSchema>({
    defaultValues,
    resolver: yupResolver(schemaUpdateQuickModalForm),
  });

  useEffect(() => {
    reset({
      ...defaultValues,
    });
  }, [order, open.includes("updateQuickOrder")]);

  const { mutate: updateOrder, isLoading } = useUpdateQuickOrder({
    onSuccess: order => {
      toast.success("Edição feita com sucesso.");
      if (onSuccess) onSuccess(order);
      handleClose("updateQuickOrder");
    },
  });

  const users = useMemo(() => {
    return rows?.users ?? [];
  }, [rows]);

  const onSubmit = ({
    id,
    additionalInfo,
    deadline,
    operator,
  }: UpdateQuickOrderModalSchema) => {
    updateOrder({
      id,
      additionalInfo,
      deadline: deadline ? formatDateToISO(deadline) : deadline,
      operator,
    });
  };

  return (
    <BaseSwipeableDrawer
      tag="updateQuickOrder"
      title="Edição rápida"
      buttonTitle="Salvar"
      buttonProps={{
        loading: isLoading,
        disabled: isLoading,
        form: "updateQuickOrderForm",
      }}
      zIndex={1302}
    >
      <Flex
        w={["100%", "350px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="updateQuickOrderForm"
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
        direction="column"
      >
        <DatePicker
          label="Prazo de entrega"
          name="deadline"
          withHour={deadlineWithHour}
          format={deadlineWithHour ? "dd/MM/yyyy - HH:mm" : "dd/MM/yyyy"}
          error={errors.deadline}
          control={control}
          formControlProps={{ bg: "white" }}
          isClearable
        />
        <Select
          value={watch("operator") ?? undefined}
          options={users.map(user => ({
            label: user.name,
            value: user.id,
          }))}
          isLoading={isLoadingUsers}
          onChange={newInputValue => {
            if (newInputValue) {
              setValue("operator", newInputValue as string);
            } else {
              setValue("operator", null);
            }
          }}
          placeholder={`Adicionar ${operatorNameDefinitions.title.toLowerCase()}...`}
          showFloatingLabel={false}
        />
        <Textarea
          label="Informações adicionais"
          error={errors.additionalInfo}
          bg="white"
          rows={4}
          {...register("additionalInfo")}
        />
      </Flex>
    </BaseSwipeableDrawer>
  );
}

export default UpdateQuickOrderModal;
