import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface CloseCashflowParams {
  closeMoney: number;
  cashFlow: string;
  note?: string;
}

export const closeCashFlow = (params: CloseCashflowParams) =>
  api.post(`/v1/cashflow/close`, params).then(res => res.data);

export function useCloseCashFlow(
  config?: UseMutationOptions<any, any, CloseCashflowParams, unknown>
) {
  return useMutation<any, any, CloseCashflowParams>(
    (data: CloseCashflowParams) => closeCashFlow(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao fechar caixa"
        );
      },
      ...config,
    }
  );
}
