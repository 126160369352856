import React, { useEffect, useMemo, useState } from "react";

import { Box, Button } from "@chakra-ui/react";
import Popover from "@material-ui/core/Popover";
import {
  add,
  sub,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import { usePopupState, bindPopover } from "material-ui-popup-state/hooks";

import Typography from "src/components/Typography";

import PeriodFilterButton from "./components/PeriodFilterButton";
import PeriodFilterContent from "./components/PeriodFilterContent";
import PeriodFilterOptions from "./components/PeriodFilterOptions";
import { PeriodFilterPickerProps, SelectedFilterType } from "./props";
import * as Styled from "./styles";

const PeriodFilterPicker: React.FC<PeriodFilterPickerProps> = props => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    initialFilter = "empty",
    options = ["empty", "byDay", "byMonth", "perInterval"],
    setSelectedFilterType,
    showingInPopover = true,
  } = props;

  // Data especifica
  const [selectedDay, setSelectedDay] = useState(new Date());

  // Mês
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  // Intervalo
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const [selectedFilter, setSelectedFilter] = useState(initialFilter);

  useEffect(() => {
    setSelectedFilter(initialFilter);
  }, [initialFilter]);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-options-menu-table",
  });

  const calcDay = (date: Date, operator?: "add" | "sub") => {
    if (operator === "add") {
      return add(date, { days: 1 });
    }

    if (operator === "sub") {
      return sub(date, { days: 1 });
    }
    return date;
  };

  const handleDayChange = (date: Date | null, operator?: "add" | "sub") => {
    if (date) {
      const calculatedDay = calcDay(date, operator);
      setSelectedDay(calculatedDay);
      setSelectedStartDate(startOfDay(calculatedDay));
      setSelectedEndDate(endOfDay(calculatedDay));
      if (!showingInPopover) {
        setStartDate(calculatedDay);
        setEndDate(calculatedDay);
      }
    }
  };

  const handleMonthChange = (date: Date | null) => {
    if (date) {
      const startDate = startOfMonth(date);
      const endDate = endOfMonth(date);
      setSelectedMonth(date);
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
      if (!showingInPopover) {
        setStartDate(startDate);
        setEndDate(endDate);
      }
    }
  };

  const handleStartDate = (date: Date | null, operator?: "add" | "sub") => {
    if (date) {
      const startDate = calcDay(date, operator);
      setSelectedStartDate(startDate);
      if (!showingInPopover) {
        setStartDate(startDate);
      }
    }
  };

  const handleEndDate = (date: Date | null, operator?: "add" | "sub") => {
    if (date) {
      const endDate = calcDay(date, operator);
      setSelectedEndDate(endDate);
      if (!showingInPopover) {
        setEndDate(endDate);
      }
    }
  };

  const applyFilter = () => {
    if (selectedFilter === "empty") {
      setStartDate(undefined);
      setEndDate(undefined);
    } else {
      setStartDate(selectedStartDate);
      setEndDate(selectedEndDate);
    }
    if (setSelectedFilterType) {
      setSelectedFilterType(selectedFilter);
    }
    popupState.close();
  };

  const renderClearSelectedFilterButton = useMemo(() => {
    if (selectedFilter !== "empty" && options.includes("empty")) {
      return (
        <Button
          onClick={() => {
            setSelectedFilter("empty");
            setStartDate(undefined);
            setEndDate(undefined);
            if (setSelectedFilterType) {
              setSelectedFilterType("empty");
            }
            popupState.close();
          }}
        >
          <Styled.CloseButton />
        </Button>
      );
    }

    return null;
  }, [selectedFilter, options]);

  const handleSetSelectedFilter = (selectedFilter: SelectedFilterType) => {
    setSelectedFilter(selectedFilter);

    if (setSelectedFilterType) {
      setSelectedFilterType(selectedFilter);
    }

    if (selectedFilter === "byDay") {
      handleDayChange(selectedDay);
    } else if (selectedFilter === "byMonth") {
      handleMonthChange(selectedMonth);
    } else if (selectedFilter === "perInterval") {
      handleStartDate(selectedStartDate);
      handleEndDate(selectedEndDate);
    } else if (selectedFilter === "byUpdate") {
      handleStartDate(selectedStartDate);
      handleEndDate(selectedEndDate);
    }
  };

  return showingInPopover ? (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <PeriodFilterButton
          selectedFilter={selectedFilter}
          selectedDay={selectedDay}
          selectedMonth={selectedMonth}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          popupState={popupState}
        />

        {renderClearSelectedFilterButton}
      </Box>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Box minWidth={350} width="100%" padding={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography color="primary" variant="h6">
              Escolher Período
            </Typography>
            <Styled.CloseButton onClick={popupState.close} />
          </Box>
          {options.length > 1 ? (
            <PeriodFilterOptions
              options={options}
              selectedFilter={selectedFilter}
              setSelectedFilter={handleSetSelectedFilter}
              showingInPopover={showingInPopover}
            />
          ) : null}

          <PeriodFilterContent
            showingInPopover={showingInPopover}
            handleDayChange={handleDayChange}
            handleEndDate={handleEndDate}
            handleMonthChange={handleMonthChange}
            handleStartDate={handleStartDate}
            selectedDay={selectedDay}
            selectedEndDate={selectedEndDate}
            selectedFilter={selectedFilter}
            selectedMonth={selectedMonth}
            selectedStartDate={selectedStartDate}
          />

          <Box mb={2} />
          <Styled.SubmitButton
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            onClick={applyFilter}
          >
            Aplicar
          </Styled.SubmitButton>
        </Box>
      </Popover>
    </>
  ) : (
    <>
      <PeriodFilterOptions
        options={options}
        selectedFilter={selectedFilter}
        setSelectedFilter={handleSetSelectedFilter}
        showingInPopover={showingInPopover}
      />
      <PeriodFilterContent
        showingInPopover={showingInPopover}
        handleDayChange={handleDayChange}
        handleEndDate={handleEndDate}
        handleMonthChange={handleMonthChange}
        handleStartDate={handleStartDate}
        selectedDay={selectedDay}
        selectedEndDate={selectedEndDate}
        selectedFilter={selectedFilter}
        selectedMonth={selectedMonth}
        selectedStartDate={selectedStartDate}
      />
    </>
  );
};

export default PeriodFilterPicker;
