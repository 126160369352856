import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { useQueryClient } from "@tanstack/react-query";

import { STATUS } from "src/constants/requestStatus";
import { SchedulingType } from "src/interfaces/scheduling";
import { createScheduling } from "src/services/scheduling";

import { SchedulingCreateContextType } from "./props";

export const SchedulingCreateContext = createContext(
  {} as SchedulingCreateContextType
);

export const SchedulingCreateProvider: React.FC = props => {
  const { children } = props;
  const queryClient = useQueryClient();

  const [status, setStatus] = useState(STATUS.inital);

  const create = async (params: SchedulingType) => {
    try {
      setStatus(STATUS.loading);

      await createScheduling(params);

      setStatus(STATUS.success);

      toast.success("Agendamento cadastrado");

      queryClient.invalidateQueries({
        queryKey: ["schedulingAllTypes"],
      });
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao cadastrar esse agendamento");
    }
  };

  return (
    <SchedulingCreateContext.Provider
      value={{
        status,
        create,
      }}
    >
      {children}
    </SchedulingCreateContext.Provider>
  );
};

export const useSchedulingCreate = () => {
  const context = useContext(SchedulingCreateContext);

  if (!context) {
    throw new Error(
      "useSchedulingCreate must be used within a SchedulingCreateProvider"
    );
  }

  return context;
};
