import React from "react";

import Box from "@material-ui/core/Box";

import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

interface LateClientsBottomBarProps {
  count: number;
  totalAmount: number;
  totalClients: number;
}

const LateClientsBottomBar: React.FC<LateClientsBottomBarProps> = props => {
  const { totalClients, count, totalAmount } = props;

  return (
    <Styled.AppBar position="fixed" component="footer">
      <Styled.Container>
        <Box display="flex">
          <Box mr={4}>
            <Styled.LabelData>Clientes</Styled.LabelData>
            <Styled.ValueData isRed>{totalClients}</Styled.ValueData>
          </Box>
          <Box pr={4} mr={4} borderRight="solid #D6D8DC 1px">
            <Styled.LabelData>Contas</Styled.LabelData>
            <Styled.ValueData isRed>{count}</Styled.ValueData>
          </Box>
          <Box>
            <Styled.LabelTotalValue>
              Valor
              <Styled.LabelIconMinus />
            </Styled.LabelTotalValue>
            <Styled.ValueData isRed>
              {formatPrice(totalAmount)}
            </Styled.ValueData>
          </Box>
        </Box>
      </Styled.Container>
    </Styled.AppBar>
  );
};

export default LateClientsBottomBar;
