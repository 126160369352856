import * as yup from "yup";

export const schemaMyUsernameForm = yup.object({
  slug: yup
    .string()
    .required("O campo usuário empresa é obrigatório")
    .min(3, "Usuário deve conter no mínimo 3 caracteres")
    .max(36, "Usuário deve conter no máximo 36 caracteres")
    .matches(/^[a-z0-9]+$/, "Usuário deve conter somente letras e números."),
});
