import React, { useState, createContext, useContext, useReducer } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import { SubCategoryRecordType } from "src/interfaces/categoryRecord";
import {
  CreateTransferType,
  GetRecordResponseType,
  PayRecordParamType,
  RecordListParamType,
  RecordType,
  UpdateRecordParamType,
} from "src/interfaces/record";
import {
  addRecord,
  deleteRecord,
  getRecord,
  getRecords,
  payRecord,
  updateRecord,
  chargebackRecord,
  createTransferService,
  deleteTransferService,
  updateTransferService,
} from "src/services/record";

import { RecordContextType } from "./props";
import { StatusRecordActionEnum, statusRecordReducer } from "./reducer";

export const RecordContext = createContext({} as RecordContextType);

export const RecordProvider: React.FC = props => {
  const { children } = props;

  const [state, dispatch] = useReducer(statusRecordReducer, {
    listStatus: STATUS.inital,
    payStatus: STATUS.inital,
    createStatus: STATUS.inital,
    updateStatus: STATUS.inital,
    deleteStatus: STATUS.inital,
    chargebackStatus: STATUS.inital,
    createTransferStatus: STATUS.inital,
  });

  const [records, setRecords] = useState<GetRecordResponseType>(
    {} as GetRecordResponseType
  );
  const [record, setRecord] = useState<RecordType | undefined>(undefined);

  const [restartRecordList, setRestartRecordList] = useState(false);

  const detail = async (id: string) => {
    try {
      dispatch({ type: StatusRecordActionEnum.LIST, payload: STATUS.loading });

      const response: RecordType = await getRecord(id);

      setRecord(response);

      dispatch({ type: StatusRecordActionEnum.LIST, payload: STATUS.success });
    } catch (e) {
      setRecord(undefined);
      dispatch({ type: StatusRecordActionEnum.LIST, payload: STATUS.error });
    }
  };

  const list = async (params: RecordListParamType) => {
    try {
      if (state.listStatus.loading) return;
      dispatch({ type: StatusRecordActionEnum.LIST, payload: STATUS.loading });

      const response: GetRecordResponseType = await getRecords({
        ...params,
      });

      setRecords(response);

      dispatch({ type: StatusRecordActionEnum.LIST, payload: STATUS.success });
    } catch (e) {
      setRecords({} as GetRecordResponseType);
      dispatch({ type: StatusRecordActionEnum.LIST, payload: STATUS.error });
    }
  };

  const add = async (
    params: Partial<RecordType>,
    action?: () => void
  ): Promise<RecordType> => {
    try {
      dispatch({
        type: StatusRecordActionEnum.CREATE,
        payload: STATUS.loading,
      });

      const response: RecordType = await addRecord(params);

      dispatch({
        type: StatusRecordActionEnum.CREATE,
        payload: STATUS.success,
      });

      if (action) {
        action();
      }

      toast.success("Registro cadastrado");
      return response;
    } catch (e) {
      dispatch({ type: StatusRecordActionEnum.CREATE, payload: STATUS.error });

      toast.error("Ocorreu um problema ao cadastrar esse registro");
      throw e;
    } finally {
      dispatch({
        type: StatusRecordActionEnum.CREATE,
        payload: STATUS.inital,
      });
    }
  };

  const update = async (
    id: string,
    params: UpdateRecordParamType
  ): Promise<RecordType> => {
    try {
      dispatch({
        type: StatusRecordActionEnum.UPDATE,
        payload: STATUS.loading,
      });

      const categoryParam = params.category as SubCategoryRecordType;

      const category = categoryParam?.id || params.category;

      const response: RecordType = await updateRecord(id, {
        ...params,
        category,
      });

      setRecord(response);

      dispatch({
        type: StatusRecordActionEnum.UPDATE,
        payload: STATUS.success,
      });

      toast.success("Registro atualizado");
      return response;
    } catch (e) {
      dispatch({ type: StatusRecordActionEnum.UPDATE, payload: STATUS.error });

      toast.error("Ocorreu um problema ao atualizar esse registro");
      throw e;
    } finally {
      dispatch({
        type: StatusRecordActionEnum.UPDATE,
        payload: STATUS.inital,
      });
    }
  };

  const remove = async (
    id: string,
    deleteType: "current" | "currentAndNext"
  ) => {
    try {
      dispatch({
        type: StatusRecordActionEnum.DELETE,
        payload: STATUS.loading,
      });

      await deleteRecord(id, { deleteType });

      dispatch({
        type: StatusRecordActionEnum.DELETE,
        payload: STATUS.success,
      });

      toast.success("Registro deletado");
    } catch (e) {
      dispatch({ type: StatusRecordActionEnum.DELETE, payload: STATUS.error });

      toast.error("Ocorreu um problema ao deletar esse registro");
      throw e;
    } finally {
      dispatch({
        type: StatusRecordActionEnum.DELETE,
        payload: STATUS.inital,
      });
    }
  };

  const chargeback = async (id: string): Promise<RecordType> => {
    try {
      dispatch({
        type: StatusRecordActionEnum.CHARGEBACK,
        payload: STATUS.loading,
      });

      const response: RecordType = await chargebackRecord(id);

      setRecord(response);

      dispatch({
        type: StatusRecordActionEnum.CHARGEBACK,
        payload: STATUS.success,
      });

      toast.success("Lançamento estornado");
      return response;
    } catch (e) {
      dispatch({
        type: StatusRecordActionEnum.CHARGEBACK,
        payload: STATUS.error,
      });

      toast.error("Ocorreu um problema ao estornar esse lançamento");
      throw e;
    } finally {
      dispatch({
        type: StatusRecordActionEnum.CHARGEBACK,
        payload: STATUS.inital,
      });
    }
  };

  const pay = async (
    id: string,
    params: PayRecordParamType
  ): Promise<RecordType> => {
    try {
      dispatch({ type: StatusRecordActionEnum.PAY, payload: STATUS.loading });

      const response: RecordType = await payRecord(id, params);

      setRecord(response);
      dispatch({ type: StatusRecordActionEnum.PAY, payload: STATUS.success });

      toast.success("Pagamento realizado");

      return response;
    } catch (e) {
      dispatch({ type: StatusRecordActionEnum.PAY, payload: STATUS.error });

      toast.error("Ocorreu um problema ao realizar esse pagamento");
      throw e;
    } finally {
      dispatch({ type: StatusRecordActionEnum.PAY, payload: STATUS.inital });
    }
  };

  const createTransfer = async (
    params: Partial<CreateTransferType>,
    action?: () => void
  ) => {
    try {
      dispatch({
        type: StatusRecordActionEnum.TRANSFER,
        payload: STATUS.loading,
      });

      await createTransferService(params);

      dispatch({
        type: StatusRecordActionEnum.TRANSFER,
        payload: STATUS.success,
      });

      if (action) {
        action();
      }

      toast.success("Transferência feita com sucesso!");
    } catch (e) {
      dispatch({
        type: StatusRecordActionEnum.TRANSFER,
        payload: STATUS.error,
      });

      toast.error("Ocorreu um problema ao fazer transferência");
    }
  };

  const updateTransfer = async (
    id: string,
    params: Partial<CreateTransferType>
  ) => {
    try {
      dispatch({
        type: StatusRecordActionEnum.UPDATE,
        payload: STATUS.loading,
      });

      await updateTransferService(id, params);

      setRecord({
        ...record,
        description: params.title,
        note: params.description,
      } as RecordType);

      dispatch({
        type: StatusRecordActionEnum.UPDATE,
        payload: STATUS.success,
      });

      toast.success("Transferência atualizada com sucesso!");
    } catch (e) {
      dispatch({ type: StatusRecordActionEnum.UPDATE, payload: STATUS.error });

      toast.error("Ocorreu um problema ao atualizar esse registro");
    }
  };

  const deleteTransfer = async (id: string) => {
    try {
      dispatch({
        type: StatusRecordActionEnum.DELETE,
        payload: STATUS.loading,
      });

      await deleteTransferService(id);

      dispatch({
        type: StatusRecordActionEnum.DELETE,
        payload: STATUS.success,
      });

      toast.success("Transferência deletada com sucesso!");
    } catch (e) {
      dispatch({ type: StatusRecordActionEnum.DELETE, payload: STATUS.error });

      toast.error("Ocorreu um problema ao deletar esse registro");
    }
  };

  const handleRestartRecordList = () => {
    setRestartRecordList(prev => !prev);
  };

  return (
    <RecordContext.Provider
      value={{
        status: state.listStatus,
        createStatus: state.createStatus,
        updateStatus: state.updateStatus,
        deleteStatus: state.deleteStatus,
        payStatus: state.payStatus,
        chargebackStatus: state.chargebackStatus,
        createTransferStatus: state.createTransferStatus,
        record,
        setRecord,
        records,
        list,
        add,
        update,
        detail,
        remove,
        chargeback,
        pay,
        createTransfer,
        updateTransfer,
        deleteTransfer,
        restartRecordList,
        handleRestartRecordList,
      }}
    >
      {children}
    </RecordContext.Provider>
  );
};

export const useRecord = () => {
  const context = useContext(RecordContext);

  if (!context) {
    throw new Error("useRecord must be used within a RecordProvider");
  }

  return context;
};
