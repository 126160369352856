import React, { useMemo } from "react";

import { useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { COLOR_STATUS } from "src/constants/orders";
import { useStatusOrder } from "src/hooks/order/useStatusOrder";

import { StatusTabsProps, TabLabelProps, TabPanelProps } from "./props";
import * as Styled from "./styles";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={3}>{children}</Box>}
    </div>
  );
};

const TabLabel: React.FC<TabLabelProps> = props => {
  const { status, activeStatus } = props;

  const { palette } = useTheme();

  const enabled = activeStatus === status._id;

  const colorIndicator = status._id ? COLOR_STATUS[status.type] : undefined;

  const colorText = status._id
    ? COLOR_STATUS[status.type]
    : palette.common.black;

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Styled.IndicatorStatus color={colorIndicator} />
        {status._id && <Box width={10} />}

        <Styled.TabLabelText statusColor={colorText} active={enabled}>
          {status.name}
        </Styled.TabLabelText>
      </Box>
      <Styled.TabLabelText statusColor={colorText} active={enabled}>
        {status.orderCount}
      </Styled.TabLabelText>
    </Box>
  );
};

const StatusTabs: React.FC<StatusTabsProps> = props => {
  const { status, setStatus, children } = props;

  const { ordersStatus } = useStatusOrder();

  const tabs = useMemo(
    () => ordersStatus.map(item => ({ key: item._id, label: item.name })),
    [ordersStatus]
  );

  const handleChange = (_: React.ChangeEvent<unknown>, newStatus: string) => {
    setStatus(newStatus);
  };

  const renderTabs = useMemo(
    () =>
      ordersStatus.map(item => (
        <Styled.Tab
          label={<TabLabel status={item} activeStatus={status} />}
          {...a11yProps(item._id)}
          key={`tab-${item._id}`}
          value={item._id}
        />
      )),
    [ordersStatus, status]
  );

  const renderTabPanels = ordersStatus.map((tabPanel, index) => (
    <TabPanel
      value={status}
      index={tabs[index].key}
      key={`tabpanel-${tabs[index].key}`}
    >
      {children}
    </TabPanel>
  ));

  return (
    <>
      <Styled.Tabs
        value={status}
        onChange={handleChange}
        aria-label="status-tabs"
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={Styled.TabScrollButton}
      >
        {renderTabs}
      </Styled.Tabs>
      {renderTabPanels}
    </>
  );
};

export default StatusTabs;
