import { FC, useState } from "react";

import Box from "@material-ui/core/Box";
import { Form, Formik } from "formik";

import Button from "src/components/Button";
import { useModule } from "src/hooks/module/useModule";
import { CashFlowControlSettingType } from "src/interfaces/module";
import SectionCard from "src/pages/Settings/components/SectionCard";

import SwitchSettings from "../../SwitchSettings";

const CashFlowSection: FC = () => {
  const { updateSettingsModule } = useModule();

  const { cashFlowControlModule } = useModule();

  const [status, setStatus] = useState(false);

  const settings =
    cashFlowControlModule?.settings as CashFlowControlSettingType;

  if (!cashFlowControlModule) {
    return null;
  }

  const onSubmit = async (settings: CashFlowControlSettingType) => {
    setStatus(true);
    await updateSettingsModule(cashFlowControlModule.module.id, settings);
    setStatus(false);
  };

  return (
    <Formik
      initialValues={
        {
          restrictMoneyPayment: settings?.restrictMoneyPayment ?? false,
        } as CashFlowControlSettingType
      }
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <SectionCard title="Controle de Caixa">
          <Box mb={2}>
            <SwitchSettings
              name="restrictMoneyPayment"
              label="Obrigatório selecionar conta do tipo caixa para pagamentos em dinheiro"
              disabled={status}
            />
          </Box>

          <Box display="flex" flexDirection="row-reverse">
            <div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="small"
                fullWidth
                loading={status}
              >
                Salvar
              </Button>
            </div>
          </Box>
        </SectionCard>
      </Form>
    </Formik>
  );
};

export default CashFlowSection;
