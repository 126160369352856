import { Link as LinkUnstyled } from "react-router-dom";

import styled from "styled-components";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiButton from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { ButtonSidebarProps } from "./props";

export const RightIcon = styled(NavigateNextIcon)<
  Pick<ButtonSidebarProps, "selected">
>`
  color: ${({ selected, theme }) =>
    selected ? theme.palette.common.white : theme.palette.primary.main};
`;

export const BottomIcon = styled(ExpandMoreIcon)<
  Pick<ButtonSidebarProps, "selected">
>`
  color: ${({ selected, theme }) =>
    selected ? theme.palette.common.white : theme.palette.primary.main};
`;

export const Container = styled(MuiAccordion)`
  & * {
    box-shadow: none !important;
    border: none !important;
  }
  &.MuiAccordion-root:before {
    height: 0px;
  }
  &.Mui-expanded {
    margin: auto;
  }
  background-color: rgba(0, 0, 0, 0);
  padding: 0px;
  margin: 0px;
  min-width: 180px;
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0px;
  margin: 0px;
  height: 30px;
  transition: 0.5s;
  &.Mui-expanded {
    min-height: 45px;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Link = styled(LinkUnstyled)`
  text-decoration: none;
  width: 100%;
`;

export const ContentLeftButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

export const Button = styled(MuiButton)<Pick<ButtonSidebarProps, "selected">>`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 100%;
  padding: 10px;
  margin: 4px 0px;
  min-height: 32px;

  &.MuiButton-root {
    background-color: ${({ selected, theme }) =>
      selected ? theme.palette.primary.main : "transparent"};
    justify-content: space-between;
  }

  :hover {
    background-color: ${({ selected, theme }) =>
      selected ? theme.palette.primary.main : theme.palette.secondary.light};
  }

  p {
    font-size: 12px;
    text-transform: lowercase;
    font-weight: 500;
    color: ${({ selected, theme }) =>
      selected ? theme.palette.common.white : theme.palette.common.black};
    line-height: 0px;
  }

  p:first-letter {
    text-transform: uppercase;
  }
`;

export const OptionButton = styled(Button)`
  padding: 8px 10px;
  &.MuiButton-root {
    background-color: ${({ selected, theme }) =>
      selected ? theme.palette.secondary.light : "transparent"};
    justify-content: flex-start;
  }

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }

  svg {
    font-size: 16px;
    margin-right: 10px;
  }

  p {
    font-size: 12px;
    color: ${({ selected, theme }) =>
      selected ? theme.palette.primary.main : theme.palette.common.black};
  }
  text-transform: none;
`;
