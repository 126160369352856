import React, { FC, useState } from "react";
import { toast } from "react-toastify";

import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { usePopupState, bindToggle } from "material-ui-popup-state/hooks";

import {
  BUY_ORDER_COLOR_STATUS,
  BUY_ORDER_STATUS_NAME,
} from "src/constants/orders";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import { BuyOrderStatusEnum, BuyOrderType } from "src/interfaces/buyOrder";
import BuyOrderStatusesPopover from "src/pages/BuyOrders/components/BuyOrderStatusesPopover";

import * as Styled from "./styles";

interface RenderBuyOrderStatusProps {
  buyOrder: BuyOrderType;
}

const RenderBuyOrderStatus: FC<RenderBuyOrderStatusProps> = props => {
  const { buyOrder } = props;
  const { updateBuyOrderStatus } = useBuyOrder();
  const [loading, setLoading] = useState(false);
  const { setRestartOrderList } = useBuyOrderFilters();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "update-status-order",
  });

  const updateStatus = async (
    buyOrderId: string,
    status: BuyOrderStatusEnum
  ) => {
    try {
      popupState.close();
      setLoading(true);
      await updateBuyOrderStatus({
        id: buyOrderId,
        status,
      });
      setRestartOrderList(true);
    } catch (error) {
      toast.error("Falha ao alterar status, tente novamente");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BuyOrderStatusesPopover
        buyOrderId={buyOrder.id}
        updateStatus={updateStatus}
        popupState={popupState}
      />
      <Styled.StatusTag
        {...bindToggle(popupState)}
        color={BUY_ORDER_COLOR_STATUS[buyOrder.status]}
      >
        {loading ? (
          <Styled.LoadingContainer>
            <Styled.Loading size={20} />
          </Styled.LoadingContainer>
        ) : (
          <>
            {BUY_ORDER_STATUS_NAME[buyOrder.status]}
            <KeyboardArrowDownRoundedIcon color="inherit" />
          </>
        )}
      </Styled.StatusTag>
    </>
  );
};

export default RenderBuyOrderStatus;
