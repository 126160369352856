import React from "react";

import { Box, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";

import CurrencyField from "src/components/Inputs/CurrencyField";
import ImageUploadField from "src/components/Inputs/ImageUploadField";
import SelectField from "src/components/Inputs/SelectField";
import Textarea from "src/components/Inputs/Textarea";
import TextField from "src/components/Inputs/TextField";
import Loader from "src/components/Loader";
import Typography from "src/components/Typography";
import { SERVICES_MEASURES } from "src/constants/measures";
import { useService } from "src/hooks/service/useService";
import { ServiceMeasureType, ServiceType } from "src/interfaces/service";
import { formatPrice } from "src/utils/format";

import { ServiceFormProps } from "./props";
import { schemaServiceForm } from "./schema";

const ServiceForm: React.FC<ServiceFormProps> = props => {
  const { method, data } = props;

  const { add, update, status } = useService();

  const onSubmit = (data: ServiceType) => {
    if (method === "add") {
      add(data);
    }

    if (method === "update") {
      update(data);
    }
  };

  const measuresItems = Object.keys(SERVICES_MEASURES).map(measure => ({
    name: SERVICES_MEASURES[measure as ServiceMeasureType],
    value: measure,
  }));

  const calcProfit = (price?: number, cost?: number) =>
    Number(price) - Number(cost);

  const colorProfitLabel = (value: number) =>
    value >= 0 ? "primary" : "error";

  if (status.loading) return <Loader size="medium" />;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={
        {
          id: data?.id || "",
          name: data?.name || "",
          price: data?.price || ("" as unknown as number),
          cost: data?.cost || ("" as unknown as number),
          description: data?.description || "",
          image: data?.image || "",
          unitOfMeasure: data?.unitOfMeasure || "un",
        } as ServiceType
      }
      validationSchema={schemaServiceForm}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ values }) => (
        <Form id="form-service">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>Dados do serviço</Typography>
            </Grid>

            <Grid item xs={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField label="Nome de serviço" name="name" required />
                </Grid>

                <Grid item xs={12}>
                  <CurrencyField label="Custo" name="cost" />
                </Grid>

                <Grid item xs={12}>
                  <CurrencyField label="Preço" name="price" required />
                </Grid>

                <Grid item xs={12}>
                  <SelectField
                    defaultValue="default"
                    label="Unidade de medida"
                    name="unitOfMeasure"
                    items={measuresItems}
                    disabled={method === "update"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Textarea label="Descrição" name="description" rows={4} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={7}>
              <Box
                height="100%"
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <ImageUploadField name="image" />

                <div>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>Lucro</Typography>
                    <Typography
                      color={colorProfitLabel(
                        calcProfit(values.price, values.cost)
                      )}
                    >
                      {formatPrice(calcProfit(values.price, values.cost))}
                    </Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography>Preço do serviço</Typography>
                    <Typography color="primary">
                      {formatPrice(values.price)}
                    </Typography>
                  </Box>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ServiceForm;
