import styled from "styled-components";

import { Box } from "@material-ui/core";
import DividerUnstyled from "@material-ui/core/Divider";

import Typography from "src/components/Typography";

export const LabelPeriod = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  margin: 0px 0px 10px 5px;
`;

export const Divider = styled(DividerUnstyled)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContainerOverflow = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;
`;
