import styled from "styled-components";

import { Box } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const Accordion = styled(MuiAccordion)`
  border-radius: 10px;
  box-shadow: none;
  display: flex;
  flex-direction: column;

  &.MuiAccordion-root:before {
    border: 0;
    background-color: transparent;
  }
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionSummary-content {
    .Mui-expanded {
      margin: 0;
    }
    margin: 0;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(101, 174, 237, 0.1);
  border-radius: 5px;
  padding: 8px 16px;
  min-height: 40px;
  margin-bottom: 8px;
  &.Mui-expanded {
    min-height: 40px;
  }
  .MuiIconButton-root {
    padding: 0;
    margin-right: 8px;
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(90deg);
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  width: 100%;
  flex-direction: column;
`;

export const RowItem = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(101, 174, 237, 0.1);
  border-radius: 5px;
  padding: 8px 16px;
  min-height: 40px;
  margin-bottom: 8px;
`;

export const RowSubItem = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  min-height: 40px;
`;

export const ExpandIcon = styled(ExpandMoreIcon)`
  transform: rotate(-90deg);
`;
