import { useMemo } from "react";

import { Text, TextProps } from "@chakra-ui/react";

import { ContactType } from "src/interfaces/contact";
import {
  formatCEP,
  formatCPF,
  formatCPNJ,
  formatPhone,
} from "src/utils/format";

export default function Client({
  customer,
  subContact,
}: {
  customer: ContactType;
  subContact: string | undefined;
}) {
  const cpf = useMemo(() => {
    if (customer?.cpf?.length) {
      return `CPF: ${formatCPF(customer.cpf)}`;
    }

    return null;
  }, [customer]);

  const cnpj = useMemo(() => {
    if (customer?.cnpj?.length) {
      return `CNPJ: ${formatCPNJ(customer.cnpj)}`;
    }

    return null;
  }, [customer]);

  const address = useMemo(() => {
    const street = customer.street ? `${customer.street}, ` : "";
    const number = customer.number ? `${customer.number}, ` : "";
    const complement = customer.complement ? `${customer.complement}, ` : "";
    const neighborhood = customer.neighborhood
      ? `${customer.neighborhood}, `
      : "";
    const city = customer.city ? `${customer.city}` : "";
    // const state = customer.state ? `${customer.state}, ` : "";
    const cep = customer.cep ? ` - ${formatCEP(customer.cep)}` : "";

    if (!street && !number && !complement && !neighborhood && !city && !cep)
      return null;

    return `Endereço: ${street}${number}${complement}${neighborhood}${city}${cep}`;
  }, [customer]);

  const phones = useMemo(() => {
    const alternativePhones = customer.alternativePhones
      ?.map(phone => formatPhone(phone.number))
      ?.join(", ");

    if (customer?.phone?.length) {
      return `${formatPhone(customer.phone) ?? ""}${
        alternativePhones ? `, ${alternativePhones}` : ""
      }
      `;
    }

    return null;
  }, [customer]);

  const CustomText = ({ children, ...props }: TextProps) => {
    return (
      <Text fontWeight={400} lineHeight={1.1} textAlign="center" {...props}>
        {children}
      </Text>
    );
  };

  return (
    <>
      <CustomText fontSize="10px" color="black">
        Cliente: {customer.name}
      </CustomText>
      {subContact ? (
        <CustomText fontSize="10px" color="black">
          {subContact}
        </CustomText>
      ) : null}
      {cnpj ? (
        <CustomText fontSize="10px" color="black">
          CNPJ: {cnpj}
        </CustomText>
      ) : null}
      {cpf ? (
        <CustomText fontSize="10px" color="black">
          CPF: {cpf}
        </CustomText>
      ) : null}
      {customer.phone || customer.alternativePhones?.length ? (
        <CustomText fontSize="10px" color="black">
          Telefones: {phones}
        </CustomText>
      ) : null}
      {address ? (
        <CustomText fontSize="10px" color="black">
          {address}
        </CustomText>
      ) : null}
      {customer.email ? (
        <CustomText fontSize="10px" color="black">
          E-mail: {customer.email}
        </CustomText>
      ) : null}
    </>
  );
}
