import React, { useState, createContext, useContext } from "react";

import { STATUS } from "src/constants/requestStatus";
import { SchedulingType } from "src/interfaces/scheduling";
import { getScheduling } from "src/services/scheduling";

import { SchedulingDetailContextType } from "./props";

export const SchedulingDetailContext = createContext(
  {} as SchedulingDetailContextType
);

export const SchedulingDetailProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState(STATUS.inital);

  const [scheduling, setScheduling] = useState<SchedulingType>(
    {} as SchedulingType
  );

  const detail = async (id: string) => {
    try {
      setStatus(STATUS.loading);

      const response: SchedulingType = await getScheduling(id);

      setScheduling(response);

      setStatus(STATUS.success);
    } catch (e) {
      setScheduling({} as SchedulingType);
      setStatus(STATUS.error);
    }
  };

  return (
    <SchedulingDetailContext.Provider
      value={{
        status,
        scheduling,
        detail,
      }}
    >
      {children}
    </SchedulingDetailContext.Provider>
  );
};

export const useSchedulingDetail = () => {
  const context = useContext(SchedulingDetailContext);

  if (!context) {
    throw new Error(
      "useSchedulingDetail must be used within a SchedulingDetailProvider"
    );
  }

  return context;
};
