import { Control, Controller, FieldError } from "react-hook-form";

import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormLabel,
  FormLabelProps,
  Input,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";
import { TimePicker as DatePickerUnstyled } from "@material-ui/pickers";

interface CurrencyInputProps extends ChakraInputProps {
  format?: string;
  name: string;
  label?: string;
  error?: FieldError | null;
  control: Control<any, any>;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  formErrorMessageProps?: FormErrorMessageProps;
}

export function TimePicker({
  name,
  label,
  control,
  error = null,
  isDisabled,
  formControlProps = {},
  formLabelProps = {},
  formErrorMessageProps = {},
  ...rest
}: CurrencyInputProps) {
  return (
    <FormControl
      isInvalid={!!error}
      isDisabled={isDisabled}
      variant="floating"
      {...formControlProps}
    >
      <Box w="100%" position="relative">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <DatePickerUnstyled
              value={value}
              onChange={date => {
                onChange(date);
              }}
              ampm={false}
              inputVariant="outlined"
              cancelLabel="cancelar"
              DialogProps={{
                style: { zIndex: 1999 },
              }}
              inputProps={{
                placeholder: " ",
              }}
              TextFieldComponent={({
                inputProps,
                onClick,
                inputRef,
                value,
              }) => {
                return (
                  <Input
                    {...inputProps}
                    ref={inputRef}
                    name={name}
                    value={String(value)}
                    onClick={onClick}
                    readOnly
                    cursor="pointer"
                    {...rest}
                  />
                );
              }}
            />
          )}
        />

        {!!label && (
          <FormLabel htmlFor={name} {...formLabelProps}>
            {label}
          </FormLabel>
        )}
      </Box>
      {!!error && (
        <FormErrorMessage {...formErrorMessageProps}>
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
