import React, { FC, useMemo } from "react";

import { Popover } from "@material-ui/core";
import { bindPopover, PopupState } from "material-ui-popup-state/hooks";

import { COLOR_STATUS } from "src/constants/orders";
import { useCompany } from "src/hooks/company/useCompany";
import { StatusNameType } from "src/interfaces/order";

import * as Styled from "./styles";

interface StatusesPopoverProps {
  updateStatus: (orderId: string, statusId: string) => void;
  orderId: string;
  popupState: PopupState;
}

const StatusesPopover: FC<StatusesPopoverProps> = ({
  orderId,
  updateStatus,
  popupState,
}) => {
  const { userCompany } = useCompany();

  const renderButtons = useMemo(() => {
    return (
      userCompany?.status?.map(item => (
        <Styled.ButtonOption
          key={item.id}
          onClick={() => {
            updateStatus(orderId, item.id);
          }}
        >
          <Styled.StatusIndicator
            color={COLOR_STATUS[item.type as StatusNameType]}
          />

          {item.name}
        </Styled.ButtonOption>
      )) ?? null
    );
  }, [userCompany, orderId]);

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: 0,
        },
      }}
    >
      <Styled.MenuOverlay>
        <Styled.MenuContainer>{renderButtons}</Styled.MenuContainer>
      </Styled.MenuOverlay>
    </Popover>
  );
};

export default StatusesPopover;
