import styled from "styled-components";

import { Box } from "@material-ui/core";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Schedule from "@material-ui/icons/Schedule";

import Typography from "src/components/Typography";

export const TitleText = styled(Typography)<{ lineThrough?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 10px;
  color: #92969c;
  text-decoration: ${({ lineThrough }) =>
    lineThrough ? `line-through` : "unset"};
  width: 100%;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TimeText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: #73757a;
`;

export const DateText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: #73757a;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const IconBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: rgba(172, 226, 246, 0.2);
  border-radius: 50%;
`;

export const CalendarTodayIcon = styled(CalendarToday)``;
export const ScheduleIcon = styled(Schedule)``;
