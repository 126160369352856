import { FormProvider, useWatch } from "react-hook-form";

import {
  TabPanel,
  Wrap,
  Button,
  Divider,
  SimpleGrid,
  Text,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { NoteAdd } from "@material-ui/icons";
import * as yup from "yup";

import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { DatePicker } from "src/components/NewComponents/DatePicker";
import { Textarea } from "src/components/NewComponents/TextArea";
import RenderModule from "src/components/RenderModule";
import { useModule } from "src/hooks/module/useModule";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";
import { ContactType, SubContactType } from "src/interfaces/contact";
import {
  ProductOrderSettingType,
  ServiceOrderSettingType,
} from "src/interfaces/module";
import { UserType } from "src/interfaces/user";

import { CreateOrderBottomBar } from "../CreateOrderBottomBar";
import { ItemsOfOrderButton } from "../ItemsOfOrderButton";
import ComissionSection from "./components/ComissionSection";
import CustomerSection from "./components/CustomerSection";
import TechnicalReport from "./components/TechnicalReport";
import { TechnicalReportResume } from "./components/TechnicalReportResume";
import { orderDataSchema } from "./schema";

export type OrderDataSchema = yup.InferType<typeof orderDataSchema>;

interface OrderDataFormProps {
  [key: string]: any;
}

export function OrderDataForm({ ...props }: OrderDataFormProps) {
  const {
    handleToNextStep,
    handleToPreviousStep,
    setOrder,
    subtotal,
    methodsOrderData,
    type,
  } = useOrderForm();
  const { serviceOrderModule, productOrderModule, hasModules } = useModule();
  const { handleOpen } = useBaseSwipeableDrawer();

  const allowSetCreatedDate =
    serviceOrderModule?.settings?.allowSetCreatedDate === "allow";
  const serviceOrderModuleSettings =
    serviceOrderModule?.settings as ServiceOrderSettingType;
  const productOrderModuleSettings =
    productOrderModule?.settings as ProductOrderSettingType;

  const createDateWithHour =
    (type === "serviceOrder" &&
      serviceOrderModuleSettings?.createdAtType === "dateHour") ||
    (type === "productOrder" &&
      productOrderModuleSettings?.createdAtType === "dateHour");

  const deadlineWithHour =
    (type === "serviceOrder" &&
      serviceOrderModuleSettings?.dueDateType === "dateHour") ||
    (type === "productOrder" &&
      productOrderModuleSettings?.dueDateType === "dateHour");

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
  } = methodsOrderData;

  const customer = useWatch({ name: "customer", control });
  const subContact = useWatch({ name: "subContact", control });
  const operator = useWatch({ name: "operator", control });
  const technicalReport = useWatch({ name: "technicalReport", control });

  const onSubmit = ({
    additionalInfo,
    createdDate,
    deadline,
    customer,
    operator,
    subContact,
  }: OrderDataSchema) => {
    setOrder(order => ({
      ...order,
      additionalInfo,
      createdDate,
      deadline,
      contactId: customer?.id,
      subContactId: subContact?.id,
      operatorId: operator?.id,
      technicalReport,
    }));
    handleToNextStep();
  };

  return (
    <FormProvider {...methodsOrderData}>
      <TabPanel {...props} p={0} as="form" onSubmit={handleSubmit(onSubmit)}>
        <Divider my={4} />
        <CustomerSection
          customer={customer}
          subContact={subContact}
          setCustomer={(customer: ContactType | undefined) =>
            setValue("customer", customer)
          }
          setSubContact={(subContact: SubContactType | undefined) =>
            setValue("subContact", subContact)
          }
        />

        <SimpleGrid columns={3} gap={4} mt={4}>
          {allowSetCreatedDate ? (
            <DatePicker
              label="Data da venda"
              name="createdDate"
              withHour={createDateWithHour}
              format={createDateWithHour ? "dd/MM/yyyy - HH:mm" : "dd/MM/yyyy"}
              error={errors.createdDate}
              control={control}
              formControlProps={{ bg: "white" }}
            />
          ) : null}

          <DatePicker
            label="Prazo de entrega"
            name="deadline"
            withHour={deadlineWithHour}
            format={deadlineWithHour ? "dd/MM/yyyy - HH:mm" : "dd/MM/yyyy"}
            error={errors.deadline}
            control={control}
            formControlProps={{ bg: "white" }}
            isClearable
          />

          <RenderModule modules={["operator"]}>
            <ComissionSection
              operator={operator}
              setOperator={(operator: UserType | undefined) =>
                setValue("operator", operator)
              }
            />
          </RenderModule>
        </SimpleGrid>

        <Divider my={4} />

        <Text color="text" mb={2.5}>
          Observação
        </Text>

        <Textarea
          mb={4}
          label=""
          placeholder="Observação (caso deseje, escreva algo que ache relevante para o pedido)"
          error={errors.additionalInfo}
          bg="white"
          rows={5}
          {...register("additionalInfo")}
        />

        {type === "serviceOrder" &&
        hasModules("technicalReport") &&
        !technicalReport ? (
          <>
            <Text color="text" mb={2.5}>
              Laudo técnico
            </Text>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bg="white"
              borderRadius={10}
              p={4}
              mb={6}
            >
              <Icon as={NoteAdd} fontSize="56px" mb={2} color="#E3E1E1" />
              <Text fontWeight={400}>Nenhum Laudo adicionado</Text>
              <Text fontSize="xs" fontWeight={400} mb={4}>
                Adicione laudo a ordem de serviço!
              </Text>
              <Button onClick={() => handleOpen("technicalReport")} size="sm">
                Adicionar laudo
              </Button>
            </Flex>
          </>
        ) : null}

        {type === "serviceOrder" &&
        hasModules("technicalReport") &&
        technicalReport ? (
          <>
            <Text color="text" mb={2.5}>
              Laudo técnico
            </Text>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bg="white"
              borderRadius={10}
              p={4}
              mb={6}
            >
              <Button
                onClick={() => {
                  handleOpen("technicalReport");
                }}
                variant="outline"
                size="sm"
                alignSelf="flex-end"
              >
                Editar laudo
              </Button>
              <Divider my={4} />
              <TechnicalReportResume technicalReport={technicalReport} />
            </Flex>
          </>
        ) : null}

        <CreateOrderBottomBar
          subtotal={subtotal}
          discount={0}
          total={subtotal}
          right={
            <Wrap spacing={4}>
              <ItemsOfOrderButton />
              <Button
                variant="outline"
                onClick={() => {
                  handleToPreviousStep();
                }}
              >
                Voltar
              </Button>
              <Button type="submit">Avançar</Button>
            </Wrap>
          }
        />
      </TabPanel>
      <TechnicalReport />
    </FormProvider>
  );
}
