import React from "react";

import { useField } from "formik";

import { TextAreaProps } from "./props";
import * as Styled from "./styles";

const Textarea: React.FC<TextAreaProps> = props => {
  const { name, value, defaultValue, disabled, disableFormik, ...rest } = props;

  if (disableFormik) {
    return (
      <Styled.TextField
        fullWidth
        variant="outlined"
        multiline
        {...rest}
        InputLabelProps={{
          required: false,
          ...rest.InputLabelProps,
        }}
        disabled={disabled}
        value={value}
      />
    );
  }

  const [field, meta] = useField<string>({
    name,
    defaultValue,
    value,
  });

  const errorProps = React.useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      };
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      };
    }
    return {};
  }, [meta.error, meta.initialError, meta.touched]);

  return (
    <Styled.TextField
      fullWidth
      multiline
      variant="outlined"
      id={field.name}
      {...errorProps}
      {...rest}
      {...field}
      InputLabelProps={{
        required: false,
        ...rest.InputLabelProps,
      }}
      disabled={disabled}
    />
  );
};

export default Textarea;
