import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import { ListParamsType } from "src/interfaces/request";
import { GetServiceResponseType, ServiceType } from "src/interfaces/service";
import {
  getService,
  addService,
  updateService,
  getServices,
  deleteService,
} from "src/services/service";

import { useImage } from "../image/useImage";
import { ServiceContextType } from "./props";

export const ServiceContext = createContext({} as ServiceContextType);

export const ServiceProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState(STATUS.inital);
  const [services, setServices] = useState<GetServiceResponseType>(
    {} as GetServiceResponseType
  );
  const [service, setService] = useState<ServiceType>({} as ServiceType);
  const { uploadAndReturnUrl } = useImage();

  const detail = async (id: string) => {
    try {
      setStatus(STATUS.loading);

      const response: ServiceType = await getService(id);

      setService(response);

      setStatus(STATUS.success);
    } catch (e) {
      setService({} as ServiceType);
      setStatus(STATUS.error);
    }
  };

  const list = async (params: ListParamsType) => {
    try {
      setServices({} as GetServiceResponseType);
      setStatus(STATUS.loading);

      const response: GetServiceResponseType = await getServices(params);

      setServices(response);

      setStatus(STATUS.success);
    } catch (e) {
      toast.error(
        "Ocorreu um problema ao carregar os serviços. Por favor verifique sua conexão e tente novamnete."
      );
      setServices({} as GetServiceResponseType);
      setStatus(STATUS.error);
    }
  };

  const uploadImage = async (image: string | File) => {
    let file = image;
    if (file instanceof File) {
      file = (await uploadAndReturnUrl(file))?.url ?? "";
    }
    return file;
  };

  const add = async (params: ServiceType) => {
    try {
      setService({} as ServiceType);
      setStatus(STATUS.loading);

      const { cost, price, image, ...rest } = params;

      const priceNumber = Number(price);
      const costNumber = Number(cost);

      const fileURL = await uploadImage(image);

      const response = await addService({
        ...rest,
        price: priceNumber,
        ...(costNumber && {
          cost: costNumber,
        }),
        image: fileURL,
      });

      setService(response);

      setStatus(STATUS.success);
      toast.success("Serviço cadastrado");
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao cadastrar esse serviço");
    }
  };

  const update = async (params: ServiceType) => {
    try {
      setService({} as ServiceType);
      setStatus(STATUS.loading);

      const { cost, price, image, ...rest } = params;

      const priceNumber = Number(price);
      const costNumber = Number(cost);

      const fileURL = await uploadImage(image);

      const response = await updateService({
        ...rest,
        price: priceNumber,
        ...(costNumber && {
          cost: costNumber,
        }),
        image: fileURL,
      });

      setService(response);

      setStatus(STATUS.success);

      toast.success("Serviço atualizado");
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao atualizar esse serviço");
    }
  };

  const remove = async (id: string) => {
    try {
      setStatus(STATUS.loading);

      await deleteService(id);

      setStatus(STATUS.success);

      if (services?.services?.length > 0) {
        setServices({
          ...services,
          services: services.services.filter(item => item?.id !== id),
        });
      }

      toast.success("Serviço deletado");
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao deletar esse serviço");
    }
  };

  return (
    <ServiceContext.Provider
      value={{
        status,
        service,
        services,
        list,
        add,
        update,
        detail,
        remove,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export const useService = () => {
  const context = useContext(ServiceContext);

  if (!context) {
    throw new Error("useService must be used within a ServiceProvider");
  }

  return context;
};
