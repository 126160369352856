import React, { useMemo } from "react";

import {
  BUY_ORDER_COLOR_STATUS,
  BUY_ORDER_STATUS_NAME,
} from "src/constants/orders";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";

import BasicData from "./BasicData";
import ContactData from "./ContactData";
import * as Styled from "./styles";

const MainDataCard: React.FC = () => {
  const { buyOrder, updateBuyOrderStatusRequest } = useBuyOrder();

  const color = useMemo(() => {
    if (buyOrder?.status) {
      return BUY_ORDER_COLOR_STATUS[buyOrder.status];
    }
    return null;
  }, [buyOrder, updateBuyOrderStatusRequest]);

  return (
    <>
      <Styled.ContainerRow>
        <Styled.CodeBox>Nº {buyOrder?.number}</Styled.CodeBox>
        <Styled.HorizontalLine />
        <Styled.StatusTag color={color}>
          {updateBuyOrderStatusRequest.loading ? (
            <Styled.Loading size={20} />
          ) : null}
          {!updateBuyOrderStatusRequest.loading && buyOrder?.status
            ? BUY_ORDER_STATUS_NAME[buyOrder.status]
            : "-"}
        </Styled.StatusTag>
      </Styled.ContainerRow>

      <Styled.Container>
        <Styled.Title>Informações da ordem de compra</Styled.Title>
        <Styled.GridContainer>
          <ContactData />
          <BasicData />
        </Styled.GridContainer>
      </Styled.Container>
    </>
  );
};

export default MainDataCard;
