import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import { useField } from "formik";

import Typography from "src/components/Typography";
import PhotoPreview from "src/pages/Orders/components/Modals/TechnicalReport/components/PhotoPreview";

import { ImageUploadFieldProps } from "./props";
import * as Styled from "./styles";

const ImageUploadField: React.FC<ImageUploadFieldProps> = props => {
  const { name } = props;
  const [, meta, helpers] = useField<string>(name);

  const onDrop = useCallback(files => {
    if (files.length) {
      helpers.setValue(files[0]);
    } else {
      toast.error(
        "Ocorreu um problema ao selecionar imagem, verique o tipo e tamanho para prosseguir."
      );
    }
  }, []);

  const { getRootProps, open } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 3 * 1024 * 1024,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    noClick: !!meta.value,
  });

  const handleDeleteFileUpload = () => {
    helpers.setValue("");
  };

  const openBrowserFile = () => {
    open();
  };

  const renderContent = useMemo(() => {
    if (meta.value) {
      const value = meta.value as any;

      const url = value instanceof File ? URL.createObjectURL(value) : value;

      return (
        <PhotoPreview
          url={url}
          handleOpenImageUploadUpdateModal={openBrowserFile}
          handleDelete={handleDeleteFileUpload}
        />
      );
    }

    return (
      <>
        <Styled.PhotoIcon />
        <Box mt={1}>
          <Typography variant="body2" color="primary">
            Adicionar foto
          </Typography>
        </Box>
      </>
    );
  }, [meta.value]);

  return (
    <div>
      <Styled.UploadImageButton type="button" {...getRootProps()}>
        {renderContent}
      </Styled.UploadImageButton>
      <Box display="flex" flexDirection="row-reverse" mt={1}>
        <Typography variant="body2" color="textSecondary">
          Máximo 3MB - PNG ou JPG ou JPEG
        </Typography>
      </Box>
    </div>
  );
};

export default ImageUploadField;
