import styled from "styled-components";

import ButtonUnstyled from "src/components/Button";

export const Button = styled(ButtonUnstyled)`
  height: 50px;
  svg {
    font-size: 25px;
    margin-right: 8px;
  }
`;
