import React from "react";

import Typography from "src/components/Typography";
import { PAGE_ORDER_TITLES } from "src/constants/orders";
import { useCompany } from "src/hooks/company/useCompany";

import { AditionalInfoTextAreaProps } from "./props";
import * as Styled from "./styles";

const AditionalInfoTextArea: React.FC<AditionalInfoTextAreaProps> = props => {
  const { orderType, disabled, value, ...rest } = props;
  const { serviceOrderNameDefinitions } = useCompany();

  if (disabled) {
    return (
      <Styled.ContainerTextArea>
        <Typography>Informações adicionais</Typography>
        <Styled.BasicTextArea
          name="additionalInfo"
          multiline
          rows={9}
          placeholder={
            PAGE_ORDER_TITLES(
              orderType === "serviceOrder"
                ? serviceOrderNameDefinitions
                : undefined
            )[orderType].observationField.detailLabel
          }
          disabled
          value={value}
        />
      </Styled.ContainerTextArea>
    );
  }

  return (
    <Styled.AditionalInfoTextArea
      name="additionalInfo"
      rows={4}
      placeholder={
        PAGE_ORDER_TITLES(
          orderType === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[orderType].observationField.formLabel
      }
      {...rest}
    />
  );
};

export default AditionalInfoTextArea;
