import React, { FC } from "react";

import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import Panel from "src/components/Layouts/Panel";
import { useFinancial } from "src/hooks/financial/useFinancial";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const GeneralBalanceCard: FC = () => {
  const { financialOverview } = useFinancial();
  return (
    <Grid item xs={12}>
      <Box width="100%" display="flex" gridGap={24} marginBottom={2}>
        <Panel
          width="50%"
          padding={2}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >
          <Styled.ArrowUpward />
          <Grid item>
            <Styled.CurrencyLabel>Receitas</Styled.CurrencyLabel>
            <Styled.CurrencyText>
              {formatPrice(financialOverview.income)}
            </Styled.CurrencyText>
          </Grid>
        </Panel>
        <Panel
          width="50%"
          padding={2}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >
          <Styled.ArrowDownward />
          <Grid item>
            <Styled.CurrencyLabel>Despesas</Styled.CurrencyLabel>
            <Styled.CurrencyText redCurrency>
              {formatPrice(financialOverview.expense)}
            </Styled.CurrencyText>
          </Grid>
        </Panel>
      </Box>
    </Grid>
  );
};

export default GeneralBalanceCard;
