import styled from "styled-components";

import ButtonUnstyled from "src/components/Button";

export const UpdateOrderButton = styled(ButtonUnstyled)`
  width: 130px;
  height: 50px;
  margin-right: 8px;
  svg {
    margin-right: 10px;
  }
`;
