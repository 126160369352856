import { Box, Text, TextProps } from "@chakra-ui/react";

import { TechnicalReportOrderType } from "src/interfaces/order";

export default function TechnicalReportFields({
  technicalReports,
}: {
  technicalReports: TechnicalReportOrderType[] | [];
}) {
  const reports = technicalReports[0];

  const CustomText = ({ children, ...props }: TextProps) => {
    return (
      <Text fontWeight={400} lineHeight={1.1} color="black" {...props}>
        {children}
      </Text>
    );
  };

  return (
    <Box w="100%">
      <CustomText fontSize="10px" mb={2}>
        Laudo Técnico
      </CustomText>

      {reports?.data.map(e =>
        e.content !== "" ? (
          <Box w="100%" key={e.id}>
            <CustomText fontSize="10px">{e.label}</CustomText>
            <CustomText fontSize="10px" my={1} ml={2}>
              {e.content}
            </CustomText>
          </Box>
        ) : null
      )}
    </Box>
  );
}
