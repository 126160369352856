import React, { useState, useMemo } from "react";

import { Divider, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Form, Formik } from "formik";

import Button from "src/components/Button";
import SelectField from "src/components/Inputs/SelectField";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Panel from "src/components/Layouts/Panel";
import Loader from "src/components/Loader";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import {
  DashboardProvider,
  useDashboard,
} from "src/hooks/dashboard/useDashboard";
import { getValidYears } from "src/utils/date";
import { withContext } from "src/utils/withContext";

import InformationInitialReportState from "../components/InformationInitialReportState";
import ExpensesChart from "./components/ExpensesChart";
import SummaryMounthDetail from "./components/SummaryMonthDetail";
import * as Styled from "./styled";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Previsto x Realizado" },
];

const YEARS = getValidYears();

const ACTUAL_YEAR = new Date().getFullYear();

const PredictedAndAccomplishedPage = () => {
  const { getFinancialResumeRecords, status, financialResumeRecords } =
    useDashboard();
  const [year, setYear] = useState(ACTUAL_YEAR);
  const [yearLegend, setYearLegend] = useState(ACTUAL_YEAR);

  const YEARS_ITEMS = YEARS.map(year => ({
    name: year.toString(),
    value: year,
  }));

  const handleGenerateReport = () => {
    setYearLegend(year);
    getFinancialResumeRecords(year);
  };

  const renderMounthDetail = useMemo(
    () =>
      financialResumeRecords.map(month => (
        <SummaryMounthDetail key={month.month} data={month} />
      )),
    [financialResumeRecords]
  );

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Previsto x Realizado" />
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-end"
        width="100%"
        style={{ gap: ".5rem" }}
      >
        <Styled.YearSelectorContainer>
          <Formik
            // eslint-disable-next-line no-console
            onSubmit={v => console.log(v)}
            initialValues={{
              year,
            }}
          >
            {({ values }) => (
              <Form>
                <SelectField
                  defaultValue={ACTUAL_YEAR}
                  label="Ano"
                  name="year"
                  items={YEARS_ITEMS}
                />
                {setYear(values.year)}
              </Form>
            )}
          </Formik>
        </Styled.YearSelectorContainer>
        <Button
          style={{ height: 56, marginLeft: 8 }}
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleGenerateReport}
        >
          Gerar relatório
        </Button>
      </Box>
      <Box my={3}>
        <Divider />
      </Box>

      {status.initial ? <InformationInitialReportState /> : null}
      {!status.initial && !status.loading ? (
        <>
          <Styled.Legend color="primary" variant="h6">
            De
            <strong> {yearLegend}</strong>
          </Styled.Legend>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
              >
                Receitas x Despesas
              </Typography>
              <ExpensesChart />
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
              >
                Previsto x Realizado - Detalhes
              </Typography>
              <Grid container>
                <Panel width="100%">{renderMounthDetail}</Panel>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}
      {status.loading ? (
        <Box height="100%">
          <Loader size="medium" />
        </Box>
      ) : null}
    </DashboardLayout>
  );
};

export default withContext(PredictedAndAccomplishedPage, DashboardProvider);
