import React, { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Box } from "@material-ui/core";

import Button from "src/components/Button";
import BottomBar from "src/components/Layouts/BottomBar";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import { CONTACT_LABELS } from "src/constants/contacts";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import { useContact } from "src/hooks/contact/useContact";
import {
  SubContactFieldType,
  SubContactType,
  SubContactTypeUpdate,
} from "src/interfaces/contact";

import SubContactForm from "../components/SubContactForm";

const UpdateSubContactPage = () => {
  const { subContactNameDefinitions } = useCompany();
  const {
    contact,
    subContactFields,
    updateSubContact,
    updateSubContactStatus,
  } = useContact();

  const { subContactId } = useParams<{ id: string; subContactId: string }>();
  const navigate = useNavigate();
  const state = useLocation().state as { data?: SubContactType };
  const subContact = state ? state.data : undefined;

  const goBack = () => {
    navigate(-1);
  };

  if (!state) {
    navigate("/", { replace: true });
  }

  if (updateSubContactStatus.success) {
    goBack();
  }

  const title = useMemo(() => {
    if (contact.type) {
      return CONTACT_LABELS[contact.type].detail.pageTitle;
    }

    return "";
  }, [contact]);

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
    { label: "Contatos", path: PATHS.CONTACTS_LIST },
    { label: title },
    { label: subContactNameDefinitions.title },
    { label: `Editar ${subContactNameDefinitions.title.toLowerCase()}` },
  ];

  const onSubmit = (data: any) => {
    const fieldsIds = Object.keys(data);

    const dataParsed = fieldsIds.map((fieldId: string) => {
      const field = subContactFields.find(
        field => field.id === fieldId
      ) as SubContactFieldType;

      return {
        referId: fieldId,
        label: field.label,
        type: field.type,
        content: data[fieldId],
        selectType: field.selectOptions?.type,
      };
    });

    updateSubContact(
      { data: dataParsed } as SubContactTypeUpdate,
      subContactId as string
    );
  };

  return (
    <DashboardLayout selected="register" subSelected="contacts">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <PageTitle
        label={`Editar ${subContactNameDefinitions.title.toLowerCase()}`}
      />
      <SubContactForm onSubmit={onSubmit} subContact={subContact} />
      <BottomBar>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          form="subContactForm"
          disabled={updateSubContactStatus.loading}
          loading={updateSubContactStatus.loading}
        >
          Salvar
        </Button>

        <Box width={20} />

        <Button color="primary" variant="outlined" onClick={goBack}>
          Cancelar
        </Button>
      </BottomBar>
    </DashboardLayout>
  );
};

export default UpdateSubContactPage;
