import React, { FC, useMemo, useState, useEffect } from "react";

import { useFormikContext } from "formik";

import TextField from "src/components/Inputs/TextField";
import { ContactType } from "src/interfaces/contact";
import { maskDocument } from "src/utils/format";

const initialValue = (values: Partial<ContactType>) => {
  if (values.cpf) {
    return maskDocument(values.cpf);
  }

  if (values.cnpj) {
    return maskDocument(values.cnpj);
  }

  return "";
};

const DocumentField: FC = () => {
  const { values, setFieldValue, getFieldMeta } =
    useFormikContext<Partial<ContactType>>();

  const [value, setValue] = useState(initialValue(values));

  const name = useMemo(() => (value.length <= 14 ? "cpf" : "cnpj"), [value]);

  const meta = getFieldMeta(name);

  useEffect(() => {
    if (name === "cpf") {
      setFieldValue("cpf", value);
      setFieldValue("cnpj", "");
    }
    if (name === "cnpj") {
      setFieldValue("cpf", "");
      setFieldValue("cnpj", value);
    }
  }, [name, value]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(maskDocument(e.target.value));
  };

  const errorProps = useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      };
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      };
    }
    return {};
  }, [meta.error, meta.initialError, meta.touched]);

  return (
    <TextField
      {...errorProps}
      label="CPF/CNPJ"
      name={name}
      value={value}
      onChange={onChange}
      disableFormik
      inputProps={{
        maxLength: 18,
      }}
    />
  );
};

export default DocumentField;
