import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@material-ui/core/Box";

import Button from "src/components/Button";
import BottomBar from "src/components/Layouts/BottomBar";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Panel from "src/components/Layouts/Panel";
import PATHS from "src/constants/paths";
import { ServiceProvider, useService } from "src/hooks/service/useService";
import { getRouteString } from "src/utils/urlHandler";
import { withContext } from "src/utils/withContext";

import ServiceForm from "../components/ServiceForm";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Serviços", path: PATHS.SERVICES_LIST },
  { label: "Criação do serviço" },
];

const ServiceAdd: React.FC = () => {
  const { status, service } = useService();

  const navigate = useNavigate();

  useEffect(() => {
    if (status.success) {
      navigate(getRouteString(PATHS.SERVICES_DETAIL, service.id));
    }
  }, [status]);

  return (
    <DashboardLayout selected="register" subSelected="services">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <PageTitle label="Criação do serviço" />

      <Panel padding={4}>
        <ServiceForm method="add" />
      </Panel>

      <BottomBar>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          form="form-service"
          disabled={status.loading}
        >
          Criar
        </Button>

        <Box width={20} />

        <Button color="primary" variant="outlined" to={PATHS.SERVICES_LIST}>
          Cancelar
        </Button>
      </BottomBar>
    </DashboardLayout>
  );
};

export default withContext(ServiceAdd, ServiceProvider);
