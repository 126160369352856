import React, { FC } from "react";

import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import cashIcon from "src/assets/icons/financialAccounts/cash.svg";
import walletIcon from "src/assets/icons/financialAccounts/wallet.svg";
import Panel from "src/components/Layouts/Panel";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import { useFinancial } from "src/hooks/financial/useFinancial";
import { FinancialAccountTypeEnum } from "src/interfaces/financialAccount";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const SectionAccountsCard: FC = () => {
  const { financialOverview } = useFinancial();

  const logo = (
    accountType: keyof typeof FinancialAccountTypeEnum,
    logo?: string
  ) => {
    if (accountType === "wallet") return walletIcon;
    if (accountType === "cash") return cashIcon;

    return logo;
  };

  return (
    <Grid item xs={12}>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1" color="textSecondary">
          Contas
        </Typography>
        <Styled.Link to={PATHS.FINANCIAL_ACCOUNTS}>
          Gerenciar contas
        </Styled.Link>
      </Grid>

      <Panel
        display="flex"
        alignItems="flex-start"
        padding={2}
        marginBottom={2}
        marginTop={1}
        flexWrap="wrap"
      >
        <Grid item xs={12} lg={7}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Styled.BalanceValue>
              {formatPrice(financialOverview?.accounts?.total)}
            </Styled.BalanceValue>
            <Typography color="textSecondary">Saldo em contas</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Box width="100%" overflow="auto" height="100px">
            {financialOverview.accounts?.results.map(account => {
              return (
                <Box
                  width="100%"
                  key={account.id}
                  display="flex"
                  alignItems="center"
                  marginBottom={1}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mr={1}
                  >
                    {account.showSum ? null : (
                      <Styled.Tooltip
                        classes={{
                          tooltip: "custom__tooltip",
                        }}
                        title={
                          <>
                            <Box
                              component="span"
                              fontSize="15px"
                              fontWeight="500"
                              color="#fff"
                            >
                              Conta não somada.{" "}
                            </Box>
                            <Box
                              component="span"
                              fontSize="15px"
                              fontWeight="400"
                              color="#d6d8dc"
                            >
                              Na conta, você escolheu não somar o saldo total,
                              por isso essa conta não está somando.
                            </Box>
                          </>
                        }
                        placement="bottom-end"
                        arrow
                      >
                        <Styled.TooltipIcon color="primary" />
                      </Styled.Tooltip>
                    )}
                    <Styled.AccountLogo
                      src={logo(account.type, account.institution?.image)}
                    />
                    <Styled.AccountName>{account.name}</Styled.AccountName>
                  </Box>
                  <Box flexGrow={1}>
                    <Styled.AccountBalance>
                      {formatPrice(account.balance)}
                    </Styled.AccountBalance>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Panel>
    </Grid>
  );
};

export default SectionAccountsCard;
