import { ProductMeasureType } from "src/interfaces/product";
import { ServiceMeasureType } from "src/interfaces/service";

type DictionaryProductMeasureType = {
  [key in ProductMeasureType]: string;
};

type DictionaryServiceMeasureType = {
  [key in ServiceMeasureType]: string;
};

export const PRODUCTS_MEASURES: DictionaryProductMeasureType = {
  un: "Unitário (un)",
  m: "Metro (m)",
  m2: "Metro quadrado (m2)",
  ml: "Milimetro (ml)",
  l: "Litro (l)",
  g: "Grama (g)",
  kg: "Quilo (kg)",
  mg: "Miligrama (MG)",
};

export const PRODUCTS_MEASURES_SHORT: DictionaryProductMeasureType = {
  un: "un",
  m: "m",
  m2: "m2",
  ml: "ml",
  l: "l",
  g: "g",
  kg: "kg",
  mg: "MG",
};

export const SERVICES_MEASURES: DictionaryServiceMeasureType = {
  un: "Unitário (un)",
  h: "Hora (h)",
  d: "Dia (d)",
  w: "Semana (s)",
  me: "Mês (me)",
  m: "Metro (m)",
  m2: "Metro quadrado (m2)",
};

export const SERVICES_MEASURES_SHORT: DictionaryServiceMeasureType = {
  un: "un",
  h: "h",
  d: "d",
  w: "s",
  me: "me",
  m: "m",
  m2: "m2",
};
