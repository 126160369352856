import React from "react";

import { Box, Text, Divider } from "@chakra-ui/react";

import PermissionsAbility from "./components/PermissionsAbility";

export function CashFlowPermissions() {
  return (
    <Box>
      <Text fontSize="sm" color="gray.500">
        Gerenciar ações
      </Text>

      <Divider my={4} />

      <Text fontSize="sm" color="gray.500">
        Caixa
      </Text>

      <PermissionsAbility
        permission="cashFlow"
        title="Gerenciar caixa"
        subtitle="O usuário precisa de um caixa aberto para realizar operações financeiras"
      />
    </Box>
  );
}
