import { toast } from "react-toastify";

import { Button, Flex, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { CompanyModule } from "src/interfaces/module";
import { useAddModuleCompany } from "src/services/hooks/modules/useAddModuleCompany";

import ModalDeleteModules from "../ModalDeleteModules";

interface IDetailModules {
  dataModules: CompanyModule | undefined;
}

export default function DetailModules({ dataModules }: IDetailModules) {
  const { handleClose, handleOpen } = useBaseSwipeableDrawer();
  const { user, setUser } = useAuth();

  const queryClient = useQueryClient();
  const { mutate: addModuleCompany, isLoading } = useAddModuleCompany({
    onSuccess: module => {
      queryClient.invalidateQueries({
        queryKey: ["modulesCompany"],
      });

      const userData = {
        ...user,
        company: {
          ...user.company,
          modules: [...user.company.modules, module],
        },
      };

      setUser(userData);

      toast.success("Módulo instalado");
      handleClose("ModuleDetail");
    },
  });

  const addModule = () => {
    addModuleCompany({ id: dataModules?.id as string });
  };

  const customHeader = (
    <Flex alignItems="center" gap={2} color="primary.500" w="80%">
      {dataModules?.name}
    </Flex>
  );

  const buttonActions = () => {
    if (dataModules?.isActive) {
      return (
        <>
          <a
            href={dataModules?.helpCenter ?? "https://ajuda.azulou.com.br/"}
            target="_blank"
            rel="noreferrer"
          >
            <Button w="full" variant="outline">
              Central de Ajuda
            </Button>
          </a>
          <Button
            colorScheme="error"
            w="full"
            variant="outline"
            onClick={() => [
              handleClose("ModuleDetail"),
              handleOpen("deleteModule"),
            ]}
          >
            Desinstalar
          </Button>
          {/* <Button w="full">Acessar Modulo </Button> */}
        </>
      );
    }
    if (!dataModules?.isAvailable) {
      return (
        <>
          <a
            href={dataModules?.helpCenter ?? "https://ajuda.azulou.com.br/"}
            target="_blank"
            rel="noreferrer"
          >
            <Button w="full" variant="outline">
              Central de Ajuda
            </Button>
          </a>
        </>
      );
    }

    return (
      <>
        <a
          href={dataModules?.helpCenter ?? "https://ajuda.azulou.com.br/"}
          target="_blank"
          rel="noreferrer"
        >
          <Button w="full" variant="outline">
            Central de Ajuda
          </Button>
        </a>

        <Button w="full" onClick={() => addModule()} isLoading={isLoading}>
          Instalar
        </Button>
      </>
    );
  };

  const customActions = (
    <Flex direction="column" gap={4} w="100%">
      {buttonActions()}
    </Flex>
  );

  return (
    <>
      <BaseSwipeableDrawer
        tag="ModuleDetail"
        zIndex={1302}
        customHeader={customHeader}
        customActions={customActions}
        customHandleClose={() => handleClose("ModuleDetail")}
      >
        <Flex
          id="ModuleDetail"
          w={["100%", "350px"]}
          bg="white"
          pt={4}
          borderRadius={10}
          border="solid 1px gray-200"
          gap={4}
          direction="column"
        >
          <Text color="primary.500">Funcionalidade</Text>
          <Text color="gray">{dataModules?.description}</Text>
          {/* <Text color="primary.500">Como acessar o módulo</Text>
          <Text color="gray">{dataModules?.description}</Text> */}
        </Flex>
      </BaseSwipeableDrawer>
      <ModalDeleteModules idModule={dataModules?.id} />
    </>
  );
}
