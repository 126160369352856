import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { SchedulingType } from "src/interfaces/scheduling";
import api from "src/services/api";

export interface UpdateScheduleParams {
  title: string;
  startDate: string;
  description?: string;
  place?: string;
}

export const updateSchedule = (params: UpdateScheduleParams) =>
  api.put<SchedulingType>(`v1/scheduling`, params).then(res => res.data);

export function useUpdateSchedule(
  config?: UseMutationOptions<any, any, UpdateScheduleParams, unknown>
) {
  return useMutation<any, any, UpdateScheduleParams>(
    (data: UpdateScheduleParams) => updateSchedule(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ??
            "Ocorreu um problema ao atualizar lançamento"
        );
      },
      ...config,
    }
  );
}
