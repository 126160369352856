import {
  createContext,
  useContext,
  useMemo,
  useState,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
} from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { useModule } from "src/hooks/module/useModule";
import { OrderDetailType, TypeOfOrder } from "src/interfaces/order";
import { OrderDataSchema } from "src/pages/Orders/CreateOrder/components/OrderDataForm";
import { orderDataSchema } from "src/pages/Orders/CreateOrder/components/OrderDataForm/schema";
import { OrderItemsSchema } from "src/pages/Orders/CreateOrder/components/OrderItemsForm";
import { orderItemsSchema } from "src/pages/Orders/CreateOrder/components/OrderItemsForm/schema";
import { OrderPaymentSchema } from "src/pages/Orders/CreateOrder/components/OrderPaymentForm";
import { orderPaymentSchema } from "src/pages/Orders/CreateOrder/components/OrderPaymentForm/schema";

import { OrderForm, ProductOrderForm, ServiceOrderForm } from "./props";

export type OrderFormProviderProps = {
  type: TypeOfOrder | undefined;
  children: ReactNode;
  isDirectSale?: boolean;
};

export type OrderFormContextProps = {
  order: Partial<OrderForm> | null;
  setOrder: Dispatch<SetStateAction<Partial<OrderForm> | null>>;
  orderCreated: OrderDetailType;
  setOrderCreated: Dispatch<SetStateAction<OrderDetailType>>;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  availableSteps: number[];
  setAvailableSteps: Dispatch<SetStateAction<number[]>>;
  handleToNextStep(): void;
  handleToPreviousStep(): void;
  handleReset(): void;
  subtotal: number;
  methodsOrderItems: UseFormReturn<OrderItemsSchema>;
  methodsOrderData: UseFormReturn<OrderDataSchema>;
  methodsOrderPayment: UseFormReturn<OrderPaymentSchema>;
  type: TypeOfOrder;
  setType: Dispatch<SetStateAction<TypeOfOrder | undefined>>;
  isDirectSale: boolean;
};

export const OrderFormContext = createContext({} as OrderFormContextProps);

export function OrderFormProvider({
  type: typeProp,
  isDirectSale = false,
  children,
}: OrderFormProviderProps) {
  const { hasModules, serviceOrderModule } = useModule();

  const [type, setType] = useState(typeProp);

  const serviceRequired =
    serviceOrderModule?.settings?.serviceRequired ?? false;

  const [availableSteps, setAvailableSteps] = useState([0]);
  const [currentStep, setCurrentStep] = useState(0);

  const [orderCreated, setOrderCreated] = useState({} as OrderDetailType);
  const [order, setOrder] = useState<Partial<OrderForm> | null>(null);

  const methodsOrderData = useForm<OrderDataSchema>({
    defaultValues: {
      createdDate: new Date().toISOString(),
    },
    resolver: yupResolver(orderDataSchema),
  });

  const methodsOrderItems = useForm<OrderItemsSchema>({
    defaultValues: {
      type,
      serviceIsRequired: serviceRequired,
    },
    resolver: yupResolver(orderItemsSchema),
  });

  const methodsOrderPayment = useForm<OrderPaymentSchema>({
    defaultValues: {
      conditionType: "A Vista",
      paymentType: "A Vista",
      downPaymentType: "percentage",
      downPaymentValue: "100",
      hasAccountManagement: hasModules("accountManagement"),
      hasDiscount: false,
    },
    resolver: yupResolver(orderPaymentSchema),
  });

  function handleToNextStep() {
    const nextStep = currentStep + 1;

    const newAvailableSteps = Array.from(
      new Set([...availableSteps, nextStep])
    );

    setAvailableSteps(newAvailableSteps);

    setCurrentStep(nextStep);
  }

  function handleToPreviousStep() {
    if (currentStep > 0) {
      setCurrentStep(currentStep => currentStep - 1);
    }
  }

  useEffect(() => {
    if (isDirectSale && type !== undefined) {
      methodsOrderItems.reset({
        products: [],
        services: [],
        serviceIsRequired: serviceRequired,
        type,
      });
    }
  }, [type]);

  function handleReset() {
    methodsOrderItems.reset({
      products: [],
      services: [],
      serviceIsRequired: serviceRequired,
      type,
    });

    methodsOrderData.reset({
      customer: undefined,
      subContact: undefined,
      operator: undefined,
      additionalInfo: "",
      deadline: undefined,
      createdDate: undefined,
      technicalReport: undefined,
    });

    methodsOrderPayment.reset({
      conditionType: "A Vista",
      paymentType: "A Vista",
      downPaymentType: "percentage",
      downPaymentValue: "100",
      hasAccountManagement: hasModules("accountManagement"),
      hasDiscount: false,
    });

    setOrder(null);
    setOrderCreated({} as OrderDetailType);
    setCurrentStep(0);
    setAvailableSteps([0]);
    if (isDirectSale) {
      setType(undefined);
    }
  }

  const subtotal = useMemo(() => {
    const totalProducts =
      order?.products?.reduce((acc, productSchema) => {
        const product = productSchema as ProductOrderForm;
        return acc + product.price * product.quantity;
      }, 0) ?? 0;
    const totalServices =
      order?.services?.reduce((acc, serviceSchema) => {
        const service = serviceSchema as ServiceOrderForm;
        return acc + service.price * service.quantity;
      }, 0) ?? 0;

    return totalProducts + totalServices;
  }, [order]);

  const providerValues = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      availableSteps,
      setAvailableSteps,
      order,
      setOrder,
      handleToNextStep,
      handleToPreviousStep,
      subtotal,
      orderCreated,
      setOrderCreated,
      methodsOrderData,
      methodsOrderItems,
      methodsOrderPayment,
      handleReset,
      type: type as TypeOfOrder,
      setType,
      isDirectSale,
    }),
    [
      currentStep,
      setCurrentStep,
      availableSteps,
      setAvailableSteps,
      order,
      setOrder,
      handleToNextStep,
      handleToPreviousStep,
      subtotal,
      orderCreated,
      setOrderCreated,
      methodsOrderData,
      methodsOrderItems,
      methodsOrderPayment,
      handleReset,
      type,
      setType,
      isDirectSale,
    ]
  );

  return (
    <OrderFormContext.Provider value={providerValues}>
      {children}
    </OrderFormContext.Provider>
  );
}

export function useOrderForm() {
  const context = useContext(OrderFormContext);

  if (context) {
    return context;
  }

  throw new Error("useOrderForm must be used within a OrderFormProvider");
}
