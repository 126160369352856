import { useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { Avatar, Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { CurrencyInput } from "src/components/NewComponents/CurrencyInput";
import { Input } from "src/components/NewComponents/Input";
import {
  ProductOrderForm,
  ServiceOrderForm,
} from "src/hooks/order/useOrderForm/props";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";
import { formatDecimalInput } from "src/utils/format";

import { orderItemsModalSchema } from "./schema";

export type OrderItemsModalSchema = yup.InferType<typeof orderItemsModalSchema>;

function OrderItemsModal() {
  const { methodsOrderItems, setOrder } = useOrderForm();
  const { handleClose } = useBaseSwipeableDrawer();

  const handleCustomClose = () => {
    handleClose("orderItemsModal");
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OrderItemsModalSchema>({
    defaultValues: methodsOrderItems.getValues(),
    resolver: yupResolver(orderItemsModalSchema),
  });

  const { fields: products } = useFieldArray({
    control,
    name: "products",
    keyName: undefined,
  });

  const { fields: services } = useFieldArray({
    control,
    name: "services",
    keyName: undefined,
  });

  const onSubmit = (data: OrderItemsModalSchema) => {
    setOrder(prev => ({
      ...(prev && prev),
      products: data.products as ProductOrderForm[],
      services: data.services as ServiceOrderForm[],
    }));
    methodsOrderItems.reset(data);
    handleCustomClose();
  };

  const baseActions = useMemo(() => {
    return (
      <>
        <Box width="100%" mb={2}>
          <Button w="100%" type="submit" form="orderItemsModalForm">
            Salvar
          </Button>
        </Box>
      </>
    );
  }, []);

  return (
    <BaseSwipeableDrawer
      tag="orderItemsModal"
      title="Itens da venda"
      customActions={baseActions}
      customHandleClose={handleCustomClose}
    >
      <Flex
        w={["100%", "350px"]}
        bg="white"
        borderRadius={10}
        border="solid 1px gray-200"
        direction="column"
        as="form"
        id="orderItemsModalForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text fontSize="sm" opacity=".7" fontWeight={500}>
          Abaixo estão os itens da sua venda
        </Text>
        <Divider mt={2} mb={6} />

        {services?.length ? (
          <>
            <Text color="primary.500" mb={6}>
              Serviços
            </Text>
            <Flex flexDirection="column" gap={4}>
              {services?.map((serviceOrder, index) => {
                return (
                  <Flex key={`${Math.random()}${index.toString()}`} gap={2}>
                    <Avatar
                      size="lg"
                      w="120px"
                      h="144px"
                      name={serviceOrder.service?.name}
                      borderRadius={10}
                      src={serviceOrder.service?.image}
                    />
                    <Flex flex={1} flexDirection="column" gap={3}>
                      <Text color="primary.500" fontWeight={400} noOfLines={1}>
                        {serviceOrder.service?.name}
                      </Text>

                      <Input
                        error={
                          errors.services
                            ? errors.services[index]?.quantity
                            : undefined
                        }
                        label="Quantidade"
                        autoComplete="off"
                        {...register(`services.${index}.quantity`, {
                          onChange: e => {
                            e.target.value = formatDecimalInput({
                              value: e.target.value,
                              beforeRange: 9,
                              range: 2,
                            });
                          },
                          onBlur: e => {
                            e.target.value = formatDecimalInput({
                              value: e.target.value,
                              beforeRange: 9,
                              range: 2,
                            });
                          },
                        })}
                      />
                      <CurrencyInput
                        error={
                          errors.services
                            ? errors.services[index]?.price
                            : undefined
                        }
                        label="Valor"
                        control={control}
                        name={`services.${index}.price`}
                      />
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </>
        ) : null}

        {products?.length && services?.length ? <Divider my={4} /> : null}

        {products?.length ? (
          <>
            <Text color="primary.500" mb={6}>
              Produtos
            </Text>
            <Flex flexDirection="column" gap={4}>
              {products?.map((productOrder, index) => {
                return (
                  <Flex key={`${Math.random()}${index.toString()}`} gap={2}>
                    <Avatar
                      size="lg"
                      w="120px"
                      h="144px"
                      name={productOrder.product?.name}
                      borderRadius={10}
                      src={productOrder.product?.image}
                    />
                    <Flex flex={1} flexDirection="column" gap={3}>
                      <Text color="primary.500" fontWeight={400} noOfLines={1}>
                        {productOrder.product?.name}
                      </Text>

                      <Input
                        error={
                          errors.products
                            ? errors.products[index]?.quantity
                            : undefined
                        }
                        label="Quantidade"
                        autoComplete="off"
                        {...register(`products.${index}.quantity`, {
                          onChange: e => {
                            e.target.value = formatDecimalInput({
                              value: e.target.value,
                              beforeRange: 9,
                              range: 2,
                            });
                          },
                          onBlur: e => {
                            e.target.value = formatDecimalInput({
                              value: e.target.value,
                              beforeRange: 9,
                              range: 2,
                            });
                          },
                        })}
                      />
                      <CurrencyInput
                        error={
                          errors.products
                            ? errors.products[index]?.price
                            : undefined
                        }
                        label="Valor"
                        control={control}
                        name={`products.${index}.price`}
                      />
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </>
        ) : null}
      </Flex>
    </BaseSwipeableDrawer>
  );
}

export default OrderItemsModal;
