import React, { useMemo, useRef } from "react";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";

import { Box, Text } from "@chakra-ui/react";

import { ProductType } from "src/interfaces/product";

export const usePrintBarCodeLabels = (
  products: Array<ProductType>
): {
  PrintBarCodeLabelsComponent: JSX.Element;
  handlePrint: () => void;
} => {
  const componentRef = useRef(null);

  const PrintBarCodeLabelsComponent = useMemo(() => {
    return (
      <Box display="none">
        <Box ref={componentRef} bg="gray.200">
          {products.map(product => {
            return (
              <Box
                bg="white"
                border="1px solid black"
                borderRadius="10px"
                p={1}
                w="calc(20% - 4px)"
                height="150px"
                key={product.id}
                sx={{
                  pageBreakInside: "avoid",
                  float: "left",
                  mx: "2px",
                  my: "1px",
                }}
              >
                <Text textAlign="center" color="black" noOfLines={2}>
                  {product.name}
                </Text>
                <Box
                  mt={2}
                  w="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                >
                  {product.barCode ? (
                    <Barcode
                      value={product.barCode ?? ""}
                      width={1}
                      height={70}
                      margin={0}
                      fontSize={14}
                      format="CODE128"
                    />
                  ) : (
                    <Text fontSize="sm" textAlign="center" color="black">
                      SEM CÓDIGO DE BARRAS
                    </Text>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }, [products]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return { PrintBarCodeLabelsComponent, handlePrint: () => handlePrint() };
};
