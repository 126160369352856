import {
  ContactFormType,
  ContactListParamType,
  SubContactsParams,
  SubContactsFormData,
  SubContactTypeUpdate,
} from "src/interfaces/contact";
import api from "src/services/api";

/**
 * Cadastrar contato
 */
export const addContact = (params: ContactFormType) =>
  api.post("v1/contact", params).then(res => res.data);

/**
 * Cadastrar contato
 */
export const addSubContactService = (params: SubContactsFormData) =>
  api.post("v1/subcontact", params).then(res => res.data);

/**
 * Deletar sub contato
 */
export const removeSubContactService = (id: string) =>
  api.delete(`v1/subcontact/${id}`).then(res => res);

/**
 * Update contato
 */
export const updateSubContactService = (
  data: SubContactTypeUpdate,
  subContactId: string
) => api.put(`v1/subcontact/${subContactId}`, data).then(res => res.data);

/**
 * Listar contatos
 */
export const getContacts = (params: ContactListParamType) =>
  api
    .get("v2/contact", {
      params,
    })
    .then(res => res.data);

/**
 * Encontrar contato pelo Id
 */
export const getContact = (id: string) =>
  api.get(`v1/contact/${id}`).then(res => res.data);

/**
 * Deletar contato
 */
export const deleteContact = (id: string) =>
  api
    .delete("v1/contact", {
      params: {
        id,
      },
    })
    .then(res => res);

/**
 * Atualizar contato
 */
export const updateContact = (params: ContactFormType) =>
  api.put("v1/contact", params).then(res => res.data);

/**
 * Listar campos de subcontato
 */
export const getSubContactFieldsService = () =>
  api.get(`v1/subcontact-field`).then(res => res.data);

/**
 * Listar de sub contatos
 */
export const getSubContactsService = (params: SubContactsParams) =>
  api.get(`v1/subcontact`, { params }).then(res => res.data);
