import React, { useMemo } from "react";
import Zoom from "react-medium-image-zoom";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import RenderModule from "src/components/RenderModule";
import Typography from "src/components/Typography";
import { useOrder } from "src/hooks/order/useOrder";
import { uppercaseFirstLetter } from "src/utils/format";

import "react-medium-image-zoom/dist/styles.css";

import * as Styled from "./styles";

const TechnicalReportCard: React.FC = () => {
  const { order } = useOrder();

  const technicalReport = order.technicalReports?.length
    ? [...order.technicalReports].shift()
    : undefined;

  const renderGallery = useMemo(() => {
    if (technicalReport?.gallery) {
      return technicalReport.gallery.map(item => (
        <Grid item xs={6} key={`image-${item.imageUrl}`}>
          <Zoom>
            <Styled.PreviewPhoto src={item.imageUrl} />
          </Zoom>
          <Typography>{item.name}</Typography>
        </Grid>
      ));
    }

    return null;
  }, [technicalReport]);

  const technicalReportDataGrid = useMemo(
    () => (technicalReport?.gallery?.length === 0 ? 12 : 6),
    [technicalReport]
  );

  const renderTextFields = useMemo(() => {
    if (technicalReport?.data?.length) {
      return technicalReport.data
        .filter(item => item.type === "text")
        .map(field => (
          <Grid item xs={6} key={field.label}>
            <Box>
              <Typography variant="subtitle2">
                {uppercaseFirstLetter(field.label)}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {field.content === "" ? "-" : field.content}
              </Typography>
            </Box>
          </Grid>
        ));
    }

    return null;
  }, [technicalReport]);

  const renderTextAreas = useMemo(() => {
    if (technicalReport?.data?.length) {
      return technicalReport.data
        .filter(item => item.type === "textarea")
        .map(field => (
          <Grid item xs={12} key={field.label}>
            <Box>
              <Typography variant="subtitle2">
                {uppercaseFirstLetter(field.label)}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {field.content === "" ? "-" : field.content}
              </Typography>
            </Box>
          </Grid>
        ));
    }

    return null;
  }, [technicalReport]);

  if (!technicalReport) {
    return null;
  }

  return (
    <RenderModule modules={["technicalReport"]}>
      <Grid item xs={12}>
        <Styled.Container>
          <Typography>Laudo Técnico</Typography>
          <Box mt={2} />
          <Grid container spacing={3}>
            <Grid item xs={technicalReportDataGrid}>
              <Grid container spacing={1}>
                {renderTextFields}
                {renderTextAreas}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>{renderGallery}</Grid>
            </Grid>
          </Grid>
        </Styled.Container>
      </Grid>
    </RenderModule>
  );
};

export default TechnicalReportCard;
