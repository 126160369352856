import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { UserType } from "src/interfaces/user";
import api from "src/services/api";
import { getToken } from "src/services/getToken";

export const useMe = (
  config?: UseQueryOptions<UserType, Error, UserType, unknown[]>
) => {
  const queryResponse = useQuery<UserType, Error, UserType, unknown[]>(
    ["me"],
    getMe,
    {
      enabled: Boolean(getToken()),
      staleTime: 1000 * 60 * 10, // 30 seconds
      retry: false,
      refetchOnWindowFocus: false,
      ...(config || {}),
    }
  );

  return queryResponse;
};

export async function getMe() {
  const { data } = await api.get<UserType>("/v1/user/sync");

  return data;
}
