import React from "react";
import { toast } from "react-toastify";

import { CompleteSignUpType, UserToAddType } from "src/interfaces/user";
import {
  checkEmail,
  checkNumber,
  completeSignUp,
  signUp,
  SMSConfirm,
  SMSResend,
} from "src/services/signup";
import { extractNumbers } from "src/utils/extractNumbers";

// import { useAuth } from "../auth/useAuth";
import {
  CreateAccountContextProps,
  CreateAccountActions,
  CreateAccountSectionsType,
  RequestSignUpType,
} from "./props";
import createAccountReducer, { initialState } from "./reducer";

const CreateAccountContext = React.createContext(
  {} as CreateAccountContextProps
);

const CreateAccountProvider: React.FC = props => {
  const { children } = props;
  const [agreeTerms, setAgreeTerms] = React.useState(false);

  // const { setUserData } = useAuth();

  const [state, dispatch] = React.useReducer(
    createAccountReducer,
    initialState
  );

  React.useEffect(() => {
    if (state.status.signUp.ready && !state.token) {
      signUpRequest();
    }
    if (state.status.completeSignUp.ready && state.user.status === "pending") {
      CompleteSignUpRequest();
    }
  }, [state.status]);

  const setSection = (payload: CreateAccountSectionsType) => {
    dispatch({ type: CreateAccountActions.SetSectionSignup, payload });
  };

  const signUpRequest = async () => {
    try {
      dispatch({ type: CreateAccountActions.RequestSignUp });

      const response: RequestSignUpType = await signUp({
        ...state.userToAdd,
        phoneNumber: extractNumbers(state.userToAdd.phoneNumber as string),
        passwordConfirmation: undefined,
        agreeTerms: undefined,
      });

      // setUserData(response);

      dispatch({
        type: CreateAccountActions.RequestSignUpSuccess,
        payload: response,
      });

      setSection("pincode");
    } catch (e) {
      dispatch({ type: CreateAccountActions.RequestSignUpError });
    }
  };

  const SMSConfirmRequest = async (code: string) => {
    try {
      dispatch({ type: CreateAccountActions.RequestSMSConfirm });

      await SMSConfirm(code);

      dispatch({
        type: CreateAccountActions.RequestSMSConfirmSuccess,
      });

      setSection("knowingBetter");
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch({ type: CreateAccountActions.RequestSMSConfirmError });
    }
  };

  const SMSResendRequest = async () => {
    try {
      dispatch({ type: CreateAccountActions.RequestSMSResend });

      await SMSResend();

      dispatch({
        type: CreateAccountActions.RequestSMSResendSuccess,
      });
    } catch (e) {
      dispatch({ type: CreateAccountActions.RequestSMSResendError });
    }
  };

  const CompleteSignUpRequest = async () => {
    try {
      dispatch({ type: CreateAccountActions.RequestCompleteSignUp });

      const response: RequestSignUpType = await completeSignUp({
        ...state.completeSignUp,
        companyCNPJ: extractNumbers(state.completeSignUp.companyCNPJ as string),
        companyCPF: extractNumbers(state.completeSignUp.companyCPF as string),
        companyCEP: extractNumbers(state.completeSignUp.companyCEP as string),
        companyPhone: extractNumbers(
          state.completeSignUp.companyPhone as string
        ),
      });

      // setUserData(response);

      dispatch({
        type: CreateAccountActions.RequestCompleteSignUpSuccess,
        payload: response,
      });

      setSection("success");
    } catch (e) {
      dispatch({ type: CreateAccountActions.RequestCompleteSignUpError });
    }
  };

  const setAccountData = async (payload: Partial<UserToAddType>) => {
    try {
      if (payload.email) {
        await checkEmail(payload.email);
      }

      if (payload.phoneNumber) {
        await checkNumber(extractNumbers(payload.phoneNumber));
      }

      dispatch({
        type: CreateAccountActions.SetAccountData,
        payload,
      });
      setSection("createPassword");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const setPasswordData = (payload: Partial<UserToAddType>) => {
    dispatch({
      type: CreateAccountActions.setPasswordData,
      payload,
    });
  };

  const setCompleteSignUpData = (
    payload: Partial<CompleteSignUpType>,
    ready?: boolean
  ) => {
    dispatch({
      type: CreateAccountActions.SetCompleteSignUpData,
      payload: {
        data: payload,
        ready,
      },
    });
  };

  const resetState = React.useCallback(
    () => dispatch({ type: CreateAccountActions.Reset }),
    []
  );

  return (
    <CreateAccountContext.Provider
      value={{
        ...state,
        setSection,
        agreeTerms,
        setAgreeTerms,
        setAccountData,
        setPasswordData,
        SMSConfirmRequest,
        SMSResendRequest,
        setCompleteSignUpData,
        resetState,
      }}
    >
      {children}
    </CreateAccountContext.Provider>
  );
};

export const useCreateAccount = () => React.useContext(CreateAccountContext);

export default CreateAccountProvider;
