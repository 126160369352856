import { PasswordResetRequestType } from "src/interfaces/password";
import {
  UpdatePasswordRequestType,
  UpdateUserRequestType,
  UserToAddType,
  UserListParamType,
} from "src/interfaces/user";
import api from "src/services/api";

/**
 * Atualiza informações básicas do usuário
 */
export const updateUser = (params: UpdateUserRequestType) =>
  api.put("v1/user/update", params).then(res => res.data);

/**
 * Atualiza senha do usuário
 */
export const updatePassword = (params: UpdatePasswordRequestType) =>
  api.post("v1/user/update-password", params).then(res => res.data);

/**
 * Envia SMS para número recuperar a senha
 */
export const SMSPasswordResetRequest = (phoneNumber: string) => {
  api
    .post("v1/user/password-reset/sms", {
      phoneNumber,
    })
    .then(res => res.data);
};

/**
 * Reseta a senha do usuário
 */
export const passwordResetRequest = (params: PasswordResetRequestType) =>
  api.post("v1/user/password-reset", params).then(res => res.data);

/**
 * Listar registros
 */
export const getUsersService = (params: UserListParamType) =>
  api
    .get("v1/company/user", {
      params,
    })
    .then(res => res.data);

/**
 * Criar usuário a partir de configurações
 */
export const createUserService = (params: Partial<UserToAddType>) =>
  api.post("v1/user/create", params).then(res => res.data);

/**
 * Criar usuário a partir de configurações
 */
export const updateUserService = (params: Partial<UserToAddType>) =>
  api
    .put(`v1/user/restricted/${params.id}/update`, {
      ...params,
      userId: params.id,
    })
    .then(res => res.data);

/**
 * Deletar usuário
 */
export const deleteUserService = (id: string) =>
  api.delete(`/v1/user/restricted/${id}/delete `).then(res => res);

/**
 * Ativar usuário
 */
export const activeUserService = (id: string) =>
  api.patch(`/v1/user/restricted/${id}/enable `).then(res => res);
