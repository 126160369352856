import React from "react";

import Popover from "@material-ui/core/Popover";
import {
  bindPopover,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";

import Button from "src/components/Button";
import { useCompany } from "src/hooks/company/useCompany";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { usePrintResumeCashFlow } from "src/pages/Financial/CashFlow/components/Modals/CloseCashFlowModal/usePrintResumeCashFlow";

import { MoreOptionsMenuProps } from "./props";
import * as Styled from "./styles";

const MoreOptionsMenu: React.FC<MoreOptionsMenuProps> = props => {
  const { cashFlow, children } = props;

  const { userCompany } = useCompany();
  const { user } = useAuth();

  const { PrintResumeCashFlowComponent, handlePrint } = usePrintResumeCashFlow({
    cashFlowResume: cashFlow,
    paymentMethods: userCompany.paymentMethods,
    operatorName: user.name,
  });

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-options-menu-table",
  });

  return (
    <>
      <Button {...bindToggle(popupState)}>{children}</Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
      >
        <Styled.MenuOverlay>
          <Styled.MenuContainer>
            <Button fullWidth onClick={() => handlePrint()}>
              Imprimir
            </Button>
          </Styled.MenuContainer>
        </Styled.MenuOverlay>
      </Popover>
      {PrintResumeCashFlowComponent}
    </>
  );
};

export default MoreOptionsMenu;
