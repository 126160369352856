import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Modal from "src/components/Modal";
import { useModal } from "src/components/Modal/useModal";
import { useContact } from "src/hooks/contact/useContact";

import { DeleteModalProps } from "./props";
import * as Styled from "./styles";

const DeleteModal: React.FC<DeleteModalProps> = props => {
  const { id, handleGetSubContacts } = props;

  const { removeSubContact, removeSubContactStatus } = useContact();
  const { handleClose } = useModal();

  const deleteService = async () => {
    await removeSubContact(id);
    handleGetSubContacts();
    handleClose();
  };

  return (
    <Modal>
      <Box p={3}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <div />
              <Styled.AlertIcon alignmentBaseline="middle" />
              <Styled.CloseButton onClick={handleClose} />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Styled.InfoText align="center" variant="h6">
              Tem certeza que deseja desabilitar esse sub contato?
            </Styled.InfoText>
          </Grid>
          <Grid item xs={12}>
            <Styled.DeleteButton
              variant="contained"
              onClick={deleteService}
              fullWidth
              size="large"
              disabled={removeSubContactStatus.loading}
            >
              Excluir
            </Styled.DeleteButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
