import React, { useMemo } from "react";

import Divider from "@material-ui/core/Divider";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import Loader from "src/components/Loader";
import { PAGE_ORDER_TITLES } from "src/constants/orders";
import { useCompany } from "src/hooks/company/useCompany";
import { useOrder } from "src/hooks/order/useOrder";
import ProductOrderForm from "src/pages/Orders/Add/components/Forms/ProductOrder";
import ServiceOrderForm from "src/pages/Orders/Add/components/Forms/ServiceOrder";
import BottomBar from "src/pages/Orders/components/BottomBar";

import { MainContentProps } from "./props";

const MainContent: React.FC<MainContentProps> = props => {
  const { type } = props;

  const { status } = useOrder();
  const { serviceOrderNameDefinitions } = useCompany();

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = useMemo(
    () => [
      {
        label: "vendas",
        path: PAGE_ORDER_TITLES(
          type === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[type].paths.list,
      },
      {
        label: PAGE_ORDER_TITLES(
          type === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[type].pageTitle.list,
        path: PAGE_ORDER_TITLES(
          type === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[type].paths.list,
      },
      {
        label: PAGE_ORDER_TITLES(
          type === "serviceOrder" ? serviceOrderNameDefinitions : undefined
        )[type].pageTitle.add,
      },
    ],
    [type]
  );

  const renderContent = () => {
    if (status.loading) {
      return <Loader />;
    }

    if (type === "serviceOrder") {
      return <ServiceOrderForm />;
    }

    if (type === "productOrder") {
      return <ProductOrderForm />;
    }

    return null;
  };

  return (
    <>
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />
      {renderContent()}
      <BottomBar orderType={type} />
    </>
  );
};

export default MainContent;
