/* eslint-disable global-require */
/* eslint-disable react/jsx-key */
import { useMemo, useState } from "react";

import { Box, Flex, Icon, Text } from "@chakra-ui/react";

import { ReactComponent as ModuleIcon } from "src/assets/icons/module-card-icon.svg";
import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Loader from "src/components/Loader";
import { CompanyModule } from "src/interfaces/module";
import { useModulesCompany } from "src/services/hooks/modules/useModulesCompany";
import { withContext } from "src/utils/withContext";

import DetailModules from "./components/DetailModules";

function Modules() {
  const { data: modules, isLoading } = useModulesCompany();
  const { handleOpen } = useBaseSwipeableDrawer();
  const [dataModule, setDataModule] = useState<CompanyModule>();

  const allModules = useMemo(() => {
    return modules?.map(group => group.modules).flat() ?? [];
  }, [modules]);

  const installedModules = useMemo(() => {
    return allModules?.filter(module => module.isActive) ?? [];
  }, [allModules]);

  const modulesComingSoon = useMemo(() => {
    return allModules?.filter(module => !module.isAvailable) ?? [];
  }, [allModules]);

  const newModules = useMemo(() => {
    return (
      allModules?.filter(
        module => module.isNew && !module.isActive && module.isAvailable
      ) ?? []
    );
  }, [allModules]);

  const renderInstalledModules = useMemo(() => {
    return installedModules.map(item => {
      return (
        <Box
          w="208px"
          h="140px"
          bg="primary.500"
          rounded="10px"
          textColor="white"
          boxShadow="2xl"
          p={4}
          cursor="pointer"
          onClick={() => [handleOpen("ModuleDetail"), setDataModule(item)]}
        >
          <Flex justifyContent="space-between">
            <Flex
              w="40px"
              h="40px"
              bg="white"
              rounded="full"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                as={ModuleIcon}
                boxSize={3.5}
                sx={{
                  path: {
                    fill: "primary.500",
                  },
                }}
              />
            </Flex>
            <Flex
              bg="white"
              h="21px"
              justifyContent="space-between"
              alignItems="center"
              p={1}
              rounded="5px"
            >
              <Text color="primary.500" fontSize="10px" fontWeight="600">
                instalado
              </Text>
            </Flex>
          </Flex>
          <Box pt={2} gap={2}>
            <Text color="white" fontSize="xs" fontWeight="500">
              {item.name}
            </Text>
            <Text color="white" fontSize="10px" fontWeight="400" noOfLines={3}>
              {item.description}
            </Text>
          </Box>
        </Box>
      );
    });
  }, [installedModules]);

  const renderModulesComingSoon = useMemo(() => {
    return modulesComingSoon.map(item => {
      return (
        <Box
          w="208px"
          h="140px"
          bg="warning.500"
          rounded="10px"
          textColor="white"
          boxShadow="2xl"
          p={4}
          cursor="pointer"
          onClick={() => [handleOpen("ModuleDetail"), setDataModule(item)]}
        >
          <Flex justifyContent="space-between">
            <Flex
              w="40px"
              h="40px"
              bg="white"
              rounded="full"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                as={ModuleIcon}
                boxSize={3.5}
                sx={{
                  path: {
                    fill: "warning.500",
                  },
                }}
              />
            </Flex>
            <Flex
              bg="white"
              h="21px"
              justifyContent="space-between"
              alignItems="center"
              p={1}
              rounded="5px"
            >
              <Text color="warning.500" fontSize="10px" fontWeight="600">
                Em breve
              </Text>
            </Flex>
          </Flex>
          <Box pt={2} gap={2}>
            <Text color="white" fontSize="xs" fontWeight="500">
              {item.name}
            </Text>
            <Text color="white" fontSize="10px" fontWeight="400" noOfLines={3}>
              {item.description}
            </Text>
          </Box>
        </Box>
      );
    });
  }, [modulesComingSoon]);

  const renderNewModules = useMemo(() => {
    return newModules.map(item => {
      return (
        <Box
          w="208px"
          h="140px"
          bg="white"
          borderWidth="1px"
          borderColor="yellow.500"
          rounded="10px"
          boxShadow="2xl"
          p={4}
          cursor="pointer"
          onClick={() => [handleOpen("ModuleDetail"), setDataModule(item)]}
        >
          <Flex justifyContent="space-between">
            <Flex
              w="40px"
              h="40px"
              bgGradient="linear(to-r, yellow.500, yellow.200)"
              rounded="full"
              justifyContent="center"
              alignItems="center"
            >
              <Icon as={ModuleIcon} boxSize={3.5} />
            </Flex>
            <Flex
              bg="white"
              h="21px"
              justifyContent="space-between"
              alignItems="center"
              borderWidth="1px"
              borderColor="yellow.500"
              p={1}
              rounded="5px"
            >
              <Text color="yellow.500" fontSize="10px" fontWeight="600">
                Novo
              </Text>
            </Flex>
          </Flex>
          <Box pt={2} gap={2}>
            <Text fontSize="xs" fontWeight="500">
              {item.name}
            </Text>
            <Text fontSize="10px" fontWeight="400" noOfLines={3}>
              {item.description}
            </Text>
          </Box>
        </Box>
      );
    });
  }, [newModules]);

  const renderModulesNotInstalleds = useMemo(() => {
    return modules
      ?.map(group => {
        return {
          ...group,
          modules: group.modules.filter(
            module => !module.isActive && !module.isNew && module.isAvailable
          ),
        };
      })
      .filter(group => group.modules.length)
      .map(group => {
        return (
          <>
            {group.label}
            <Flex gap={4}>
              {group.modules.map(module => {
                return (
                  <Box
                    w="208px"
                    h="140px"
                    bg="white"
                    borderWidth="1px"
                    borderColor="primary.500"
                    rounded="10px"
                    boxShadow="2xl"
                    textColor="white"
                    p={4}
                    cursor="pointer"
                    onClick={() => [
                      handleOpen("ModuleDetail"),
                      setDataModule(module),
                    ]}
                  >
                    <Flex justifyContent="space-between">
                      <Flex
                        w="40px"
                        h="40px"
                        bg="primary.500"
                        rounded="full"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Icon as={ModuleIcon} boxSize={3.5} />
                      </Flex>
                      <Flex
                        bg="white"
                        h="21px"
                        justifyContent="space-between"
                        alignItems="center"
                        borderWidth="1px"
                        borderColor="primary.500"
                        p={1}
                        rounded="5px"
                      >
                        <Text
                          color="primary.500"
                          fontSize="10px"
                          fontWeight="600"
                        >
                          Instalar
                        </Text>
                      </Flex>
                    </Flex>
                    <Box pt={2}>
                      <Text fontSize="xs" fontWeight="500">
                        {module.name}
                      </Text>
                      <Text fontSize="10px" fontWeight="400" noOfLines={3}>
                        {module.description}
                      </Text>
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </>
        );
      });
  }, [modules]);

  if (isLoading) {
    return <Loader size="medium" />;
  }

  return (
    <Flex direction="column" gap={5}>
      <Text color="primary.500" fontWeight="700" fontSize="sm">
        Instalados
      </Text>

      <Flex gap={4} wrap="wrap">
        {renderInstalledModules}
      </Flex>

      <Text color="warning.500" fontWeight="700" fontSize="sm">
        Em Breve
      </Text>

      <Flex gap={4} wrap="wrap">
        {renderModulesComingSoon}
      </Flex>

      <Text
        bgGradient="linear(to-r, yellow.500, yellow.200)"
        bgClip="text"
        fontWeight="700"
        fontSize="sm"
      >
        Novos
      </Text>

      <Flex gap={4} wrap="wrap">
        {renderNewModules}
      </Flex>

      <Text color="gray" borderBottomWidth="1px" pb={2}>
        Não instalados
      </Text>

      <Flex direction="column" gap={4} wrap="wrap">
        {renderModulesNotInstalleds}
      </Flex>
      {dataModule ? <DetailModules dataModules={dataModule} /> : null}
    </Flex>
  );
}

export default withContext(Modules, BaseSwipeableDrawerProvider);
