import styled from "styled-components";

import DividerMUI from "@material-ui/core/Divider";

import Typography from "src/components/Typography";
import { RECORD_STATUS } from "src/constants/record";
import { PaidStatusType } from "src/interfaces/record";

export const DayBill = styled(Typography)<{ status: PaidStatusType }>`
  color: ${({ status, theme }) =>
    status ? RECORD_STATUS[status].color : theme.palette.common.white};
  font-weight: 700;
  line-height: 1;
`;

export const Divider = styled(DividerMUI)`
  margin-bottom: 1rem;
  width: 100%;
`;
