import React, { FC } from "react";

import Typography from "src/components/Typography";
import { BuyOrderType } from "src/interfaces/buyOrder";

interface RenderProviderCellProps {
  buyOrder: BuyOrderType;
}

const RenderProviderCell: FC<RenderProviderCellProps> = ({ buyOrder }) => {
  return (
    <div>
      <Typography style={{ fontSize: "14px" }}>
        {buyOrder.provider?.name ?? "-"}
      </Typography>
    </div>
  );
};

export default RenderProviderCell;
