import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";

import { usePermission } from "src/hooks/permission/usePermission";

import { TabPanelProps, TabsProps } from "./props";
import * as Styled from "./styles";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={3}>{children}</Box>}
    </div>
  );
};

const Tabs: React.FC<TabsProps> = props => {
  const { tabs, tabPanels, ariaLabel, tab, setTab } = props;
  const { can } = usePermission();

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: any) => {
    setTab(newValue);
  };

  const renderTabs = useMemo(
    () =>
      tabs
        .filter(tab => {
          if (!tab.permissions) return true;

          return can(tab.permissions, tab.allPermissions);
        })
        .map(item => (
          <Styled.Tab
            label={
              item.label
                ? item.label[0].toUpperCase() + item.label.substring(1)
                : undefined
            }
            {...a11yProps(item.key)}
            key={`tab-${item.key}`}
            value={item.key}
            icon={item.icon}
          />
        )),
    [tabs]
  );

  const renderTabPanels = useMemo(
    () =>
      tabs.reduce<React.ReactNode[]>((panels, tabSelect, index) => {
        if (
          !tabSelect.permissions ||
          can(tabSelect.permissions, tabSelect.allPermissions)
        ) {
          return [
            ...panels,
            <TabPanel
              value={tab}
              index={tabSelect.key}
              key={`tabpanel-${tabSelect.key}`}
            >
              {tabPanels[index]}
            </TabPanel>,
          ];
        }
        return panels;
      }, []),
    [tabPanels, tab, tabs]
  );

  return (
    <div>
      <Styled.Tabs
        value={tab}
        onChange={handleChange}
        aria-label={ariaLabel}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={Styled.TabScrollButton}
      >
        {renderTabs}
      </Styled.Tabs>
      {renderTabPanels}
    </div>
  );
};

export default Tabs;
