/* eslint-disable no-unused-expressions */
import React, { FC } from "react";

import { Box, Button } from "@chakra-ui/react";

import Typography from "src/components/Typography";
import { TypeFilterFieldDate } from "src/interfaces/order";

import { SelectedFilterForType, SelectedFilterType } from "../../props";

interface PeriodFilterOptionsProps {
  options: Array<SelectedFilterType>;
  selectedFilter: SelectedFilterType;
  setSelectedFilter: (selectedFilter: SelectedFilterType) => void;
  selectedFilterForType: SelectedFilterForType;
  setSelectedFilterForType: (
    selectedFilterForType: SelectedFilterForType
  ) => void;
  showingInPopover?: boolean;
  setFilterFieldDate: React.Dispatch<
    React.SetStateAction<TypeFilterFieldDate | undefined>
  >;
  setSelectedFilterType: React.Dispatch<
    React.SetStateAction<TypeFilterFieldDate | undefined>
  >;
}

const PeriodFilterOptions: FC<PeriodFilterOptionsProps> = ({
  options,
  selectedFilter,
  setSelectedFilter,
  selectedFilterForType,
  setSelectedFilterForType,
  showingInPopover,
}) => {
  const activeButtonVariant = (filter: SelectedFilterType) =>
    selectedFilter === filter ? "solid" : "outline";

  const activeButtonType = (filter: SelectedFilterType) =>
    selectedFilterForType === filter ? "solid" : "outline";

  return (
    <Box>
      <Typography color={showingInPopover ? "textSecondary" : "primary"}>
        Tipo
      </Typography>
      <Box
        minHeight={20}
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        {options.includes("empty") && (
          <Button
            variant={activeButtonType("empty")}
            colorScheme="primary"
            rounded="50px"
            px="12px"
            onClick={() => [setSelectedFilterForType("empty")]}
          >
            sem filtro
          </Button>
        )}

        {options.includes("byUpdate") && (
          <Button
            variant={activeButtonType("byUpdate")}
            colorScheme="primary"
            rounded="50px"
            px="12px"
            onClick={() => [setSelectedFilterForType("byUpdate")]}
          >
            últimos modificados
          </Button>
        )}
      </Box>
      <Box>
        <Typography color={showingInPopover ? "textSecondary" : "primary"}>
          Período
        </Typography>
        <Box
          minHeight={20}
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
        >
          {options.includes("empty") && (
            <Button
              variant={activeButtonVariant("empty")}
              colorScheme="primary"
              rounded="50px"
              px="12px"
              onClick={() => setSelectedFilter("empty")}
            >
              sem filtro
            </Button>
          )}

          {options.includes("byDay") && (
            <Button
              variant={activeButtonVariant("byDay")}
              colorScheme="primary"
              rounded="50px"
              px="12px"
              onClick={() => {
                setSelectedFilter("byDay");
              }}
            >
              do dia
            </Button>
          )}

          {options.includes("byMonth") && (
            <Button
              variant={activeButtonVariant("byMonth")}
              colorScheme="primary"
              rounded="50px"
              px="12px"
              onClick={() => {
                setSelectedFilter("byMonth");
              }}
            >
              do mês
            </Button>
          )}

          {options.includes("perInterval") && (
            <Button
              variant={activeButtonVariant("perInterval")}
              colorScheme="primary"
              rounded="50px"
              px="12px"
              onClick={() => {
                setSelectedFilter("perInterval");
              }}
            >
              do intervalo
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PeriodFilterOptions;
