import React, { FC, useMemo } from "react";

import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";

import { RenderModuleProps } from "./props";

const RenderModule: FC<RenderModuleProps> = props => {
  const { children, modules, allModules = false } = props;
  const { userCompany } = useCompany();
  const { hasModules } = useModule();

  const shouldRender = useMemo(() => {
    if (!modules || modules.length === 0) return true;
    return hasModules(modules, allModules);
  }, [hasModules, modules, userCompany]);

  if (!shouldRender) return null;

  return <>{children}</>;
};

export default RenderModule;
