import React, { FC, useMemo } from "react";

import MuiTypography from "@material-ui/core/Typography";

import { TypographyProps } from "./props";

const Typography: FC<TypographyProps> = props => {
  const { children, uppercase, lowercase, firstletter, ...rest } = props;

  const text = useMemo(() => {
    const childrenText = children?.toString();

    if (uppercase) {
      return childrenText?.toUpperCase();
    }

    if (lowercase) {
      return childrenText?.toLowerCase();
    }

    if (firstletter && childrenText) {
      return childrenText[0].toUpperCase() + childrenText.substring(1);
    }

    return children;
  }, [children]);

  return <MuiTypography {...rest}>{text}</MuiTypography>;
};

export default Typography;
