import React, { FC } from "react";

import Typography from "src/components/Typography";
import { ProductType } from "src/interfaces/product";
import { formatPrice } from "src/utils/format";

interface RenderQuantityCellProps {
  product: ProductType;
}

const RenderQuantityCell: FC<RenderQuantityCellProps> = ({ product }) => {
  if (!product.stock) {
    return <Typography style={{ fontSize: "14px" }}>-</Typography>;
  }

  return (
    <div>
      <Typography style={{ fontSize: "14px" }}>
        {`${product.stock.quantity} ${product.unitOfMeasure.toUpperCase()}`}
      </Typography>
      <Typography style={{ fontSize: "8px" }}>
        Patrimônio:
        <strong> {formatPrice(product.price * product.stock.quantity)}</strong>
      </Typography>
    </div>
  );
};

export default RenderQuantityCell;
