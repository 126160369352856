import React, { useState } from "react";

import VisibilityButton from "src/components/CommonButtons/VisibilityButton";
import TextField from "src/components/Inputs/TextField";

import { TextFieldProps } from "../TextField/props";

const PasswordField: React.FC<TextFieldProps> = props => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <VisibilityButton
            visible={!showPassword}
            onClick={toggleVisibility}
          />
        ),
      }}
      {...props}
    />
  );
};

export default PasswordField;
