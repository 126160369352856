import {
  MovementFormType,
  MovementListParamsType,
} from "src/interfaces/movement";
import api from "src/services/api";

/**
 * Cadastrar movimentação
 */
export const addMovement = (params: MovementFormType) =>
  api.post("/v1/product/stock/movement", params).then(res => res.data);

/**
 * Listar movimentações
 */
export const getMovements = (params: MovementListParamsType) =>
  api
    .get(`/v1/product/${params.productId}/stock/movements`, {
      params,
    })
    .then(res => res.data);

/**
 * Deletar movimentação
 */
export const deleteMovement = (id: string) =>
  api
    .delete(`/v1/product/${id}/stock/movement`, {
      params: {
        id,
      },
    })
    .then(res => res);
