import React, { FC } from "react";

import { Box } from "@chakra-ui/react";

import Typography from "src/components/Typography";

import { SelectedFilterType } from "../../props";
import * as Styled from "./styles";

interface PeriodFilterContentProps {
  selectedDay: Date;
  selectedMonth: Date;
  selectedFilter: SelectedFilterType;
  selectedStartDate: Date;
  selectedEndDate: Date;
  handleDayChange: (date: Date | null, operator?: "add" | "sub") => void;
  handleMonthChange: (date: Date | null, operator?: "add" | "sub") => void;
  handleStartDate: (date: Date | null, operator?: "add" | "sub") => void;
  handleEndDate: (date: Date | null, operator?: "add" | "sub") => void;
  showingInPopover?: boolean;
}

const PeriodFilterContent: FC<PeriodFilterContentProps> = ({
  selectedFilter,
  selectedDay,
  selectedMonth,
  selectedStartDate,
  selectedEndDate,
  handleDayChange,
  handleMonthChange,
  handleStartDate,
  handleEndDate,
  showingInPopover,
}) => {
  if (selectedFilter === "empty") {
    return <></>;
  }

  if (selectedFilter === "byDay") {
    return (
      <Box width={showingInPopover ? "100%" : "250px"}>
        <Box mb={showingInPopover ? 1 : 0}>
          <Typography color={showingInPopover ? "textSecondary" : "primary"}>
            Data
          </Typography>
        </Box>
        <Styled.PickerContainer showingInPopover={showingInPopover}>
          <Styled.BackButton
            onClick={() => handleDayChange(selectedDay, "sub")}
          />
          <Styled.FowardButton
            onClick={() => handleDayChange(selectedDay, "add")}
          />

          <Styled.DatePicker
            value={selectedDay}
            variant="inline"
            onChange={handleDayChange}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            autoOk
          />
        </Styled.PickerContainer>
      </Box>
    );
  }

  if (selectedFilter === "byMonth") {
    return (
      <Box width={showingInPopover ? "100%" : "250px"}>
        <Box mb={showingInPopover ? 1 : 0}>
          <Typography color={showingInPopover ? "textSecondary" : "primary"}>
            Mês
          </Typography>
        </Box>

        <Styled.PickerContainer showingInPopover={showingInPopover}>
          <Styled.MonthIcon />

          <Styled.DatePicker
            views={["month"]}
            value={selectedMonth}
            variant="inline"
            onChange={handleMonthChange}
            inputVariant="outlined"
            format="MMMM"
            disableToolbar
            autoOk
          />
        </Styled.PickerContainer>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexWrap={showingInPopover ? "wrap" : "nowrap"}
      style={{ columnGap: "1rem" }}
    >
      <Box width={showingInPopover ? "100%" : "250px"}>
        <Box mb={showingInPopover ? 1 : 0}>
          <Typography color={showingInPopover ? "textSecondary" : "primary"}>
            De
          </Typography>
        </Box>

        <Styled.PickerContainer showingInPopover={showingInPopover}>
          <Styled.BackButton
            onClick={() => handleStartDate(selectedStartDate, "sub")}
          />
          <Styled.FowardButton
            onClick={() => handleStartDate(selectedStartDate, "add")}
          />

          <Styled.DatePicker
            value={selectedStartDate}
            variant="inline"
            onChange={handleStartDate}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            autoOk
          />
        </Styled.PickerContainer>
      </Box>
      {showingInPopover ? <Box mb={1} /> : null}

      <Box width={showingInPopover ? "100%" : "250px"}>
        <Box mb={showingInPopover ? 1 : 0}>
          <Typography color={showingInPopover ? "textSecondary" : "primary"}>
            Até
          </Typography>
        </Box>

        <Styled.PickerContainer showingInPopover={showingInPopover}>
          <Styled.BackButton
            onClick={() => handleEndDate(selectedEndDate, "sub")}
          />
          <Styled.FowardButton
            onClick={() => handleEndDate(selectedEndDate, "add")}
          />

          <Styled.DatePicker
            value={selectedEndDate}
            variant="inline"
            onChange={handleEndDate}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            autoOk
          />
        </Styled.PickerContainer>
      </Box>
    </Box>
  );
};

export default PeriodFilterContent;
