import * as yup from "yup";

export const schemaPayRecordForm = yup.object({
  id: yup.string().required("Id obrigatório"),
  type: yup.string(),
  paymentMethodId: yup.string().when("type", type => {
    if (type === "income") {
      return yup.string().required("Forma de pagamento é obrigatória");
    }
    return yup.string();
  }),
  paidDate: yup.string().required("Data de pagamento é obrigatória"),
  value: yup
    .number()
    .typeError("Valor é obrigatório")
    .test("is-number", "Valor é obrigatório", value => value !== undefined),
  financialAccount: yup
    .string()
    .when("hasAccountManagement", hasAccountManagement => {
      if (hasAccountManagement) {
        return yup.string().required("Conta financeira é obrigatória");
      }
      return yup.string();
    }),
  hasAccountManagement: yup.bool(),
});
