import React, { useMemo } from "react";
import { useParams, Navigate, RouteObject, useRoutes } from "react-router-dom";

import { differenceInDays, differenceInMilliseconds } from "date-fns";

import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import { CanCallback, usePermission } from "src/hooks/permission/usePermission";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { FeatureAction } from "src/interfaces/permissions";
import { CONTACTS_DETAILS_TABS } from "src/pages/Contacts/Detail";
import HomePage from "src/pages/Home";
import PlanExpired from "src/pages/PlanExpired";
import { SETTINGS_TABS } from "src/pages/Settings";
import SignInPage from "src/pages/SignIn";
import { FirebaseAnalytics } from "src/services/firebase";

import { contactRoutes } from "./parts/contact.routes";
import { financialRoutes } from "./parts/financial.routes";
import { orderRoutes } from "./parts/order.routes";
import { productOrderRoutes } from "./parts/product_order.routes";
import { productRoutes } from "./parts/product.routes";
import { reportRoutes } from "./parts/report.routes";
import { scheduleRoutes } from "./parts/schedule.routes";
import { serviceOrderRoutes } from "./parts/service_order.routes";
import { serviceRoutes } from "./parts/service.routes";
import { settingsRoutes } from "./parts/settings.routes";
import ProtectedRoutes from "./protectedRoutes";
import PublicRoute from "./publicRoutes";

export interface RouteObjectProps extends RouteObject {
  children?: RouteObjectProps[];
  permissions?: FeatureAction[];
  allPermissions?: boolean;
}

const commonRoutes = (privateRoutes: RouteObjectProps[]) => {
  return [
    {
      children: [
        {
          element: (
            <>
              <FirebaseAnalytics />
              <ProtectedRoutes />
            </>
          ),
          children: [
            {
              path: PATHS.HOME,
              element: <HomePage />,
            },
            ...privateRoutes,
            {
              path: "*",
              element: <Navigate to={PATHS.HOME} replace />,
            },
          ],
        },
        {
          element: <PublicRoute />,
          children: [
            {
              path: PATHS.SIGN_IN,
              element: <SignInPage />,
            },
          ],
        },
      ],
    },
  ];
};

const AppRoutes: React.FC = () => {
  const { tab } = useParams<{ tab?: string }>();

  const { subContactNameDefinitions } = useCompany();
  const { isAuthenticated, user } = useAuth();

  const tabContactDetailIsValid = useMemo(
    () =>
      tab === "" ||
      CONTACTS_DETAILS_TABS(subContactNameDefinitions.title.toLowerCase())
        .map(tab => tab.key)
        .includes(tab ?? ""),
    [tab]
  );

  const tabSettingsIsValid = useMemo(
    () => tab === "" || SETTINGS_TABS.map(tab => tab.key).includes(tab ?? ""),
    [tab]
  );

  const getPrivateRoutes = (can: CanCallback) => {
    return [
      ...contactRoutes({ subContactNameDefinitions, tabContactDetailIsValid }),
      ...serviceRoutes,
      ...productRoutes,
      ...financialRoutes.filter(
        route =>
          !route.permissions || can(route.permissions, route.allPermissions)
      ),
      ...orderRoutes,
      ...serviceOrderRoutes,
      ...productOrderRoutes,
      ...reportRoutes.filter(
        route =>
          !route.permissions || can(route.permissions, route.allPermissions)
      ),
      ...settingsRoutes({ tabSettingsIsValid }),
      ...scheduleRoutes,
    ];
  };

  const { can } = usePermission();
  const privateRoutes = useMemo(() => getPrivateRoutes(can), [can]);

  const redirectToAlertPage = useMemo(() => {
    if (!isAuthenticated) return false;

    const planSignature = user.company?.planSignature;

    return planSignature == null ||
      (planSignature.isTrial &&
        differenceInMilliseconds(
          new Date(planSignature.expirationDate),
          new Date()
        ) <= 0) ||
      (!planSignature.isTrial &&
        differenceInDays(new Date(planSignature.expirationDate), new Date()) <=
          -5)
      ? [
          {
            path: "*",
            element: <PlanExpired />,
          },
        ]
      : false;
  }, [user, isAuthenticated]);

  const routes = useRoutes(redirectToAlertPage || commonRoutes(privateRoutes));

  return routes;
};

export default AppRoutes;
