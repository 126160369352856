import { Box } from "@chakra-ui/react";

import { useAuth } from "src/hooks/user/auth/useAuth";

import HelpAndSuport from "./HelpAndSuport";
import MyAccount from "./MyAccount";
import MyBusiness from "./MyBusiness";
import { MyUsername } from "./MyUsername";
import PrintGuide from "./PrintGuide";

export function General() {
  const { isOwner } = useAuth();

  return (
    <Box>
      <MyAccount />
      <MyBusiness />
      {isOwner ? <MyUsername /> : null}
      <HelpAndSuport />
      <PrintGuide />
    </Box>
  );
}
