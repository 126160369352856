import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { Formik, Form } from "formik";

import Button from "src/components/Button";
import { useCompany } from "src/hooks/company/useCompany";
import { UpdatePaymentMethodParamType } from "src/interfaces/payment";
import SectionCard from "src/pages/Settings/components/SectionCard";

import PaymentOption from "./PaymentOption";
import { usePaymentMethods } from "./usePaymentMethods";

const PaymentSection: React.FC = () => {
  const { userCompany } = useCompany();
  const { update, status } = usePaymentMethods();

  const companyPaymentMethods = useMemo(() => {
    return (
      userCompany?.paymentMethods?.map(payment => ({
        id: payment.id,
        status: payment.status,
      })) ?? []
    );
  }, [userCompany]);

  const renderPaymentsMethods = useMemo(() => {
    return (
      userCompany?.paymentMethods?.map(paymentMethod => (
        <div key={paymentMethod.slug}>
          <Divider />
          <PaymentOption label={paymentMethod.title} id={paymentMethod.id} />
        </div>
      )) ?? null
    );
  }, [userCompany]);

  return (
    <Formik
      initialValues={{
        companyPaymentMethods,
      }}
      onSubmit={(values: UpdatePaymentMethodParamType) => update(values)}
      enableReinitialize
    >
      <Form>
        <SectionCard title="Formas de pagamento" dividerHeader={false}>
          {renderPaymentsMethods}
          <Box display="flex" flexDirection="row-reverse">
            <div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="small"
                fullWidth
                loading={status.loading}
              >
                Salvar
              </Button>
            </div>
          </Box>
        </SectionCard>
      </Form>
    </Formik>
  );
};

export default PaymentSection;
