import React from "react";

import * as Styled from "../styles";

const UsernameForm = () => {
  return (
    <Styled.ContainerTop>
      <Styled.WelcomeText color="primary" variant="h5">
        Área do Cliente
      </Styled.WelcomeText>
      <Styled.FriendlyText color="primary" variant="body2">
        Seja bem-vindo!
      </Styled.FriendlyText>

      <Styled.TextField
        name="username"
        label="Usuario"
        variant="outlined"
        fullWidth
        required
      />
    </Styled.ContainerTop>
  );
};

export default UsernameForm;
