import PATHS from "src/constants/paths";
import { BuyOrderStatusEnum } from "src/interfaces/buyOrder";
import { NameDefitionType } from "src/interfaces/company";
import {
  PaymentStatusNameType,
  StatusNameType,
  TypeOfOrder,
} from "src/interfaces/order";
import { ServiceOrderSettingsField } from "src/interfaces/preferences";

const DEFAULT_ERROR_MESSAGE =
  "Falha ao efetuar ação, tente novamente em breve!";

interface PageOrderTitleType {
  name: string;
  pageTitle: {
    list: string;
    add: string;
  };
  productSection: {
    label: string;
  };
  observationField: {
    formLabel: string;
    detailLabel: string;
  };
  message: {
    create: {
      success: string;
      error: string;
    };
    update: {
      success: string;
      error: string;
    };
    delete: {
      success: string;
      error: string;
    };
  };
  tableHeader: string;
  paths: {
    list: string;
    detail: string;
    update: string;
  };
}

export const PAGE_ORDER_TITLES: (
  nameDefinition?: NameDefitionType
) => { [key in TypeOfOrder]: PageOrderTitleType } = (
  nameDefinition?: NameDefitionType
) => ({
  serviceOrder: {
    name: nameDefinition?.title.toLowerCase() ?? "ordem de serviço",
    pageTitle: {
      list: nameDefinition?.titlePlural ?? "Ordens de serviço",
      add: nameDefinition?.title ?? "Nova ordem de serviço",
    },
    productSection: {
      label: "Adicione produtos!",
    },
    observationField: {
      formLabel: "Observação (caso deseje, escreva algo que ache relevante).",
      detailLabel: `Aqui temos observações sobre ${
        nameDefinition?.title.toLowerCase() ?? "ordem de serviço"
      }`,
    },
    message: {
      create: {
        success: `Sucesso ao criar ${
          nameDefinition?.title.toLowerCase() ?? "ordem de serviço"
        }`,
        error: DEFAULT_ERROR_MESSAGE,
      },
      update: {
        success: `Atualização de ${
          nameDefinition?.title.toLowerCase() ?? "ordem de serviço"
        } feita com sucesso!`,
        error: DEFAULT_ERROR_MESSAGE,
      },
      delete: {
        success: `Sucesso ao excluir ${
          nameDefinition?.title.toLowerCase() ?? "ordem de serviço"
        }`,
        error: DEFAULT_ERROR_MESSAGE,
      },
    },
    paths: {
      list: PATHS.SERVICE_ORDER_LIST,
      detail: PATHS.SERVICE_ORDER_DETAIL,
      update: PATHS.SERVICE_ORDER_UPDATE,
    },
    tableHeader: "Nº OS",
  },
  productOrder: {
    name: "pedido de venda",
    pageTitle: {
      list: "Pedidos de venda",
      add: "Novo pedido de venda",
    },
    productSection: {
      label: "Adicione produtos ao pedido de venda!",
    },
    observationField: {
      formLabel:
        "Observação (caso deseje, escreva algo que ache relevante para o pedido de venda)",
      detailLabel: "Aqui temos observações sobre o pedido de venda",
    },
    message: {
      create: {
        success: "Pedido de venda criado com sucesso!",
        error: DEFAULT_ERROR_MESSAGE,
      },
      update: {
        success: "Pedido de venda atualizado com sucesso!",
        error: DEFAULT_ERROR_MESSAGE,
      },
      delete: {
        success: "Pedido de venda deletado com sucesso!",
        error: DEFAULT_ERROR_MESSAGE,
      },
    },
    paths: {
      list: PATHS.PRODUCT_ORDER_LIST,
      detail: PATHS.PRODUCT_ORDER_DETAIL,
      update: PATHS.PRODUCT_ORDER_UPDATE,
    },
    tableHeader: "Nº Pedido",
  },
});

export const COLOR_STATUS: { [key in StatusNameType]: string } = {
  waiting: "#ED6B22",
  progress: "#1E2C47",
  success: "#14B738",
  warning: "#ED6B22",
  error: "#E54848",
};

export const BUY_ORDER_COLOR_STATUS: { [key in BuyOrderStatusEnum]: string } = {
  open: "#074EE8",
  pending: "#ED6B22",
  finished: "#14B738",
};

export const BUY_ORDER_STATUS_NAME: { [key in BuyOrderStatusEnum]: string } = {
  open: "Aberto",
  pending: "Aguardando entrega",
  finished: "Lançada",
};

export const COLOR_PAYMENT_STATUS: { [key in PaymentStatusNameType]: string } =
  {
    null: "#92969C",
    notPaid: "#E54848",
    partial: "#ED6B22",
    paid: "#1A5DF2",
  };

export const NAME_PAYMENT_STATUS: { [key in PaymentStatusNameType]: string } = {
  null: "Não lançado ",
  notPaid: "Não pago",
  partial: "Parcialmente",
  paid: "Pago",
};

export const SERVICE_ORDER_FIELDS: ServiceOrderSettingsField[] = [
  {
    name: "code",
    label: "Nº OS",
    enabled: true,
    flex: 0.8,
  },
  {
    name: "controlNumber",
    label: "Nº CR",
    enabled: false,
    flex: 0.8,
  },
  {
    name: "contact",
    label: "Cliente",
    enabled: true,
    flex: 1,
  },
  {
    name: "document",
    label: "CPF/CNPJ",
    enabled: false,
    flex: 1,
  },
  {
    name: "location",
    label: "Cidade/UF",
    enabled: false,
    flex: 1,
  },
  {
    name: "totalAmount",
    label: "Valor",
    enabled: true,
    flex: 0.7,
  },
  {
    name: "deadline",
    label: "Prazo de entrega",
    enabled: true,
    flex: 1,
  },
  {
    name: "createdDate",
    label: "Data de criação",
    enabled: false,
    flex: 1,
  },
  {
    name: "status",
    label: "Status",
    enabled: true,
    flex: 1.4,
  },
  {
    name: "isPaid",
    label: "Pagamento",
    enabled: true,
    flex: 1,
  },
  {
    name: "discount",
    label: "Desconto",
    enabled: false,
    flex: 1,
  },
  {
    name: "additionalInfo",
    label: "Observações",
    enabled: false,
    flex: 1,
  },
];
