import {
  RecordType,
  RecordListParamType,
  PayRecordParamType,
  UpdateRecordParamType,
  DeleteRecordParamType,
  CreateTransferType,
} from "src/interfaces/record";
import api from "src/services/api";

/**
 * Cadastrar registro
 */
export const addRecord = (params: Partial<RecordType>) =>
  api.post("v1/record", params).then(res => res.data);

/**
 * Listar registros
 */
export const getRecords = (params: RecordListParamType) =>
  api
    .get("v1/record", {
      params,
    })
    .then(res => res.data);

/**
 * Encontrar registro pelo Id
 */
export const getRecord = (id: string) =>
  api.get(`v1/record/${id}`).then(res => res.data);

/**
 * Deletar registro
 */
export const deleteRecord = (id: string, params: DeleteRecordParamType) =>
  api.delete(`v1/record/${id}`, { params }).then(res => res);

/**
 * Estornar registro
 */
export const chargebackRecord = (id: string) =>
  api.post(`v1/record/${id}/chargeback`).then(res => res.data);

/**
 * Atualizar registro
 */
export const updateRecord = (id: string, params: UpdateRecordParamType) =>
  api.put(`v1/record/${id}`, params).then(res => res.data);

/**
 * Cadastrar pagamento
 */
export const payRecord = (id: string, params: PayRecordParamType) =>
  api.post(`v1/record/${id}/pay`, params).then(res => res.data);

/**
 * Listar categorias do financeiro
 */
export const getCategoriesRecord = (params: any) =>
  api
    .get("v1/record-category/company", {
      params,
    })
    .then(res => res.data);

/**
 * Criar nova transferência
 */
export const createTransferService = (params: Partial<CreateTransferType>) =>
  api.post("v1/transfer", params).then(res => res);

/**
 * Atualizar transferência
 */
export const updateTransferService = (
  id: string,
  params: Partial<CreateTransferType>
) => api.put(`v1/transfer/${id}`, params).then(res => res);

/**
 * Deletar transferência
 */
export const deleteTransferService = (id: string) =>
  api.delete(`v1/transfer/${id}`).then(res => res);
