import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const DateTitle = styled.p`
  font-size: 15px;
  color: #92969c;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const TotalLabel = styled.span<{ isExpense?: boolean }>`
  font-weight: 500;
  color: ${({ theme, isExpense }) =>
    isExpense ? theme.palette.error.main : theme.palette.primary.main};
`;

export const ContainerGroup = styled.div<{
  isExpense?: boolean;
  initial?: boolean;
}>`
  width: 100%;
  position: relative;
  padding-left: 32px;
  &::before {
    content: "";
    position: absolute;
    top: 1rem;
    left: calc(0.5rem - 0.5px);
    width: 1px;
    height: calc(100% + 1rem);
    background-color: ${({ theme, isExpense }) =>
      isExpense ? theme.palette.error.main : theme.palette.primary.main};
  }
  &::before {
    background-color: ${({ initial }) => (initial ? "initial" : "none")};
  }
  &:last-child {
    &::before {
      top: 0;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${({ theme, isExpense }) =>
      isExpense ? theme.palette.error.main : theme.palette.primary.main};
  }
`;
