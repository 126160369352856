import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import MuiToolbar from "@material-ui/core/Toolbar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import { ReactComponent as ExitActiveIcon } from "src/assets/icons/sidebar/exit-active.svg";
import { ReactComponent as ExitIcon } from "src/assets/icons/sidebar/exit.svg";
import { ReactComponent as SettingsActiveIcon } from "src/assets/icons/sidebar/settings-active.svg";
import { ReactComponent as SettingsIcon } from "src/assets/icons/sidebar/settings.svg";
import { PAGES } from "src/constants/pages";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import { useFAQ } from "src/hooks/FAQ/useFAQ";
import { useModule } from "src/hooks/module/useModule";
import { usePermission } from "src/hooks/permission/usePermission";
import { useAuth } from "src/hooks/user/auth/useAuth";

import ButtonSidebar from "./components/ButtonSidebar";
import MainToolbar from "./components/MainToolbar";
import { DashboardLayoutProps } from "./props";
import * as Styled from "./styles";

const DashboardLayout: React.FC<DashboardLayoutProps> = props => {
  const {
    children,
    selected,
    subSelected,
    modules,
    hidden,
    allModules = true,
  } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const { logout } = useAuth();
  const { hasModules } = useModule();
  const { userCompany, getNameDefinitionsFromKey } = useCompany();
  const { can } = usePermission();

  const { FAQ } = useFAQ();

  const handleDrawerToggle = () => setDrawerOpen(drawerOpen => !drawerOpen);

  const renderButtonsSidebar = React.useMemo(() => {
    const topButtons = PAGES.filter(page => page.key !== "settings");

    return topButtons
      .filter(button => {
        if (!button.permissions) return true;

        return can(button.permissions, button.allPermissions);
      })
      .filter(button => hasModules(button.moduleSlug))
      .map(button => {
        const label =
          getNameDefinitionsFromKey(button.nameDefinition)?.titlePlural ??
          button.label;
        return (
          <ButtonSidebar
            label={label}
            selected={selected === button.key}
            subSelected={subSelected}
            key={`button-sidebar-${button.key}`}
            icon={button.icon}
            iconActive={button.iconActive}
            to={button.path}
            options={button?.options}
          />
        );
      });
  }, [selected, userCompany]);

  const contentDrawer = useMemo(
    () => (
      <>
        <Box mt={2}>{renderButtonsSidebar}</Box>
        <Box mb={5}>
          <ButtonSidebar
            label="configurações"
            selected={selected === "settings"}
            icon={SettingsIcon}
            iconActive={SettingsActiveIcon}
            to={PATHS.SETTINGS}
          />
          <ButtonSidebar
            label="sair"
            icon={ExitIcon}
            iconActive={ExitActiveIcon}
            onClick={logout}
          />
          <Box mt={2} textAlign="center">
            <Styled.HelpText>Ei, precisa de ajuda?</Styled.HelpText>
            <Styled.HelpText>
              Chama no{" "}
              <a href={FAQ.whatsappLink} target="_blank" rel="noreferrer">
                Whatsapp
                <WhatsAppIcon color="primary" />
              </a>
            </Styled.HelpText>
          </Box>
        </Box>
      </>
    ),
    [selected, FAQ, renderButtonsSidebar]
  );

  if (modules) {
    if (!hasModules(modules, allModules))
      return <Navigate to={PATHS.HOME} replace />;
  }

  return (
    <Styled.Root hidden={hidden}>
      <MainToolbar handleDrawerToggle={handleDrawerToggle} />

      <Hidden smDown>
        <Styled.Drawer
          variant="persistent"
          anchor="left"
          open
          onClose={handleDrawerToggle}
        >
          <MuiToolbar />
          <Styled.ContentDrawer>{contentDrawer}</Styled.ContentDrawer>
        </Styled.Drawer>
      </Hidden>

      <Hidden xsDown>
        <Styled.Drawer
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
        >
          <MuiToolbar />
          <Styled.ContentDrawer>{contentDrawer}</Styled.ContentDrawer>
        </Styled.Drawer>
      </Hidden>

      <Hidden smUp>
        <Styled.Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          anchor="left"
          ModalProps={{
            keepMounted: true, // Melhora a perfomance no mobile
          }}
        >
          {contentDrawer}
        </Styled.Drawer>
      </Hidden>

      <Styled.Main>
        <Container maxWidth="xl">
          <MuiToolbar />
          {children}
        </Container>
      </Styled.Main>
    </Styled.Root>
  );
};

export default DashboardLayout;
