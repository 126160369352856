import styled from "styled-components";

import Paper from "@material-ui/core/Paper";

import CurrencyFieldBase from "src/components/Inputs/CurrencyField";
import PercentFieldComponent from "src/components/Inputs/PercentField";
import SelectFieldComponent from "src/components/Inputs/SelectField";
import MUITextField from "src/components/Inputs/TextField";

export const Container = styled(Paper)<{ enabled: boolean }>`
  box-shadow: none;
  opacity: ${({ enabled }) => (enabled ? 100 : 50)}%;
  pointer-events: ${({ enabled }) => (enabled ? "auto" : "none")};
`;

export const PercentField = styled(PercentFieldComponent)`
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const SelectField = styled(SelectFieldComponent)`
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const TextField = styled(MUITextField)`
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const CurrencyField = styled(CurrencyFieldBase)`
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
