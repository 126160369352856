import { useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { Flex, FormControl, FormLabel, Switch, Text } from "@chakra-ui/react";

import { DatePicker } from "src/components/NewComponents/DatePicker";
import { Select } from "src/components/NewComponents/Select";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { FinancialAccountType } from "src/interfaces/financialAccount";
import { RecordType } from "src/interfaces/record";

import { RecordFormSchema } from "../..";

interface FormRecordPaymentProps {
  accounts: FinancialAccountType[];
  record?: RecordType;
}

export function FormRecordPayment({
  accounts,
  record,
}: FormRecordPaymentProps) {
  const { userCompany } = useCompany();
  const { hasModules } = useModule();

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<RecordFormSchema>();

  const paidStatus = useWatch({ name: "paidStatus", control });
  const recordType = useWatch({ name: "type", control });
  const financialAccount = useWatch({ name: "financialAccount", control });
  const paymentMethod = useWatch({ name: "paymentMethod", control });

  const paymentMethodsItems = useMemo(() => {
    return (
      userCompany?.paymentMethods
        ?.filter(item => item.status === "enabled")
        .map(method => ({
          label: method.title,
          value: method.id,
        })) ?? []
    );
  }, [userCompany]);

  return (
    <>
      <Flex justifyContent="space-between">
        <Text>Pagamento</Text>
        {record ? (
          <Text
            fontSize="sm"
            color={paidStatus === "paid" ? "primary.500" : "error.500"}
          >
            {paidStatus === "paid" ? "Pago" : "Não pago"}
          </Text>
        ) : null}
      </Flex>

      {!record ? (
        <Controller
          control={control}
          name="paidStatus"
          render={({ field: { ref, value, onChange, name } }) => (
            <FormControl display="flex" alignItems="center">
              <FormLabel
                htmlFor="paidStatus"
                color={value === "paid" ? "primary.500" : "error.500"}
                flex={1}
                m={0}
              >
                {value === "paid" ? "Pago" : "Não pago"}
              </FormLabel>
              <Switch
                ref={ref}
                id="paidStatus"
                name={name}
                isChecked={value === "paid"}
                checked={value === "paid"}
                colorScheme={value === "paid" ? "primary" : "error"}
                onChange={e => {
                  if (e.target.checked) {
                    onChange("paid");
                  } else {
                    onChange("pending");
                    setValue("paymentMethod", undefined);
                  }
                }}
              />
            </FormControl>
          )}
        />
      ) : null}

      <DatePicker
        label={paidStatus === "paid" ? "Data do pagamento" : "Prazo"}
        error={errors.paidDate}
        control={control}
        name="paidDate"
        isDisabled={record && paidStatus === "paid"}
      />

      {paidStatus === "paid" && recordType === "income" ? (
        <Controller
          control={control}
          name="paymentMethod"
          render={({ field: { ref, onChange } }) => (
            <Select
              options={paymentMethodsItems}
              inputRef={ref}
              error={!!errors.paymentMethod}
              errorMessage={errors.paymentMethod?.message}
              placeholder="Forma de pagamento"
              isClearable={false}
              isDisabled={!!record}
              onChange={paymentMethod => {
                if (hasModules("cashflowcontrol")) {
                  const paymentMethodSlugSelected =
                    userCompany.paymentMethods?.find(
                      method => method.id === paymentMethod
                    )?.slug;
                  const financialAccountTypeSelected = accounts.find(
                    account => account.id === financialAccount
                  )?.type;
                  if (
                    (financialAccountTypeSelected === "cash" &&
                      paymentMethodSlugSelected !== "money") ||
                    (financialAccountTypeSelected !== "cash" &&
                      paymentMethodSlugSelected === "money")
                  ) {
                    setValue("financialAccount", undefined);
                  }
                }
                onChange(paymentMethod);
              }}
              value={paymentMethod}
            />
          )}
        />
      ) : null}
    </>
  );
}
