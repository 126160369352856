import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Box, Button, Divider, Flex } from "@chakra-ui/react";

import { ReactComponent as ExcelIcon } from "src/assets/icons/reports/excel.svg";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import { Input } from "src/components/NewComponents/Input";
import { Select } from "src/components/NewComponents/Select";
import PATHS from "src/constants/paths";
import { BRAZIL_UF } from "src/constants/uf";
import { Can } from "src/hooks/permission/usePermission";
import { useContactsReport } from "src/services/hooks/reports/clients/useContactsReport";
import { useTags } from "src/services/hooks/tag/useTags";
import { formatPhone, renderDocument } from "src/utils/format";
import { generateXLSX } from "src/utils/generateXLSX";

import InformationInitialReportState from "../components/InformationInitialReportState";
import ProvidersTable from "./ProvidersTable";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Lista de fornecedores" },
];

interface ProvidersReportForm {
  search: string;
  uf: string;
  tags: string[];
  page: number;
  limit: number;
}

export default function ProvidersReportPage() {
  const { data: tags } = useTags({ search: "", type: "contact" });
  const [exportLoading, setExportLoading] = useState(false);

  const { handleSubmit, register, control, watch, setValue } =
    useForm<ProvidersReportForm>({
      defaultValues: {
        search: "",
        uf: "",
        tags: [],
        limit: 10000,
        page: 1,
      },
    });

  const formData = watch();

  const {
    data,
    refetch: getClientsReport,
    isFetched,
    isFetching,
  } = useContactsReport({
    type: "provider",
    ...formData,
    tags: JSON.stringify(watch("tags")),
  });

  const onSubmit = () => {
    getClientsReport({});
  };

  const exportToXLSX = async () => {
    if (exportLoading) return;

    const { contacts = [] } = data;

    if (contacts?.length) {
      setExportLoading(true);
      const header = [
        "Nome",
        "CPF/CNPJ",
        "E-mail",
        "Telefone",
        "Estado",
        "Marcadores",
      ];
      const data = contacts.reduce<string[][]>((data, contact) => {
        const row = [
          contact.name,
          renderDocument(contact.cpf, contact.cnpj),
          contact.email ?? "-",
          contact.phone ? formatPhone(contact.phone) : "-",
          BRAZIL_UF.find(uf => contact?.uf === uf.uf)?.name ?? "-",
          Array.isArray(contact.tags)
            ? contact.tags.map(tag => tag.label).join(", ")
            : "-",
        ] as Array<string>;
        data.push(row);
        return data;
      }, []);

      await generateXLSX(
        header,
        data,
        "Relatório de fornecedores",
        "Relatório de fornecedores.xlsx"
      );
      setExportLoading(false);
    }
  };

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Lista de fornecedores" />

      <Flex
        gap={2}
        direction={["column", "column", "row"]}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input flex={1} bg="white" label="Fornecedor" {...register("search")} />
        <Box minW={["100%", "100%", "220px"]}>
          <Controller
            control={control}
            name="uf"
            render={({ field: { ref, ...props } }) => (
              <Select
                options={BRAZIL_UF.map(uf => ({
                  label: uf.name,
                  value: uf.uf,
                }))}
                inputRef={ref}
                placeholder="Estado"
                {...props}
              />
            )}
          />
        </Box>
        <Box minW={["100%", "100%", "220px"]}>
          <Controller
            control={control}
            name="tags"
            render={({ field: { ref, ...props } }) => (
              <Select
                options={tags.map(tag => ({
                  label: tag.label,
                  value: tag.id,
                }))}
                inputRef={ref}
                placeholder="Marcador"
                isMulti
                {...props}
              />
            )}
          />
        </Box>
        <Button
          isLoading={isFetching}
          minW={["100%", "100%", "min-content"]}
          type="submit"
          px={2}
        >
          Gerar relatório
        </Button>
        <Can subject="reportProviders" action="export">
          <Button
            isDisabled={!data.contacts.length}
            isLoading={exportLoading}
            onClick={exportToXLSX}
            minW={["100%", "100%", "min-content"]}
            px={2}
            variant="outline"
            rightIcon={<ExcelIcon />}
            type="button"
          >
            Exportar
          </Button>
        </Can>
      </Flex>
      <Divider my={6} />

      {!isFetched && !isFetching ? <InformationInitialReportState /> : null}

      {isFetched && !isFetching ? (
        <ProvidersTable
          providers={data.contacts}
          page={formData.page}
          setPage={value => setValue("page", value)}
          limit={data.contacts.length}
          setLimit={value => setValue("limit", value)}
        />
      ) : null}
    </DashboardLayout>
  );
}
