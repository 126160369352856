import React, { useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";

import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Tabs from "src/components/Tabs";
import { ItemTabsProps } from "src/components/Tabs/props";
import Typography from "src/components/Typography";
import { CONTACT_LABELS } from "src/constants/contacts";
import PATHS from "src/constants/paths";
import { useContact } from "src/hooks/contact/useContact";
import { useModule } from "src/hooks/module/useModule";
import { ContactType, TypeOfContact } from "src/interfaces/contact";
import {} from "src/utils/urlHandler";
import { withContext } from "src/utils/withContext";

import OptionsPanel from "./components/OptionsPanel";
import CustomerTable from "./components/Tables/Customer";
import OthersTable from "./components/Tables/Others";
import ProviderTable from "./components/Tables/Provider";
import * as Styled from "./styles";
import { ContactTypeProvider, useContactType } from "./useContactType";

const TABS: Array<ItemTabsProps> = Object.keys(CONTACT_LABELS).map(item => ({
  key: item,
  label: CONTACT_LABELS[item as TypeOfContact].name,
}));

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [{ label: "Contatos" }];

export interface ContactListFilters {
  tagSelected: {
    name: string;
    value: string;
  };
  limit: number;
  page: number;
  search: string;
}

const ContactsList: React.FC = () => {
  const match = useMatch(PATHS.CONTACTS_LIST);
  const { pathname } = useLocation();
  const { type, setType } = useContactType();
  const { setContact, getTags } = useContact();
  const { hasModules, companyModules } = useModule();

  const [filters, setFilters] = useState<ContactListFilters>({
    tagSelected: { name: "Todos", value: "" },
    search: "",
    limit: 10,
    page: 1,
  });

  useEffect(() => {
    if (hasModules("contactTags")) {
      getTags({
        search: "",
        type: "contact",
      });
    }
  }, [companyModules]);

  return (
    <DashboardLayout
      selected="register"
      subSelected="contacts"
      hidden={match?.pathname !== pathname}
    >
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />
      <PageTitle label="Contatos">
        <Box>
          <Styled.Button
            variant="contained"
            color="primary"
            to={PATHS.CONTACTS_ADD}
            onClick={() => setContact({} as ContactType)}
          >
            <AddCircleIcon />
            <Typography variant="body2">Novo contato</Typography>
          </Styled.Button>
        </Box>
      </PageTitle>
      <OptionsPanel filters={filters} setFilters={setFilters} />

      <Tabs
        tab={type}
        setTab={value => {
          setType(value);
          setFilters({
            ...filters,
            page: 1,
          });
        }}
        ariaLabel="Contatos"
        tabs={TABS}
        tabPanels={[
          <CustomerTable
            key="customer-table"
            filters={filters}
            setFilters={setFilters}
          />,
          <ProviderTable
            key="provider-table"
            filters={filters}
            setFilters={setFilters}
          />,
          <OthersTable
            key="others-table"
            filters={filters}
            setFilters={setFilters}
          />,
        ]}
      />
    </DashboardLayout>
  );
};

export default withContext(ContactsList, ContactTypeProvider);
