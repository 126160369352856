import styled from "styled-components";

import { Box } from "@material-ui/core";

export const SwitchManageStockContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
