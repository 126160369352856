import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface ChangeCompanyLogoType {
  logo: string;
  type: string;
}

export const changeCompanyLogo = (params: ChangeCompanyLogoType) =>
  api.put(`/v1/user/company`, params).then(res => res.data);

export function useChangeCompanyLogo(
  config?: UseMutationOptions<any, any, ChangeCompanyLogoType, unknown>
) {
  return useMutation<any, any, ChangeCompanyLogoType>(
    (data: ChangeCompanyLogoType) => changeCompanyLogo(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao alterar logo"
        );
      },
      ...config,
    }
  );
}
