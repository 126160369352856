import { useMemo } from "react";

import { Button, Circle, Text } from "@chakra-ui/react";

import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";

export function ItemsOfOrderButton() {
  const { order } = useOrderForm();
  const { handleOpen } = useBaseSwipeableDrawer();

  const quantityOfItems = useMemo(() => {
    const quantityOfProducts =
      order?.products?.reduce(
        (total, productOrder) => total + productOrder.quantity,
        0
      ) ?? 0;
    const quantityOfServices =
      order?.services?.reduce(
        (total, serviceOrder) => total + serviceOrder.quantity,
        0
      ) ?? 0;

    return quantityOfProducts + quantityOfServices;
  }, [order]);

  return (
    <Button
      variant="outline"
      onClick={() => {
        handleOpen("orderItemsModal");
      }}
      rightIcon={
        <Circle bg="primary.500" size={quantityOfItems > 999 ? "32px" : "28px"}>
          <Text color="white" fontSize="xs">
            {quantityOfItems}
          </Text>
        </Circle>
      }
    >
      Carrinho
    </Button>
  );
}
