import React from "react";

import Box from "@material-ui/core/Box";

import Typography from "src/components/Typography";
import { formatPrice } from "src/utils/format";

import { DreItemCardProps } from "./props";
import * as Styled from "./styles";

const DreItemCard: React.FC<DreItemCardProps> = props => {
  const { dre } = props;

  const getColor = (type: string, total: number) => {
    switch (type) {
      case "income":
        return "#074EE8";
      case "expense":
        return "#E54848";
      default:
        return total < 0 ? "#E54848" : "#14B738";
    }
  };

  const getSymbol = (type: string) => {
    switch (type) {
      case "income":
        return "(+) ";
      case "expense":
        return "(-) ";
      default:
        return "(=) ";
    }
  };

  if (!dre.data) {
    return (
      <Styled.RowItem>
        <Typography variant="subtitle2" color="textSecondary">
          {getSymbol(dre.type)}
          {dre.title}
        </Typography>
        <Typography
          variant="subtitle2"
          style={{ color: getColor(dre.type, dre.total) }}
        >
          {formatPrice(dre.total)}
        </Typography>
      </Styled.RowItem>
    );
  }

  return (
    <Styled.Accordion TransitionProps={{ unmountOnExit: true }} square>
      <Styled.AccordionSummary
        expandIcon={<Styled.ExpandIcon color="primary" />}
      >
        <Box width="100%" display="flex" alignItems="center">
          <Typography variant="subtitle2" color="textSecondary">
            {getSymbol(dre.type)}
            {dre.title}
          </Typography>
        </Box>
        <Typography
          variant="subtitle2"
          style={{ color: getColor(dre.type, dre.total) }}
        >
          {formatPrice(dre.total)}
        </Typography>
      </Styled.AccordionSummary>

      <Styled.AccordionDetails>
        {dre?.data?.map(dre => (
          <Styled.RowSubItem key={dre.id}>
            <Typography variant="subtitle1" color="textSecondary">
              {dre.name}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ color: getColor(dre.type, dre.total ?? 0) }}
            >
              {formatPrice(dre.total)}
            </Typography>
          </Styled.RowSubItem>
        ))}
      </Styled.AccordionDetails>
    </Styled.Accordion>
  );
};

export default DreItemCard;
