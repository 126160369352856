import React, { useEffect, useState } from "react";

import { Box, Text, Button, Flex, Tag } from "@chakra-ui/react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";

import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { Select } from "src/components/NewComponents/Select";
import { useCompany } from "src/hooks/company/useCompany";
import { ContactProvider } from "src/hooks/contact/useContact";
import { useModule } from "src/hooks/module/useModule";
import { Option } from "src/interfaces/common";
import { ContactType, SubContactType } from "src/interfaces/contact";
import ContactManagerModal from "src/pages/Contacts/components/ContactManagerModal";
import AddSubContactModal from "src/pages/Contacts/Detail/components/AddSubContactModal";
import { useContacts } from "src/services/hooks/contact/useContacts";
import { useSubContacts } from "src/services/hooks/subContact/useSubContacts";
import { useDebounce } from "src/utils/debounce";
import { withContext } from "src/utils/withContext";

import * as Styled from "./styles";

interface CustomerSectionProps {
  customer: ContactType | undefined;
  subContact: SubContactType | undefined;
  setCustomer: (customer: ContactType | undefined) => void;
  setSubContact: (subContact: SubContactType | undefined) => void;
}

const CustomerSection: React.FC<CustomerSectionProps> = ({
  customer,
  subContact,
  setCustomer,
  setSubContact,
}) => {
  const { hasModules } = useModule();
  const { subContactNameDefinitions } = useCompany();
  const { handleOpen: handleOpenBaseSwipeableDrawer } =
    useBaseSwipeableDrawer();

  const [customers, setCustomers] = useState<ContactType[]>([]);
  const [subContacts, setSubContacts] = useState<SubContactType[]>([]);

  const [search, setSearch] = useState("");
  const searchValue = useDebounce<string>(search);

  const [method, setMehtod] = useState<"add" | "update">("add");

  useEffect(() => {
    if (!customer && !subContact) {
      setCustomers([]);
      setSubContacts([]);
      setSearch("");
    }
  }, [customer, subContact]);

  const { isLoading: isLoadingContacts, isFetching: isFetchingContacts } =
    useContacts(
      {
        page: 1,
        limit: 10,
        search: searchValue,
        type: "customer",
      },
      {
        enabled: searchValue.length > 0,
        onSuccess(data) {
          setCustomers(data.contacts);
        },
      }
    );

  const { isLoading: isLoadingSubContacts, isFetching: isFetchingSubContacts } =
    useSubContacts(
      {
        contact: customer?.id as string,
      },
      {
        enabled: customer !== undefined && hasModules("subcustomers"),
        onSuccess(data) {
          setSubContacts(data);
          if (data.length) {
            setSubContact(data[0]);
          }
        },
      }
    );

  return (
    <Box w="100%">
      <Text color="text" mb={2.5}>
        Cliente (Opcional)
      </Text>

      <Flex align="flex-start" w="100%" gap={4}>
        <Box w="100%">
          <Select
            value={customer?.id}
            options={customers.map(customer => ({
              label: customer.name,
              value: customer.id,
              ...customer,
            }))}
            formatOptionLabel={(data: unknown) => {
              const customerData = data as Option;
              return (
                <Flex alignItems="center" gap={2} fontSize="sm">
                  {customerData.name}
                  {customer?.id !== customerData.value
                    ? customerData.subContacts?.map(
                        (subContact: SubContactType) => {
                          return (
                            <Tag
                              bg="#ACE2F6"
                              color="#074EE8"
                              fontSize="xs"
                              size="sm"
                              borderRadius="full"
                              key={subContact.id}
                            >
                              {subContact?.data && subContact?.data[0].content}
                            </Tag>
                          );
                        }
                      )
                    : null}
                </Flex>
              );
            }}
            isLoading={isLoadingContacts || isFetchingContacts}
            onInputChange={(inputValue, event) => {
              if (
                event.action !== "input-blur" &&
                event.action !== "menu-close" &&
                event.action !== "set-value"
              ) {
                setSearch(inputValue);
              }
            }}
            onChange={newInputValue => {
              const customer = customers.find(
                customer => customer.id === newInputValue
              );

              setCustomer(customer as ContactType);
              setSubContact({} as SubContactType);
              setSubContacts([]);
            }}
            placeholder="Procurar cliente..."
            showFloatingLabel={false}
          />

          {hasModules("subcustomers") &&
          !isLoadingSubContacts &&
          !isFetchingSubContacts ? (
            <Flex flexWrap="wrap" columnGap={2} rowGap={1} mt={2}>
              {subContacts.map(item => {
                const isSelected = item.id === subContact?.id;
                const label = (item.data ?? [])
                  .map(data => data?.content)
                  .slice(0, 2)
                  .filter(data => data)
                  .join(" - ");

                const bg = isSelected ? "#ACE2F6" : "#D6D8DC";

                return (
                  <Button
                    key={item.id}
                    borderRadius={10}
                    variant="solid"
                    bg={bg}
                    _hover={{
                      bg,
                    }}
                    _focus={{
                      bg,
                    }}
                    _active={{
                      bg,
                    }}
                    color={isSelected ? "#074EE8" : "#5B5C60"}
                    size="sm"
                    onClick={() => {
                      if (isSelected) {
                        setSubContact({} as SubContactType);
                      } else {
                        setSubContact(item);
                      }
                    }}
                    rightIcon={
                      isSelected ? (
                        <CheckCircleIcon
                          style={{ color: "#074EE8" }}
                          fontSize="small"
                        />
                      ) : undefined
                    }
                  >
                    {label}
                  </Button>
                );
              })}

              {!!customer?.id && (
                <>
                  <Button
                    onClick={() =>
                      handleOpenBaseSwipeableDrawer("createSubContact")
                    }
                    color="primary.500"
                    variant="ghost"
                    size="sm"
                    borderRadius="full"
                  >
                    Adicionar {subContactNameDefinitions.title.toLowerCase()}
                    <Styled.AddCircleIcon color="primary" />
                  </Button>
                  <AddSubContactModal
                    contactId={customer.id}
                    setSubContactCreated={subcontact => {
                      setSubContact(subcontact);
                      setSubContacts(prev => [...prev, subcontact]);
                    }}
                  />
                </>
              )}
            </Flex>
          ) : null}
        </Box>

        {customer?.id ? (
          <Button
            onClick={() => {
              setMehtod("update");
              handleOpenBaseSwipeableDrawer("createContactModal");
            }}
            leftIcon={<EditIcon />}
            minWidth={150}
          >
            Editar
          </Button>
        ) : null}

        <Button
          onClick={() => handleOpenBaseSwipeableDrawer("createContactModal")}
          leftIcon={<AddCircleIcon />}
          minWidth={150}
        >
          Cliente
        </Button>
      </Flex>

      <ContactManagerModal
        method={method}
        contact={customer}
        setContact={customer => {
          setCustomer(customer);

          if (method === "add") {
            setSubContact({} as SubContactType);
            setSubContacts([]);
            setCustomers(customers => [...customers, customer]);
          } else {
            setCustomers(customers =>
              customers.map(option =>
                option.id === customer.id ? customer : option
              )
            );
          }
        }}
        contactType="customer"
      />
    </Box>
  );
};

export default withContext(
  CustomerSection,
  ContactProvider,
  BaseSwipeableDrawerProvider
);
