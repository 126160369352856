import { CreatedAndUpdateType } from "./common";
import { ContactType } from "./contact";
import { PaymentStatusNameType } from "./order";
import { ProductType } from "./product";
import { RecordType } from "./record";
import { GetResponseType } from "./request";

export enum BuyOrderStatusEnum {
  open = "open",
  pending = "pending",
  finished = "finished",
}

export interface ProductBuyOrderType {
  id: string;
  product: ProductType;
  quantity: number;
  cost: number;
  validity?: string;
}

export interface BuyOrderType extends CreatedAndUpdateType {
  id: string;
  number?: number;
  provider: ContactType;

  active?: boolean;

  deadline?: string;
  createdAt: string;
  createdDate: string;

  status: BuyOrderStatusEnum;
  additionalInfo: string;

  grossAmount: number;
  totalAmount: number;
  discount: number;
  discountType: string;
  paymentType: string;

  downPaymentType: string;
  downPaymentValue: number;

  remainingPaymentType: string;
  remainingPaymentValue: number;

  products: ProductBuyOrderType[];
  records: RecordType[];

  isPaid: PaymentStatusNameType | null;

  stockCreatedAt: string | null;
  recordsCreatedAt: string | null;
}

export interface GetBuyOrderResponseType extends GetResponseType {
  buyOrders: BuyOrderType[];
}

export interface BuyOrderListParamsType {
  page?: number;
  limit?: number;
  search?: string;
  status?: "" | BuyOrderStatusEnum;
  startDate?: Date | string;
  endDate?: Date | string;
}

export interface ProductBuyOrderFormType {
  id: string;
  productSelected?: Partial<ProductType>;
  product: {
    id: string;
    stock?: string;
  };
  quantity: number;
  cost: number;
  delete: boolean;
  validity?: string;
}

export interface BuyOrderFormType {
  id?: string;
  provider?: string;
  deadline?: string;
  createdDate?: string;
  additionalInfo: string;

  paymentMethod?: string;

  products: ProductBuyOrderFormType[];

  paymentType: string;
  discount: number;
  discountType: string;
  downPaymentType?: string;
  downPaymentValue?: number;
  remainingPaymentType?: string;
  remainingPaymentValue?: number;
}

export interface BuyOrderStatusType {
  status: BuyOrderStatusEnum | "";
  count: number;
  totalAmount: number;
}

export interface BuyOrderStatuesMetaParamsType {
  startDate?: Date | string;
  endDate?: Date | string;
  providerId?: string;
}

export interface UpdateBuyOrderStatusParams {
  status: BuyOrderStatusEnum;
  id: string;
}
