import React, { FC } from "react";

import { GridColDef } from "@material-ui/data-grid";

import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { ProductType } from "src/interfaces/product";
import { formatPrice } from "src/utils/format";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Produto",
    flex: 1,
    sortComparator: (v1, v2) => {
      const name = v1 ? (v1 as string).toLowerCase() : "-";
      const nameComparable = v2 ? (v2 as string).toLowerCase() : "-";

      if (name < nameComparable) {
        return -1;
      }
      if (name > nameComparable) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "quantity",
    headerName: "Quantidade",
    renderCell: params => params.row?.stock?.quantity ?? 0,
    flex: 1,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "cost",
    headerName: "Custo unit.",
    renderCell: params => formatPrice(params.row?.cost ?? 0),
    flex: 1,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "inventory",
    headerName: "Inventário",
    renderCell: params =>
      formatPrice((params.row?.cost ?? 0) * params.row?.stock?.quantity ?? 0),
    flex: 1,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
];

export interface InventoryTableProps {
  sales: ProductType[];
}

const InventoryTable: FC<InventoryTableProps> = ({ sales }) => {
  const [page, setPage] = React.useState(1);

  return (
    <Table
      sortingOrder={["desc", "asc"]}
      rows={sales.map((sale, index) => ({
        ...sale,
        id: `${index}${Math.random()}`,
      }))}
      columns={columns}
      page={page}
      setPage={setPage}
      pageSize={sales.length}
      onPageSizeChange={undefined}
      filterMode="server"
      totalCount={sales.length}
      hideFooter
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <Typography>Nenhuma relatório disponível</Typography>
          </EmptyTable>
        ),
      }}
    />
  );
};

export default InventoryTable;
