import React from "react";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import * as Styled from "./styles";

interface PhotoPreviewProps {
  url: string;
  handleDelete: () => void;
  handleOpenImageUploadUpdateModal: () => void;
}

const PhotoPreview: React.FC<PhotoPreviewProps> = props => {
  const { url, handleDelete, handleOpenImageUploadUpdateModal } = props;

  return (
    <Styled.PhotoContainer>
      <Styled.Photo src={url} />
      <Styled.PhotoOptions>
        <Styled.ActionButton onClick={handleDelete} type="button">
          <DeleteIcon color="primary" fontSize="small" />
        </Styled.ActionButton>
        <Styled.Separator />
        <Styled.ActionButton
          onClick={handleOpenImageUploadUpdateModal}
          type="button"
        >
          <CreateIcon color="primary" fontSize="small" />
        </Styled.ActionButton>
      </Styled.PhotoOptions>
    </Styled.PhotoContainer>
  );
};

export default PhotoPreview;
