import React from "react";
import { RouteProps, Navigate, useLocation, Outlet } from "react-router-dom";

import { Flex, Spinner } from "@chakra-ui/react";

import PATHS from "src/constants/paths";
import { useAuth } from "src/hooks/user/auth/useAuth";

const PublicRoute: React.FC<RouteProps> = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return (
      <Flex align="center" justify="center" h="100vh">
        <Spinner size="lg" color="primary.500" />
      </Flex>
    );
  }

  if (isAuthenticated) {
    return <Navigate to={PATHS.HOME} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
