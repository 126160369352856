import React, { useMemo } from "react";

import Grid from "@material-ui/core/Grid";

import { useCompany } from "src/hooks/company/useCompany";
import { useOrder } from "src/hooks/order/useOrder";
import { SubContactFieldDataType } from "src/interfaces/contact";
import { formatPhone } from "src/utils/format";

import * as Styled from "./styles";

const ContactData: React.FC = () => {
  const { order } = useOrder();
  const { subContactNameDefinitions } = useCompany();

  const getLabelValue = (value?: string) => {
    if (value) return value;

    return "-";
  };

  const getSubContactLabelValue = (data?: SubContactFieldDataType[]) => {
    let label = "-";

    if (data && data.length) {
      label = `${data[0]?.content}${
        data.length > 1 && data[1]?.content ? ` - ${data[1].content}` : ""
      }`;
    }

    return label;
  };

  const address = useMemo(() => {
    const customer = order?.contact;

    if (!customer) return "-";

    const street = customer.street ? `${customer.street}, ` : "";
    const number = customer.number ? `${customer.number}, ` : "";
    const complement = customer.complement ? `${customer.complement}, ` : "";
    const neighborhood = customer.neighborhood
      ? `${customer.neighborhood}, `
      : "";
    const city = customer.city ? `${customer.city}` : "";

    if (!street && !number && !complement && !neighborhood && !city) return "-";

    return `${street}${number}${neighborhood}${city}${complement}`;
  }, [order?.contact]);

  return (
    <>
      <Grid item>
        <Styled.LabelText>Cliente</Styled.LabelText>
        <Styled.LabelValue>
          {getLabelValue(order?.contact?.name)}
        </Styled.LabelValue>
      </Grid>
      {!!order?.subContact && (
        <Grid item>
          <Styled.LabelText>{subContactNameDefinitions.title}</Styled.LabelText>
          <Styled.LabelValue>
            {getSubContactLabelValue(order?.subContact?.data ?? [])}
          </Styled.LabelValue>
        </Grid>
      )}

      <Grid item>
        <Styled.LabelText>Telefone</Styled.LabelText>
        <Styled.LabelValue>
          {order?.contact?.phone ? formatPhone(order?.contact?.phone) : "-"}
        </Styled.LabelValue>
      </Grid>
      {order?.contact?.alternativePhones?.length ? (
        <Grid item>
          <Styled.LabelText>Telefones alternativos</Styled.LabelText>
          <Styled.LabelValue>
            {order?.contact?.alternativePhones
              ?.map(phone => formatPhone(phone.number))
              ?.join(", ")}
          </Styled.LabelValue>
        </Grid>
      ) : null}
      <Grid item>
        <Styled.LabelText>Endereço</Styled.LabelText>
        <Styled.LabelValue>{address}</Styled.LabelValue>
      </Grid>
      <Grid item>
        <Styled.LabelText>Cidade</Styled.LabelText>
        <Styled.LabelValue>
          {getLabelValue(order?.contact?.city)}
        </Styled.LabelValue>
      </Grid>
    </>
  );
};

export default ContactData;
