import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  TabPanel,
  Wrap,
  Button,
  Divider,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  FormErrorMessage,
  FormControl,
  Box,
} from "@chakra-ui/react";
import * as yup from "yup";

import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import {
  PRODUCTS_MEASURES_SHORT,
  SERVICES_MEASURES_SHORT,
} from "src/constants/measures";
import { useModule } from "src/hooks/module/useModule";
import {
  isServiceOrderForm,
  ProductOrderForm,
  ServiceOrderForm,
} from "src/hooks/order/useOrderForm/props";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";
import { ProductType } from "src/interfaces/product";
import { ServiceType } from "src/interfaces/service";
import { formatPrice } from "src/utils/format";

import { CreateOrderBottomBar } from "../CreateOrderBottomBar";
import TechnicalReportInfo from "../OrderDataForm/components/TechnicalReportInfo";
import { AddOrEditItemForm } from "./components/AddOrEditItemForm";
import { ProdructOrServiceSearch } from "./components/ProdructOrServiceSearch";
import { orderItemsSchema } from "./schema";

export type OrderItemsSchema = yup.InferType<typeof orderItemsSchema>;

export function OrderItemsForm({ ...props }) {
  const navigation = useNavigate();
  const { handleToNextStep, setOrder, methodsOrderItems, type } =
    useOrderForm();

  const { hasModules } = useModule();
  const { handleOpen } = useBaseSwipeableDrawer();
  const [
    showTechnicalReportInfoModalCount,
    setShowTechnicalReportInfoModalCount,
  ] = useState(0);

  const [item, setItem] = useState<ProductOrderForm | ServiceOrderForm | null>(
    null
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methodsOrderItems;

  const {
    fields: products,
    append: appendProductOrder,
    update: updateProductOrder,
    remove: removeProductOrder,
  } = useFieldArray({
    control,
    name: "products",
    keyName: undefined,
  });

  const {
    fields: services,
    append: appendServiceOrder,
    update: updateServiceOrder,
    remove: removeServiceOrder,
  } = useFieldArray({
    control,
    name: "services",
    keyName: undefined,
  });

  const total = useMemo(() => {
    const totalProducts = products.reduce((acc, productSchema) => {
      const product = productSchema as ProductOrderForm;
      return acc + product.price * product.quantity;
    }, 0);
    const totalServices = services.reduce((acc, serviceSchema) => {
      const service = serviceSchema as ServiceOrderForm;
      return acc + service.price * service.quantity;
    }, 0);

    return totalProducts + totalServices;
  }, [products, services]);

  const handleSetAddedItem = (
    item: ProductType | ServiceType,
    type: "product" | "service"
  ) => {
    if (type === "product") {
      setItem({
        id: item.id,
        product: item as ProductType,
        quantity: 1,
        price: item.price,
        stock: (item as ProductType)?.stock?.id,
      });
    } else {
      setItem({
        id: item.id,
        service: item as ServiceType,
        quantity: 1,
        price: item.price,
      });
    }
  };

  const onSubmit = ({ products, services }: OrderItemsSchema) => {
    setOrder(prev => ({
      ...(prev && prev),
      products: products as ProductOrderForm[],
      services: services as ServiceOrderForm[],
    }));
    if (
      showTechnicalReportInfoModalCount === 0 &&
      type === "serviceOrder" &&
      hasModules("technicalReport")
    ) {
      handleOpen("technicalReportInfo");
      setShowTechnicalReportInfoModalCount(1);
    } else {
      handleToNextStep();
    }
  };

  useEffect(() => {
    const handleSubmitAfterKeyDown = (e: globalThis.KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
        handleSubmit(onSubmit)();
      }
    };

    window.addEventListener("keydown", handleSubmitAfterKeyDown);

    return () =>
      window.removeEventListener("keydown", handleSubmitAfterKeyDown);
  }, []);

  const getAddOrEditItemForm = (
    item: ProductOrderForm | ServiceOrderForm,
    key?: string
  ) => {
    return (
      <AddOrEditItemForm
        key={key}
        item={item}
        setProductOrder={(productOrder: ProductOrderForm) => {
          const data = {
            id: productOrder?.product?.id,
            product: productOrder?.product,
            price: productOrder.price,
            stock: productOrder.stock,
            productId: undefined,
            quantity: productOrder.quantity,
            delete: undefined,
          };
          if (productOrder.index !== undefined) {
            updateProductOrder(productOrder.index, data);
          } else {
            appendProductOrder(data);
          }
          setItem(null);
        }}
        setServiceOrder={(serviceOrder: ServiceOrderForm) => {
          const data = {
            id: serviceOrder?.service?.id,
            service: serviceOrder?.service,
            price: serviceOrder.price,
            serviceId: undefined,
            quantity: serviceOrder.quantity,
            delete: undefined,
          };
          if (serviceOrder.index !== undefined) {
            updateServiceOrder(serviceOrder.index, data);
          } else {
            appendServiceOrder(data);
          }
          setItem(null);
        }}
        removeProductOrder={index => {
          removeProductOrder(index);
          setItem(null);
        }}
        removeServiceOrder={index => {
          removeServiceOrder(index);
          setItem(null);
        }}
      />
    );
  };

  return (
    <TabPanel {...props} p={0}>
      <Divider my={4} />

      <FormControl isInvalid={!!errors.services}>
        <ProdructOrServiceSearch setItem={handleSetAddedItem} />
        <FormErrorMessage>{errors.services?.message}</FormErrorMessage>
      </FormControl>

      <Divider my={4} />

      {item && item.index === undefined ? (
        <Box
          w="100%"
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          bg="white"
          borderRadius={10}
          border={
            item || products.length || services.length
              ? "1px solid #D6D8DC"
              : undefined
          }
          mb={4}
          py={4}
          px={4}
        >
          {getAddOrEditItemForm(item)}
        </Box>
      ) : null}

      <Box overflowX="auto">
        {services?.length ? (
          <TableContainer
            bg="white"
            border="1px solid #D6D8DC"
            borderRadius={10}
            mb={4}
          >
            <Table
              variant="simple"
              sx={{
                th: {
                  paddingTop: 4,
                  fontWeight: 500,
                  fontSize: "md",
                  textTransform: "capitalize",
                  color: "text",
                },
                "th,tr,td": {
                  paddingLeft: 2,
                  paddingRight: 2,
                },
                "th,td": {
                  borderBottom: "1px solid rgba(179, 182, 188, .3)",
                },
                "th:first-of-type,tr:first-of-type,td:first-of-type": {
                  paddingLeft: 4,
                },
                "th:last-of-type,tr:last-of-type,td:last-of-type": {
                  paddingRight: 4,
                },
                "tr:last-of-type": {
                  td: {
                    borderBottom: "none",
                  },
                },
              }}
            >
              <Thead>
                <Tr>
                  <Th>Serviço</Th>
                  <Th>Qtde</Th>
                  <Th>Preço un</Th>
                  <Th>Preço total</Th>
                  <Th textAlign="right">Ações</Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">
                {services.map((serviceOrder, index) => {
                  if (
                    item &&
                    isServiceOrderForm(item) &&
                    item.index === index
                  ) {
                    return getAddOrEditItemForm(
                      item,
                      `${Math.random()}${index.toString()}`
                    );
                  }

                  return (
                    <Tr
                      key={`${Math.random()}${index.toString()}`}
                      cursor="pointer"
                      onClick={() =>
                        setItem({
                          ...serviceOrder,
                          index,
                        } as ServiceOrderForm)
                      }
                    >
                      <Td color="primary.500" w="30%">
                        {serviceOrder.service?.name}
                        {serviceOrder.service?.unitOfMeasure
                          ? ` (${
                              SERVICES_MEASURES_SHORT[
                                serviceOrder.service?.unitOfMeasure
                              ]
                            })`
                          : ""}
                      </Td>
                      <Td
                        minW={{
                          base: "200px",
                          md: "150px",
                        }}
                      >
                        {serviceOrder.quantity}
                      </Td>
                      <Td
                        minW={{
                          base: "200px",
                          md: "150px",
                        }}
                      >
                        {formatPrice(serviceOrder.price)}
                      </Td>
                      <Td
                        minW={{
                          base: "200px",
                          md: "150px",
                        }}
                      >
                        {formatPrice(
                          (serviceOrder.price ?? 0) *
                            (serviceOrder.quantity ?? 0)
                        )}
                      </Td>
                      <Td textAlign="right" w="200px">
                        <Button variant="ghost" size="sm">
                          Editar
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        ) : null}
        {products?.length ? (
          <TableContainer
            bg="white"
            border="1px solid #D6D8DC"
            borderRadius={10}
            mb={4}
          >
            <Table
              variant="simple"
              colorScheme="gray"
              sx={{
                th: {
                  paddingTop: 4,
                  fontWeight: 500,
                  fontSize: "md",
                  textTransform: "capitalize",
                  color: "text",
                },
                "th,tr,td": {
                  paddingLeft: 2,
                  paddingRight: 2,
                },
                "th,td": {
                  borderBottom: "1px solid rgba(179, 182, 188, .3)",
                },
                "th:first-of-type,tr:first-of-type,td:first-of-type": {
                  paddingLeft: 4,
                },
                "th:last-of-type,tr:last-of-type,td:last-of-type": {
                  paddingRight: 4,
                },
                "tr:last-of-type": {
                  td: {
                    borderBottom: "none",
                  },
                },
              }}
            >
              <Thead>
                <Tr>
                  <Th fontSize="md">Produto</Th>
                  <Th fontSize="md">Qtde</Th>
                  <Th fontSize="md">Preço un</Th>
                  <Th fontSize="md">Preço total</Th>
                  <Th textAlign="right">Ações</Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">
                {products.map((productOrder, index) => {
                  if (
                    item &&
                    !isServiceOrderForm(item) &&
                    item.index === index
                  ) {
                    return getAddOrEditItemForm(
                      item,
                      `${Math.random()}${index.toString()}`
                    );
                  }

                  return (
                    <Tr
                      key={`${Math.random()}${index.toString()}`}
                      cursor="pointer"
                      onClick={() =>
                        setItem({
                          ...productOrder,
                          index,
                        } as ProductOrderForm)
                      }
                    >
                      <Td color="primary.500" w="30%">
                        {productOrder.product?.name}
                        {productOrder.product?.unitOfMeasure
                          ? ` (${
                              PRODUCTS_MEASURES_SHORT[
                                productOrder.product?.unitOfMeasure
                              ]
                            })`
                          : ""}
                      </Td>
                      <Td
                        minW={{
                          base: "200px",
                          md: "150px",
                        }}
                      >
                        {productOrder.quantity}
                      </Td>
                      <Td
                        minW={{
                          base: "200px",
                          md: "150px",
                        }}
                      >
                        {formatPrice(productOrder.price)}
                      </Td>
                      <Td
                        minW={{
                          base: "200px",
                          md: "150px",
                        }}
                      >
                        {formatPrice(
                          (productOrder.price ?? 0) *
                            (productOrder.quantity ?? 0)
                        )}
                      </Td>
                      <Td textAlign="right" w="200px">
                        <Button variant="ghost" size="sm">
                          Editar
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>

      <TechnicalReportInfo />
      <CreateOrderBottomBar
        subtotal={total}
        discount={0}
        total={total}
        right={
          <Wrap spacing={4}>
            <Button variant="outline" onClick={() => navigation(-1)}>
              Cancelar
            </Button>
            <Button type="submit" onClick={handleSubmit(onSubmit)}>
              Avançar
            </Button>
          </Wrap>
        }
      />
    </TabPanel>
  );
}
