import styled from "styled-components";

export const PeriodFilterPickerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -20px;
`;

export const Container = styled.div`
  position: relative;
`;
