import styled from "styled-components";

import AppBarUnstyled from "@material-ui/core/AppBar";

import { ReactComponent as Equals } from "src/assets/icons/orderBottomApp/equals.svg";
import { ReactComponent as Minus } from "src/assets/icons/orderBottomApp/minus.svg";
import { ReactComponent as Plus } from "src/assets/icons/orderBottomApp/plus.svg";
import ButtonComponent from "src/components/Button";
import Typography from "src/components/Typography";

export const AppBar = styled(AppBarUnstyled)`
  top: auto;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-top: 1px solid #d6d8dc;
  box-shadow: none;
  color: unset;
`;

export const Container = styled.div`
  margin-left: 250px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
`;

export const Button = styled(ButtonComponent)`
  height: 50px;
`;

export const LabelData = styled(Typography)`
  font-weight: 500;
  color: #1e2c47;
  font-size: 13px;
  display: flex;
  align-items: center;
`;

export const LabelTotalValue = styled(Typography)`
  font-weight: 500;
  color: #1e2c47;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const LabelIconMinus = styled(Minus)`
  margin-left: 6px;
`;
export const LabelIconPlus = styled(Plus)`
  margin-left: 6px;
`;
export const LabelIconEquals = styled(Equals)`
  margin-left: 6px;
`;

export const ValueData = styled(Typography)<{ isRed?: boolean }>`
  font-weight: 700;
  color: ${({ theme, isRed }) =>
    isRed ? theme.palette.error.main : theme.palette.primary.main};
  font-size: 20px;
`;
