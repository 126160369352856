import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

import { ButtonProps } from "./props";
import * as Styled from "./styles";

const Button: React.FC<ButtonProps> = props => {
  const { palette } = useTheme();
  const {
    children,
    loading,
    loadingSeleton,
    heightSkeleton = 25,
    widthSkeleton = 30,
    to,
    linkProps,
    loadingColor = palette.common.white,
    ...rest
  } = props;

  const ButtonContent = (
    <Styled.Button {...rest} disableElevation>
      {loading ? (
        <CircularProgress style={{ color: loadingColor }} size={20} />
      ) : (
        children
      )}
    </Styled.Button>
  );

  if (loadingSeleton) {
    return (
      <Styled.Container>
        <Styled.ButtonLoading
          disableElevation
          variant="contained"
          color="primary"
          disabled
        >
          <Skeleton
            variant="text"
            height={heightSkeleton}
            width={widthSkeleton}
          />
        </Styled.ButtonLoading>
      </Styled.Container>
    );
  }

  if (to) {
    return (
      <Styled.Link to={to} {...linkProps}>
        {ButtonContent}
      </Styled.Link>
    );
  }

  return ButtonContent;
};

export default Button;
