const A_UNITY = 1;

export const sortArrayByKeyASC = (arr: Array<any>, key: string) =>
  // eslint-disable-next-line no-nested-ternary
  arr.sort((a, b) => (a[key] < b[key] ? 1 : a[key] > b[key] ? -A_UNITY : 0));

export const sortArrayByKeyDESC = (arr: Array<any>, key: string) =>
  // eslint-disable-next-line no-nested-ternary
  arr.sort((a, b) => (a[key] < b[key] ? -A_UNITY : a[key] > b[key] ? 1 : 0));

export const sumArrayValues = (arr: Array<number>) =>
  arr.reduce((a, b) => a + b, 0);
