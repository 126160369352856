import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Button from "src/components/Button";
import { ModalProvider, useModal } from "src/components/Modal/useModal";
import PATHS from "src/constants/paths";
import { BuyOrderType } from "src/interfaces/buyOrder";
import BuyOrderDeleteModal from "src/pages/BuyOrders/components/BuyOrderDeleteModal";
import { getRouteString } from "src/utils/urlHandler";

import { BuyOrdersTableOptionsMenuProps } from "./props";
import * as Styled from "./styles";

const DeleteModalOption: React.FC<{
  buyOrder: BuyOrderType;
  afterActions(): void;
}> = props => {
  const { afterActions, buyOrder } = props;

  const { handleOpen } = useModal();

  const cantDeleteBuyOrder =
    !!buyOrder.stockCreatedAt || !!buyOrder.recordsCreatedAt;

  return (
    <>
      <Box position="relative" display="flex" alignItems="center">
        <Styled.DeleteButton
          fullWidth
          onClick={() => {
            if (!cantDeleteBuyOrder) {
              handleOpen();
            }
          }}
          disabled={cantDeleteBuyOrder}
        >
          Excluir
        </Styled.DeleteButton>

        {cantDeleteBuyOrder ? (
          <Styled.Tooltip
            classes={{
              tooltip: "custom__tooltip",
            }}
            title={
              <>
                <Box
                  component="span"
                  fontSize="15px"
                  fontWeight="500"
                  color="#fff"
                >
                  Exclusão desabilitada.{" "}
                </Box>
                <Box
                  component="span"
                  fontSize="15px"
                  fontWeight="400"
                  color="#d6d8dc"
                >
                  Para excluir esta ordem de compra é preciso estornar o estoque
                  e pagamentos!
                </Box>
              </>
            }
            placement="bottom-end"
            arrow
          >
            <Styled.TooltipIcon />
          </Styled.Tooltip>
        ) : null}
      </Box>
      <BuyOrderDeleteModal id={buyOrder.id} afterActions={afterActions} />
    </>
  );
};

const BuyOrdersTableOptionsMenu: React.FC<BuyOrdersTableOptionsMenuProps> =
  props => {
    const { buyOrder } = props;

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const cantUpdateBuyOrder =
      !buyOrder.stockCreatedAt && !buyOrder.recordsCreatedAt;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const renderOptionsByStatus = useMemo(() => {
      return (
        <>
          {cantUpdateBuyOrder ? (
            <Button
              fullWidth
              onClick={() => {
                setAnchorEl(null);
                navigate(
                  getRouteString(
                    PATHS.BUY_ORDER_UPDATE,
                    buyOrder.id,
                    "buyOrderId"
                  ),
                  {
                    state: {
                      data: buyOrder,
                    },
                  }
                );
              }}
            >
              Editar
            </Button>
          ) : null}

          <ModalProvider>
            <DeleteModalOption
              buyOrder={buyOrder}
              afterActions={() => {
                setAnchorEl(null);
              }}
            />
          </ModalProvider>
        </>
      );
    }, [buyOrder]);

    return (
      <>
        <Button
          aria-describedby={`more-options-menu-${buyOrder.id}`}
          onClick={handleClick}
        >
          <MoreVertIcon color="primary" />
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          onClose={handleClose}
          id={`more-options-menu-${buyOrder.id}`}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              borderRadius: 0,
            },
          }}
        >
          <Styled.MenuOverlay>
            <Styled.MenuContainer>{renderOptionsByStatus}</Styled.MenuContainer>
          </Styled.MenuOverlay>
        </Popover>
      </>
    );
  };

export default BuyOrdersTableOptionsMenu;
