import { STATUS } from "src/constants/requestStatus";
import { RequestStatusType } from "src/interfaces/request";

export enum StatusFinancialAccountActionEnum {
  LIST = "LIST",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  ARCHIVE_OR_UNARCHIVE = "ARCHIVE_OR_UNARCHIVE",
}

interface StatusFinancialAccountAction {
  type: StatusFinancialAccountActionEnum;
  payload: RequestStatusType;
}

interface StatusFinancialAccountState {
  listStatus: RequestStatusType;
  createStatus: RequestStatusType;
  updateStatus: RequestStatusType;
  deleteStatus: RequestStatusType;
  archiveOrUnarchiveStatus: RequestStatusType;
}

export function statusFinancialAccountReducer(
  state: StatusFinancialAccountState,
  action: StatusFinancialAccountAction
) {
  const { type, payload } = action;
  switch (type) {
    case StatusFinancialAccountActionEnum.LIST:
      return {
        ...state,
        listStatus: payload,
        createStatus: STATUS.inital,
        updateStatus: STATUS.inital,
        deleteStatus: STATUS.inital,
        archiveOrUnarchiveStatus: STATUS.inital,
      };
    case StatusFinancialAccountActionEnum.CREATE:
      return {
        ...state,
        createStatus: payload,
      };
    case StatusFinancialAccountActionEnum.UPDATE:
      return {
        ...state,
        updateStatus: payload,
      };
    case StatusFinancialAccountActionEnum.DELETE:
      return {
        ...state,
        deleteStatus: payload,
      };
    case StatusFinancialAccountActionEnum.ARCHIVE_OR_UNARCHIVE:
      return {
        ...state,
        archiveOrUnarchiveStatus: payload,
      };
    default:
      return state;
  }
}
