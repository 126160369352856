import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Formik } from "formik";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import {
  UpdateBuyOrderProvider,
  useUpdateBuyOrder,
} from "src/hooks/buy_order/useUpdateBuyOrder";
import {
  BuyOrderFormType,
  BuyOrderType,
  ProductBuyOrderFormType,
  ProductBuyOrderType,
} from "src/interfaces/buyOrder";
import { withContext } from "src/utils/withContext";

import BuyOrderForm from "../components/BuyOrderForm";
import AddBuyOrderBottomBar from "./components/BottomBar";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Produtos" },
  { label: "Ordens de compra" },
  { label: "Nova ordem de compra" },
];

interface LocationStateProp {
  data?: BuyOrderType;
}

const UpdateBuyOrderPage: React.FC = () => {
  const { buyOrderId = "" } = useParams<{ buyOrderId: string }>();
  const { state: localtionState } = useLocation();
  const navigate = useNavigate();

  const { buyOrder, setBuyOrder, getBuyOrder, buyOrderStatus } = useBuyOrder();

  const {
    updateBuyOrder,
    provider,
    setProvider,
    updateBuyOrderStatus,
    productsBuyOrder,
    setProductsBuyOrder,
    discount,
    setDiscount,
  } = useUpdateBuyOrder();

  const { setRestartOrderList } = useBuyOrderFilters();

  useEffect(() => {
    const state = localtionState as LocationStateProp;
    if (!state?.data && buyOrder) {
      if (buyOrder.recordsCreatedAt || buyOrder.stockCreatedAt) {
        navigate(-1);

        return;
      }
      setDiscount(buyOrder.discount);
      setProvider(buyOrder.provider);
      setProductsBuyOrder(getProductsBuyOrder(buyOrder.products));
    }
  }, [buyOrderStatus.success]);

  useEffect(() => {
    const state = localtionState as LocationStateProp;
    if (state?.data) {
      if (state.data.recordsCreatedAt || state.data.stockCreatedAt) {
        navigate(-1);

        return;
      }
      setBuyOrder(state.data);
      setDiscount(state.data.discount);
      setProvider(state.data.provider);
      setProductsBuyOrder(getProductsBuyOrder(state.data.products));
    }
    getBuyOrder(buyOrderId);
  }, []);

  const getProductsBuyOrder = (products: ProductBuyOrderType[]) => {
    return products.map<ProductBuyOrderFormType>(productBuyOrder => ({
      id: productBuyOrder.id,
      productSelected: productBuyOrder.product,
      product: {
        id: productBuyOrder.product.id,
        stock: productBuyOrder.product.stock?.id,
      },
      quantity: productBuyOrder.quantity,
      cost: productBuyOrder.cost,
      validity: productBuyOrder.validity,
      delete: false,
    }));
  };

  const handleCreateBuyOrder = (data: Partial<BuyOrderFormType>) => {
    if (productsBuyOrder.length === 0) {
      toast.error("Adicione no mínimo um produto!");
      return;
    }

    if (!provider?.id) {
      toast.error("Adicione no mínimo um fornecedor!");
      return;
    }
    if (data) {
      updateBuyOrder(data as BuyOrderFormType);
      setRestartOrderList(true);
    }
  };

  const renderContent = () => {
    if (updateBuyOrderStatus.loading) {
      return <Loader />;
    }

    return (
      <>
        <BuyOrderForm
          productsBuyOrder={productsBuyOrder}
          setProductsBuyOrder={setProductsBuyOrder}
          discount={discount}
          setDiscount={setDiscount}
          provider={provider}
          setProvider={setProvider}
          method="update"
          buyOrder={buyOrder ?? ({} as BuyOrderType)}
        />
        <AddBuyOrderBottomBar />
      </>
    );
  };

  return (
    <DashboardLayout
      selected="register"
      subSelected="products"
      modules={["stock", "buyorder"]}
    >
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <PageTitle label="Editar ordem de compra" mt={0} mb={3} />
      <Formik
        onSubmit={handleCreateBuyOrder}
        initialValues={
          {
            additionalInfo: buyOrder?.additionalInfo,
            discount: buyOrder?.discount,
            discountType: buyOrder?.discountType,

            downPaymentType: buyOrder?.downPaymentType,
            downPaymentValue: buyOrder?.downPaymentValue,
            remainingPaymentType: buyOrder?.remainingPaymentType,
            remainingPaymentValue: buyOrder?.remainingPaymentValue,

            products: getProductsBuyOrder(buyOrder?.products ?? []),

            paymentType: buyOrder?.paymentType,

            deadline: buyOrder?.deadline,
            createdDate: buyOrder?.createdDate,
          } as Partial<BuyOrderFormType>
        }
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {renderContent()}
      </Formik>
    </DashboardLayout>
  );
};

export default withContext(UpdateBuyOrderPage, UpdateBuyOrderProvider);
