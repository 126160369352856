import React from "react";

import * as Styled from "./styles";

const EmptyTable: React.FC = props => {
  const { children } = props;

  return <Styled.EmptyContainer>{children}</Styled.EmptyContainer>;
};

export default EmptyTable;
