import React, { FC } from "react";

import { GridColDef } from "@material-ui/data-grid";

import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { SalesByPeriodSale } from "src/services/hooks/reports/sales/useSalesByPeriodReport";
import { formatPrice } from "src/utils/format";

const columns: GridColDef[] = [
  {
    field: "label",
    headerName: "Período",
    flex: 1,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "count",
    headerName: "Quantidade",
    renderCell: params => params.value,
    flex: 1,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
  {
    field: "totalAmount",
    headerName: "Valor",
    renderCell: params => formatPrice(params.value),
    flex: 1,
    sortComparator: (v1, v2) => (v1 as number) - (v2 as number),
  },
];

export interface SalesByPeriodTableProps {
  sales: SalesByPeriodSale[];
}

const SalesByPeriodTable: FC<SalesByPeriodTableProps> = ({ sales }) => {
  const [page, setPage] = React.useState(1);

  return (
    <Table
      sortingOrder={["desc", "asc"]}
      rows={sales.map((sale, index) => ({
        ...sale,
        id: `${index}${Math.random()}`,
      }))}
      columns={columns}
      page={page}
      setPage={setPage}
      pageSize={sales.length}
      onPageSizeChange={undefined}
      filterMode="server"
      totalCount={sales.length}
      hideFooter
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <Typography>Nenhuma relatório disponível</Typography>
          </EmptyTable>
        ),
      }}
    />
  );
};

export default SalesByPeriodTable;
