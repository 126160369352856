import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import {
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";

import {
  FeaturesActions,
  FeaturesSubjects,
  PERMISSIONS,
  UserPermissions,
} from "src/interfaces/permissions";

export interface PermissionsAbilityProps {
  title: string;
  subtitle: string;
  permission: FeaturesSubjects;
  excludes?: FeaturesActions[];
}

function PermissionsAbility(props: PermissionsAbilityProps) {
  const { title, subtitle, permission, excludes = [] } = props;

  const { setValue, watch } = useFormContext();

  const value: UserPermissions = watch("permissions") ?? {
    [permission]: [],
  };

  const permissions = (value[permission] ?? []) as Array<string>;

  const handleChange = (action: string, checked: boolean) => {
    if (checked) {
      setValue("permissions", {
        ...value,
        [permission]: [...permissions, action],
      });
    } else {
      const permissionsFiltered = permissions.filter(per => per !== action);
      setValue("permissions", {
        ...value,
        [permission]: permissionsFiltered,
      });
    }
  };

  const permissionsFiltered = useMemo(() => {
    return PERMISSIONS[permission]
      .map(permission => {
        if (excludes.includes(permission.action)) {
          return undefined;
        }
        return permission;
      }, [])
      .filter(permission => permission) as Array<{
      action: string;
      label: string;
    }>;
  }, [PERMISSIONS, permission]);

  return (
    <Grid
      templateAreas={[`"subject""actions"`, null, null, `"subject actions"`]}
      templateColumns={["1fr", null, null, "auto 1fr"]}
      gap={4}
      justifyContent="space-between"
      mt={4}
    >
      <GridItem area="subject" w="300px">
        <Text color="gray.500">{title}</Text>
        <Text fontSize="sm" color="gray.400">
          {subtitle}
        </Text>
      </GridItem>
      <GridItem
        display="flex"
        gap={4}
        justifyContent={
          permissionsFiltered.length > 3 ? "space-between" : "flex-start"
        }
        area="actions"
      >
        {permissionsFiltered.map((group, index) => {
          return (
            <FormControl
              w="auto"
              minW="60px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              key={`${permission}${group.action}`}
            >
              <FormLabel
                m="0"
                textAlign="center"
                fontSize="sm"
                color="gray.500"
                htmlFor={`${permission}${group.action}${index}-checkbox`}
              >
                {group.label}
              </FormLabel>
              <Checkbox
                mt={4}
                id={`${permission}${group.action}${index}-checkbox`}
                size="lg"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(group.action, event.target.checked);
                }}
                isChecked={permissions.includes(group.action)}
              />
            </FormControl>
          );
        })}
      </GridItem>
    </Grid>
  );
}

export default PermissionsAbility;
