import React from "react";

import Box from "@material-ui/core/Box";

import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

interface SalesByPeriodBottomBarProps {
  count: number;
  totalAmount: number;
}

const SalesByPeriodBottomBar: React.FC<SalesByPeriodBottomBarProps> = props => {
  const { count, totalAmount } = props;

  return (
    <Styled.AppBar position="fixed" component="footer">
      <Styled.Container>
        <Box display="flex">
          <Box mr={4}>
            <Styled.LabelData>Quantidade</Styled.LabelData>
            <Styled.ValueData>{count}</Styled.ValueData>
          </Box>
          <Box mr={4}>
            <Styled.LabelTotalValue>Valor total</Styled.LabelTotalValue>
            <Styled.ValueData>{formatPrice(totalAmount)}</Styled.ValueData>
          </Box>
          <Box>
            <Styled.LabelTotalValue>Ticket Médio</Styled.LabelTotalValue>
            <Styled.ValueData>
              {formatPrice(totalAmount / count)}
            </Styled.ValueData>
          </Box>
        </Box>
      </Styled.Container>
    </Styled.AppBar>
  );
};

export default SalesByPeriodBottomBar;
