import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Flex } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { DatePicker } from "src/components/NewComponents/DatePicker";
import { Textarea } from "src/components/NewComponents/TextArea";
import { BuyOrderType } from "src/interfaces/buyOrder";
import { useUpdateQuickBuyOrder } from "src/services/hooks/buyOrder/useUpdateQuickBuyOrder";
import { formatDateToISO } from "src/utils/date";

import { schemaUpdateQuickModalForm } from "./schema";

export type UpdateQuickBuyOrderModalSchema = yup.InferType<
  typeof schemaUpdateQuickModalForm
>;

interface UpdateQuickBuyOrderModalType {
  buyOrder: BuyOrderType;
  onSuccess?: (buyOrder: BuyOrderType) => void;
}

function UpdateQuickBuyOrderModal({
  buyOrder,
  onSuccess,
}: UpdateQuickBuyOrderModalType) {
  const { handleClose, open } = useBaseSwipeableDrawer();

  const defaultValues: UpdateQuickBuyOrderModalSchema = {
    id: buyOrder.id,
    deadline: buyOrder.deadline
      ? formatDateToISO(buyOrder.deadline)
      : buyOrder.deadline,
    additionalInfo: buyOrder.additionalInfo ?? "",
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
    reset,
  } = useForm<UpdateQuickBuyOrderModalSchema>({
    defaultValues,
    resolver: yupResolver(schemaUpdateQuickModalForm),
  });

  useEffect(() => {
    reset({
      ...defaultValues,
    });
  }, [buyOrder, open.includes("updateQuickBuyOrder")]);

  const { mutate: updateOrder, isLoading } = useUpdateQuickBuyOrder({
    onSuccess: buyOrder => {
      toast.success("Edição feita com sucesso.");
      if (onSuccess) onSuccess(buyOrder);
      handleClose("updateQuickBuyOrder");
    },
  });

  const onSubmit = ({
    id,
    additionalInfo,
    deadline,
  }: UpdateQuickBuyOrderModalSchema) => {
    updateOrder({
      id,
      additionalInfo,
      deadline: deadline ? formatDateToISO(deadline) : deadline,
    });
  };

  return (
    <BaseSwipeableDrawer
      tag="updateQuickBuyOrder"
      title="Edição rápida"
      buttonTitle="Salvar"
      buttonProps={{
        loading: isLoading,
        disabled: isLoading,
        form: "updateQuickBuyForm",
      }}
      zIndex={1302}
    >
      <Flex
        w={["100%", "350px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="updateQuickBuyForm"
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
        direction="column"
      >
        <DatePicker
          label="Prazo de entrega"
          name="deadline"
          withHour
          format="dd/MM/yyyy - HH:mm"
          error={errors.deadline}
          control={control}
          formControlProps={{ bg: "white" }}
          isClearable
        />
        <Textarea
          label="Informações adicionais"
          error={errors.additionalInfo}
          bg="white"
          rows={4}
          {...register("additionalInfo")}
        />
      </Flex>
    </BaseSwipeableDrawer>
  );
}

export default UpdateQuickBuyOrderModal;
