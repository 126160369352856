export const MOVEMENT_TYPE = [
  {
    value: "input",
    name: "Entrada",
  },
  {
    value: "output",
    name: "Saida",
  },
  {
    value: "balance",
    name: "Balanço",
  },
];

export const MOVEMENT_SOURCE = [
  {
    value: "buyOrder",
    name: "Ordem de compra",
  },
  {
    value: "order",
    name: "Venda",
  },
  {
    value: "manual",
    name: "Manual",
  },
];
