import { Link as LinkUnstyled } from "react-router-dom";

import styled from "styled-components";

import MuiButton from "@material-ui/core/Button";

export const Container = styled.div`
  width: auto;
`;

export const Link = styled(LinkUnstyled)`
  text-decoration: none;
`;

export const Button = styled(MuiButton)`
  &.MuiButton-contained.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    opacity: 0.5;
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  &.MuiButton-root.Mui-disabled {
    opacity: 0.5;
    color: ${({ theme }) => theme.palette.common.white};
  }
  &.MuiButton-outlined.Mui-disabled {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    opacity: 0.5;
    color: ${({ theme }) => theme.palette.primary.dark};
  }

  :hover {
    transition: 0.5s;
  }

  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  text-transform: none;
  min-width: fit-content;
`;

export const ButtonLoading = styled(MuiButton)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  min-width: fit-content;
`;
