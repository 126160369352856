import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@material-ui/core/Box";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import BottomBar from "src/components/Layouts/BottomBar";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Panel from "src/components/Layouts/Panel";
import { ModalProvider, useModal } from "src/components/Modal/useModal";
import Tabs from "src/components/Tabs";
import { ItemTabsProps } from "src/components/Tabs/props";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import { useModule } from "src/hooks/module/useModule";
import { MovementProvider } from "src/hooks/product/useMovement";
import { ProductProvider, useProduct } from "src/hooks/product/useProduct";
import { formatPrice } from "src/utils/format";
import { withContext } from "src/utils/withContext";

import DeleteModal from "../components/DeleteModal";
import Lots from "../components/Lots";
import Movements from "../components/Movements";
import ProductForm from "../components/ProductForm";
import CreateMovementModal from "./components/CreateMovementModal";
import * as Styled from "./styles";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Produtos", path: PATHS.PRODUCTS_LIST },
  { label: "Detalhes do produto" },
];

const ProductsDetail: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const [tab, setTab] = useState("details");

  const { detail, status, product } = useProduct();
  const { hasModules } = useModule();
  const { handleOpen: handleOpenModals } = useBaseSwipeableDrawer();

  const { handleOpen } = useModal();

  const navigate = useNavigate();

  const getProductDetail = () => {
    if (tab === "details") {
      detail(id);
    }
  };

  useEffect(() => {
    getProductDetail();
  }, [id, tab]);

  const afterAction = () => {
    navigate(PATHS.PRODUCTS_LIST);
  };

  return (
    <DashboardLayout selected="register" subSelected="products">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Detalhes do produto" mt={0} mb={3} height={45}>
        {hasModules("stock") && product?.stock && tab !== "lots" ? (
          <Box>
            <Styled.Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenModals("createMovement")}
            >
              <AddCircleIcon />
              <Typography variant="body2">Alterar quantidade</Typography>
            </Styled.Button>
          </Box>
        ) : null}
      </PageTitle>

      {hasModules("stock") && product?.stock ? (
        <Tabs
          tab={tab}
          setTab={setTab}
          ariaLabel="Contatos"
          tabs={
            [
              {
                key: "details",
                label: "Detalhes",
              },
              {
                key: "movements",
                label: "Movimentações",
              },
              {
                key: "lots",
                label: "Lotes",
              },
            ] as Array<ItemTabsProps>
          }
          tabPanels={[
            <React.Fragment key="details">
              <Panel padding={4}>
                <ProductForm method="update" data={product} />
              </Panel>
              {tab === "details" ? (
                <CreateMovementModal
                  product={product}
                  afterAction={() => {
                    getProductDetail();
                  }}
                />
              ) : null}
            </React.Fragment>,

            <React.Fragment key="movements">
              <Movements />
            </React.Fragment>,
            <React.Fragment key="lots">
              <Lots />
            </React.Fragment>,
          ]}
        />
      ) : (
        <Panel padding={4}>
          <ProductForm method="update" data={product} />
        </Panel>
      )}
      {tab === "details" ? (
        <BottomBar
          width="100%"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
        >
          <Box>
            <Styled.DeleteButton
              variant="outlined"
              size="medium"
              onClick={handleOpen}
            >
              Excluir produto
            </Styled.DeleteButton>
            <DeleteModal id={id} afterActions={afterAction} />
          </Box>
          <Box display="flex" alignItems="center">
            {hasModules("stock") && product.stock ? (
              <Box mr={4}>
                <Styled.LabelData>Patrimônio:</Styled.LabelData>
                <Styled.ValueData>
                  {formatPrice(product.price * product.stock.quantity)}
                </Styled.ValueData>
              </Box>
            ) : null}
            <Styled.Button
              color="primary"
              size="medium"
              variant="outlined"
              to={PATHS.PRODUCTS_LIST}
            >
              Cancelar
            </Styled.Button>
            <Box width={16} />
            <Styled.Button
              color="primary"
              variant="contained"
              type="submit"
              form="form-product"
              disabled={status.loading}
            >
              Salvar
            </Styled.Button>
          </Box>
        </BottomBar>
      ) : null}
    </DashboardLayout>
  );
};

export default withContext(
  ProductsDetail,
  BaseSwipeableDrawerProvider,
  ProductProvider,
  ModalProvider,
  MovementProvider
);
