import styled from "styled-components";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker as DatePickerUnstyled } from "@material-ui/pickers";

import ButtonUnstyled from "src/components/Button";
import Typography from "src/components/Typography";

export const Button = styled(ButtonUnstyled)<{ enabled?: boolean }>`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 47px;
  margin: 0px;
  width: 120px;

  svg {
    color: ${({ enabled, theme }) =>
      enabled ? theme.palette.primary.main : theme.palette.grey[500]};
    margin-right: 8px;
  }
`;

export const SelectedFilterButton = styled(ButtonUnstyled)`
  border-radius: 20px;
`;

export const CloseButton = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export const PickerContainer = styled.div`
  position: relative;
  background-color: red;
  height: 55px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-color: ${({ theme }) => theme.palette.grey[500]};
  border-style: solid;
  border-width: 1px;
  :hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const DatePicker = styled(DatePickerUnstyled)`
  & * {
    cursor: pointer;
    border: 0px;
    text-transform: capitalize;
  }
  position: absolute;
  padding: 0px 40px;
  width: 100%;
  z-index: 100;
  svg {
    margin-right: 8px;
  }
`;

export const BackButton = styled(ArrowBackIosIcon)`
  height: 55px;
  margin: 0px 15px;
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 200;
  color: ${({ theme }) => theme.palette.grey[500]};
  :hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const FowardButton = styled(ArrowForwardIosIcon)`
  height: 55px;
  margin: 0px 15px;
  cursor: pointer;
  position: absolute;
  right: 0;
  z-index: 200;
  color: ${({ theme }) => theme.palette.grey[500]};
  :hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const MonthIcon = styled(CalendarTodayIcon)`
  height: 55px;
  margin: 0px 15px;
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 200;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const SubmitButton = styled(ButtonUnstyled)`
  height: 55px;
`;

export const IntervalDate = styled(Typography)`
  p {
    margin: 0px;
  }
`;
