import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Circle,
  Divider,
  HStack,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react";
import EmailIcon from "@material-ui/icons/Email";
import SecurityIcon from "@material-ui/icons/Security";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import { LINKS } from "src/constants/links";
import { useFaq } from "src/services/hooks/faq/useFaq";
import { formatPhone } from "src/utils/format";
import { getUrlParameter } from "src/utils/getUrlParam";

function HelpAndSuport() {
  const {
    data = {
      faq: [],
      emailContact: "",
      whatsappLink: "",
    },
  } = useFaq();

  return (
    <>
      <Box
        mb={6}
        p={6}
        bg="white"
        borderRadius={10}
        border="solid 1px gray-200"
      >
        <Text color="primary.500" fontWeight="bold">
          Dúvidas mais frequentes
        </Text>

        <Accordion allowMultiple mt={4}>
          {data.faq.map(item => (
            <AccordionItem
              key={item.id}
              borderColor="#EEF0F4"
              sx={{
                "&:last-of-type": {
                  borderBottom: "none",
                },
              }}
            >
              <h2>
                <AccordionButton py={3} px={0}>
                  <Box as="span" flex="1" textAlign="left" fontSize="15">
                    {item.question}
                  </Box>
                  <AccordionIcon color="primary.500" fontSize={24} />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} px={0} color="primary.500" fontSize="sm">
                {item.answer}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
      <Box
        mb={6}
        p={6}
        bg="white"
        borderRadius={10}
        border="solid 1px gray-200"
      >
        <Text color="primary.500" fontWeight="bold">
          Fale conosco
        </Text>

        <Divider my={4} />

        <HStack mt={4}>
          <HStack mr={8}>
            <Circle size="42px" bg="gray.100">
              <Icon boxSize="24px" color="primary.500" as={EmailIcon} />
            </Circle>
            <Link
              sx={{
                "&:link": {
                  color: "primary.500",
                },
              }}
              href={`mailto:${data.emailContact}`}
            >
              {data.emailContact}
            </Link>
          </HStack>
          <HStack>
            <Circle size="42px" bg="gray.100">
              <Icon boxSize="24px" color="#5B5C60" as={WhatsAppIcon} />
            </Circle>
            <Link
              sx={{
                "&:link,&:visited": {
                  color: "primary.500",
                },
              }}
              href={data.whatsappLink}
              target="_blank"
              rel="noreferrer"
            >
              {formatPhone(getUrlParameter(data.whatsappLink, "phone"))}
            </Link>
          </HStack>
        </HStack>

        <Text color="primary.500" fontWeight="bold" mt={6}>
          LGPD
        </Text>

        <Divider my={4} />
        <HStack>
          <Circle size="64px" bg="gray.100">
            <Icon boxSize="24px" color="primary.500" as={SecurityIcon} />
          </Circle>

          <Text>
            Nós respeitamos muito sua privacidade, sempre tratando seus dados de
            acordo com a nossa{" "}
            <Link
              sx={{
                "&:link,&:visited": {
                  color: "gray.500",
                },
              }}
              href={LINKS.privacypolicy}
              target="_blank"
            >
              Política de Privacidade.
            </Link>
          </Text>
        </HStack>
      </Box>
    </>
  );
}

export default HelpAndSuport;
