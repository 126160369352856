import React, { useState, createContext, useContext } from "react";

import { STATUS } from "src/constants/requestStatus";
import { RequestStatusType } from "src/interfaces/request";
import { TagFormType, TagType } from "src/interfaces/tag";
import { createTagsService } from "src/services/tags";

export const TagContext = createContext(
  {} as {
    createTagLoading: RequestStatusType;
    createTag: (params: TagFormType) => Promise<TagType | undefined>;
  }
);

export const TagProvider: React.FC = props => {
  const { children } = props;

  const [createTagLoading, setCreateTagLoading] = useState(STATUS.inital);

  const createTag = async (
    params: TagFormType
  ): Promise<TagType | undefined> => {
    try {
      setCreateTagLoading(STATUS.loading);

      const response: TagType = await createTagsService(params);

      setCreateTagLoading(STATUS.success);

      return response;
    } catch (e) {
      setCreateTagLoading(STATUS.error);
    }
    return undefined;
  };

  return (
    <TagContext.Provider
      value={{
        createTag,
        createTagLoading,
      }}
    >
      {children}
    </TagContext.Provider>
  );
};

export const useTag = () => {
  const context = useContext(TagContext);

  if (!context) {
    throw new Error("useTag must be used within a TagProvider");
  }

  return context;
};
