import { differenceInSeconds, isEqual, set, startOfDay } from "date-fns";

/**
 * Data de pagamento formatada no padrão ISO com o timezone
 */
export const formatPaidDateToISO = (value: string | number | Date) => {
  const seconds = differenceInSeconds(new Date(), startOfDay(new Date()));

  const paidDateStartOfDay = startOfDay(new Date(value));

  const date = isEqual(paidDateStartOfDay, startOfDay(new Date()))
    ? set(new Date(paidDateStartOfDay), {
        seconds,
      })
    : paidDateStartOfDay;

  return date.toISOString();
};

export const formatEmissionDateToISO = (value: string | number | Date) => {
  const seconds = differenceInSeconds(new Date(), startOfDay(new Date()));

  const emissionDateStartOfDay = startOfDay(new Date(value));

  const date = isEqual(emissionDateStartOfDay, startOfDay(new Date()))
    ? set(new Date(emissionDateStartOfDay), {
        seconds,
      })
    : emissionDateStartOfDay;

  return date.toISOString();
};
