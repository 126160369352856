import React from "react";

import Box from "@material-ui/core/Box";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import cashIcon from "src/assets/icons/financialAccounts/cash.svg";
import walletIcon from "src/assets/icons/financialAccounts/wallet.svg";
import { useBaseModal } from "src/components/BaseModal/useBaseModal";
import { useFinancialAccount } from "src/hooks/financialAccount/useFinancialAccount";
import {
  FinancialAccountType,
  FinancialAccountTypeEnum,
} from "src/interfaces/financialAccount";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

export interface AccountListProps {
  accounts: Array<FinancialAccountType>;
}

const AccountsList: React.FC<AccountListProps> = ({ accounts }) => {
  const { setFinancialAccount } = useFinancialAccount();
  const { handleOpen } = useBaseModal();

  const logo = (
    accountType: keyof typeof FinancialAccountTypeEnum,
    logo?: string
  ) => {
    if (accountType === "wallet") return walletIcon;
    if (accountType === "cash") return cashIcon;

    return logo;
  };

  const institution = (
    accountType: keyof typeof FinancialAccountTypeEnum,
    institutionName?: string
  ) => {
    if (accountType === "wallet") return "Carteira";
    if (accountType === "cash") return "Caixa";

    return institutionName;
  };

  const handleOpenUpdateModal = (data: FinancialAccountType) => {
    setFinancialAccount(data);
    handleOpen("financialAccountUpdate");
  };

  return (
    <Styled.Grid>
      {accounts.map(account => (
        <Styled.Account
          key={account.id}
          onClick={() => handleOpenUpdateModal(account)}
        >
          <Styled.AccountHeader>
            <Styled.AccountHeaderRow>
              <Styled.AccountLogo
                src={logo(account.type, account.institution?.image)}
              />
              <Box>
                <Styled.AccountName>{account.name}</Styled.AccountName>
                <Styled.AccountType>
                  {institution(account.type, account.institution?.name)}
                </Styled.AccountType>
              </Box>
            </Styled.AccountHeaderRow>
            <ChevronRightIcon color="primary" />
          </Styled.AccountHeader>
          <Styled.AccountFooter>
            <Styled.AccountBalance>Saldo atual</Styled.AccountBalance>
            <Styled.AccountBalance>
              {formatPrice(account.balance)}
            </Styled.AccountBalance>
          </Styled.AccountFooter>
        </Styled.Account>
      ))}
    </Styled.Grid>
  );
};

export default AccountsList;
