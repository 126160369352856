/*
 * Prevendo submissão ao pressionar enter com o formulário em focus
 */
export const preventEnterOnKeyDown = (
  keyEvent: React.KeyboardEvent<HTMLFormElement>
) => {
  if (keyEvent?.code === "Enter" || keyEvent?.code === "NumpadEnter") {
    keyEvent?.preventDefault();
  }
};

export default { preventEnterOnKeyDown };
