import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useField } from "formik";

import { RadioSimpleProps } from "./props";
import * as Styled from "./styles";

const RadioSimple: React.FC<RadioSimpleProps> = props => {
  const { label, name, value, ...rest } = props;

  const [field, , helpers] = useField<string>({
    name,
    value,
  });

  const checked = React.useMemo(() => value === field.value, [field]);

  const change = (value: string) => {
    helpers.setValue(value);
  };

  return (
    <FormControlLabel
      onClick={() => change(value)}
      checked={checked}
      control={<Styled.Radio {...rest} color="primary" checked={checked} />}
      label={label}
    />
  );
};

export default RadioSimple;
