import React from "react";

import ErrorIcon from "@material-ui/icons/Error";
import StarIcon from "@material-ui/icons/Star";
import { useField } from "formik";

import NumberFormatCustom from "./NumberFormatter";
import { TextFieldProps } from "./props";
import * as Styled from "./styles";

const NumberField: React.FC<TextFieldProps> = props => {
  const {
    name,
    value,
    decimalScale = 2,
    defaultValue,
    disabled,
    icon,
    disableFormik,
    ...rest
  } = props;

  if (disableFormik) {
    return (
      <Styled.TextField
        fullWidth
        variant="outlined"
        {...rest}
        InputLabelProps={{
          required: false,
          ...rest.InputLabelProps,
        }}
        value={value}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
          ...rest.InputProps,
        }}
        {...{
          inputProps: {
            decimalScale,
            name,
          },
        }}
        disabled={disabled}
      />
    );
  }

  const [field, meta] = useField<string>({
    name,
    defaultValue,
    value,
  });

  const EndAdornment = React.useMemo(() => {
    if (meta.error && meta.touched) {
      return <ErrorIcon color="error" />;
    }

    if (icon) {
      return <StarIcon color="primary" />;
    }

    return null;
  }, [meta]);

  const errorProps = React.useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      };
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      };
    }
    return {};
  }, [meta.error, meta.initialError, meta.touched]);

  return (
    <Styled.TextField
      fullWidth
      variant="outlined"
      id={field.name}
      {...errorProps}
      {...rest}
      {...field}
      InputLabelProps={{
        required: false,
        ...rest.InputLabelProps,
      }}
      InputProps={{
        endAdornment: EndAdornment,
        inputComponent: NumberFormatCustom as any,
        ...rest.InputProps,
      }}
      {...{
        inputProps: {
          decimalScale,
        },
      }}
      disabled={disabled}
    />
  );
};

export default NumberField;
