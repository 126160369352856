import { toast } from "react-toastify";

import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import { ReactComponent as InfoIcon } from "src/assets/icons/info.svg";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { useDeleteModuleCompany } from "src/services/hooks/modules/useDeleteModuleCompany";

interface IDelete {
  idModule: string | undefined;
}

export default function ModalDeleteModules({ idModule }: IDelete) {
  const { open, handleClose } = useBaseSwipeableDrawer();
  const { user, setUser } = useAuth();

  const queryClient = useQueryClient();

  const { mutate: deleteModuleCompany, isLoading } = useDeleteModuleCompany({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["modulesCompany"],
      });

      const userData = {
        ...user,
        company: {
          ...user.company,
          modules: user.company.modules.filter(
            module => module.module.id !== variables.id
          ),
        },
      };

      setUser(userData);

      toast.success("Módulo desinstalado");
      handleClose("deleteModule");
    },
  });

  const deleteService = async () => {
    deleteModuleCompany({ id: idModule as string });
  };

  return (
    <Modal
      isOpen={open.includes("deleteModule")}
      onClose={() => handleClose("deleteModule")}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="center" alignItems="center">
            <Icon as={InfoIcon} boxSize={10} />
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text textAlign="center" mb={10} fontWeight="semibold" fontSize="lg">
            Tem certeza que deseja excluir esse módulo?
          </Text>

          <Button
            w="100%"
            colorScheme="error"
            onClick={() => deleteService()}
            isLoading={isLoading}
          >
            Desinstalar
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
