import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Divider from "@material-ui/core/Divider";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Tabs from "src/components/Tabs";
import { ItemTabsProps } from "src/components/Tabs/props";
import PATHS from "src/constants/paths";
import { ModuleProvider } from "src/hooks/module/useModule";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { withContext } from "src/utils/withContext";

import { General } from "./General";
import Modules from "./Modules";
import Preferences from "./Preferences";
import Users from "./Users";

export const SETTINGS_TABS: Array<ItemTabsProps> = [
  { key: "", label: "Geral" },
  { key: "modulos", label: "Módulos" },
  { key: "preferencias", label: "Preferências" },
  { key: "usuarios", label: "Usuários" },
];

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Configurações" },
];

const SettingsPage: React.FC = () => {
  const { tab = "" } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const { isOwner } = useAuth();

  const tabs = useMemo(() => {
    if (isOwner) return SETTINGS_TABS;

    return SETTINGS_TABS.filter(tab => tab.key !== "usuarios");
  }, [isOwner]);

  return (
    <DashboardLayout selected="settings">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />
      <PageTitle label="Configurações" />

      <Tabs
        tab={tab}
        setTab={tab => navigate(`${PATHS.SETTINGS}/${tab}`, { replace: true })}
        ariaLabel="Configurações"
        tabs={tabs}
        tabPanels={[
          <General key="general" />,
          <Modules key="modules" />,
          <Preferences key="preferences" />,
          isOwner && <Users key="users" />,
        ].filter(item => item)}
      />
    </DashboardLayout>
  );
};

export default withContext(SettingsPage, ModuleProvider);
