import React, { useState, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import {
  BuyOrderFormType,
  ProductBuyOrderFormType,
} from "src/interfaces/buyOrder";
import { ContactType } from "src/interfaces/contact";
import { addBuyOrderService } from "src/services/buy_order";
import { formatDateToISO } from "src/utils/date";

import { useBuyOrder } from "../useBuyOrder";
import { CreateBuyOrderContextType } from "./props";

export const CreateBuyOrderContext = createContext(
  {} as CreateBuyOrderContextType
);

export const CreateBuyOrderProvider: React.FC = props => {
  const { children } = props;
  const navigate = useNavigate();

  const { setBuyOrder } = useBuyOrder();

  const [createBuyOrderStatus, setCreateBuyOrdersStatus] = useState(
    STATUS.inital
  );

  const [provider, setProvider] = useState({} as ContactType);
  const [discount, setDiscount] = useState(0);
  const [productsBuyOrder, setProductsBuyOrder] = useState<
    ProductBuyOrderFormType[]
  >([]);

  const formatProductsToBuyOrder = () => {
    if (productsBuyOrder.length > 0) {
      return productsBuyOrder.map(productBuyOrder => {
        const product: Partial<ProductBuyOrderFormType> = {
          cost: productBuyOrder.cost,
          product: {
            id: productBuyOrder.product.id,
            stock: productBuyOrder.productSelected?.stock?.id,
          },
          quantity: productBuyOrder.quantity,
          validity: productBuyOrder.validity
            ? formatDateToISO(productBuyOrder.validity)
            : undefined,
        };

        return product;
      });
    }

    return [];
  };

  const createBuyOrder = async (params: BuyOrderFormType) => {
    try {
      setCreateBuyOrdersStatus(STATUS.loading);

      const data = {
        ...params,
        provider: provider.id,
        products: formatProductsToBuyOrder(),
        ...(params?.deadline && {
          deadline: formatDateToISO(params.deadline),
        }),
        ...(params?.createdDate && {
          createdDate: formatDateToISO(params.createdDate),
        }),
        downPaymentValue: params?.downPaymentValue
          ? Number(params.downPaymentValue)
          : undefined,
        remainingPaymentValue: params?.remainingPaymentValue
          ? Number(params.remainingPaymentValue)
          : undefined,
      } as BuyOrderFormType;

      const response = await addBuyOrderService(data);

      setBuyOrder(response.data);

      toast.success("Ordem de compra criada com sucesso!");

      navigate(-1);

      setCreateBuyOrdersStatus(STATUS.success);
    } catch (e) {
      setCreateBuyOrdersStatus(STATUS.error);

      toast.error(
        "Falha ao criar ordem de compra. Verifique os dados e tente novamente."
      );
    }
  };

  return (
    <CreateBuyOrderContext.Provider
      value={{
        createBuyOrderStatus,
        createBuyOrder,
        provider,
        setProvider,
        productsBuyOrder,
        setProductsBuyOrder,
        discount,
        setDiscount,
      }}
    >
      {children}
    </CreateBuyOrderContext.Provider>
  );
};

export const useCreateBuyOrder = () => {
  const context = useContext(CreateBuyOrderContext);

  if (!context) {
    throw new Error(
      "useCreateBuyOrder must be used within a useCreateBuyOrderProvider"
    );
  }

  return context;
};
