import styled from "styled-components";

import CircularProgress from "@material-ui/core/CircularProgress";
import DialogUnstyled from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MuiPaper from "@material-ui/core/Paper";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import Typography from "src/components/Typography";

export const Dialog = styled(DialogUnstyled)`
  .MuiDialog-paper {
    overflow-x: hidden;
    width: 600px;
  }
`;

export const Container = styled(MuiPaper)`
  padding: 20px;
  width: 100%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: none;
`;

export const TitleText = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 19px;
`;

export const LabelText = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 15px;
  margin-bottom: 5px;
`;

export const ContentText = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 13px;
  margin-bottom: 10px;
`;

export const Indicator = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const BlackText = styled.span`
  color: ${({ theme }) => theme.palette.common.black};
`;

export const OptionsContainer = styled(Grid)`
  background-color: #f8f9fa;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  height: 70px;
  align-items: center;
`;

export const ButtonLabel = styled(Typography)`
  font-weight: 400;
  font-size: 10px;
`;

export const DoneButtonLabel = styled(ButtonLabel)`
  color: #14b738;
`;

export const CheckedIcon = styled(CheckBoxIcon)`
  color: #14b738;
`;

export const UnCheckedIcon = styled(CheckBoxOutlineBlankIcon)`
  color: #14b738;
`;

export const Loading = styled(CircularProgress)`
  color: #14b738;
`;
