import styled from "styled-components";

import Paper from "@material-ui/core/Paper";
import AddCircleIconUnstyled from "@material-ui/icons/AddCircle";
import NoteAddIconUnstyled from "@material-ui/icons/NoteAdd";

import Button from "src/components/Button";
import Typography from "src/components/Typography";

export const Container = styled(Paper)`
  box-shadow: none;
  border: 1.5px solid #d6d8dc;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: 25px;
`;

export const Icon = styled(NoteAddIconUnstyled)`
  margin-right: 15px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export const NoteAddIcon = styled(NoteAddIconUnstyled)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 45px;
`;

export const AddCircleIcon = styled(AddCircleIconUnstyled)`
  margin-right: 10px;
`;

export const Photo = styled.div<{ url: string }>`
  width: 100%;
  height: 120px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-size: cover;
  background-image: url(${({ url }) => url});
`;

export const ActionButton = styled(Button)`
  max-height: 80px;
  max-width: 300px;
  margin-right: 5px;
  border-radius: 5px;
  padding: 3px 8px;
`;

export const ActionButtonLabel = styled(Typography)`
  font-size: 14px;
`;
