import { useMemo } from "react";

import { SimpleGrid, Text, TextProps } from "@chakra-ui/react";

import { ProductOrderType } from "src/interfaces/order";
import { formatPrice } from "src/utils/format";

export default function Products({
  products,
  showTotal,
}: {
  products: ProductOrderType[];
  showTotal: boolean;
}) {
  const renderTotalProductsValue = useMemo(() => {
    return products.reduce((calc, product) => {
      return calc + product.productPrice * product.quantity;
    }, 0);
  }, [products]);

  const CustomText = ({ children, ...props }: TextProps) => {
    return (
      <Text fontWeight={400} lineHeight={1.1} color="black" {...props}>
        {children}
      </Text>
    );
  };

  return (
    <>
      <SimpleGrid columns={2} w="100%" mb={2} templateColumns="1fr min-content">
        <CustomText fontSize="10px">
          Qtd
          <CustomText fontSize="10px" ml={2} as="span">
            Produto
          </CustomText>
          <CustomText fontSize="10px" ml={3} as="span">
            Vl Un.
          </CustomText>
        </CustomText>

        <CustomText fontSize="10px" textAlign="right">
          Valor
        </CustomText>
      </SimpleGrid>
      <SimpleGrid columns={2} w="100%">
        {products.map(product => (
          <>
            <CustomText fontSize="10px">
              {product.productName}
              <br />
              {product.quantity} x {formatPrice(product.productPrice)}
            </CustomText>
            <CustomText fontSize="10px" textAlign="right">
              {formatPrice(product.productPrice * product.quantity)}
            </CustomText>
          </>
        ))}
      </SimpleGrid>
      {showTotal ? (
        <SimpleGrid columns={2} w="100%">
          <CustomText fontSize="10px">Total dos produtos</CustomText>
          <CustomText fontSize="10px" textAlign="right">
            {formatPrice(renderTotalProductsValue)}
          </CustomText>
        </SimpleGrid>
      ) : null}
    </>
  );
}
