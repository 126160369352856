import styled from "styled-components";

import { Box } from "@material-ui/core";
import DialogUnstyled from "@material-ui/core/Dialog";
import MuiTooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

import Button from "src/components/Button";

export const DeleteButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error.main};
  &.MuiButton-root.Mui-disabled {
    opacity: 0.5;
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const Dialog = styled(DialogUnstyled)`
  .MuiDialog-paper {
    overflow-x: hidden;
  }
`;

export const TooltipIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 24px;
  position: absolute;
  right: 2rem;
`;

export const Tooltip = styled(MuiTooltip)`
  & > .custom__tooltip {
    background-color: red;
  }
`;

export const MenuOverlay = styled(Box)`
  border-radius: 3px;
  position: relative;
  padding-bottom: 1rem;
`;
export const MenuContainer = styled(Box)`
  min-width: 180px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.3);
  &::before {
    background-color: #fff;
    content: "";
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    bottom: 0.5rem;
    transform: rotate(45deg);
    left: calc(85% - 0.5rem);
    border-radius: 3px;
  }
`;
