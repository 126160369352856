import React, { useMemo } from "react";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloseIcon from "@material-ui/icons/Close";
import { useFormikContext } from "formik";

import Button from "src/components/Button";
import { useModule } from "src/hooks/module/useModule";
import {
  ProductOrderSettingType,
  ServiceOrderSettingType,
} from "src/interfaces/module";
import { OrderType } from "src/interfaces/order";
import { formatDateToISO } from "src/utils/date";

import { useOrderType } from "../../useOrderType";
import * as Styled from "./styles";

const CreatedAtSection: React.FC = () => {
  const { values, setFieldValue } =
    useFormikContext<Pick<OrderType, "createdDate">>();

  const { serviceOrderModule, productOrderModule } = useModule();

  const serviceOrderModuleSettings =
    serviceOrderModule?.settings as ServiceOrderSettingType;
  const productOrderModuleSettings =
    productOrderModule?.settings as ProductOrderSettingType;

  const { type } = useOrderType();

  const date = useMemo(() => {
    if (values.createdDate) {
      return formatDateToISO(values.createdDate);
    }
    return null;
  }, [values.createdDate]);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setFieldValue("createdDate", date);
    }
  };

  const handleDelete = (event: { stopPropagation(): void }) => {
    event.stopPropagation();
    setFieldValue("createdDate", null);
  };

  const inputProps = useMemo(() => {
    if (date) {
      return {
        endAdornment: (
          <div>
            <Button onClick={handleDelete} size="small">
              <CloseIcon color="primary" />
            </Button>
          </div>
        ),
      };
    }

    return {
      endAdornment: <CalendarTodayIcon color="primary" />,
    };
  }, [date]);

  if (
    (type === "serviceOrder" &&
      serviceOrderModuleSettings?.createdAtType === "dateHour") ||
    (type === "productOrder" &&
      productOrderModuleSettings?.createdAtType === "dateHour")
  ) {
    return (
      <Styled.Container>
        <Styled.CreatedAtDateTimePicker
          value={date}
          onChange={handleDateChange}
          animateYearScrolling
          inputVariant="outlined"
          ampm={false}
          format="dd/MM/yyyy - HH:mm"
          InputProps={inputProps}
          label="Data de criação"
          cancelLabel="cancelar"
          fullWidth
        />
      </Styled.Container>
    );
  }

  return (
    <Styled.Container>
      <Styled.CreatedAtDatePicker
        color="primary"
        value={date}
        onChange={handleDateChange}
        animateYearScrolling
        inputVariant="outlined"
        format="dd/MM/yyyy"
        InputProps={inputProps}
        label="Data de criação"
        cancelLabel="cancelar"
        fullWidth
      />
    </Styled.Container>
  );
};

export default CreatedAtSection;
