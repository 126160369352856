import { ProductFormType } from "src/interfaces/product";
import { ListParamsType } from "src/interfaces/request";
import api from "src/services/api";

/**
 * Cadastrar produto
 */
export const addProduct = (params: ProductFormType) =>
  api.post("v1/product", params).then(res => res.data);

/**
 * Listar produtos
 */
export const getProducts = (params: ListParamsType) =>
  api
    .get("v1/product", {
      params,
    })
    .then(res => res.data);

/**
 * Encontrar produto pelo id
 */
export const getProduct = (id: string) =>
  api.get(`v1/product/${id}`).then(res => res.data);

/**
 * Deletar produto
 */
export const deleteProduct = (id: string) =>
  api
    .delete("v1/product", {
      params: {
        id,
      },
    })
    .then(res => res);

/**
 * Atualizar produto
 */
export const updateProduct = (params: ProductFormType) =>
  api.put("v1/product", params).then(res => res.data);
