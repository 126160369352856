import React from "react";

import { Box, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";

import CurrencyField from "src/components/Inputs/CurrencyField";
import ImageUploadField from "src/components/Inputs/ImageUploadField";
import NumberField from "src/components/Inputs/NumberField";
import SelectField from "src/components/Inputs/SelectField";
import Textarea from "src/components/Inputs/Textarea";
import TextField from "src/components/Inputs/TextField";
import Loader from "src/components/Loader";
import RenderModule from "src/components/RenderModule";
import Typography from "src/components/Typography";
import { PRODUCTS_MEASURES } from "src/constants/measures";
import { useModule } from "src/hooks/module/useModule";
import { useProduct } from "src/hooks/product/useProduct";
import { ProductMeasureType, ProductFormType } from "src/interfaces/product";

import { ProductFormProps } from "./props";
import { schemaProductForm } from "./schema";
import * as Styled from "./styles";
import SwitchManageStock from "./SwitchManageStock";

const ProductForm: React.FC<ProductFormProps> = props => {
  const { method, data } = props;

  const { add, update, status } = useProduct();
  const { hasModules } = useModule();

  const onSubmit = (data: ProductFormType) => {
    if (method === "add") {
      add(data);
    }

    if (method === "update") {
      update(data);
    }
  };

  const measuresItems = Object.keys(PRODUCTS_MEASURES).map(measure => ({
    name: PRODUCTS_MEASURES[measure as ProductMeasureType],
    value: measure,
  }));

  if (status.loading) return <Loader size="medium" />;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={
        {
          id: data?.id || "",
          name: data?.name || "",
          price: data?.price || ("" as unknown as number),
          cost: data?.cost || ("" as unknown as number),
          description: data?.description || "",
          unitOfMeasure: data?.unitOfMeasure || "un",
          image: data?.image || "",
          manageStock: !!data?.stock,
          quantity: data?.stock?.quantity,
          minQuantity: data?.stock?.minQuantity,
          barCode: data?.barCode || "",
        } as ProductFormType
      }
      validationSchema={schemaProductForm}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ values }) => (
        <Form id="form-product">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography color="primary">Dados do produto</Typography>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <ImageUploadField name="image" />
                  </Box>
                </Grid>

                <RenderModule modules={["stock"]}>
                  <Grid item xs={12}>
                    <TextField label="Código" name="barCode" />
                  </Grid>
                </RenderModule>

                <Grid item xs={12}>
                  <TextField label="Nome do produto" name="name" required />
                </Grid>

                <Grid item xs={12}>
                  <CurrencyField label="Custo" name="cost" />
                </Grid>

                <Grid item xs={12}>
                  <CurrencyField label="Preço" name="price" required />
                </Grid>

                <Grid item xs={12}>
                  <SelectField
                    defaultValue="un"
                    label="Unidade de medida"
                    name="unitOfMeasure"
                    items={measuresItems}
                    disabled={method === "update"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Textarea label="Descrição" name="description" rows={4} />
                </Grid>
                {hasModules("stock") ? (
                  <>
                    <Grid item xs={12}>
                      <SwitchManageStock
                        name="manageStock"
                        label="Controlar estoque"
                        disabled={method === "update" && !!data?.stock}
                      />
                    </Grid>
                    {values.manageStock ? (
                      <>
                        <Grid item xs={12}>
                          <NumberField
                            decimalScale={values.unitOfMeasure === "un" ? 0 : 2}
                            label="Estoque atual"
                            name="quantity"
                            disabled={!!data?.stock}
                            required
                            InputProps={{
                              endAdornment: (
                                <Styled.SuffixQuantityField
                                  variant="subtitle2"
                                  color="textPrimary"
                                >
                                  {values.unitOfMeasure.toUpperCase()}
                                </Styled.SuffixQuantityField>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <NumberField
                            label="Quantidade minima"
                            name="minQuantity"
                            required
                            decimalScale={values.unitOfMeasure === "un" ? 0 : 2}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProductForm;
