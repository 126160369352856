import React, { FC, useState } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Typography from "src/components/Typography";
import { formatPrice } from "src/utils/format";

import { SummaryMonthDetailProps } from "./props";
import * as Styled from "./styles";

const SummaryMonthDetail: FC<SummaryMonthDetailProps> = props => {
  const { data } = props;
  const [expanded, setExpanded] = useState<string | false>(false);

  const panelName = `panel-${data.monthName}`;

  const handleChange =
    // eslint-disable-next-line @typescript-eslint/ban-types
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const isActivePanel = expanded === panelName;

  const renderColor = (value: number) => (value >= 0 ? "primary" : "error");

  return (
    <Styled.Accordion
      expanded={isActivePanel}
      onChange={handleChange(panelName)}
      TransitionProps={{ unmountOnExit: true }}
      square
    >
      <Styled.AccordionSummary>
        <Grid container>
          <Grid item xs={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {isActivePanel ? (
                <ExpandMoreIcon color="primary" />
              ) : (
                <ChevronRightIcon color="primary" />
              )}
            </Box>
          </Grid>

          <Grid item xs={9}>
            <Styled.ActiveMonthLabel
              color={isActivePanel ? "primary" : "initial"}
            >
              {data.monthName}
            </Styled.ActiveMonthLabel>
          </Grid>

          <Grid item xs={2}>
            <Typography color={renderColor(data.totalPaid)}>
              {formatPrice(data.totalPaid)}
            </Typography>
          </Grid>
        </Grid>
      </Styled.AccordionSummary>

      <Styled.AccordionDetails>
        <Box width="90%">
          <Grid container spacing={2}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>
                Previsto
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>
                Realizado
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2">Receitas</Typography>
            </Grid>
            <Grid item xs={4}>
              <Styled.ExitTypography
                color={renderColor(data.incomeProvided)}
                variant="body1"
              >
                {formatPrice(data.incomeProvided)}
              </Styled.ExitTypography>
            </Grid>
            <Grid item xs={4}>
              <Typography color={renderColor(data.incomePaid)}>
                {formatPrice(data.incomePaid)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2">Despesas</Typography>
            </Grid>
            <Grid item xs={4}>
              <Styled.ExitTypography color="error">
                {formatPrice(data.expenseProvided)}
              </Styled.ExitTypography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="error">
                {formatPrice(data.expensePaid)}
              </Typography>
            </Grid>
          </Grid>

          <Styled.ResultGrid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h6">Resultados</Typography>
            </Grid>
            <Grid item xs={4}>
              <Styled.ExitTypography
                color={renderColor(data.totalProvided)}
                variant="h6"
              >
                {formatPrice(data.totalProvided)}
              </Styled.ExitTypography>
            </Grid>
            <Grid item xs={4}>
              <Typography color={renderColor(data.totalPaid)} variant="h6">
                {formatPrice(data.totalPaid)}
              </Typography>
            </Grid>
          </Styled.ResultGrid>
        </Box>
      </Styled.AccordionDetails>
    </Styled.Accordion>
  );
};

export default SummaryMonthDetail;
