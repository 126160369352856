type CommonActions = "create" | "edit" | "view" | "delete";

type CreateFeatureActions<
  FeatureName extends keyof any,
  Actions extends keyof any
> = {
  [K in FeatureName]: Actions;
};

type RecordFeatureActions = CreateFeatureActions<
  "record",
  "pay" | "chargeback" | CommonActions
>;
type FinancialAccountFeatureActions = CreateFeatureActions<
  "financialAccount",
  "enable" | "disable" | CommonActions
>;
type FinancialDashboardFeatureActions = CreateFeatureActions<
  "financialDashboard",
  "view"
>;
type OrderDashboardFeatureActions = CreateFeatureActions<
  "orderDashboard",
  "view"
>;
type ReportFinancialDreFeatureActions = CreateFeatureActions<
  "reportFinancialDre",
  "view"
>;
type ReportFinancialPredictedAndCccomplishedFeatureActions =
  CreateFeatureActions<"reportFinancialPredictedAndAccomplished", "view">;
type ReportFinancialRecordsFeatureActions = CreateFeatureActions<
  "reportFinancialRecords",
  "view" | "export"
>;
type ReportOrderProductsFeatureActions = CreateFeatureActions<
  "reportOrderProducts",
  "view"
>;
type ReportOrderServicesFeatureActions = CreateFeatureActions<
  "reportOrderServices",
  "view"
>;
type ReportPaymentMethodsFeatureActions = CreateFeatureActions<
  "reportPaymentMethods",
  "view"
>;
type ReportSalesByPeriodFeatureActions = CreateFeatureActions<
  "reportSalesByPeriod",
  "view" | "export"
>;
type ReportSalesByClientFeatureActions = CreateFeatureActions<
  "reportSalesByClient",
  "view" | "export"
>;
type ReportLateClientsFeatureActions = CreateFeatureActions<
  "reportLateClients",
  "view" | "export"
>;
type ReportClientsFeatureActions = CreateFeatureActions<
  "reportClients",
  "view" | "export"
>;
type ReportProvidersFeatureActions = CreateFeatureActions<
  "reportProviders",
  "view" | "export"
>;
type ReportOrderComissionFeatureActions = CreateFeatureActions<
  "reportOrderComission",
  "view" | "export"
>;
type ReportCashFlowControlFeatureActions = CreateFeatureActions<
  "reportCashFlowControl",
  "view"
>;
type ReportInventoryFeatureActions = CreateFeatureActions<
  "reportInventory",
  "view" | "export"
>;

type CashFlowFeatureActions = CreateFeatureActions<"cashFlow", "requestOpened">;

type ServiceOrderFeatureActions = CreateFeatureActions<
  "serviceOrder",
  "hideTotalizers"
>;
type ProductOrderFeatureActions = CreateFeatureActions<
  "productOrder",
  "hideTotalizers"
>;

type FeaturePermissions = RecordFeatureActions &
  FinancialAccountFeatureActions &
  FinancialDashboardFeatureActions &
  OrderDashboardFeatureActions &
  ReportFinancialDreFeatureActions &
  ReportFinancialPredictedAndCccomplishedFeatureActions &
  ReportFinancialRecordsFeatureActions &
  ReportOrderProductsFeatureActions &
  ReportOrderServicesFeatureActions &
  ReportPaymentMethodsFeatureActions &
  ReportSalesByPeriodFeatureActions &
  ReportSalesByClientFeatureActions &
  ReportLateClientsFeatureActions &
  ReportClientsFeatureActions &
  ReportProvidersFeatureActions &
  CashFlowFeatureActions &
  ServiceOrderFeatureActions &
  ProductOrderFeatureActions &
  ReportOrderComissionFeatureActions &
  ReportCashFlowControlFeatureActions &
  ReportInventoryFeatureActions;

export type FeatureAction<
  Features extends keyof FeaturePermissions = keyof FeaturePermissions
> = {
  [Feature in Features]: {
    subject: Feature;
    action: FeaturePermissions[Feature];
  };
}[Features];

export type FeaturesSubjects = FeatureAction["subject"];
export type FeaturesActions = FeatureAction["action"];

export type UserPermissions<
  Features extends keyof FeaturePermissions = keyof FeaturePermissions
> = {
  [Feature in Features]: Array<FeaturePermissions[Feature]>;
};

export type FeaturesGroupActions<
  Features extends keyof FeaturePermissions = keyof FeaturePermissions
> = {
  [Feature in Features]: Array<{
    action: FeaturePermissions[Feature];
    label: string;
  }>;
};

export const PERMISSIONS: Required<FeaturesGroupActions> = {
  financialAccount: [
    { action: "view", label: "Visualizar" },
    { action: "create", label: "Criar" },
    { action: "edit", label: "Editar" },
    { action: "delete", label: "Excluir" },
    { action: "enable", label: "Desarquivar" },
    { action: "disable", label: "Arquivar" },
  ],
  record: [
    { action: "view", label: "Visualizar" },
    { action: "create", label: "Criar" },
    { action: "edit", label: "Editar" },
    { action: "delete", label: "Excluir" },
    { action: "pay", label: "Pagar" },
    { action: "chargeback", label: "Estornar" },
  ],
  financialDashboard: [{ action: "view", label: "Visualizar" }],
  orderDashboard: [{ action: "view", label: "Visualizar" }],
  reportFinancialDre: [{ action: "view", label: "Visualizar" }],
  reportFinancialPredictedAndAccomplished: [
    { action: "view", label: "Visualizar" },
  ],
  reportFinancialRecords: [
    { action: "view", label: "Visualizar" },
    { action: "export", label: "Exportar" },
  ],
  reportOrderProducts: [{ action: "view", label: "Visualizar" }],
  reportOrderServices: [{ action: "view", label: "Visualizar" }],
  reportPaymentMethods: [{ action: "view", label: "Visualizar" }],
  reportSalesByPeriod: [
    { action: "view", label: "Visualizar" },
    { action: "export", label: "Exportar" },
  ],
  reportSalesByClient: [
    { action: "view", label: "Visualizar" },
    { action: "export", label: "Exportar" },
  ],
  reportLateClients: [
    { action: "view", label: "Visualizar" },
    { action: "export", label: "Exportar" },
  ],
  reportClients: [
    { action: "view", label: "Visualizar" },
    { action: "export", label: "Exportar" },
  ],
  reportProviders: [
    { action: "view", label: "Visualizar" },
    { action: "export", label: "Exportar" },
  ],
  cashFlow: [{ action: "requestOpened", label: "" }],
  reportOrderComission: [
    { action: "view", label: "Visualizar" },
    { action: "export", label: "Exportar" },
  ],
  reportCashFlowControl: [{ action: "view", label: "Visualizar" }],
  reportInventory: [
    { action: "view", label: "Visualizar" },
    { action: "export", label: "Exportar" },
  ],
  serviceOrder: [{ action: "hideTotalizers", label: "Ocultar" }],
  productOrder: [{ action: "hideTotalizers", label: "Ocultar" }],
};
