import { GridValueFormatterParams } from "@material-ui/data-grid";
import { format } from "date-fns-tz";

import { OrderListType } from "src/interfaces/order";
import { formatCPF, formatCPNJ, formatPrice } from "src/utils/format";

export const renderCurrencyValue = (params: GridValueFormatterParams) =>
  formatPrice(params.value as number);

export const renderDateAndHour = (params: GridValueFormatterParams) => {
  if (params.value) {
    return format(new Date(params.value as string), "dd/MM/yyyy - HH:mm");
  }

  return "-";
};

export const renderDate = (params: GridValueFormatterParams) => {
  if (params.value) {
    return format(new Date(params.value as string), "dd/MM/yyyy");
  }

  return "-";
};

export const renderDocument = (params: GridValueFormatterParams) => {
  if (params.value === null) {
    return null;
  }
  const { contact } = params.row as OrderListType;

  if (contact?.cpf) {
    return formatCPF(contact?.cpf);
  }

  if (contact?.cnpj) {
    return formatCPNJ(contact?.cnpj);
  }

  return "-";
};

export const renderLocation = (params: GridValueFormatterParams) => {
  if (params.value === null) {
    return null;
  }
  const { contact } = params.row as OrderListType;

  return contact?.city && contact?.uf
    ? `${contact?.city} - ${contact?.uf}`
    : "-";
};

export const renderSimpleValue = (params: GridValueFormatterParams) =>
  params.value ? params.value : "-";
