import { RouteObject } from "react-router-dom";

import { BaseSwipeableDrawerProvider } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import PATHS from "src/constants/paths";
import { OrderFormProvider } from "src/hooks/order/useOrderForm/useOrderForm";
import CreateDirectSalePage from "src/pages/Orders/CreateDirectSale";

export const orderRoutes: RouteObject[] = [
  {
    path: PATHS.CREATE_DIRECT_SALE,
    element: (
      <BaseSwipeableDrawerProvider>
        <OrderFormProvider type={undefined} isDirectSale>
          <CreateDirectSalePage />
        </OrderFormProvider>
      </BaseSwipeableDrawerProvider>
    ),
  },
];
