import React, { useMemo, useState } from "react";

import { Box, Divider, Flex, Text, Wrap } from "@chakra-ui/react";
import { startOfMonth, endOfMonth } from "date-fns";

import { ReactComponent as ExcelIcon } from "src/assets/icons/reports/excel.svg";
import Button from "src/components/Button";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import { Select } from "src/components/NewComponents/Select";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import PATHS from "src/constants/paths";
import { Can } from "src/hooks/permission/usePermission";
import { UserType } from "src/interfaces/user";
import { useSalesComissionReport } from "src/services/hooks/reports/sales/useSalesComissionReport";
import { useUsers } from "src/services/hooks/user/useUsers";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { formatNumber } from "src/utils/format";
import { generateXLSX } from "src/utils/generateXLSX";

import InformationInitialReportState from "../components/InformationInitialReportState";
import SalesComissionBottomBar from "./components/SalesComissionBottomBar";
import SalesComissionTable from "./components/SalesComissionTable";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Comissão de vendedores" },
];

const today = new Date();

const SalesComissionPage = () => {
  const [exportLoading, setExportLoading] = useState(false);
  const [operator, setOperator] = useState({} as UserType);
  const [startDate, setStartDate] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(endOfMonth(today));

  const {
    data,
    refetch: getSalesComissionReport,
    isFetching,
    isFetched,
  } = useSalesComissionReport(
    {
      startDate: formatStartDateISO(startDate || today),
      endDate: formatEndDateISO(endDate || today),
      operatorId: operator?.id,
    },
    { enabled: false }
  );

  const { data: rows, isLoading } = useUsers(
    {
      limit: 10000,
      page: 1,
      status: "enabled",
    },
    { staleTime: 0 }
  );

  const users = useMemo(() => {
    return rows?.users ?? [];
  }, [rows]);

  const exportToXLSX = async () => {
    if (exportLoading) return;
    if (data.result.length) {
      setExportLoading(true);
      const header = [
        "Vendedor",
        "Qd de vendas",
        "Valor da comissão",
        "Valor de vendas",
      ];
      const dataExcel = data.result.reduce<string[][]>((data, model) => {
        data.push([model.period]);
        model.operators.forEach(operator => {
          const row = [
            operator.name,
            String(operator.count),
            formatNumber(operator.totalComission),
            formatNumber(operator.totalAmount),
          ] as Array<string>;
          data.push(row);
        });

        return data;
      }, []);

      const footer = [
        ["", "", "", ""],
        [
          "",
          "Total",
          String(data.totals.count),
          formatNumber(data.totals.totalAmount),
        ],
      ];

      await generateXLSX(
        header,
        [...dataExcel, ...footer],
        "Comissão de vendedores",
        "Comissão de vendedores.xlsx"
      );
      setExportLoading(false);
    }
  };

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Comissão de vendedores" />
      <Wrap width="100%" gap={2}>
        <PeriodFilterPicker
          startDate={startDate || today}
          endDate={endDate || today}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          initialFilter="byMonth"
          options={["perInterval", "byMonth"]}
          showingInPopover={false}
        />
        <Flex
          w="250px"
          alignItems="flex-end"
          sx={{
            ".azulou_select__control": {
              minHeight: "56px !important",
            },
          }}
        >
          <Select
            value={operator?.id}
            options={users.map(user => ({
              label: user.name,
              value: user.id,
            }))}
            isLoading={isLoading}
            onChange={newInputValue => {
              const user = users.find(user => user.id === newInputValue);
              setOperator(user as UserType);
            }}
            placeholder="Selecionar vendedor"
            showFloatingLabel={false}
          />
        </Flex>

        <Flex flex={1} justify="flex-end" alignItems="flex-end">
          <Button
            style={{ height: 56, marginLeft: 8 }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => getSalesComissionReport()}
          >
            Gerar relatório
          </Button>
          <Can subject="reportOrderComission" action="export">
            <Button
              style={{ height: 56, marginLeft: 8, minWidth: 120 }}
              color="primary"
              variant="outlined"
              size="medium"
              onClick={exportToXLSX}
              loading={exportLoading}
            >
              Exportar
              <ExcelIcon style={{ marginLeft: 10 }} />
            </Button>
          </Can>
        </Flex>
      </Wrap>

      <Divider mt={3} mb={1} />

      {!isFetched && !isFetching ? <InformationInitialReportState /> : null}
      {!isFetching
        ? data.result.map(item => (
            <Box key={item.period}>
              <Text fontWeight="bold" color="primary.500" my={4}>
                {item.period}
              </Text>
              <SalesComissionTable operators={item.operators} />
            </Box>
          ))
        : null}

      {!isFetching && data?.totals && isFetched ? (
        <SalesComissionBottomBar
          count={data.totals.count}
          totalAmount={data.totals.totalAmount}
        />
      ) : null}
      {isFetching ? (
        <Box height="100%">
          <Loader size="medium" />
        </Box>
      ) : null}
    </DashboardLayout>
  );
};

export default SalesComissionPage;
