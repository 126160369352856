import React, { FC, useMemo } from "react";

import Box from "@material-ui/core/Box";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { format } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

import Panel from "src/components/Layouts/Panel";

import { SchedulingCardProps } from "./props";
import * as Styled from "./styles";

const SchedulingCard: FC<SchedulingCardProps> = props => {
  const { data, openDetailModal } = props;

  const handleOpenDetailModal = () => {
    openDetailModal(data);
  };
  const renderTime = useMemo(() => {
    if (data.startDate) {
      const startDate = format(new Date(data.startDate), "HH:mm");

      return <Styled.TimeText>{startDate}</Styled.TimeText>;
    }

    return null;
  }, [data]);

  const renderDate = useMemo(() => {
    if (data.startDate) {
      const startDate = format(
        new Date(data.startDate),
        "EEE',' MMM d',' yyyy",
        {
          locale: ptBrLocale,
        }
      );

      const partOne = startDate.split(",")[0];
      const partTwo = startDate.split(",")[1];
      const partThree = startDate.split(",")[2];

      return (
        <>
          <Styled.DateText>{partOne}, </Styled.DateText>
          <Styled.DateText>
            {partTwo}, {partThree}
          </Styled.DateText>
        </>
      );
    }

    return null;
  }, [data]);

  return (
    <Panel
      style={{ cursor: "pointer" }}
      padding={2}
      minWidth={240}
      maxWidth={240}
      height={130}
      onClick={handleOpenDetailModal}
      border="solid 1px #D6D8DC"
    >
      <Styled.TitleText
        variant="subtitle1"
        align="left"
        lineThrough={Boolean(data?.isDoneAt)}
      >
        {data.title}
      </Styled.TitleText>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Box display="flex" alignItems="center" gridGap={4} marginBottom={1}>
            <Styled.IconBox>
              <Styled.ScheduleIcon color="primary" />
            </Styled.IconBox>
            {renderTime}
          </Box>
          <Box display="flex" alignItems="center" gridGap={4} marginBottom={1}>
            <Styled.IconBox>
              <Styled.CalendarTodayIcon color="primary" />
            </Styled.IconBox>
            {renderDate}
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-end" paddingBottom={2}>
          <ArrowForwardIosIcon color="primary" fontSize="small" />
        </Box>
      </Box>
    </Panel>
  );
};

export default SchedulingCard;
