import React, { FC, useMemo, useRef, useEffect } from "react";

import { Box } from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";

import BaseModal from "src/components/BaseModal";
import { useBaseModal } from "src/components/BaseModal/useBaseModal";
import Button from "src/components/Button";
import CurrencyField from "src/components/Inputs/CurrencyField";
import SelectField from "src/components/Inputs/SelectField";
import TextField from "src/components/Inputs/TextField";
import { FINANCIAL_ACCOUNT_TYPES } from "src/constants/financial";
import { useFinancialAccount } from "src/hooks/financialAccount/useFinancialAccount";
import { Can } from "src/hooks/permission/usePermission";
import { CreateOrEditFinancialAccountType } from "src/interfaces/financialAccount";
import { formatPrice } from "src/utils/format";

import InstitutionField from "../InstitutionField";
import * as Styled from "./styles";

const FinancialAccountFormModal: FC = () => {
  const {
    add,
    update,
    createStatus,
    updateStatus,
    financialAccount,
    setFinancialAccount,
  } = useFinancialAccount();

  const method = financialAccount ? "update" : "add";

  const { handleClose, handleOpen } = useBaseModal();

  const formikRef = useRef<FormikProps<CreateOrEditFinancialAccountType>>(null);

  const closeModal = () => {
    if (method === "add") {
      handleClose("financialAccount");
    }

    if (method === "update") {
      handleClose("financialAccountUpdate");
    }

    formikRef.current?.resetForm();
    setFinancialAccount(undefined);
  };

  useEffect(() => {
    if (financialAccount) {
      const data = {
        ...financialAccount,
        amount: financialAccount?.initialBalance || ("" as unknown as number),
      };

      formikRef.current?.setValues(data);
    } else {
      formikRef.current?.resetForm();
    }
  }, [financialAccount]);

  useEffect(() => {
    if (createStatus.success || updateStatus.success) {
      closeModal();
    }
  }, [createStatus, updateStatus]);

  const title = useMemo(() => {
    if (method === "add") {
      return "Nova conta";
    }

    return "Editar conta";
  }, [method, financialAccount]);

  const buttonTitle = useMemo(() => {
    if (method === "add") {
      return "Criar conta";
    }

    return "Salvar conta";
  }, [method, financialAccount]);

  const onSubmit = async (data: CreateOrEditFinancialAccountType) => {
    const { amount, institution, ...rest } = data;

    const sendInstitution = ["savings", "checking", "investments"].includes(
      rest?.type ?? ""
    );

    const parsedData: CreateOrEditFinancialAccountType = {
      ...rest,
      amount: Number(amount ?? 0),
      institution: sendInstitution ? institution : undefined,
    };

    if (method === "add") {
      await add(parsedData);
    } else {
      await update(data?.id as string, parsedData);
    }
  };

  const baseActions = useMemo(() => {
    return (
      <>
        {method === "update" && financialAccount?.status === "disabled" ? (
          <Can action="delete" subject="financialAccount">
            <Box width="100%" mb={1}>
              <Styled.DeleteButton
                variant="outlined"
                size="large"
                fullWidth
                onClick={() => handleOpen("financialAccountDelete")}
              >
                Excluir conta
              </Styled.DeleteButton>
            </Box>
          </Can>
        ) : null}
        {method === "update" && !financialAccount?.isDefault ? (
          <Can
            action={
              financialAccount?.status === "disabled" ? "enable" : "disable"
            }
            subject="financialAccount"
          >
            <Box width="100%" mb={1}>
              <Styled.DeleteButton
                variant="outlined"
                size="large"
                fullWidth
                onClick={() => handleOpen("financialAccountArchiveOrUnArchive")}
              >
                {financialAccount?.status === "disabled"
                  ? "Desarquivar conta"
                  : "Arquivar conta"}
              </Styled.DeleteButton>
            </Box>
          </Can>
        ) : null}
        <Can
          action={method === "update" ? "edit" : "create"}
          subject="financialAccount"
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            type="submit"
            loading={createStatus.loading || updateStatus.loading}
            disabled={createStatus.loading || updateStatus.loading}
            form="createOrUpdateFinancialAccount"
          >
            {buttonTitle}
          </Button>
        </Can>
      </>
    );
  }, [method, buttonTitle, financialAccount, createStatus, updateStatus]);

  return (
    <>
      <BaseModal
        title={title}
        tag={
          method === "update" ? "financialAccountUpdate" : "financialAccount"
        }
        customActions={baseActions}
        customHandleClose={closeModal}
      >
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            id: financialAccount?.id || "",
            name: financialAccount?.name || "",
            type: financialAccount?.type || "",
            amount:
              financialAccount?.initialBalance || ("" as unknown as number),
            isDefault: false,
            showSum: true,
            institution: financialAccount?.institution,
          }}
          validateOnChange={false}
          validateOnBlur={false}
          innerRef={formikRef}
        >
          {({ values, setFieldValue }) => (
            <Form id="createOrUpdateFinancialAccount">
              <Styled.Container>
                {method === "update" ? (
                  <Styled.BalanceRow>
                    <Styled.BalanceLabel>Saldo</Styled.BalanceLabel>
                    <Styled.BalanceValue>
                      {formatPrice(financialAccount?.balance ?? 0)}
                    </Styled.BalanceValue>
                  </Styled.BalanceRow>
                ) : null}
                <Box width="100%" mb={2}>
                  <TextField label="Nome da conta" name="name" required />
                </Box>

                <Box width="100%" mb={2}>
                  <SelectField
                    label="Tipo de conta"
                    name="type"
                    items={FINANCIAL_ACCOUNT_TYPES}
                    defaultValue=""
                    disabled={method === "update"}
                    placeholder="Selecione o tipo de conta"
                    required
                  />
                </Box>

                <Box width="100%" mb={2}>
                  <CurrencyField
                    disabled={method === "update"}
                    label="Valor inicial"
                    name="amount"
                  />
                </Box>
                {["savings", "checking", "investments"].includes(
                  values?.type ?? ""
                ) ? (
                  <Box width="100%" mb={2}>
                    <InstitutionField required disabled={method === "update"} />
                  </Box>
                ) : null}

                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Box display="flex" alignItems="center">
                    <Styled.InfoIcon />
                    <Styled.SwitchLabel>
                      Incluir na soma da tela inicial
                    </Styled.SwitchLabel>
                  </Box>
                  <Styled.Switch
                    checked={values.showSum}
                    onChange={() => setFieldValue("showSum", !values.showSum)}
                    name="showSum"
                  />
                </Box>
                {method === "add" ||
                (!financialAccount?.isDefault &&
                  financialAccount?.status === "enabled") ? (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Box display="flex" alignItems="center">
                      <Styled.InfoIcon />
                      <Styled.SwitchLabel>
                        Colocar esta conta como padrão
                      </Styled.SwitchLabel>
                    </Box>
                    <Styled.Switch
                      checked={values.isDefault}
                      onChange={() =>
                        setFieldValue("isDefault", !values.isDefault)
                      }
                      name="isDefault"
                    />
                  </Box>
                ) : null}
              </Styled.Container>
            </Form>
          )}
        </Formik>
      </BaseModal>
    </>
  );
};

export default FinancialAccountFormModal;
