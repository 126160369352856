import { DashboardFinancialResumeRecordsParamsType } from "src/interfaces/dashboard";
import api from "src/services/api";

import { DashboardInvervalEnum } from "../interfaces/dashboard";

/**
 * Dados apresentandos na dashboard sobre vendas
 */
export const getDashboardSalesService = (
  interval: keyof typeof DashboardInvervalEnum
) =>
  api
    .get("v1/dashboard/sales", {
      params: {
        interval,
      },
    })
    .then(res => res.data);

/** @DEPECRATED
 * Dados apresentandos na dashboard sobre a saúde financeira (mensal)
 */
export const getFinancialHealthData = () =>
  api.get("v1/dashboard/financial-health").then(res => res.data);

/**
 * Listagem de registros financeiros (Balanço mensal e contagem de contas)
 */
export const getDashboardFinancialResume = () =>
  api.get("v1/dashboard/financial/resume").then(res => res.data);

/**
 * Listagem de registros financeiros
 */
export const getDashboardFinancialResumeRecords = (
  params: DashboardFinancialResumeRecordsParamsType
) =>
  api
    .get("v2/dashboard/financial/resume/records", {
      params,
    })
    .then(res => res.data);
