import styled from "styled-components";

import MuiTextField from "@material-ui/core/TextField";

export const TextField = styled(MuiTextField)`
  .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
  .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline {
    /* border-color: ${({ theme, error }) =>
      !error
        ? theme.palette.error.main
        : theme.palette.primary.main} !important; */
  }

  .MuiInputBase-input {
    color: #13223e;
  }

  .Mui-disabled {
    opacity: 80%;
  }
`;
