import React, { useState } from "react";

import { Box, Divider, Flex, Radio, RadioGroup, Wrap } from "@chakra-ui/react";

import { ReactComponent as ExcelIcon } from "src/assets/icons/reports/excel.svg";
import Button from "src/components/Button";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import PATHS from "src/constants/paths";
import { Can } from "src/hooks/permission/usePermission";
import {
  InventoryFilterType,
  useInventoryReport,
} from "src/services/hooks/reports/financial/useInventoryReport";
import { formatNumber } from "src/utils/format";
import { generateXLSX } from "src/utils/generateXLSX";

import InformationInitialReportState from "../components/InformationInitialReportState";
import InventoryBottomBar from "./components/InventoryBottomBar";
import InventoryTable from "./components/InventoryTable";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Inventário" },
];

const InventoryPage = () => {
  const [exportLoading, setExportLoading] = useState(false);

  const [filter, setFilter] = useState<InventoryFilterType>("default");

  const {
    data,
    refetch: getInventoryReport,
    isFetching,
    isFetched,
  } = useInventoryReport(
    {
      filter,
    },
    { enabled: false }
  );

  const exportToXLSX = async () => {
    if (exportLoading) return;
    if (data.result.length) {
      setExportLoading(true);
      const header = ["Produto", "Quantidade", "Custo unit.", "Inventário"];

      const dataExcel = data.result.reduce<string[][]>((data, product) => {
        data.push([
          product.name,
          formatNumber(product.stock?.quantity ?? 0),
          formatNumber(product.cost ?? 0),
          formatNumber((product.cost ?? 0) * (product.stock?.quantity ?? 0)),
        ]);

        return data;
      }, []);

      const footer = [
        ["", "", ""],
        ["Total", formatNumber(data.totals.totalAmount)],
      ];

      await generateXLSX(
        header,
        [...dataExcel, ...footer],
        "Inventário",
        "Inventário.xlsx"
      );
      setExportLoading(false);
    }
  };

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Inventário" />
      <Wrap width="100%" gap={2} align="center">
        <RadioGroup
          value={filter}
          onChange={value => setFilter(value as InventoryFilterType)}
          as={Wrap}
          spacing={4}
          colorScheme="primary"
        >
          <Radio value="default">Estoque suficiente</Radio>
          <Radio value="minQuantity">Quantidade mínima</Radio>
          <Radio value="withoutStock">Sem estoque</Radio>
        </RadioGroup>
        <Flex flex={1} justify="flex-end" alignItems="flex-end">
          <Button
            style={{ height: 56, marginLeft: 8 }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => getInventoryReport()}
          >
            Gerar relatório
          </Button>
          <Can subject="reportInventory" action="export">
            <Button
              style={{ height: 56, marginLeft: 8, minWidth: 120 }}
              color="primary"
              variant="outlined"
              size="medium"
              onClick={exportToXLSX}
              loading={exportLoading}
            >
              Exportar
              <ExcelIcon style={{ marginLeft: 10 }} />
            </Button>
          </Can>
        </Flex>
      </Wrap>

      <Divider mt={3} mb={1} />

      {!isFetched && !isFetching ? <InformationInitialReportState /> : null}
      {!isFetching && isFetched ? <InventoryTable sales={data.result} /> : null}

      {!isFetching && data?.totals && isFetched ? (
        <InventoryBottomBar totalAmount={data.totals.totalAmount} />
      ) : null}
      {isFetching ? (
        <Box height="100%">
          <Loader size="medium" />
        </Box>
      ) : null}
    </DashboardLayout>
  );
};

export default InventoryPage;
