import React, { useMemo, useState } from "react";

import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Button from "src/components/Button";
import { useDocument } from "src/hooks/document/useDocument";
import { Can } from "src/hooks/permission/usePermission";
import { useRecord } from "src/hooks/record/useRecord";
import { RecordType } from "src/interfaces/record";

import { RecordCardOptionsMenuProps } from "./props";
import * as Styled from "./styles";

const RecordCardOptionsMenu: React.FC<RecordCardOptionsMenuProps> = props => {
  const { data } = props;

  const { handleOpen } = useBaseSwipeableDrawer();
  const { downloadReceipt } = useDocument();
  const { setRecord } = useRecord();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUpdateModal = () => {
    setRecord(data as RecordType);
    handleOpen("recordForm");
    handleClose();
  };

  const handleOpenUpdateTransferModal = () => {
    setRecord(data as RecordType);
    handleOpen("transferForm");
    handleClose();
  };

  const handleOpenPaymentModal = () => {
    setRecord(data as RecordType);
    handleOpen("payRecord");
    handleClose();
  };

  const handleOpenDeleteModal = () => {
    setRecord(data as RecordType);
    handleOpen("deleteRecord");
    handleClose();
  };

  const handleOpenDeleteTransferModal = () => {
    setRecord(data as RecordType);
    handleOpen("deleteTransfer");
    handleClose();
  };

  const handleOpenChargebackModal = () => {
    setRecord(data as RecordType);
    handleOpen("chargebackRecord");
    handleClose();
  };

  const generateReceipt = () => {
    downloadReceipt(data.id as string);
    handleClose();
  };

  const renderOptionsByStatus = useMemo(() => {
    if (data.type && ["transferIn", "transferOut"].includes(data.type)) {
      return (
        <>
          <Can action="edit" subject="record">
            <Button fullWidth onClick={handleOpenUpdateTransferModal}>
              Editar
            </Button>
          </Can>

          <Can action="delete" subject="record">
            <Styled.DeleteButton
              fullWidth
              onClick={handleOpenDeleteTransferModal}
            >
              Excluir
            </Styled.DeleteButton>
          </Can>
        </>
      );
    }

    if (data.paidStatus === "paid") {
      return (
        <>
          <Can action="edit" subject="record">
            <Button fullWidth onClick={handleOpenUpdateModal}>
              Editar
            </Button>
          </Can>
          <Can action="chargeback" subject="record">
            <Button fullWidth onClick={handleOpenChargebackModal}>
              Estornar
            </Button>
          </Can>
          {data.type === "income" ? (
            <Button fullWidth onClick={generateReceipt}>
              Emitir recibo
            </Button>
          ) : null}
          <Can action="delete" subject="record">
            <Box position="relative" display="flex" alignItems="center">
              <Styled.DeleteButton fullWidth disabled>
                Excluir
              </Styled.DeleteButton>
              <Styled.Tooltip
                classes={{
                  tooltip: "custom__tooltip",
                }}
                title={
                  <>
                    <Box
                      component="span"
                      fontSize="15px"
                      fontWeight="500"
                      color="#fff"
                    >
                      Exclusão desabilitada.{" "}
                    </Box>
                    <Box
                      component="span"
                      fontSize="15px"
                      fontWeight="400"
                      color="#d6d8dc"
                    >
                      Não é possivel excluir um lançamento já pago, é preciso
                      estornar o pagamento primeiro!
                    </Box>
                  </>
                }
                placement="bottom-end"
                arrow
              >
                <Styled.TooltipIcon />
              </Styled.Tooltip>
            </Box>
          </Can>
        </>
      );
    }

    return (
      <>
        <Can action="pay" subject="record">
          <Button fullWidth onClick={handleOpenPaymentModal}>
            Pagar
          </Button>
        </Can>
        <Can action="edit" subject="record">
          <Button fullWidth onClick={handleOpenUpdateModal}>
            Editar
          </Button>
        </Can>
        <Can action="delete" subject="record">
          <Styled.DeleteButton fullWidth onClick={handleOpenDeleteModal}>
            Excluir
          </Styled.DeleteButton>
        </Can>
      </>
    );
  }, [data]);

  return (
    <>
      <Button
        aria-describedby={`more-options-menu-${data.id}`}
        onClick={handleClick}
      >
        <MoreVertIcon color="primary" />
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        id={`more-options-menu-${data.id}`}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
      >
        <Styled.MenuOverlay>
          <Styled.MenuContainer>{renderOptionsByStatus}</Styled.MenuContainer>
        </Styled.MenuOverlay>
      </Popover>
    </>
  );
};

export default RecordCardOptionsMenu;
