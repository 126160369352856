import { Link as LinkUnstyled } from "react-router-dom";

import styled from "styled-components";

import MuiTooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

import Typography from "src/components/Typography";

export const BalanceValue = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  font-size: 32px;
`;

export const Link = styled(LinkUnstyled)`
  text-decoration: underline;
  color: #1e2c47;
`;

export const AccountLogo = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const AccountName = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  text-align: left;
`;

export const AccountBalance = styled(Typography)`
  font-size: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  text-align: right;
`;

export const TooltipIcon = styled(InfoIcon)`
  font-size: 24px;
  margin-right: 0.5rem;
`;

export const Tooltip = styled(MuiTooltip)`
  & > .custom__tooltip {
    background-color: red;
  }
`;
