import { startOfDay, endOfDay } from "date-fns";

/**
 * Data formatada no padrão ISO com o timezone
 */
export const formatDateToISO = (value: string | number | Date) => {
  const data = new Date(value);

  return data.toISOString();
};

/**
 * Retorna primeira hora do dia
 */
export const formatStartDateISO = (value: string | number | Date) => {
  const date = new Date(value);
  return startOfDay(date).toISOString();
};

/**
 * Retorna ultima hora do dia
 */
export const formatEndDateISO = (value: string | number | Date) => {
  const date = new Date(value);
  return endOfDay(date).toISOString();
};

/**
 * Retorna intervalo de anos válidos.
 * [2021...Ano atual]
 */
export const getValidYears = () => {
  const actualYear = new Date().getFullYear();
  const FIRST_VALID_YEAR = 2021;

  const years = [];

  for (
    let currentYear = FIRST_VALID_YEAR;
    currentYear <= actualYear;
    currentYear += 1
  ) {
    years.push(currentYear);
  }

  return years;
};
