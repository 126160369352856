/* eslint-disable no-unneeded-ternary */
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { Box, Flex, FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { Input } from "src/components/NewComponents/Input";

import { schemaPrintSettings } from "./schema";

export type PrintSettingsSchema = yup.InferType<typeof schemaPrintSettings>;

type PrintSettings = {
  handlePrint: () => void;
  setCustomizedMessage: React.Dispatch<React.SetStateAction<string>>;
  setPrintTechnicalReport: React.Dispatch<React.SetStateAction<string>>;
};

function PrintSettingsModal({
  handlePrint,
  setCustomizedMessage,
  setPrintTechnicalReport,
}: PrintSettings) {
  const localprintTechnicalReport = localStorage.getItem(
    "printTechnicalReport"
  );
  const localCustomizedMessage = localStorage.getItem("customizedMessage");

  const defaultValues: PrintSettingsSchema = {
    printTechnicalReport: localprintTechnicalReport || "false",
    customizedMessage: localCustomizedMessage || "",
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
  } = useForm<PrintSettingsSchema>({
    defaultValues,
    resolver: yupResolver(schemaPrintSettings),
  });

  const onSubmit = ({
    printTechnicalReport,
    customizedMessage,
  }: PrintSettingsSchema) => {
    localStorage.setItem("customizedMessage", customizedMessage as string);
    localStorage.setItem("printTechnicalReport", printTechnicalReport);
    setCustomizedMessage(customizedMessage as string);
    setPrintTechnicalReport(printTechnicalReport);
    handlePrint();
  };

  return (
    <BaseSwipeableDrawer
      tag="printSettings"
      title="Configurar documento"
      buttonProps={{
        form: "printSettingsForm",
      }}
      buttonTitle="Imprimir"
    >
      <Flex
        w={["100%", "450px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="printSettingsForm"
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
        direction="column"
      />
      <Box>
        <Controller
          control={control}
          name="printTechnicalReport"
          render={({ field: { ref, value, onChange, name } }) => (
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="printTechnicalReport" flex={1} m={0}>
                Imprimir Laudo Técnico
              </FormLabel>
              <Switch
                ref={ref}
                name={name}
                isChecked={value === "true"}
                checked={value === "false"}
                id="printTechnicalReport"
                onChange={e => {
                  if (e.target.checked) {
                    onChange("true");
                  } else {
                    onChange("false");
                  }
                }}
              />
            </FormControl>
          )}
        />
      </Box>
      <Box my={4}>
        <Input
          label="Mensagem personalizada"
          error={errors.customizedMessage}
          {...register("customizedMessage")}
        />
      </Box>
    </BaseSwipeableDrawer>
  );
}

export default PrintSettingsModal;
