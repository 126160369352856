import React, { FC, useMemo, useState } from "react";

import { Box, Grid } from "@material-ui/core";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  Sector,
  Text,
} from "recharts";

import Panel from "src/components/Layouts/Panel";
import { useFinancial } from "src/hooks/financial/useFinancial";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const CategoryChart: FC = () => {
  const { financialOverview } = useFinancial();
  const [activeIndex, setActiveIndex] = useState(0);

  const generateColor = () => {
    const color = Math.floor(Math.random() * 16777215).toString(16);
    return `#${color}`;
  };
  // dark colors to adjust
  const data = useMemo(() => {
    return (
      financialOverview.expensesCategories?.map(category => ({
        ...category,
        name: category.label,
        color: generateColor(),
      })) ?? []
    );
  }, [financialOverview]);

  const renderActiveShape = (props: any) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;

    return (
      <g>
        <Text
          x={cx}
          y={cy}
          fontSize={20}
          fontWeight={600}
          width={150}
          textAnchor="middle"
          fill="#1A5DF2"
          maxLines={1}
        >
          {formatPrice(payload.value)}
        </Text>
        <Text
          x={cx}
          y={cy}
          dy={32}
          fontSize={12}
          width={150}
          textAnchor="middle"
          fill="#1A5DF2"
          maxLines={3}
        >
          {payload.name}
        </Text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  return (
    <Grid item xs={12}>
      <Panel padding={2} marginBottom={2}>
        <Grid container spacing={2}>
          <Grid container xs={6}>
            <Box width="100%" height={250}>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart data={data}>
                  <Label value="any text" position="center" fill="#1A5DF2" />
                  <Pie
                    data={data}
                    innerRadius="70%"
                    outerRadius="90%"
                    dataKey="value"
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    onMouseEnter={onPieEnter}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${entry.label}-${index.toString()}`}
                        fill={entry.color}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Grid>
          <Grid item container xs={6}>
            {data.map((entry, index) => (
              <Grid
                onMouseEnter={() => setActiveIndex(index)}
                key={`category-${entry.label}-${index.toString()}`}
                xs={6}
              >
                <Box
                  width={30}
                  height={13}
                  borderRadius={5}
                  bgcolor={entry.color}
                  mb={1}
                />
                <Styled.CategoryText>{entry.name}</Styled.CategoryText>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Panel>
    </Grid>
  );
};

export default CategoryChart;
