/* eslint-disable no-console */
import React from "react";

import { Button } from "@chakra-ui/react";
import { Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { useModal } from "src/components/Modal/useModal";
import { useModule } from "src/hooks/module/useModule";
import { OrderListType } from "src/interfaces/order";
import DeleteModal from "src/pages/Orders/components/DeleteModal";

import * as Styled from "./styles";

interface DeleteOrderProps {
  order: OrderListType;
  afterActions(): void;
}

const DeleteOrder: React.FC<DeleteOrderProps> = props => {
  const { afterActions, order } = props;

  const { handleOpen } = useModal();
  const { hasModules } = useModule();

  const canDeleteOrder =
    (!hasModules("stock") || !order?.stockCreatedAt) &&
    !order?.recordsCreatedAt;

  return (
    <>
      <Box position="relative" display="flex" alignItems="center">
        <Button
          w="100%"
          justifyContent="flex-start"
          size="sm"
          variant="ghost"
          color="text"
          leftIcon={<DeleteIcon color="primary" fontSize="small" />}
          disabled={!canDeleteOrder}
          onClick={handleOpen}
        >
          Excluir
        </Button>
        <DeleteModal id={order.id} afterActions={afterActions} />

        {!canDeleteOrder ? (
          <Styled.Tooltip
            classes={{
              tooltip: "custom__tooltip",
            }}
            title={
              <>
                <Box
                  component="span"
                  fontSize="15px"
                  fontWeight="500"
                  color="#fff"
                >
                  Exclusão desabilitada.{" "}
                </Box>
                <Box
                  component="span"
                  fontSize="15px"
                  fontWeight="400"
                  color="#d6d8dc"
                >
                  Para excluir{" "}
                  {order.type === "serviceOrder"
                    ? " esta ordem de serviço "
                    : " este pedido de venda "}
                  é preciso estornar o estoque e pagamentos!
                </Box>
              </>
            }
            placement="bottom-end"
            arrow
          >
            <Box position="absolute" width="100%" height="100%" />
          </Styled.Tooltip>
        ) : null}
      </Box>
    </>
  );
};

export default DeleteOrder;
