import React from "react";

import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  usePopupState,
  bindToggle,
  bindPopover,
} from "material-ui-popup-state/hooks";

import Button from "src/components/Button";
import { useModal } from "src/components/Modal/useModal";
import DeleteModal from "src/pages/Contacts/components/DeleteModal";

import { useContactType } from "../../useContactType";
import { MoreOptionsMenuProps } from "./props";

const MoreOptionsMenu: React.FC<MoreOptionsMenuProps> = props => {
  const { id } = props;

  const { handleOpen } = useModal();
  const { type } = useContactType();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-options-menu-table",
  });

  return (
    <>
      <Button {...bindToggle(popupState)}>
        <MoreVertIcon color="primary" />
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box>
          <Button fullWidth onClick={handleOpen}>
            Deletar
          </Button>
        </Box>
      </Popover>
      <DeleteModal id={id} afterActions={popupState.close} type={type} />
    </>
  );
};

export default MoreOptionsMenu;
