import React, { useState, createContext, useContext } from "react";

import { TypeOfOrder } from "src/interfaces/order";

import { OrderTypeContextProps } from "./props";

export const OrderTypeContext = createContext({} as OrderTypeContextProps);

export const OrderTypeProvider: React.FC = props => {
  const { children } = props;

  const [type, setType] = useState<TypeOfOrder>("serviceOrder");

  return (
    <OrderTypeContext.Provider
      value={{
        type,
        setType,
      }}
    >
      {children}
    </OrderTypeContext.Provider>
  );
};

export const useOrderType = () => {
  const context = useContext(OrderTypeContext);

  if (!context) {
    throw new Error("useOrderType must be used within a OrderTypeProvider");
  }

  return context;
};
