import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@material-ui/core/Box";

import Button from "src/components/Button";
import BottomBar from "src/components/Layouts/BottomBar";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Panel from "src/components/Layouts/Panel";
import { ModalProvider, useModal } from "src/components/Modal/useModal";
import PATHS from "src/constants/paths";
import { ServiceProvider, useService } from "src/hooks/service/useService";
import { withContext } from "src/utils/withContext";

import DeleteModal from "../components/DeleteModal";
import ServiceForm from "../components/ServiceForm";
import * as Styled from "./styles";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Serviços", path: PATHS.SERVICES_LIST },
  { label: "Detalhes do serviço" },
];

const ServicesDetail: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const { detail, status, service } = useService();

  const { handleOpen } = useModal();

  const navigate = useNavigate();

  useEffect(() => {
    detail(id);
  }, [id]);

  const afterAction = () => {
    navigate(PATHS.SERVICES_DETAIL);
  };

  return (
    <DashboardLayout selected="register" subSelected="services">
      <Breadcrumb data={BREADCRUMB_BUTTONS}>
        <Box mt={2}>
          <Styled.DeleteButton
            variant="outlined"
            size="medium"
            onClick={handleOpen}
          >
            Excluir serviço
          </Styled.DeleteButton>

          <DeleteModal id={id} afterActions={afterAction} />
        </Box>
      </Breadcrumb>

      <PageTitle label="Detalhes do serviço" />

      <Panel padding={4}>
        <ServiceForm method="update" data={service} />
      </Panel>

      <BottomBar>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          form="form-service"
          disabled={status.loading}
        >
          Salvar
        </Button>

        <Box width={20} />

        <Button color="primary" variant="outlined" to={PATHS.SERVICES_LIST}>
          Cancelar
        </Button>
      </BottomBar>
    </DashboardLayout>
  );
};

export default withContext(ServicesDetail, ServiceProvider, ModalProvider);
