import React, { useRef, useEffect } from "react";
import { toast } from "react-toastify";

import { Box, Divider } from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Textarea from "src/components/Inputs/Textarea";
import TextField from "src/components/Inputs/TextField";
import Typography from "src/components/Typography";
import {
  saveConfigurationDocument,
  getConfigurationDocument,
  DocumentConfigurationKeys,
} from "src/constants/documentConfiguration";
import { PAGE_ORDER_TITLES } from "src/constants/orders";
import { useCompany } from "src/hooks/company/useCompany";
import { useDocument } from "src/hooks/document/useDocument";
import { DocumentConfigurationType } from "src/interfaces/documentConfiguration";

import ConfigurationDocumentOption from "./DocumentConfigurationOption";
import * as Styled from "./styles";

interface DocumentConfigurationModalProps {
  orderType: "serviceOrder" | "productOrder";
  documentType: DocumentConfigurationKeys;
  orderId: string;
}

const DocumentConfigurationModal: React.FC<DocumentConfigurationModalProps> =
  props => {
    const { documentType, orderType, orderId } = props;
    const { downloadOrderBudget, downloadOrderDocument, status } =
      useDocument();
    const { handleClose } = useBaseSwipeableDrawer();
    const { serviceOrderNameDefinitions } = useCompany();

    const formikRef = useRef<FormikProps<DocumentConfigurationType>>(null);

    const generateDocument = (values: DocumentConfigurationType) => {
      if (["serviceOrder", "productOrder"].includes(documentType)) {
        saveConfigurationDocument(documentType, {
          title: values.docTitle,
          description: values.docDescription,
        });
        downloadOrderDocument(
          orderId,
          values.docTitle
            ? values.docTitle
            : PAGE_ORDER_TITLES(
                orderType === "serviceOrder"
                  ? serviceOrderNameDefinitions
                  : undefined
              )[orderType].name,
          values
        );
      } else if (
        ["serviceOrderBudget", "productOrderBudget"].includes(documentType)
      ) {
        downloadOrderBudget(
          orderId,
          values.docTitle ? values.docTitle : "orçamento",
          values
        );
        saveConfigurationDocument(documentType, {
          title: values.docTitle,
          description: values.docDescription,
        });
      } else {
        toast.error("Tipo de documento inválido");
      }
    };

    const customHandleClose = () => {
      if (status.loading) return;
      handleClose("configDocument");
    };

    useEffect(() => {
      if (status.success) {
        handleClose("configDocument");
      }
    }, [status]);

    useEffect(() => {
      if (
        (
          [
            "serviceOrder",
            "serviceOrderBudget",
            "productOrder",
            "productOrderBudget",
          ] as Array<DocumentConfigurationKeys>
        ).includes(documentType)
      ) {
        const configurationDocument = getConfigurationDocument();
        const { title } = configurationDocument[documentType] ?? "";
        const { description } = configurationDocument[documentType] ?? "";

        formikRef.current?.setFieldValue("docTitle", title);
        formikRef.current?.setFieldValue("docDescription", description);
      }
    }, [documentType]);

    return (
      <BaseSwipeableDrawer
        tag="configDocument"
        title="Configurar documento"
        buttonTitle="Gerar documento"
        buttonProps={{
          loading: status.loading,
          disabled: status.loading,
          form: "configDocumentForm",
        }}
        customHandleClose={customHandleClose}
      >
        <Formik
          initialValues={{
            docTitle: "",
            docDescription: "",
            showProductPrice: true,
            showProductDescription: true,
            showServicePrice: true,
            showServiceDescription: true,
          }}
          onSubmit={generateDocument}
          innerRef={formikRef}
          enableReinitialize
        >
          <Form id="configDocumentForm">
            <Styled.Container>
              <Typography variant="body2" color="textSecondary">
                Selecione abaixo as configurações que você deseja para realizar
                o download do documento
              </Typography>
              <Box width="100%" mb={2} mt={2}>
                <Divider />
              </Box>
              <Box width="100%" mb={2}>
                <TextField label="Título" name="docTitle" />
              </Box>
              <Box width="100%">
                <Textarea label="Observação" name="docDescription" rows={3} />
              </Box>
              <Box width="100%" mb={2} mt={2}>
                <Divider />
              </Box>
              <Typography variant="body2" color="textSecondary">
                Preços
              </Typography>
              <ConfigurationDocumentOption
                label="Mostrar preços dos produtos"
                name="showProductPrice"
              />
              {["serviceOrder"].includes(orderType) ? (
                <ConfigurationDocumentOption
                  label="Mostrar preços dos serviços"
                  name="showServicePrice"
                />
              ) : null}

              <Box width="100%" mt={2}>
                <Typography variant="body2" color="textSecondary">
                  Descrições
                </Typography>
              </Box>
              <ConfigurationDocumentOption
                label="Mostrar descrição dos produtos"
                name="showProductDescription"
              />
              {["serviceOrder"].includes(orderType) ? (
                <ConfigurationDocumentOption
                  label="Mostrar descrição dos serviços"
                  name="showServiceDescription"
                />
              ) : null}
            </Styled.Container>
          </Form>
        </Formik>
      </BaseSwipeableDrawer>
    );
  };

export default DocumentConfigurationModal;
