import React from "react";

import Typography from "src/components/Typography";

import { AditionalInfoTextAreaSectionProps } from "./props";
import * as Styled from "./styles";

const AditionalInfoTextAreaSection: React.FC<AditionalInfoTextAreaSectionProps> =
  props => {
    const { disabled, value, ...rest } = props;

    if (disabled) {
      return (
        <Styled.ContainerTextArea>
          <Typography color="primary">Informações adicionais</Typography>
          <Styled.BasicTextArea
            name="additionalInfo"
            multiline
            rows={9}
            placeholder="Aqui temos observações sobre a ordem de compra"
            disabled
            value={value}
          />
        </Styled.ContainerTextArea>
      );
    }

    return (
      <Styled.AditionalInfoTextArea
        name="additionalInfo"
        rows={4}
        placeholder="Observação (caso deseje, escreva algo que ache relevante para a ordem de compra)"
        {...rest}
      />
    );
  };

export default AditionalInfoTextAreaSection;
