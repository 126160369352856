import { FC, useMemo, useEffect, useState } from "react";

import { Divider, Flex, Text } from "@chakra-ui/react";
import { ButtonProps } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { format, formatDistance } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Button from "src/components/Button";
import { useSchedulingDelete } from "src/hooks/scheduling/delete/useSchedulingDelete";
import { useSchedulingDetail } from "src/hooks/scheduling/detail/useSchedulingDetail";
import { useSchedulingDone } from "src/hooks/scheduling/done/useSchedulingDone";
import { formatDateToISO } from "src/utils/date";

import SchedulingDeleteModal from "../SchedulingDelete";
import SchedulingFormModal from "../SchedulingFormModal";
import SchedulingUpdateModal from "../SchedulingUpdateModal";
import { SchedulingDetailModalProps } from "./props";
import * as Styled from "./styles";

const today = new Date();

const ButtonOption: FC<ButtonProps> = props => {
  const { children, ...rest } = props;

  return (
    <Button fullWidth {...rest}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
      >
        {children}
      </Box>
    </Button>
  );
};

const SchedulingDetailModal: FC<SchedulingDetailModalProps> = props => {
  const { data } = props;

  const { handleClose, open, handleOpen } = useBaseSwipeableDrawer();
  const {
    scheduling: schedulingDetail,
    detail,
    status: statusDetail,
  } = useSchedulingDetail();

  const { status: statusDelete } = useSchedulingDelete();

  const { status: statusDone, done } = useSchedulingDone();

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenUpdateModal = () => {
    handleOpen("DrawerUpdateScheduling");
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
    handleClose("DetailCompromisseHome");
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (data.id) {
      detail(data.id);
    }
  }, [statusDone, open]);

  useEffect(() => {
    if (statusDelete.success) {
      handleClose("DetailCompromisseHome");
    }
  }, [statusDelete.success]);

  const createdAt = useMemo(() => {
    if (data.createdAt) {
      return formatDistance(new Date(), new Date(data.createdAt), {
        locale: ptBrLocale,
      });
    }

    return "-";
  }, [data]);

  const date = useMemo(() => {
    if (data.startDate) {
      return format(new Date(data.startDate), "dd/MM");
    }

    return "-";
  }, [data]);

  const hour = useMemo(() => {
    if (data.startDate) {
      return format(new Date(data.startDate), "HH:mm");
    }

    return "-";
  }, [data]);

  const handleDoneScheduling = () => {
    done({
      id: data.id,
      isDoneAt: schedulingDetail.isDoneAt ? null : formatDateToISO(today),
    });
  };

  const contentDoneButton = useMemo(() => {
    if (statusDetail.loading || statusDone.loading) {
      return <Styled.Loading size={15} />;
    }

    if (schedulingDetail?.isDoneAt) {
      return (
        <>
          <Styled.CheckedIcon fontSize="small" />
          <Styled.DoneButtonLabel>Finalizado</Styled.DoneButtonLabel>
        </>
      );
    }

    return (
      <>
        <Styled.UnCheckedIcon fontSize="small" />
        <Styled.DoneButtonLabel>Finalizar</Styled.DoneButtonLabel>
      </>
    );
  }, [schedulingDetail, statusDetail, statusDone]);

  const customHeader = (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Styled.TitleText>Reunião</Styled.TitleText>
      </Box>
    </Box>
  );

  const customActions = (
    <Flex w="100%">
      <Grid item xs={4}>
        <ButtonOption onClick={handleDoneScheduling}>
          {contentDoneButton}
        </ButtonOption>
      </Grid>

      <Grid item xs={4}>
        <ButtonOption onClick={handleOpenUpdateModal}>
          <CreateIcon color="primary" fontSize="small" />
          <Styled.ButtonLabel>Editar</Styled.ButtonLabel>
        </ButtonOption>
      </Grid>

      <Grid item xs={4}>
        <ButtonOption onClick={() => handleOpenDeleteModal()}>
          <DeleteIcon color="error" fontSize="small" />
          <Styled.ButtonLabel>Excluir</Styled.ButtonLabel>
        </ButtonOption>
      </Grid>
    </Flex>
  );

  return (
    <>
      <BaseSwipeableDrawer
        tag="DetailCompromisseHome"
        customActions={customActions}
        customHeader={customHeader}
        buttonProps={{
          form: "addCompromisseHome",
        }}
        zIndex={1302}
        customHandleClose={() => handleClose("DetailCompromisseHome")}
      >
        <Flex
          w={["100%", "350px"]}
          bg="white"
          pt={4}
          borderRadius={10}
          border="solid 1px gray-200"
          gap={4}
          direction="column"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Text color="gray">Adicionado há {createdAt}</Text>
            </Grid>

            <Grid item>
              <Text color="text">
                Data:&nbsp;
                <Text as="span" color="gray">
                  {date}
                </Text>
              </Text>
            </Grid>

            <Grid item>
              <Text color="text">
                Horário:&nbsp;
                <Text as="span" color="gray">
                  {hour}
                </Text>
              </Text>
            </Grid>
            <Divider />

            <Grid item xs={12}>
              <Text color="text">
                Titulo :
                <Text color="gray" fontSize="sm">
                  {data.title}
                </Text>
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Text color="text">
                Descrição :
                <Text fontSize="sm" color="gray">
                  {data?.description ? data.description : "-"}
                </Text>
              </Text>
            </Grid>

            <Grid item xs={6}>
              <Text color="text">
                Local :{" "}
                <Text fontSize="sm" color="gray">
                  {data?.place ? data.place : "-"}
                </Text>
              </Text>
            </Grid>
          </Grid>
        </Flex>

        <SchedulingUpdateModal data={data} />
      </BaseSwipeableDrawer>
      <SchedulingFormModal
        method="update"
        open={openUpdateModal}
        handleClose={handleCloseUpdateModal}
        data={data}
      />

      <SchedulingDeleteModal
        id={data?.id as string}
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
      />
    </>
  );
};

export default SchedulingDetailModal;
