import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { Box } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import { getRouteString } from "src/utils/urlHandler";

import SubcontactsTable from "./components/SubcontactsTable";
import * as Styled from "./styles";

const Subcontacts: FC = () => {
  const { id = "", tab = "" } = useParams<{ id: string; tab: string }>();
  const { subContactNameDefinitions } = useCompany();

  const path = `${getRouteString(
    PATHS.CONTACTS_DETAIL,
    id,
    "id"
  )}/${tab}/novo-${subContactNameDefinitions.title.toLowerCase()}`;

  return (
    <Box>
      <Box width="100%" display="flex" justifyContent="flex-end" mb={4}>
        <Styled.Button variant="contained" color="primary" to={path}>
          <Typography variant="body2">
            Novo {subContactNameDefinitions.title.toLowerCase()}
          </Typography>
          <AddCircleIcon />
        </Styled.Button>
      </Box>
      <SubcontactsTable />
    </Box>
  );
};

export default Subcontacts;
