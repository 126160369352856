import styled from "styled-components";

import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

import Button from "src/components/Button";
import Typography from "src/components/Typography";

export const AlertIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 45px;
`;

export const InfoText = styled(Typography)`
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const DeleteButton = styled(Button)`
  background-color: #e54848;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 15px;
  font-weight: 500;

  :hover {
    background-color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const CloseButton = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;
