import { useStorage } from "src/hooks/storage/useStorage";
import { PreferencesOrdersType } from "src/interfaces/preferences";

export const usePreferencesStorage = () => {
  const { getItem, setItem } = useStorage();
  function saveParamsByKey(
    key: "serviceOrder" | "productOrder",
    params: any
  ): void {
    const storage = getItem<PreferencesOrdersType>("preferences", {} as any);

    if (key === "serviceOrder") {
      const data = {
        ...storage,
        serviceOrder: {
          ...(storage?.serviceOrder ?? {}),
          ...params,
        },
      };
      setItem("preferences", data);
    }
    if (key === "productOrder") {
      const data = {
        ...storage,
        productOrder: {
          ...(storage?.productOrder ?? {}),
          ...params,
        },
      };
      setItem("preferences", data);
    }
  }

  function getPreferencesStorage() {
    const storage = getItem<PreferencesOrdersType>(
      "preferences",
      {} as PreferencesOrdersType
    );
    return storage as PreferencesOrdersType;
  }

  return { saveParamsByKey, getPreferencesStorage };
};
