import React, { useState, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import {
  BuyOrderListParamsType,
  BuyOrderStatuesMetaParamsType,
  BuyOrderStatusType,
  BuyOrderType,
  UpdateBuyOrderStatusParams,
} from "src/interfaces/buyOrder";
import {
  getBuyOrdersService,
  getBuyOrdersStatusesMeta,
  updateBuyOrderStatusService,
  deleteBuyOrdersService,
  getBuyOrderService,
  createBuyOrderStockService,
  deleteBuyOrderStockService,
  deleteBuyOrderRecordsService,
  createBuyOrderRecordsService,
} from "src/services/buy_order";
import { sumArrayValues } from "src/utils/arrayUtil";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";

import { BuyOrderFiltersContextType } from "./props";

export const useBuyOrderContext = createContext(
  {} as BuyOrderFiltersContextType
);

export const useBuyOrderProvider: React.FC = props => {
  const { children } = props;
  const navigate = useNavigate();

  const [getBuyOrderStatuesMetaStatus, setGetBuyOrderStatuesMetaStatus] =
    useState(STATUS.inital);

  const [buyOrdersStatusesMeta, setBuyOrdersStatusesMeta] = useState<
    BuyOrderStatusType[]
  >([]);

  const getBuyOrderStatuesMeta = async (
    params: BuyOrderStatuesMetaParamsType
  ) => {
    try {
      setGetBuyOrderStatuesMetaStatus(STATUS.loading);

      const response: BuyOrderStatusType[] = await getBuyOrdersStatusesMeta({
        ...params,
        ...(params.startDate && {
          startDate: formatStartDateISO(params.startDate),
        }),
        ...(params.endDate && { endDate: formatEndDateISO(params.endDate) }),
      });

      const defaultOption: BuyOrderStatusType = {
        count: sumArrayValues(response.map(item => item.count)),
        totalAmount: sumArrayValues(response.map(item => item.totalAmount)),
        status: "",
      };

      const statuses = [defaultOption, ...response];

      setBuyOrdersStatusesMeta(statuses);

      setGetBuyOrderStatuesMetaStatus(STATUS.success);
    } catch (e) {
      setGetBuyOrderStatuesMetaStatus(STATUS.error);
    }
  };

  const [buyOrdersStatus, setBuyOrdersStatus] = useState(STATUS.inital);
  const [buyOrders, setBuyOrders] = useState<BuyOrderType[]>([]);

  const getBuyOrders = async (params: BuyOrderListParamsType) => {
    if (buyOrdersStatus.loading) return;
    try {
      setBuyOrders([]);
      setBuyOrdersStatus(STATUS.loading);

      const response: BuyOrderType[] = (await getBuyOrdersService(params))
        .buyOrders;

      setBuyOrders(response);

      setBuyOrdersStatus(STATUS.success);
    } catch (e) {
      setBuyOrders([]);
      setBuyOrdersStatus(STATUS.error);
    }
  };

  const [buyOrder, setBuyOrder] = useState<BuyOrderType>({} as BuyOrderType);
  const [buyOrderStatus, setBuyOrderStatus] = useState(STATUS.inital);

  const getBuyOrder = async (id: string) => {
    if (buyOrdersStatus.loading) return;
    try {
      setBuyOrderStatus(STATUS.loading);

      const response: BuyOrderType = await getBuyOrderService(id);

      setBuyOrder(response);

      setBuyOrderStatus(STATUS.success);
    } catch (e) {
      setBuyOrderStatus(STATUS.error);
      toast.error(
        "Falha ao recarregar dados da ordem de compra, atualize sua página"
      );
      navigate(-1);
    }
  };

  const [updateBuyOrderStatusRequest, setUpdateBuyOrderStatusRequest] =
    useState(STATUS.inital);
  const updateBuyOrderStatus = async (params: UpdateBuyOrderStatusParams) => {
    try {
      setUpdateBuyOrderStatusRequest(STATUS.loading);

      await updateBuyOrderStatusService(params);

      setUpdateBuyOrderStatusRequest(STATUS.success);
    } catch (e) {
      setUpdateBuyOrderStatusRequest(STATUS.error);
      throw e;
    }
  };

  const [deleteBuyOrderStatus, setDeleteBuyOrderStatus] = useState(
    STATUS.inital
  );

  const deleteBuyOrder = async (id: string) => {
    try {
      setDeleteBuyOrderStatus(STATUS.loading);

      await deleteBuyOrdersService(id);

      setDeleteBuyOrderStatus(STATUS.success);
      toast.success("Ordem de compra excluída com sucesso");
    } catch (e) {
      setDeleteBuyOrderStatus(STATUS.error);
      toast.error("Falha ao excluir ordem de compra");
    }
  };

  const [createBuyOrderStockStatus, setCreateBuyOrderStockStatus] = useState(
    STATUS.inital
  );

  const createBuyOrderStock = async (id: string) => {
    try {
      setCreateBuyOrderStockStatus(STATUS.loading);

      await createBuyOrderStockService(id);
      setBuyOrder(prev => ({
        ...prev,
        stockCreatedAt: new Date().toLocaleDateString(),
      }));

      setCreateBuyOrderStockStatus(STATUS.success);
      toast.success("Estoque lançado com sucesso");
    } catch (e) {
      setCreateBuyOrderStockStatus(STATUS.error);
      toast.error("Falha ao lançar estoque, tente novamente");
    }
  };

  const [deleteBuyOrderStockStatus, setDeleteBuyOrderStockStatus] = useState(
    STATUS.inital
  );

  const deleteBuyOrderStock = async (id: string) => {
    try {
      setDeleteBuyOrderStockStatus(STATUS.loading);

      await deleteBuyOrderStockService(id);
      setBuyOrder(prev => ({
        ...prev,
        stockCreatedAt: null,
      }));

      setDeleteBuyOrderStockStatus(STATUS.success);
      toast.success("Estoque estornado com sucesso");
    } catch (e) {
      setDeleteBuyOrderStockStatus(STATUS.error);
      toast.error("Falha ao estornar estoque, tente novamente");
    }
  };

  const [createBuyOrderRecordsStatus, setCreateBuyOrderRecordsStatus] =
    useState(STATUS.inital);

  const createBuyOrderRecords = async (id: string) => {
    try {
      setCreateBuyOrderRecordsStatus(STATUS.loading);

      await createBuyOrderRecordsService(id);
      await getBuyOrder(id);

      setCreateBuyOrderRecordsStatus(STATUS.success);
      toast.success("Contas lançadas com sucesso");
    } catch (e: any) {
      setCreateBuyOrderRecordsStatus(STATUS.error);
      toast.error(
        e?.response?.data?.message ?? "Falha ao lançar contas, tente novamente"
      );
    }
  };

  const [deleteBuyOrderRecordsStatus, setDeleteBuyOrderRecordsStatus] =
    useState(STATUS.inital);

  const deleteBuyOrderRecords = async (id: string) => {
    try {
      setDeleteBuyOrderRecordsStatus(STATUS.loading);

      await deleteBuyOrderRecordsService(id);
      await getBuyOrder(id);

      setDeleteBuyOrderRecordsStatus(STATUS.success);
      toast.success("Contas estornadas com sucesso");
    } catch (e: any) {
      setDeleteBuyOrderRecordsStatus(STATUS.error);
      toast.error(
        e?.response?.data?.message ??
          "Falha ao estornar contas, tente novamente."
      );
    }
  };

  return (
    <useBuyOrderContext.Provider
      value={{
        getBuyOrderStatuesMetaStatus,
        buyOrdersStatusesMeta,
        getBuyOrderStatuesMeta,
        buyOrdersStatus,
        buyOrders,
        getBuyOrders,
        updateBuyOrderStatus,
        updateBuyOrderStatusRequest,
        deleteBuyOrderStatus,
        deleteBuyOrder,
        buyOrder,
        setBuyOrder,
        buyOrderStatus,
        getBuyOrder,
        createBuyOrderStockStatus,
        createBuyOrderStock,
        deleteBuyOrderStockStatus,
        deleteBuyOrderStock,
        createBuyOrderRecordsStatus,
        createBuyOrderRecords,
        deleteBuyOrderRecordsStatus,
        deleteBuyOrderRecords,
      }}
    >
      {children}
    </useBuyOrderContext.Provider>
  );
};

export const useBuyOrder = () => {
  const context = useContext(useBuyOrderContext);

  if (!context) {
    throw new Error("useBuyOrder must be used within a useBuyOrderProvider");
  }

  return context;
};
