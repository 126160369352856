import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import RenderModule from "src/components/RenderModule";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import { ProductProvider, useProduct } from "src/hooks/product/useProduct";
import { withContext } from "src/utils/withContext";

import OptionsPanel from "./components/OptionsPanel";
import Table from "./components/Table";
import * as Styled from "./styles";
import { usePrintBarCodeLabels } from "./usePrintBarCodeLabels";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [{ label: "Produtos" }];

const ProductsList: React.FC = () => {
  const { products } = useProduct();

  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);

  const { handlePrint, PrintBarCodeLabelsComponent } = usePrintBarCodeLabels(
    products?.products?.filter(product => selectedRows.includes(product.id)) ??
      []
  );

  return (
    <DashboardLayout selected="register" subSelected="products">
      {PrintBarCodeLabelsComponent}
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <PageTitle label="Produtos" mt={0} mb={3}>
        <Box display="flex">
          <Styled.Button
            variant="contained"
            color="primary"
            to={PATHS.PRODUCTS_ADD}
          >
            <AddCircleIcon />
            <Typography variant="body2">Novo produto</Typography>
          </Styled.Button>

          <RenderModule modules={["stock", "buyorder"]} allModules>
            <Box width={16} />
            <Styled.Button
              variant="contained"
              color="primary"
              to={PATHS.BUY_ORDER_LIST}
            >
              <Typography variant="body2">Ordens de compra</Typography>
            </Styled.Button>
          </RenderModule>
          {selectedRows.length ? (
            <>
              <Box width={16} />
              <Styled.Button
                onClick={handlePrint}
                variant="contained"
                color="primary"
              >
                <Typography variant="body2">Imprimir etiquetas</Typography>
              </Styled.Button>
            </>
          ) : null}
        </Box>
      </PageTitle>
      <OptionsPanel />

      <Table setSelectedRows={setSelectedRows} />
    </DashboardLayout>
  );
};

export default withContext(ProductsList, ProductProvider);
