import React, { useState, useEffect, useMemo } from "react";

import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import DeleteIcon from "@material-ui/icons/Delete";
import { useFormikContext } from "formik";

import Button from "src/components/Button";
import { ProductProvider, useProduct } from "src/hooks/product/useProduct";
import {
  BuyOrderFormType,
  ProductBuyOrderFormType,
} from "src/interfaces/buyOrder";
import { ProductType } from "src/interfaces/product";
import { withContext } from "src/utils/withContext";

import ValidityField from "../ValidityField";
import * as Styled from "./styles";

const LIMIT = 8;

interface ProductsBuyOrderManagerProps {
  isViewMode?: boolean;
  productsBuyOrder: ProductBuyOrderFormType[];
  setProductsBuyOrder: (value: ProductBuyOrderFormType[]) => void;
}

const ProductsBuyOrderManager: React.FC<ProductsBuyOrderManagerProps> = ({
  isViewMode,
  productsBuyOrder,
  setProductsBuyOrder,
}) => {
  const { list, products: rows, status: statusSearch } = useProduct();

  const { values, setFieldValue } = useFormikContext<BuyOrderFormType>();

  const [options, setOptions] = useState<ProductType[]>([]);
  const [product, setProduct] = useState<ProductType | null>(null);

  const [costField, setCostField] = useState<string | undefined>(undefined);
  const [quantityField, setQuantityField] = useState<string | undefined>(
    undefined
  );
  const [validityField, setValidityField] = useState<string | null | undefined>(
    null
  );

  const [updateProductCell, setUpdateProductCell] = useState<
    number | undefined
  >(undefined);

  const [showProductInlineForm, setShowProductInlineForm] = useState(false);

  useEffect(() => {
    setOptions(rows.products || []);
  }, [rows]);

  const searchQuery = (query: string) => {
    list({
      limit: LIMIT,
      ...(query && { name: query }),
    });
  };

  const initialRequest = () =>
    list({
      limit: LIMIT,
    });

  const handleDelete = (indexProduct: number) => {
    const dataToInsert = productsBuyOrder.filter(
      (_, index) => index !== indexProduct
    );
    setProductsBuyOrder(dataToInsert);
    setFieldValue("products", dataToInsert);
  };

  const resetForm = () => {
    setProduct(null);
    setCostField("");
    setQuantityField("");
    setValidityField(null);
    setShowProductInlineForm(false);
  };

  const addProduct = (
    productToAdd: ProductType | null,
    indexProductToUpdate?: number,
    quantity?: number,
    cost?: number,
    validity?: any
  ) => {
    const quantityValue = quantity ?? quantityField;
    const costValue = cost ?? costField;
    const validityValue = validity ?? validityField;

    if (productToAdd?.id) {
      let productsToInsert: ProductBuyOrderFormType[] = [];

      const productToInsert: ProductBuyOrderFormType = {
        id: "",
        productSelected: productToAdd,
        product: {
          id: productToAdd.id,
          stock: productToAdd.stock?.id,
        },
        quantity: Number(quantityValue) ?? 1,
        cost: Number(costValue) ?? 0,
        delete: false,
        validity: validityValue,
      };

      // Se for adicionar o produto ele adiciona o item no final da lista
      // Senão ele deve apenas modificar o item na posição original
      if (indexProductToUpdate === undefined) {
        productsToInsert = [...productsBuyOrder, productToInsert];
      } else {
        productsToInsert = productsBuyOrder.map((item, index) => {
          if (indexProductToUpdate === index) {
            return productToInsert;
          }

          return item;
        });
      }

      setProductsBuyOrder(productsToInsert);
      setFieldValue("products", productsToInsert);
    }

    resetForm();
  };

  const onChangeQuantityField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuantityField(e.target.value);
  };

  const onChangeCostField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCostField(e.target.value);
  };

  const onChangeValidityField = (date: any) => {
    setValidityField(date);
  };

  const addNewInlineProductForm = () => {
    if (product) {
      addProduct(product);
    }
    resetForm();
    setShowProductInlineForm(true);
  };

  const onClickAwayAddProductInlineForm = () => {
    if (product) {
      addProduct(product);
    } else {
      resetForm();
    }
    setShowProductInlineForm(false);
  };

  const cancelUpdateProductCell = () => {
    setUpdateProductCell(undefined);
    setFieldValue("products", productsBuyOrder);
  };

  const renderRenderProductsRegistered = useMemo(
    () => (
      <>
        {values?.products?.map((productBuyOrder, index) => {
          const isEnableToUpdateCell = index === updateProductCell;
          const isLastIndex = index === (values?.products?.length ?? 0) - 1;
          const key = `${productBuyOrder.product.id}${index}`;

          return (
            <Box key={key} display="flex" flexDirection="row" width="100%">
              <Styled.NumberBox
                radiusType={
                  isLastIndex && !showProductInlineForm
                    ? "bottomLeft"
                    : undefined
                }
              >
                <Styled.TypographyNumber>{index + 1}</Styled.TypographyNumber>
              </Styled.NumberBox>
              <Box display="flex" flexDirection="row" width="100%">
                <Styled.Grid
                  item
                  xs={!isViewMode ? 4 : 6}
                  lg={!isViewMode ? 4 : 6}
                >
                  <Styled.TextField
                    name={`products[${index}].productSelected.name`}
                    size="small"
                    disabled
                  />
                </Styled.Grid>
                <Styled.Grid item xs={1} lg={1}>
                  <Styled.NumberField
                    name={`products[${index}].quantity`}
                    size="small"
                    decimalScale={
                      values.products[index]?.productSelected?.unitOfMeasure ===
                      "un"
                        ? 0
                        : 2
                    }
                    disabled={!isEnableToUpdateCell}
                  />
                </Styled.Grid>

                <Styled.Grid item xs={2} lg={2}>
                  <Styled.CurrencyField
                    name={`products[${index}].cost`}
                    size="small"
                    disabled={!isEnableToUpdateCell}
                  />
                </Styled.Grid>

                <Styled.Grid
                  item
                  xs={2}
                  lg={2}
                  radiusType={
                    isViewMode && isLastIndex ? "bottomRight" : undefined
                  }
                >
                  <Styled.CurrencyField
                    name="productQtdTotal"
                    size="small"
                    value={
                      (productBuyOrder?.cost as number) *
                      (productBuyOrder?.quantity ?? 1)
                    }
                    disabled
                    disableFormik
                  />
                </Styled.Grid>

                <Styled.Grid item xs={3} lg={3}>
                  <ValidityField
                    size="small"
                    name={`products[${index}].validity`}
                    disabled={!isEnableToUpdateCell}
                  />
                </Styled.Grid>

                {!isViewMode ? (
                  <Styled.Grid
                    item
                    center
                    xs={2}
                    lg={2}
                    radiusType={
                      isLastIndex && !showProductInlineForm
                        ? "bottomRight"
                        : undefined
                    }
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      {isEnableToUpdateCell ? (
                        <>
                          <Button
                            size="small"
                            onClick={() => {
                              addProduct(
                                productBuyOrder.productSelected as ProductType,
                                index,
                                productBuyOrder?.quantity,
                                productBuyOrder?.cost,
                                productBuyOrder?.validity
                              );
                              setUpdateProductCell(undefined);
                            }}
                          >
                            <Typography color="primary" variant="caption">
                              Salvar
                            </Typography>
                          </Button>

                          <Button
                            size="small"
                            onClick={cancelUpdateProductCell}
                          >
                            <Tooltip
                              TransitionComponent={Zoom}
                              title="Cancelar edição"
                            >
                              <Styled.CancelIcon
                                color="primary"
                                fontSize="small"
                              />
                            </Tooltip>
                          </Button>
                        </>
                      ) : (
                        <Button
                          size="small"
                          onClick={() => setUpdateProductCell(index)}
                        >
                          <Typography color="primary" variant="caption">
                            Editar
                          </Typography>
                        </Button>
                      )}
                      <Button size="small" onClick={() => handleDelete(index)}>
                        <Tooltip
                          TransitionComponent={Zoom}
                          title="Deletar produto"
                        >
                          <DeleteIcon color="primary" fontSize="small" />
                        </Tooltip>
                      </Button>
                    </Box>
                  </Styled.Grid>
                ) : null}
              </Box>
            </Box>
          );
        })}
      </>
    ),
    [values?.products, updateProductCell, isViewMode, showProductInlineForm]
  );

  const renderAddProductInlineForm = useMemo(() => {
    if (
      (showProductInlineForm || productsBuyOrder.length === 0) &&
      !isViewMode
    ) {
      return (
        <ClickAwayListener onClickAway={onClickAwayAddProductInlineForm}>
          <Box display="flex" flexDirection="row" width="100%">
            <Styled.NumberBox radiusType="bottomLeft">
              <Styled.TypographyNumber>
                {productsBuyOrder.length + 1}
              </Styled.TypographyNumber>
            </Styled.NumberBox>
            <Box display="flex" flexDirection="row" width="100%">
              <Styled.Grid item xs={4} lg={4}>
                <Box width="100%">
                  <Styled.SearchProduct
                    value={product}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onChange={(_: any, productSelected: any, __: any) => {
                      setProduct(productSelected);

                      if (productSelected) {
                        setQuantityField("1");
                        setCostField(productSelected?.price);
                      } else {
                        setQuantityField("");
                        setCostField("");
                      }
                    }}
                    id="autocomplete-product-order"
                    options={options}
                    setOptions={setOptions}
                    loading={statusSearch.loading}
                    searchQuery={searchQuery}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={option => option.name}
                    initialRequest={initialRequest}
                    searchFieldProps={{
                      placeholder: "Pesquisar por produto",
                      size: "small",
                    }}
                  />
                </Box>
              </Styled.Grid>

              <Styled.Grid item xs={1} lg={1}>
                <Styled.NumberField
                  name="quantity"
                  size="small"
                  decimalScale={product?.unitOfMeasure === "un" ? 0 : 2}
                  value={quantityField}
                  onChange={onChangeQuantityField}
                  disableFormik
                />
              </Styled.Grid>

              <Styled.Grid item xs={2} lg={2}>
                <Styled.CurrencyField
                  name="costUnd"
                  size="small"
                  InputProps={{
                    value: costField,
                  }}
                  onChange={onChangeCostField}
                  disableFormik
                />
              </Styled.Grid>

              <Styled.Grid item xs={2} lg={2}>
                <Styled.CurrencyField
                  name="costTotal"
                  size="small"
                  InputProps={{
                    value: quantityField
                      ? Number(costField) * Number(quantityField)
                      : costField,
                  }}
                  disabled
                  disableFormik
                />
              </Styled.Grid>

              <Styled.Grid item xs={3} lg={3}>
                <ValidityField
                  size="small"
                  name="validity"
                  value={validityField}
                  onChange={onChangeValidityField}
                />
              </Styled.Grid>

              <Styled.Grid item center xs={2} lg={2} radiusType="bottomRight">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Button
                    size="small"
                    onClick={() => {
                      addProduct(product);
                    }}
                  >
                    <Typography color="primary" variant="caption">
                      Salvar
                    </Typography>
                  </Button>

                  <Button size="small" onClick={resetForm}>
                    <Tooltip TransitionComponent={Zoom} title="Deletar produto">
                      <DeleteIcon color="primary" fontSize="small" />
                    </Tooltip>
                  </Button>
                </Box>
              </Styled.Grid>
            </Box>
          </Box>
        </ClickAwayListener>
      );
    }

    return null;
  }, [
    showProductInlineForm,
    productsBuyOrder,
    product,
    options,
    costField,
    quantityField,
    validityField,
    isViewMode,
  ]);

  return (
    <>
      <Box mt={2} mb={2}>
        <Typography variant="body1" color="primary">
          Produtos
        </Typography>
      </Box>
      <Styled.Container>
        <Box display="flex" flexDirection="row" width="100%">
          <Styled.NumberBox radiusType="topLeft">
            <Typography color="primary"> Nº</Typography>
          </Styled.NumberBox>
          <Box display="flex" flexDirection="row" width="100%">
            <Styled.Grid item xs={!isViewMode ? 4 : 6} lg={!isViewMode ? 4 : 6}>
              <Typography color="primary">Produto</Typography>
            </Styled.Grid>

            <Styled.Grid item xs={1} lg={1}>
              <Typography color="primary">Qtde</Typography>
            </Styled.Grid>

            <Styled.Grid item xs={2} lg={2}>
              <Typography color="primary">Custo</Typography>
            </Styled.Grid>

            <Styled.Grid item xs={2} lg={2}>
              <Typography color="primary"> Custo total</Typography>
            </Styled.Grid>
            <Styled.Grid
              item
              xs={3}
              lg={3}
              radiusType={isViewMode ? "topRight" : undefined}
            >
              <Typography color="primary">Validade</Typography>
            </Styled.Grid>
            {!isViewMode ? (
              <Styled.Grid item xs={2} lg={2} radiusType="topRight">
                <Typography color="primary"> Ações</Typography>
              </Styled.Grid>
            ) : null}
          </Box>
        </Box>
        {renderRenderProductsRegistered}

        {renderAddProductInlineForm}
        {!isViewMode ? (
          <Box mt={1}>
            <Button
              color="primary"
              size="small"
              onClick={addNewInlineProductForm}
            >
              <Styled.AddCircleIcon color="primary" />
              Adicionar outro produto
            </Button>
          </Box>
        ) : null}
      </Styled.Container>
    </>
  );
};

export default withContext(ProductsBuyOrderManager, ProductProvider);
