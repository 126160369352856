import React, { FC } from "react";

import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";
import { differenceInCalendarDays, format } from "date-fns";

import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { ContactType } from "src/interfaces/contact";
import { GetReportLateClientsResponseType } from "src/interfaces/report";
import { formatPrice, maskDocument } from "src/utils/format";

export const renderDocument = (params: GridValueFormatterParams) => {
  if (params.value === null) {
    return null;
  }
  const contact = (params.row ?? {}) as ContactType;

  return renderDocumentContact(contact);
};

export const renderDocumentContact = (contact?: ContactType) => {
  if (!contact) {
    return "-";
  }

  if (contact?.cpf) {
    return maskDocument(contact?.cpf);
  }

  if (contact?.cnpj) {
    return maskDocument(contact?.cnpj);
  }

  return "-";
};

const renderContactName = (params: GridValueFormatterParams) =>
  params.row?.name ?? "-";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Cliente",
    renderCell: renderContactName,
    flex: 1.5,
    sortComparator: (v1, v2) => {
      const name = (v1 as string)?.toLowerCase() ?? "-";
      const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

      if (name < nameComparable) {
        return -1;
      }
      if (name > nameComparable) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "contact",
    headerName: "CPF/CNPJ",
    renderCell: renderDocument,
    flex: 1.5,
    valueGetter: params => params.row,
    sortComparator: (contact, contactComparable) => {
      const document = renderDocumentContact(
        contact as ContactType
      )?.toLowerCase();
      const documentComparable = renderDocumentContact(
        contactComparable as ContactType
      )?.toLowerCase();

      if (document < documentComparable) {
        return -1;
      }
      if (document > documentComparable) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "firstDate",
    headerName: "Atrasado desde",
    renderCell: params =>
      params.row?.records?.firstDate
        ? format(new Date(params.row?.records?.firstDate), "dd/MM/yyyy")
        : "-",
    flex: 1.5,
    valueGetter: params => params.row?.records?.firstDate ?? "-",
    sortComparator: (v1, v2) => {
      const name = (v1 as string)?.toLowerCase() ?? "-";
      const nameComparable = (v2 as string)?.toLowerCase() ?? "-";

      if (name < nameComparable) {
        return -1;
      }
      if (name > nameComparable) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: params =>
      params.row?.status === "enabled" ? "Ativo" : "Inativo",
    flex: 1,
  },
  {
    field: "count",
    headerName: "Contas atrasadas",
    renderCell: params => params.row?.records?.count,
    flex: 1.5,
    valueGetter: params => params.row?.records?.count ?? 0,
  },
  {
    field: "days",
    headerName: "Dias em atraso",
    renderCell: params =>
      params.row?.records?.firstDate
        ? differenceInCalendarDays(
            new Date(),
            new Date(params.row?.records?.firstDate)
          )
        : "-",
    flex: 1.5,
    valueGetter: params =>
      params.row?.records?.firstDate
        ? differenceInCalendarDays(
            new Date(),
            new Date(params.row?.records?.firstDate)
          )
        : 0,
  },
  {
    field: "totalAmount",
    headerName: "Valor",
    renderCell: params => formatPrice(params.row?.records.totalAmount),
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueGetter: params => params.row?.records.totalAmount ?? 0,
  },
];

export interface ClientsTableProps {
  reportLateClients: GetReportLateClientsResponseType;
}

const ClientsTable: FC<ClientsTableProps> = ({ reportLateClients }) => {
  const { result: clients } = reportLateClients;
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState<number>(clients.length);

  return (
    <Table
      sortingOrder={["desc", "asc"]}
      rows={clients}
      columns={columns}
      page={page}
      setPage={setPage}
      pageSize={limit}
      onPageSizeChange={setLimit}
      filterMode="server"
      totalCount={clients.length}
      hideFooter
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <Typography>Nenhuma relatório disponível</Typography>
          </EmptyTable>
        ),
      }}
    />
  );
};

export default ClientsTable;
