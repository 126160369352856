import api from "src/services/api";

/**
 * Upload de imagem
 */
export const uploadImage = (formData: FormData) =>
  api
    .post("v1/upload/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(res => res.data);
