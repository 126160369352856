import { produce } from "immer";

import { STATUS } from "src/constants/requestStatus";
import { Reducer } from "src/hooks/props";
import { UserType } from "src/interfaces/user";

import {
  CreateAccountState,
  CreateAccountActionMap,
  CreateAccountActions,
} from "./props";

const initialState: CreateAccountState = {
  section: "welcome",
  token: undefined,
  user: {} as UserType,
  status: {
    signUp: {
      ready: false,
    },
    completeSignUp: {
      ready: false,
    },
  },
  signUpRequestStatus: STATUS.inital,
  SMSConfirmRequestStatus: STATUS.inital,
  SMSResendRequestStatus: STATUS.inital,
  completeSignUpRequestStatus: STATUS.inital,

  userToAdd: {
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    passwordConfirmation: "",
  },
  completeSignUp: {
    companyType: "PJ",
    companyCEP: "",
    companyCNPJ: "",
    companyCPF: "",
    companyCity: "",
    companyEmail: "",
    companyName: "",
    companyNeighborhood: "",
    companyPhone: "",
    companyUF: "",
    companyOccupationArea: "",
    workAlone: null,
  },
};

const createAccountReducer: Reducer<
  CreateAccountState,
  CreateAccountActionMap
> = (state, action) => {
  switch (action.type) {
    case CreateAccountActions.SetSectionSignup:
      state.section = action.payload;
      return state;

    case CreateAccountActions.RequestSignUp:
      state.signUpRequestStatus = STATUS.loading;
      return state;

    case CreateAccountActions.RequestSignUpSuccess:
      state.signUpRequestStatus = STATUS.success;
      state.token = action.payload.token;
      state.user = action.payload.user;
      return state;

    case CreateAccountActions.RequestSignUpError:
      state.signUpRequestStatus = STATUS.error;
      state.user = initialState.user;
      state.token = undefined;
      return state;

    case CreateAccountActions.RequestSMSConfirm:
      state.SMSConfirmRequestStatus = STATUS.loading;
      return state;

    case CreateAccountActions.RequestSMSConfirmSuccess:
      state.SMSConfirmRequestStatus = STATUS.success;
      return state;

    case CreateAccountActions.RequestSMSConfirmError:
      state.SMSConfirmRequestStatus = STATUS.error;
      return state;

    case CreateAccountActions.RequestSMSResend:
      state.SMSResendRequestStatus = STATUS.loading;
      return state;

    case CreateAccountActions.RequestSMSResendSuccess:
      state.SMSResendRequestStatus = STATUS.success;
      return state;

    case CreateAccountActions.RequestSMSResendError:
      state.SMSResendRequestStatus = STATUS.error;
      return state;

    case CreateAccountActions.RequestCompleteSignUp:
      state.completeSignUpRequestStatus = STATUS.loading;
      return state;

    case CreateAccountActions.RequestCompleteSignUpSuccess:
      state.completeSignUpRequestStatus = STATUS.success;
      state.token = action.payload.token;
      state.user = action.payload.user;
      return state;

    case CreateAccountActions.RequestCompleteSignUpError:
      state.completeSignUpRequestStatus = STATUS.error;
      return state;

    case CreateAccountActions.SetAccountData:
      state.userToAdd = action.payload;
      return state;

    case CreateAccountActions.SetCompleteSignUpData:
      state.completeSignUp = {
        ...initialState.completeSignUp,
        ...action.payload.data,
      };
      state.status.completeSignUp.ready = Boolean(action.payload.ready);
      return state;

    case CreateAccountActions.setPasswordData:
      state.userToAdd = action.payload;
      state.status.signUp.ready = true;
      return state;

    case CreateAccountActions.Reset:
      return initialState;

    default:
      return state;
  }
};

export { initialState };
export default produce(createAccountReducer);
