import styled from "styled-components";

import MuiTooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

export const TooltipIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 24px;
  position: absolute;
  right: 2rem;
`;

export const Tooltip = styled(MuiTooltip)`
  & > .custom__tooltip {
    background-color: red;
  }
`;
