import { useCallback, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useForm, FormProvider } from "react-hook-form";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { Input } from "src/components/NewComponents/Input";
import { Textarea } from "src/components/NewComponents/TextArea";

import { ConfirmDeleteImage } from "../ConfirmDeleteImage";
import { technicalReportImageFormSchema } from "./schema";

export type TechnicalReportImageFormSchema = yup.InferType<
  typeof technicalReportImageFormSchema
>;

interface TechnicalReportImageFormProps {
  technicalReportImage: TechnicalReportImageFormSchema | undefined;
  clearTechnicalReportImage: () => void;
  setItem: (item: TechnicalReportImageFormSchema) => void;
  removeItem: (index: number) => void;
}

function TechnicalReportImageForm({
  technicalReportImage,
  clearTechnicalReportImage,
  setItem,
  removeItem,
}: TechnicalReportImageFormProps) {
  const { handleClose } = useBaseSwipeableDrawer();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const methods = useForm<TechnicalReportImageFormSchema>({
    resolver: yupResolver(technicalReportImageFormSchema),
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (technicalReportImage) {
      reset(technicalReportImage);
    } else {
      reset({
        name: "",
        description: "",
        image: null,
        index: undefined,
      });
    }
  }, [technicalReportImage]);

  const image: File = watch("image");

  const onDrop = useCallback(
    droppedFiles => {
      setValue("image", droppedFiles[0], { shouldValidate: true });
    },
    [setValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
    multiple: false,
    maxSize: 3 * 1024 * 1024,
    maxFiles: 1,
  });

  const handleCustomClose = () => {
    clearTechnicalReportImage();
    handleClose("technicalReportImage");
  };

  const onSubmit = (data: TechnicalReportImageFormSchema) => {
    setItem(data);
    handleCustomClose();
    reset({});
  };

  const baseActions = useMemo(() => {
    return (
      <>
        <Box width="100%" mb={2}>
          {technicalReportImage ? (
            <Button
              w="100%"
              variant="outline"
              colorScheme="error"
              mb={3}
              onClick={onOpen}
            >
              Excluir
            </Button>
          ) : null}
          <Button w="100%" type="submit" form="technicalReportImageForm">
            Salvar
          </Button>
        </Box>
      </>
    );
  }, []);

  return (
    <BaseSwipeableDrawer
      tag="technicalReportImage"
      title={technicalReportImage ? "Atualizar imagem" : "Adicionar imagem"}
      customActions={baseActions}
      customHandleClose={handleCustomClose}
    >
      <FormProvider {...methods}>
        <Flex
          w={["100%", "350px"]}
          bg="white"
          borderRadius={10}
          border="solid 1px gray-200"
          as="form"
          id="technicalReportImageForm"
          onSubmit={handleSubmit(onSubmit)}
          direction="column"
          gap={4}
        >
          <FormControl {...getRootProps()} isInvalid={!!errors.image}>
            <input {...getInputProps()} />
            {image ? (
              <Flex
                cursor="pointer"
                h="350px"
                w="100%"
                borderRadius={10}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Image
                  w="100%"
                  h="100%"
                  objectFit="cover"
                  borderRadius={10}
                  src={URL.createObjectURL(image)}
                />
              </Flex>
            ) : (
              <>
                <Flex
                  bg="secondary.100"
                  _hover={{ bg: "secondary.50" }}
                  _active={{ bg: "secondary.20" }}
                  cursor="pointer"
                  border="dashed 1px"
                  borderColor={errors.image ? "error.500" : "primary.500"}
                  h="350px"
                  w="100%"
                  borderRadius={10}
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Icon
                    as={AddPhotoAlternateIcon}
                    fontSize="56px"
                    color="primary.500"
                  />
                  <Text
                    fontSize="xs"
                    fontWeight={400}
                    color="primary.500"
                    textAlign="center"
                    lineHeight="15px"
                  >
                    {isDragActive
                      ? `Arraste a foto ou clique <br /> para adicionar uma foto`
                      : "Clique para adicionar uma foto"}
                  </Text>
                </Flex>
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                >
                  <FormErrorMessage mt={0}>
                    {errors.image?.message}
                  </FormErrorMessage>
                  <Text fontSize="xs" color="text" textAlign="right" mt={1}>
                    Máximo 3MB - PNG ou JPG
                  </Text>
                </HStack>
              </>
            )}
          </FormControl>

          <Input
            error={errors.name}
            label="Nome da imagem"
            {...register("name")}
          />
          <Textarea
            error={errors.description}
            label="Descrição da imagem"
            {...register("description")}
          />
        </Flex>
      </FormProvider>
      <ConfirmDeleteImage
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => {
          removeItem(technicalReportImage?.index as number);
          handleCustomClose();
          reset({});
          onClose();
        }}
      />
    </BaseSwipeableDrawer>
  );
}

export default TechnicalReportImageForm;
