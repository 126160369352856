import styled from "styled-components";

import { KeyboardDatePicker as KeyboardDatePickerUnstyled } from "@material-ui/pickers";

export const KeyboardDatePicker = styled(KeyboardDatePickerUnstyled)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 100%;
  .MuiIconButton-root {
    padding: 0px;
  }
`;
