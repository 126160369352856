import * as yup from "yup";

import { PASSWORD_SIZE_MESSAGE } from "src/constants/password";

export const schemaChangePasswordForm = yup.object({
  oldPassword: yup
    .string()
    .required("Campo obrigatório")
    .min(6, PASSWORD_SIZE_MESSAGE),
  newPassword: yup
    .string()
    .required("Campo obrigatório")
    .min(6, PASSWORD_SIZE_MESSAGE),
  confirmPassword: yup
    .string()
    .required("Campo obrigatório")
    .min(6, PASSWORD_SIZE_MESSAGE)
    .oneOf([yup.ref("newPassword")], "Senhas diferentes"),
});
