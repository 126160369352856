import { toast } from "react-toastify";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { UserToAddType } from "src/interfaces/user";
import api from "src/services/api";

export const updateUser = (params: Partial<UserToAddType>) =>
  api
    .put(`v1/user/restricted/${params.id}/update`, {
      ...params,
      userId: params.id,
    })
    .then(res => res.data);

export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation((user: Partial<UserToAddType>) => updateUser(user), {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast.success("Usuário atualizado com sucesso.");
    },
    onError: ({ response }) => {
      toast.error(
        response?.data?.message ??
          "Ocorreu um problema ao atualizar esse usuário"
      );
    },
  });
}
