import React, { useState, createContext, useContext } from "react";

import { BaseSwipeableDrawerContextType } from "./props";

export const BaseSwipeableDrawerContext = createContext(
  {} as BaseSwipeableDrawerContextType
);

export const BaseSwipeableDrawerProvider: React.FC = props => {
  const { children } = props;
  const [open, setOpen] = useState<Array<string>>([]);

  const handleOpen = (tag: string) => {
    setOpen(prev => [...prev, tag]);
  };

  const handleClose = (removeTagsList: string | Array<string>) => {
    let openTags = open;
    if (Array.isArray(removeTagsList)) {
      openTags = openTags.filter(openTag => !removeTagsList.includes(openTag));
    } else {
      openTags = openTags.filter(openTag => removeTagsList !== openTag);
    }

    setOpen(openTags);
  };

  return (
    <BaseSwipeableDrawerContext.Provider
      value={{
        open,
        handleOpen,
        handleClose,
      }}
    >
      {children}
    </BaseSwipeableDrawerContext.Provider>
  );
};

export const useBaseSwipeableDrawer = () => {
  const context = useContext(BaseSwipeableDrawerContext);

  if (!context) {
    throw new Error(
      "useBaseSwipeableDrawer must be used within a ModalProvider"
    );
  }

  return context;
};
