import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { useQueryClient } from "@tanstack/react-query";

import { STATUS } from "src/constants/requestStatus";
import { deleteScheduling } from "src/services/scheduling";

import { SchedulingDeleteContextType } from "./props";

export const SchedulingDeleteContext = createContext(
  {} as SchedulingDeleteContextType
);

export const SchedulingDeleteProvider: React.FC = props => {
  const { children } = props;
  const queryClient = useQueryClient();
  const [status, setStatus] = useState(STATUS.inital);

  const remove = async (id: string) => {
    try {
      setStatus(STATUS.loading);

      await deleteScheduling(id);

      setStatus(STATUS.success);

      toast.success("Agendamento deletado");

      queryClient.invalidateQueries({
        queryKey: ["schedulingAllTypes"],
      });
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao deletar esse agendamento");
    }
  };

  return (
    <SchedulingDeleteContext.Provider
      value={{
        status,
        remove,
      }}
    >
      {children}
    </SchedulingDeleteContext.Provider>
  );
};

export const useSchedulingDelete = () => {
  const context = useContext(SchedulingDeleteContext);

  if (!context) {
    throw new Error(
      "useSchedulingDelete must be used within a SchedulingDeleterovider"
    );
  }

  return context;
};
