import React, { useMemo } from "react";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { isValid } from "date-fns";
import { useField } from "formik";

import { formatDateToISO } from "src/utils/date";

import { KeyboardTimePickerFieldProps } from "./props";
import * as Styled from "./styles";

const KeyboardTimePickerField: React.FC<KeyboardTimePickerFieldProps> =
  props => {
    const { name, ...rest } = props;

    const [, meta, helpers] = useField<string>(name);

    const date = useMemo(() => {
      if (meta.value) {
        return formatDateToISO(meta.value);
      }
      return null;
    }, [meta.value]);

    const handleChange = (date: Date | null) => {
      if (isValid(date) && date) {
        const value = formatDateToISO(date);
        helpers.setValue(value.toString());
      }
    };

    const errorProps = useMemo(() => {
      if (meta.initialError) {
        return {
          error: true,
          helperText: meta.initialError,
        };
      }
      if (meta.error && meta.touched) {
        return {
          error: true,
          helperText: meta.error,
        };
      }
      return {};
    }, [meta.error, meta.initialError, meta.touched]);

    return (
      <Styled.KeyboardTimePicker
        value={date}
        ampm={false}
        onChange={handleChange}
        inputVariant="outlined"
        cancelLabel="cancelar"
        keyboardIcon={<AccessTimeIcon />}
        {...errorProps}
        {...rest}
      />
    );
  };

export default KeyboardTimePickerField;
