import React, { useState, useEffect, useMemo } from "react";

import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import DeleteIcon from "@material-ui/icons/Delete";
import { useFormikContext } from "formik";

import Button from "src/components/Button";
import { useOrder } from "src/hooks/order/useOrder";
import { ProductProvider, useProduct } from "src/hooks/product/useProduct";
import { OrderType, ProductOrderType } from "src/interfaces/order";
import { ProductType } from "src/interfaces/product";
import { withContext } from "src/utils/withContext";

import * as Styled from "./styles";

const LIMIT = 8;

interface ProductManagerProps {
  isViewMode?: boolean;
}

const ProductManager: React.FC<ProductManagerProps> = ({ isViewMode }) => {
  const { products, setProducts, order } = useOrder();
  const { list, products: rows, status: statusSearch } = useProduct();

  const { values, setFieldValue } = useFormikContext<Partial<OrderType>>();

  const [options, setOptions] = useState<ProductType[]>([]);
  const [product, setProduct] = useState<ProductType | null>(null);

  const [priceField, setPriceField] = useState<string | undefined>(undefined);
  const [quantityField, setQuantityField] = useState<string | undefined>(
    undefined
  );

  const [updateProductCell, setUpdateProductCell] = useState<
    number | undefined
  >(undefined);

  const [showProductInlineForm, setShowProductInlineForm] = useState(false);

  useEffect(() => {
    setOptions(rows.products || []);
  }, [rows]);

  const searchQuery = (query: string) => {
    list({
      limit: LIMIT,
      ...(query && { name: query }),
    });
  };

  const initialRequest = () =>
    list({
      limit: LIMIT,
    });

  const handleDelete = (indexProduct: number) => {
    const dataToInsert = products.filter((_, index) => index !== indexProduct);
    setProducts(dataToInsert);
    setFieldValue("products", dataToInsert);
  };

  const resetForm = () => {
    setProduct(null);
    setPriceField("");
    setQuantityField("");
    setShowProductInlineForm(false);
  };

  const addProduct = (
    productToAdd: ProductType | null,
    indexProductToUpdate?: number,
    quantity?: number,
    price?: number
  ) => {
    const quantityValue = quantity ?? quantityField;
    const priceValue = price ?? priceField;

    if (productToAdd?.id) {
      let productsToInsert: ProductOrderType[] = [];

      const productToInsert: ProductOrderType = {
        ...(order?.id && {
          order: order.id,
        }),
        product: productToAdd,
        id: productToAdd.id,
        productCost: productToAdd.cost,
        productPrice: Number(priceValue) ?? 0,
        productName: productToAdd.name,
        quantity: Number(quantityValue) ?? 1,
      };

      // Se for adicionar o produto ele adiciona o item no final da lista
      // Senão ele deve apenas modificar o item na posição original
      if (indexProductToUpdate === undefined) {
        productsToInsert = [...products, productToInsert];
      } else {
        productsToInsert = products.map((item, index) => {
          if (indexProductToUpdate === index) {
            return productToInsert;
          }

          return item;
        });
      }

      setProducts(productsToInsert);
      setFieldValue("products", productsToInsert);
    }

    resetForm();
  };

  const onChangeQuantityField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuantityField(e.target.value);
  };

  const onChangePriceField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPriceField(e.target.value);
  };

  const addNewInlineProductForm = () => {
    if (product) {
      addProduct(product);
    }
    resetForm();
    setShowProductInlineForm(true);
  };

  const onClickAwayAddProductInlineForm = () => {
    if (product) {
      addProduct(product);
    } else {
      resetForm();
    }
    setShowProductInlineForm(false);
  };

  const cancelUpdateProductCell = () => {
    setUpdateProductCell(undefined);
    setFieldValue("products", products);
  };

  const renderRenderProductsRegistered = useMemo(
    () => (
      <>
        {values?.products?.map((product, index) => {
          const isEnableToUpdateCell = index === updateProductCell;
          const isLastIndex = index === (values?.products?.length ?? 0) - 1;
          return (
            <>
              <Styled.Grid
                item
                xs={1}
                lg={1}
                radiusType={
                  isLastIndex && !showProductInlineForm
                    ? "bottomLeft"
                    : undefined
                }
              >
                <Styled.TypographyNumber>{index + 1}</Styled.TypographyNumber>
              </Styled.Grid>

              <Styled.Grid
                item
                xs={!isViewMode ? 4 : 6}
                lg={!isViewMode ? 4 : 6}
              >
                <Styled.TextField
                  name={`products[${index}].productName`}
                  size="small"
                  disabled
                />
              </Styled.Grid>

              <Styled.Grid item xs={1} lg={1}>
                <Styled.TextField
                  name={`products[${index}].quantity`}
                  size="small"
                  disabled={!isEnableToUpdateCell}
                />
              </Styled.Grid>

              <Styled.Grid item xs={2} lg={2}>
                <Styled.CurrencyField
                  name={`products[${index}].productPrice`}
                  size="small"
                  disabled={!isEnableToUpdateCell}
                />
              </Styled.Grid>

              <Styled.Grid
                item
                xs={2}
                lg={2}
                radiusType={
                  isViewMode && isLastIndex ? "bottomRight" : undefined
                }
              >
                <Styled.CurrencyField
                  name="productQtdTotal"
                  size="small"
                  value={
                    (product?.productPrice as number) * (product?.quantity ?? 1)
                  }
                  disabled
                  disableFormik
                />
              </Styled.Grid>
              {!isViewMode ? (
                <Styled.Grid
                  item
                  center
                  xs={2}
                  lg={2}
                  radiusType={
                    isLastIndex && !showProductInlineForm
                      ? "bottomRight"
                      : undefined
                  }
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-evenly"
                  >
                    {isEnableToUpdateCell ? (
                      <>
                        <Button
                          size="small"
                          onClick={() => {
                            addProduct(
                              product.product as ProductType,
                              index,
                              product?.quantity,
                              product?.productPrice
                            );
                            setUpdateProductCell(undefined);
                          }}
                        >
                          <Typography color="primary" variant="caption">
                            Salvar
                          </Typography>
                        </Button>

                        <Button size="small" onClick={cancelUpdateProductCell}>
                          <Tooltip
                            TransitionComponent={Zoom}
                            title="Cancelar edição"
                          >
                            <Styled.CancelIcon
                              color="primary"
                              fontSize="small"
                            />
                          </Tooltip>
                        </Button>
                      </>
                    ) : (
                      <Button
                        size="small"
                        onClick={() => setUpdateProductCell(index)}
                      >
                        <Typography color="primary" variant="caption">
                          Editar
                        </Typography>
                      </Button>
                    )}
                    <Button size="small" onClick={() => handleDelete(index)}>
                      <Tooltip
                        TransitionComponent={Zoom}
                        title="Deletar produto"
                      >
                        <DeleteIcon color="primary" fontSize="small" />
                      </Tooltip>
                    </Button>
                  </Box>
                </Styled.Grid>
              ) : null}
            </>
          );
        })}
      </>
    ),
    [values?.products, updateProductCell, isViewMode, showProductInlineForm]
  );

  const renderAddProductInlineForm = useMemo(() => {
    if ((showProductInlineForm || products.length === 0) && !isViewMode) {
      return (
        <ClickAwayListener onClickAway={onClickAwayAddProductInlineForm}>
          <Box display="flex" flexDirection="row" width="100%">
            <Styled.Grid item xs={1} lg={1} radiusType="bottomLeft">
              <Styled.TypographyNumber>
                {products.length + 1}
              </Styled.TypographyNumber>
            </Styled.Grid>
            <Styled.Grid item xs={4} lg={4}>
              <Box width="100%">
                <Styled.SearchProduct
                  value={product}
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  onChange={(_, productSelected, __) => {
                    setProduct(productSelected);

                    if (productSelected) {
                      setQuantityField("1");
                      setPriceField(productSelected?.price);
                    } else {
                      setQuantityField("");
                      setPriceField("");
                    }
                  }}
                  id="autocomplete-product-order"
                  options={options}
                  setOptions={setOptions}
                  loading={statusSearch.loading}
                  searchQuery={searchQuery}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={option => option.name}
                  filterOptions={options => options}
                  initialRequest={initialRequest}
                  searchFieldProps={{
                    placeholder: "Pesquisar por produto",
                    size: "small",
                  }}
                />
              </Box>
            </Styled.Grid>

            <Styled.Grid item xs={1} lg={1}>
              <Styled.TextField
                name="quantity"
                size="small"
                value={quantityField}
                onChange={onChangeQuantityField}
                disableFormik
              />
            </Styled.Grid>

            <Styled.Grid item xs={2} lg={2}>
              <Styled.CurrencyField
                name="priceUnd"
                size="small"
                InputProps={{
                  value: priceField,
                }}
                onChange={onChangePriceField}
                disableFormik
              />
            </Styled.Grid>

            <Styled.Grid item xs={2} lg={2}>
              <Styled.CurrencyField
                name="priceTotal"
                size="small"
                InputProps={{
                  value: quantityField
                    ? Number(priceField) * Number(quantityField)
                    : priceField,
                }}
                disabled
                disableFormik
              />
            </Styled.Grid>

            <Styled.Grid item center xs={2} lg={2} radiusType="bottomRight">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <Button
                  size="small"
                  onClick={() => {
                    addProduct(product);
                  }}
                >
                  <Typography color="primary" variant="caption">
                    Salvar
                  </Typography>
                </Button>

                <Button size="small" onClick={resetForm}>
                  <Tooltip TransitionComponent={Zoom} title="Deletar produto">
                    <DeleteIcon color="primary" fontSize="small" />
                  </Tooltip>
                </Button>
              </Box>
            </Styled.Grid>
          </Box>
        </ClickAwayListener>
      );
    }

    return null;
  }, [
    showProductInlineForm,
    products,
    product,
    options,
    priceField,
    quantityField,
    isViewMode,
  ]);

  return (
    <>
      <Box mt={2} mb={2}>
        <Typography variant="body1" color="textSecondary">
          Produtos
        </Typography>
      </Box>
      <Styled.Container>
        <Grid container>
          <Styled.Grid item xs={1} lg={1} radiusType="topLeft">
            <Typography color="textSecondary"> Nº</Typography>
          </Styled.Grid>

          <Styled.Grid item xs={!isViewMode ? 4 : 6} lg={!isViewMode ? 4 : 6}>
            <Typography color="textSecondary">Produto</Typography>
          </Styled.Grid>

          <Styled.Grid item xs={1} lg={1}>
            <Typography color="textSecondary">Qtde</Typography>
          </Styled.Grid>

          <Styled.Grid item xs={2} lg={2}>
            <Typography color="textSecondary">Preço Unitário</Typography>
          </Styled.Grid>

          <Styled.Grid
            item
            xs={2}
            lg={2}
            radiusType={isViewMode ? "topRight" : undefined}
          >
            <Typography color="textSecondary"> Preço total</Typography>
          </Styled.Grid>
          {!isViewMode ? (
            <Styled.Grid item xs={2} lg={2} radiusType="topRight">
              <Typography color="textSecondary"> Ações</Typography>
            </Styled.Grid>
          ) : null}
          {renderRenderProductsRegistered}

          {renderAddProductInlineForm}
        </Grid>
        {!isViewMode ? (
          <Box mt={1}>
            <Button
              color="primary"
              size="small"
              onClick={addNewInlineProductForm}
            >
              <Styled.AddCircleIcon color="primary" />
              Adicionar outro produto
            </Button>
          </Box>
        ) : null}
      </Styled.Container>
    </>
  );
};

export default withContext(ProductManager, ProductProvider);
