import styled from "styled-components";

import MuiTab from "@material-ui/core/Tab";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTabScrollButton from "@material-ui/core/TabScrollButton";

import Typography from "src/components/Typography";

export const Tabs = styled(MuiTabs)`
  flex: 1;
  border-bottom: 2px solid #d6d8dc;
  .MuiTabs-indicator {
    display: none;
  }
`;

export const Tab = styled(MuiTab)`
  text-transform: initial;
  min-width: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    min-width: unset;
  }
`;

export const IndicatorStatus = styled.div<{ color?: string }>`
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: ${({ color }) => color};
  visibility: ${({ color }) => (color ? "visible" : "hidden")};
`;

export const TabLabelText = styled(Typography)<{
  statusColor?: string;
  active?: boolean;
}>`
  color: ${({ active, statusColor }) =>
    active ? statusColor || "#73757a" : "#73757a"};
  font-size: 14px;
  font-weight: ${({ theme, active }) =>
    active
      ? theme.typography.fontWeightBold
      : theme.typography.fontWeightRegular};
`;

export const TabScrollButton = styled(MuiTabScrollButton)`
  width: 28px;
  overflow: hidden;
  transition: width 0.5s;
  &.Mui-disabled {
    width: 0px;
  }
`;
