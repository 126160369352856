import React from "react";
import NumberFormat from "react-number-format";

import { NumberFormatCustomProps } from "./props";

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, decimalScale = 2, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalSeparator=","
      decimalScale={decimalScale}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default NumberFormatCustom;
