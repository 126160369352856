import styled from "styled-components";

import LinearProgress from "@material-ui/core/LinearProgress";

import Typography from "src/components/Typography";
import { PasswordStatusColorType } from "src/interfaces/password";

export const Container = styled.div`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.grey[400]};
  padding: 5px 20px;
`;

export const LabelText = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[700]};
  margin-right: 8px;
`;

export const PasswordMeterText = styled(Typography)<{
  colorText: PasswordStatusColorType;
}>`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: 12px;
  color: ${({ colorText }) => colorText};
`;

export const PasswordMeterStatus = styled(LinearProgress)<{
  colorBar: PasswordStatusColorType;
}>`
  height: 10px;
  border-radius: 10px;
  margin-top: 5px;

  &.MuiLinearProgress-colorPrimary {
    background-color: #D6D8DC;
  },
  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ colorBar }) => colorBar};
  },
`;
