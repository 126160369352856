/* eslint-disable react/destructuring-assignment */
import { useState, useMemo, FC } from "react";
import { useNavigate } from "react-router-dom";

import {
  Flex,
  Text,
  Divider,
  Spinner,
  Wrap,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { ButtonProps } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { format, formatDistanceStrict } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Button from "src/components/Button";
import PATHS from "src/constants/paths";
import { useSchedulingDone } from "src/hooks/scheduling/done/useSchedulingDone";
import { BuyOrderType } from "src/interfaces/buyOrder";
import { Event } from "src/interfaces/events";
import { OrderListType } from "src/interfaces/order";
import { SchedulingType } from "src/interfaces/scheduling";
import SchedulingDeleteModal from "src/pages/Schedule/components/Modals/SchedulingDeleteModal";
import { formatDateToISO } from "src/utils/date";
import { formatPrice } from "src/utils/format";
import { getRouteString } from "src/utils/urlHandler";

import SchedulingUpdateModal from "../SchedulingUpdateModal";
import * as Styled from "./styles";

interface IDrawerDetail {
  eventDetail: Event;
}
const today = new Date();

const ButtonOption: FC<ButtonProps> = props => {
  const { children, ...rest } = props;

  return (
    <Button fullWidth {...rest}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
      >
        {children}
      </Box>
    </Button>
  );
};

export default function DrawerDetailCompromisse(eventDetail: IDrawerDetail) {
  const event = eventDetail.eventDetail;

  const scheduling = event.model as SchedulingType;
  const order = event.model as OrderListType;
  const buyOrder = event.model as BuyOrderType;

  const { eventHours } = eventDetail.eventDetail;
  const data = format(new Date(event.start), "dd/MM");
  const created = new Date(event.model.createdAt as string);
  const dayCreated = formatDistanceStrict(created, today, { locale: ptBR });

  const createdHours = format(
    new Date(event.model.createdAt as string),
    "H:mm"
  );

  const { status: statusDone, done } = useSchedulingDone();
  const { handleClose, handleOpen } = useBaseSwipeableDrawer();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const navigate = useNavigate();

  const handleCustomClose = () => {
    handleClose("DetailCompromisse");
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenDeleteModal = () => {
    handleClose("DetailCompromisse");
    setOpenDeleteModal(true);
  };

  const handleDoneScheduling = () => {
    done({
      id: scheduling.id,
      isDoneAt: scheduling.isDoneAt ? null : formatDateToISO(today),
    });
    handleClose("DetailCompromisse");
  };

  const contentDoneButton = useMemo(() => {
    if (statusDone.loading) {
      return <Spinner color="green" />;
    }

    if (scheduling.isDoneAt) {
      return (
        <>
          <Styled.CheckedIcon fontSize="small" />
          <Styled.DoneButtonLabel>Finalizado</Styled.DoneButtonLabel>
        </>
      );
    }

    return (
      <>
        <Styled.UnCheckedIcon fontSize="small" />
        <Styled.DoneButtonLabel>Finalizar</Styled.DoneButtonLabel>
      </>
    );
  }, [event, statusDone]);

  const title = () => {
    if (eventDetail.eventDetail.type === "scheduling") {
      return (
        <Text fontSize="xl" color="text">
          Reunião
        </Text>
      );
    }
    if (eventDetail.eventDetail.type === "buyOrder") {
      return (
        <Text fontSize="xl" color="text">
          Ordens de compra
        </Text>
      );
    }
    if (new Date(order.deadline) > new Date()) {
      return (
        <Text color="text" fontSize="xl" fontWeight="semibold">
          Entrega não finalizada
        </Text>
      );
    }

    return (
      <Text color="text" fontSize="xl" fontWeight="semibold">
        Entrega atrasada
      </Text>
    );
  };
  const customHeader = (
    <Flex alignItems="center" gap={2}>
      {title()}
    </Flex>
  );

  const buttonActions = () => {
    if (eventDetail.eventDetail.type === "orders") {
      return (
        <ChakraButton
          w="100%"
          onClick={() =>
            navigate(
              getRouteString(PATHS.PRODUCT_ORDER_DETAIL, order.id, "orderId")
            )
          }
        >
          Ir para Detalhes
        </ChakraButton>
      );
    }
    if (eventDetail.eventDetail.type === "buyOrder") {
      return (
        <ChakraButton
          w="100%"
          onClick={() =>
            navigate(
              getRouteString(PATHS.BUY_ORDER_DETAIL, buyOrder.id, "buyOrderId")
            )
          }
        >
          Ir para Detalhes
        </ChakraButton>
      );
    }
    return (
      <Flex w="100%">
        <Grid item xs={4}>
          <ButtonOption onClick={() => handleDoneScheduling()}>
            {contentDoneButton}
          </ButtonOption>
        </Grid>

        <Grid item xs={4}>
          <ButtonOption onClick={() => handleOpen("DrawerUpdateScheduling")}>
            <CreateIcon color="primary" fontSize="small" />
            <Styled.ButtonLabel>Editar</Styled.ButtonLabel>
          </ButtonOption>
        </Grid>

        <Grid item xs={4}>
          <ButtonOption onClick={() => handleOpenDeleteModal()}>
            <DeleteIcon color="error" fontSize="small" />
            <Styled.ButtonLabel>Excluir</Styled.ButtonLabel>
          </ButtonOption>
        </Grid>
      </Flex>
    );
  };

  const customActions = <Flex w="100%">{buttonActions()}</Flex>;

  const compromisseOrder = () => {
    if (eventDetail.eventDetail.type === "orders") {
      return (
        <Flex direction="column" gap={2}>
          <Text color="gray">
            adicionado há {dayCreated}, às {createdHours}
          </Text>
          <Flex gap={4}>
            <Text color="text">
              Data:&nbsp;
              <Text as="span" color="gray">
                {data}
              </Text>
            </Text>

            <Text color="text">
              {order.type === "productOrder" ? "Produto N:" : "Serviço N:"}{" "}
              <Text as="span" color="gray">
                {order.code}
              </Text>
            </Text>
          </Flex>
          <Divider />
          <Flex justifyItems="center" direction="column" gap={2}>
            <Wrap spacing="8">
              <Text color="text">
                Cliente:&nbsp;
                <Text color="gray" fontSize="sm">
                  {order.contact?.name ?? "-"}
                </Text>
              </Text>

              <Text color="text">
                Valor:&nbsp;
                <Text fontSize="sm" color="gray">
                  {formatPrice(order.totalAmount)}
                </Text>
              </Text>
            </Wrap>

            <Text fontSize="sm" color="text">
              SubCliente:&nbsp;
              <Text fontSize="sm" color="gray">
                {order.subContact?.contact ?? "-"}
              </Text>
            </Text>
            <Text fontSize="sm" color="text">
              Data de entrega:&nbsp;
              <Text fontSize="sm" color="gray">
                {format(new Date(order.deadline), "dd/MM/yyyy")}
              </Text>
            </Text>
          </Flex>
        </Flex>
      );
    }
    if (eventDetail.eventDetail.type === "buyOrder") {
      return (
        <Flex direction="column" gap={4}>
          <Text color="gray">
            adicionado há {dayCreated}, às {createdHours}
          </Text>
          <Flex gap={4}>
            <Text color="text">
              Data:&nbsp;
              <Text as="span" color="gray">
                {data}
              </Text>
            </Text>
            <Text color="text">
              Pedido:&nbsp;
              <Text as="span" color="gray">
                {buyOrder.number}
              </Text>
            </Text>
          </Flex>
          <Divider />
          <Flex justifyItems="center" direction="column" gap={4}>
            <Flex gap={4}>
              <Text color="text">
                Fornecedor:&nbsp;{" "}
                <Text color="gray" fontSize="sm">
                  {buyOrder.provider?.name ?? "-"}
                </Text>
              </Text>

              <Text color="text">
                Valor:&nbsp;
                <Text fontSize="sm" color="gray">
                  {formatPrice(buyOrder.totalAmount)}
                </Text>
              </Text>
            </Flex>
            <Flex justifyItems="center" alignItems="center">
              <Text fontSize="sm" color="text">
                Data de entrega:&nbsp;
              </Text>
              <Text fontSize="sm" color="gray">
                {format(new Date(buyOrder.deadline as string), "dd/MM/yyyy")}
              </Text>
            </Flex>

            <Flex justifyItems="center">
              <Text fontSize="sm" color="text">
                Condição de pagamento:&nbsp;
              </Text>
              <Text fontSize="sm" color="gray">
                {buyOrder.paymentType}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      );
    }
    return (
      <Flex direction="column" gap={2}>
        <Text color="gray">
          adicionado há {dayCreated}, às {createdHours}
        </Text>
        <Flex gap={4}>
          <Text color="text">
            Data:&nbsp;
            <Text as="span" color="gray">
              {data}
            </Text>
          </Text>

          <Text color="text">
            Horário:&nbsp;
            <Text as="span" color="gray">
              {eventHours}
            </Text>
          </Text>
        </Flex>
        <Divider />
        <Flex direction="column" gap={2}>
          <Text color="text">
            Titulo :{" "}
            <Text color="gray" fontSize="sm">
              {event.title}
            </Text>
          </Text>

          <Text color="text">
            Descrição :
            <Text fontSize="sm" color="gray">
              {scheduling.description}
            </Text>
          </Text>
          <Text color="text">
            Local :{" "}
            <Text fontSize="sm" color="gray">
              {event.place}
            </Text>
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <BaseSwipeableDrawer
        tag="DetailCompromisse"
        customHeader={customHeader}
        buttonTitle="Salvar"
        customActions={customActions}
        zIndex={1302}
        customHandleClose={handleCustomClose}
      >
        <Flex
          w={["100%", "350px"]}
          bg="white"
          pt={4}
          borderRadius={10}
          border="solid 1px gray-200"
          gap={4}
          direction="column"
        >
          {compromisseOrder()}
        </Flex>
      </BaseSwipeableDrawer>
      <SchedulingDeleteModal
        id={scheduling.id as string}
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
      />
      <SchedulingUpdateModal data={scheduling} />
    </>
  );
}
