import React, { useEffect } from "react";
import { useForm, FormProvider, UseFormReturn } from "react-hook-form";

import {
  Box,
  Divider,
  Flex,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { Input } from "src/components/NewComponents/Input";
import RenderModule from "src/components/RenderModule";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { UserToAddType } from "src/interfaces/user";

import { PermissionsSection } from "./PermissionsSection";
import { schemaUserForm } from "./schema";

interface UserFormProps {
  method: "add" | "update";

  data?: UserToAddType;

  createUser?: (value: Partial<UserToAddType>) => void;
  updateUser?: (value: Partial<UserToAddType>) => void;
}

function UserForm(props: UserFormProps) {
  const { data, method, createUser, updateUser } = props;
  const { userCompany } = useCompany();
  const { hasModules } = useModule();

  const defaultValues: Partial<UserToAddType> = {
    id: data?.id,
    name: data?.name || "",
    username: data?.username || "",
    email: data?.email || "",
    password: "",
    permissions: data?.permissions || {},
    operator: { comission: (data?.operator?.comission ?? 0) * 100 },
  };

  const methods: UseFormReturn<UserToAddType, any> = useForm<UserToAddType>({
    defaultValues,
    resolver: yupResolver(schemaUserForm),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [data]);

  const onSubmit = (user: Partial<UserToAddType>) => {
    const data = {
      ...user,
      email: user.email?.toLowerCase(),
      username: user.username?.toLowerCase(),
      operator: hasModules("orderComission")
        ? {
            comission: Number(user.operator?.comission ?? 0) / 100,
          }
        : undefined,
    };

    if (method === "add" && createUser) {
      createUser(data);
    } else {
      const params = data;
      if (!params.password) {
        delete params.password;
      }

      if (updateUser) {
        updateUser(params);
      }
    }
  };

  const validatePercentNumber = (value: string) => {
    const range = 2;
    const beforeRange = 3;

    const oldValue = value.substring(0, value.length - 1);
    const truncated = value.replace(/[^0-9.]+/gi, "");

    const dotQuantity = truncated
      .split("")
      .filter(letter => letter === ".").length;

    if (dotQuantity > 1 || Number(truncated) > 100) {
      return Number(oldValue) > 100 ? "" : oldValue;
    }

    const containsDot = truncated.includes(".");

    if (!containsDot && truncated.length > beforeRange) return oldValue;

    const isMoreOfBeforeRange =
      containsDot &&
      truncated.substring(0, truncated.indexOf(".")).length > beforeRange;

    if (isMoreOfBeforeRange) {
      return oldValue;
    }

    const containsDotAndIsMoreOfRange =
      truncated.includes(".") &&
      truncated.substring(truncated.indexOf(".") + 1).length > range;

    if (containsDotAndIsMoreOfRange) {
      return oldValue;
    }

    return truncated;
  };

  return (
    <FormProvider {...methods}>
      <Flex
        id="userForm"
        direction="column"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        gap={6}
        mt={12}
        px={5}
      >
        <Box
          px={6}
          py={4}
          bg="white"
          borderRadius={10}
          border="solid 1px gray-200"
        >
          <Text fontSize="md" color="primary.500" mb={4}>
            Dados pessoais
          </Text>
          <SimpleGrid columns={[1, 1, 2]} gap={6}>
            <Input label="Nome" error={errors.name} {...register("name")} />
            <Input label="E-mail" error={errors.email} {...register("email")} />
          </SimpleGrid>

          <Divider color="gray-300" mt={6} mb={4} />

          <Text fontSize="md" color="primary.500" mb={4}>
            Dados da conta
          </Text>
          <SimpleGrid columns={[1, 1, 2]} gap={6}>
            <InputGroup>
              <Input
                label="Usuário"
                error={errors.username}
                {...register("username")}
                onChange={event => {
                  event.target.value = event.target.value
                    .toLowerCase()
                    .replace(/[^0-9a-z]+/gi, "");
                }}
              />
              <InputRightElement
                bg="#D9D9D9"
                right="1px"
                top="1px"
                height="calc(var(--chakra-sizes-12) - 2px)"
                w="auto"
                px={4}
                borderTopRightRadius="10px"
                borderBottomRightRadius="10px"
              >
                <Text color="text" fontWeight={500} fontSize="md">
                  @{userCompany?.slug}
                </Text>
              </InputRightElement>
            </InputGroup>
            <Input
              label="Senha"
              error={errors.password}
              type="password"
              {...register("password")}
            />
          </SimpleGrid>

          <RenderModule modules={["orderComission"]}>
            <Divider color="gray-300" mt={6} mb={4} />

            <Text fontSize="md" color="primary.500" mb={4}>
              Comissão de venda
            </Text>
            <RadioGroup colorScheme="primary" mb={4}>
              <Radio isChecked mr={4}>
                Fixa
              </Radio>
              <Radio
                isDisabled
                sx={{
                  "&[data-disabled]": {
                    bg: "gray.100",
                    borderColor: "gray.200",
                  },
                }}
              >
                Variável [Em breve]
              </Radio>
            </RadioGroup>
            <SimpleGrid columns={[1, 1, 2]} gap={6}>
              <InputGroup>
                <Input
                  label="Porcentagem de comissão"
                  error={errors.operator?.comission}
                  autoComplete="off"
                  {...register("operator.comission", {
                    onChange: e => {
                      e.target.value = validatePercentNumber(e.target.value);
                    },
                    onBlur: e => {
                      e.target.value = validatePercentNumber(e.target.value);
                    },
                  })}
                />
                <InputRightElement
                  bg="#D9D9D9"
                  right="1px"
                  top="1px"
                  height="calc(var(--chakra-sizes-12) - 2px)"
                  w="auto"
                  px={4}
                  borderTopRightRadius="10px"
                  borderBottomRightRadius="10px"
                >
                  <Text color="text" fontWeight={500} fontSize="md">
                    %
                  </Text>
                </InputRightElement>
              </InputGroup>
            </SimpleGrid>
          </RenderModule>
        </Box>
        <Text fontSize="md">Permissões de acesso</Text>
        <Box
          px={6}
          py={4}
          bg="white"
          borderRadius={10}
          border="solid 1px gray-200"
        >
          <PermissionsSection />
        </Box>
      </Flex>
    </FormProvider>
  );
}

export default UserForm;
