import { ReactNode } from "react";

export interface CreatedAndUpdateType {
  createdAt: string;
  updatedAt: string;
}

export type Option = {
  label: string | ReactNode;
  value: string;
  [key: string]: any;
};

export type GroupOption = {
  label: string;
  options: Option[];
};

export function isGroupOptions(
  data: GroupOption[] | Option[]
): data is GroupOption[] {
  return !!data.length && "options" in data[0];
}
