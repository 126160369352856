import React, { useEffect, useMemo, useState } from "react";

import Box from "@material-ui/core/Box";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Button from "src/components/Button";
import Loader from "src/components/Loader";
import Typography from "src/components/Typography";
import {
  SchedulingCreateProvider,
  useSchedulingCreate,
} from "src/hooks/scheduling/create/useSchedulingCreate";
import {
  SchedulingDeleteProvider,
  useSchedulingDelete,
} from "src/hooks/scheduling/delete/useSchedulingDelete";
import { SchedulingDetailProvider } from "src/hooks/scheduling/detail/useSchedulingDetail";
import {
  SchedulingDoneProvider,
  useSchedulingDone,
} from "src/hooks/scheduling/done/useSchedulingDone";
import {
  SchedulingListProvider,
  useSchedulingList,
} from "src/hooks/scheduling/list/useSchedulingList";
import {
  SchedulingUpdateProvider,
  useSchedulingUpdate,
} from "src/hooks/scheduling/update/useSchedulingUpdate";
import { SchedulingType } from "src/interfaces/scheduling";
import { withContext } from "src/utils/withContext";

import DrawerAddCompromisse from "../Modals/DrawerAddCompromisse";
import SchedulingDetailModal from "../Modals/SchedulingDetail";
import SchedulingFormModal from "../Modals/SchedulingFormModal";
import SchedulingCard from "../SchedulingCard";
import * as Styled from "./styles";

const actualMonth = new Date().getMonth() + 1;

const SchedulingsPanel = () => {
  const { schedulings, list, status: statusList } = useSchedulingList();
  const { status: statusDone } = useSchedulingDone();
  const { status: statusCreate } = useSchedulingCreate();
  const { schedulingUpdated, status: statusUpdate } = useSchedulingUpdate();
  const { status: statusDelete } = useSchedulingDelete();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { handleOpen } = useBaseSwipeableDrawer();
  const [detailScheduling, setDetailSecheduling] = useState(
    {} as Partial<SchedulingType>
  );

  useEffect(() => {
    list({
      monthNumber: actualMonth,
    });
  }, [
    statusCreate.success,
    statusUpdate.success,
    statusDelete.success,
    statusDone.success,
  ]);

  useEffect(() => {
    setDetailSecheduling(schedulingUpdated);
  }, [statusUpdate.success]);

  const handleOpenDetailModal = (data: Partial<SchedulingType>) => {
    handleOpen("DetailCompromisseHome");
    setDetailSecheduling(data);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const renderSchedulings = useMemo(() => {
    if (statusList.loading) {
      return (
        <Box width="100%" height={130} display="flex" alignItems="center">
          <Loader size="medium" />
        </Box>
      );
    }

    if (!schedulings?.schedules?.length) {
      return <Typography>Nenhum compromisso pra hoje</Typography>;
    }

    if (statusList.success) {
      return (
        <>
          {schedulings?.schedules?.map(scheduling => (
            <SchedulingCard
              data={scheduling}
              key={scheduling.id}
              openDetailModal={handleOpenDetailModal}
            />
          ))}
        </>
      );
    }

    return null;
  }, [schedulings, statusList]);

  return (
    <Box marginTop={2}>
      <Box
        display="flex"
        justifyContent="space-betweeen"
        alignItems="center"
        width="100%"
        mb={2}
      >
        <Typography color="primary" variant="subtitle2">
          Próximos compromissos
        </Typography>
        <div>
          <Button size="small" onClick={() => handleOpen("AddCompromisseHome")}>
            <AddCircleOutlineIcon color="primary" />
          </Button>
        </div>
      </Box>
      <Styled.ContainerOverflow>{renderSchedulings}</Styled.ContainerOverflow>

      <DrawerAddCompromisse />

      <SchedulingDetailModal data={detailScheduling} />

      <SchedulingFormModal
        method="add"
        open={openCreateModal}
        handleClose={handleCloseCreateModal}
      />
    </Box>
  );
};

export default withContext(
  SchedulingsPanel,
  SchedulingListProvider,
  SchedulingDetailProvider,
  SchedulingUpdateProvider,
  SchedulingCreateProvider,
  SchedulingDeleteProvider,
  SchedulingDoneProvider
);
