import styled from "styled-components";

import ButtonComponent from "src/components/Button";
import Typography from "src/components/Typography";

export const DeleteButton = styled(ButtonComponent)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-color: #e54848;
  color: #e54848;
  height: 50px;
`;

export const LabelData = styled(Typography)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 14px;
  text-align: left;
`;

export const ValueData = styled(Typography)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
  text-align: left;
`;

export const Button = styled(ButtonComponent)`
  height: 50px;
  svg {
    font-size: 25px;
    margin-right: 8px;
  }
`;
