import styled from "styled-components";

import MuiGrid from "@material-ui/core/Grid";
import MuiPaper from "@material-ui/core/Paper";
import CloseIconUnstyled from "@material-ui/icons/Close";

import ModalUnstyled from "src/components/Modal";
import Typography from "src/components/Typography";

export const Modal = styled(ModalUnstyled)`
  .MuiDialog-paper {
    height: 600px;
    max-height: 900px;
  }
`;

export const Container = styled(MuiPaper)`
  width: 100%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: none;
  height: 440px;
  max-height: 600px;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: 10px 15px;
`;

export const HeaderLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  height: 25px;
`;

export const CloseIcon = styled(CloseIconUnstyled)`
  color: ${({ theme }) => theme.palette.common.white};
`;

export const TopicTitle = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  font-size: 12px;
`;

export const TopicDescription = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 13px;
`;

export const TechnicalReportTitleSection = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 15px;
`;

export const Date = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: 10px;
  text-align: right;
`;

export const PreviewPhoto = styled.img`
  width: 100%;
  height: 90px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  object-fit: cover;
`;

export const Column = styled(MuiGrid)`
  min-width: 470px;
`;
