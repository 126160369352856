import React from "react";

import CreateAccountProvider from "src/hooks/user/createAccount";
import { withContext } from "src/utils/withContext";

import { CompanyProvider } from "./company/useCompany";
import { FAQProvider } from "./FAQ/useFAQ";
import { ModuleProvider } from "./module/useModule";
import { PermissionProvider } from "./permission/usePermission";
import { AuthProvider } from "./user/auth/useAuth";

const Providers: React.FC = props => {
  const { children } = props;
  return <>{children}</>;
};

export default withContext(
  Providers,
  AuthProvider,
  CreateAccountProvider,
  CompanyProvider,
  PermissionProvider,
  ModuleProvider,
  FAQProvider
);
