import React, { useMemo } from "react";

import { Select } from "src/components/NewComponents/Select";
import { useCompany } from "src/hooks/company/useCompany";
import { UserType } from "src/interfaces/user";
import { useUsers } from "src/services/hooks/user/useUsers";

interface ComissionSectionProps {
  operator: UserType | undefined;
  setOperator: (value: UserType) => void;
}

const ComissionSection: React.FC<ComissionSectionProps> = ({
  operator,
  setOperator,
}) => {
  const { operatorNameDefinitions } = useCompany();

  const { data: rows, isLoading } = useUsers(
    {
      limit: 10000,
      page: 1,
      status: "enabled",
    },
    { staleTime: 0 }
  );

  const users = useMemo(() => {
    return rows?.users ?? [];
  }, [rows]);

  return (
    <Select
      value={operator?.id}
      options={users.map(user => ({
        label: user.name,
        value: user.id,
      }))}
      isLoading={isLoading}
      onChange={newInputValue => {
        const user = users.find(user => user.id === newInputValue);
        setOperator(user as UserType);
      }}
      placeholder={`Adicionar ${operatorNameDefinitions.title.toLowerCase()}...`}
      showFloatingLabel={false}
    />
  );
};

export default ComissionSection;
