import { useMemo } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Switch,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { CurrencyInput } from "src/components/NewComponents/CurrencyInput";
import { DatePicker } from "src/components/NewComponents/DatePicker";
import { Select } from "src/components/NewComponents/Select";
import RenderModule from "src/components/RenderModule";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { Option } from "src/interfaces/common";
import { RecordType } from "src/interfaces/record";
import { useCashFlowOpened } from "src/services/hooks/cashFlow/useCashFlowOpened";
import { useActiveFinancialAccounts } from "src/services/hooks/financialAccount/useActiveFinancialAccounts";
import { useCreateRecord } from "src/services/hooks/record/useCreateRecord";
import { formatStartDateISO } from "src/utils/date";
import { groupFinancialAccounts } from "src/utils/financialAccount";
import { formatPaidDateToISO } from "src/utils/record";

import { schemaCreateOrderRecordForm } from "./schema";

export type CreateOrderRecordFormSchema = yup.InferType<
  typeof schemaCreateOrderRecordForm
>;

interface CreateOrderRecordModalType {
  orderId?: string;
  buyOrderId?: string;
  recordType?: "expense" | "income";
  onSuccess?: (value: RecordType) => void;
}

const today = new Date();

function CreateOrderRecordModal({
  recordType = "expense",
  onSuccess,
  orderId,
  buyOrderId,
}: CreateOrderRecordModalType) {
  const { hasModules } = useModule();
  const { userCompany } = useCompany();
  const { handleClose } = useBaseSwipeableDrawer();

  const { data: cashFlowOpened } = useCashFlowOpened({
    enabled: hasModules("cashflowcontrol"),
    retry: 1,
    retryDelay: 500,
    onSuccess(cashFlowOpened) {
      reset({
        ...defaultValues,
        financialAccount: cashFlowOpened?.financialAccount as unknown as string,
      });
    },
  });

  const { mutate: createRecord, isLoading } = useCreateRecord({
    onSuccess: record => {
      toast.success("Lançamento criado com sucesso!");
      handleCustomClose();
      if (onSuccess) onSuccess(record);
    },
  });

  const { data: accounts } = useActiveFinancialAccounts(undefined, {
    enabled: hasModules("accountManagement"),
  });

  const defaultValues: CreateOrderRecordFormSchema = {
    value: "" as unknown as number,
    financialAccount:
      (cashFlowOpened?.financialAccount as unknown as string) ??
      accounts.find(account => account.isDefault)?.id,
    hasAccountManagement: hasModules("accountManagement"),
    paidStatus: "pending",
    paidDate: formatStartDateISO(today),
    paymentMethod: undefined,
    emissionDate: formatStartDateISO(today),
  };

  const methods = useForm<CreateOrderRecordFormSchema>({
    defaultValues,
    resolver: yupResolver(schemaCreateOrderRecordForm),
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const paymentMethod = useWatch({ name: "paymentMethod", control });
  const financialAccount = useWatch({ name: "financialAccount", control });
  const paidStatus = useWatch({ name: "paidStatus", control });

  const handleCustomClose = () => {
    reset(defaultValues);
    handleClose("orderRecordForm");
  };

  const onSubmit = ({
    value,
    paidStatus,
    paidDate,
    emissionDate,
    financialAccount,
    paymentMethod,
  }: CreateOrderRecordFormSchema) => {
    createRecord({
      category: undefined as unknown as string,
      value: Number(value),
      paidStatus,
      paidDate: formatPaidDateToISO(paidDate),
      emissionDate,
      financialAccount,
      paymentMethod,
      type: recordType,
      orderId,
      buyOrderId,
    });
  };

  const title = useMemo(() => {
    return "Criar pagamento";
  }, [recordType]);

  const buttonTitle = useMemo(() => {
    return "Criar";
  }, []);

  const baseActions = useMemo(() => {
    return (
      <Button
        w="100%"
        type="submit"
        isLoading={isLoading}
        form="orderRecordForm"
      >
        {buttonTitle}
      </Button>
    );
  }, [buttonTitle, isLoading]);

  const paymentMethodsItems = useMemo(() => {
    return (
      userCompany?.paymentMethods
        ?.filter(item => item.status === "enabled")
        .map(method => ({
          label: method.title,
          value: method.id,
        })) ?? []
    );
  }, [userCompany]);

  const financialAccountsGrouped = useMemo(() => {
    return groupFinancialAccounts(
      accounts.filter(account => {
        if (cashFlowOpened) {
          return (
            account.type !== "cash" ||
            account.id ===
              (cashFlowOpened?.financialAccount as unknown as string)
          );
        }
        return true;
      })
    );
  }, [accounts, cashFlowOpened]);

  return (
    <BaseSwipeableDrawer
      tag="orderRecordForm"
      title={title}
      customActions={baseActions}
      customHandleClose={handleCustomClose}
    >
      <Flex
        w={["100%", "350px"]}
        bg="white"
        pt={4}
        borderRadius={10}
        border="solid 1px gray-200"
        as="form"
        id="orderRecordForm"
        onSubmit={handleSubmit(onSubmit)}
        gap={4}
        direction="column"
      >
        <CurrencyInput
          label="Valor"
          error={errors.value}
          control={control}
          name="value"
        />

        <Text>Pagamento</Text>

        <Controller
          control={control}
          name="paidStatus"
          render={({ field: { ref, value, onChange, name } }) => (
            <FormControl display="flex" alignItems="center">
              <FormLabel
                htmlFor="paidStatus"
                color={value === "paid" ? "primary.500" : "error.500"}
                flex={1}
                m={0}
              >
                {value === "paid" ? "Pago" : "Não pago"}
              </FormLabel>
              <Switch
                ref={ref}
                id="paidStatus"
                name={name}
                isChecked={value === "paid"}
                checked={value === "paid"}
                colorScheme={value === "paid" ? "primary" : "error"}
                onChange={e => {
                  if (e.target.checked) {
                    onChange("paid");
                  } else {
                    onChange("pending");
                    setValue("paymentMethod", undefined);
                  }
                }}
              />
            </FormControl>
          )}
        />

        <DatePicker
          label={paidStatus === "paid" ? "Data do pagamento" : "Prazo"}
          error={errors.paidDate}
          control={control}
          name="paidDate"
        />

        {paidStatus === "paid" && recordType === "income" ? (
          <Controller
            control={control}
            name="paymentMethod"
            render={({ field: { ref, onChange } }) => (
              <Select
                options={paymentMethodsItems}
                inputRef={ref}
                error={!!errors.paymentMethod}
                errorMessage={errors.paymentMethod?.message}
                placeholder="Forma de pagamento"
                isClearable={false}
                onChange={paymentMethod => {
                  if (hasModules("cashflowcontrol")) {
                    const paymentMethodSlugSelected =
                      userCompany.paymentMethods?.find(
                        method => method.id === paymentMethod
                      )?.slug;

                    const financialAccountTypeSelected = accounts.find(
                      account => account.id === financialAccount
                    )?.type;

                    if (
                      (financialAccountTypeSelected === "cash" &&
                        paymentMethodSlugSelected !== "money") ||
                      (financialAccountTypeSelected !== "cash" &&
                        paymentMethodSlugSelected === "money")
                    ) {
                      setValue("financialAccount", undefined);
                    }
                  }
                  onChange(paymentMethod);
                }}
                value={paymentMethod}
              />
            )}
          />
        ) : null}

        <RenderModule modules={["accountManagement"]}>
          <Controller
            control={control}
            name="financialAccount"
            render={({ field: { ref, onChange } }) => (
              <Select
                options={financialAccountsGrouped}
                inputRef={ref}
                error={!!errors.financialAccount}
                errorMessage={errors.financialAccount?.message}
                placeholder="Conta"
                isClearable={false}
                formatOptionLabel={(data: unknown) => {
                  const account = data as Option;
                  return (
                    <Flex alignItems="center" gap={2}>
                      <Image
                        borderRadius="full"
                        boxSize="24px"
                        src={account?.url}
                        alt={account.label as string}
                      />
                      {account.label}
                    </Flex>
                  );
                }}
                onChange={financialAccount => {
                  onChange(financialAccount);
                  if (hasModules("cashflowcontrol")) {
                    const paymentMethodSlugSelected =
                      userCompany.paymentMethods?.find(
                        method => method.id === paymentMethod
                      )?.slug;

                    const financialAccountTypeSelected = accounts.find(
                      account => account.id === financialAccount
                    )?.type;

                    if (
                      (paymentMethodSlugSelected === "money" &&
                        financialAccountTypeSelected !== "cash") ||
                      (paymentMethodSlugSelected !== "money" &&
                        financialAccountTypeSelected === "cash")
                    ) {
                      setValue("paymentMethod", undefined);
                    }
                  }
                }}
                value={financialAccount}
              />
            )}
          />
        </RenderModule>
      </Flex>
    </BaseSwipeableDrawer>
  );
}

export default CreateOrderRecordModal;
