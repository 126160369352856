import React, { FC } from "react";

import { Box } from "@material-ui/core";

import Panel from "src/components/Layouts/Panel";

import { DashboardCardProps } from "./props";
import * as Styled from "./styles";

const DashboardCard: FC<DashboardCardProps> = props => {
  const { loading, title, value, percentage = null, type } = props;

  return (
    <Panel
      minHeight={80}
      padding={1}
      paddingLeft="12px"
      paddingRight="12px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      gridGap={2}
      border="solid 1px #D6D8DC"
    >
      <Box flex={1}>
        <Styled.Label>{title}</Styled.Label>
        {loading ? (
          <Styled.Loading width={50} height={23} />
        ) : (
          <Styled.LabelValue redCurrency={type === "expense"}>
            {value}
            {typeof percentage === "number" ? (
              <Styled.LabelPercentage redCurrency={percentage < 0}>
                {percentage > 0 ? "+" : null}
                {percentage} %
              </Styled.LabelPercentage>
            ) : null}
          </Styled.LabelValue>
        )}
      </Box>
      {type === "income" && (percentage === null || percentage >= 0) ? (
        <Styled.ArrowUpward />
      ) : (
        <Styled.ArrowDownward />
      )}
    </Panel>
  );
};

export default DashboardCard;
