import React, { useState, createContext, useContext } from "react";

import { BaseModalContextType } from "./props";

export const BaseModalContext = createContext({} as BaseModalContextType);

export const BaseModalProvider: React.FC = props => {
  const { children } = props;
  const [open, setOpen] = useState<Array<string>>([]);

  const handleOpen = (tag: string) => {
    setOpen(prev => [...prev, tag]);
  };

  const handleClose = (removeTagsList: string | Array<string>) => {
    let openTags = open;
    if (Array.isArray(removeTagsList)) {
      openTags = openTags.filter(openTag => !removeTagsList.includes(openTag));
    } else {
      openTags = openTags.filter(openTag => removeTagsList !== openTag);
    }

    setOpen(openTags);
  };

  return (
    <BaseModalContext.Provider
      value={{
        open,
        handleOpen,
        handleClose,
      }}
    >
      {children}
    </BaseModalContext.Provider>
  );
};

export const useBaseModal = () => {
  const context = useContext(BaseModalContext);

  if (!context) {
    throw new Error("useBaseModal must be used within a ModalProvider");
  }

  return context;
};
