import { AxiosRequestConfig } from "axios";

import { DocumentConfigurationType } from "src/interfaces/documentConfiguration";
import api from "src/services/api";

const config: AxiosRequestConfig = {
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/pdf",
  },
};

/**
 * Gerar orçamento
 */
export const GenerateOrderBudget = (
  orderId: string,
  params: DocumentConfigurationType
) =>
  api
    .get(`v1/documents/order/${orderId}/budget`, { ...config, params })
    .then(res => res.data);

/**
 * Gerar ordem de serviço / pedido de venda
 */
export const GenerateOrderDocument = (
  orderId: string,
  params: DocumentConfigurationType
) =>
  api
    .get(`v1/documents/order/${orderId}`, { ...config, params })
    .then(res => res.data);

/**
 * Gerar recibo de pagamento
 */
export const GeneratePaymentReceipt = (recordId: string) =>
  api
    .get(`v1/documents/record/${recordId}/payment-receipt`, config)
    .then(res => res.data);
