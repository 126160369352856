import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { BuyOrderType } from "src/interfaces/buyOrder";
import { OrderListType } from "src/interfaces/order";
import { RecordType } from "src/interfaces/record";
import { SchedulingType } from "src/interfaces/scheduling";
import api from "src/services/api";

export interface GetScheduleAllTypesResponseType {
  orders: OrderListType[];
  buyOrder: BuyOrderType[];
  schedules: SchedulingType[];
  record: RecordType[];
}

interface SchedulesAllTypesParams {
  startDate: string;
  endDate: string;
  isDone?: boolean;
}

export const getSchedulesAllTypes = (params: SchedulesAllTypesParams) =>
  api
    .get<GetScheduleAllTypesResponseType>("v1/scheduling/all-types", {
      params,
    })
    .then(res => res.data);

export function useSchedulesAllTypes(
  params: SchedulesAllTypesParams,
  config?: UseQueryOptions<
    GetScheduleAllTypesResponseType,
    Error,
    GetScheduleAllTypesResponseType,
    unknown[]
  >
) {
  return useQuery<
    GetScheduleAllTypesResponseType,
    Error,
    GetScheduleAllTypesResponseType,
    unknown[]
  >(["schedulingAllTypes", params], () => getSchedulesAllTypes(params), {
    refetchOnWindowFocus: false,
    ...(config || {}),
    initialData: {
      orders: [],
      buyOrder: [],
      schedules: [],
      record: [],
    },
  });
}
