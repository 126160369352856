import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Modal from "src/components/Modal";
import { useModal } from "src/components/Modal/useModal";
import { useService } from "src/hooks/service/useService";
import { delay } from "src/utils/debounce";

import { DeleteModalProps } from "./props";
import * as Styled from "./styles";

const DeleteModal: React.FC<DeleteModalProps> = props => {
  const { id, afterActions } = props;

  const { remove } = useService();
  const { handleClose } = useModal();

  const deleteService = async () => {
    remove(id);
    handleClose();
    await delay(1000);
    afterActions();
  };

  return (
    <Modal>
      <Box p={3}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <div />
              <Styled.AlertIcon alignmentBaseline="middle" />
              <Styled.CloseButton onClick={handleClose} />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Styled.InfoText align="center" variant="h6">
              Tem certeza que deseja deletar esse serviço?
            </Styled.InfoText>
          </Grid>
          <Grid item xs={12}>
            <Styled.DeleteButton
              variant="contained"
              onClick={deleteService}
              fullWidth
              size="large"
            >
              Excluir
            </Styled.DeleteButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
