import { useState, useEffect } from "react";
import { HeaderProps } from "react-big-calendar";

import { Button, Center, Flex, Text } from "@chakra-ui/react";
import { format } from "date-fns";

import {
  BaseSwipeableDrawerProvider,
  useBaseSwipeableDrawer,
} from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { withContext } from "src/utils/withContext";

function WeekHeader({ date, label }: HeaderProps) {
  const data = label.split(" ");
  const { handleOpen } = useBaseSwipeableDrawer();
  const [dateToday, setDateToday] = useState<boolean>();
  const today = format(new Date(), "dd M yyyy");
  const dateFormat = format(date, "dd M yyyy");

  useEffect(() => {
    if (dateFormat === today) {
      setDateToday(true);
    } else {
      setDateToday(false);
    }
  }, [date]);

  return (
    <Flex h="36" p="2" direction="column">
      <Button variant="unstyled" onClick={() => handleOpen("DetailDay")}>
        <Center display="flex" flexDirection="column">
          <Flex
            bg={dateToday ? "primary.500" : "gray.200"}
            w="10"
            h="10"
            borderRadius="full"
            justifyContent="center"
            alignItems="center"
            color="white"
          >
            {data[0]}
          </Flex>
          <Text casing="capitalize" color="gray">
            {data[1]}
          </Text>
        </Center>
      </Button>
    </Flex>
  );
}

export default withContext(WeekHeader, BaseSwipeableDrawerProvider);
