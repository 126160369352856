import React, { useState, createContext, useContext, useEffect } from "react";

import { STATUS } from "src/constants/requestStatus";
import { GetFAQResponseType } from "src/interfaces/FAQ";
import { ListParamsType } from "src/interfaces/request";
import { getFAQ } from "src/services/FAQ";

import { useAuth } from "../user/auth/useAuth";
import { FAQContextType } from "./props";

export const FAQContext = createContext({} as FAQContextType);

export const FAQProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState(STATUS.inital);
  const [FAQ, setFAQ] = useState<GetFAQResponseType>({} as GetFAQResponseType);
  const [called, setCalled] = useState(false);

  const { isAuthenticated } = useAuth();

  const get = async (params: Partial<ListParamsType>) => {
    try {
      setStatus(STATUS.loading);

      const response: GetFAQResponseType = await getFAQ(params);

      setFAQ(response);

      setStatus(STATUS.success);
    } catch (e) {
      setFAQ({} as GetFAQResponseType);
      setStatus(STATUS.error);
    }
  };

  useEffect(() => {
    if (!called) {
      setCalled(true);
      return;
    }

    if (called && isAuthenticated) {
      get({
        limit: 100,
      });
    }
  }, [called, isAuthenticated]);

  return (
    <FAQContext.Provider
      value={{
        status,
        FAQ,
        get,
      }}
    >
      {children}
    </FAQContext.Provider>
  );
};

export const useFAQ = () => {
  const context = useContext(FAQContext);

  if (!context) {
    throw new Error("useFAQ must be used within a FAQProvider");
  }

  return context;
};
