import React, { createContext, useContext, useReducer, useState } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import {
  UserToAddType,
  GetUserResponseType,
  UserListParamType,
} from "src/interfaces/user";
import {
  createUserService,
  getUsersService,
  updateUserService,
  deleteUserService,
  activeUserService,
} from "src/services/user";

import { UserContextType } from "./props";
import { StatusUserActionEnum, statusUserReducer } from "./reducer";

export const UserContext = createContext({} as UserContextType);

export const UserProvider: React.FC = props => {
  const { children } = props;

  const [state, dispatch] = useReducer(statusUserReducer, {
    createStatus: STATUS.inital,
    listStatus: STATUS.inital,
    updateStatus: STATUS.inital,
    deleteStatus: STATUS.inital,
    activeStatus: STATUS.inital,
  });

  const [users, setUsers] = useState<GetUserResponseType>(
    {} as GetUserResponseType
  );
  const [user, setUser] = useState<UserToAddType | undefined>(undefined);

  const list = async (params: UserListParamType) => {
    try {
      if (state.listStatus.loading) return;
      dispatch({ type: StatusUserActionEnum.LIST, payload: STATUS.loading });

      const response: GetUserResponseType = await getUsersService({
        ...params,
      });

      setUsers(response);

      dispatch({ type: StatusUserActionEnum.LIST, payload: STATUS.success });
    } catch (e: any) {
      setUsers({} as GetUserResponseType);
      dispatch({ type: StatusUserActionEnum.LIST, payload: STATUS.error });
      toast.error(
        e?.response?.data?.message ??
          "Ocorreu um problema ao listar usuários, tente novamente"
      );
    }
  };

  const create = async (params: Partial<UserToAddType>) => {
    try {
      dispatch({
        type: StatusUserActionEnum.CREATE,
        payload: STATUS.loading,
      });

      await createUserService(params);

      dispatch({
        type: StatusUserActionEnum.CREATE,
        payload: STATUS.success,
      });

      toast.success("Usuário cadastrado");
    } catch (e: any) {
      dispatch({ type: StatusUserActionEnum.CREATE, payload: STATUS.error });

      toast.error(
        e?.response?.data?.message ??
          "Ocorreu um problema ao cadastrar esse usuário"
      );
    }
  };

  const update = async (params: Partial<UserToAddType>) => {
    try {
      dispatch({
        type: StatusUserActionEnum.UPDATE,
        payload: STATUS.loading,
      });

      await updateUserService(params);

      dispatch({
        type: StatusUserActionEnum.UPDATE,
        payload: STATUS.success,
      });

      toast.success("Usuário atualizado");
    } catch (e: any) {
      dispatch({ type: StatusUserActionEnum.UPDATE, payload: STATUS.error });

      toast.error(
        e?.response?.data?.message ??
          "Ocorreu um problema ao atualizar esse usuário"
      );
    }
  };

  const deleteUser = async (id: string) => {
    try {
      dispatch({ type: StatusUserActionEnum.DELETE, payload: STATUS.loading });

      await deleteUserService(id);

      dispatch({ type: StatusUserActionEnum.DELETE, payload: STATUS.success });
      toast.success("Usuário desativado");
    } catch (e) {
      dispatch({ type: StatusUserActionEnum.DELETE, payload: STATUS.error });

      toast.error("Ocorreu um problema ao desativar esse usuário");
    }
  };

  const activeUser = async (id: string) => {
    try {
      dispatch({ type: StatusUserActionEnum.ACTIVE, payload: STATUS.loading });

      await activeUserService(id);

      dispatch({ type: StatusUserActionEnum.ACTIVE, payload: STATUS.success });
      toast.success("Usuário ativado");
    } catch (e) {
      dispatch({ type: StatusUserActionEnum.ACTIVE, payload: STATUS.error });

      toast.error("Ocorreu um problema ao ativar esse usuário");
    }
  };

  return (
    <UserContext.Provider
      value={{
        users,
        user,
        setUser,
        list,
        listStatus: state.listStatus,
        create,
        createStatus: state.createStatus,
        update,
        updateStatus: state.updateStatus,
        deleteUser,
        deleteStatus: state.deleteStatus,
        activeUser,
        activeStatus: state.activeStatus,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};
