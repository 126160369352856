import React from "react";
import InputMask from "react-input-mask";

import ErrorIcon from "@material-ui/icons/Error";
import { useField } from "formik";

import { TextFieldProps } from "./props";
import * as Styled from "./styles";

const TextField: React.FC<TextFieldProps> = props => {
  const {
    name,
    value,
    defaultValue,
    mask,
    disabled,
    icon,
    disableFormik,
    ...rest
  } = props;

  if (disableFormik) {
    return (
      <Styled.TextField
        fullWidth
        variant="outlined"
        {...rest}
        InputLabelProps={{
          required: false,
          ...rest.InputLabelProps,
        }}
        InputProps={{
          endAdornment: icon || null,
          ...rest.InputProps,
        }}
        defaultValue={defaultValue}
        disabled={disabled}
        value={value}
      />
    );
  }

  const [field, meta] = useField<string>({
    name,
    defaultValue,
    value,
  });

  const inputMask = React.useMemo(() => mask || "", [mask]);

  const EndAdornment = React.useMemo(() => {
    if (meta.error && meta.touched) {
      return <ErrorIcon color="error" />;
    }

    if (icon) {
      return icon || null;
    }

    return null;
  }, [meta]);

  const errorProps = React.useMemo(() => {
    if (meta.initialError) {
      return {
        error: true,
        helperText: meta.initialError,
      };
    }
    if (meta.error && meta.touched) {
      return {
        error: true,
        helperText: meta.error,
      };
    }
    return {};
  }, [meta.error, meta.initialError, meta.touched]);

  return (
    <InputMask {...field} mask={inputMask} maskChar={null} disabled={disabled}>
      {() => (
        <Styled.TextField
          fullWidth
          variant="outlined"
          id={field.name}
          {...errorProps}
          {...rest}
          {...field}
          InputLabelProps={{
            required: false,
            ...rest.InputLabelProps,
          }}
          InputProps={{
            endAdornment: EndAdornment,
            ...rest.InputProps,
          }}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      )}
    </InputMask>
  );
};

export default TextField;
