import React, { useCallback } from "react";

import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";

import TextField from "src/components/Inputs/TextField";
import { useService } from "src/hooks/service/useService";
import { debounce } from "src/utils/debounce";

import * as Styled from "./styles";

const OptionsPanel = () => {
  const { list } = useService();

  const searchByName = useCallback(
    debounce((query: string) => {
      if (query.length) {
        list({ name: query });
      } else {
        list({});
      }
    }, 1000),
    []
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    searchByName(e.target.value);
  };

  return (
    <Styled.Container>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12}>
          <TextField
            label="Pesquisar serviço"
            name="name"
            disableFormik
            onChange={onChange}
            icon={<SearchIcon color="primary" />}
            backgroundwhite
          />
        </Grid>
      </Grid>
    </Styled.Container>
  );
};

export default OptionsPanel;
