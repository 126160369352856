import React from "react";

import { Box, Text, Divider } from "@chakra-ui/react";

import PermissionActionSwitch from "./components/PermissionActionSwitch";
import PermissionsAbility from "./components/PermissionsAbility";

export function FinancialPermissions() {
  return (
    <Box>
      <Text fontSize="sm" color="gray.500">
        Gerenciar ações
      </Text>

      <PermissionActionSwitch
        permissions={["record"]}
        title="Gerenciar lançamentos"
        subtitle="O usuário pode gerenciar todas as informações do financeiro"
      />

      <PermissionActionSwitch
        permissions={["financialAccount"]}
        title="Gerenciar contas bancárias"
        subtitle="O usuário pode gerenciar todas as informações de contas bancárias"
      />

      <Divider my={4} />

      <Text fontSize="sm" color="gray.500">
        Gerenciar lançamentos
      </Text>

      <PermissionsAbility
        permission="record"
        title="Lançamentos"
        subtitle="O usuário pode gerênciar as seguintes ações"
      />

      <Divider my={4} />

      <Text fontSize="sm" color="gray.500">
        Gerenciar contas
      </Text>

      <PermissionsAbility
        permission="financialAccount"
        title="Contas bancárias"
        subtitle="O usuário pode gerênciar as seguintes ações"
      />
    </Box>
  );
}
