import React from "react";
import { toast } from "react-toastify";

import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { usePopupState, bindToggle } from "material-ui-popup-state/hooks";

import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import { BuyOrderStatusEnum } from "src/interfaces/buyOrder";
import BuyOrderStatusesPopover from "src/pages/BuyOrders/components/BuyOrderStatusesPopover";

import * as Styled from "./styles";

const UpdateBuyOrderStatus: React.FC = () => {
  const { buyOrder, updateBuyOrderStatus, setBuyOrder } = useBuyOrder();

  const { setRestartOrderList } = useBuyOrderFilters();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "update-status-order",
  });

  const updateStatus = async (
    buyOrderId: string,
    status: BuyOrderStatusEnum
  ) => {
    try {
      popupState.close();
      await updateBuyOrderStatus({
        id: buyOrderId,
        status,
      });

      if (buyOrder) {
        setBuyOrder({ ...buyOrder, status });
      }
      setRestartOrderList(true);
      toast.success("Status alterado com sucesso!");
    } catch (error) {
      toast.error("Falha ao alterar status, tente novamente");
    }
  };

  return (
    <Styled.SelectStatus
      variant="outlined"
      color="primary"
      {...bindToggle(popupState)}
    >
      <Typography>Alterar status</Typography>
      <ArrowDropDownIcon color="primary" />
      {buyOrder ? (
        <BuyOrderStatusesPopover
          buyOrderId={buyOrder.id}
          updateStatus={updateStatus}
          popupState={popupState}
        />
      ) : null}
    </Styled.SelectStatus>
  );
};

export default UpdateBuyOrderStatus;
