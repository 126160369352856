export enum DashboardInvervalEnum {
  actualMonth = "Mês atual",
  week = "Últimos 7 dias",
  month = "Últimos 30 dias",
  quarter = "Últimos 3 meses",
  halfyear = "Últimos 6 meses",
  annual = "Últimos 12 meses",
}

export interface DashboardFinancialResumeRecordsParamsType {
  year: number;
}

export interface FinancialResumeType {
  monthBalance: number;
  bills: {
    billsToPay: number;
    billsToReceive: number;
    overdueBills: number;
  };
}

export interface FinancialHealthType {
  expenses: {
    paid: number;
    pending: number;
    overdue: number;
    growthPercentage: number;
  };
  incomes: {
    paid: number;
    pending: number;
    overdue: number;
    growthPercentage: number;
  };
}

export interface FinancialResumeRecordMonthType {
  expensePaid: number;
  expenseProvided: number;
  incomePaid: number;
  incomeProvided: number;
  month: number;
  monthName: string;
  totalPaid: number;
  totalProvided: number;
}

export type FinacialResumeRecordsType = FinancialResumeRecordMonthType[];

export interface DashboardSaleType {
  customersTotal: number;
  customersTotalPercentChange: number | null;
  customersNewTotal: number;
  customersNewTotalPercentChange: number | null;
  salesByPeriodActual: Array<{
    label: string;
    type: string;
    value: number;
  }>;
  salesByPeriodPast: Array<{
    label: string;
    type: string;
    value: number;
  }>;
  salesTotalActual: number;
  salesTotalPast: number;
  salesTotalPercentChange: number | null;
  ticketByPeriodActual: Array<{
    label: string;
    type: string;
    value: number;
  }>;
  ticketByPeriodPast: Array<{
    label: string;
    type: string;
    value: number;
  }>;
  ticketTotalActual: number;
  ticketTotalPast: number;
  ticketTotalPercentChange: number | null;
}
