import React from "react";

import { Box, Text, Divider } from "@chakra-ui/react";

import PermissionActionSwitch from "./components/PermissionActionSwitch";
import PermissionsAbility from "./components/PermissionsAbility";

export function DashboardPermissions() {
  return (
    <Box>
      <Text fontSize="sm" color="gray.500">
        Gerenciar ações
      </Text>

      <Divider my={4} />

      <PermissionActionSwitch
        permissions={["orderDashboard"]}
        title="Visualizar dashboard de vendas"
        subtitle="O usuário pode visualizar todas as informações da dashboard de vendas"
      />

      <PermissionActionSwitch
        permissions={["financialDashboard"]}
        title="Visualizar dashboard financeira"
        subtitle="O usuário pode visualizar todas as informações da dashboard financeira"
      />

      <Divider my={4} />

      <Text fontSize="sm" color="gray.500">
        Vendas
      </Text>

      <PermissionsAbility
        permission="orderDashboard"
        title="Dashboard de vendas"
        subtitle="O usuário pode gerênciar as seguintes ações"
      />

      <Divider my={4} />

      <Text fontSize="sm" color="gray.500">
        Financeiro
      </Text>

      <PermissionsAbility
        permission="financialDashboard"
        title="Dashboard financeira"
        subtitle="O usuário pode gerênciar as seguintes ações"
      />
    </Box>
  );
}
