import { toast } from "react-toastify";

import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { ReactComponent as FinancialIcon } from "src/assets/icons/sidebar/financial.svg";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { RecordType } from "src/interfaces/record";
import { useDeleteRecord } from "src/services/hooks/record/useDeleteRecord";

interface DeleteRecordModalType {
  record?: RecordType;
  onSuccess?: () => void;
}

function DeleteRecordModal({ record, onSuccess }: DeleteRecordModalType) {
  const { open, handleClose } = useBaseSwipeableDrawer();

  const {
    mutate: deleteRecord,
    isLoading,
    variables,
  } = useDeleteRecord({
    onSuccess: () => {
      toast.success("Lançamento(s) excluídos com com sucesso!");
      handleOnClose();
      if (onSuccess) onSuccess();
    },
  });

  const handleOnClose = () => {
    handleClose("deleteRecord");
  };

  const handleDeleteRecord = (
    deleteType: "current" | "currentAndNext" = "current"
  ) => {
    deleteRecord({
      id: record?.id as string,
      deleteType,
    });
  };

  return (
    <Modal
      isOpen={open.includes("deleteRecord")}
      onClose={handleOnClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" pb={0}>
          <Icon
            as={FinancialIcon}
            sx={{
              path: {
                fill: "error.500",
              },
            }}
          />
        </ModalHeader>
        <ModalCloseButton color="error.500" />
        <ModalBody pb={6}>
          {record?.recurrenceType ? (
            <>
              <Text textAlign="center" mb={7}>
                Deseja excluir somente o
                <br /> lançamento atual? Ou todos?
              </Text>
              <Button
                w="100%"
                variant="outline"
                colorScheme="error"
                mb={3}
                onClick={() => {
                  handleDeleteRecord("current");
                }}
                isLoading={isLoading && variables?.deleteType === "current"}
              >
                Somente atual
              </Button>
              <Button
                w="100%"
                colorScheme="error"
                onClick={() => {
                  handleDeleteRecord("currentAndNext");
                }}
                isLoading={
                  isLoading && variables?.deleteType === "currentAndNext"
                }
              >
                Atual e próximos
              </Button>
            </>
          ) : (
            <>
              <Text textAlign="center" mb={7}>
                Tem certeza que deseja excluir esse lançamento?
              </Text>

              <Button
                w="100%"
                colorScheme="error"
                onClick={() => {
                  handleDeleteRecord("current");
                }}
                isLoading={isLoading}
              >
                Excluir
              </Button>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DeleteRecordModal;
