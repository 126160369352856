import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import {
  GetMovementResponseType,
  MovementFormType,
  MovementListParamsType,
} from "src/interfaces/movement";
import {
  addMovement,
  deleteMovement,
  getMovements,
} from "src/services/movement";

import { MovementContextType } from "./props";

export const MovementContext = createContext({} as MovementContextType);

export const MovementProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState(STATUS.inital);
  const [createStatus, setCreateStatus] = useState(STATUS.inital);
  const [deleteStatus, setDeleteStatus] = useState(STATUS.inital);
  const [movements, setMovements] = useState<GetMovementResponseType>(
    {} as GetMovementResponseType
  );

  const list = async (params: MovementListParamsType) => {
    try {
      setMovements({} as GetMovementResponseType);
      setStatus(STATUS.loading);

      const response: GetMovementResponseType = await getMovements(params);

      setMovements(response);

      setStatus(STATUS.success);
    } catch (e) {
      toast.error(
        "Ocorreu um problema ao carregar as movimentações. Por favor verifique sua conexão e tente novamnete."
      );
      setMovements({} as GetMovementResponseType);
      setStatus(STATUS.error);
      throw e;
    }
  };

  const add = async (params: MovementFormType) => {
    try {
      setCreateStatus(STATUS.loading);

      const { quantity, ...rest } = params;

      const quantityNumber = Number(quantity);

      await addMovement({
        ...rest,
        quantity: quantityNumber,
      } as MovementFormType);

      setCreateStatus(STATUS.success);
      toast.success("Movimentação cadastrada!");
    } catch (e) {
      setCreateStatus(STATUS.error);

      toast.error("Ocorreu um problema ao cadastrar movimentação");
      throw e;
    }
  };

  const remove = async (id: string) => {
    try {
      setDeleteStatus(STATUS.loading);

      await deleteMovement(id);

      setDeleteStatus(STATUS.success);

      toast.success("Movimentação deletada!");
    } catch (e) {
      setDeleteStatus(STATUS.error);

      toast.error("Ocorreu um problema ao deletar essa movimentação");
      throw e;
    }
  };

  return (
    <MovementContext.Provider
      value={{
        movements,
        list,
        status,
        add,
        createStatus,
        remove,
        deleteStatus,
      }}
    >
      {children}
    </MovementContext.Provider>
  );
};

export const useMovement = () => {
  const context = useContext(MovementContext);

  if (!context) {
    throw new Error("useMovement must be used within a MovementProvider");
  }

  return context;
};
