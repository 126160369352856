import React from "react";

import { Button as ChakaraButton } from "@chakra-ui/react";
import { Chip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReplayIcon from "@material-ui/icons/Replay";

import Button from "src/components/Button";
import Modal from "src/components/Modal";
import { useModal } from "src/components/Modal/useModal";
import { useOrder } from "src/hooks/order/useOrder";
import { useRecordsOrder } from "src/hooks/order/useRecordsOrder";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";
import { OrderListType } from "src/interfaces/order";

import { CreateOrDeleteOrderRecordsModalProps } from "./props";
import * as Styled from "./styles";

const CreateOrDeleteOrderRecordsModal: React.FC<CreateOrDeleteOrderRecordsModalProps> =
  props => {
    const { id, recordsCreatedAt, type } = props;
    const { getOrderRecords } = useRecordsOrder();

    const {
      createOrderRecords,
      createOrderRecordsStatus,
      deleteOrderRecordsStatus,
      deleteOrderRecords,
    } = useOrder();
    const { handleClose } = useModal();
    const { setRestartOrderList } = useOrderFilters();

    const createOrDeleteOrderRecordsService = async () => {
      if (!recordsCreatedAt && !createOrderRecordsStatus.loading) {
        await createOrderRecords(id);
        getOrderRecords(id);
      }
      if (recordsCreatedAt && !deleteOrderRecordsStatus.loading) {
        await deleteOrderRecords(id);
      }

      handleClose();
      setRestartOrderList(true);
    };

    return (
      <Modal>
        <Box p={3}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <div />
                {recordsCreatedAt ? (
                  <Styled.AlertIcon alignmentBaseline="middle" />
                ) : (
                  <Styled.InfoIcon alignmentBaseline="middle" />
                )}
                <Styled.CloseButton onClick={handleClose} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Styled.InfoText align="center" variant="h6">
                Tem certeza que deseja{" "}
                {recordsCreatedAt ? "estornar" : "lançar"} os pagamentos
                {type === "serviceOrder"
                  ? " desta ordem de serviço"
                  : " deste pedido de venda"}
                ?
              </Styled.InfoText>
            </Grid>
            <Grid item xs={12}>
              {recordsCreatedAt ? (
                <Styled.DeleteButton
                  variant="contained"
                  onClick={createOrDeleteOrderRecordsService}
                  fullWidth
                  size="large"
                  disabled={deleteOrderRecordsStatus.loading}
                  loading={deleteOrderRecordsStatus.loading}
                >
                  Estornar
                </Styled.DeleteButton>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={createOrDeleteOrderRecordsService}
                  fullWidth
                  size="large"
                  disabled={createOrderRecordsStatus.loading}
                  loading={createOrderRecordsStatus.loading}
                >
                  Lançar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };

interface CreateOrDeleteOrderRecordsProps {
  order: OrderListType;
  showType?: "button" | "chip";
}

const CreateOrDeleteOrderRecords: React.FC<CreateOrDeleteOrderRecordsProps> =
  props => {
    const { order, showType = "chip" } = props;
    const { handleOpen } = useModal();

    return (
      <>
        {showType === "chip" ? (
          <Chip
            style={{
              backgroundColor: order.recordsCreatedAt ? "#14B73880" : "#14B738",
              color: "#FFF",
              fontWeight: 500,
              marginRight: "8px",
              lineHeight: 1,
            }}
            label={order.recordsCreatedAt ? "Contas lançadas" : "Lançar contas"}
            onClick={handleOpen}
            onDelete={order?.recordsCreatedAt ? handleOpen : undefined}
            deleteIcon={
              <CheckCircleIcon style={{ color: "#FFF" }} fontSize="small" />
            }
          />
        ) : (
          <ChakaraButton
            justifyContent="flex-start"
            size="sm"
            variant="ghost"
            color="text"
            leftIcon={
              order.recordsCreatedAt ? (
                <ReplayIcon color="primary" fontSize="small" />
              ) : (
                <CallMadeIcon color="primary" fontSize="small" />
              )
            }
            onClick={handleOpen}
          >
            {order.recordsCreatedAt ? "Estornar contas" : "Lançar contas"}
          </ChakaraButton>
        )}
        <CreateOrDeleteOrderRecordsModal
          id={order.id}
          recordsCreatedAt={order.recordsCreatedAt}
          type={order.type}
        />
      </>
    );
  };

export default CreateOrDeleteOrderRecords;
