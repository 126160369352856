import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "azulou-app.firebaseapp.com",
  projectId: "azulou-app",
  storageBucket: "azulou-app.appspot.com",
  messagingSenderId: "931022910123",
  appId: "1:931022910123:web:ff35d7e8015397c1f83dee",
  measurementId: "G-FMEDZYJE4P",
};
let analytics: Analytics;

const initFirebaseAnalytics = () => {
  if (process.env.REACT_APP_FIREBASE_API_KEY) {
    const app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
  }
  return analytics;
};
initFirebaseAnalytics();

export const setLogEvent = (event: string, params = {}) => {
  if (analytics) {
    logEvent(analytics, event, params);
  }
};

export const setLogCurrentScreen = (screenName: string) => {
  if (analytics) {
    logEvent(analytics, screenName);
  }
};

export const FirebaseAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    if (analytics) {
      setLogCurrentScreen(location.pathname);
      setLogEvent("page_view", { page_path: location.pathname });
    }
  }, [location]);
  return null;
};
