import * as yup from "yup";

export const schemaMyBusinessForm = yup.object({
  type: yup.string().required(),
  cpf: yup.string().when("type", type => {
    if (type === "PF") {
      return yup.string().required("CPF inválido");
    }
    return yup.string();
  }),
  cnpj: yup.string().when("type", type => {
    if (type === "PJ") {
      return yup.string().required("CNPJ inválido");
    }
    return yup.string();
  }),
  companyName: yup.string(),
  email: yup.string().email("E-mail inválido"),
  phone: yup.string(),
  cep: yup.string().test("is-cep", "CEP inválido.", (cep = "") => {
    const normalize = cep.replace(/[^0-9]+/gi, "");
    return normalize.length === 0 || normalize.length === 8;
  }),

  street: yup.string(),
  number: yup.string(),
  neighborhood: yup.string(),
  uf: yup.string(),
  city: yup.string(),
  complement: yup.string(),
});
