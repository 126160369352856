import { Box } from "@chakra-ui/react";

import Typography from "src/components/Typography";

import * as Styled from "./styles";

const EmptyContactTable = () => {
  return (
    <Box display="flex" flexDirection="column" flex={1} alignItems="center">
      <Styled.ContactsIcon />
      <Typography variant="subtitle1">
        Você ainda não possui clientes para serem visualizados nesta lista!
      </Typography>
      <Typography variant="subtitle1">
        comece
        <Styled.MarkedText> cadastrando os seus clientes</Styled.MarkedText>
      </Typography>
    </Box>
  );
};

export default EmptyContactTable;
