import styled from "styled-components";

import { Box } from "@material-ui/core";

export const BoxGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
