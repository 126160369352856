import {
  PasswordStatusKeyType,
  PasswordStatusType,
} from "src/interfaces/password";

type PasswordStatusMapType = {
  [key in PasswordStatusKeyType]: PasswordStatusType;
};

export const PASSWORD_STATUS_METER: PasswordStatusMapType = {
  empty: {
    label: "",
    value: 0,
    color: "",
  },
  lower: {
    label: "muito insegura",
    value: 5,
    color: "#E54848",
  },
  insecure: {
    label: "insegura",
    value: 25,
    color: "#F9A82F",
  },

  secure: {
    label: "segura",
    value: 75,
    color: "#F9A82F",
  },
  stronger: {
    label: "muito segura",
    value: 100,
    color: "#1A5DF2",
  },
};

export const PASSWORD_SIZE_MESSAGE =
  "A senha deve conter no mínimo 6 caracteres";
