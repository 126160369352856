import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormControl, FormLabel, SimpleGrid, Switch } from "@chakra-ui/react";

import { CurrencyInput } from "src/components/NewComponents/CurrencyInput";
import { Input } from "src/components/NewComponents/Input";
import { Select } from "src/components/NewComponents/Select";
import { DISCOUNT_ITEMS } from "src/pages/Orders/components/DiscountSection";
import { formatDecimalInput } from "src/utils/format";

import { OrderPaymentSchema } from "../..";

export function DiscountSection() {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<OrderPaymentSchema>();

  const { hasDiscount, discountType, discount } = watch();

  const discountValueField = useMemo(() => {
    if (discountType === "money") {
      return (
        <CurrencyInput
          error={errors.discount}
          control={control}
          name="discount"
          bg="white"
          label="Valor do desconto"
        />
      );
    }

    if (discountType === "percentage") {
      return (
        <Input
          error={errors.discount}
          autoComplete="off"
          {...register("discount", {
            onChange: e => {
              e.target.value = formatDecimalInput({
                value: e.target.value,
                beforeRange: 9,
                range: 2,
                maxValue: 100,
              });
            },
            onBlur: e => {
              e.target.value = formatDecimalInput({
                value: e.target.value,
                beforeRange: 9,
                range: 2,
                maxValue: 100,
              });
            },
          })}
          bg="white"
          label="Desconto em %"
        />
      );
    }

    return null;
  }, [discountType, errors]);

  const renderFields = useMemo(() => {
    if (hasDiscount) {
      return (
        <SimpleGrid
          columns={{
            base: 2,
            md: 3,
            xl: 4,
          }}
          gap={4}
          mt={3}
          mb={2.5}
        >
          <Controller
            control={control}
            name="discountType"
            render={({ field: { ref, onChange, ...props } }) => {
              return (
                <Select
                  options={DISCOUNT_ITEMS.map(condition => ({
                    label: condition.name,
                    value: condition.value,
                  }))}
                  inputRef={ref}
                  error={!!errors.discountType}
                  errorMessage={errors.discountType?.message}
                  placeholder="Tipo de desconto"
                  isClearable={false}
                  onChange={value => {
                    onChange(value);
                    setValue("discount", "");
                  }}
                  {...props}
                />
              );
            }}
          />
          {discountValueField}
        </SimpleGrid>
      );
    }

    return null;
  }, [hasDiscount, discountType, discount, discountValueField, errors]);

  return (
    <>
      <Controller
        control={control}
        name="hasDiscount"
        render={({ field: { ref, value, onChange, name } }) => (
          <FormControl display="flex" alignItems="center" mt={4}>
            <FormLabel
              htmlFor="hasDiscount"
              color="text"
              fontSize="md"
              fontWeight={500}
              m={0}
              mb={2.5}
              mr={4}
            >
              Desconto
            </FormLabel>
            <Switch
              ref={ref}
              id="hasDiscount"
              name={name}
              isChecked={value}
              checked={value}
              colorScheme={value ? "primary" : "error"}
              onChange={e => {
                onChange(e.target.checked);
                if (e.target.checked) {
                  setValue("discountType", "money");
                } else {
                  setValue("discountType", "");
                  setValue("discount", "");
                }
              }}
            />
          </FormControl>
        )}
      />

      {renderFields}
    </>
  );
}
