import { ListParamsType } from "src/interfaces/request";
import { ServiceType } from "src/interfaces/service";
import api from "src/services/api";

/**
 * Cadastrar serviço
 */
export const addService = (params: ServiceType) =>
  api.post("v1/service", params).then(res => res.data);

/**
 * Listar serviços
 */
export const getServices = (params: ListParamsType) =>
  api
    .get("v1/service", {
      params,
    })
    .then(res => res.data);

/**
 * Encontrar serviço pelo Id
 */
export const getService = (id: string) =>
  api.get(`v1/service/${id}`).then(res => res.data);

/**
 * Deletar serviço
 */
export const deleteService = (id: string) =>
  api
    .delete("v1/service", {
      params: {
        id,
      },
    })
    .then(res => res);

/**
 * Atualizar serviço
 */
export const updateService = (params: ServiceType) =>
  api.put("v1/service", params).then(res => res.data);
