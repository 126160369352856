import {
  OrderListParamsType,
  OrderType,
  GetOrdersStatusParamType,
} from "src/interfaces/order";
import api from "src/services/api";

/**
 * Cadastrar Ordem de serviço
 */
export const addOrder = (params: Partial<OrderType>) =>
  api.post("v2/order", params).then(res => res);

/**
 * Listar Ordens de serviço
 */
export const getOrders = (params: OrderListParamsType) =>
  api
    .get("v1/order", {
      params,
    })
    .then(res => res.data);

/**
 * Encontrar Ordem de serviço pelo Id
 */
export const getOrder = (id: string) =>
  api.get(`v1/order/${id}`).then(res => res.data);

/**
 * Deletar Ordem de serviço
 */
export const deleteOrder = (id: string) =>
  api
    .delete("v1/order", {
      params: {
        id,
      },
    })
    .then(res => res);

/**
 * Atualizar Ordem de serviço
 */
export const updateOrder = (params: Partial<OrderType>) =>
  api.put("v2/order", params).then(res => res.data);

/*
 * Atualizar status de Ordem de serviço/produto
 */
export const updateOrderStatus = (orderId: string, statusId: string) =>
  api
    .put(`v1/order/${orderId}/status`, {
      statusId,
    })
    .then(res => res.data);

/**
 * Lista registros financeiros de uma Order
 */
export const getRecordsByOrder = (orderId: string) =>
  api.get(`v1/order/${orderId}/record`).then(res => res.data);

/**
 * Status das ordens de serviço
 */
export const getOrderStatus = (params: GetOrdersStatusParamType) =>
  api
    .get(`/v1/order/meta`, {
      params,
    })
    .then(res => res.data);

/**
 * Lançar estoque de uma os/pv
 */
export const createOrderStockService = (id: string) =>
  api.post(`v1/order/${id}/stock`).then(res => res);

/**
 * Estornar estoque de uma os/pv
 */
export const deleteOrderStockService = (id: string) =>
  api.delete(`v1/order/${id}/stock`).then(res => res);

/**
 * Lançar contas de uma os/pv
 */
export const createOrderRecordsService = (id: string) =>
  api.post(`v1/order/${id}/records`).then(res => res);

/**
 * Estornar contas de uma os/pv
 */
export const deleteOrderRecordsService = (id: string) =>
  api.delete(`v1/order/${id}/records`).then(res => res);
