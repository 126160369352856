import React, { FC } from "react";

import { Box, Grid } from "@material-ui/core";
import { Formik, Form } from "formik";

import Button from "src/components/Button";
import Typography from "src/components/Typography";
import { useCompany } from "src/hooks/company/useCompany";
import { PaymentTermsType } from "src/interfaces/company";
import {
  DOWN_PAYMENT_ITEMS,
  PAYMENT_CONDITION_ITEMS,
} from "src/pages/Orders/components/ConditionPaymentSection";
import SectionCard from "src/pages/Settings/components/SectionCard";

import SwitchSettings from "../../SwitchSettings";
import * as Styled from "./styles";

const ConditionPaymentSection: FC = () => {
  const { userCompany, updatePaymentTerms, statusPaymentTerms } = useCompany();

  const onSubmit = (values: PaymentTermsType) => {
    updatePaymentTerms(values);
  };

  const renderPaymentValueField = (values: PaymentTermsType) => {
    if (values?.inputType === "value") {
      return (
        <Styled.CurrencyField
          label="Valor da entrada"
          name="priceValue"
          InputLabelProps={{
            shrink: true,
          }}
          required
          disabled={!values.status}
        />
      );
    }

    if (values?.inputType === "percentage") {
      return (
        <Styled.PercentField
          label="Valor da entrada"
          name="percentValue"
          InputLabelProps={{
            shrink: true,
          }}
          required
          disabled={!values.status}
        />
      );
    }

    return null;
  };

  const renderFields = (values: PaymentTermsType) => {
    if (values.coditionType === "Com entrada") {
      return (
        <>
          <Grid item xs={12} sm={4} md={3}>
            <Styled.SelectField
              label="Tipo de entrada"
              name="inputType"
              items={DOWN_PAYMENT_ITEMS}
              defaultValue="value"
              required
              disabled={!values.status}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            {renderPaymentValueField(values)}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Styled.TextField
              label="Qtd de parcelas restantes"
              name="parcelValue"
              InputLabelProps={{
                shrink: true,
              }}
              required
              disabled={!values.status}
            />
          </Grid>
        </>
      );
    }

    if (values.coditionType === "Parcelado") {
      return (
        <>
          <Grid item xs={12} sm={4} md={3}>
            <Styled.TextField
              label="Quantidade de parcelas"
              name="parcelValue"
              InputLabelProps={{
                shrink: true,
              }}
              required
              disabled={!values.status}
            />
          </Grid>
        </>
      );
    }

    return null;
  };

  return (
    <Formik
      initialValues={{
        status: userCompany.settings?.defaultPaymentTerm?.status ?? false,
        coditionType:
          userCompany.settings?.defaultPaymentTerm?.coditionType ?? "",
        inputType: userCompany.settings?.defaultPaymentTerm?.inputType ?? "",
        parcelValue:
          userCompany.settings?.defaultPaymentTerm?.parcelValue ?? "",
        percentValue:
          userCompany.settings?.defaultPaymentTerm?.percentValue ?? "",
        priceValue: userCompany.settings?.defaultPaymentTerm?.priceValue ?? "",
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values }) => {
        return (
          <Form>
            <SectionCard
              title="Configurações de condição de pagamento"
              rightHeader={<SwitchSettings name="status" label="" />}
            >
              <Box mb={2}>
                <Typography variant="body2">
                  Pré-selecionar condição de pagamento
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <Styled.SelectField
                    label="Condição de pagamento"
                    name="coditionType"
                    items={PAYMENT_CONDITION_ITEMS}
                    defaultValue="A Vista"
                    disabled={!values.status}
                    required
                  />
                </Grid>
                {renderFields(values)}
              </Grid>
              <Box display="flex" flexDirection="row-reverse" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                  loading={statusPaymentTerms.loading}
                >
                  Salvar
                </Button>
              </Box>
            </SectionCard>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ConditionPaymentSection;
