import styled from "styled-components";

import { Box, ChipTypeMap } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

export type ChipOrderProps = OverridableComponent<
  ChipTypeMap<
    {
      background: string;
    },
    "div"
  >
>;

export interface StatusTagProps {
  color?: string;
}

export const ChipOrder = styled<ChipOrderProps>(Chip)`
  max-width: 8rem;
  font-size: 13px;
  font-weight: 500;
  color: white;
  background-color: ${({ background }) => background};
  border-radius: 10px;
  .MuiChip-label {
    display: flex;
    align-items: center;
  }
`;

export const StatusTag = styled(Box)<StatusTagProps>`
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  font-size: 13px;
  text-transform: capitalize;
`;

export const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
`;

export const Loading = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.common.white};
`;
