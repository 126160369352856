import React, { FC, useMemo } from "react";

import { Box } from "@chakra-ui/react";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { format } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { PopupState } from "material-ui-popup-state/core";
import { bindToggle } from "material-ui-popup-state/hooks";

import Typography from "src/components/Typography";

import { SelectedFilterType } from "../../props";
import * as Styled from "./styles";

interface PeriodFilterButtonProps {
  startDate: Date;
  endDate: Date;
  selectedDay: Date;
  selectedMonth: Date;
  selectedFilter: SelectedFilterType;
  popupState: PopupState;
}

const PeriodFilterButton: FC<PeriodFilterButtonProps> = ({
  startDate,
  endDate,
  selectedFilter,
  selectedDay,
  selectedMonth,
  popupState,
}) => {
  const content = useMemo(() => {
    if (selectedFilter === "empty") {
      return (
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <CalendarTodayIcon />
          <Typography color="textSecondary" variant="body2" noWrap>
            Por período
          </Typography>
          <Box width={15} />
        </Box>
      );
    }

    if (selectedFilter === "byDay") {
      return (
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <CalendarTodayIcon />
          <Typography color="primary">
            {format(selectedDay, "dd/MM/yyyy")}
          </Typography>
        </Box>
      );
    }

    if (selectedFilter === "byMonth") {
      return (
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <CalendarTodayIcon />
          <Typography color="primary" firstletter>
            {format(selectedMonth, "MMMM", { locale: ptBrLocale })}
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <CalendarTodayIcon />
        <Styled.IntervalDate color="primary" variant="subtitle2">
          <p>{format(startDate, "dd/MM/yyyy")}</p>
          <p>{format(endDate, "dd/MM/yyyy")}</p>
        </Styled.IntervalDate>
      </Box>
    );
  }, [selectedFilter, selectedDay, selectedMonth, startDate, endDate]);

  return (
    <Styled.Button
      enabled={selectedFilter !== "empty"}
      variant="outlined"
      color={selectedFilter === "empty" ? "default" : "primary"}
      fullWidth
      {...bindToggle(popupState)}
    >
      {content}
    </Styled.Button>
  );
};

export default PeriodFilterButton;
