import styled from "styled-components";

import MuiSelect from "@material-ui/core/Select";

import { SelectFieldProps } from "./props";

export const Select = styled(MuiSelect)<
  Pick<SelectFieldProps, "backgroundwhite">
>`
  .MuiSelect-icon {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  background-color: ${({ backgroundwhite, theme }) =>
    backgroundwhite ? theme.palette.common.white : `rgba(0, 0, 0, 0)`};

  &.MuiInputBase-root.Mui-disabled {
    opacity: 80%;
  }

  .Mui-disabled {
    opacity: 80%;
  }
`;
