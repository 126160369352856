import React from "react";
import { DateHeaderProps } from "react-big-calendar";

import { Flex } from "@chakra-ui/react";

interface IDateHeader extends DateHeaderProps {
  handleDateSelect: (date: Date) => void;
}
function DateHeader({ date, label, handleDateSelect }: IDateHeader) {
  return (
    <>
      <Flex
        onClick={() => handleDateSelect(date)}
        cursor="pointer"
        fontWeight="bold"
        color={date.getDay() === 0 ? "error.500" : "gray"}
        pr={4}
        pb={3}
        justifyContent="end"
      >
        {label}
      </Flex>
    </>
  );
}

export default DateHeader;
