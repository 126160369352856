// import { ReactComponent as ContactsActiveIcon } from "src/assets/icons/sidebar/contacts-active.svg";
// import { ReactComponent as ContactsIcon } from "src/assets/icons/sidebar/contacts.svg";
import { ReactComponent as DashboardActiveIcon } from "src/assets/icons/sidebar/dashboard-active.svg";
import { ReactComponent as DashboardIcon } from "src/assets/icons/sidebar/dashboard.svg";
import { ReactComponent as FinancialActiveIcon } from "src/assets/icons/sidebar/financial-active.svg";
import { ReactComponent as FinancialIcon } from "src/assets/icons/sidebar/financial.svg";
// import { ReactComponent as ProductOrderActiveIcon } from "src/assets/icons/sidebar/product-order-active.svg";
// import { ReactComponent as ProductOrderIcon } from "src/assets/icons/sidebar/product-order.svg";
// import { ReactComponent as ProductsActiveIcon } from "src/assets/icons/sidebar/products-active.svg";
// import { ReactComponent as ProductsIcon } from "src/assets/icons/sidebar/products.svg";
import { ReactComponent as RegisterActiveIcon } from "src/assets/icons/sidebar/register-active.svg";
import { ReactComponent as RegisterIcon } from "src/assets/icons/sidebar/register.svg";
import { ReactComponent as ReportsActiveIcon } from "src/assets/icons/sidebar/reports-active.svg";
import { ReactComponent as ReportssIcon } from "src/assets/icons/sidebar/reports.svg";
import { ReactComponent as SalesActiveIcon } from "src/assets/icons/sidebar/sales-active.svg";
import { ReactComponent as SalesIcon } from "src/assets/icons/sidebar/sales.svg";
import { ReactComponent as ScheduleActiveIcon } from "src/assets/icons/sidebar/schedule-active.svg";
import { ReactComponent as ScheduleIcon } from "src/assets/icons/sidebar/schedule.svg";
// import { ReactComponent as ServiceOrderActiveIcon } from "src/assets/icons/sidebar/service-order-active.svg";
// import { ReactComponent as ServiceOrderIcon } from "src/assets/icons/sidebar/service-order.svg";
// import { ReactComponent as ServicesActiveIcon } from "src/assets/icons/sidebar/services-active.svg";
// import { ReactComponent as ServicesIcon } from "src/assets/icons/sidebar/services.svg";
import { ReactComponent as SettingsActiveIcon } from "src/assets/icons/sidebar/settings-active.svg";
import { ReactComponent as SettingsIcon } from "src/assets/icons/sidebar/settings.svg";
import PATHS from "src/constants/paths";
import { PagesType } from "src/interfaces/layout";

/**
 * - Dashboard
 * - Cadastros (contatos, produtos, serviços)
 * - Vendas (ordens de serviço)
 * - Financeiro
 * - Relatórios
 * - Agenda
 */
export const PAGES: PagesType = [
  {
    label: "dashboard",
    key: "dashboard",
    icon: DashboardIcon,
    iconActive: DashboardActiveIcon,
    moduleSlug: "default",
    path: PATHS.HOME,
  },
  {
    label: "agenda",
    key: "schedule",
    icon: ScheduleIcon,
    iconActive: ScheduleActiveIcon,
    moduleSlug: "default",
    path: PATHS.SCHEDULE,
  },
  {
    label: "Cadastros",
    key: "register",
    icon: RegisterIcon,
    iconActive: RegisterActiveIcon,
    moduleSlug: "default",
    path: PATHS.HOME,
    options: [
      {
        label: "contatos",
        key: "contacts",
        // icon: ContactsIcon,
        // iconActive: ContactsActiveIcon,
        moduleSlug: "default",
        path: PATHS.CONTACTS_LIST,
      },
      {
        label: "produtos",
        key: "products",
        // icon: ProductsIcon,
        // iconActive: ProductsActiveIcon,
        moduleSlug: "default",
        path: PATHS.PRODUCTS_LIST,
      },
      {
        label: "serviços",
        key: "services",
        // icon: ServicesIcon,
        // iconActive: ServicesActiveIcon,
        moduleSlug: "default",
        path: PATHS.SERVICES_LIST,
      },
    ],
  },
  {
    label: "vendas",
    key: "sales",
    icon: SalesIcon,
    iconActive: SalesActiveIcon,
    moduleSlug: ["serviceOrder", "productOrder"],
    path: PATHS.SERVICE_ORDER_LIST,
    options: [
      {
        label: "Ordens de serviço",
        key: "service-order",
        // icon: ServiceOrderIcon,
        // iconActive: ServiceOrderActiveIcon,
        moduleSlug: "serviceOrder",
        nameDefinition: "serviceOrder",
        path: PATHS.SERVICE_ORDER_LIST,
      },
      {
        label: "Pedidos de venda",
        key: "product-order",
        // icon: ProductOrderIcon,
        // iconActive: ProductOrderActiveIcon,
        moduleSlug: "productOrder",
        path: PATHS.PRODUCT_ORDER_LIST,
      },
      {
        label: "Venda direta (PDV)",
        key: "direct-sale",
        moduleSlug: ["productOrder", "serviceOrder"],
        path: PATHS.CREATE_DIRECT_SALE,
      },
    ],
  },
  {
    label: "financeiro",
    key: "financial",
    icon: FinancialIcon,
    iconActive: FinancialActiveIcon,
    moduleSlug: "default",
    path: "",
    permissions: [
      {
        subject: "record",
        action: "view",
      },
      {
        subject: "financialAccount",
        action: "view",
      },
    ],
    options: [
      {
        label: "Contas a receber",
        key: "bills-to-receive",
        moduleSlug: "default",
        path: PATHS.BILL_TO_RECEIVE,
        permissions: [
          {
            subject: "record",
            action: "view",
          },
        ],
      },
      {
        label: "Contas a pagar",
        key: "bills-to-pay",
        moduleSlug: "default",
        path: PATHS.BILL_TO_PAY,
        permissions: [
          {
            subject: "record",
            action: "view",
          },
        ],
      },
      {
        label: "Extrato de contas",
        key: "financial-account-statement",
        moduleSlug: "accountManagement",
        path: PATHS.FINANCIAL_ACCOUNT_STATEMENT,
        permissions: [
          {
            subject: "record",
            action: "view",
          },
        ],
      },
      {
        label: "Gestão de contas",
        key: "financial-accounts",
        moduleSlug: "accountManagement",
        path: PATHS.FINANCIAL_ACCOUNTS,
        permissions: [
          {
            subject: "financialAccount",
            action: "view",
          },
        ],
      },
    ],
  },
  {
    label: "relatórios",
    key: "reports",
    icon: ReportssIcon,
    iconActive: ReportsActiveIcon,
    moduleSlug: "default",
    path: PATHS.REPORTS,
    permissions: [
      {
        subject: "reportClients",
        action: "view",
      },
      {
        subject: "reportFinancialDre",
        action: "view",
      },
      {
        subject: "reportFinancialPredictedAndAccomplished",
        action: "view",
      },
      {
        subject: "reportFinancialRecords",
        action: "view",
      },
      {
        subject: "reportLateClients",
        action: "view",
      },
      {
        subject: "reportOrderProducts",
        action: "view",
      },
      {
        subject: "reportOrderServices",
        action: "view",
      },
      {
        subject: "reportPaymentMethods",
        action: "view",
      },
      {
        subject: "reportProviders",
        action: "view",
      },
      {
        subject: "reportSalesByClient",
        action: "view",
      },
      {
        subject: "reportSalesByPeriod",
        action: "view",
      },
      {
        subject: "reportOrderComission",
        action: "view",
      },
      {
        subject: "reportCashFlowControl",
        action: "view",
      },
      {
        subject: "reportSalesByPeriod",
        action: "view",
      },
      {
        subject: "reportInventory",
        action: "view",
      },
    ],
  },

  {
    label: "configurações",
    key: "settings",
    icon: SettingsIcon,
    iconActive: SettingsActiveIcon,
    moduleSlug: "default",
    path: PATHS.HOME,
  },
];
