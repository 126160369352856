import React, { useEffect, useState, useCallback } from "react";

import { Popper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";

import { debounce } from "src/utils/debounce";

import { AutoCompleteProps } from "./props";
import * as Styled from "./styles";

const AutoComplete: React.FC<AutoCompleteProps> = props => {
  const {
    id,
    options,
    setOptions,
    searchQuery,
    initialRequest,
    getOptionSelected,
    getOptionLabel,
    filterOptions,
    loading,
    searchFieldProps,
    value,
    onChange,
    groupBy,
    disabled,
    endAdornmentChildren,
  } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const searchByName = useCallback(
    debounce((query: string) => {
      searchQuery(query);
    }, 1000),
    []
  );

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    searchByName(e.target.value);
  };

  return (
    <MuiAutocomplete
      id={id}
      open={open}
      onOpen={() => {
        initialRequest();
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      options={options}
      loading={loading}
      value={value}
      onChange={onChange}
      filterOptions={filterOptions}
      disabled={disabled}
      renderInput={params => (
        <Styled.SearchField
          {...params}
          {...searchFieldProps}
          variant="outlined"
          fullWidth
          onChange={onInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {endAdornmentChildren}
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      PopperComponent={({ style, ...props }) => (
        <Popper {...props} style={{ ...style, zIndex: 1999 }} />
      )}
    />
  );
};

export default AutoComplete;
