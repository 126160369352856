import styled from "styled-components";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpwardRounded";

import Typography from "src/components/Typography";

export const CurrencyText = styled(Typography)<{ redCurrency?: boolean }>`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme, redCurrency }) =>
    redCurrency ? theme.palette.error.main : theme.palette.primary.main};
`;

export const CurrencyLabel = styled(Typography)`
  font-size: 10px;
  font-weight: 400;
  color: #5b5c60;
`;

export const ArrowUpward = styled(ArrowUpwardIcon)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

export const ArrowDownward = styled(ArrowDownwardIcon)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.error.main};
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;
