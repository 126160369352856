/* eslint-disable no-console */
import React from "react";

import { Button } from "@chakra-ui/react";
import { Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { useModal } from "src/components/Modal/useModal";
import { BuyOrderType } from "src/interfaces/buyOrder";

import BuyOrderDeleteModal from "../../../components/BuyOrderDeleteModal";
import * as Styled from "./styles";

interface DeleteBuyOrderProps {
  buyOrder: BuyOrderType;
  afterActions(): void;
}

const DeleteBuyOrder: React.FC<DeleteBuyOrderProps> = props => {
  const { afterActions, buyOrder } = props;

  const { handleOpen } = useModal();

  const cantDeleteBuyOrder =
    !!buyOrder.stockCreatedAt || !!buyOrder.recordsCreatedAt;

  return (
    <>
      <Box position="relative" display="flex" alignItems="center">
        <Button
          w="100%"
          justifyContent="flex-start"
          size="sm"
          variant="ghost"
          color="text"
          leftIcon={<DeleteIcon color="primary" fontSize="small" />}
          disabled={cantDeleteBuyOrder}
          onClick={() => {
            if (!cantDeleteBuyOrder) {
              handleOpen();
            }
          }}
        >
          Excluir
        </Button>

        {cantDeleteBuyOrder ? (
          <Styled.Tooltip
            classes={{
              tooltip: "custom__tooltip",
            }}
            title={
              <>
                <Box
                  component="span"
                  fontSize="15px"
                  fontWeight="500"
                  color="#fff"
                >
                  Exclusão desabilitada.{" "}
                </Box>
                <Box
                  component="span"
                  fontSize="15px"
                  fontWeight="400"
                  color="#d6d8dc"
                >
                  Para excluir esta ordem de compra é preciso estornar o estoque
                  e pagamentos!
                </Box>
              </>
            }
            placement="bottom-end"
            arrow
          >
            <Box position="absolute" width="100%" height="100%" />
          </Styled.Tooltip>
        ) : null}
      </Box>
      <BuyOrderDeleteModal id={buyOrder.id} afterActions={afterActions} />
    </>
  );
};

export default DeleteBuyOrder;
