import React, { FC } from "react";

import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import Panel from "src/components/Layouts/Panel";
import Typography from "src/components/Typography";
import { useFinancial } from "src/hooks/financial/useFinancial";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const SectionPendenciesCard: FC = () => {
  const { financialOverview } = useFinancial();

  return (
    <Grid item xs={12}>
      <Box marginBottom={2}>
        <Typography variant="subtitle1" color="textSecondary">
          Pêndencias
        </Typography>
      </Box>
      <Box width="100%" display="flex" gridGap={24} marginBottom={2}>
        <Panel width="50%" padding={2} display="flex" flexWrap="wrap">
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            mb={3}
          >
            <Styled.LabelPendencies>
              Receitas
              <br />
              Pendentes
            </Styled.LabelPendencies>
            <Styled.QuantityPendencies as="span">
              {financialOverview.incomePendencies?.quantity}
            </Styled.QuantityPendencies>
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Styled.PendenciesValue>
              <Styled.PendenciesValue as="span">Valor</Styled.PendenciesValue>
              {formatPrice(financialOverview.incomePendencies?.value)}
            </Styled.PendenciesValue>
            <Styled.IncomePendenciesIcon />
          </Box>
        </Panel>
        <Panel width="50%" padding={2} display="flex" flexWrap="wrap">
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            mb={3}
          >
            <Styled.LabelPendencies>
              Despesas
              <br />
              Pendentes
            </Styled.LabelPendencies>
            <Styled.QuantityPendencies redColor as="span">
              {financialOverview.expensePendencies?.quantity}
            </Styled.QuantityPendencies>
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Styled.PendenciesValue redColor>
              <Styled.PendenciesValue as="span">Valor</Styled.PendenciesValue>
              {formatPrice(financialOverview.expensePendencies?.value)}
            </Styled.PendenciesValue>
            <Styled.ExpensePendenciesIcon />
          </Box>
        </Panel>
      </Box>
    </Grid>
  );
};

export default SectionPendenciesCard;
