import React from "react";

type ContextWithProp = {
  component: React.FC<any>;
  props: {
    [key: string]: any;
  };
};

type WithContextType = <T>(
  Component: React.FC<T>,
  ...Context: Array<React.FC | ContextWithProp>
) => React.FC<T>;

/**
 * @param {*} Wrappered Componente Encapsulado
 * @param {*} Wrapper[]  Componente Encapsulador
 */
export const withContext: WithContextType = (Wrappered, ...Wrappers) => {
  if (Wrappers.length === 0) return () => null;

  return ({ children, ...props }: any) =>
    Wrappers.reduceRight((previusProvider, CurrentProvider) => {
      if (typeof CurrentProvider === "object") {
        const { component: Component, props } = CurrentProvider;
        return <Component {...props}>{previusProvider}</Component>;
      }
      return <CurrentProvider>{previusProvider}</CurrentProvider>;
    }, <Wrappered {...props}>{children}</Wrappered>);
};
