import React, { useEffect } from "react";

import DashboardLayout from "src/components/Layouts/Dashboard";
import { OrderProvider } from "src/hooks/order/useOrder";
import { ViewOrderProvider } from "src/hooks/order/useViewOrder";
import { OrderTypeProvider, useOrderType } from "src/pages/Orders/useOrderType";
import { withContext } from "src/utils/withContext";

import MainContent from "../components/MainContent";

const AddOrderService: React.FC = () => {
  const { setType } = useOrderType();

  useEffect(() => {
    setType("serviceOrder");
  }, []);

  return (
    <DashboardLayout
      selected="sales"
      subSelected="service-order"
      modules={["serviceOrder"]}
    >
      <MainContent type="serviceOrder" />,
    </DashboardLayout>
  );
};

export default withContext(
  AddOrderService,
  OrderProvider,
  OrderTypeProvider,
  ViewOrderProvider
);
