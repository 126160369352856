import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";

import { useOrder } from "src/hooks/order/useOrder";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const DetailsBottomBar: React.FC = () => {
  const { order } = useOrder();

  const discount = useMemo(() => {
    const discountValue = Number(order?.discount || 0);

    if (order.discountType === "money") {
      return discountValue;
    }

    if (order.discountType === "percentage") {
      return (discountValue * order.grossAmount) / 100;
    }

    return 0;
  }, [order.discountType, order.discount]);

  return (
    <Styled.AppBar position="fixed" component="footer">
      <Styled.Container>
        <Box display="flex">
          <Box mr={4}>
            <Styled.LabelData>Subtotal</Styled.LabelData>
            <Styled.ValueData>
              {formatPrice(order.grossAmount)}
            </Styled.ValueData>
          </Box>
          <Box pr={4} mr={4} borderRight="solid #D6D8DC 1px">
            <Styled.LabelData>
              Desconto
              <Styled.LabelIconMinus />
            </Styled.LabelData>
            <Styled.ValueData isRed>{formatPrice(discount)}</Styled.ValueData>
          </Box>
          <Box>
            <Styled.LabelTotalValue>
              Valor total
              <Styled.LabelIconEquals />
            </Styled.LabelTotalValue>
            <Styled.ValueData>
              {formatPrice(order.totalAmount)}
            </Styled.ValueData>
          </Box>
        </Box>
      </Styled.Container>
    </Styled.AppBar>
  );
};

export default DetailsBottomBar;
