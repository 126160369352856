import React, { useMemo } from "react";

import Grid from "@material-ui/core/Grid";

import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { formatPhone } from "src/utils/format";

import * as Styled from "./styles";

const ContactData: React.FC = () => {
  const { buyOrder } = useBuyOrder();

  const getLabelValue = (value?: string) => {
    if (value) return value;

    return "-";
  };

  const address = useMemo(() => {
    const provider = buyOrder?.provider;

    if (!provider) return "-";

    const street = provider.street ? `${provider.street}, ` : "";
    const number = provider.number ? `${provider.number}, ` : "";
    const complement = provider.complement ? `${provider.complement}, ` : "";
    const neighborhood = provider.neighborhood
      ? `${provider.neighborhood}, `
      : "";
    const city = provider.city ? `${provider.city}` : "";

    if (!street && !number && !complement && !neighborhood && !city) return "-";

    return `${street}${number}${neighborhood}${city}${complement}`;
  }, [buyOrder?.provider]);

  return (
    <>
      <Grid item>
        <Styled.LabelText>Cliente</Styled.LabelText>
        <Styled.LabelValue>
          {getLabelValue(buyOrder?.provider?.name)}
        </Styled.LabelValue>
      </Grid>

      <Grid item>
        <Styled.LabelText>Telefone</Styled.LabelText>
        <Styled.LabelValue>
          {buyOrder?.provider?.phone
            ? formatPhone(buyOrder?.provider?.phone)
            : "-"}
        </Styled.LabelValue>
      </Grid>
      {buyOrder?.provider?.alternativePhones?.length ? (
        <Grid item>
          <Styled.LabelText>Telefones alternativos</Styled.LabelText>
          <Styled.LabelValue>
            {buyOrder?.provider?.alternativePhones
              ?.map(phone => formatPhone(phone.number))
              ?.join(", ")}
          </Styled.LabelValue>
        </Grid>
      ) : null}
      <Grid item>
        <Styled.LabelText>Endereço</Styled.LabelText>
        <Styled.LabelValue>{address}</Styled.LabelValue>
      </Grid>
      <Grid item>
        <Styled.LabelText>Cidade</Styled.LabelText>
        <Styled.LabelValue>
          {getLabelValue(buyOrder?.provider?.city)}
        </Styled.LabelValue>
      </Grid>
    </>
  );
};

export default ContactData;
