import styled from "styled-components";

import Box from "@material-ui/core/Box";

export const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px #d6d8dc solid;
  box-shadow: none;
  padding: 30px;
  min-height: 250px;
`;

export const PreviewPhoto = styled.img`
  width: 100%;
  height: 150px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  object-fit: cover;
`;
