import React from "react";
import { toast } from "react-toastify";

import { Formik } from "formik";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import {
  CreateBuyOrderProvider,
  useCreateBuyOrder,
} from "src/hooks/buy_order/useCreateBuyOrder";
import { BuyOrderFormType, BuyOrderType } from "src/interfaces/buyOrder";
import { withContext } from "src/utils/withContext";

import BuyOrderForm from "../components/BuyOrderForm";
import AddBuyOrderBottomBar from "./components/BottomBar";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Produtos" },
  { label: "Ordens de compra" },
  { label: "Nova ordem de compra" },
];

const AddBuyOrderPage: React.FC = () => {
  const {
    createBuyOrder,
    createBuyOrderStatus,
    productsBuyOrder,
    setProductsBuyOrder,
    discount,
    setDiscount,
    provider,
    setProvider,
  } = useCreateBuyOrder();

  const { setRestartOrderList } = useBuyOrderFilters();

  const handleCreateBuyOrder = (data: BuyOrderFormType) => {
    if (productsBuyOrder.length === 0) {
      toast.error("Adicione no mínimo um produto!");
      return;
    }

    if (!provider?.id) {
      toast.error("Adicione no mínimo um fornecedor!");
      return;
    }

    createBuyOrder(data);
    setRestartOrderList(true);
  };

  const renderContent = () => {
    if (createBuyOrderStatus.loading) {
      return <Loader />;
    }

    return (
      <>
        <BuyOrderForm
          productsBuyOrder={productsBuyOrder}
          setProductsBuyOrder={setProductsBuyOrder}
          discount={discount}
          setDiscount={setDiscount}
          provider={provider}
          setProvider={setProvider}
          method="post"
          buyOrder={{} as BuyOrderType}
        />
        <AddBuyOrderBottomBar />
      </>
    );
  };

  return (
    <DashboardLayout
      selected="register"
      subSelected="products"
      modules={["stock", "buyorder"]}
    >
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <PageTitle label="Nova ordem de compra" mt={0} mb={3} />
      <Formik
        onSubmit={handleCreateBuyOrder}
        initialValues={
          {
            additionalInfo: "",
            discount: undefined as unknown,
            discountType: undefined as unknown,

            downPaymentType: "percentage",
            downPaymentValue: 100,
            remainingPaymentType: undefined,
            remainingPaymentValue: undefined,

            products: [],

            paymentType: "A Vista",

            deadline: undefined,
            createdDate: undefined,
          } as BuyOrderFormType
        }
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {renderContent()}
      </Formik>
    </DashboardLayout>
  );
};

export default withContext(AddBuyOrderPage, CreateBuyOrderProvider);
