import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { FinancialAccountType } from "src/interfaces/financialAccount";
import api from "src/services/api";

export interface UpdateTransferParams {
  id: string;
  title: string;
  description?: string;
}

export const updateTransfer = (params: UpdateTransferParams) =>
  api
    .put<FinancialAccountType>(`v1/transfer/${params.id}`, params)
    .then(res => res);

export function useUpdateTransfer(
  config?: UseMutationOptions<any, any, UpdateTransferParams, unknown>
) {
  return useMutation<any, any, UpdateTransferParams>(
    (data: UpdateTransferParams) => updateTransfer(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ??
            "Ocorreu um problema ao atualizar lançamento lançamento"
        );
      },
      ...config,
    }
  );
}
