import React from "react";

import {
  Button,
  Circle,
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import {
  COLOR_PAYMENT_STATUS,
  NAME_PAYMENT_STATUS,
} from "src/constants/orders";
import { RECORD_STATUS } from "src/constants/record";
import { OrderListType } from "src/interfaces/order";
import { PaymentMethodType } from "src/interfaces/payment";
import { PaidStatusType, RecordType } from "src/interfaces/record";
import { formatPrice } from "src/utils/format";

interface PaymentColumnType {
  order: OrderListType;
  handlePayRecord: (value: RecordType) => void;
}

export function PaymentColumn({ order, handlePayRecord }: PaymentColumnType) {
  const isPaid = String(
    order.isPaid ?? null
  ) as keyof typeof COLOR_PAYMENT_STATUS;

  return (
    <Flex alignItems="center" gap={0.5}>
      <Popover>
        <PopoverTrigger>
          <Button
            size="sm"
            variant="ghost"
            color={COLOR_PAYMENT_STATUS[isPaid]}
            iconSpacing={1}
            px={1}
            pl={2}
            rightIcon={
              <Icon boxSize="18px" color={COLOR_PAYMENT_STATUS[isPaid]}>
                <KeyboardArrowDownRoundedIcon />
              </Icon>
            }
          >
            {NAME_PAYMENT_STATUS[isPaid]}
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            minW="200px"
            maxW="min-content"
            boxShadow=" 0px 4px 20px rgba(0, 0, 0, 0.25)"
            _focus={{ boxShadow: " 0px 4px 20px rgba(0, 0, 0, 0.25)" }}
            borderRadius={10}
            border="none"
          >
            {order.records.map((record, index) => {
              const parcelNumber = index + 1;
              return (
                <Button
                  variant="ghost"
                  key={record.id}
                  size="md"
                  borderRadius={0}
                  isDisabled={record.paidStatus === "paid"}
                  onClick={() => handlePayRecord(record)}
                  sx={{
                    "&:first-child": {
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    },
                    "&:last-child": {
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    },
                  }}
                  fontSize="sm"
                  justifyContent="flex-start"
                  px={2}
                  color="black"
                >
                  <Circle
                    size="10px"
                    bg={
                      RECORD_STATUS[record.paidStatus as PaidStatusType]?.color
                    }
                    mr={2}
                  />
                  {parcelNumber}ª parcela -{" "}
                  {record.paidStatus === "paid"
                    ? (record?.paymentMethod as PaymentMethodType)?.title
                    : formatPrice(record.value)}
                </Button>
              );
            })}
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  );
}
