import React, { useState, useEffect, useMemo } from "react";

import { Divider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { startOfMonth, endOfMonth, format } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { Form, Formik } from "formik";

import { ReactComponent as ExcelIcon } from "src/assets/icons/reports/excel.svg";
import Button from "src/components/Button";
import SearchByCustomer from "src/components/Inputs/AutoComplete";
import SelectField from "src/components/Inputs/SelectField";
import { MenuItemType } from "src/components/Inputs/SelectField/props";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import PATHS from "src/constants/paths";
import {
  RECORD_FITER_DATE_TYPE,
  RECORD_STATUS,
  RECORD_TYPE,
} from "src/constants/record";
import { ContactProvider, useContact } from "src/hooks/contact/useContact";
import { Can } from "src/hooks/permission/usePermission";
import { ReportProvider, useReport } from "src/hooks/report/useReport";
import { SubCategoryRecordType } from "src/interfaces/categoryRecord";
import { ContactType } from "src/interfaces/contact";
import { FinancialAccountType } from "src/interfaces/financialAccount";
import { PaymentMethodType } from "src/interfaces/payment";
import {
  FieldDateType,
  PaidStatusType,
  RecordTypeType,
  RecordType,
} from "src/interfaces/record";
import { GetReportRecordsParamType } from "src/interfaces/report";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { formatNumber, formatPrice } from "src/utils/format";
import { generateXLSX } from "src/utils/generateXLSX";
import { withContext } from "src/utils/withContext";

import InformationInitialReportState from "../components/InformationInitialReportState";
import RecordsByClientBottomBar from "./components/RecordsByClientBottomBar";
import RecordsTable, {
  renderContactNameWithDocument,
  renderContactEmail,
} from "./components/RecordsTable";
import * as Styled from "./styled";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Lançamentos" },
];

const today = new Date();
const LIMIT = 10;

const renderTypeRecord = (type: string) => {
  if (type === "income") {
    return "Receita";
  }
  if (type === "expense") {
    return "Despesa";
  }
  return "Transferência";
};
const renderTitleRelease = (record?: RecordType) => {
  if (record?.description === "") {
    if (record.order?.type === "serviceOrder") {
      return `OS ${record.order.code}` ?? "-";
    }
    if (record.order?.type === "productOrder") {
      return `Recebimento Pedido ${record.order.code}` ?? "-";
    }
  }

  return record?.description ?? "-";
};

const RecordsByClientPage = () => {
  const { reportRecords, status, getReportRecords } = useReport();

  const [exportLoading, setExportLoading] = useState(false);
  const [statusRecord, setStatusRecord] = useState("");
  const [typeRecord, setTypeRecord] = useState("");
  const [filterFieldDate, setFilterFieldDate] = useState("");
  const [options, setOptions] = useState<ContactType[]>([]);
  const [customer, setCustomer] = useState<ContactType | undefined>(undefined);
  const { list, contacts, status: contactStatus } = useContact();

  const [startDate, setStartDate] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(endOfMonth(today));

  const [startDateLegend, setStartDateLegend] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDateLegend, setEndDateLegend] = useState<Date | undefined>(
    endOfMonth(today)
  );

  useEffect(() => {
    setOptions(contacts.contacts || []);
  }, [contacts]);

  const handleGenerateReport = () => {
    setStartDateLegend(startDate);
    setEndDateLegend(endDate);
    getReportRecords({
      startDate: formatStartDateISO(startDate || today),
      endDate: formatEndDateISO(endDate || today),
      contactId: customer?.id ?? "",
      paidStatus: statusRecord,
      type: typeRecord,
      filterFieldDate,
    } as GetReportRecordsParamType);
  };

  const searchQuery = (query: string) => {
    if (query.length) {
      list({ search: query, type: "customer", limit: LIMIT });
    } else {
      list({ type: "customer", limit: LIMIT });
    }
  };

  const initialRequest = () => list({ type: "customer", limit: LIMIT });

  const exportToXLSX = async () => {
    if (exportLoading) return;
    if (reportRecords?.result?.length) {
      setExportLoading(true);

      const header = [
        "Cliente",
        "E-mail",
        "Data de emissão",
        "Tipo",
        "Data de pagamento",
        "Categoria",
        "Forma de pagamento",
        "Status",
        "Valor",
        "Conta",
        "Título do lançamento",
      ];

      const data = reportRecords.result.reduce<string[][]>((data, record) => {
        const row = [
          renderContactNameWithDocument(record.order?.contact),
          renderContactEmail(record.order?.contact),
          record.emissionDate
            ? format(new Date(record?.emissionDate), "dd/MM/yyyy")
            : "-",
          renderTypeRecord(record.type),
          record.paidDate
            ? format(new Date(record.paidDate), "dd/MM/yyyy")
            : "-",
          typeof record.category === "object"
            ? (record.category as SubCategoryRecordType)?.name
            : "-",
          typeof record.paymentMethod === "object"
            ? (record.paymentMethod as PaymentMethodType)?.title
            : "-",
          record.paidStatus === "paid" ? "Pago" : "Não pago",
          formatPrice(record.value),
          typeof record.financialAccount === "object"
            ? (record.financialAccount as FinancialAccountType)?.name
            : "-",
          renderTitleRelease(record),
        ] as Array<string>;

        data.push(row);

        return data;
      }, []);

      const footer = [
        ["", "", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "Quantidade", "Despesa", "Receita", "Total"],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          `${reportRecords?.totals?.count ?? 0}`,
          formatNumber(reportRecords?.totals?.totalExpense ?? 0),
          formatNumber(reportRecords?.totals?.totalIncome ?? 0),
          formatNumber(reportRecords?.totals?.totalAmount ?? 0),
        ],
      ];

      await generateXLSX(
        header,
        [...data, ...footer],
        "Lançamentos",
        "Lançamentos.xlsx"
      );

      setExportLoading(false);
    }
  };

  const paidStatusItems = useMemo(() => {
    const items: MenuItemType[] = Object.keys(RECORD_STATUS).map(status => ({
      name: RECORD_STATUS[status as PaidStatusType].name,
      value: status,
    }));

    const parsedItems = items.filter(item => item.name !== "Cancelado");

    return [{ name: "Todos", value: "" }, ...parsedItems];
  }, [RECORD_STATUS]);

  const typeRecordItems = useMemo(() => {
    const items: MenuItemType[] = Object.keys(RECORD_TYPE).map(type => ({
      name: RECORD_TYPE[type as RecordTypeType],
      value: type,
    }));

    return [{ name: "Todos", value: "" }, ...items];
  }, [RECORD_TYPE]);

  const fieldDataRecordItems = useMemo(() => {
    const items: MenuItemType[] = Object.keys(RECORD_FITER_DATE_TYPE).map(
      type => ({
        name: RECORD_FITER_DATE_TYPE[type as FieldDateType],
        value: type,
      })
    );

    return [{ name: "Todos", value: "" }, ...items];
  }, [RECORD_TYPE]);

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Lançamentos" />
      <Formik
        // eslint-disable-next-line no-console
        onSubmit={v => console.log(v)}
        initialValues={{
          statusRecord,
          typeRecord,
          filterFieldDate,
        }}
      >
        {({ values }) => (
          <Form>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="flex-end"
              width="100%"
              style={{ gap: ".5rem" }}
            >
              <PeriodFilterPicker
                startDate={startDate || today}
                endDate={endDate || today}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                initialFilter="byMonth"
                options={["perInterval", "byMonth"]}
                showingInPopover={false}
              />
              <Box width={250}>
                <SearchByCustomer
                  value={customer}
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  onChange={(_, newInputValue, __) => {
                    setCustomer(newInputValue as ContactType);
                  }}
                  id="autocomplete-customer"
                  options={options}
                  setOptions={setOptions}
                  loading={contactStatus.loading}
                  searchQuery={searchQuery}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={option => option.name}
                  initialRequest={initialRequest}
                  searchFieldProps={{ placeholder: "Procurar cliente..." }}
                  filterOptions={options => options}
                />
              </Box>
              <Box width={250}>
                <SelectField
                  label="Status"
                  name="statusRecord"
                  items={paidStatusItems}
                  defaultValue=""
                  backgroundwhite
                />
                {setStatusRecord(values.statusRecord)}
              </Box>
              <Box width={200}>
                <SelectField
                  label="Tipo"
                  name="typeRecord"
                  items={typeRecordItems}
                  defaultValue=""
                  backgroundwhite
                />
                {setTypeRecord(values.typeRecord)}
              </Box>
              <Box width={200}>
                <SelectField
                  label="Filtrar por data"
                  name="filterFieldDate"
                  items={fieldDataRecordItems}
                  defaultValue=""
                  backgroundwhite
                />
                {setFilterFieldDate(values.filterFieldDate)}
              </Box>
              <Box mt={1} display="flex" width="100%" justifyContent="flex-end">
                <Button
                  style={{ height: 56, marginLeft: 8 }}
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={handleGenerateReport}
                >
                  Gerar relatório
                </Button>
                <Can subject="reportFinancialRecords" action="export">
                  <Button
                    style={{ height: 56, marginLeft: 8, minWidth: 120 }}
                    color="primary"
                    variant="outlined"
                    size="medium"
                    onClick={exportToXLSX}
                    loading={exportLoading}
                  >
                    Exportar
                    <ExcelIcon style={{ marginLeft: 10 }} />
                  </Button>
                </Can>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>

      <Box my={3}>
        <Divider />
      </Box>

      {status.initial ? <InformationInitialReportState /> : null}
      {!status.initial && !status.loading ? (
        <>
          <Styled.Legend color="primary" variant="h6">
            De
            <strong>
              {startDateLegend
                ? format(startDateLegend, " dd/MM/yyyy ", {
                    locale: ptBrLocale,
                  })
                : ""}
            </strong>
            até
            <strong>
              {endDateLegend
                ? format(endDateLegend, " dd/MM/yyyy", { locale: ptBrLocale })
                : ""}
            </strong>
          </Styled.Legend>
          <RecordsTable reportRecords={reportRecords} />
          <RecordsByClientBottomBar
            count={reportRecords?.totals?.count ?? 0}
            totalAmount={reportRecords?.totals?.totalAmount ?? 0}
            totalExpense={reportRecords?.totals?.totalExpense ?? 0}
            totalIncome={reportRecords?.totals?.totalIncome ?? 0}
          />
        </>
      ) : null}
      {status.loading ? (
        <Box height="100%">
          <Loader size="medium" />
        </Box>
      ) : null}
    </DashboardLayout>
  );
};

export default withContext(
  RecordsByClientPage,
  ContactProvider,
  ReportProvider
);
