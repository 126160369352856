import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Flex } from "@chakra-ui/react";
import { Divider } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { ModalProvider } from "src/components/Modal/useModal";
import Typography from "src/components/Typography";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import { BuyOrderType } from "src/interfaces/buyOrder";

import DeleteBuyOrder from "../DeleteBuyOrder";
import UpdateQuickBuyOrderModal from "../UpdateQuickBuyOrderModal";

interface MoreOptionsOrderDetailModalProps {
  buyOrder: BuyOrderType;
}

const MoreOptionsBuyOrderDetailModal: React.FC<MoreOptionsOrderDetailModalProps> =
  ({ buyOrder }) => {
    const navigate = useNavigate();
    const { handleOpen } = useBaseSwipeableDrawer();
    const { setBuyOrder } = useBuyOrder();
    const { setRestartOrderList } = useBuyOrderFilters();

    const detaulStyleButton = {
      justifyContent: "flex-start",
      size: "sm",
      variant: "ghost",
      color: "text",
    };

    const backToList = () => {
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    };

    return (
      <BaseSwipeableDrawer
        tag="moreOptionsBuyOrderDetailModal"
        title="Mais opções"
        zIndex={1299}
        dialogHeaderProps={{
          style: {
            paddingBottom: 0,
          },
        }}
        dialogContentProps={{
          style: {
            padding: 16,
          },
        }}
        customActions={<></>}
      >
        <Box px={2} maxWidth="450px">
          <Typography variant="body2" color="textSecondary">
            Selecione abaixo as ações que você deseja realizar
          </Typography>
          <Box mb={2} mt={2}>
            <Divider />
          </Box>
        </Box>
        <Flex direction="column" gap={2}>
          <ModalProvider>
            <DeleteBuyOrder buyOrder={buyOrder} afterActions={backToList} />
          </ModalProvider>
          <Button
            {...detaulStyleButton}
            leftIcon={<Edit color="primary" fontSize="small" />}
            onClick={() => handleOpen("updateQuickBuyOrder")}
          >
            Edição rápida
          </Button>
          <UpdateQuickBuyOrderModal
            buyOrder={buyOrder}
            onSuccess={buyOrderResult => {
              setBuyOrder({
                ...buyOrder,
                deadline: buyOrderResult.deadline,
                additionalInfo: buyOrderResult.additionalInfo,
              });
              setRestartOrderList(true);
            }}
          />
        </Flex>
      </BaseSwipeableDrawer>
    );
  };

export default MoreOptionsBuyOrderDetailModal;
