import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { FinancialAccountType } from "src/interfaces/financialAccount";
import api from "src/services/api";

interface ActiveFinancialAccountsType {
  status: "enabled";
  search?: string;
}

export const getActiveFinancialAccountsWithBalance = (
  params: ActiveFinancialAccountsType
) =>
  api
    .get<FinancialAccountType[]>("v1/financial-account-with-balance", {
      params,
    })
    .then(res => res.data);

export function useActiveFinancialAccountsWithBalance(
  search?: string,
  config?: UseQueryOptions<
    FinancialAccountType[],
    Error,
    FinancialAccountType[],
    unknown[]
  >
) {
  const params: ActiveFinancialAccountsType = {
    status: "enabled",
    search,
  };

  return useQuery<
    FinancialAccountType[],
    Error,
    FinancialAccountType[],
    unknown[]
  >(
    ["activeFinancialAccountsWithBalance", params],
    () => getActiveFinancialAccountsWithBalance(params),
    {
      refetchOnWindowFocus: false,
      ...(config || {}),
      initialData: [] as FinancialAccountType[],
    }
  );
}
