import React, { useEffect, useState } from "react";

import { Text } from "@chakra-ui/react";
import { Box } from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import { differenceInDays, format } from "date-fns";

import Button from "src/components/Button";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import { SelectedFilterType } from "src/components/PeriodFilterPicker/props";
import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { useProduct } from "src/hooks/product/useProduct";
import { useLots } from "src/services/hooks/product/useLots";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { formatPrice } from "src/utils/format";
import { generateLocaleGridText } from "src/utils/localGridText";

interface EmptyTableContentProps {
  isFetched: boolean;
}

const EmptyTableContent: React.FC<EmptyTableContentProps> = ({ isFetched }) => {
  return (
    <div>
      <Typography variant="subtitle1">
        {isFetched
          ? "Você não tem lotes deste produto"
          : "Pressione o botão de Filtrar para carregar os dados"}
      </Typography>
    </div>
  );
};

const today = new Date();

const Movements: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [periodFilterType, setPeriodFilterType] =
    useState<SelectedFilterType>("empty");

  const { product } = useProduct();

  const {
    isLoading,
    isFetching,
    isFetched,
    data: { movements, totalCount },
    refetch,
  } = useLots(
    {
      limit,
      page,
      id: product.id,
      startDate:
        periodFilterType !== "empty" && startDate
          ? formatStartDateISO(startDate)
          : undefined,
      endDate:
        periodFilterType !== "empty" && endDate
          ? formatEndDateISO(endDate)
          : undefined,
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [page, limit]);

  const columns = [
    {
      field: "buyOrder.code",
      headerName: "Origem",
      renderCell: (params: any) =>
        params.row?.buyOrder?.number
          ? `OC ${params.row?.buyOrder?.number}`
          : "-",
      flex: 2,
    },
    {
      field: "createdAt",
      headerName: "Data/Hora",
      renderCell: (params: any) =>
        params.row?.createdAt
          ? format(new Date(params.row?.createdAt), "dd/MM/yyyy HH:mm")
          : "-",
      sortComparator: (v1: string, v2: string) => {
        const date = (v1 as string)?.toLowerCase() ?? "-";
        const dateComparable = (v2 as string)?.toLowerCase() ?? "-";

        if (date < dateComparable) {
          return -1;
        }
        if (date > dateComparable) {
          return 1;
        }
        return 0;
      },
      width: 150,
    },
    {
      field: "validity",
      headerName: "Validade",
      renderCell: ({ row }: any) => {
        const validityFormatted = row?.validity
          ? format(new Date(row?.validity), "dd/MM/yyyy")
          : "-";

        if (row?.validity) {
          let color = "#000000DE";

          const differenceInDaysOfValidity = differenceInDays(
            new Date(row?.validity),
            new Date()
          );

          if (
            differenceInDaysOfValidity >= 0 &&
            differenceInDaysOfValidity <= 7
          ) {
            color = "error.500";
          } else if (
            differenceInDaysOfValidity >= 0 &&
            differenceInDaysOfValidity <= 30
          ) {
            color = "yellow.500";
          }

          return (
            <Text fontSize="sm" fontWeight={400} color={color}>
              {validityFormatted}
            </Text>
          );
        }

        return validityFormatted;
      },
      sortComparator: (v1: string, v2: string) => {
        const date = (v1 as string)?.toLowerCase() ?? "-";
        const dateComparable = (v2 as string)?.toLowerCase() ?? "-";

        if (date < dateComparable) {
          return -1;
        }
        if (date > dateComparable) {
          return 1;
        }
        return 0;
      },
      width: 150,
    },
    {
      field: "cost",
      headerName: "Custo",
      renderCell: (params: any) => formatPrice(params.row?.cost),
      flex: 2,
    },
    {
      field: "quantity",
      headerName: "Quantidade",
      flex: 2,
    },
    {
      field: "value",
      headerName: "Valor",
      renderCell: (params: any) =>
        formatPrice((params.row?.cost ?? 0) * (params.row?.quantity ?? 0)),
      flex: 2,
    },
  ] as Array<GridColDef>;

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-end"
        width="100%"
        style={{ gap: ".5rem" }}
        mb={3}
      >
        <PeriodFilterPicker
          startDate={startDate || today}
          endDate={endDate || today}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          initialFilter={periodFilterType}
          setSelectedFilterType={setPeriodFilterType}
          options={["empty", "perInterval", "byMonth"]}
          showingInPopover={false}
        />

        <Box display="flex" flex={1} justifyContent="flex-end">
          <Button
            style={{ height: 56, marginLeft: 8, minWidth: 110 }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => refetch()}
          >
            Filtrar
          </Button>
        </Box>
      </Box>

      <Table
        rows={movements || []}
        columns={columns}
        page={page}
        setPage={setPage}
        pageSize={limit}
        onPageSizeChange={setLimit}
        filterMode="server"
        loading={isLoading || isFetching}
        totalCount={totalCount}
        localeText={generateLocaleGridText("lots")}
        components={{
          NoRowsOverlay: () => (
            <EmptyTable>
              <EmptyTableContent isFetched={isFetched} />
            </EmptyTable>
          ),
        }}
        // error={status.error}
      />
    </>
  );
};

export default Movements;
