import React, { FC, useMemo } from "react";

import { Grid } from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

import Panel from "src/components/Layouts/Panel";
import { useFinancial } from "src/hooks/financial/useFinancial";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const InvoiceChart: FC = () => {
  const { financialOverview } = useFinancial();

  const data = useMemo(() => {
    return financialOverview?.invoicing ?? [];
  }, [financialOverview.invoicing]);

  const widthChart = useMemo(() => {
    return data.length * 45;
  }, [data]);

  const longestLabelLength = useMemo(() => {
    return (
      data
        .map(item => formatPrice(item.value))
        .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0) ?? 10
    );
  }, [data]);

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <Styled.TooltipContent>
          <Styled.TooltipText>
            <strong>{label}</strong>
          </Styled.TooltipText>
          <Styled.TooltipText>
            {formatPrice(payload[0].value)}
          </Styled.TooltipText>
        </Styled.TooltipContent>
      );
    }
    return null;
  };

  return (
    <Grid item xs={12}>
      <Panel padding={2} marginBottom={2}>
        <Styled.ResponsiveContainerOverflow>
          <ResponsiveContainer
            minHeight={300}
            maxHeight={300}
            minWidth={widthChart}
            width="100%"
          >
            <BarChart data={data}>
              <XAxis
                dataKey="label"
                scale="point"
                padding={{ left: 30, right: 30 }}
                fontSize={10}
                tickLine={false}
                stroke="rgba(19, 34, 62, 0.7)"
              />
              <YAxis
                fontSize={10}
                stroke="rgba(19, 34, 62, 0.7)"
                tickFormatter={formatPrice}
                tickLine={false}
                axisLine={false}
                width={longestLabelLength * 7}
              />
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid
                stroke="#ccc"
                strokeDasharray=".5 .5"
                vertical={false}
              />
              <Bar
                barSize={40}
                dataKey="totalIncome"
                fill="#1A5DF2"
                radius={[10, 10, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Styled.ResponsiveContainerOverflow>
      </Panel>
    </Grid>
  );
};

export default InvoiceChart;
