import styled from "styled-components";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import ButtonComponent from "src/components/Button";
import Typography from "src/components/Typography";

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px #d6d8dc solid;
  box-shadow: none;
  padding: 16px 24px 24px 24px;
  min-height: 130px;
  margin-top: 1.5rem;
`;

export const TitleText = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 14px;
  margin-bottom: 5px;
`;

export const Text = styled(Typography)<{ paid?: boolean }>`
  color: ${({ paid, theme }) =>
    paid ? theme.palette.grey[500] : theme.palette.common.black};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 14px;
`;
export const Button = styled(ButtonComponent)`
  height: 50px;
`;

export const LineRecord = styled(Grid)`
  margin-top: 5px;
  margin-bottom: 5px;
  height: 30px;
  display: flex;
  align-items: center;
`;

export const IndicatorRecord = styled.div<{ color: string; paid?: boolean }>`
  opacity: ${({ paid }) => (paid ? 0.5 : 1)};
  background-color: ${({ color }) => color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const GridContainer = styled.div`
  display: flex;
  gap: 24px;
  border-bottom: solid rgba(179, 182, 188, 0.3) 1px;
  margin-bottom: 16px;
  padding-bottom: 16px;
`;

export const LabelText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  color: #73757a;
`;

export const LabelValue = styled(Typography)`
  font-weight: 500;
  font-size: 19px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
