import { ComponentStyleConfig, theme } from "@chakra-ui/react";

export const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      color: "primary.500",
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: "rgba(19, 34, 62, 0.25)",
        borderRadius: 10,
        "&:not(:placeholder-shown)": {
          borderColor: "primary.500",
          color: "primary.500",
        },
        _disabled: {
          opacity: 0.5,
        },
        _focus: {
          borderColor: "primary.500",
          boxShadow: "none",
        },
        _hover: {
          borderColor: "primary.600",
        },
        _invalid: {
          borderColor: "error.500",
          boxShadow: "none",
          color: "error.500",
          "&:not(:placeholder-shown)": {
            color: "error.500",
            borderColor: "error.500",
          },
          _focusVisible: {
            boxShadow: "none",
            borderColor: "error.500",
          },
        },
      },
    },
    outlineWhite: {
      field: {
        ...theme.components.Input.variants.outline,
        bg: "transparent",
        border: "solid 1px",
        borderColor: "white",
        color: "white",
        borderRadius: 10,
        "&:not(:placeholder-shown)": {
          borderColor: "white",
          color: "white",
        },
        _disabled: {
          opacity: 0.5,
        },
        _focus: {
          borderColor: "white",
          boxShadow: "none",
        },
        _hover: {
          borderColor: "white",
        },
        _invalid: {
          borderColor: "error.500",
          boxShadow: "none",
          color: "error.500",
          "&:not(:placeholder-shown)": {
            color: "error.500",
            borderColor: "error.500",
          },
          _focusVisible: {
            boxShadow: "none",
            borderColor: "error.500",
          },
        },
      },
    },
  },
  sizes: {
    lg: {
      field: {
        fontSize: "md",
      },
    },
  },
  defaultProps: {
    size: "lg",
  },
};
