import React, { useMemo } from "react";
import ReactSelect, {
  components,
  MultiValueGenericProps,
  GroupProps,
} from "react-select";

import {
  Spinner,
  Text,
  Flex,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";

import { isGroupOptions, Option } from "src/interfaces/common";

import { SelectProps } from "./props";
import { getSelectStyle } from "./style";

const MultiValueLabel = (props: MultiValueGenericProps<unknown>) => {
  const { children } = props;
  return (
    <components.MultiValueLabel {...props}>
      <Flex gap={1.5}>{children}</Flex>
    </components.MultiValueLabel>
  );
};

const GroupValueLabel = (props: GroupProps<unknown>) => {
  const { children } = props;
  return (
    <components.Group {...props}>
      <Flex direction="column" gap={1}>
        {children}
      </Flex>
    </components.Group>
  );
};

export function Select({
  error,
  isMulti = false,
  isClearable = true,
  isLoading = false,
  isDisabled = false,
  autoFocus = false,
  placeholder,
  showFloatingLabel = true,
  noOptionsMessage = "Sem opções",
  errorMessage,
  options,
  onInputChange,
  onChange,
  onMenuOpen,
  value,
  formatOptionLabel,
  filterOption,
  ...props
}: SelectProps) {
  const styles = getSelectStyle({
    error,
    placeholder,
    showFloatingLabel,
  });

  const valueSelected = useMemo(() => {
    if (!options.length || value === undefined) return null;

    if (typeof value === "string") {
      return (
        (isGroupOptions(options)
          ? options.map(group => group.options).flat()
          : options
        ).find(option => option.value === value) ?? null
      );
    }
    return (
      (isGroupOptions(options)
        ? options.map(group => group.options).flat()
        : options
      )?.filter(option => value?.includes(option.value)) ?? null
    );
  }, [options, value]);

  return (
    <FormControl isInvalid={error}>
      <ReactSelect
        isClearable={isClearable}
        isMulti={isMulti}
        isLoading={isLoading}
        isDisabled={isDisabled}
        styles={styles}
        placeholder=""
        noOptionsMessage={() => noOptionsMessage}
        classNamePrefix="azulou_select"
        menuPortalTarget={document.body}
        autoFocus={autoFocus}
        filterOption={filterOption}
        components={{
          // Menu: children => <Portal>{children}</Portal>,
          IndicatorSeparator: () => null,
          Placeholder: () => null,
          MultiValueLabel,
          Group: GroupValueLabel,
          LoadingIndicator: () => <Spinner color="primary.500" />,
          NoOptionsMessage: noOptionsMessage
            ? () => (
                <Text
                  textAlign="center"
                  fontWeight={500}
                  fontSize="var(--chakra-fontSizes-md)"
                  color="gray.300"
                >
                  {noOptionsMessage}
                </Text>
              )
            : undefined,
          LoadingMessage: () => (
            <Text
              textAlign="center"
              fontWeight={500}
              fontSize="var(--chakra-fontSizes-md)"
              color="gray.300"
            >
              Carregando...
            </Text>
          ),
        }}
        onInputChange={onInputChange}
        onMenuOpen={onMenuOpen}
        options={options}
        formatOptionLabel={formatOptionLabel}
        onChange={option => {
          if (Array.isArray(option)) {
            onChange((option as Option[]).map(({ value }) => value));
          } else {
            onChange(option ? (option as Option).value : "");
          }
        }}
        value={valueSelected}
        {...props}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
