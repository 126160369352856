import React, { useEffect, useMemo, useState } from "react";

import { GridColDef, GridValueFormatterParams } from "@material-ui/data-grid";

import { ModalProvider } from "src/components/Modal/useModal";
import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import { PRODUCTS_MEASURES } from "src/constants/measures";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { useProduct } from "src/hooks/product/useProduct";
import { ProductMeasureType, ProductType } from "src/interfaces/product";
import { formatPrice } from "src/utils/format";
import { generateLocaleGridText } from "src/utils/localGridText";

import MoreOptionsMenu from "../MoreOptionsMenu";
import RenderQuantityCell from "./RenderQuantityCell";
import * as Styled from "./styles";

const renderOptionsButton = (params: GridValueFormatterParams) => (
  <ModalProvider>
    <MoreOptionsMenu id={params.id as string} />
  </ModalProvider>
);

const renderValue = (params: GridValueFormatterParams) =>
  formatPrice(params.value as number);

const renderCost = (params: GridValueFormatterParams) => (
  <Typography color="error">- {formatPrice(params.value as number)}</Typography>
);

const renderMeasure = (params: GridValueFormatterParams) =>
  PRODUCTS_MEASURES[params.value as ProductMeasureType];

const EmptyTableContent = () => (
  <div>
    <Styled.ProductIcon />
    <Typography variant="subtitle1">
      Você ainda não possui produtos para serem visualizados nesta lista!
    </Typography>
    <Typography variant="subtitle1">
      comece{" "}
      <Styled.MarkedText> cadastrando os seus produtos</Styled.MarkedText>
    </Typography>
  </div>
);

interface ProductTableProps {
  setSelectedRows: (value: string[]) => void;
}

const ProductTable = ({ setSelectedRows }: ProductTableProps) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);

  const { list, products, status } = useProduct();
  const { hasModules } = useModule();
  const { userCompany } = useCompany();

  useEffect(() => {
    list({
      limit,
      page,
    });
  }, [page, limit]);

  const columns = useMemo<GridColDef[]>(() => {
    return hasModules("stock")
      ? [
          {
            field: "name",
            headerName: "Nome do produto",
            flex: 2,
          },
          {
            field: "price",
            headerName: "Valor",
            renderCell: renderValue,
            flex: 2,
          },
          {
            field: "stock",
            headerName: "Quantidade",
            renderCell: ({ row }) => (
              <RenderQuantityCell product={row as ProductType} />
            ),
            flex: 2,
          },
          {
            field: "options",
            headerName: " ",
            renderCell: renderOptionsButton,
            flex: 1,
            align: "right",
          },
        ]
      : [
          {
            field: "name",
            headerName: "Nome do produto",
            flex: 2,
          },
          {
            field: "cost",
            headerName: "Custo",
            renderCell: renderCost,
            flex: 2,
          },
          {
            field: "price",
            headerName: "Preço",
            renderCell: renderValue,
            flex: 2,
          },
          {
            field: "unitOfMeasure",
            headerName: "Medida",
            renderCell: renderMeasure,
            flex: 2,
          },
          {
            field: "description",
            headerName: "Descrição",
            description: "Descrição",
            flex: 3,
          },
          {
            field: "options",
            headerName: " ",
            renderCell: renderOptionsButton,
            flex: 1,
            align: "right",
          },
        ];
  }, [userCompany]);

  return (
    <Table
      rows={products.products || []}
      columns={columns}
      page={page}
      setPage={setPage}
      pageSize={limit}
      onPageSizeChange={setLimit}
      filterMode="server"
      loading={status.loading}
      totalCount={products.totalCount}
      localeText={generateLocaleGridText("product")}
      detailLink={PATHS.PRODUCTS_DETAIL}
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <EmptyTableContent />
          </EmptyTable>
        ),
      }}
      checkboxSelection
      onSelectionModelChange={selectionModel => {
        setSelectedRows(selectionModel as Array<string>);
      }}
      // error={status.error}
    />
  );
};

export default ProductTable;
