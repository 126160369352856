import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import Button from "src/components/Button";
import BottomBar from "src/components/Layouts/BottomBar";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Panel from "src/components/Layouts/Panel";
import PATHS from "src/constants/paths";
import { useContact } from "src/hooks/contact/useContact";
import { getRouteString } from "src/utils/urlHandler";

import ContactForm from "../components/ContactForm";

const ContactAdd: React.FC = () => {
  const { createStatus, contact } = useContact();

  const navigate = useNavigate();

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
    { label: "Contatos", path: PATHS.CONTACTS_LIST },
    { label: "Criação de contato" },
  ];

  useEffect(() => {
    if (createStatus.success && contact?.id) {
      navigate(getRouteString(PATHS.CONTACTS_DETAIL, contact.id), {
        replace: true,
      });
    }
  }, [createStatus]);

  return (
    <DashboardLayout selected="register" subSelected="contacts">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />
      <PageTitle label="Criação de contato" />

      <Panel padding={4}>
        <ContactForm method="add" />
      </Panel>

      <BottomBar>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          form="contactForm"
          disabled={createStatus.loading}
        >
          Criar
        </Button>

        <Box width={20} />

        <Button color="primary" variant="outlined" to={PATHS.CONTACTS_LIST}>
          Cancelar
        </Button>
      </BottomBar>
    </DashboardLayout>
  );
};

export default ContactAdd;
