import React, { useState, useEffect } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormikContext } from "formik";

import { useFinancialAccount } from "src/hooks/financialAccount/useFinancialAccount";
import {
  FinancialAccountType,
  FinancialInstitutionType,
} from "src/interfaces/financialAccount";

import * as Styled from "./styles";

interface InstitutionFieldProps {
  required?: boolean;
  disabled?: boolean;
}

interface FinancialInstitutionTypeProp extends FinancialInstitutionType {
  firstLetter: string;
}

const InstitutionField: React.FC<InstitutionFieldProps> = props => {
  const { required, disabled } = props;
  const [options, setOptions] = useState<FinancialInstitutionTypeProp[]>([]);

  const [institution, setInstitution] = useState(
    {} as FinancialInstitutionTypeProp
  );

  const { institutions } = useFinancialAccount();

  const { values, setFieldValue } =
    useFormikContext<Partial<FinancialAccountType>>();

  useEffect(() => {
    if (values.institution) {
      const institution = values.institution as FinancialInstitutionTypeProp;

      if (institution?.id) {
        setInstitution(institution);
      }
    } else {
      setInstitution({} as FinancialInstitutionTypeProp);
    }
  }, [values.institution]);

  useEffect(() => {
    setOptions(
      institutions.map(option => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      })
    );
  }, [institutions]);

  return (
    <Autocomplete
      id="autocomplete-institution"
      onChange={(_, newInputValue) => {
        setInstitution(newInputValue as FinancialInstitutionTypeProp);
        setFieldValue("institution", newInputValue?.id);
      }}
      options={options.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      )}
      groupBy={option => option.firstLetter}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      renderOption={option => (
        <>
          <Styled.Logo src={option.image} loading="eager" />
          {option.name}
        </>
      )}
      value={values.institution as FinancialInstitutionTypeProp}
      disabled={disabled}
      renderInput={params => (
        <Styled.SearchField
          {...params}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: institution?.image ? (
              <Styled.Logo src={institution.image} loading="eager" />
            ) : null,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
          placeholder="Escolher instituição financeira"
          required={required}
        />
      )}
    />
  );
};

export default InstitutionField;
