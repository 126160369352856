import { RequestStatusType } from "src/interfaces/request";

export enum StatusUserActionEnum {
  CREATE = "CREATE",
  LIST = "LIST",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  ACTIVE = "ACTIVE",
}

interface StatusUserAction {
  type: StatusUserActionEnum;
  payload: RequestStatusType;
}

interface StatusUserState {
  createStatus: RequestStatusType;
  updateStatus: RequestStatusType;
  listStatus: RequestStatusType;
  deleteStatus: RequestStatusType;
  activeStatus: RequestStatusType;
}

export function statusUserReducer(
  state: StatusUserState,
  action: StatusUserAction
) {
  const { type, payload } = action;
  switch (type) {
    case StatusUserActionEnum.CREATE:
      return {
        ...state,
        createStatus: payload,
      };
    case StatusUserActionEnum.LIST:
      return {
        ...state,
        listStatus: payload,
      };
    case StatusUserActionEnum.UPDATE:
      return {
        ...state,
        updateStatus: payload,
      };
    case StatusUserActionEnum.DELETE:
      return {
        ...state,
        deleteStatus: payload,
      };
    case StatusUserActionEnum.ACTIVE:
      return {
        ...state,
        activeStatus: payload,
      };
    default:
      return state;
  }
}
