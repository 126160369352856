import React, { createContext, useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import {
  CashFlowControlSettingType,
  GetCompanyModulesResponseType,
  GetModulesResponseType,
  ModuleSlugType,
  ProductOrderSettingType,
  ServiceOrderSettingType,
} from "src/interfaces/module";
import { ListParamsType } from "src/interfaces/request";
import {
  getCompanyModules,
  getModules,
  patchSettingsModule,
  subscribeModule,
  unsubscribeModule,
} from "src/services/module";

import { useCompany } from "../company/useCompany";
import { useAuth } from "../user/auth/useAuth";
import { ModuleContextType } from "./props";

export const ModuleContext = createContext({} as ModuleContextType);

export const ModuleProvider: React.FC = props => {
  const { children } = props;

  const { user, setUser } = useAuth();

  const [status, setStatus] = useState(STATUS.inital);
  const [subscribingStatus, setSubscribingStatus] = useState(STATUS.inital);
  const [modules, setModules] = useState<GetModulesResponseType>(
    {} as GetModulesResponseType
  );
  const [companyModules, setCompanyModules] =
    useState<GetCompanyModulesResponseType>(
      {} as GetCompanyModulesResponseType
    );

  const { userCompany } = useCompany();

  const listModules = async (params: Partial<ListParamsType>) => {
    try {
      setModules({} as GetModulesResponseType);
      setStatus(STATUS.loading);

      const response: GetModulesResponseType = await getModules(params);

      setModules(response);

      setStatus(STATUS.success);
    } catch (e) {
      setModules({} as GetModulesResponseType);
      setStatus(STATUS.error);
    }
  };

  const listCompanyModules = async () => {
    try {
      setCompanyModules({} as GetCompanyModulesResponseType);
      setStatus(STATUS.loading);

      const response: GetCompanyModulesResponseType = await getCompanyModules();

      setCompanyModules(response);

      setStatus(STATUS.success);
    } catch (e) {
      setCompanyModules({} as GetCompanyModulesResponseType);
      setStatus(STATUS.error);
    }
  };

  const updateSettingsModule = async (
    id: string,
    params:
      | ServiceOrderSettingType
      | ProductOrderSettingType
      | CashFlowControlSettingType
  ) => {
    try {
      setStatus(STATUS.loading);

      const moduleResponse = await patchSettingsModule(id, params);

      const userData = {
        ...user,
        company: {
          ...user.company,
          modules: user.company.modules.map(module => {
            if (module.module.id === moduleResponse.module.id) {
              return moduleResponse;
            }
            return module;
          }),
        },
      };

      setUser(userData);

      setStatus(STATUS.success);

      toast.success("Preferências atualizadas");
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao atualizar as preferências");
    }
  };

  const subscribe = async (id: string) => {
    try {
      setSubscribingStatus(STATUS.loading);

      const companyModule = await subscribeModule(id);

      setSubscribingStatus(STATUS.success);

      toast.success("Módulo instalado");

      listCompanyModules();

      const userData = {
        ...user,
        company: {
          ...user.company,
          modules: [...user.company.modules, companyModule],
        },
      };

      setUser(userData);
    } catch (e: any) {
      setSubscribingStatus(STATUS.error);
      toast.error(
        e?.response?.data?.message ??
          "Ocorreu um problema ao instalar esse módulo"
      );
    }
  };

  const unsubscribe = async (id: string) => {
    try {
      setSubscribingStatus(STATUS.loading);

      await unsubscribeModule(id);

      setSubscribingStatus(STATUS.success);

      toast.success("Módulo removido");

      listCompanyModules();

      const userData = {
        ...user,
        company: {
          ...user.company,
          modules: user.company.modules.filter(
            module => module.module.id !== id
          ),
        },
      };

      setUser(userData);
    } catch (e) {
      setSubscribingStatus(STATUS.error);

      toast.error("Ocorreu um problema ao remover esse módulo");
    }
  };

  const findModule = (module: ModuleSlugType) =>
    userCompany?.modules?.find(item => item.module.slug === module);

  const hasModules = (
    moduleSlug: ModuleSlugType | ModuleSlugType[],
    allModules = false
  ) => {
    if (!userCompany.modules) return false;

    if (moduleSlug === "default") return true;

    if (!Array.isArray(moduleSlug))
      return userCompany.modules.some(item => item.module.slug === moduleSlug);

    if (allModules) {
      return moduleSlug.every(moduleSlugItem => findModule(moduleSlugItem));
    }

    return moduleSlug.some(moduleSlugItem => findModule(moduleSlugItem));
  };

  const serviceOrderModule = useMemo(
    () => findModule("serviceOrder"),
    [userCompany]
  );

  const productOrderModule = useMemo(
    () => findModule("productOrder"),
    [userCompany]
  );

  const buyOrderModule = useMemo(() => findModule("buyorder"), [userCompany]);

  const cashFlowControlModule = useMemo(
    () => findModule("cashflowcontrol"),
    [userCompany]
  );

  return (
    <ModuleContext.Provider
      value={{
        status,
        subscribingStatus,
        modules,
        companyModules,
        listModules,
        listCompanyModules,
        updateSettingsModule,
        subscribe,
        unsubscribe,
        serviceOrderModule,
        hasModules,
        productOrderModule,
        buyOrderModule,
        cashFlowControlModule,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};

export const useModule = () => {
  const context = useContext(ModuleContext);

  if (!context) {
    throw new Error("useModule must be used within a ModuleProvider");
  }

  return context;
};
