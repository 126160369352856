import React from "react";

import Switch from "@material-ui/core/Switch";
import { useFormikContext } from "formik";

import Typography from "src/components/Typography";
import { ServiceOrderSettingType } from "src/interfaces/module";

import { SwitchSettingsOptionProps } from "./props";
import * as Styled from "./styles";

const SwitchSettings: React.FC<SwitchSettingsOptionProps> = props => {
  const { name, label, ...rest } = props;

  const { values, setFieldValue } = useFormikContext<ServiceOrderSettingType>();

  const handleChange = () => {
    setFieldValue(name, !values[name as keyof ServiceOrderSettingType]);
  };

  return (
    <Styled.SwitchSettingsContainer>
      <Typography variant="body2">{label}</Typography>
      <Switch
        checked={!!values[name as keyof ServiceOrderSettingType]}
        onChange={handleChange}
        name={name}
        color="primary"
        inputProps={{ "aria-label": label }}
        {...rest}
      />
    </Styled.SwitchSettingsContainer>
  );
};

export default SwitchSettings;
