import { useState } from "react";

import Tabs from "src/components/Tabs";
import { ItemTabsProps } from "src/components/Tabs/props";

import { CashFlowPermissions } from "./CashFlowPermissions";
import { DashboardPermissions } from "./DashboardPermissions";
import { FinancialPermissions } from "./FinancialPermissions";
import { OrdersPermissions } from "./OrdersPermissions";
import { ReportPermissions } from "./ReportPermissions";

export const PERMISSIONS_TABS: Array<ItemTabsProps> = [
  { key: "", label: "Financeiro" },
  { key: "orders", label: "Vendas" },
  { key: "dashboard", label: "Dashboard" },
  { key: "report", label: "Relatórios" },
  { key: "cashFlow", label: "Caixa" },
];

export function PermissionsSection() {
  const [tab, setTab] = useState("");

  return (
    <Tabs
      tab={tab}
      setTab={setTab}
      ariaLabel="Permissões"
      tabs={PERMISSIONS_TABS}
      tabPanels={[
        <FinancialPermissions key="" />,
        <OrdersPermissions key="orders" />,
        <DashboardPermissions key="dashboard" />,
        <ReportPermissions key="report" />,
        <CashFlowPermissions key="cashFlow" />,
      ]}
    />
  );
}
