import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export interface ProfileUpdateType {
  name: string;
  email: string;
}

export const profileUpdate = (params: ProfileUpdateType) =>
  api.put(`/v1/user/update`, params).then(res => res.data);

export function useProfileUpdate(
  config?: UseMutationOptions<any, any, ProfileUpdateType, unknown>
) {
  return useMutation<any, any, ProfileUpdateType>(
    (data: ProfileUpdateType) => profileUpdate(data),
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao atualizar dados"
        );
      },
      ...config,
    }
  );
}
