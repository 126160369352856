import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import {
  usePopupState,
  bindToggle,
  bindPopover,
} from "material-ui-popup-state/hooks";

import Button from "src/components/Button";
import { ModalProvider, useModal } from "src/components/Modal/useModal";
import RenderModule from "src/components/RenderModule";
import PATHS from "src/constants/paths";
import { useModule } from "src/hooks/module/useModule";
import { OrderListType, TypeOfOrder } from "src/interfaces/order";
import DeleteModal from "src/pages/Orders/components/DeleteModal";
import { DeleteModalProps } from "src/pages/Orders/components/DeleteModal/props";
import { useOrderType } from "src/pages/Orders/useOrderType";
import { getRouteString } from "src/utils/urlHandler";

import DetailOrderModal from "../DetailOrderModal";
import { DetailOrderProps } from "../DetailOrderModal/props";
import { MoreOptionsMenuProps } from "./props";
import * as Styled from "./styles";

const DetailModalOption: React.FC<DetailOrderProps> = props => {
  const { order } = props;

  const { handleOpen } = useModal();

  if (order?.type === "serviceOrder") {
    return (
      <RenderModule modules={["technicalReport"]}>
        <Button fullWidth onClick={handleOpen}>
          Ver laudo
        </Button>
        <DetailOrderModal order={order} />
      </RenderModule>
    );
  }

  return null;
};

const DeleteModalOption: React.FC<
  DeleteModalProps & { canDeleteOrder: boolean; type: TypeOfOrder }
> = props => {
  const { id, afterActions, canDeleteOrder, type } = props;

  const { handleOpen } = useModal();

  return (
    <>
      <Box position="relative" display="flex" alignItems="center">
        <Styled.DeleteButton
          fullWidth
          onClick={() => {
            if (canDeleteOrder) {
              handleOpen();
            }
          }}
          disabled={!canDeleteOrder}
        >
          Excluir
        </Styled.DeleteButton>

        {!canDeleteOrder ? (
          <Styled.Tooltip
            classes={{
              tooltip: "custom__tooltip",
            }}
            title={
              <>
                <Box
                  component="span"
                  fontSize="15px"
                  fontWeight="500"
                  color="#fff"
                >
                  Exclusão desabilitada.
                </Box>
                <Box
                  component="span"
                  fontSize="15px"
                  fontWeight="400"
                  color="#d6d8dc"
                >
                  Para excluir
                  {type === "serviceOrder"
                    ? " esta ordem de serviço "
                    : " este pedido de venda "}
                  é preciso estornar o estoque e pagamentos!
                </Box>
              </>
            }
            placement="bottom-end"
            arrow
          >
            <Styled.TooltipIcon />
          </Styled.Tooltip>
        ) : null}
      </Box>
      <DeleteModal id={id} afterActions={afterActions} />
    </>
  );
};

const MoreOptionsMenu: React.FC<MoreOptionsMenuProps> = props => {
  const { id, order, children } = props;

  const { type } = useOrderType();

  const { hasModules } = useModule();

  const canUpdateOrder =
    (!hasModules("stock") || !order?.stockCreatedAt) &&
    !order?.recordsCreatedAt;

  const updatePath = useMemo(
    () =>
      type === "serviceOrder"
        ? getRouteString(PATHS.SERVICE_ORDER_UPDATE, id, "orderId")
        : getRouteString(PATHS.PRODUCT_ORDER_UPDATE, id, "orderId"),
    [type, id]
  );

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-options-menu-table",
  });

  return (
    <>
      <Button {...bindToggle(popupState)}>{children}</Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
      >
        <Styled.MenuOverlay>
          <Styled.MenuContainer>
            {canUpdateOrder ? (
              <Button fullWidth to={updatePath} onClick={popupState.close}>
                Editar
              </Button>
            ) : null}

            <ModalProvider>
              <DetailModalOption order={order as OrderListType} />
            </ModalProvider>

            <ModalProvider>
              <DeleteModalOption
                id={id}
                afterActions={() => {
                  popupState.close();
                }}
                canDeleteOrder={canUpdateOrder}
                type={type}
              />
            </ModalProvider>
          </Styled.MenuContainer>
        </Styled.MenuOverlay>
      </Popover>
    </>
  );
};

export default MoreOptionsMenu;
