import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@material-ui/core/Box";

import PATHS from "src/constants/paths";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { formatPrice } from "src/utils/format";
import { getRouteString } from "src/utils/urlHandler";

import * as Styled from "./styles";

interface DetailsBuyOrderBottomBarProps {
  canUpdateBuyOrder: boolean;
}

const DetailsBuyOrderBottomBar: React.FC<DetailsBuyOrderBottomBarProps> = ({
  canUpdateBuyOrder,
}) => {
  const { buyOrderId = "" } = useParams<{ buyOrderId: string }>();
  const { buyOrder } = useBuyOrder();
  const navigate = useNavigate();

  const discount = useMemo(() => {
    if (!buyOrder) {
      return 0;
    }

    const discountValue = Number(buyOrder?.discount || 0);

    if (buyOrder.discountType === "money") {
      return discountValue;
    }

    if (buyOrder.discountType === "percentage") {
      return (discountValue * buyOrder.grossAmount) / 100;
    }

    return 0;
  }, [buyOrder]);

  return (
    <Styled.AppBar position="fixed" component="footer">
      <Styled.Container>
        <Box display="flex">
          <Box mr={4}>
            <Styled.LabelData>Subtotal</Styled.LabelData>
            <Styled.ValueData>
              {buyOrder ? formatPrice(buyOrder.grossAmount) : "-"}
            </Styled.ValueData>
          </Box>
          <Box pr={4} mr={4} borderRight="solid #D6D8DC 1px">
            <Styled.LabelData>
              Desconto
              <Styled.LabelIconMinus />
            </Styled.LabelData>
            <Styled.ValueData isRed>{formatPrice(discount)}</Styled.ValueData>
          </Box>
          <Box>
            <Styled.LabelTotalValue>
              Valor total
              <Styled.LabelIconEquals />
            </Styled.LabelTotalValue>
            <Styled.ValueData>
              {buyOrder ? formatPrice(buyOrder.totalAmount) : "-"}
            </Styled.ValueData>
          </Box>
        </Box>
        <Box display="flex">
          <Box mr={1} ml={1}>
            <Styled.Button
              color="primary"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Styled.Button>
          </Box>
          <Box>
            <Styled.Button
              color="primary"
              variant="contained"
              disabled={!canUpdateBuyOrder}
              onClick={() => {
                navigate(
                  getRouteString(
                    PATHS.BUY_ORDER_UPDATE,
                    buyOrderId,
                    "buyOrderId"
                  ),
                  {
                    state: {
                      data: buyOrder,
                    },
                  }
                );
              }}
            >
              Editar
            </Styled.Button>
          </Box>
        </Box>
      </Styled.Container>
    </Styled.AppBar>
  );
};

export default DetailsBuyOrderBottomBar;
