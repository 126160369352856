import React, { FC } from "react";

import { Box } from "@chakra-ui/react";

import Typography from "src/components/Typography";

import { SelectedFilterType } from "../../props";
import * as Styled from "./styles";

interface PeriodFilterOptionsProps {
  options: Array<SelectedFilterType>;
  selectedFilter: SelectedFilterType;
  setSelectedFilter: (selectedFilter: SelectedFilterType) => void;
  showingInPopover?: boolean;
}

const PeriodFilterOptions: FC<PeriodFilterOptionsProps> = ({
  options,
  selectedFilter,
  setSelectedFilter,
  showingInPopover,
}) => {
  const activeButtonVariant = (filter: SelectedFilterType) =>
    selectedFilter === filter ? "contained" : "outlined";
  return (
    <Box>
      <Typography color={showingInPopover ? "textSecondary" : "primary"}>
        Período
      </Typography>
      <Box
        minHeight={20}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        {options.includes("empty") && (
          <Box width={105}>
            <Styled.SelectedFilterButton
              variant={activeButtonVariant("empty")}
              color="primary"
              onClick={() => setSelectedFilter("empty")}
            >
              sem filtro
            </Styled.SelectedFilterButton>
          </Box>
        )}

        {options.includes("byDay") && (
          <Box width={85}>
            <Styled.SelectedFilterButton
              variant={activeButtonVariant("byDay")}
              color="primary"
              onClick={() => {
                setSelectedFilter("byDay");
              }}
            >
              do dia
            </Styled.SelectedFilterButton>
          </Box>
        )}

        {options.includes("byMonth") && (
          <Box width={95}>
            <Styled.SelectedFilterButton
              variant={activeButtonVariant("byMonth")}
              color="primary"
              onClick={() => {
                setSelectedFilter("byMonth");
              }}
            >
              do mês
            </Styled.SelectedFilterButton>
          </Box>
        )}

        {options.includes("perInterval") && (
          <Box width={125}>
            <Styled.SelectedFilterButton
              variant={activeButtonVariant("perInterval")}
              color="primary"
              onClick={() => {
                setSelectedFilter("perInterval");
              }}
            >
              do intervalo
            </Styled.SelectedFilterButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PeriodFilterOptions;
