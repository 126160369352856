import { extendTheme } from "@chakra-ui/react";

import {
  Button,
  FloatingLabel,
  FormError,
  FormLabel,
  Input,
  Text,
  Switch,
  Checkbox,
} from "../components";

export const chakraTheme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "background",
      },
      text: {
        color: "text",
      },
    },
  },
  fonts: {
    body: "Poppins, sans-serif",
    heading: "Poppins, sans-serif",
  },
  fontSizes: {
    xss: "0.5rem",
  },
  colors: {
    white: "#FFFFFF",
    black: "#000000",

    text: "#13223E",
    background: "#F7F6FB",

    gray: {
      100: "#F8F9FA",
      200: "#D6D8DC",
      300: "#B3B6BC",
      400: "#92969C",
      500: "#73757A",
      600: "#5B5C60",
    },
    primary: {
      50: "#e6edfd",
      100: "#cddcfa",
      200: "#5183ef",
      300: "#3971ed",
      400: "#2060ea",
      500: "#074ee8",
      600: "#0646d1",
      700: "#063eba",
      800: "#0537a2",
      900: "#042f8b",
    },
    secondary: {
      50: "#f0f7fd",
      100: "#e0effb",
      200: "#93c6f2",
      300: "#84bef1",
      400: "#74b6ef",
      500: "#65aeed",
      600: "#5b9dd5",
      700: "#518bbe",
      800: "#477aa6",
      900: "#3d688e",
    },
    success: {
      50: "#e8f8eb",
      100: "#d0f1d7",
      200: "#5bcd74",
      300: "#43c560",
      400: "#2bbe4c",
      500: "#14b738",
      600: "#12a532",
      700: "#10922d",
      800: "#0e8027",
      900: "#0c6e22",
    },
    error: {
      50: "#fceded",
      100: "#fadada",
      200: "#ed7f7f",
      300: "#ea6d6d",
      400: "#e85a5a",
      500: "#e54848",
      600: "#ce4141",
      700: "#b73a3a",
      800: "#a03232",
      900: "#892b2b",
    },
    warning: {
      50: "#fdf0e9",
      100: "#fbe1d3",
      200: "#f29764",
      300: "#f1894e",
      400: "#ef7a38",
      500: "#ed6b22",
      600: "#d5601f",
      700: "#be561b",
      800: "#a64b18",
      900: "#8e4014",
    },
    yellow: {
      50: "#fef6ea",
      100: "#feeed5",
      200: "#fbc26d",
      300: "#fab959",
      400: "#fab144",
      500: "#F9A82F",
      600: "#e0972a",
      700: "#c78626",
      800: "#ae7621",
      900: "#95651c",
    },
  },
  shadows: {
    outline: "none",
  },
  components: {
    Button,
    Input,
    FormLabel,
    FormError,
    Form: {
      ...FloatingLabel,
    },
    Text,
    Switch,
    Checkbox,
  },
});
