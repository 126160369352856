import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import BottomBar from "src/components/Layouts/BottomBar";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import PATHS from "src/constants/paths";
import { UserToAddType } from "src/interfaces/user";
import { useActiveUser } from "src/services/hooks/user/useActiveUser";
import { useDisableUser } from "src/services/hooks/user/useDisableUser";
import { useUpdateUser } from "src/services/hooks/user/useUpdateUser";

import UserForm from "../components/UserForm";

function UpdateUserPage() {
  const {
    mutate: updateUser,
    isSuccess: isUpdateUserSuccess,
    isLoading: isUpdateUserLoading,
  } = useUpdateUser();

  const {
    mutate: disableUser,
    isSuccess: isDisableUserSuccess,
    isLoading: isDisableUserLoading,
  } = useDisableUser();

  const {
    mutate: activeUser,
    isSuccess: isActiveUserSuccess,
    isLoading: isActiveUserLoading,
  } = useActiveUser();

  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<UserToAddType | undefined>(undefined);

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
    { label: "Configurações", path: `${PATHS.SETTINGS}/usuarios` },
    { label: "Editar usuário" },
  ];

  useEffect(() => {
    const state = location.state as { data?: UserToAddType };

    if (typeof state === "object" && state?.data) {
      setUser(state.data as UserToAddType);
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isUpdateUserSuccess || isDisableUserSuccess || isActiveUserSuccess) {
      navigate(-1);
    }
  }, [isUpdateUserSuccess, isDisableUserSuccess, isActiveUserSuccess]);

  return (
    <DashboardLayout selected="settings">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />
      <PageTitle label="Detalhes do usuário">
        {user?.status === "enabled" ? (
          <Button
            variant="outline"
            colorScheme="error"
            size="md"
            isLoading={isDisableUserLoading}
            disabled={isDisableUserLoading}
            onClick={() => user?.id && disableUser(user.id)}
          >
            Desativar usuário
          </Button>
        ) : (
          <Button
            variant="outline"
            size="md"
            isLoading={isActiveUserLoading}
            disabled={isActiveUserLoading}
            onClick={() => user?.id && activeUser(user.id)}
          >
            Ativar usuário
          </Button>
        )}
      </PageTitle>

      <UserForm method="update" data={user} updateUser={updateUser} />

      <BottomBar>
        <Button
          size="md"
          type="submit"
          form="userForm"
          isLoading={isUpdateUserLoading}
          disabled={isUpdateUserLoading}
        >
          Salvar
        </Button>

        <Box width={20} />

        <Button size="md" variant="outline" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
      </BottomBar>
    </DashboardLayout>
  );
}

export default UpdateUserPage;
