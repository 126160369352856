import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import InfoIcon from "@material-ui/icons/Info";

interface ConfirmDeleteTechnicalReportProps {
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
}

export function ConfirmDeleteTechnicalReport({
  isOpen,
  onClose,
  onConfirm,
}: ConfirmDeleteTechnicalReportProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" pb={0}>
          <Icon as={InfoIcon} color="error.500" />
        </ModalHeader>
        <ModalCloseButton color="error.500" />
        <ModalBody pb={6}>
          <Text textAlign="center" mb={7}>
            Tem certeza que deseja excluir
            <br /> o laudo?
          </Text>
          <Button colorScheme="error" w="100%" onClick={onConfirm}>
            Excluir
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
