import styled from "styled-components";

import MuiTextField from "@material-ui/core/TextField";

import { TextFieldProps } from "./props";

export const TextField = styled(MuiTextField)<
  Pick<TextFieldProps, "backgroundwhite">
>`
  background-color: ${({ backgroundwhite, theme }) =>
    backgroundwhite ? theme.palette.common.white : `rgba(0, 0, 0, 0)`};

  .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
  .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline {
    /* border-color: ${({ theme, error }) =>
      !error
        ? theme.palette.error.main
        : theme.palette.primary.main} !important; */
  }

  .MuiInputBase-input {
    color: #13223e;
  }

  .Mui-disabled {
    opacity: 80%;
  }
`;
