import { RequestStatusType } from "src/interfaces/request";

/**
 * Estado Inicial
 */
const RequestStatusInitial: RequestStatusType = {
  initial: true,
  loading: false,
  success: false,
  error: false,
};

/**
 * Estado de carregamento
 */
const RequestStatusLoading: RequestStatusType = {
  initial: false,
  loading: true,
  success: false,
  error: false,
};

/**
 * Estado de sucesso
 */
const RequestStatusSuccess: RequestStatusType = {
  initial: false,
  loading: false,
  success: true,
  error: false,
};

/**
 * Estado de falha
 */
const RequestStatusError: RequestStatusType = {
  initial: false,
  loading: false,
  success: false,
  error: true,
};

export const STATUS = {
  inital: RequestStatusInitial,
  loading: RequestStatusLoading,
  success: RequestStatusSuccess,
  error: RequestStatusError,
};
