import styled from "styled-components";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";

import Typography from "src/components/Typography";

export const Accordion = styled(MuiAccordion)`
  border: 0px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  padding: 0px 0px;
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  height: 60px;
  width: 100%;
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ActiveMonthLabel = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  line-height: 2;
`;

export const ExitTypography = styled(Typography)`
  opacity: 0.6;
`;

export const ResultGrid = styled(Grid)`
  background-color: #f3fbfe;
  border-radius: 5px;
`;

export const ExpenseCardDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;
