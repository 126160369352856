import React, { FC, useRef, useMemo } from "react";

import { Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";

import KeyboardDatePicker from "src/components/Inputs/KeyboardDatePicker";
import TextField from "src/components/Inputs/TextField";
import { Panel } from "src/components/Layouts/Panel/styles";
import Typography from "src/components/Typography";
import { useContact } from "src/hooks/contact/useContact";
import { SubContactFieldType, SubContactType } from "src/interfaces/contact";
import { uppercaseFirstLetter } from "src/utils/format";
import formUtil from "src/utils/formUtil";

import { AutocompleteField } from "../../styles";

interface SubContactFormProps {
  onSubmit: (data: any) => void;
  subContact?: SubContactType;
  isModal?: boolean;
  formikRef?: React.MutableRefObject<null>;
}

const SubContactForm: FC<SubContactFormProps> = ({
  onSubmit,
  subContact,
  isModal = false,
  formikRef: customFormikRef,
}) => {
  const { subContactFields } = useContact();

  const formikRef = customFormikRef ?? useRef(null);

  const intialValues = useMemo(() => {
    if (!subContact) return {};

    return subContact.data.reduce<{ [key: string]: any }>((acc, field) => {
      return {
        ...acc,
        [field.referId]: field.content,
      };
    }, {});
  }, [subContact]);

  const getFieldByType = (
    field: SubContactFieldType,
    values: any,
    setFieldValue: any
  ) => {
    const options =
      field?.selectOptions?.options.map(option => ({
        name: option,
        value: option,
      })) ?? [];

    switch (field.type) {
      case "text":
        return (
          <TextField
            name={field.id}
            label={uppercaseFirstLetter(field.label)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      case "select":
        return (
          <Autocomplete
            id={`select-${field.id}-outlined`}
            value={
              options.find(option => option.value === values[field.id]) ?? null
            }
            onChange={(event, data) => {
              setFieldValue(field.id, data?.value ?? "");
            }}
            options={
              field.selectOptions?.options.map(option => ({
                name: option,
                value: option,
              })) ?? []
            }
            getOptionLabel={option => option.name}
            defaultValue={undefined}
            filterSelectedOptions
            getOptionSelected={(option, value) => option.value === value.value}
            noOptionsText=""
            renderInput={params => (
              <AutocompleteField
                {...params}
                variant="outlined"
                fullWidth
                label={uppercaseFirstLetter(field.label)}
                placeholder={`Pesquisar ${field.label}`}
              />
            )}
          />
        );

      case "date":
        return (
          <KeyboardDatePicker
            label={uppercaseFirstLetter(field.label)}
            name={field.id}
          />
        );

      case "radio":
        return (
          <FormControl>
            <FormLabel id={`${field.id}-label`}>
              {uppercaseFirstLetter(field.label)}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby={`${field.id}-label`}
              name={field.id}
              value={values[field.id] ?? ""}
              onChange={event => {
                setFieldValue(field.id, event.currentTarget.value);
              }}
            >
              {field.selectOptions?.options.map(option => {
                return (
                  <FormControlLabel
                    key={`${field.id}${option}`}
                    value={option}
                    control={<Radio size="small" />}
                    label={option}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
      case "image":
        return null;
      default:
        return null;
    }
  };
  return (
    <Panel padding={isModal ? 0 : 2}>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          ...intialValues,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={formikRef}
      >
        {({ values, setFieldValue }) => (
          <Form id="subContactForm" onKeyDown={formUtil.preventEnterOnKeyDown}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography color="primary">Informações</Typography>
              </Grid>
              {subContactFields.map(field => (
                <Grid item xs={12} md={isModal ? 12 : 6} key={field.id}>
                  {getFieldByType(field, values, setFieldValue)}
                </Grid>
              ))}
            </Grid>
          </Form>
        )}
      </Formik>
    </Panel>
  );
};

export default SubContactForm;
