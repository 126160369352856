import React, { useState } from "react";

import { Box, Divider, Flex, Wrap } from "@chakra-ui/react";
import { startOfMonth, endOfMonth } from "date-fns";

import { ReactComponent as ExcelIcon } from "src/assets/icons/reports/excel.svg";
import Button from "src/components/Button";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Loader from "src/components/Loader";
import PeriodFilterPicker from "src/components/PeriodFilterPicker";
import PATHS from "src/constants/paths";
import { Can } from "src/hooks/permission/usePermission";
import { useSalesByPeriodReport } from "src/services/hooks/reports/sales/useSalesByPeriodReport";
import { formatEndDateISO, formatStartDateISO } from "src/utils/date";
import { formatNumber } from "src/utils/format";
import { generateXLSX } from "src/utils/generateXLSX";

import InformationInitialReportState from "../components/InformationInitialReportState";
import SalesByPeriodBottomBar from "./components/SalesByPeriodBottomBar";
import SalesByPeriodTable from "./components/SalesByPeriodTable";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Vendas por período" },
];

const today = new Date();

const SalesByPeriodPage = () => {
  const [exportLoading, setExportLoading] = useState(false);

  const [startDate, setStartDate] = useState<Date | undefined>(
    startOfMonth(today)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(endOfMonth(today));

  const {
    data,
    refetch: getSalesByPeriodReport,
    isFetching,
    isFetched,
  } = useSalesByPeriodReport(
    {
      startDate: formatStartDateISO(startDate || today),
      endDate: formatEndDateISO(endDate || today),
    },
    { enabled: false }
  );

  const exportToXLSX = async () => {
    if (exportLoading) return;
    if (data.result.length) {
      setExportLoading(true);
      const header = ["Período", "Quantidade", "Valor"];

      const dataExcel = data.result.reduce<string[][]>((data, model) => {
        data.push([
          model.label,
          formatNumber(model.count),
          formatNumber(model.totalAmount),
        ]);

        return data;
      }, []);

      const footer = [
        ["", "", ""],
        [
          "Total",
          String(data.totals.count),
          formatNumber(data.totals.totalAmount),
        ],
      ];

      await generateXLSX(
        header,
        [...dataExcel, ...footer],
        "Vendas por período",
        "Vendas por período.xlsx"
      );
      setExportLoading(false);
    }
  };

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Vendas por periodo" />
      <Wrap width="100%" gap={2}>
        <PeriodFilterPicker
          startDate={startDate || today}
          endDate={endDate || today}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          initialFilter="byMonth"
          options={["perInterval", "byMonth"]}
          showingInPopover={false}
        />
        <Flex flex={1} justify="flex-end" alignItems="flex-end">
          <Button
            style={{ height: 56, marginLeft: 8 }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => getSalesByPeriodReport()}
          >
            Gerar relatório
          </Button>
          <Can subject="reportSalesByPeriod" action="export">
            <Button
              style={{ height: 56, marginLeft: 8, minWidth: 120 }}
              color="primary"
              variant="outlined"
              size="medium"
              onClick={exportToXLSX}
              loading={exportLoading}
            >
              Exportar
              <ExcelIcon style={{ marginLeft: 10 }} />
            </Button>
          </Can>
        </Flex>
      </Wrap>

      <Divider mt={3} mb={1} />

      {!isFetched && !isFetching ? <InformationInitialReportState /> : null}
      {!isFetching && isFetched ? (
        <SalesByPeriodTable sales={data.result} />
      ) : null}

      {!isFetching && data?.totals && isFetched ? (
        <SalesByPeriodBottomBar
          count={data.totals.count}
          totalAmount={data.totals.totalAmount}
        />
      ) : null}
      {isFetching ? (
        <Box height="100%">
          <Loader size="medium" />
        </Box>
      ) : null}
    </DashboardLayout>
  );
};

export default SalesByPeriodPage;
