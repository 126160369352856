import { useForm } from "react-hook-form";

import { Divider } from "@chakra-ui/react";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import PATHS from "src/constants/paths";
import { useCashFlowReport } from "src/services/hooks/reports/financial/useCashFlowReport";

import CashFlowTable from "./CashFlowTable";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [
  { label: "Relatórios", path: PATHS.REPORTS },
  { label: "Controle de caixa" },
];

interface CashFlowReportForm {
  page: number;
  limit: number;
}

export default function CashFlowReportPage() {
  const { watch, setValue } = useForm<CashFlowReportForm>({
    defaultValues: {
      limit: 10,
      page: 1,
    },
  });

  const formData = watch();

  const { data, isFetching } = useCashFlowReport({
    ...formData,
  });

  return (
    <DashboardLayout selected="reports">
      <Breadcrumb data={BREADCRUMB_BUTTONS} />

      <PageTitle label="Relatórios - Controle de caixa" />

      <Divider my={6} />

      <CashFlowTable
        totalCount={data.totalCount}
        cashflows={data.cashflows}
        page={formData.page}
        setPage={value => {
          setValue("page", value);
        }}
        limit={formData.limit}
        isLoading={isFetching}
        setLimit={value => {
          setValue("limit", value);
          setValue("page", 1);
        }}
      />
    </DashboardLayout>
  );
}
