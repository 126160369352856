import styled from "styled-components";

import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Typography,
} from "@material-ui/core";
import DrawerMui from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";

export const Drawer = styled(DrawerMui)`
  .MuiDrawer-paper {
    position: relative;
    @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      position: fixed;
    }
  }
`;

export const DialogHeader = styled(MuiDialogTitle)`
  h2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 0;
  }
  padding: 24px;
`;

export const DialogTitle = styled(Typography)`
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 24px;
  font-weight: 700;
`;

export const CloseButton = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  position: absolute;
  right: 0;
`;

export const DialogContent = styled(MuiDialogContent)`
  width: 100%;
  padding: 0 24px 24px 24px;
`;

export const DialogActions = styled(MuiDialogActions)`
  padding: 16px;
  flex-direction: column;
  border-bottom-left-radius: 100px;
  &.MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 0;
  }
`;
