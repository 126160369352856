import React, { useState, createContext, useContext } from "react";

import { STATUS } from "src/constants/requestStatus";
import {
  getAccountStatement,
  getFinancialOverview as getFinancialOverviewApi,
} from "src/services/financial";

import {
  FinancialAccountStatementItem,
  FinancialContextType,
  FinancialContextTypeParams,
  FinancialOverviewType,
  FinancialOverviewTypeParams,
} from "./props";

export const FinancialContext = createContext({} as FinancialContextType);

export const FinancialProvider: React.FC = props => {
  const { children } = props;

  const [status, setStatus] = useState(STATUS.inital);
  const [rows, setRows] = useState<Array<FinancialAccountStatementItem>>([]);

  const [financialOverview, setFinancialOverview] =
    useState<FinancialOverviewType>({} as FinancialOverviewType);
  const [financialOverviewStatus, setfinancialOverviewStatus] = useState(
    STATUS.inital
  );

  const list = async (params: FinancialContextTypeParams) => {
    try {
      setStatus(STATUS.loading);

      const response: Array<FinancialAccountStatementItem> =
        (await getAccountStatement(params)) as any;

      setRows(response);

      setStatus(STATUS.success);
    } catch (e) {
      setRows([]);
      setStatus(STATUS.error);
    }
  };

  const getFinancialOverview = async (params: FinancialOverviewTypeParams) => {
    try {
      setfinancialOverviewStatus(STATUS.loading);

      const response: FinancialOverviewType = (await getFinancialOverviewApi(
        params
      )) as any;

      setFinancialOverview(response);

      setfinancialOverviewStatus(STATUS.success);
    } catch (e) {
      setRows([]);
      setfinancialOverviewStatus(STATUS.error);
    }
  };

  return (
    <FinancialContext.Provider
      value={{
        status,
        rows,
        list,
        financialOverview,
        getFinancialOverview,
        financialOverviewStatus,
      }}
    >
      {children}
    </FinancialContext.Provider>
  );
};

export const useFinancial = () => {
  const context = useContext(FinancialContext);

  if (!context) {
    throw new Error("useFinancial must be used within a FinancialProvider");
  }

  return context;
};
