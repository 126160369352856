import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import RenderModule from "src/components/RenderModule";

import BuyOrderSection from "./components/Sections/BuyOrderSection";
import CashFlowSection from "./components/Sections/CashFlowSection";
import ConditionPaymentSection from "./components/Sections/ConditionPaymentSection";
import PaymentSection from "./components/Sections/PaymentSection";
import ProductOrderSection from "./components/Sections/ProductOrderSection";
import ServiceOrderSection from "./components/Sections/ServiceOrderSection";

const Preferences: FC = () => {
  return (
    <Box mt={2}>
      <Grid container spacing={4}>
        <RenderModule modules={["serviceOrder"]}>
          <Grid item xs={12}>
            <ServiceOrderSection />
          </Grid>
        </RenderModule>

        <RenderModule modules={["productOrder"]}>
          <Grid item xs={12}>
            <ProductOrderSection />
          </Grid>
        </RenderModule>

        <RenderModule modules={["buyorder"]}>
          <Grid item xs={12}>
            <BuyOrderSection />
          </Grid>
        </RenderModule>

        <RenderModule modules={["cashflowcontrol"]}>
          <Grid item xs={12}>
            <CashFlowSection />
          </Grid>
        </RenderModule>

        <Grid item xs={12}>
          <ConditionPaymentSection />
        </Grid>

        <Grid item xs={12}>
          <PaymentSection />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Preferences;
