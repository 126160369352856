import { Navigate, RouteObject } from "react-router-dom";

import PATHS from "src/constants/paths";
import SettingsPage from "src/pages/Settings";
import CreateUserPage from "src/pages/Settings/Users/Create";
import UpdateUserPage from "src/pages/Settings/Users/Update";

interface SettingsRoutesProps {
  tabSettingsIsValid: boolean;
}

export const settingsRoutes: (props: SettingsRoutesProps) => RouteObject[] = ({
  tabSettingsIsValid,
}: SettingsRoutesProps) => [
  {
    path: PATHS.SETTINGS_USER_ADD,
    element: <CreateUserPage />,
  },
  {
    path: PATHS.SETTINGS_USER_UPDATE,
    element: <UpdateUserPage />,
  },
  {
    path: PATHS.SETTINGS,
    element: <SettingsPage />,
  },
  {
    path: `${PATHS.SETTINGS}/:tab`,
    element: tabSettingsIsValid ? (
      <SettingsPage />
    ) : (
      <Navigate to={PATHS.SETTINGS} replace />
    ),
  },
];
