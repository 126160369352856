const PATHS = {
  HOME: "/",
  SIGN_IN: "/login",
  SIGN_UP: "/cadastro",
  RESET_PASSWORD: "/resetar-senha",
  ALERT_PLAN: "/alerta-plano",

  SERVICES_LIST: "/servicos",
  SERVICES_ADD: "/servicos/novo-servico",
  SERVICES_DETAIL: "/servicos/:id/detalhes",

  PRODUCTS_LIST: "/produtos",
  PRODUCTS_ADD: "/produtos/novo-produto",
  PRODUCTS_DETAIL: "/produto/:id/detalhes",
  BUY_ORDER_LIST: "/produtos/ordens-de-compra",
  BUY_ORDER_ADD: "/produtos/ordens-de-compra/nova-ordem-de-compra",
  BUY_ORDER_DETAIL: "/produtos/ordens-de-compra/:buyOrderId/detalhes",
  BUY_ORDER_UPDATE: "/produtos/ordens-de-compra/:buyOrderId/editar",

  CONTACTS_LIST: "/contatos",
  CONTACTS_DETAIL: "/contatos/:id/detalhes",
  CONTACTS_ADD: "/contatos/novo-contato",

  CREATE_DIRECT_SALE: "/nova-venda-rapida",

  SERVICE_ORDER_LIST: "/ordens-de-servico",
  SERVICE_ORDER_ADD: "/ordens-de-servico/nova-ordem-servico",
  SERVICE_ORDER_DETAIL: "/ordens-de-servico/:orderId/detalhes",
  SERVICE_ORDER_UPDATE: "/ordens-de-servico/:orderId/editar",

  PRODUCT_ORDER_LIST: "/pedidos-de-venda",
  PRODUCT_ORDER_ADD: "/pedidos-de-venda/novo-pedido-de-venda",
  PRODUCT_ORDER_DETAIL: "/pedidos-de-venda/:orderId/detalhes",
  PRODUCT_ORDER_UPDATE: "/pedidos-de-venda/:orderId/editar",

  BILL_TO_RECEIVE: "/financeiro/contas-a-receber",
  BILL_TO_PAY: "/financeiro/contas-a-pagar",
  FINANCIAL_ACCOUNT_STATEMENT: "/financeiro/extrato-de-contas",
  FINANCIAL_ACCOUNTS: "/financeiro/contas",

  REPORTS: "/relatorios",
  REPORTS_DRE: "/relatorios/dre",
  REPORTS_PREDICTED_AND_ACCOMPLISHED: "/relatorios/previsto-x-realizado",
  REPORTS_PRODUCTS: "/relatorios/produtos",
  REPORTS_SERVICES: "/relatorios/servicos",
  REPORTS_SALES: "/relatorios/vendas",
  REPORTS_SALES_BY_CLIENT: "/relatorios/vendas-por-cliente",
  REPORTS_LATE_CLIENTS: "/relatorios/clientes-atrasados",
  REPORTS_RECORDS: "/relatorios/lancamentos",
  REPORTS_CLIENTS: "/relatorios/clientes",
  REPORTS_PROVIDERS: "/relatorios/fornecedores",
  REPORTS_CASHFLOW: "/relatorios/controle-de-caixa",
  REPORTS_SALES_COMISSION: "/relatorios/comissao-de-vendedores",
  REPORTS_SALES_BY_PERIOD: "/relatorios/vendas-por-periodo",
  REPORTS_INVENTORY: "/relatorios/inventario",

  SCHEDULE: "/agenda",

  SETTINGS: "/configuracoes",
  SETTINGS_USER_ADD: "/configuracoes/usuarios/novo-usuario",
  SETTINGS_USER_UPDATE: "/configuracoes/usuarios/:id/editar",
};

export default PATHS;
