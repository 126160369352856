import React from "react";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";

import PageTitle from "src/components/Layouts/PageTitle";
import RenderModule from "src/components/RenderModule";
import { PAGE_ORDER_TITLES } from "src/constants/orders";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { useOrder } from "src/hooks/order/useOrder";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";
import { OrderType } from "src/interfaces/order";
import AditionalInfoTextArea from "src/pages/Orders/components/AditionalInfoTextArea";
import ComissionSection from "src/pages/Orders/components/ComissionSection";
import ConditionPaymentSection from "src/pages/Orders/components/ConditionPaymentSection";
import CreatedAtSection from "src/pages/Orders/components/CreatedAtSection";
import CustomerSection from "src/pages/Orders/components/CustomerSection";
import DeadlineSection from "src/pages/Orders/components/DeadlineSection";
import DiscountSection from "src/pages/Orders/components/DiscountSection";
import ProductManager from "src/pages/Orders/components/ProductManager";
import ServiceManager from "src/pages/Orders/components/ServiceManager";
import TechnicalReportManager from "src/pages/Orders/components/TechnicalReportManager";
import { useOrderType } from "src/pages/Orders/useOrderType";
import formUtil from "src/utils/formUtil";

const ServiceOrderForm: React.FC = () => {
  const { create, customer, services } = useOrder();
  const { serviceOrderNameDefinitions } = useCompany();
  const { serviceOrderModule } = useModule();
  const { type } = useOrderType();
  const { setRestartOrderList } = useOrderFilters();

  const serviceRequired = serviceOrderModule?.settings?.serviceRequired ?? true;
  const allowSetCreatedDate =
    serviceOrderModule?.settings?.allowSetCreatedDate === "allow";

  const createServiceOrder = (data: Partial<OrderType>) => {
    if (serviceRequired && services.length === 0) {
      toast.error("Adicione no mínimo um serviço!");
    }

    if (!customer?.id) {
      toast.error("Adicione no mínimo um cliente!");
    }

    if ((services.length > 0 || !serviceRequired) && customer?.id) {
      create(data, "serviceOrder");
      setRestartOrderList(true);
    }
  };

  return (
    <Formik
      onSubmit={createServiceOrder}
      initialValues={
        {
          additionalInfo: undefined,
          discount: undefined,
          discountType: undefined,

          downPaymentType: "percentage",
          downPaymentValue: 100,
          remainingPaymentType: undefined,
          remainingPaymentValue: undefined,

          services: [],
          products: [],

          paymentType: "A Vista",

          deadline: undefined,
          createdDate: undefined,
        } as Partial<OrderType>
      }
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      <Form id="form-order" onKeyDown={formUtil.preventEnterOnKeyDown}>
        <PageTitle
          label={
            PAGE_ORDER_TITLES(serviceOrderNameDefinitions)[type].pageTitle.add
          }
        />

        <Grid container>
          <CustomerSection />

          <Grid item xs={12}>
            <Box mt={2} />
          </Grid>

          <Grid container>
            <Grid item xs={12} md={5} lg={3}>
              <DeadlineSection />
            </Grid>
            {allowSetCreatedDate ? (
              <Grid item xs={12} md={5} lg={3}>
                <CreatedAtSection />
              </Grid>
            ) : null}
            <RenderModule modules={["operator"]}>
              <Grid item xs={12} md={5} lg={3}>
                <ComissionSection />
              </Grid>
            </RenderModule>
          </Grid>

          <Grid item xs={12}>
            <ServiceManager />
          </Grid>

          <Grid item xs={12}>
            <ProductManager />
          </Grid>

          <RenderModule modules={["technicalReport"]}>
            <Grid item xs={12}>
              <TechnicalReportManager />
            </Grid>
          </RenderModule>

          <Grid item xs={12}>
            <ConditionPaymentSection />
          </Grid>

          <Grid item xs={12}>
            <DiscountSection />
          </Grid>

          <Grid item xs={12}>
            <Box mt={2} />
          </Grid>

          <AditionalInfoTextArea orderType="serviceOrder" />
        </Grid>
      </Form>
    </Formik>
  );
};

export default ServiceOrderForm;
