import { RouteObject } from "react-router-dom";

import PATHS from "src/constants/paths";
import ServicesAddPage from "src/pages/Services/Add";
import ServicesDetailPage from "src/pages/Services/Detail";
import ServicesListPage from "src/pages/Services/List";

export const serviceRoutes: RouteObject[] = [
  {
    path: PATHS.SERVICES_LIST,
    element: <ServicesListPage />,
  },
  {
    path: PATHS.SERVICES_DETAIL,
    element: <ServicesDetailPage />,
  },
  {
    path: PATHS.SERVICES_ADD,
    element: <ServicesAddPage />,
  },
];
