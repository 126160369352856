import { useEffect, useMemo } from "react";

import {
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Spinner,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Wrap,
} from "@chakra-ui/react";

import { ReactComponent as PointOfSale } from "src/assets/icons/order/point-of-sale.svg";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";
import { usePermission } from "src/hooks/permission/usePermission";
import { useAuth } from "src/hooks/user/auth/useAuth";
import { useCashFlowOpened } from "src/services/hooks/cashFlow/useCashFlowOpened";

import { CustomTab } from "../CreateOrder/components/CustomTab";
import { OrderDataForm } from "../CreateOrder/components/OrderDataForm";
import { OrderItemsForm } from "../CreateOrder/components/OrderItemsForm";
import OrderItemsModal from "../CreateOrder/components/OrderItemsForm/components/OrderItemsModal";
import { OrderPaymentForm } from "../CreateOrder/components/OrderPaymentForm";
import { OrderResumeForm } from "../CreateOrder/components/OrderResumeForm";
import { OpenCashFlowInfo } from "../List/components/OpenCashFlowInfo";

export default function CreateDirectSalePage() {
  const { open } = useBaseSwipeableDrawer();

  const { serviceOrderNameDefinitions } = useCompany();
  const { hasModules } = useModule();
  const hasProductOrderModule = hasModules("productOrder");
  const hasServiceOrderModule = hasModules("serviceOrder");

  const { can } = usePermission();
  const { isOwner } = useAuth();

  const shouldOpenCashFlow =
    hasModules("cashflowcontrol") &&
    !isOwner &&
    can([{ subject: "cashFlow", action: "requestOpened" }]);

  const {
    data: cashFlowOpened,
    error,
    isLoading,
  } = useCashFlowOpened({
    enabled: hasModules("cashflowcontrol"),
    retry: 1,
    retryDelay: 300,
  });

  const hasCashFlowOpened = cashFlowOpened && !error;

  const showOrderContent =
    !hasModules("cashflowcontrol") || !shouldOpenCashFlow || hasCashFlowOpened;

  const {
    currentStep,
    availableSteps,
    setCurrentStep,
    orderCreated,
    type,
    setType,
  } = useOrderForm();

  const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = useMemo(
    () => [
      {
        label: "Nova venda rápida",
      },
    ],
    []
  );

  useEffect(() => {
    if (hasProductOrderModule && !hasServiceOrderModule) {
      setType("productOrder");
    }
    if (hasServiceOrderModule && !hasProductOrderModule) {
      setType("serviceOrder");
    }
  }, []);

  return (
    <DashboardLayout
      selected="sales"
      subSelected="direct-sale"
      modules={["productOrder", "serviceOrder"]}
      allModules={false}
    >
      <Breadcrumb data={BREADCRUMB_BUTTONS} />
      <Divider />
      <PageTitle label="Nova venda rápida" />

      {isLoading && shouldOpenCashFlow ? (
        <Flex align="center" justify="center" pt={10}>
          <Spinner size="lg" color="primary.500" />
        </Flex>
      ) : null}

      {shouldOpenCashFlow && !hasCashFlowOpened && !isLoading ? (
        <>
          <OpenCashFlowInfo />
        </>
      ) : null}

      {type && showOrderContent ? (
        <>
          <Tabs
            isFitted
            w="full"
            variant="unstyled"
            index={currentStep}
            onChange={newStep => setCurrentStep(newStep)}
          >
            <Flex justifyContent="center" bg="white" borderRadius={10}>
              <TabList
                as={Wrap}
                minH="55px"
                p={2}
                direction={{
                  base: "column",
                  lg: "row",
                }}
                bg="white"
              >
                <CustomTab
                  key="order-items"
                  index={0}
                  availableSteps={availableSteps}
                  disable={orderCreated?.id}
                  label={
                    type === "serviceOrder" ? "Produtos/Serviços" : "Produtos"
                  }
                  tabsCount={4}
                />
                <CustomTab
                  key="order-data"
                  index={1}
                  availableSteps={availableSteps}
                  disable={orderCreated?.id}
                  label="Dados da venda"
                  tabsCount={4}
                />
                <CustomTab
                  key="order-payment"
                  index={2}
                  availableSteps={availableSteps}
                  disable={orderCreated?.id}
                  label="Dados de pagamento"
                  tabsCount={4}
                />
                <CustomTab
                  key="order-resume"
                  index={3}
                  availableSteps={availableSteps}
                  label="Resumo"
                  tabsCount={4}
                />
              </TabList>
            </Flex>
            <TabPanels p={0}>
              <OrderItemsForm />
              <OrderDataForm />
              <OrderPaymentForm />
              <OrderResumeForm />
            </TabPanels>
          </Tabs>
          {open.includes("orderItemsModal") ? <OrderItemsModal /> : null}
        </>
      ) : null}

      {!type && showOrderContent ? (
        <>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            h="60vh"
          >
            <Icon as={PointOfSale} fontSize="64px" mb={5} />
            <Text fontSize="lg" mb={2} lineHeight="27px" textAlign="center">
              Você tem os módulos
              <br />
              <strong>Ordem de serviço</strong> e{" "}
              <strong>Pedido de venda</strong> instalados
            </Text>
            <Text color="gray.500" mb={4}>
              Escolha a baixo qual tipo deseja fazer nesta venda
            </Text>
            <HStack spacing={2}>
              <Button onClick={() => setType("serviceOrder")}>
                {serviceOrderNameDefinitions.title}
              </Button>
              <Text fontSize="lg" fontWeight={400}>
                OU
              </Text>
              <Button onClick={() => setType("productOrder")}>
                Pedido de venda
              </Button>
            </HStack>
          </Flex>
        </>
      ) : null}
    </DashboardLayout>
  );
}
