import React, { useMemo } from "react";

import { Box, Flex } from "@chakra-ui/react";

import { useStatusOrder } from "src/hooks/order/useStatusOrder";
import { Can } from "src/hooks/permission/usePermission";
import { TypeOfOrder } from "src/interfaces/order";
import { formatPrice } from "src/utils/format";

import { CashFlowActions } from "../CashFlowActions";
import * as Styled from "./styles";

interface BottomBarProps {
  orderType: TypeOfOrder;
}

const BottomBar: React.FC<BottomBarProps> = ({ orderType }) => {
  const { ordersStatus } = useStatusOrder();

  const status = useMemo(
    () => ordersStatus.find(orderStatus => orderStatus._id === ""),
    [ordersStatus]
  );

  return (
    <Styled.AppBar position="fixed" component="footer">
      <Flex pl="250px" h="70px" alignItems="center" pr="36px">
        <Box pl="24px" flex={1}>
          <CashFlowActions />
        </Box>
        <Can not subject={orderType} action="hideTotalizers">
          <Flex gap={10}>
            <Box>
              <Styled.LabelData>Quantidade</Styled.LabelData>
              <Styled.ValueData>{status?.orderCount ?? 0}</Styled.ValueData>
            </Box>
            <Box>
              <Styled.LabelData>Ticket médio</Styled.LabelData>
              <Styled.ValueData>
                {formatPrice(
                  (status?.orderTotalAmount ?? 0) / (status?.orderCount ?? 0)
                )}
              </Styled.ValueData>
            </Box>
            <Box>
              <Styled.LabelData>Valor total</Styled.LabelData>
              <Styled.ValueData>
                {formatPrice(status?.orderTotalAmount)}
              </Styled.ValueData>
            </Box>
          </Flex>
        </Can>
      </Flex>
    </Styled.AppBar>
  );
};

export default BottomBar;
