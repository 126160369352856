import React, { FC, useMemo } from "react";

import Typography from "src/components/Typography";

import { DataCardProps } from "./props";
import * as Styled from "./styles";

const DataCard: FC<DataCardProps> = props => {
  const { title, value } = props;

  const renderValue = useMemo(() => {
    if (value === undefined) {
      return "-";
    }
    return value;
  }, [value]);

  return (
    <Styled.Container>
      <Typography color="primary" variant="body2">
        {title}
      </Typography>
      <Typography color="primary" variant="h6">
        {renderValue}
      </Typography>
    </Styled.Container>
  );
};

export default DataCard;
