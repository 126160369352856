import React from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as AnalyticsIcon } from "src/assets/icons/reports/analytics.svg";
import Typography from "src/components/Typography";

const InformationInitialReportState = () => {
  return (
    <Box
      marginTop={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <AnalyticsIcon />
      <Box mb={6} />
      <Typography variant="h6" color="primary" align="center">
        Você ainda não possui relatórios gerados para ser visualizado aqui!{" "}
        <br />
        comece <strong>gerando relatório</strong>
      </Typography>
    </Box>
  );
};

export default InformationInitialReportState;
