/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";

import SelectField from "src/components/Inputs/SelectField";
import TextField from "src/components/Inputs/TextField";
import { BRAZIL_UF } from "src/constants/uf";
import { useAddress } from "src/hooks/address/useAddress";
import { ContactType } from "src/interfaces/contact";
import { extractNumbers } from "src/utils/extractNumbers";

interface AddressFieldsProps {
  isModal?: boolean;
}

const AddressFields: FC<AddressFieldsProps> = ({ isModal = false }) => {
  const { getAddress, address, status } = useAddress();

  const { values, setFieldValue, dirty } = useFormikContext<ContactType>();

  useEffect(() => {
    const formatedCEP = extractNumbers(values.cep);
    if (formatedCEP.length === 8 && dirty) {
      getAddress(formatedCEP);
    }
  }, [values?.cep]);

  useEffect(() => {
    if (status.success) {
      setFieldValue("neighborhood", address?.neighborhood);
      setFieldValue("street", address?.street);
      setFieldValue("uf", address?.uf);
      setFieldValue("city", address?.city);
      setFieldValue("complement", address?.complement);
    }
  }, [status]);

  const UFItems = BRAZIL_UF.map(item => ({
    name: item.uf,
    value: item.uf,
  }));

  return (
    <>
      <Grid item xs={isModal ? 12 : 6}>
        <TextField label="CEP" name="cep" mask="99999-999" />
      </Grid>
      <Grid item xs={isModal ? 12 : 3}>
        <TextField label="Número" name="number" />
      </Grid>
      <Grid item xs={isModal ? 12 : 3}>
        <TextField label="Bairro" name="neighborhood" />
      </Grid>
      <Grid item xs={isModal ? 12 : 6}>
        <TextField label="Logradouro" name="street" />
      </Grid>
      <Grid item xs={isModal ? 12 : 3}>
        <SelectField
          label="Estado"
          name="uf"
          items={UFItems}
          displayEmpty
          defaultValue=""
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 3}>
        <TextField label="Cidade" name="city" />
      </Grid>
      <Grid item xs={isModal ? 12 : 6}>
        <TextField label="Complemento" name="complement" />
      </Grid>
    </>
  );
};

export default AddressFields;
