import React, { FC, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Loader from "src/components/Loader";
import Typography from "src/components/Typography";
import { FinancialIntervalEnum } from "src/hooks/financial/props";
import { useFinancial } from "src/hooks/financial/useFinancial";
import { useModule } from "src/hooks/module/useModule";

import CashFlowChart from "./components/CashFlowChart";
import CategoryChart from "./components/CategoryChart";
import GeneralBalanceCard from "./components/GeneralBalanceCard";
import InvoiceChart from "./components/InvoiceChart";
import SectionAccountsCard from "./components/SectionAccountsCard";
import SectionPendenciesCard from "./components/SectionPendenciesCard";
import * as Styled from "./styles";

const Summary: FC<{ interval: keyof typeof FinancialIntervalEnum }> = ({
  interval,
}) => {
  const { financialOverviewStatus, getFinancialOverview } = useFinancial();
  const { hasModules } = useModule();

  useEffect(() => {
    getFinancialOverview({
      interval,
    });
  }, [interval]);

  if (financialOverviewStatus.loading) {
    return (
      <Box height="100%">
        <Loader size="medium" />
      </Box>
    );
  }

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Resumo financeiro
          </Typography>
          <Grid container>
            <GeneralBalanceCard />
            <Styled.Divider />
            {hasModules("accountManagement") ? <SectionAccountsCard /> : null}
            <SectionPendenciesCard />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          {hasModules("accountManagement") ? (
            <>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
              >
                Fluxo de caixa
              </Typography>
              <Grid container>
                <CashFlowChart />
              </Grid>
            </>
          ) : null}
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Faturamento
          </Typography>
          <Grid container>
            <InvoiceChart />
          </Grid>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Despesas por categoria
          </Typography>
          <Grid container>
            <CategoryChart />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Summary;
