import React from "react";

import { Button as ChakaraButton } from "@chakra-ui/react";
import { Chip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReplayIcon from "@material-ui/icons/Replay";

import Button from "src/components/Button";
import Modal from "src/components/Modal";
import { useModal } from "src/components/Modal/useModal";
import { useOrder } from "src/hooks/order/useOrder";
import { useOrderFilters } from "src/hooks/order/userOrderFilters";
import { OrderDetailType } from "src/interfaces/order";

import { CreateOrDeleteOrderStockModalProps } from "./props";
import * as Styled from "./styles";

const CreateOrDeleteOrderStockModal: React.FC<CreateOrDeleteOrderStockModalProps> =
  props => {
    const { id, stockCreatedAt, type } = props;

    const {
      createOrderStock,
      createOrderStockStatus,
      deleteOrderStockStatus,
      deleteOrderStock,
    } = useOrder();
    const { handleClose } = useModal();
    const { setRestartOrderList } = useOrderFilters();

    const createOrDeleteOrderStockService = async () => {
      if (!stockCreatedAt && !createOrderStockStatus.loading) {
        await createOrderStock(id);
      }
      if (stockCreatedAt && !deleteOrderStockStatus.loading) {
        await deleteOrderStock(id);
      }

      handleClose();
      setRestartOrderList(true);
    };

    return (
      <Modal>
        <Box p={3}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <div />
                {stockCreatedAt ? (
                  <Styled.AlertIcon alignmentBaseline="middle" />
                ) : (
                  <Styled.InfoIcon alignmentBaseline="middle" />
                )}
                <Styled.CloseButton onClick={handleClose} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Styled.InfoText align="center" variant="h6">
                Tem certeza que deseja {stockCreatedAt ? "estornar" : "lançar"}{" "}
                o estoque
                {type === "serviceOrder"
                  ? " desta ordem de serviço"
                  : " deste pedido de venda"}
                ?
              </Styled.InfoText>
            </Grid>
            <Grid item xs={12}>
              {stockCreatedAt ? (
                <Styled.DeleteButton
                  variant="contained"
                  onClick={createOrDeleteOrderStockService}
                  fullWidth
                  size="large"
                  disabled={deleteOrderStockStatus.loading}
                  loading={deleteOrderStockStatus.loading}
                >
                  Estornar
                </Styled.DeleteButton>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={createOrDeleteOrderStockService}
                  fullWidth
                  size="large"
                  disabled={createOrderStockStatus.loading}
                  loading={createOrderStockStatus.loading}
                >
                  Lançar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };

interface CreateOrDeleteOrderStockProps {
  order: OrderDetailType;
  showType?: "button" | "chip";
}

const CreateOrDeleteOrderStock: React.FC<CreateOrDeleteOrderStockProps> =
  props => {
    const { order, showType = "chip" } = props;
    const { handleOpen } = useModal();

    return (
      <>
        {order.products?.length ? (
          showType === "chip" ? (
            <Chip
              style={{
                backgroundColor: order.stockCreatedAt ? "#F9A82F80" : "#F9A82F",
                color: "#FFF",
                fontWeight: 500,
                marginRight: "8px",
                lineHeight: 1,
              }}
              label={
                order.stockCreatedAt ? "Estoque lançado" : "Lançar estoque"
              }
              onClick={handleOpen}
              onDelete={order?.stockCreatedAt ? handleOpen : undefined}
              deleteIcon={
                <CheckCircleIcon style={{ color: "#FFF" }} fontSize="small" />
              }
            />
          ) : (
            <ChakaraButton
              justifyContent="flex-start"
              size="sm"
              variant="ghost"
              color="text"
              leftIcon={
                order.stockCreatedAt ? (
                  <ReplayIcon color="primary" fontSize="small" />
                ) : (
                  <CallMadeIcon color="primary" fontSize="small" />
                )
              }
              onClick={handleOpen}
            >
              {order.stockCreatedAt ? "Estornar estoque" : "Lançar estoque"}
            </ChakaraButton>
          )
        ) : null}
        <CreateOrDeleteOrderStockModal
          id={order.id}
          stockCreatedAt={order.stockCreatedAt}
          type={order.type}
        />
      </>
    );
  };

export default CreateOrDeleteOrderStock;
