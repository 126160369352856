import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { STATUS } from "src/constants/requestStatus";
import {
  ContactListParamType,
  ContactType,
  GetContactResponseType,
  SubContactFieldType,
  SubContactType,
  SubContactTypeUpdate,
  SubContactsFormData,
  SubContactsParams,
} from "src/interfaces/contact";
import { TagParamType, TagType } from "src/interfaces/tag";
import {
  addContact,
  addSubContactService,
  deleteContact,
  getContact,
  getContacts,
  getSubContactFieldsService,
  getSubContactsService,
  removeSubContactService,
  updateContact,
  updateSubContactService,
} from "src/services/contact";
import { getTagsService } from "src/services/tags";
import { extractNumbers } from "src/utils/extractNumbers";

import { useCompany } from "../company/useCompany";
import { ContactContextType } from "./props";

export const ContactContext = createContext({} as ContactContextType);

export const ContactProvider: React.FC = props => {
  const { children } = props;
  const { subContactNameDefinitions } = useCompany();

  const [status, setStatus] = useState(STATUS.inital);
  const [createStatus, setCreateStatus] = useState(STATUS.inital);
  const [createSubContactStatus, setCreateSubContactStatus] = useState(
    STATUS.inital
  );
  const [updateSubContactStatus, setUpdateSubContactStatus] = useState(
    STATUS.inital
  );
  const [updateStatus, setUpdateStatus] = useState(STATUS.inital);
  const [contacts, setContacts] = useState<GetContactResponseType>(
    {} as GetContactResponseType
  );
  const [contact, setContact] = useState<ContactType>({} as ContactType);

  const [tags, setTags] = useState<TagType[]>([]);
  const [tagStatus, setTagStatus] = useState(STATUS.inital);

  const [subContactFields, setSubContactFields] = useState<
    SubContactFieldType[]
  >([]);
  const [subContactFieldsStatus, setSubContactFieldsStatus] = useState(
    STATUS.inital
  );

  const [removeSubContactStatus, setRemoveSubContactStatus] = useState(
    STATUS.inital
  );
  const [subContactsStatus, setSubContactsStatus] = useState(STATUS.inital);
  const [subContacts, setSubContacts] = useState<SubContactType[]>([]);

  useEffect(() => {
    getContactFields();
  }, []);

  const detail = async (id: string) => {
    try {
      setStatus(STATUS.loading);

      const response: ContactType = await getContact(id);

      setContact(response);

      setStatus(STATUS.success);
    } catch (e) {
      setContact({} as ContactType);
      setStatus(STATUS.error);
    }
  };

  const getTags = async (params: TagParamType) => {
    try {
      setTagStatus(STATUS.loading);

      const response: TagType[] = await getTagsService(params);

      setTags(response);

      setTagStatus(STATUS.success);
    } catch (e) {
      setTags([]);
      setTagStatus(STATUS.error);
    }
  };

  const getSubContacts = async (params: SubContactsParams) => {
    try {
      setSubContactsStatus(STATUS.loading);

      const response: SubContactType[] = await getSubContactsService(params);

      setSubContacts(response);

      setSubContactsStatus(STATUS.success);
    } catch (e) {
      setTags([]);
      setSubContactsStatus(STATUS.error);
    }
  };

  const addSubContact = async (
    params: SubContactsFormData
  ): Promise<SubContactType | undefined> => {
    try {
      setCreateSubContactStatus(STATUS.loading);

      const response: SubContactType = await addSubContactService(params);

      setCreateSubContactStatus(STATUS.success);

      toast.success(`${subContactNameDefinitions.title} cadastrado`);
      return response;
    } catch (e) {
      setCreateSubContactStatus(STATUS.error);
      toast.error(
        `Ocorreu um problema ao cadastrar esse ${subContactNameDefinitions.title.toLowerCase()}`
      );
    }
    return undefined;
  };

  const updateSubContact = async (
    data: SubContactTypeUpdate,
    subContactId: string
  ) => {
    try {
      setUpdateSubContactStatus(STATUS.loading);

      await updateSubContactService(data, subContactId);

      setUpdateSubContactStatus(STATUS.success);
      toast.success(`${subContactNameDefinitions.title} atualizado`);
    } catch (e) {
      setUpdateSubContactStatus(STATUS.error);

      toast.error(
        `Ocorreu um problema ao atualizar ${subContactNameDefinitions.title.toLowerCase()}`
      );
    }
  };

  const getContactFields = async () => {
    try {
      setSubContactFieldsStatus(STATUS.loading);

      const response: SubContactFieldType[] =
        await getSubContactFieldsService();

      setSubContactFields(response);

      setSubContactFieldsStatus(STATUS.success);
    } catch (e) {
      setSubContactFields([]);
      setSubContactFieldsStatus(STATUS.error);
    }
  };

  const list = async (params: ContactListParamType) => {
    try {
      setContacts({} as GetContactResponseType);
      setStatus(STATUS.loading);

      if (params.search) {
        params.search = params.search?.trim();
      }

      const response: GetContactResponseType = await getContacts(params);

      setContacts(response);

      setStatus(STATUS.success);
    } catch (e) {
      toast.error(
        "Ocorreu um problema ao carregar os contantos. Por favor verifique sua conexão e tente novamnete."
      );
      setContacts({} as GetContactResponseType);
      setStatus(STATUS.error);
    }
  };

  const add = async (params: ContactType) => {
    try {
      setContacts({} as GetContactResponseType);
      setCreateStatus(STATUS.loading);

      const response = await addContact({
        ...params,
        phone: params?.phone && extractNumbers(params.phone),
        cep: params?.cep && extractNumbers(params?.cep),
        cpf: params?.cpf && extractNumbers(params.cpf),
        cnpj: params?.cnpj && extractNumbers(params.cnpj),
        tags: params?.tags && params.tags.map(tag => tag.id),
      });

      setContact(response);
      setCreateStatus(STATUS.success);
      toast.success("Contato cadastrado");
    } catch (e) {
      setCreateStatus(STATUS.error);

      toast.error("Ocorreu um problema ao cadastrar esse contato");
    }
  };

  const update = async (params: ContactType) => {
    try {
      setContacts({} as GetContactResponseType);
      setUpdateStatus(STATUS.loading);

      const response: ContactType = await updateContact({
        ...params,
        phone: params?.phone && extractNumbers(params.phone),
        cep: params?.cep && extractNumbers(params?.cep),
        cpf: params?.cpf && extractNumbers(params.cpf),
        cnpj: params?.cnpj && extractNumbers(params.cnpj),
        tags: params?.tags && params.tags.map(tag => tag.id),
      });

      setContact(response);
      setUpdateStatus(STATUS.success);

      toast.success("Contato atualizado");
    } catch (e) {
      setUpdateStatus(STATUS.error);

      toast.error("Ocorreu um problema ao atualizar esse contato");
    }
  };

  const remove = async (id: string) => {
    try {
      setStatus(STATUS.loading);

      await deleteContact(id);

      setStatus(STATUS.success);

      if (contacts?.contacts?.length > 0) {
        setContacts({
          ...contacts,
          contacts: contacts.contacts.filter(item => item?.id !== id),
        });
      }

      toast.success("Contato deletado");
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao deletar esse contato");
    }
  };

  const removeSubContact = async (id: string) => {
    try {
      setRemoveSubContactStatus(STATUS.loading);

      await removeSubContactService(id);

      setRemoveSubContactStatus(STATUS.success);

      toast.success("Sub Contato deletado");
    } catch (e) {
      setRemoveSubContactStatus(STATUS.error);

      toast.error("Ocorreu um problema ao deletar esse contato");
    }
  };

  return (
    <ContactContext.Provider
      value={{
        status,
        createStatus,
        updateStatus,
        contact,
        setContact,
        contacts,
        list,
        add,
        update,
        detail,
        remove,

        tags,
        getTags,
        tagStatus,

        getContactFields,
        subContactFieldsStatus,
        subContactFields,

        addSubContact,
        createSubContactStatus,

        updateSubContact,
        updateSubContactStatus,

        removeSubContact,
        removeSubContactStatus,

        getSubContacts,
        subContactsStatus,
        subContacts,
        setSubContacts,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export const useContact = () => {
  const context = useContext(ContactContext);

  if (!context) {
    throw new Error("useContact must be used within a ContactProvider");
  }

  return context;
};
