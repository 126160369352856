import * as yup from "yup";

import { TechnicalReportForm } from "src/hooks/order/useOrderForm/props";
import { ContactType, SubContactType } from "src/interfaces/contact";
import { UserType } from "src/interfaces/user";

export const orderDataSchema = yup.object({
  customer: yup.mixed<ContactType>(),
  subContact: yup.mixed<SubContactType>(),
  operator: yup.mixed<UserType>(),
  additionalInfo: yup.string(),
  deadline: yup.string(),
  createdDate: yup.string(),
  technicalReport: yup.mixed<TechnicalReportForm>(),
});
