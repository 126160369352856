import { Navigate, Outlet, RouteObject } from "react-router-dom";

import PATHS from "src/constants/paths";
import { ContactProvider } from "src/hooks/contact/useContact";
import { NameDefitionType } from "src/interfaces/company";
import ContactAddPage from "src/pages/Contacts/Add";
import ContactDetailPage from "src/pages/Contacts/Detail";
import AddSubContactPage from "src/pages/Contacts/Detail/AddSubContactPage";
import UpdateSubContactPage from "src/pages/Contacts/Detail/UpdateSubContactPage";
import ContactListPage from "src/pages/Contacts/List";

interface ContactRoutesProps {
  tabContactDetailIsValid: boolean;
  subContactNameDefinitions: NameDefitionType;
}

export const contactRoutes: (props: ContactRoutesProps) => RouteObject[] = ({
  tabContactDetailIsValid,
  subContactNameDefinitions,
}: ContactRoutesProps) => [
  {
    path: PATHS.CONTACTS_LIST,
    element: (
      <ContactProvider>
        <ContactListPage />
        <Outlet />
      </ContactProvider>
    ),
    children: [
      { path: PATHS.CONTACTS_ADD, element: <ContactAddPage /> },
      { path: PATHS.CONTACTS_DETAIL, element: <ContactDetailPage /> },
      {
        path: `${PATHS.CONTACTS_DETAIL}/:tab`,
        element: tabContactDetailIsValid ? (
          <ContactDetailPage />
        ) : (
          <Navigate to={PATHS.CONTACTS_DETAIL} replace />
        ),
      },
      {
        path: `${
          PATHS.CONTACTS_DETAIL
        }/:tab/novo-${subContactNameDefinitions.title.toLowerCase()}`,
        element: <AddSubContactPage />,
      },
      {
        path: `${PATHS.CONTACTS_DETAIL}/:tab/:subContactId/editar`,
        element: <UpdateSubContactPage />,
      },
      {
        path: "*",
        element: <Navigate to={PATHS.CONTACTS_LIST} replace />,
      },
    ],
  },
];
