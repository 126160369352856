/* eslint-disable no-console */
import React from "react";

import { Box } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import * as Styled from "./styles";

const UpdateDisabledInfo: React.FC = () => {
  return (
    <Box position="relative" display="flex" alignItems="center">
      <InfoIcon color="primary" />

      <Styled.Tooltip
        classes={{
          tooltip: "custom__tooltip",
        }}
        title={
          <>
            <Box component="span" fontSize="15px" fontWeight="500" color="#fff">
              Edição desabilitada.{" "}
            </Box>
            <Box
              component="span"
              fontSize="15px"
              fontWeight="400"
              color="#d6d8dc"
            >
              Para editar esta ordem de compra é preciso estornar o estoque e
              contas!
            </Box>
          </>
        }
        placement="bottom-end"
        arrow
      >
        <Box position="absolute" width="100%" height="100%" />
      </Styled.Tooltip>
    </Box>
  );
};

export default UpdateDisabledInfo;
