import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { CashFlow } from "src/interfaces/cashFlow";
import { GetResponseType } from "src/interfaces/request";
import api from "src/services/api";

interface CashFlowReportParams {
  userId?: string;
  page: number;
  limit: number;
}

interface GetCashFlowReportResponse extends GetResponseType {
  cashflows: CashFlow[];
}

export const cashFlowReport = (params: CashFlowReportParams) =>
  api
    .get<GetCashFlowReportResponse>("/v1/report/cashflow", {
      params,
    })
    .then(res => res.data);

export function useCashFlowReport(
  params: CashFlowReportParams,
  config?: UseQueryOptions<
    GetCashFlowReportResponse,
    Error,
    GetCashFlowReportResponse,
    unknown[]
  >
) {
  return useQuery<
    GetCashFlowReportResponse,
    Error,
    GetCashFlowReportResponse,
    unknown[]
  >(["reportContacts", params], () => cashFlowReport(params), {
    refetchOnWindowFocus: false,
    ...(config || {}),
    initialData: {
      cashflows: [],
      limit: params.limit,
      page: params.page,
      totalCount: 0,
    } as GetCashFlowReportResponse,
  });
}
