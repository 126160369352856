import React, { FC } from "react";

import { DataGridProps } from "@material-ui/data-grid";

import * as Styled from "./styles";

const StripedTable: FC<DataGridProps> = props => {
  return (
    <Styled.DataGrid
      {...props}
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnSelector
      disableColumnMenu
      autoHeight
      hideFooter
    />
  );
};

export default StripedTable;
