import { RecurrenceCycleType } from "src/interfaces/record";

type CyclesType = {
  [key in RecurrenceCycleType]: string;
};

export const CYCLES: CyclesType = {
  daily: "Diária",
  weekly: "Semanal",
  biweekly: "Quinzenal",
  monthly: "Mensal",
  yearly: "Anual",
};
