import { Box, Divider, Text } from "@chakra-ui/react";

import { useCompany } from "src/hooks/company/useCompany";

import PermissionAbilitySwitch from "./components/PermissionAbilitySwitch";

export function OrdersPermissions() {
  const { serviceOrderNameDefinitions } = useCompany();

  return (
    <Box>
      <Text fontSize="sm" color="gray.500">
        Gerenciar {serviceOrderNameDefinitions.titlePlural.toLowerCase()}
      </Text>

      <PermissionAbilitySwitch
        permission="serviceOrder"
        action="hideTotalizers"
        title={`Ocultar valores totais de ${serviceOrderNameDefinitions.titlePlural.toLowerCase()}`}
        subtitle={`Os valores totais serão ocultados na listagem de ${serviceOrderNameDefinitions.titlePlural.toLowerCase()}`}
      />
      <Divider my={4} />

      <Text fontSize="sm" color="gray.500">
        Gerenciar pedidos de venda
      </Text>

      <PermissionAbilitySwitch
        permission="productOrder"
        action="hideTotalizers"
        title="Ocultar valores totais de pedidos de venda"
        subtitle="Os valores totais serão ocultados na listagem de pedidos de venda"
      />
      <Divider my={4} />
    </Box>
  );
}
