import { useMemo } from "react";

import { SimpleGrid, Text, TextProps } from "@chakra-ui/react";

import { ServiceOrderType } from "src/interfaces/order";
import { formatPrice } from "src/utils/format";

export default function Services({
  services,
  showTotal,
}: {
  services: ServiceOrderType[];
  showTotal: boolean;
}) {
  const renderTotalServicesValue = useMemo(() => {
    return services.reduce((calc, service) => {
      return calc + service.servicePrice * service.quantity;
    }, 0);
  }, [services]);

  const CustomText = ({ children, ...props }: TextProps) => {
    return (
      <Text fontWeight={400} lineHeight={1.1} color="black" {...props}>
        {children}
      </Text>
    );
  };

  return (
    <>
      <SimpleGrid columns={2} w="100%" mb={2} templateColumns="1fr min-content">
        <CustomText fontSize="10px">
          Qtd
          <CustomText fontSize="10px" ml={2} as="span">
            Servico
          </CustomText>
          <CustomText fontSize="10px" ml={3} as="span">
            Vl Un.
          </CustomText>
        </CustomText>

        <CustomText fontSize="10px" textAlign="right">
          Valor
        </CustomText>
      </SimpleGrid>
      <SimpleGrid columns={2} w="100%">
        {services.map(service => (
          <>
            <CustomText fontSize="10px">
              {service.serviceName}
              <br />
              {service.quantity} x {formatPrice(service.servicePrice)}
            </CustomText>
            <CustomText fontSize="10px" textAlign="right">
              {formatPrice(service.servicePrice * service.quantity)}
            </CustomText>
          </>
        ))}
      </SimpleGrid>
      {showTotal ? (
        <SimpleGrid columns={2} w="100%">
          <CustomText fontSize="10px">Total dos serviços</CustomText>
          <CustomText fontSize="10px" textAlign="right">
            {formatPrice(renderTotalServicesValue)}
          </CustomText>
        </SimpleGrid>
      ) : null}
    </>
  );
}
