import React, { useMemo } from "react";

import { GridColDef } from "@material-ui/data-grid";

import Table from "src/components/Table";
import EmptyTable from "src/components/Table/Empty";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { useBuyOrderFilters } from "src/hooks/buy_order/useBuyOrderFilters";
import { BuyOrderType } from "src/interfaces/buyOrder";
import { generateLocaleGridText } from "src/utils/localGridText";

import BuyOrdersTableOptionsMenu from "./components/BuyOrdersTableOptionsMenu";
import RenderBuyOrderStatus from "./components/RenderBuyOrderStatus";
import RenderProviderCell from "./components/RenderProviderCell";
import {
  renderCurrencyValue,
  renderDate,
  renderPaymentStatus,
} from "./formatters";
import * as Styled from "./styles";

const EmptyTableContent = () => (
  <div>
    <Styled.OrderIcon />
    <Typography variant="subtitle1">
      Você ainda não possui ordens de compra para serem visualizados nesta
      lista!
    </Typography>
    <Typography variant="subtitle1">
      comece{" "}
      <Styled.MarkedText>
        {" "}
        cadastrando as suas ordens de compra
      </Styled.MarkedText>
    </Typography>
  </div>
);

interface BuyOrdersTableProps {
  handleGetBuyOrders: (value?: number) => void;
}

const BuyOrdersTable: React.FC<BuyOrdersTableProps> = ({
  handleGetBuyOrders,
}) => {
  const {
    buyOrders,
    buyOrdersStatus,
    getBuyOrderStatuesMetaStatus,
    buyOrdersStatusesMeta,
  } = useBuyOrder();
  const { page, setPage, limit, setLimit, status } = useBuyOrderFilters();

  const orderTotalCount = useMemo(() => {
    return (
      buyOrdersStatusesMeta.find(
        buyOrderstatus => buyOrderstatus.status === status
      )?.count ?? 0
    );
  }, [buyOrdersStatusesMeta, getBuyOrderStatuesMetaStatus, status]);

  const columns: GridColDef[] = [
    { field: "number", headerName: "Nº OC", flex: 1 },
    {
      field: "contact",
      headerName: "Fornecedor",
      renderCell: ({ row }) => (
        <RenderProviderCell buyOrder={row as BuyOrderType} />
      ),
      flex: 3,
    },
    {
      field: "totalAmount",
      headerName: "Valor",
      renderCell: renderCurrencyValue,
      flex: 1.5,
    },
    {
      field: "createdDate",
      headerName: "Data de criação",
      valueFormatter: renderDate,
      flex: 2,
    },
    {
      field: "deadline",
      headerName: "Prazo",
      valueFormatter: renderDate,
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => (
        <RenderBuyOrderStatus buyOrder={row as BuyOrderType} />
      ),
      headerAlign: "center",
      align: "center",
      flex: 3,
    },
    {
      field: "isPaid",
      headerName: "Pagamento",
      renderCell: renderPaymentStatus,
      flex: 2,
    },
    {
      field: "options",
      headerName: " ",
      renderCell: ({ row }) => (
        <BuyOrdersTableOptionsMenu buyOrder={row as BuyOrderType} />
      ),
      align: "right",
      flex: 1,
    },
  ];

  return (
    <Table
      rows={buyOrders}
      columns={columns}
      page={page}
      setPage={page => {
        setPage(page);
        handleGetBuyOrders(page);
      }}
      pageSize={limit}
      onPageSizeChange={setLimit}
      filterMode="server"
      loading={buyOrdersStatus.loading}
      totalCount={orderTotalCount}
      localeText={generateLocaleGridText("buyOrders")}
      detailLink={PATHS.BUY_ORDER_DETAIL}
      detailLinkParamName="buyOrderId"
      excludeCellClick={["options", "status"]}
      components={{
        NoRowsOverlay: () => (
          <EmptyTable>
            <EmptyTableContent />
          </EmptyTable>
        ),
      }}
      // error={status.error}
    />
  );
};

export default BuyOrdersTable;
