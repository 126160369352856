import React from "react";

import Box from "@material-ui/core/Box";
import AddCircle from "@material-ui/icons/AddCircle";

import Breadcrumb from "src/components/Layouts/Breadcrumb";
import { DataBreadcrumbType } from "src/components/Layouts/Breadcrumb/props";
import DashboardLayout from "src/components/Layouts/Dashboard";
import PageTitle from "src/components/Layouts/PageTitle";
import Typography from "src/components/Typography";
import PATHS from "src/constants/paths";
import { ServiceProvider } from "src/hooks/service/useService";
import { withContext } from "src/utils/withContext";

import OptionsPanel from "./components/OptionsPanel";
import Table from "./components/Table";
import * as Styled from "./styles";

const BREADCRUMB_BUTTONS: Array<DataBreadcrumbType> = [{ label: "Serviços" }];

const ServicesList: React.FC = () => {
  return (
    <DashboardLayout selected="register" subSelected="services">
      <Breadcrumb data={BREADCRUMB_BUTTONS}>
        <Box padding={1} paddingRight={0}>
          <Styled.Button
            variant="contained"
            color="primary"
            to={PATHS.SERVICES_ADD}
          >
            <AddCircle />
            <Typography variant="body2">Novo serviço</Typography>
          </Styled.Button>
        </Box>
      </Breadcrumb>
      <PageTitle label="Serviços" />
      <OptionsPanel />

      <Table />
    </DashboardLayout>
  );
};

export default withContext(ServicesList, ServiceProvider);
