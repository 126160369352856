import { useMemo, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import {
  TabPanel,
  Wrap,
  Button,
  Text,
  HStack,
  Box,
  Divider,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
} from "@chakra-ui/react";
import { format } from "date-fns";

import RenderModule from "src/components/RenderModule";
import { PRODUCTS_MEASURES, SERVICES_MEASURES } from "src/constants/measures";
import { useCompany } from "src/hooks/company/useCompany";
import { useModule } from "src/hooks/module/useModule";
import { useOrderForm } from "src/hooks/order/useOrderForm/useOrderForm";
import { OrderReceipt } from "src/pages/Orders/Detail/components/OrderTemplate";
import { useActiveFinancialAccounts } from "src/services/hooks/financialAccount/useActiveFinancialAccounts";
import { formatPrice } from "src/utils/format";

import { CreateOrderBottomBar } from "../CreateOrderBottomBar";
import { TechnicalReportResume } from "../OrderDataForm/components/TechnicalReportResume";

export function OrderResumeForm({ ...props }) {
  const { subtotal, order, orderCreated, handleReset } = useOrderForm();

  const { userCompany, subContactNameDefinitions, operatorNameDefinitions } =
    useCompany();

  const { serviceOrderModule, hasModules } = useModule();

  const componentRef = useRef(null);

  const { data: accounts } = useActiveFinancialAccounts(undefined, {
    enabled: hasModules("accountManagement"),
  });

  const formatterDate = (date?: string, withoutHour?: boolean) => {
    if (date) {
      return format(
        new Date(date),
        withoutHour ? "dd/MM/yyyy" : "dd/MM/yyyy - HH:mm"
      );
    }
    return "-";
  };

  const financialAccountSelected = useMemo(() => {
    if (!order?.financialAccount) {
      return "-";
    }
    return (
      accounts.find(account => account.id === order?.financialAccount)?.name ??
      "-"
    );
  }, [order, accounts]);

  const paymentMethodSelected = useMemo(() => {
    if (!order?.paymentMethod) {
      return "-";
    }
    return (
      userCompany?.paymentMethods?.find(
        item => item.id === order?.paymentMethod
      )?.title ?? "-"
    );
  }, [order, userCompany]);

  const discountParsed = useMemo(() => {
    if (order?.discount === undefined) return 0;

    return order.discountType === "money"
      ? Number(order?.discount || 0)
      : (Number(order?.discount || 0) * subtotal) / 100;
  }, [order, subtotal]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <TabPanel {...props} p={0}>
      <HStack my={4} justifyContent="space-between">
        <Text color="text" fontWeight={700} fontSize="lg" mb={2.5}>
          Venda nº {orderCreated?.code} foi finalizada
        </Text>
        {orderCreated.operator ? (
          <Box>
            <Text fontSize="xs" fontWeight={500}>
              {operatorNameDefinitions.title}
            </Text>
            <Text fontSize="lg" fontWeight={600} color="primary.500">
              {orderCreated.operator?.name}
            </Text>
          </Box>
        ) : null}
      </HStack>

      <Text color="text" fontWeight={500} fontSize="md" mb={4}>
        Dados da venda
      </Text>

      <Wrap spacing={4}>
        <Box>
          <Text fontSize="xs" fontWeight={500}>
            Cliente
          </Text>
          <Text fontSize="lg" fontWeight={600} color="primary.500">
            {orderCreated.contact?.name ?? "-"}
          </Text>
        </Box>
        <RenderModule modules={["subcustomers"]}>
          <Box>
            <Text fontSize="xs" fontWeight={500}>
              {subContactNameDefinitions.title}
            </Text>
            <Text fontSize="lg" fontWeight={600} color="primary.500">
              {orderCreated.subContact?.data
                ?.map(data => data?.content)
                .slice(0, 2)
                .filter(data => data)
                .join(" - ") ?? "-"}
            </Text>
          </Box>
        </RenderModule>
        <Box>
          <Text fontSize="xs" fontWeight={500}>
            Data de criação
          </Text>
          <Text fontSize="lg" fontWeight={600} color="primary.500">
            {orderCreated?.createdDate
              ? formatterDate(
                  orderCreated?.createdDate,
                  serviceOrderModule?.settings?.createdAtType === "date" ||
                    orderCreated.type === "productOrder"
                )
              : "-"}
          </Text>
        </Box>
        <Box>
          <Text fontSize="xs" fontWeight={500}>
            Prazo
          </Text>
          <Text fontSize="lg" fontWeight={600} color="primary.500">
            {orderCreated?.deadline
              ? formatterDate(
                  orderCreated?.deadline,
                  serviceOrderModule?.settings?.dueDateType === "date" ||
                    orderCreated.type === "productOrder"
                )
              : "-"}
          </Text>
        </Box>
        <Box w="100%">
          <Text fontSize="xs" fontWeight={500}>
            Observações
          </Text>
          <Text fontSize="lg" fontWeight={600} color="primary.500">
            {orderCreated.additionalInfo || "-"}
          </Text>
        </Box>
      </Wrap>

      <Divider my={4} />

      <Text color="text" fontWeight={500} fontSize="md" mb={4}>
        Dados do pagamento
      </Text>

      <Wrap spacing={4}>
        {order?.paymentMethod ? (
          <Box>
            <Text fontSize="xs" fontWeight={500}>
              Forma de recebimento
            </Text>
            <Text fontSize="lg" fontWeight={600} color="primary.500">
              {paymentMethodSelected}
            </Text>
          </Box>
        ) : null}

        {!order?.paymentMethod ? (
          <Box>
            <Text fontSize="xs" fontWeight={500}>
              Forma de pagamento
            </Text>
            <Text fontSize="lg" fontWeight={600} color="primary.500">
              Pagar depois
            </Text>
          </Box>
        ) : null}

        <Box>
          <Text fontSize="xs" fontWeight={500}>
            Condição de pagamento
          </Text>
          <Text fontSize="lg" fontWeight={600} color="primary.500">
            {order?.paymentType ?? "-"}
          </Text>
        </Box>

        {order?.financialAccount ? (
          <Box>
            <Text fontSize="xs" fontWeight={500}>
              Conta
            </Text>
            <Text fontSize="lg" fontWeight={600} color="primary.500">
              {financialAccountSelected}
            </Text>
          </Box>
        ) : null}
      </Wrap>

      <Wrap spacing={4} mt={4}>
        {discountParsed > 0 ? (
          <Box>
            <Text fontSize="xs" color="text">
              Subtotal
            </Text>
            <Text fontSize="lg" fontWeight={400} color="primary.500">
              {formatPrice(subtotal)}
            </Text>
          </Box>
        ) : null}

        {discountParsed > 0 ? (
          <Box>
            <Text fontSize="xs" color="text">
              Desconto
            </Text>
            <Text fontSize="lg" fontWeight={400} color="error.500">
              {formatPrice(-discountParsed)}
            </Text>
          </Box>
        ) : null}

        <Box
          ml={4}
          pl={discountParsed > 0 ? 4 : 0}
          borderLeft={discountParsed > 0 ? "solid 1px" : undefined}
          borderColor="gray.200"
        >
          <Text fontSize="xs" color="text">
            Total da venda
          </Text>
          <Text fontSize="lg" fontWeight={700} color="primary.500">
            {formatPrice(subtotal - discountParsed)}
          </Text>
        </Box>

        <Box>
          <Text fontSize="xs" color="text">
            Já pago
          </Text>
          <Text fontSize="lg" fontWeight={400} color="primary.500">
            {formatPrice(
              orderCreated?.records?.reduce((calc, cur) => {
                if (cur.paidStatus === "paid") {
                  return calc + cur.value;
                }
                return calc;
              }, 0) ?? 0
            )}
          </Text>
        </Box>
      </Wrap>

      <Divider my={4} />

      {order?.services?.length ? (
        <TableContainer
          bg="white"
          border="1px solid #D6D8DC"
          borderRadius={10}
          mb={4}
        >
          <Table
            variant="simple"
            colorScheme="gray"
            sx={{
              th: {
                paddingTop: 4,
                fontWeight: 500,
                fontSize: "md",
                textTransform: "capitalize",
                color: "text",
              },
              "th,tr,td": {
                paddingLeft: 2,
                paddingRight: 2,
              },
              "th:first-child,tr:first-child,td:first-child": {
                paddingLeft: 4,
              },
              "th:last-child,tr:last-child,td:last-child": {
                paddingRight: 4,
              },
            }}
          >
            <Thead>
              <Tr>
                <Th>Serviço</Th>
                <Th>Unidade</Th>
                <Th isNumeric>Qtde</Th>
                <Th isNumeric>Preço un</Th>
                <Th isNumeric>Preço total</Th>
              </Tr>
            </Thead>
            <Tbody fontSize="md">
              {order?.services.map((serviceOrder, index) => {
                return (
                  <Tr key={`${Math.random()}${index.toString()}`}>
                    <Td color="primary.500">{serviceOrder.service?.name}</Td>
                    <Td w="150px">
                      {serviceOrder.service?.unitOfMeasure
                        ? SERVICES_MEASURES[serviceOrder.service?.unitOfMeasure]
                        : ""}
                    </Td>
                    <Td w="150px" isNumeric>
                      {serviceOrder.quantity}
                    </Td>
                    <Td w="150px" isNumeric>
                      {formatPrice(serviceOrder.price)}
                    </Td>
                    <Td w="150px" isNumeric>
                      {formatPrice(
                        (serviceOrder.price ?? 0) * (serviceOrder.quantity ?? 0)
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : null}
      {order?.products?.length ? (
        <TableContainer
          bg="white"
          border="1px solid #D6D8DC"
          borderRadius={10}
          mb={4}
        >
          <Table
            variant="simple"
            colorScheme="gray"
            sx={{
              th: {
                paddingTop: 4,
                fontWeight: 500,
                fontSize: "md",
                textTransform: "capitalize",
                color: "text",
              },
              "th,tr,td": {
                paddingLeft: 2,
                paddingRight: 2,
              },
              "th:first-child,tr:first-child,td:first-child": {
                paddingLeft: 4,
              },
              "th:last-child,tr:last-child,td:last-child": {
                paddingRight: 4,
              },
            }}
          >
            <Thead>
              <Tr>
                <Th fontSize="md">Produto</Th>
                <Th fontSize="md">Unidade</Th>
                <Th fontSize="md" isNumeric>
                  Qtde
                </Th>
                <Th fontSize="md" isNumeric>
                  Preço un
                </Th>
                <Th fontSize="md" isNumeric>
                  Preço total
                </Th>
              </Tr>
            </Thead>
            <Tbody fontSize="md">
              {order?.products.map((productOrder, index) => {
                return (
                  <Tr key={`${Math.random()}${index.toString()}`}>
                    <Td color="primary.500">{productOrder.product?.name}</Td>
                    <Td w="150px">
                      {productOrder.product?.unitOfMeasure
                        ? PRODUCTS_MEASURES[productOrder.product?.unitOfMeasure]
                        : ""}
                    </Td>
                    <Td w="150px" isNumeric>
                      {productOrder.quantity}
                    </Td>
                    <Td w="150px" isNumeric>
                      {formatPrice(productOrder.price)}
                    </Td>
                    <Td w="150px" isNumeric>
                      {formatPrice(
                        (productOrder.price ?? 0) * (productOrder.quantity ?? 0)
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : null}

      {order?.technicalReport ? (
        <>
          <Text color="text" mb={2.5}>
            Laudo técnico
          </Text>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg="white"
            borderRadius={10}
            p={4}
            mb={6}
          >
            <TechnicalReportResume technicalReport={order.technicalReport} />
          </Flex>
        </>
      ) : null}

      {orderCreated ? (
        <OrderReceipt
          ref={componentRef}
          company={userCompany}
          order={orderCreated}
          products={orderCreated.products ?? []}
          services={orderCreated.services ?? []}
          customer={orderCreated.contact}
          records={orderCreated?.records ?? []}
        />
      ) : null}

      <CreateOrderBottomBar
        showPaymentDetails={false}
        left={<Button onClick={handlePrint}>Imprimir recibo</Button>}
        right={
          <Wrap spacing={4}>
            <Button
              onClick={() => {
                handleReset();
              }}
            >
              Fazer outra venda
            </Button>
          </Wrap>
        }
      />
    </TabPanel>
  );
}
