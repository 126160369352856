import React, { useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import { useFormikContext } from "formik";

import Typography from "src/components/Typography";
import { ServiceOrderSettingsField } from "src/interfaces/preferences";

import { FieldOptionProps, SettingsFieldForm } from "./props";
import * as Styled from "./styles";

const FieldOption: React.FC<FieldOptionProps> = props => {
  const { name, label } = props;

  const { values, setFieldValue } = useFormikContext<SettingsFieldForm>();

  const checked = useMemo(
    () => values.fields.some(item => item.name === name && item.enabled),
    [values]
  );

  const toggleStatus = (itemName: string, itemStatus: boolean) =>
    itemName === name ? !itemStatus : itemStatus;

  const handleChange = () => {
    const newValues = values.fields.map(item => ({
      ...item,
      enabled: toggleStatus(item.name, item.enabled),
    }));

    setFieldValue("fields", newValues as ServiceOrderSettingsField[]);
  };

  return (
    <Styled.FieldOptionContainer container spacing={2}>
      <Grid item xs={2}>
        <Switch
          checked={checked}
          onChange={handleChange}
          name="check"
          color="primary"
          inputProps={{ "aria-label": label }}
        />
      </Grid>

      <Grid item xs={10}>
        <Typography variant="body2">{label}</Typography>
      </Grid>
    </Styled.FieldOptionContainer>
  );
};

export default FieldOption;
