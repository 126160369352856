import styled from "styled-components";

import { ReactComponent as OrdersIconUnstyled } from "src/assets/icons/emptyTable/orders.svg";
import Typography from "src/components/Typography";

import { StatusTextProps } from "./props";

export const StatusText = styled(Typography)<StatusTextProps>`
  color: ${({ statusColor }) => statusColor};
`;

export const OrderIcon = styled(OrdersIconUnstyled)`
  margin-bottom: 15px;
`;

export const MarkedText = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;
