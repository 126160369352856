import React, { FC } from "react";

import { Flex, Heading } from "@chakra-ui/react";

import RenderModule from "src/components/RenderModule";
import PATHS from "src/constants/paths";
import { Can, usePermission } from "src/hooks/permission/usePermission";

import ReportCard from "../ReportCard";

const OrderReportsTab: FC = () => {
  const { can } = usePermission();

  if (
    !can([
      { subject: "reportOrderProducts", action: "view" },
      { subject: "reportOrderServices", action: "view" },
      { subject: "reportOrderComission", action: "view" },
      { subject: "reportSalesByPeriod", action: "view" },
    ])
  ) {
    return (
      <Flex flex={1} justify="center" align="center">
        <Heading as="h3" fontSize="md" textAlign="center">
          Você não tem permissão para acessar os relatórios de vendas.
        </Heading>
      </Flex>
    );
  }

  return (
    <>
      <Can subject="reportOrderProducts" action="view">
        <ReportCard
          title="Produtos"
          subtitle="Visualize os produtos que mais vendem e mais te dão lucro"
          path={PATHS.REPORTS_PRODUCTS}
        />
      </Can>
      <Can subject="reportOrderServices" action="view">
        <ReportCard
          title="Serviços"
          subtitle="Visualize os serviços mais prestados e que te dão mais lucro"
          path={PATHS.REPORTS_SERVICES}
        />
      </Can>
      <RenderModule modules={["orderComission"]}>
        <Can subject="reportOrderComission" action="view">
          <ReportCard
            title="Comissão de vendedores"
            subtitle="Visualize a comissão de cada venda feita"
            path={PATHS.REPORTS_SALES_COMISSION}
          />
        </Can>
      </RenderModule>
      <Can subject="reportSalesByPeriod" action="view">
        <ReportCard
          title="Vendas por período"
          subtitle="Visualize as vendas por período feitas"
          path={PATHS.REPORTS_SALES_BY_PERIOD}
        />
      </Can>
    </>
  );
};

export default OrderReportsTab;
