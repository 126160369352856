import { toast } from "react-toastify";

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import api from "src/services/api";

export const uploadImage = (formData: FormData) =>
  api
    .post("v1/upload/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(res => res.data);

export function useUploadImage(
  config?: UseMutationOptions<any, any, any, unknown>
) {
  return useMutation<any, any, any>(
    (file: any) => {
      const formData = new FormData();
      formData.append("image", file);
      return uploadImage(formData);
    },
    {
      onError: ({ response }) => {
        toast.error(
          response?.data?.message ?? "Ocorreu um problema ao enviar dados"
        );
      },
      ...config,
    }
  );
}
