import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useAuth } from "src/hooks/user/auth/useAuth";
import { CashFlow } from "src/interfaces/cashFlow";
import api from "src/services/api";

export const getCashFlowOpened = () =>
  api.get<CashFlow>("v1/cashflow/active").then(res => res.data);

export function useCashFlowOpened(
  config?: UseQueryOptions<CashFlow, Error, CashFlow, unknown[]>
) {
  const { user } = useAuth();
  return useQuery<CashFlow, Error, CashFlow, unknown[]>(
    ["cashflowOpened", user.id],
    () => getCashFlowOpened(),
    {
      refetchOnWindowFocus: false,
      retry: false,
      ...(config || {}),
    }
  );
}
