import { CompleteSignUpType, UserToAddType } from "src/interfaces/user";
import api from "src/services/api";

/**
 * Checar se o e-mail é válido
 */
export const checkEmail = (email: string) =>
  api
    .post("v1/user/signup/check/email", null, {
      params: {
        email,
      },
    })
    .then(res => res);

/**
 * Checar se o telefone é válido
 */
export const checkNumber = (phoneNumber: string) =>
  api
    .post("v1/user/signup/check/number", null, {
      params: {
        phoneNumber,
      },
    })
    .then(res => res);

/**
 * Cadastrar usuário na base
 */
export const signUp = (params: Partial<UserToAddType>) =>
  api.post("v1/user/signup", params).then(res => res.data);

/**
 * Checar código enviado por SMS
 */
export const SMSConfirm = (code: string) =>
  api
    .post("v1/user/sms/confirm", {
      code,
    })
    .then(res => res);

/**
 * Reenvia código SMS
 */
export const SMSResend = () => api.post("v1/user/sms/resend").then(res => res);

/**
 * Busca por dados do CNPJ
 */
export const searchCNPJ = (cnpj: string) =>
  api
    .post("v1/user/signup/search/cnpj", {
      cnpj,
    })
    .then(res => res.data);

/**
 * Busca por CEP
 */
export const searchCEP = (cep: string) =>
  api
    .get("v1/user/signup/search/cep", {
      params: {
        cep,
      },
    })
    .then(res => res.data);

/**
 * Listagem de areas de atuação de uma empresa
 */
export const occupationArea = () =>
  api.get("v1/occupationarea").then(res => res.data);

/**
 *  Completar cadastro de usuário
 */
export const completeSignUp = (params: Partial<CompleteSignUpType>) =>
  api.post("v1/user/signup/complete", params).then(res => res.data);
