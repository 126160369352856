import { ReactNode } from "react";

import { Box, Flex, Text, Wrap } from "@chakra-ui/react";

import { CashFlowActions } from "src/pages/Orders/List/components/CashFlowActions";
import { formatPrice } from "src/utils/format";

interface CreateOrderBottomBarProps {
  left?: ReactNode;
  right?: ReactNode;
  subtotal?: number;
  discount?: number;
  deliveryTax?: number;
  total?: number;
  showPaymentDetails?: boolean;
}

export function CreateOrderBottomBar({
  left,
  right,
  subtotal,
  discount,
  deliveryTax,
  total,
  showPaymentDetails = true,
}: CreateOrderBottomBarProps) {
  return (
    <Flex
      position="fixed"
      px={4}
      right={0}
      bottom={0}
      w="calc(100% - 250px)"
      h="90px"
      bg="white"
      alignItems="center"
      justifyContent="flex-end"
      gap={4}
    >
      <Wrap spacing={4} pl={2} flex={1}>
        <CashFlowActions />
        {left}
      </Wrap>

      {showPaymentDetails ? (
        <Wrap spacing={4}>
          {subtotal !== undefined ? (
            <Box>
              <Text fontSize="xs" color="text">
                Subtotal
              </Text>
              <Text fontSize="lg" fontWeight={600} color="primary.500">
                {formatPrice(subtotal)}
              </Text>
            </Box>
          ) : null}
          {discount !== undefined ? (
            <Box>
              <Text fontSize="xs" color="text">
                Desconto
              </Text>
              <Text fontSize="lg" fontWeight={600} color="error.500">
                {formatPrice(-discount)}
              </Text>
            </Box>
          ) : null}
          {deliveryTax !== undefined ? (
            <Box>
              <Text fontSize="xs" color="text">
                Frete
              </Text>
              <Text fontSize="lg" fontWeight={600} color="primary.500">
                {formatPrice(deliveryTax)}
              </Text>
            </Box>
          ) : null}
          {total !== undefined ? (
            <Box ml={4} pl={4} borderLeft="solid 1px" borderColor="gray.200">
              <Text fontSize="xs" color="text">
                Valor total
              </Text>
              <Text fontSize="lg" fontWeight={700} color="primary.500">
                {formatPrice(total)}
              </Text>
            </Box>
          ) : null}
        </Wrap>
      ) : null}

      {right}
    </Flex>
  );
}
