import styled from "styled-components";

import MuiAppBar from "@material-ui/core/AppBar";
import MuiContainer from "@material-ui/core/Container";
import MuiToolbar from "@material-ui/core/Toolbar";

const BORDER_BOTTOM = 12;

export const AppBar = styled(MuiAppBar)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom-left-radius: ${BORDER_BOTTOM}px;
  border-bottom-right-radius: ${BORDER_BOTTOM}px;
  &.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px rgba(104, 104, 104, 0.25);
  }
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;

export const Toolbar = styled(MuiToolbar)`
  border-bottom-left-radius: ${BORDER_BOTTOM}px;
  border-bottom-right-radius: ${BORDER_BOTTOM}px;
`;

export const Container = styled(MuiContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
