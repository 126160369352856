import React, { useMemo, useRef, useState } from "react";

import { Box, Divider, SwipeableDrawer } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import { Formik, Form, FormikProps } from "formik";

import Button from "src/components/Button";
import CloseButton from "src/components/CommonButtons/CloseButton";
import Typography from "src/components/Typography";
import { useViewOrder } from "src/hooks/order/useViewOrder";

import FieldOption from "./FieldOption";
import { SettingsFieldForm } from "./props";
import * as Styled from "./styles";

const FieldsManager: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { tableFields, setTableFields } = useViewOrder();
  const formikRef = useRef<FormikProps<SettingsFieldForm>>(null);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    formikRef.current?.resetForm();
  };

  const renderFieldOptions = useMemo(
    () =>
      tableFields.map(field => (
        <div key={field.name}>
          <Divider />
          <FieldOption name={field.name} label={field.label} />
        </div>
      )),
    [tableFields]
  );

  const applyFilter = (values: SettingsFieldForm) => {
    setTableFields(values.fields);
    closeModal();
  };

  return (
    <Formik
      initialValues={{
        fields: tableFields,
      }}
      onSubmit={applyFilter}
      innerRef={formikRef}
      enableReinitialize
    >
      <Form id="fields-manage-form">
        <Styled.OpenFilterButton
          variant="contained"
          color="primary"
          onClick={openModal}
          size="small"
        >
          <TuneIcon />
        </Styled.OpenFilterButton>
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={closeModal}
          onOpen={openModal}
        >
          <Styled.Container>
            <div>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Styled.Title>Informações visíveis</Styled.Title>
                <CloseButton size="small" onClick={closeModal} />
              </Box>

              <Typography variant="body2" color="textSecondary">
                Selecione abaixo quais informações deseja que estejam visíveis
                na lista
              </Typography>

              <Box mb={2} />

              {renderFieldOptions}
            </div>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="fields-manage-form"
              fullWidth
            >
              Aplicar filtros
            </Button>
          </Styled.Container>
        </SwipeableDrawer>
      </Form>
    </Formik>
  );
};

export default FieldsManager;
