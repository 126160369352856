import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { useQueryClient } from "@tanstack/react-query";

import { STATUS } from "src/constants/requestStatus";
import { SchedulingType } from "src/interfaces/scheduling";
import { updateScheduling } from "src/services/scheduling";

import { SchedulingUpdateContextType } from "./props";

export const SchedulingUpdateContext = createContext(
  {} as SchedulingUpdateContextType
);

export const SchedulingUpdateProvider: React.FC = props => {
  const { children } = props;
  const queryClient = useQueryClient();
  const [status, setStatus] = useState(STATUS.inital);

  const [schedulingUpdated, setSchedulingUpdated] = useState<SchedulingType>(
    {} as SchedulingType
  );

  const update = async (params: SchedulingType) => {
    try {
      setStatus(STATUS.loading);

      const response = await updateScheduling(params);

      setSchedulingUpdated(response);

      setStatus(STATUS.success);

      toast.success("Agendamento atualizado");
      queryClient.invalidateQueries({
        queryKey: ["schedulingAllTypes"],
      });
    } catch (e) {
      setStatus(STATUS.error);

      toast.error("Ocorreu um problema ao atualizar esse agendamento");
    }
  };

  return (
    <SchedulingUpdateContext.Provider
      value={{
        status,
        schedulingUpdated,
        update,
      }}
    >
      {children}
    </SchedulingUpdateContext.Provider>
  );
};

export const useSchedulingUpdate = () => {
  const context = useContext(SchedulingUpdateContext);

  if (!context) {
    throw new Error(
      "useSchedulingUpdate must be used within a SchedulingUpdateProvider"
    );
  }

  return context;
};
