import styled from "styled-components";

export const Main = styled.main`
  display: none;
`;

export const Print = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 2mm;
`;

export const Divider = styled.hr`
  width: 100%;
  border: none;
  background-color: #d6d8dc;
  height: 1px;
  margin: 1rem 0;
`;

export const Row = styled.tr``;

export const Cell = styled.td<{
  fontSize: number;
  isBold?: boolean;
  align: string;
}>`
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ isBold }) => (isBold ? "bold" : "400")};
  color: #000;
  text-align: ${({ align }) => align};
`;

export const Table = styled.table`
  width: 100%;
`;

export const Paragraph = styled.p<{
  fontSize: number;
  isBold?: boolean;
}>`
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ isBold }) => (isBold ? "bold" : 400)};
  color: #000;
`;
