import React, { useEffect } from "react";

import BaseSwipeableDrawer from "src/components/BaseSwipeableDrawer";
import { useBaseSwipeableDrawer } from "src/components/BaseSwipeableDrawer/useBaseSwipeableDrawer";
import { useContact } from "src/hooks/contact/useContact";
import { ContactType, TypeOfContact } from "src/interfaces/contact";

import ContactForm from "../ContactForm";
import * as Styled from "./styles";

interface ContactManagerModalProps {
  contact?: ContactType;
  setContact: (subContact: ContactType) => void;
  contactType?: TypeOfContact;
  method: "add" | "update";
}

function ContactManagerModal({
  contact: contactProp,
  setContact: setContactProp,
  contactType,
  method,
}: ContactManagerModalProps) {
  const { handleClose } = useBaseSwipeableDrawer();
  const { createStatus, updateStatus, contact, setContact } = useContact();

  const customHandleClose = () => {
    handleClose("createContactModal");
  };

  useEffect(() => {
    if ((createStatus.success || updateStatus.success) && contact?.id) {
      setContactProp(contact);
      setContact({} as ContactType);
      customHandleClose();
    }
  }, [createStatus, updateStatus]);

  return (
    <BaseSwipeableDrawer
      tag="createContactModal"
      title={method === "update" ? "Atualizar contato" : "Adicionar contato"}
      buttonTitle="Salvar"
      buttonProps={{
        loading: createStatus.loading || updateStatus.loading,
        disabled: createStatus.loading || updateStatus.loading,
        form: "contactForm",
      }}
      customHandleClose={customHandleClose}
    >
      <Styled.Container>
        <ContactForm
          method={method}
          data={method === "add" ? undefined : contactProp}
          contactType={contactType}
          isModal
        />
      </Styled.Container>
    </BaseSwipeableDrawer>
  );
}

export default ContactManagerModal;
