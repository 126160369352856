import React from "react";

import Grid from "@material-ui/core/Grid";
import { format } from "date-fns";

import { useBuyOrder } from "src/hooks/buy_order/useBuyOrder";
import { formatPrice } from "src/utils/format";

import * as Styled from "./styles";

const BasicData: React.FC = () => {
  const { buyOrder } = useBuyOrder();

  const formatterDate = (date?: string, withoutHour?: boolean) => {
    if (date) {
      return format(
        new Date(date),
        withoutHour ? "dd/MM/yyyy" : "dd/MM/yyyy - HH:mm"
      );
    }
    return "-";
  };

  return (
    <>
      <Grid item>
        <Styled.LabelText>Data de criação</Styled.LabelText>
        <Styled.LabelValue>
          {buyOrder?.createdDate ? formatterDate(buyOrder.createdDate) : "-"}
        </Styled.LabelValue>
      </Grid>
      <Grid item>
        <Styled.LabelText>Prazo de entrega</Styled.LabelText>
        <Styled.LabelValue>
          {buyOrder?.deadline ? formatterDate(buyOrder.deadline) : "-"}
        </Styled.LabelValue>
      </Grid>
      <Grid item>
        <Styled.LabelText>Valor total</Styled.LabelText>
        <Styled.LabelValue>
          {buyOrder?.totalAmount ? formatPrice(buyOrder.totalAmount) : "-"}
        </Styled.LabelValue>
      </Grid>
    </>
  );
};

export default BasicData;
