import { createTheme, responsiveFontSizes } from "@material-ui/core";
import { ptBR as corePtBr } from "@material-ui/core/locale";
import { ptBR as dataGridPtBr } from "@material-ui/data-grid";

const materialTheme = createTheme(
  {
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      h3: {
        fontWeight: 500,
      },
      h5: {
        fontWeight: 500,
      },
      subtitle1: {
        fontWeight: 400,
        fontSize: 15,
        letterSpacing: 0.15,
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: 15,
        letterSpacing: 0.15,
      },
      caption: {
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: 0.4,
      },
    },
    palette: {
      primary: {
        main: "#074EE8",
        light: "#65AEED",
        dark: "#1A5DF2",
      },
      secondary: {
        main: "#13223E",
        light: "#ACE2F6",
        contrastText: "#F0F7FE",
      },
      error: {
        main: "#e54848",
      },
    },
    shape: {
      borderRadius: 10,
    },
    overrides: {
      MuiInputLabel: {
        outlined: {
          backgroundColor: "#fff",
          paddingLeft: 2,
          paddingRight: 2,
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: "#5B5C60",
          boxShadow: "5px 5px 12px rgba(0, 0, 0, 0.3)",
          borderRadius: "3px",
          padding: "8px 10px",
        },
        arrow: {
          borderRadius: "3px",
        },
      },
    },
  },
  dataGridPtBr,
  corePtBr
);

export default responsiveFontSizes(materialTheme);
