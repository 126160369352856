import React from "react";
import { Link } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
// import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import DomainIcon from "@material-ui/icons/Domain";
import MenuIcon from "@material-ui/icons/Menu";
// import NotificationsIcon from "@material-ui/icons/Notifications";

import { ReactComponent as LogoMobile } from "src/assets/azulou-logo-mobile.svg";
import { ReactComponent as Logo } from "src/assets/azulou-logo.svg";
import PATHS from "src/constants/paths";
import { useCompany } from "src/hooks/company/useCompany";

import { MainToolbarProps } from "./props";
import * as Styled from "./styles";

const MainToolbar: React.FC<MainToolbarProps> = props => {
  const { handleDrawerToggle } = props;

  const { userCompany } = useCompany();

  return (
    <Styled.AppBar position="fixed">
      <Styled.Toolbar>
        <Styled.Container maxWidth={false}>
          <Box display="flex" alignItems="center">
            <Hidden mdUp>
              <IconButton
                color="primary"
                aria-label="abrir sidebar"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>

            <Link to={PATHS.HOME}>
              <Hidden xsDown>
                <Logo />
              </Hidden>
              <Hidden smUp>
                <LogoMobile />
              </Hidden>
            </Link>
          </Box>

          <div>
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <Hidden smDown>
                <Grid item>
                  <Button variant="outlined" color="primary">
                    Texto
                  </Button>
                </Grid>

                <Grid item>
                  <Button color="primary">Texto</Button>
                </Grid>

                <Grid item>
                  <IconButton
                    aria-label="Exibir 11 novas notificações"
                    color="primary"
                  >
                    <Badge badgeContent={1} color="error">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Grid>
              </Hidden> */}

              <Grid item>
                <Avatar
                  alt="Logomarca da empresa"
                  variant="rounded"
                  src={userCompany?.logo}
                >
                  <DomainIcon />
                </Avatar>
              </Grid>
            </Grid>
          </div>
        </Styled.Container>
      </Styled.Toolbar>
    </Styled.AppBar>
  );
};

export default MainToolbar;
