import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TagType } from "src/interfaces/tag";
import api from "src/services/api";

interface TagsParamsType {
  search: string;
  type: "contact";
}

export const getTags = (params: TagsParamsType) =>
  api
    .get<TagType[]>("v1/tag", {
      params,
    })
    .then(res => res.data);

export function useTags(
  params: TagsParamsType,
  config?: UseQueryOptions<TagType[], Error, TagType[], unknown[]>
) {
  return useQuery<TagType[], Error, TagType[], unknown[]>(
    ["tags", params],
    () => getTags(params),
    {
      refetchOnWindowFocus: false,
      ...(config || {}),
      initialData: [] as TagType[],
    }
  );
}
