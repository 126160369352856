import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from "react";

import { SERVICE_ORDER_FIELDS } from "src/constants/orders";
import { usePreferencesStorage } from "src/hooks/storage/usePreferenceStorage";
import { useStorage } from "src/hooks/storage/useStorage";
import {
  ListViewType,
  PreferencesOrdersType,
  ServiceOrderSettingsField,
} from "src/interfaces/preferences";
import { sumArrayValues } from "src/utils/arrayUtil";

import { useOrder } from "../useOrder";
import { ViewOrderContextType } from "./props";

export const ViewOrderContext = createContext({} as ViewOrderContextType);

const DEFAULT_SERVICE_ORDER_LIST_VIEW_PEFERENCE = "list";
const DEFAULT_SERVICE_ORDER_TABLE_FIELDS = SERVICE_ORDER_FIELDS;

const initializerPreferences = () => {
  const { getItem } = useStorage();
  const data = getItem<PreferencesOrdersType>("preferences", undefined);

  return data;
};

const initializerListView = () => {
  const listViewPreferences = initializerPreferences();

  if (listViewPreferences?.serviceOrder?.listView) {
    return listViewPreferences?.serviceOrder?.listView;
  }

  return DEFAULT_SERVICE_ORDER_LIST_VIEW_PEFERENCE;
};

const initializerTableFields = () => {
  const tableFieldsPreferences = initializerPreferences();

  if (tableFieldsPreferences?.serviceOrder?.tableFields) {
    const localField = tableFieldsPreferences?.serviceOrder?.tableFields;

    const fields = DEFAULT_SERVICE_ORDER_TABLE_FIELDS.map(field => ({
      ...field,
      enabled:
        localField.find(itemField => itemField.name === field.name)?.enabled ??
        false,
    }));

    return fields;
  }

  return DEFAULT_SERVICE_ORDER_TABLE_FIELDS;
};

export const ViewOrderProvider: React.FC = props => {
  const { children } = props;

  const { products, services } = useOrder();

  const [listView, setListView] = useState<ListViewType>(initializerListView());
  const [tableFields, setTableFields] = useState<ServiceOrderSettingsField[]>(
    initializerTableFields()
  );

  const [discount, setDiscount] = useState(0);

  const { saveParamsByKey } = usePreferencesStorage();

  useEffect(() => {
    saveParamsByKey("serviceOrder", {
      listView,
      tableFields,
    });
  }, [listView, tableFields]);

  const productsTotal = useMemo(() => {
    const productsTotal = products.map(
      product => product.productPrice * product.quantity
    );

    return sumArrayValues(productsTotal);
  }, [products]);

  const servicesTotal = useMemo(() => {
    const servicesTotal = services.map(
      service => service.servicePrice * service.quantity
    );

    return sumArrayValues(servicesTotal);
  }, [services]);

  const quantityServices = useMemo(() => {
    const quantity = services.map(product => product.quantity);

    return sumArrayValues(quantity);
  }, [services]);

  const quantityProducts = useMemo(() => {
    const quantity = products.map(product => product.quantity);

    return sumArrayValues(quantity);
  }, [products]);

  const totalWithoutDiscount = useMemo(
    () => productsTotal + servicesTotal,
    [productsTotal, servicesTotal, discount]
  );

  const total = useMemo(
    () => totalWithoutDiscount - discount,
    [totalWithoutDiscount, discount]
  );

  return (
    <ViewOrderContext.Provider
      value={{
        listView,
        setListView,

        tableFields,
        setTableFields,

        discount,
        setDiscount,

        productsTotal,
        quantityProducts,

        servicesTotal,
        quantityServices,

        total,
        totalWithoutDiscount,
      }}
    >
      {children}
    </ViewOrderContext.Provider>
  );
};

export const useViewOrder = () => {
  const context = useContext(ViewOrderContext);

  if (!context) {
    throw new Error("useViewOrder must be used within a ViewOrderProvider");
  }

  return context;
};
