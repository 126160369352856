import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { FormControl, FormLabel, Switch, Text } from "@chakra-ui/react";

import {
  FeaturesActions,
  FeaturesSubjects,
  UserPermissions,
} from "src/interfaces/permissions";

interface PermissionAbilitySwitchProps {
  title: string;
  subtitle: string;
  action: FeaturesActions;
  permission: FeaturesSubjects;
}

function PermissionAbilitySwitch(props: PermissionAbilitySwitchProps) {
  const { title, subtitle, action, permission } = props;

  const { setValue, watch } = useFormContext();

  const value: UserPermissions = watch("permissions") ?? {
    [permission]: [],
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let permissions = [...(value[permission] ?? [])];

    if (event.target.checked) {
      permissions = [...permissions, action];
    } else {
      permissions = permissions.filter(permission => permission !== action);
    }

    setValue("permissions", {
      ...value,
      [permission]: permissions,
    });
  };

  const isChecked = useMemo(() => {
    return (
      value[permission]?.some(permission => {
        return permission === action;
      }) ?? false
    );
  }, [permission, value]);

  return (
    <FormControl display="flex" justifyContent="space-between" mt={4}>
      <FormLabel htmlFor={`${permission}-${action}-switch`}>
        <Text color="gray.500">{title}</Text>
        <Text fontSize="sm" color="gray.400">
          {subtitle}
        </Text>
      </FormLabel>
      <Switch
        id={`${permission}-${action}-switch`}
        isChecked={isChecked}
        checked={isChecked}
        onChange={handleChange}
        name={`${permission}-${action}-switch`}
      />
    </FormControl>
  );
}

export default PermissionAbilitySwitch;
